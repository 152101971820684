import * as React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import MDTypography from 'components/MDTypography';
import MDInputAutocomplete from "components/MDInputAutocomplete";
import './style.css'

const NewIcon = (props) => (
  <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.9181 0.950195L10.3981 7.4702C9.62812 8.2402 8.36813 8.2402 7.59813 7.4702L1.07812 0.950195" stroke="#303134" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default function Acomplete(props) {
  const options = props.options ? props.options : [];

  return (<>
    {props.label && <MDTypography variant="h6" fontWeight="medium">
      {props.label}
    </MDTypography>}
    <Autocomplete
      disablePortal
      className="combo-box-demo"
      name={props.name}
      options={options}
      placeholder={props.placeholder}
      popupIcon={<NewIcon />}
      value={props.value && props.labels && {value:props.value, label:props.labels}}
      onChange={(event, selectedValue) => props.onChange(props.name, selectedValue.value)}
      isOptionEqualToValue={(option, value) => option?.id === value?.id}
      sx={{
        width: "auto",
        "& .MuiAutocomplete-popupIndicator": { transform: "none" },
      }}
      renderInput={(params) => (
        <MDInputAutocomplete
          type="text"
          useBrandingColor={props.useBrandingColor}
          placeholder={props.placeholder}
          {...params}
        />
      )}
      {...props}
    />
  </>);
}
