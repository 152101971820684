import React, { useState, useEffect } from "react";
import { Grid } from '@mui/material';
import "../styles.css";
import SimpleTable from "components/SimpleTable";
import ImageRatio from 'assets/images/ImageRatio.png';
import MDBox from "components/MDBox";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "constants";

const datatableModel = {
  columns: [
    { Header: "Number", accessor: "id" },
    { Header: "Name", accessor: "name" },
    { Header: "Quantity", accessor: "in_stock" },
    { Header: "Price", accessor: "default_price" },
    { Header: "UCP Code", accessor: "ucp_code" },
    { Header: "Item Location", accessor: "item_location" },
    { Header: '', accessor: 'action' },
  ],
  rows: [],
  spacing: 15,
}

export default function AllInventoryTab(props) {
  const navigate = useNavigate();

  let data = {
    ...datatableModel,
    rows: [...props.data]
  }

  const dataCard = (data) => {
    let image
    if (data?.product_images?.[0]) image = data.product_images[0]?.image
    
    console.log(' data => ', data)

    return (
      <MDBox
      className="gridView"
      sx={{cursor: 'pointer' }}
      onClick={() => navigate(ROUTES.ITEM_EDIT(data.id))}
      >
        <MDBox
          component={'img'} alt={'default_img'}
          style={{ height: 250, width: 345, background: '#C6C9CE', objectFit: 'cover' }}
          src={image ? image : ImageRatio}
        />
        <ul>
          <li>
            <span style={{ float: 'left', color: '#60A77C' }}>{`I${data.id} • ${data.name}`}</span>
            <span style={{ float: 'right' }}>{data.description}</span>
          </li>
          <br />
          <li>
            <span style={{ float: 'left' }}>Quantity: {data?.in_stock || 0}</span>
            <span style={{ float: 'right' }}>Price: ${data.default_price}</span>
          </li>
          <br />
        </ul>

      </MDBox>
    )
  }

  return (
    props.current === 'list'
      ? <SimpleTable data={data} />
      : <Grid container spacing={3} pb={3} className={props.current === 'list' ? "tables-container" : "tables-top"}>
        {
          data.rows.map((row, key) => dataCard(row))
        }
      </Grid>
  )
}
