
export const API_VERSION_PREFIX = '/api/v1'

export const DOWNLOAD_ROUTES = {
  CLIENT_NOTES: (id = ':id') => `/client_notes/${id}/download`,
}

export const ROLES = {
  SUPERVISOR : 'SUPERVISOR',
  TEAM_MEMBER : 'TEAM_MEMBER',
  CLIENT : 'CLIENT',
  ADMIN : 'ADMIN',
}

export const ROUTES = {
  ROOT: "/",
  LOGOUT: "/logout",
  LOGIN: "/login",
  SIGNUP: "/signup",
  HOME: "/home",
  REQUESTS: "/requests/*",
  NEW_REQUESTS: "/requests/new",
  REQUEST_EDIT: (id = ':id') => `/requests/edit/${id}`,
  QUOTES: "/quotes/*",
  NEW_QUOTES: "/quotes/new",
  QUOTE_EDIT: (id = ':id') => `/quotes/edit/${id}`,
  FORGOT_PASSWORD: "/forgot-password",
  SET_NEW_PASSWORD: "/set-new-password/*",
  ACTIVATE_USER: "/activate-user/*",
  SCHEDULER: "/scheduler",
  CLIENTS: "/clients",
  CLIENTS_NEW: "/clients/new",
  CLIENTS_EDIT: (id = ':id') => `/clients/edit/${id}`,
  CLIENTS_PROFILE: (id = ':id') => `/clients/profile/${id}`,
  CLIENTS_REQUEST_OPEN: (id = ':id') => `/clients/request_open/${id}`,
  CLIENTS_QUOTE_DETAILS: (id = ':id') => `/clients/quote_details/${id}`,
  CLIENTS_JOB_DETAILS: (id = ':id') => `/clients/job_details/${id}`,
  JOBS: "/jobs",
  JOB_FORM: "/jobs/job_form",
  NEW_TASK: (dates = ':dates', group = ':group') => `/scheduler/new-task/${dates}/${group}`,
  EDIT_TASK: (id = ':id') => `/scheduler/edit-task/${id}`,
  NEW_EVENT: "/scheduler/event",
  EDIT_EVENT: (id = ':id') => `/scheduler/event/${id}`,
  NEW_JOB: "/jobs/new-job",
  EDIT_JOB: (id = ':id') => `/jobs/edit/${id}`,
  INVOICES: "/invoices/*",
  NEW_INVOICE: "/invoices/new",
  INVOICE_EDIT: (id = ':id') => `/invoice/edit/${id}`,
  MESSAGES: "/messages",
  INVENTORY: "/inventory",
  MATRIX_CREATE: "/matrix/create",
  MATRIX_EDIT: (id = ':id') => `/matrix/edit/${id}`,
  ITEM_CREATE: "/item/create",
  ITEM_EDIT: (id = ':id') => `/item/edit/${id}`,
  // ITEM_EDIT: "/item/edit",
  IMPORT_CREATE: "/import/create",
  TIMESHEET: "/timesheet",
  REPORTS: "/reports",
  REPORTS_PROJECTED_INCOME: "/reports/projected_income",
  REPORTS_TRANSACTION_LIST: "/reports/transaction_list",
  REPORTS_INVOICES: "/reports/invoices",
  REPORTS_TAXATION: "/reports/taxation",
  REPORTS_AGED_RECEIVABLES: "/reports/aged_receivables",
  REPORTS_BAD_DEBT: "/reports/bad_debt",
  REPORTS_CLIENT_BALANCE_SUMMARY: "/reports/client_balance_summary",
  REPORTS_VISITS_REPORT: "/reports/visits_report",
  REPORTS_ONE_OFF_JOBS: "/reports/one_off_jobs",
  REPORTS_RECURRING_JOBS: "/reports/recurring_jobs",
  REPORTS_QUOTES_CREATED: "/reports/quotes_created",
  REPORTS_QUOTES_CONVERTED: "/reports/quotes_converted",
  REPORTS_PRODUCT_SERVICES: "/reports/products_services",
  REPORTS_WAYPOINTS: "/reports/waypoints",
  REPORTS_TIMESHEET: "/reports/timesheet",
  REPORTS_CLIENT_COMMUNICATIONS: "/reports/client_communications",
  REPORTS_JOB_FOLLOWUPS_EMAILS: "/reports/job_followup_emails",
  REPORTS_CLIENT_CONTACT_INFO: "/reports/client_contact_info",
  REPORTS_CLIENT_REENGAGEMENT: "/reports/client_reengagement",
  REPORTS_CLIENT_HUB: "/reports/client_hub",
  REPORTS_CLIENT_HUB_DETAIL: "/reports/client_hub/detail",
  REPORTS_SCHEDULE: "/reports/schedule",
  REPORTS_DAILY_TRANSACTION: "/reports/daily-transaction",
  REPORTS_SALES: "/reports/sales",
  REPORTS_OPEN_ORDER: "/reports/open-order",
  REPORTS_SELECTED_SALES: "/reports/selected-sales",
  REPORTS_SALES_REP: "/reports/sales-rep",
  REPORTS_SPECIAL_ORDER: "/reports/special-order",
  REPORTS_SALES_TAX: "/reports/sales-tax",
  EXPENSES: "/expenses",
  NOTIFICATIONS: "/notifications",
  SETTINGS: "/settings",
  SETTINGS_COMPANY: "/settings/company",
  SETTINGS_BRANDING: "/settings/branding",
  SETTINGS_SUBSCRIPTIONS: "/settings/subscriptions",
  SETTINGS_PDF_STYLE_EXAMPLE: "/pdf/pdf_example/",
  SETTINGS_CREATE_PDF_STYLE: "/pdf/pdf_style/",
  SETTINGS_PDF_STYLE_OPEN: (id = ':id') => `/pdf/pdf_style/${id}/`,
  SETTINGS_UPDATE_PDF_STYLE: (id = ':id') => `/pdf/pdf_style/${id}/`,
  SETTINGS_TEAM: "/settings/team",
  SETTINGS_CREATE_USER: "/settings/create_user",
  SETTINGS_UPDATE_USER: (id = ':id') => `/settings/update_user/${id}`,
  ADMIN_ANALYTICS: "/admin/analytics",
  ADMIN_USERS: "/admin/users",
  ADMIN_USERS_DETAIL: (id = ':id') => `/admin/users/details/${id}`,
  ADMIN_USERS_APPROVE_ACCOUNTS: "/admin/approve_accounts",
  ADMIN_USERS_APPROVE_ACCOUNTS_DETAILS: (id = ':id') => `/admin/approve_accounts/details/${id}`,
  ADMIN_SUBSCRIPTIONS: "/admin/subscriptions",
  CLIENT_APPOINTMENTS: "/client/appointments",
  CLIENT_ALL_REQUEST: "/client/all_requests",
  CLIENT_NEW_REQUEST: "/client/all_requests/new",
  CLIENT_REQUEST_EDIT: (id = ':id') => `/client/request/${id}/edit`,
  CLIENT_ALL_QUOTES: "/client/all_quotes",
  CLIENT_ALL_INVOICES: "/client/all_invoices",
  CLIENT_CONTACT_US: "/client/contact_us",
  CLIENT_WALLET: "/client/wallet",
  CLIENT_SUBSCRIPTIONS: "/client/subscriptions",
}

export const industries = [
  { name: 'Appliance Repair', value: 'Appliance Repair'},
  { name: 'Auto Detailing', value: 'Auto Detailing'},
  { name: 'Awning Services', value: 'Awning Services'},
  { name: 'Carpentry', value: 'Carpentry'},
  { name: 'Carpet Cleaning', value: 'Carpet Cleaning'},
  { name: 'Chimney Sweep Services', value: 'Chimney Sweep Services'},
  { name: 'Commercial Cleaning', value: 'Commercial Cleaning'},
  { name: 'Concrete', value: 'Concrete'},
  { name: 'Construction', value: 'Construction'},
  { name: 'Deck Services', value: 'Deck Services'},
  { name: 'Demolition Contractor', value: 'Demolition Contractor'},
  { name: 'Dog Walking', value: 'Dog Walking'},
  { name: 'Drywall', value: 'Drywall'},
  { name: 'Electrical Contracting', value: 'Electrical Contracting'},
  { name: 'Elevator Services', value: 'Elevator Services'},
  { name: 'Excavation', value: 'Excavation'},
  { name: 'Fence Services', value: 'Fence Services'},
  { name: 'Flooring', value: 'Flooring'},
  { name: 'Garage Door Services', value: 'Garage Door Services'},
  { name: 'General Contracting', value: 'General Contracting'},
  { name: 'Handyman', value: 'Handyman'},
  { name: 'HVAC', value: 'HVAC'},
  { name: 'Installation Services', value: 'Installation Services'},
  { name: 'Irrigation Services', value: 'Irrigation Services'},
  { name: 'Janitorial Services', value: 'Janitorial Services'},
  { name: 'Junk Removal', value: 'Junk Removal'},
  { name: 'Landscaping', value: 'Landscaping'},
  { name: 'Lawn Care', value: 'Lawn Care'},
  { name: 'Locksmith Services', value: 'Locksmith Services'},
  { name: 'Mechanical Contracting', value: 'Mechanical Contracting'},
  { name: 'Patio Covers', value: 'Patio Covers'},
  { name: 'Painting', value: 'Painting'},
  { name: 'Paving', value: 'Paving'},
  { name: 'Pest Control', value: 'Pest Control'},
  { name: 'Plumbing', value: 'Plumbing'},
  { name: 'Pool & Spa Services', value: 'Pool & Spa Services'},
  { name: 'Pressure Washing', value: 'Pressure Washing'},
  { name: 'Property Maintenance', value: 'Property Maintenance'},
  { name: 'Remodeling', value: 'Remodeling'},
  { name: 'Residential Cleaning', value: 'Residential Cleaning'},
  { name: 'Restoration', value: 'Restoration'},
  { name: 'Roofing', value: 'Roofing'},
  { name: 'Snow Removal', value: 'Snow Removal'},
  { name: 'Tile Services', value: 'Tile Services'},
  { name: 'Tree Care', value: 'Tree Care'},
  { name: 'Well Water Services', value: 'Well Water Services'},
  { name: 'Window Cleaning', value: 'Window Cleaning'},
  { name: 'Other', value: 'Other'},
];

export const CUSTOM_FIELDS_APPLY_TO = [
  { id: 'TASK',  label: 'Task', value: 'TASK' },
  // { id: 1,  label: 'User', value: 'USER' },
  { id: 'CLIENT',  label: 'Client', value: 'CLIENT' },
  { id: 'REQUEST',  label: 'Request', value: 'REQUEST' },
  { id: 'EVENT',  label: 'Event', value: 'EVENT' },
  { id: 'JOB',  label: 'Job', value: 'JOB' },
  { id: 'CATEGORY',  label: 'Category', value: 'CATEGORY' },
  { id: 'PRODUCT',  label: 'Product', value: 'PRODUCT' },
  { id: 'INVOICE',  label: 'Invoice', value: 'INVOICE' },
  { id: 'QUOTE',  label: 'Quote', value: 'QUOTE' },
  { id: 'INVENTORY',  label: 'Inventory', value: 'INVENTORY' },
  { id: 'JOB_CHECK_LIST',  label: 'Job Check List', value: 'JOB_CHECK_LIST' },
]

export const CUSTOM_FIELD_TYPE = [
  { value: 'NUMERIC', label: 'Number' },
  { value: 'TEXT', label: 'Text' },
  { value: 'BOOLEAN', label: 'True/False' },
  { value: 'SELECT', label: 'List of Options' },
  { value: 'DATE', label: 'Date' },
  { value: 'DATETIME', label: 'Date and Time' },
]
