import { useEffect, useState } from "react";
import 'react-calendar-timeline/lib/Timeline.css'
import moment from 'moment'
import { Grid, InputAdornment, IconButton } from '@mui/material';
import AdminLayout from "../../../components/AdminLayout";
import { useNavigate } from "react-router-dom";
import MDBox from "../../../components/MDBox";
import ThimeCard from "../../../components/ThimeCard";

import MDTypography from "../../../components/MDTypography";
import MDInput from "../../../components/MDInput";
import MDButton from "../../../components/MDButton";
import MDDatePicker from "../../../components/MDDatePicker";
//icons
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';


export default function Matrix() {
    const navigate = useNavigate();
    const [Step, setStep] = useState(1);
    const [ShowTermsConditions, setShowTermsConditions] = useState(false);
    const [ShowPrivacyPolicy, setShowPrivacyPolicy] = useState(false);

  return (

    <AdminLayout title={"New Task"}>
      <Grid container alignItems="top" paddingTop={0} marginTop={0}>
        <Grid item xs={12} lg={9} md={9} >
            <MDTypography variant="h2" fontWeight="medium" mb={1}>
             Create New Job Check List
            </MDTypography>
        </Grid>
    </Grid>
    <Grid container spacing={3} pb={3} justifyContent="center" className="task_container">

      <Grid item xs={6} lg={6} md={6}>
        <MDTypography variant="h6" fontWeight="bold">
            Job Form name
        </MDTypography>
        <MDInput
          fullWidth
          placeholder='input name'
        />
      </Grid>
      <Grid item xs={6} lg={6} md={6}>
        <MDTypography variant="h6" fontWeight="bold">
            Section name
        </MDTypography>
        <MDInput
          fullWidth
          placeholder='input name'
        />
      </Grid>

      <Grid item xs={12} lg={12} md={12}>
        <hr className="MuiDivider-root MuiDivider-fullWidth" style={{background: '#C6C9CE', margin: '16px 0'}} />
      </Grid>

      <Grid item xs={6} lg={6} md={6}>
        <MDTypography variant="h6" fontWeight="bold" style={{ float: 'left' }}>
          Add short answer
        </MDTypography>
        <MDTypography variant="h6" color="error" style={{ float: 'right' }}>
          <u>Delete</u>
        </MDTypography>
        <MDInput
          fullWidth
          placeholder='input question?'
        />
        <MDInput
          placeholder='input short answer'
          style={{ marginLeft: 14, width: '97.5%', marginTop: 8 }}
        />
      </Grid>
      <Grid item xs={6} lg={6} md={6}>
        <MDTypography variant="h6" fontWeight="bold" style={{ float: 'left' }}>
          Add long answer
        </MDTypography>
        <MDTypography variant="h6" color="error" style={{ float: 'right' }}>
          <u>Delete</u>
        </MDTypography>
        <MDInput
          fullWidth
          placeholder='input question?'
        />
        <MDInput
          placeholder='input long answer'
          style={{ marginLeft: 14, width: '97.5%', marginTop: 8 }}
        />
      </Grid>

      <Grid item xs={6} lg={6} md={6}>
        <MDTypography variant="h6" fontWeight="bold" style={{ float: 'left' }}>
          Add dropdown
        </MDTypography>
        <MDTypography variant="h6" color="error" style={{ float: 'right' }}>
          <u>Delete</u>
        </MDTypography>
        <MDInput
          fullWidth
          placeholder='input question?'
        />
        <MDInput
          placeholder='Option Added'
          style={{ marginLeft: 14, width: '97.5%', marginTop: 8, borderColor: '1px solid red' }}
          InputProps={{
            endAdornment: (<InputAdornment position="end"><DeleteOutlineIcon color="error"/></InputAdornment>)
          }}
        />
        <MDInput
          placeholder='input option 2'
          style={{ marginLeft: 14, width: '97.5%', marginTop: 8 }}
        />
        <MDButton size="small" color="green" style={{ float: 'right', marginTop:8 }}>Add option +</MDButton>
      </Grid>

      <Grid item xs={6} lg={6} md={6} style={{ height: 240 }}>
        <MDTypography variant="h6" fontWeight="bold" style={{ float: 'left' }}>
          Add check box
        </MDTypography>
        <MDTypography variant="h6" color="error" style={{ float: 'right' }}>
          <u>Delete</u>
        </MDTypography>
        <MDInput
          fullWidth
          placeholder='input checkbox name'
        />
      </Grid>


      <Grid item xs={12} lg={12} md={12} display="flex" justifyContent="center" alignItems="center">
            <MDButton
              useBrandingColor={'primary'}
                color="green"
                variant="outlined"
                type="submit"
                className={'btn-save'}
            >
                Cancel
            </MDButton>

            <MDButton
              useBrandingColor={'primary'}
                color="green"
                type="submit"
                className={'btn-save'}
            >
                Create Job Check List
            </MDButton>
      </Grid>

    </Grid>
    </AdminLayout>
  )
}
