import React, { useState } from 'react';
import { Link } from "react-router-dom";
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MDButton from "components/MDButton";
import { styled } from "@mui/material/styles";
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import RuleIcon from '@mui/icons-material/Rule';
import { Typography } from '@mui/material';
import "./tables.css";
import { ROUTES } from 'constants';


export const AddButton = ({ style = {}, label }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [active, setActive] = useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setActive(true);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setActive(false);
  };

  return (
    <div>
      <MDButton
        style={{ ...style }}
        color="green"
        useBrandingColor={'primary'}
        className={['btn-import-client', active && 'active'].join(" ")}
        onClick={handleClick}
      >{label || 'Add New +'}</MDButton>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        className="menu-basics"
        onClose={handleClose}
        MenuListProps={{ 'aria-labelledby': 'basic-button' }}
      >
        <MenuItem onClick={handleClose}>
          <MDButton
            useBrandingColor={'primary'}
            color="green"
            className={'btn-import-sub'}
            startIcon={<PersonOutlineIcon className="btn-import-sub-icon" />}
            component={Link}
            to={ROUTES.CLIENTS_NEW}
          > Client
          </MDButton>

        </MenuItem>
        <MenuItem onClick={handleClose}>
          <MDButton
            useBrandingColor={'primary'}
            color="green"
            className={'btn-import-sub'}
            startIcon={<RuleIcon fontSize="large" />}
            component={Link}
            to={ROUTES.JOB_FORM}
          > Job Check List
          </MDButton>
        </MenuItem>
      </Menu>
    </div>
  );
}
