import React, {useEffect, useState} from "react";
import {Link, useNavigate} from 'react-router-dom';
import moment from 'moment'
import {Grid} from '@mui/material';
import ClientPanelLayout from "../../../../components/ClientPanelLayout";
import MDTypography from "../../../../components/MDTypography";
import "../../styles.css"

//icons
import ClockIcon from "assets/images/icons/clock.png";
import CalendarIcon from "assets/images/icons/scheduler_black.png";

import {useStores} from "models";
import {showMessage} from "services/helpers";
import MDButton from "components/MDButton";
import {ROUTES} from 'constants'

export default function Requests() {
    const rootStore = useStores()
    const {loginStore} = rootStore
    const navigate = useNavigate();

    const [Data, setData] = useState([]);
    const [loading, setLoading] = useState(false)

    const getRequests = (searchData) => {
        setLoading(true)
        loginStore.environment.api.getRequests(searchData).then((result) => {
            if (result.kind === "ok") {
                let data = []
                result.data.results.map(r => {
                    data.push({
                        name: r?.task_for?.first_name,
                        start_time: moment(r.start_date).format('DD/MM/YYYY HH:mm'),
                        ...r
                    })
                })
                setData(data)
            } else {
                if (result.kind === "bad-data") {
                    showMessage(result?.errors, 'error', true)
                } else {
                    showMessage('An error occurred, please try again later')
                }
            }
        })
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        getRequests('');
    }, []);

    return (
        <ClientPanelLayout
            title={"Requests"}
            searchFunc={getRequests}
            loading={loading}
        >
            <Grid container alignItems="center" paddingTop={0} marginTop={0} display='flex'
                  justifyContent='space-between'>
                <Grid item xs={12} sm={3} lg={6} className="leftContainer">
                    <MDTypography variant="h2" fontWeight="medium" ml={4} mt={1} width='13rem'>
                        All Requests
                    </MDTypography>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={3}
                    lg={6}
                    display='flex'
                    justifyContent={{xs: 'flex-start', sm: 'flex-end'}}
                    ml={{xs: 4, sm: 0}}
                    pr={{xs: 0, sm: 4}}
                >
                    <MDButton color="green" onClick={() => navigate(ROUTES.CLIENT_NEW_REQUEST)}> Add New +</MDButton>
                </Grid>
            </Grid>
            {(Array.isArray(Data) && Data[0])
                ? Data.map((data, i) =>
                    <Grid key={data.id} container spacing={3} padding={3} className="task_container">
                        <Grid item xs={3} lg={3} md={3}>
                            <MDTypography fontWeight='bold' variant="h6" style={{
                                display: 'flex',
                                alignItems: 'center',
                                flexWrap: 'wrap',
                            }}>

                                {`Created at: ${data.created_at
                                    ? moment(data.created_at).format('ll')
                                    : '-'
                                }`}
                            </MDTypography>
                        </Grid>
                        <Grid item xs={3} lg={3} md={3}>
                            <MDTypography variant="h6" style={{
                                display: 'flex',
                                alignItems: 'center',
                                flexWrap: 'wrap',
                            }}>
                                <img src={CalendarIcon} style={{paddingRight: 10}}/>
                                {data.start_date
                                    ? `${moment(data.start_date).format('dddd')}, ${moment(data.start_date).format('ll')}`
                                    : '-'
                                }
                            </MDTypography>
                        </Grid>
                        <Grid item xs={3} lg={3} md={3}>
                            <MDTypography fontWeight='regular' variant="h6" style={{
                                display: 'flex',
                                alignItems: 'center',
                                flexWrap: 'wrap',
                            }}>
                                <img src={ClockIcon} style={{paddingRight: 10}}/>
                                {data.start_date
                                    ? moment(data.start_date).format('LT')
                                    : '-'
                                }
                            </MDTypography>
                        </Grid>
                        <Grid item xs={3} lg={3} md={3}>
                            <Link to={`/client/request/${data.id}/edit`}
                                  className='base-item link-item'>{data.title}</Link>
                        </Grid>
                    </Grid>
                )
                : <Grid container spacing={3} padding={3} className="task_container">
                    <Grid item xs={4} lg={4} md={4}>
                        <MDTypography variant="h6">Sin datos</MDTypography>
                    </Grid>
                </Grid>
            }
        </ClientPanelLayout>
    )
}
