import React from 'react';
import { useNavigate } from "react-router-dom";

/* MUI components */
import {Box, Button, Typography} from '@mui/material';

/* assets */
import woman from 'assets/images/woman.png';
import man from 'assets/images/man.png';
import logo from 'assets/images/logo.png';
import pxToRem from 'assets/theme/functions/pxToRem';
import colors from 'assets/theme/base/colors';
import {ROUTES} from 'constants'

const {green, white, greenLight} = colors;

export const InitialMobile = () => {
  const navigate = useNavigate();
  return (
    <Box sx={styles.mobile_container}>
      <Box sx={styles.mobile_logo}>
        <img src={logo} alt="logo" style={styles.img} />
      </Box>
      <Box sx={styles.actions}>
        <Button
          sx={[styles.base_button, styles.signup_button]}
          onClick={() => navigate(ROUTES.SIGNUP)}
        >
          <Typography sx={[styles.button_base_text, styles.signup_text]}>
            Sign Up
          </Typography>
        </Button>
        <Button
          sx={[styles.base_button, styles.login_button]}
          onClick={() => navigate(ROUTES.LOGIN)}
        >
          <Typography sx={[styles.button_base_text, styles.login_text]}>
            Log In
          </Typography>
        </Button>
      </Box>
      <Box sx={styles.assets}>
        <Box sx={styles.womman_asset}>
            <img src={woman} alt='woman' style={styles.img} />
        </Box>
        <Box sx={styles.man_asset}>
            <img src={man} alt='man' style={styles.img} />
        </Box>
      </Box>
    </Box>
  )
}

export const styles  = {
    mobile_container: {
        width: '100%',
        height: '100vh',
        overflow: 'hidden',
    },
    mobile_logo: {
        marginInline: 'auto',
        marginTop: pxToRem(84),
        width: pxToRem(222),
        height: pxToRem(52),
    },
    img: {
        width: '100%',
    },
    actions: {
        display: 'flex',
        flexDirection: 'column',
        gap: pxToRem(40),
        marginInline: 'auto',
        marginTop: pxToRem(115),
    },
    base_button: {
        alignItems: 'center',
        alignSelf: 'center',
        borderRadius: '8px',
        display: 'flex',
        height: pxToRem(48),
        justifyContent: 'center',
        width: pxToRem(330),
    },
    signup_button: {
        background: green.main,
        '&:hover': {
            background: green.main,
        }
    },
    login_button: {
        background: greenLight.main,
        '&:hover': {
            background: greenLight.main,
        }
    },
    button_base_text: {
        fontSize: pxToRem(14),
        fontWeight: 700,
    },
    signup_text: {
        color: white.main,
    },
    login_text: {
        color: greenLight.contrastText,
    },
    assets: {
        height: pxToRem(423),
        marginTop: pxToRem(82),
        position: 'relative',
    },
    womman_asset: {
        height: pxToRem(423),
        left: '-7%',
        position: 'absolute',
        width: pxToRem(205),
    },
    man_asset: {
        bottom: '-2.5%',
        height: pxToRem(388),
        position: 'absolute',
        left: '30%',
        width: pxToRem(327),
    },
};

