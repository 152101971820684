import React from 'react';
import Button from '@mui/material/Button';
import {styled} from "@mui/material/styles";
import ClearIcon from '@mui/icons-material/Clear';
import MDBox from "../../../components/MDBox";
import {useStores} from "../../../models";

export const GreenOutlinedButton = styled(Button)(({theme, useBrandingColor}) => {
  const rootStore = useStores()
  const { loginStore } = rootStore;
  let color = '#BFE3CD';
  if (useBrandingColor) {
    try {
      color = JSON.parse(loginStore.branding_data?.[useBrandingColor])?.hex
    } catch (error) {}
  }
  return ({
    color: color,
    backgroundColor: "transparent",
    borderColor: color,
    '&:hover': {
      borderColor: color,
      boxShadow: 'none',
      color: color,
      opacity: 0.6
    },

  })
});


export const FilterButton = ({onClick, ...rest}) => {

  return (
    <MDBox {...rest} display='flex' justifyContent={{xs: 'flex-end', lg: 'flex-start'}}>
      <GreenOutlinedButton
        variant="outlined"
        useBrandingColor={'primary'}
        endIcon={<ClearIcon/>}
        onClick={onClick}
        className={'btn-filter'}
      >
        Clear filters
      </GreenOutlinedButton>
    </MDBox>
  );
}
