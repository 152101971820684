import * as React from 'react';
import CircularProgress, {
  CircularProgressProps,
} from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function CircularProgressWithLabel(props: CircularProgressProps & { value: number, icon: any, colors: any },) {
  return (
    <Box sx={{ position: 'relative' }} className="percents-box" >
      <CircularProgress 
        variant="determinate" {...props} 
        style={{ width: 60, borderRadius: 5, color: `${props.colors}` }} 
      />
      <Box
        sx={{
          top: 10,
          left: 28,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: 25,
          height: 25
        }}
        
      >
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
        >{props.icon}</Typography>
      </Box>
    </Box>
  );
}

export default function Percents(props) {
  const {icon, perc, colors} = props;
  const [progress, setProgress] = React.useState(10);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
    }, 800);
    return () => {
      clearInterval(timer);
    };
  }, []);
  
  return props && <CircularProgressWithLabel value={perc} icon={icon} colors={colors}/>;
}