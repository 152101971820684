import React, {useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import moment from 'moment'

import AdminLayout from "components/AdminLayout";
import SimpleTable from "components/SimpleTable";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

import {Grid} from '@mui/material';
import {AddButton} from "./components/AddButton";
import PrintIcon from "assets/images/icons/print.png";
import {FilterButton} from "./components/FilterButton";
import "./styles.css"
import './components/header.css'
//icons
import {showMessage, useApi} from "services/helpers";
import {dataTableModel, renderTableRow} from "./utils";
import { pickersCalendarHeaderClasses } from "@mui/x-date-pickers/CalendarPicker";



const Quotes = () => {
  const api = useApi();
  const [loading, setLoading] = useState(false);
  const [Data, setData] = useState({...dataTableModel});
  const [StatDate, setStatDate] = useState('');
  const [EndDate, setEndDate] = useState('');
  const [QuotesStatus, setQuotesStatus] = useState({
    APPROVED: 0,
    ARCHIVED: 0,
    AWAITING_RESPONSE: 0,
    CHANGES_REQUESTED: 0,
    CONVERTED: 0,
    DRAFT: 0,
  });

  const resetFilters = () => {
    setStatDate('')
    setEndDate('')
    getInitialData()
  }

  const getInitialData = () => {
    getQuotes('');
    getQuoteStatus();
  }

  const getQuoteStatus = () => {
    api.getQuoteStatus().then((result) => {
      if (result.kind === "ok") {
        setQuotesStatus(result.data)
      }
    })
  }

  const printQuote = (id) => {
    api.getPrintQuote(id)
      .then((result) => {
        if (result.kind === "ok") result.download_file()
        else showMessage('An error occurred, please try again later')
      })
      .catch(err => showMessage())
  }

  const downloadButton = pk => (
    <MDButton
      color="green"
      iconOnly
      useBrandingColor={'primary'}
      onClick={() => printQuote(pk)}
    >
      <img src={PrintIcon} alt='PrintIcon' />
    </MDButton>
  )

  const getQuotes = (searchData, extraData = {}) => {
    setLoading(true)
    let filter = {}
    if (window.location.pathname.includes('approved')) filter.approved = true
    if (window.location.pathname.includes('draft')) filter.draft = true
    api.getQuotes(searchData, {...extraData, ...filter}).then((result) => {
      if (result.kind === "ok") {
        const {results} = result.data
        const tmp = {...dataTableModel}
        tmp.rows = results.map(e => renderTableRow(e))
        tmp.rows.map(r => r.action = downloadButton(r.pk))
        setData(tmp);
      } else {
        if (result.kind === "bad-data") {
          showMessage(result?.errors, 'error', true)
        } else {
          showMessage()
        }
      }
    }).catch(err => showMessage())
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    getInitialData()
  }, []);

  useEffect(() => {
    if (StatDate && EndDate) {
      const startDate = moment(StatDate, 'YYYY-MM-DD')
      const endDate = moment(EndDate, 'YYYY-MM-DD')
      if (startDate > endDate) {
        resetFilters()
        showMessage('Invalid date range')
      } else {
        getQuotes('', {start_date: StatDate, end_date: EndDate})
      }
    }
  }, [StatDate, EndDate])

  return (

    <AdminLayout
      title={"Quotes"}
      searchFunc={getQuotes}
      loading={loading}
    >
      <Grid container alignItems="top" marginTop={0} pt={{xs: 7.5, sm: 0}}>
        <Grid item xs={12} lg={12} md={12} mb={3}>
          <Grid container gap={'10px'} pl={2} justifyContent={{xs: 'center', md: 'space-between'}}>
            <Grid item className="quotes_boxes" xs={5} md={3} lg={1.5}>
              <h6>Draft</h6>
              <p>{QuotesStatus.DRAFT}</p>
            </Grid>
            <Grid item className="quotes_boxes" xs={5} md={3} lg={1.5}>
              <h6>Awaiting response</h6>
              <p>{QuotesStatus.AWAITING_RESPONSE}</p>
            </Grid>
            <Grid item className="quotes_boxes" xs={5} md={3} lg={1.5}>
              <h6>Changes requested</h6>
              <p>{QuotesStatus.CHANGES_REQUESTED}</p>
            </Grid>
            <Grid item className="quotes_boxes" xs={5} md={3} lg={1.5}>
              <h6>Approved</h6>
              <p>{QuotesStatus.APPROVED}</p>
            </Grid>
            <Grid item className="quotes_boxes" xs={5} md={3} lg={1.5}>
              <h6>Converted</h6>
              <p>{QuotesStatus.CONVERTED}</p>
            </Grid>
            <Grid item className="quotes_boxes" xs={5} md={3} lg={1.5}>
              <h6>Archived</h6>
              <p>{QuotesStatus.ARCHIVED}</p>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={7} md={3} lg={2} mt='auto' pb={{xs: '20px', md: 0}}>
          <MDTypography useBrandingColor={'tertiary'} variant="h2" fontWeight="medium" ml={{xs: 0, lg: 3}} width={'12rem'}>
            All Quotes
          </MDTypography>
        </Grid>
        <Grid item xs={6} md={3} lg={2} ml='auto' mt={{xs: 'auto', md: 0, lg: 'auto'}}>
          <AddButton/>
        </Grid>
        <Grid item xs={6} md={3} lg={2} mt={{xs: 'auto', md: 0, lg: 'auto'}}>
          <FilterButton onClick={() => resetFilters()}/>
        </Grid>
        <Grid item xs={6} lg={2} py={{xs: 4, lg: 0}} pr={{xs: 2, lg: 0}} mr={{lg: 2, xs: 0}}>
          <MDTypography variant="h6" fontWeight="bold">
            Start Date
          </MDTypography>
          <MDInput
            fullWidth
            useBrandingColor={'secondary'}
            input={{placeholder: "Select date"}}
            type='date'
            value={StatDate}
            onChange={evt => setStatDate(evt.target.value)}
          />
        </Grid>

        <Grid item xs={6} lg={2} py={{xs: 4, lg: 0}}>
          <MDTypography variant="h6" fontWeight="bold">
            End Date
          </MDTypography>
          <MDInput
            fullWidth
            useBrandingColor={'secondary'}
            input={{placeholder: "Select date"}}
            type="date"
            value={EndDate}
            onChange={evt => setEndDate(evt.target.value)}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} ml={3} mt={2}>
          <SimpleTable data={Data} emptyLabelText={'No quotes found'}/>
        </Grid>
      </Grid>
    </AdminLayout>
  )
}

export default observer(Quotes)
