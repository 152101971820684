import * as React from 'react';
import moment from 'moment';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {TimePicker} from '@mui/x-date-pickers/TimePicker';
import {DateTimePicker} from '@mui/x-date-pickers/DateTimePicker';
import {DesktopDatePicker} from '@mui/x-date-pickers/DesktopDatePicker';
import {MobileDatePicker} from '@mui/x-date-pickers/MobileDatePicker';
import MDInput from "../MDInput";

export default function MDDatesPicker(props) {
  const {date, maxdate, key} = props;
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState('');
  const handleChange = (newValue) => {
    setValue(newValue);
  };


  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Stack spacing={3}>
        <DesktopDatePicker
          key={key}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          maxDate={maxdate}
          value={value}
          onChange={handleChange}
          placeholder={props.placeholder}
          renderInput={(params) =>
            <MDInput
              {...params}
              error={props.error}
              placeholder={props.placeholder}
              onClick={(e) => setOpen(true)}
              InputProps={{
                ...params.InputProps,
                placeholder: props.placeholder
              }}
              white={props.white}
            />}
        />
      </Stack>
    </LocalizationProvider>
  );
}