import React, { useEffect, useState } from "react";
import { Grid } from '@mui/material';
import AdminLayout from "../../../components/AdminLayout";
import { useNavigate } from "react-router-dom";
import MDButton from "../../../components/MDButton";
import MDTypography from "../../../components/MDTypography";
import MDSwitch from "../../../components/MDSwitch";
import MDBox from "components/MDBox";

import appontments_true from "assets/images/client_hub/appontments_true.png"
import appontments_false from "assets/images/client_hub/appontments_false.png"
import invoice_quote_true from "assets/images/client_hub/invoice_quote_true.png"
import invoice_quote_false from "assets/images/client_hub/invoice_quote_false.png"
import { useStores } from "models";
import { showMessage } from "services/helpers";

import "../styles.css"

const options = [
    {
        title: 'Menu visibility',
        selected_options: [appontments_true, appontments_false],
        description: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod...'
    },
    {
        title: 'Quote approval',
        selected_options: [invoice_quote_true, invoice_quote_false],
        description: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod...'
    },
    {
        title: 'Appointments',
        selected_options: [appontments_true, appontments_false],
        description: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod...'
    },
    {
        title: 'Client can request changes or ask a question',
        selected_options: [invoice_quote_true, invoice_quote_false],
        description: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod...'
    }
]

export default function ClientHubDetail(props) {
    const navigate = useNavigate();
    const rootStore = useStores()
    const { loginStore } = rootStore;
    const id = loginStore?.getCompany?.id

    const [Data, setData] = useState([]);

    const toggleSwitch = (key, value) => {
        let temp = [...Data]
        temp[key].selected = value
        setData(temp)
    }

    const updateCompanyClientHub = () => {
        let data = {}

        data['id'] = id
        Object.values(Data).map(d => { data[d.key] = d.selected })

        loginStore.environment.api.updateCompany(data).then((result) => {
            if (result.kind === 'ok') {
                loginStore.setCompany(result.response)
                showMessage('The client hub has been updated', 'success')
            } else showMessage(result?.errors, 'error', true)
        })
    }

    const previewBox = (data, key) => {

        return (
            <React.Fragment key={key}>
                <Grid item xs={5} lg={5} md={5} mt={3}>
                    <MDTypography variant="h5">
                        {data.title}
                    </MDTypography>
                    <MDTypography variant="h6">
                        {data.description}
                    </MDTypography>
                </Grid>
                <Grid item xs={1} lg={1} md={1} mt={3}>
                    <MDSwitch type='ios' checked={data.selected}
                        onChange={e => toggleSwitch(key, e.target.checked)} />
                </Grid>
                <Grid item xs={6} lg={6} md={6}>
                    <MDTypography variant="h5" mb={2}>
                        Preview
                    </MDTypography>
                    <MDBox
                        style={{
                            backgroundColor: '#FFF5F3',
                            width: '100%',
                            border: '1px solid #DD816B',
                            borderRadius: 8,
                            alignItems: 'center',
                            justifyContent: 'center',
                            display: 'flex'
                        }}
                    >
                        <MDBox
                            style={{ margin: 10 }}
                            component={'img'}
                            src={data.selected ? data.selected_options[0] : data.selected_options[1]}
                        />
                    </MDBox>
                </Grid>
            </React.Fragment>
        )
    }

    useEffect(() => {
        let data = [...options]
        data[0].selected = loginStore?.getCompany?.client_hub_1 || false
        data[0].key = 'client_hub_1'

        data[1].selected = loginStore?.getCompany?.client_hub_2 || false
        data[1].key = 'client_hub_2'

        data[2].selected = loginStore?.getCompany?.client_hub_3 || false
        data[2].key = 'client_hub_3'

        data[3].selected = loginStore?.getCompany?.client_hub_4 || false
        data[3].key = 'client_hub_4'

        setData(data)
    }, []);

    return (
        <AdminLayout title={"Client Hub"}>
            <Grid container alignItems="top" paddingTop={0} marginTop={0} spacing={3}>
                <Grid item xs={12} lg={12} md={12} mb={2}>
                    <MDTypography variant="h2" fontWeight="medium" mb={1} ml={3} style={{ float: 'left' }}>
                        Clients Hub
                    </MDTypography>
                </Grid>
            </Grid>
            <Grid container spacing={3} pb={3} alignItems={'center'} justifyContent={'center'} className="task_container">
                {Data.map((d, key) => previewBox(d, key))}

                <Grid item xs={4} lg={4} md={4} pb={3} mt={3}>
                    <MDButton
                        color="green"
                        type="submit"
                        className={'btn-save'}
                        fullWidth
                        onClick={() => updateCompanyClientHub()}
                    >
                        SAVE
                    </MDButton>
                </Grid>
            </Grid>
        </AdminLayout>
    )
}
