import * as Yup from "yup";

export const invoiceValidationSchema =
  Yup.object().shape({
    title: Yup.string().required(),
    invoice_for: Yup.object().required('A client is required').typeError('A client is required'),
    invoice_products: Yup.array().of(Yup.object().shape(
      {
        product: Yup.object(),
        quantity: Yup.string(),
        total: Yup.string(),
      }
    ).required('asdasdasdasd')),
    invoice_custom: Yup.array().of(Yup.object().shape(
      {
        custom: Yup.string(),
        quantity: Yup.string(),
        total: Yup.string(),
      }
    )),
    note_for_client: Yup.string(),
    // subtotal: Yup.string().required(),
    // total: Yup.string().required(),
    discount: Yup.string().required(),
  })

export const initialInvoiceProduct = [
  {
    product: {},
    quantity: 0,
    total: 0,
    price: 0,
    subtotal: true,
    recommended: false
  }
]

export const initialInvoiceMatrix = [
  {
    custom: '',
    quantity: 0,
    total: 0,
    price: 0,
    subtotal: true,
    recommended: false
  }
]

export const calculateMatrixPrice = (matrix) => {
  let aux = 0;
  if (matrix?.matrix_bundles && Array.isArray(matrix?.matrix_bundles)) {
    matrix.matrix_bundles.map(bundle => {
      if (bundle.bundle_items && Array.isArray(bundle.bundle_items)) {
        bundle.bundle_items.map(item => {
          let amount = item.amount || 1
          let each = item.each || 1
          let default_price = item?.product?.default_price || 0
          aux += ((1 / each * amount) * default_price)
        })
      }
    })
  }
  return parseInt(aux)
}

export const invoiceInitialValues = (data = null) => {

  let invoice_products = []
  let invoice_custom = []

  if (data && Array.isArray(data?.invoice_details)) {
    data.invoice_details.map(det => {
      let temp = { ...det }
      if (temp?.product?.id) {
        temp.product.type = 'product'
        temp.price = temp?.product?.default_price || 1
        temp.total = parseInt(temp?.product?.default_price || 1) * parseInt(temp.quantity)
        invoice_products.push(temp)
      } else if (temp?.matrix?.id) {
        temp.product = temp.matrix
        temp.product.type = 'matrix'
        temp.price = calculateMatrixPrice(temp?.product) || 1
        temp.total = calculateMatrixPrice(temp?.product) * parseInt(temp.quantity)
        invoice_products.push(temp)
      } else if (temp?.custom && temp.custom !== null) invoice_custom.push(temp)
    })
  }

  let arr = {
    id: data ? data.id : null,
    title: data ? data.title : "",
    invoice_for: data ? data.invoice_for : null,
    tax: data ? data.tax : null,
    invoice_products: invoice_products.length === 0 ? [...initialInvoiceProduct] : invoice_products,
    invoice_custom: invoice_custom.length === 0 ? [...initialInvoiceMatrix] : invoice_custom,
    note_for_client: data ? data.note_for_client : "",
    // subtotal: data ? data.subtotal : null,
    internal_note: data ? data.internal_note : '',
    total: data ? data.total : null,
    discount: data ? data.discount : 0,
    required_deposit: data ? data.required_deposit : 0,
    required_deposit_percentage: data ? data.required_deposit_percentage : false,
  }
  return arr
}