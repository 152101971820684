import { money_fmt, padToFour, truncate } from "../../services/helpers";
import MDButton from "../../components/MDButton";
import MDBadge from "../../components/MDBadge";
import moment from "moment";
import { Link } from "react-router-dom";

export const dataTableModel = {
  columns: [
    { Header: "Number", accessor: "id" },
    { Header: "Name(A-Z)", accessor: "client_name" },
    { Header: "Date", accessor: "created_at" },
    { Header: "Title", accessor: "title" },
    { Header: "Status", accessor: "status" },
    { Header: "Subtotal", accessor: "subtotal" },
    { Header: "Discount", accessor: "discount" },
    { Header: "Total", accessor: "total" },
    { Header: "", accessor: "action", sorted: false },
  ],
  rows: []
}

const nameLinkBox = (name, link) => (
  <Link to={link} className='base-item link-item' >
    {name}
  </Link>
)


const badgeBoxStatus = (item) => {
  if (item.status === 'Draft') {
    return <MDBadge variant="contained" color="dark" badgeContent={item.status} container />
  } else if (item.status === 'Approved' || item.status === 'Converted') {
    return <MDBadge variant="contained" color="success" badgeContent={item.status} container />
  } else if (item.status === 'Archived') {
    return <MDBadge variant="contained" color="error" badgeContent={item.status} container />
  } else {
    return <MDBadge variant="contained" color="warning" badgeContent={item.status} container />
  }
}
export const renderTableRow = (item) => {
  item.pk = item.id
  item.id = nameLinkBox("QN" + padToFour(item.id), `/quotes/edit/${item.id}`)
  item.created_at = moment(item.created_at).format('MMMM Do YYYY, hh:mm A')
  item.title = truncate(item.title, 15)
  item.status = badgeBoxStatus(item)
  item.client_name = nameLinkBox(item.client_name, `/clients/profile/${item.client_id}`)
  item.subtotal = money_fmt(item.subtotal)
  item.discount = money_fmt(item.discount)
  item.total = money_fmt(item.total)

  return item
}
