import * as Yup from "yup";
import { money_fmt} from "services/helpers";

const matrixTypes = [
    { label: 'Single', value: 'Single' },
    { label: 'Box', value: 'Box' },
    { label: 'Asembly', value: 'Asembly' },
    { label: 'Non-Inventory', value: 'Non-Inventory' }
]

export const bundleValidationSchema =
    Yup.object().shape({
        name: Yup.string().required(),
        description: Yup.string().required(),
        bundle: Yup.array().min(1, "at least 1").of(Yup.object().shape(
            {
                name: Yup.string(),
                boolean: Yup.boolean(),
                bundle_items: Yup.array().of(Yup.object().shape(
                    {
                        product_id: Yup.object(),
                        matrix_id: Yup.object(),
                        each: Yup.string(),
                        amount: Yup.string(),
                        round_up: Yup.boolean(),
                    }
                ))
            }
        )),
    })

export const initialBundle = {
    name: '',
    boolean: false,
    bundle_items: []
}

export const bundleInitialValues = (data = null) => {
    let bundles = [];
    if (data) {
        bundles = data.matrix_bundles.map(c => (
            {
                ...c,
                name: c.name,
                description: c.description,
                bundle_items: c.bundle_items.map(i => (
                    {
                        ...i,
                        product_id: i.product || {},
                        matrix_id: i.matrix || {}
                    }
                ))
            }
        ))
    }

    return ({
        id: data ? data?.id : null,
        name: data ? data?.name : "",
        description: data ? data?.description : '',
        type: data?.type ? matrixTypes.find(t => t.value === data?.type) : null,
        bundle: data ? bundles : [initialBundle],
    })
}

export const itemInitialValues = (itemInfo) => {
    let values = {
        id: itemInfo && itemInfo?.id,

        average_cost: itemInfo ? money_fmt(itemInfo?.product_inventory_values?.average_cost) : '',
        in_stock: itemInfo ? itemInfo?.product_inventory_values?.in_stock : '',
        total_cost_value: itemInfo ? money_fmt(itemInfo?.product_inventory_values?.total_cost_value) : '',
        total_inventory_value: itemInfo ? money_fmt(itemInfo?.product_inventory_values?.total_inventory_value) : '',

        name: itemInfo ? itemInfo.name : '',
        description: itemInfo ? itemInfo?.description : '',
        default_price: itemInfo ? itemInfo?.default_price : '',
        markup: itemInfo ? itemInfo?.markup : '',
        margin: itemInfo ? itemInfo?.margin : '',

        category: itemInfo ? itemInfo?.category : '',
        tags: itemInfo ? itemInfo?.tags : '',
        ucp_code: itemInfo ? itemInfo?.ucp_code : '',


        default_cost: itemInfo ? itemInfo?.default_cost : '',
        preferred_vendor: itemInfo ? itemInfo?.preferred_vendor : '',
        desired_inventory_level: itemInfo ? itemInfo?.desired_inventory_level : '',
        item_location: itemInfo && itemInfo?.item_location || '',
    }

    if (itemInfo?.serial_number) values.serial_number = itemInfo?.serial_number

    return values
}

export const itemValidationSchema = {

    // item
    name: Yup.string('').required(''),
    description: Yup.string('').required(''),
    default_price: Yup.string('').required(''),
    markup: Yup.string('').required(''),
    margin: Yup.string('').required(''),

    // organize
    // category: Yup.object(),
    //serial number
    tags: Yup.string(''),
    ucp_code: Yup.string('').required(''),

    // inventory defaults
    default_cost: Yup.string('').required(''),
    preferred_vendor: Yup.string(''),
    // vendor
    desired_inventory_level: Yup.string(''),
    item_location: Yup.string(''),

    // add inventory
    inventory: Yup.object().shape({
        quantity: Yup.string(''),
        default_cost: Yup.string(''),
        category: Yup.string(''),
    })

}