import { useEffect, useState } from "react";
import moment from 'moment'
import { Grid, InputAdornment, IconButton } from '@mui/material';
import AdminLayout from "../../../components/AdminLayout";
import { useNavigate } from "react-router-dom";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import MDInput from "../../../components/MDInput";
import MDButton from "../../../components/MDButton";
import MDDatePicker from "../../../components/MDDatePicker";
import {Header} from "../components/Header";
import {Tables} from "../components/Tables";
import {DateFilter} from "../components/DateFilter";
import "../styles.css"

const header = [
    {title: 'Name (A-Z)', column: 'name', align: '', id: 1},
    {title: 'Property Address', column: 'created', align: '', id: 2},
    {title: 'Drafted', column: 'title', align: '', id: 3},
    {title: 'Sent', column: 'start', align: '', id: 4},
    {title: 'Converted', column: 'end', align: '', id: 5},
    {title: 'Jobs', column: 'asigned', align: '', id: 6},
    {title: 'Total', column: 'completed', align: '', id: 7}
];
const rows = [
    {number: 'Jane Cooper', name: 'Lorem Ipsum', created: 'Lorem Ipsum', due: 'Lorem Ipsum', total: 'Lorem Ipsum', asigned: 'Lorem Ipsum', completed: '$0000', id: 1},
    {number: 'Jane Cooper', name: 'Lorem Ipsum', created: 'Lorem Ipsum', due: 'Lorem Ipsum', total: 'Lorem Ipsum', asigned: 'Lorem Ipsum', completed: '$0000', id: 2},
    {number: 'Jane Cooper', name: 'Lorem Ipsum', created: 'Lorem Ipsum', due: 'Lorem Ipsum', total: 'Lorem Ipsum', asigned: 'Lorem Ipsum', completed: '$0000', id: 3},
    {number: 'Jane Cooper', name: 'Lorem Ipsum', created: 'Lorem Ipsum', due: 'Lorem Ipsum', total: 'Lorem Ipsum', asigned: 'Lorem Ipsum', completed: '$0000', id: 4},
    {number: 'Jane Cooper', name: 'Lorem Ipsum', created: 'Lorem Ipsum', due: 'Lorem Ipsum', total: 'Lorem Ipsum', asigned: 'Lorem Ipsum', completed: '$0000', id: 5},
    {number: 'Jane Cooper', name: 'Lorem Ipsum', created: 'Lorem Ipsum', due: 'Lorem Ipsum', total: 'Lorem Ipsum', asigned: 'Lorem Ipsum', completed: '$0000', id: 6},
    {number: 'Jane Cooper', name: 'Lorem Ipsum', created: 'Lorem Ipsum', due: 'Lorem Ipsum', total: 'Lorem Ipsum', asigned: 'Lorem Ipsum', completed: '$0000', id: 7},
    {number: 'Jane Cooper', name: 'Lorem Ipsum', created: 'Lorem Ipsum', due: 'Lorem Ipsum', total: 'Lorem Ipsum', asigned: 'Lorem Ipsum', completed: '$0000', id: 8},
  ];

const headers = [
    {title: 'Drafts', quantity: '0', content: '$000', class:'', id: 1},
    {title: 'Awaiting response', quantity: '1', content: '$000', id: 2},
    {title: 'Changes requested', quantity: '3', content: '$000', class: '', id: 3},
    {title: 'Approved', quantity: '3', content: '$000', class: '', id: 4},
    {title: 'Converted', quantity: '3', content: '$000', class: '', id: 5},
    {title: 'Archived', quantity: '3', content: '$000', class: '', id: 6},
    {title: 'Total', quantity: '3', content: '$000', class: 'success', id: 7},
  ];

export default function ClientBalanceSummary(props) {
    const navigate = useNavigate();

  return (

    <AdminLayout title={"Quotes Report - Converted"}>
        <Grid container alignItems="top" paddingTop={0} marginTop={0}>
            <Grid item xs={12} lg={12} md={12} mb={3}>
                <MDTypography variant="h2" fontWeight="medium" mb={1} ml={3} style={{ float: 'left' }}>
                Quotes Report - Converted
                </MDTypography>
            </Grid>
           
            <Grid item xs={12} lg={12} md={12} mb={3}>
                <Header data={headers} styles={{ width: 137}}/>
            </Grid>
        </Grid>
    <Grid container spacing={3}>
        <Grid item xs={12} lg={12} md={12} ml={2}>
            <MDTypography variant="h3" fontWeight="medium" mb={1} style={{ float: 'left' }}>
                List
            </MDTypography>
            <DateFilter style={{ float: 'right', display: 'inline', marginBottom: 10 }} />
            <Tables body={rows} header={header}/>
        </Grid>
    </Grid>
    </AdminLayout>
  )
}
