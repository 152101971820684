import { useEffect, useState } from "react";
import moment from 'moment'
import { Grid, InputAdornment, IconButton } from '@mui/material';
import AdminLayout from "../../../components/AdminLayout";
import { useNavigate } from "react-router-dom";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import MDInput from "../../../components/MDInput";
import MDButton from "../../../components/MDButton";
import MDDatePicker from "../../../components/MDDatePicker";
import {Header} from "../components/Header";
import {Tables} from "../components/Tables";
import {DateFilter} from "../components/DateFilter";
import "../styles.css"

const header = [
    {title: 'Name (A-Z)', align: '', id: 1},
    {title: 'Paid to date', align: '', id: 2},
    {title: 'Invoiced', align: '', id: 3},
    {title: 'Phone', align: '', id: 4},
    {title: 'Email', align: '', id: 5}
];
const rows = [
    {number: 'Jane Cooper', name: '0000', created: '0000', due: 'Lorem Ipsum', status: 'Lorem Ipsum', id: 1},
    {number: 'Jane Cooper', name: '0000', created: '0000', due: 'Lorem Ipsum', status: 'Lorem Ipsum', id: 2},
    {number: 'Jane Cooper', name: '0000', created: '0000', due: 'Lorem Ipsum', status: 'Lorem Ipsum', id: 3},
    {number: 'Jane Cooper', name: '0000', created: '0000', due: 'Lorem Ipsum', status: 'Lorem Ipsum', id: 4},
    {number: 'Jane Cooper', name: '0000', created: '0000', due: 'Lorem Ipsum', status: 'Lorem Ipsum', id: 5},
    {number: 'Jane Cooper', name: '0000', created: '0000', due: 'Lorem Ipsum', status: 'Lorem Ipsum', id: 6},
    {number: 'Jane Cooper', name: '0000', created: '0000', due: 'Lorem Ipsum', status: 'Lorem Ipsum', id: 7},
    {number: 'Jane Cooper', name: '0000', created: '0000', due: 'Lorem Ipsum', status: 'Lorem Ipsum', id: 8},
  ];

const headers = [
    {title: ' Clients with credit', quantity: '0', content: '$000', class:'', id: 1},
    {title: 'Clients with outstanding balance', quantity: '1', content: '$000', id: 2},
    {title: '', quantity: '', content: '', class: 'empty', id: 3},
    {title: '', quantity: '', content: '', class: 'empty', id: 4},
    {title: '', quantity: '', content: '', class: 'empty', id: 5},
  ];

export default function ClientBalanceSummary(props) {
    const navigate = useNavigate();

  return (

    <AdminLayout title={"Client Balance Summary"}>
        <Grid container alignItems="top" paddingTop={0} marginTop={0}>
            <Grid item xs={12} lg={12} md={12} mb={3}>
                <MDTypography variant="h2" fontWeight="medium" mb={1} ml={3} style={{ float: 'left' }}>
                Client Balance Summary
                </MDTypography>
            </Grid>
           
            <Grid item xs={12} lg={12} md={12} mb={3}>
                <Header data={headers} styles={{ width: 200}}/>
            </Grid>
        </Grid>
    <Grid container spacing={3}>
        <Grid item xs={12} lg={12} md={12} ml={2}>
            <MDTypography variant="h3" fontWeight="medium" mb={1} style={{ float: 'left' }}>
                List
            </MDTypography>
            <DateFilter style={{ float: 'right', display: 'inline', marginBottom: 10 }} range={false}/>
            <Tables body={rows} header={header}/>
        </Grid>
    </Grid>
    </AdminLayout>
  )
}
