import * as Yup from "yup";

export const jobValidationSchema =
    Yup.object().shape({
        title: Yup.string().required(),
    })

export const jobInitialValues = (data = null) => ({
    id: data ? data?.id : null,
    title: data ? data?.title : "",
    quote: data ? data?.quote : null,
    start_date: data ? data?.start_date?.replace(':00Z', '') : null,
    end_date: data ? data?.end_date?.replace(':00Z', '') : null,
    assign_to: data ? data?.assign_to : null,
    assign_to_all: data ? data?.assign_to_all : false,
})


export const getTaskInitialValues = (data) => ({
    title: data ? data?.title : '',
    task_for: data ? data?.task_for : null,
    job: data ? data?.job : null,
    start_date: data ? data?.start_date?.replace(':00Z', '') : null,
    end_date: data ? data?.end_date?.replace(':00Z', '') : null,
    assign_to: data ? data?.assign_to : null,
    instructions: data ? data?.instructions : '',
    repeats: data ? data?.repeats : '',
    reminders: data ? data?.reminders : '',
});
export const getEventInitialValues = (data) => ({
    title: data ? data?.title : '',
    start_date: data ? data?.start_date?.replace(':00Z', '') : null,
    end_date: data ? data?.end_date?.replace(':00Z', '') : null,
    instructions: data ? data?.instructions : '',
    repeats: data ? data?.repeats : '',
});

export const eventValidationSchemaTask = Yup.object().shape({
    title: Yup.string().required('Title is required'),
    instructions: Yup.string().required('Instructions is required'),
    start_date: Yup.string().required('Start Date is required'),
    end_date:Yup.string().required('End Date is required'),
    repeats: Yup.object().required('Repeats is required'),
});
export const taskValidationSchemaTask = Yup.object().shape({
    title: Yup.string().required(),
    task_for: Yup.object().required('A client is required').typeError('A client is required'),
});
