
// Custom pages
import SchedulerPage from "screens/scheduler";
import InitialPage from "screens/initial";
import SignupPage from "screens/signup";
import LoginPage from "screens/login";
import HomePage from "screens/home";
import RequestPage from "./screens/requests";
import RequestNewPage from "./screens/requests/create";
import QuotesPage from "./screens/quotes";
import QuotesNewPage from "./screens/quotes/create";
import CreateTaskPage from "./screens/scheduler/create/Task";
import CreateEventPage from "./screens/scheduler/create/Event";
import CreateJobPage from "./screens/scheduler/create/Job";
import ClientsPage from "./screens/clients";
import ClientsNewPage from "./screens/clients/create";
// Clients pages
import ClientsProfilePage from "./screens/clients/profile";
import ClientsRequestOpenPage from "./screens/clients/pages/RequestOpen";
import ClientsQuoteDetailsPage from "./screens/clients/pages/QuoteDetails";
import InvoicesPage from "./screens/invoices";
import InvoicesNewPage from "./screens/invoices/create";
import Messages from "./screens/messages";

// Job
import JobForm from "./screens/jobs/JobForm"
import ClientsJobDetailsPage from "./screens/clients/pages/JobDetails";
import JobsPage from "./screens/jobs";

// Inventory items
import InventoryPage from "./screens/inventory";
import CreateMatix from "./screens/inventory/pages/CreateMatrix"
import CreateItem from "./screens/inventory/pages/CreateItem"
import CreateImport from "./screens/inventory/pages/CreateImport"

import TimesheetsPage from "./screens/timesheets";
import ReportsPage from "./screens/reports";
// Reports pages
import OneOffJobsPage from "./screens/reports/pages/OneOffJobs";
import ReccuringJobsPage from "./screens/reports/pages/ReccuringJobs";
import ReportsProjectIncomePage from "./screens/reports/pages/ProjectIncome";
import ReportsSchedulePage from "./screens/reports/pages/Schedule";
import ReportsDailyTransactionPage from "./screens/reports/pages/DailyTransaction";
import ReportsSalesPage from "./screens/reports/pages/Sales";
import ReportsOpenOrderPage from "./screens/reports/pages/OpenOrder";
import ReportsSelectedSalesPage from "./screens/reports/pages/SelectedSales";
import ReportsSalesRepPage from "./screens/reports/pages/SalesRep";
import ReportsSpecialOrderPage from "./screens/reports/pages/SpecialOrder";
import ReportsSalesTaxPage from "./screens/reports/pages/SalesTax";
import TransactionListPage from "./screens/reports/pages/TransactionList";
import InvoicesReportPage from "./screens/reports/pages/Invoices";
import TaxationPage from "./screens/reports/pages/Taxation";
import AgedReceivablesPage from "./screens/reports/pages/AgedReceivables";
import BadDebtPage from "./screens/reports/pages/BadDebt";
import ClientBalanceSummaryPage from "./screens/reports/pages/ClientBalanceSummary";
import VisitsReportPage from "./screens/reports/pages/VisitsReport";
import QuotesCreatedPage from "./screens/reports/pages/QuotesCreated";
import QuotesConvertedPage from "./screens/reports/pages/QuotesConverted";
import ProductsServiceReportPage from "./screens/reports/pages/ProductsServiceReport";
import WaypointsPage from "./screens/reports/pages/Waypoints";
import ClientCommunicationsPage from "./screens/reports/pages/ClientCommunications";
import FollowupEmailsReportPage from "./screens/reports/pages/FollowupEmailsReport";
import ClientContactInfoPage from "./screens/reports/pages/ClientContactInfo";
import ClientReEngagementPage from "./screens/reports/pages/ClientReEngagement";
import ExpensesPage from "./screens/expenses";
import NotificationsPage from "./screens/notifications";
import SettingsPage from "./screens/settings";

// Settings pages
import CompanySettingsPage from "./screens/settings/pages/Company";
import BrandingSettingsPage from "./screens/settings/pages/Branding";
import TeamSettingsPage from "./screens/settings/pages/Team";
import SettingsSubscriptions  from "./screens/settings/pages/Subscriptions";
import ClientHub from "./screens/settings/pages/ClientsHub";
import ClientHubDetail from "./screens/settings/pages/ClientsHubDetail";
import CreateUser from "./screens/settings/pages/CreateUser";

// Admin routes
import AdminAnalytics from "./screens/admin/pages/Analitycs";
import AdminUsers from "./screens/admin/pages/Users";
import AdminUserDetails from "./screens/admin/pages/UserDetails";
import AdminApproveAccounts from "./screens/admin/pages/ApproveAccounts";
import AdminApproveAccountsDetails from "./screens/admin/pages/ApproveAccountsDetails";
import AdminSubscriptions from "./screens/admin/pages/Subscriptions";

// Client routes
import Appointments from "./screens/client_pages/pages/Appointments";
import ClientRequests from "./screens/client_pages/pages/Requests";
import ClientCreateRequest from "./screens/client_pages/pages/Requests/create"
import ClientQuotes from "./screens/client_pages/pages/Quotes";
import ClientInvoices from "./screens/client_pages/pages/Invoices";
import ContactUs from "./screens/client_pages/pages/ContactUs";
import Wallet from "./screens/client_pages/pages/Wallet";
import Subscriptions from "./screens/client_pages/pages/Subscriptions";

import {ROLES, ROUTES} from "./constants";


const routes = [
  {
    type: "collapse",
    name: "Initial",
    key: "initial",
    route: ROUTES.ROOT,
    component: <InitialPage />,
  },
  {
    type: "collapse",
    name: "Signup",
    key: "signup",
    route: ROUTES.SIGNUP,
    component: <SignupPage />,
  },
  {
    type: "collapse",
    name: "Login",
    key: "login",
    route: ROUTES.LOGIN,
    component: <LoginPage />,
  },
  {
    name: "Home",
    key: "home",
    route: ROUTES.HOME,
    routeRole: ROLES.SUPERVISOR,
    protected: true,
    component: <HomePage />,
  },
  {
    type: "collapse",
    name: "Requests",
    key: "Requests",
    route: ROUTES.REQUESTS,
    routeRole: ROLES.SUPERVISOR,
    protected: true,
    component: <RequestPage />,
  },
  {
    type: "collapse",
    name: "Request/Create",
    key: "Requests",
    route: ROUTES.NEW_REQUESTS,
    routeRole: ROLES.SUPERVISOR,
    protected: true,
    component: <RequestNewPage />,
  },
  {
    type: "collapse",
    name: "Request/Edit",
    key: "clients",
    route: ROUTES.REQUEST_EDIT(),
    routeRole: ROLES.SUPERVISOR,
    protected: true,
    component: <RequestNewPage />,
  },
  {
    type: "collapse",
    name: "Quotes",
    key: "Quotes",
    route: ROUTES.QUOTES,
    routeRole: ROLES.SUPERVISOR,
    protected: true,
    component: <QuotesPage />,
  },
  {
    type: "collapse",
    name: "Quotes/Create",
    key: "Quotes",
    route: ROUTES.NEW_QUOTES,
    routeRole: ROLES.SUPERVISOR,
    protected: true,
    component: <QuotesNewPage />,
  },
  {
    type: "collapse",
    name: "Quote/Edit",
    key: "Quotes",
    route: ROUTES.QUOTE_EDIT(),
    routeRole: ROLES.SUPERVISOR,
    protected: true,
    component: <QuotesNewPage />,
  },
  {
    type: "collapse",
    name: "Scheduler",
    key: "scheduler",
    route: ROUTES.SCHEDULER,
    routeRole: ROLES.SUPERVISOR,
    protected: true,
    component: <SchedulerPage />,
  },
  {
    type: "collapse",
    name: "Scheduler",
    key: "scheduler",
    route: ROUTES.NEW_TASK(),
    routeRole: ROLES.SUPERVISOR,
    protected: true,
    component: <CreateTaskPage />,
  },
  {
    type: "collapse",
    name: "Scheduler",
    key: "scheduler",
    route: ROUTES.EDIT_TASK(),
    routeRole: ROLES.SUPERVISOR,
    protected: true,
    component: <CreateTaskPage />,
  },
  {
    type: "collapse",
    name: "Scheduler",
    key: "scheduler",
    route: ROUTES.NEW_EVENT,
    routeRole: ROLES.SUPERVISOR,
    protected: true,
    component: <CreateEventPage />,
  },
  {
    type: "collapse",
    name: "Scheduler",
    key: "scheduler",
    route: ROUTES.EDIT_EVENT(),
    routeRole: ROLES.SUPERVISOR,
    protected: true,
    component: <CreateEventPage/>
  },
  {
    type: "collapse",
    name: "Scheduler",
    key: "jobs",
    route: ROUTES.NEW_JOB,
    routeRole: ROLES.SUPERVISOR,
    protected: true,
    component: <CreateJobPage />,
  },
  {
    type: "collapse",
    name: "Scheduler",
    key: "jobs",
    route: ROUTES.EDIT_JOB(),
    routeRole: ROLES.SUPERVISOR,
    protected: true,
    component: <CreateJobPage />,
  },
  {
    type: "collapse",
    name: "Clients",
    key: "clients",
    route: ROUTES.CLIENTS,
    routeRole: ROLES.SUPERVISOR,
    protected: true,
    component: <ClientsPage />,
  },
  {
    type: "collapse",
    name: "Clients",
    key: "clients",
    route: ROUTES.CLIENTS_NEW,
    routeRole: ROLES.SUPERVISOR,
    protected: true,
    component: <ClientsNewPage />,
  },
  {
    type: "collapse",
    name: "Clients",
    key: "clients",
    route: ROUTES.CLIENTS_EDIT(),
    routeRole: ROLES.SUPERVISOR,
    protected: true,
    component: <ClientsNewPage />,
  },
  // Clients Pages
  {
    type: "collapse",
    name: "Clients",
    key: "clients",
    route: ROUTES.CLIENTS_PROFILE(),
    routeRole: ROLES.SUPERVISOR,
    protected: true,
    component: <ClientsProfilePage />,
  },
  {
    type: "collapse",
    name: "Clients",
    key: "clients",
    route: ROUTES.CLIENTS_REQUEST_OPEN(),
    routeRole: ROLES.SUPERVISOR,
    protected: true,
    component: <ClientsRequestOpenPage />,
  },
  {
    type: "collapse",
    name: "Clients",
    key: "clients",
    route: ROUTES.CLIENTS_QUOTE_DETAILS(),
    routeRole: ROLES.SUPERVISOR,
    protected: true,
    component: <ClientsQuoteDetailsPage />,
  },
  {
    type: "collapse",
    name: "Clients",
    key: "clients",
    route: ROUTES.CLIENTS_JOB_DETAILS(),
    routeRole: ROLES.SUPERVISOR,
    protected: true,
    component: <ClientsJobDetailsPage />,
  },
  {
    type: "collapse",
    name: "Jobs",
    key: "jobs",
    route: ROUTES.JOBS,
    routeRole: ROLES.SUPERVISOR,
    protected: true,
    component: <JobsPage />,
  },
  {
    type: "collapse",
    name: "Jobs",
    key: "jobs",
    route: ROUTES.JOB_FORM,
    routeRole: ROLES.SUPERVISOR,
    protected: true,
    component: <JobForm />,
  },
  {
    type: "collapse",
    name: "Invoices",
    key: "invoices",
    route: ROUTES.INVOICES,
    routeRole: ROLES.SUPERVISOR,
    protected: true,
    component: <InvoicesPage />,
  },
  {
    type: "collapse",
    name: "Invoices",
    key: "invoices",
    route: ROUTES.NEW_INVOICE,
    routeRole: ROLES.SUPERVISOR,
    protected: true,
    component: <InvoicesNewPage />,
  },
  {
    type: "collapse",
    name: "Invoices/Edit",
    key: "Invoices",
    route: ROUTES.INVOICE_EDIT(),
    routeRole: ROLES.SUPERVISOR,
    protected: true,
    component: <InvoicesNewPage />,
  },
  {
    type: "collapse",
    name: "Messages",
    key: "Messages",
    route: ROUTES.MESSAGES,
    routeRole: ROLES.SUPERVISOR,
    protected: true,
    component: <Messages />,
  },
  {
    type: "collapse",
    name: "Inventory",
    key: "inventory",
    route: ROUTES.INVENTORY,
    routeRole: ROLES.SUPERVISOR,
    protected: true,
    component: <InventoryPage />,
  },

  {
    type: "collapse",
    name: "Create Matrix",
    key: "create-matrix",
    route: ROUTES.MATRIX_CREATE,
    routeRole: ROLES.SUPERVISOR,
    protected: true,
    component: <CreateMatix />,
  },
  {
    type: "collapse",
    name: "Create Matrix",
    key: "create-matrix",
    route: ROUTES.MATRIX_EDIT(),
    routeRole: ROLES.SUPERVISOR,
    protected: true,
    component: <CreateMatix type={'edit'} />,
  },
  {
    type: "collapse",
    name: "Create Item",
    key: "create-item",
    route: ROUTES.ITEM_CREATE,
    routeRole: ROLES.SUPERVISOR,
    protected: true,
    component: <CreateItem />,
  },
  {
    type: "collapse",
    name: "Edit Item",
    key: "edit-item",
    route: ROUTES.ITEM_EDIT(),
    routeRole: ROLES.SUPERVISOR,
    protected: true,
    component: <CreateItem type={'edit'} />,
  },
  {
    type: "collapse",
    name: "Create Import",
    key: "create-import",
    route: ROUTES.IMPORT_CREATE,
    routeRole: ROLES.SUPERVISOR,
    protected: true,
    component: <CreateImport />,
  },

  {
    type: "collapse",
    name: "Timesheet",
    key: "timesheet",
    route: ROUTES.TIMESHEET,
    routeRole: ROLES.SUPERVISOR,
    protected: true,
    component: <TimesheetsPage />,
  },
  {
    type: "collapse",
    name: "Reports",
    key: "reports",
    route: ROUTES.REPORTS,
    routeRole: ROLES.SUPERVISOR,
    protected: true,
    component: <ReportsPage />,
  },
  {
    type: "collapse",
    name: "Reports",
    key: "reports",
    route: ROUTES.REPORTS_PROJECTED_INCOME,
    routeRole: ROLES.SUPERVISOR,
    protected: true,
    component: <ReportsProjectIncomePage />,
  },
  {
    type: "collapse",
    name: "Reports",
    key: "reports",
    route: ROUTES.REPORTS_TRANSACTION_LIST,
    routeRole: ROLES.SUPERVISOR,
    protected: true,
    component: <TransactionListPage />,
  },
  {
    type: "collapse",
    name: "Reports",
    key: "reports",
    route: ROUTES.REPORTS_INVOICES,
    routeRole: ROLES.SUPERVISOR,
    protected: true,
    component: <InvoicesReportPage />,
  },
  {
    type: "collapse",
    name: "Reports",
    key: "reports",
    route: ROUTES.REPORTS_TAXATION,
    routeRole: ROLES.SUPERVISOR,
    protected: true,
    component: <TaxationPage />,
  },
  {
    type: "collapse",
    name: "Reports",
    key: "reports",
    route: ROUTES.REPORTS_AGED_RECEIVABLES,
    routeRole: ROLES.SUPERVISOR,
    protected: true,
    component: <AgedReceivablesPage />,
  },
  {
    type: "collapse",
    name: "Reports",
    key: "reports",
    route: ROUTES.REPORTS_BAD_DEBT,
    routeRole: ROLES.SUPERVISOR,
    protected: true,
    component: <BadDebtPage />,
  },
  {
    type: "collapse",
    name: "Reports",
    key: "reports",
    route: ROUTES.REPORTS_CLIENT_BALANCE_SUMMARY,
    routeRole: ROLES.SUPERVISOR,
    protected: true,
    component: <ClientBalanceSummaryPage />,
  },
  {
    type: "collapse",
    name: "Reports",
    key: "reports",
    route: ROUTES.REPORTS_VISITS_REPORT,
    routeRole: ROLES.SUPERVISOR,
    protected: true,
    component: <VisitsReportPage />,
  },
  {
    type: "collapse",
    name: "Reports",
    key: "reports",
    route: ROUTES.REPORTS_ONE_OFF_JOBS,
    routeRole: ROLES.SUPERVISOR,
    protected: true,
    component: <OneOffJobsPage />,
  },
  {
    type: "collapse",
    name: "Reports",
    key: "reports",
    route: ROUTES.REPORTS_RECURRING_JOBS,
    routeRole: ROLES.SUPERVISOR,
    protected: true,
    component: <ReccuringJobsPage />,
  },
  {
    type: "collapse",
    name: "Reports",
    key: "reports",
    route: ROUTES.REPORTS_QUOTES_CREATED,
    routeRole: ROLES.SUPERVISOR,
    protected: true,
    component: <QuotesCreatedPage />,
  },
  {
    type: "collapse",
    name: "Reports",
    key: "reports",
    route: ROUTES.REPORTS_QUOTES_CONVERTED,
    routeRole: ROLES.SUPERVISOR,
    protected: true,
    component: <QuotesConvertedPage />,
  },
  {
    type: "collapse",
    name: "Reports",
    key: "reports",
    route: ROUTES.REPORTS_PRODUCT_SERVICES,
    routeRole: ROLES.SUPERVISOR,
    protected: true,
    component: <ProductsServiceReportPage />,
  },
  {
    type: "collapse",
    name: "Reports",
    key: "reports",
    route: ROUTES.REPORTS_WAYPOINTS,
    routeRole: ROLES.SUPERVISOR,
    protected: true,
    component: <WaypointsPage />,
  },
  {
    type: "collapse",
    name: "Reports",
    key: "reports",
    route: ROUTES.REPORTS_CLIENT_COMMUNICATIONS,
    routeRole: ROLES.SUPERVISOR,
    protected: true,
    component: <ClientCommunicationsPage />,
  },
  {
    type: "collapse",
    name: "Reports",
    key: "reports",
    route: ROUTES.REPORTS_JOB_FOLLOWUPS_EMAILS,
    routeRole: ROLES.SUPERVISOR,
    protected: true,
    component: <FollowupEmailsReportPage />,
  },
  {
    type: "collapse",
    name: "Reports",
    key: "reports",
    route: ROUTES.REPORTS_CLIENT_CONTACT_INFO,
    routeRole: ROLES.SUPERVISOR,
    protected: true,
    component: <ClientContactInfoPage />,
  },
  {
    type: "collapse",
    name: "Reports",
    key: "reports",
    route: ROUTES.REPORTS_CLIENT_REENGAGEMENT,
    routeRole: ROLES.SUPERVISOR,
    protected: true,
    component: <ClientReEngagementPage />,
  },
  {
    type: "collapse",
    name: "Expenses",
    key: "expenses",
    route: ROUTES.EXPENSES,
    routeRole: ROLES.SUPERVISOR,
    protected: true,
    component: <ExpensesPage />,
  },
  {
    type: "collapse",
    name: "Notifications",
    key: "notifications",
    route: ROUTES.NOTIFICATIONS,
    routeRole: ROLES.SUPERVISOR,
    protected: true,
    component: <NotificationsPage />,
  },
  {
    type: "collapse",
    name: "Settings",
    key: "settings",
    route: ROUTES.SETTINGS,
    routeRole: ROLES.SUPERVISOR,
    protected: true,
    component: <SettingsPage />,
  },
  {
    type: "collapse",
    name: "Settings",
    key: "settings",
    route: ROUTES.SETTINGS_COMPANY,
    routeRole: ROLES.SUPERVISOR,
    protected: true,
    component: <CompanySettingsPage />,
  },
  {
    type: "collapse",
    name: "Settings",
    key: "settings",
    route: ROUTES.SETTINGS_BRANDING,
    routeRole: ROLES.SUPERVISOR,
    protected: true,
    component: <BrandingSettingsPage />,
  },
  {
    type: "collapse",
    name: "Settings",
    key: "settings",
    route: ROUTES.SETTINGS_TEAM,
    routeRole: ROLES.SUPERVISOR,
    protected: true,
    component: <TeamSettingsPage />,
  },
  {
    type: "collapse",
    name: "create_user",
    key: "create_user",
    routeRole: ROLES.SUPERVISOR,
    protected: true,
    route: ROUTES.SETTINGS_CREATE_USER,
    component: <CreateUser />,
  },
  {
    type: "collapse",
    name: "create_user",
    key: "create_user",
    routeRole: ROLES.SUPERVISOR,
    protected: true,
    route: ROUTES.SETTINGS_UPDATE_USER(),
    component: <CreateUser />,
  },
  {
    type: "collapse",
    name: "Settings",
    key: "settings",
    route: ROUTES.SETTINGS_SUBSCRIPTIONS,
    routeRole: ROLES.SUPERVISOR,
    protected: true,
    component: <SettingsSubscriptions />,
  },
  // Admin Routes
  {
    type: "collapse",
    name: "Client Hub",
    key: "client_hub",
    route: ROUTES.REPORTS_CLIENT_HUB,
    routeRole: ROLES.SUPERVISOR,
    protected: true,
    component: <ClientHub />,
  },
  {
    type: "collapse",
    name: "Client Hub Detail",
    key: "client_hub_detail",
    route: ROUTES.REPORTS_CLIENT_HUB_DETAIL,
    routeRole: ROLES.SUPERVISOR,
    protected: true,
    component: <ClientHubDetail />,
  },
  // New Reports
  {
    "type": "collapse",
    "name": "Schedule Reports",
    "key": "schedule-reports",
    "route": ROUTES.REPORTS_SCHEDULE,
    "routeRole": ROLES.SUPERVISOR,
    "protected": true,
    "component": <ReportsSchedulePage />
  },
  {
    "type": "collapse",
    "name": "Daily Transaction Reports",
    "key": "daily-transaction-reports",
    "route": ROUTES.REPORTS_DAILY_TRANSACTION,
    "routeRole": ROLES.SUPERVISOR,
    "protected": true,
    "component": <ReportsDailyTransactionPage />
  },
  {
    "type": "collapse",
    "name": "Sales Reports",
    "key": "sales-reports",
    "route": ROUTES.REPORTS_SALES,
    "routeRole": ROLES.SUPERVISOR,
    "protected": true,
    "component": <ReportsSalesPage />
  },
  {
    "type": "collapse",
    "name": "Open Order Reports",
    "key": "open-order-reports",
    "route": ROUTES.REPORTS_OPEN_ORDER,
    "routeRole": ROLES.SUPERVISOR,
    "protected": true,
    "component": <ReportsOpenOrderPage />
  },
  {
    "type": "collapse",
    "name": "Selected Sales Reports",
    "key": "selected-sales-reports",
    "route": ROUTES.REPORTS_SELECTED_SALES,
    "routeRole": ROLES.SUPERVISOR,
    "protected": true,
    "component": <ReportsSelectedSalesPage />
  },
  {
    "type": "collapse",
    "name": "Sales Rep Reports",
    "key": "sales-rep-reports",
    "route": ROUTES.REPORTS_SALES_REP,
    "routeRole": ROLES.SUPERVISOR,
    "protected": true,
    "component": <ReportsSalesRepPage />
  },
  {
    "type": "collapse",
    "name": "Special Order Reports",
    "key": "special-order-reports",
    "route": ROUTES.REPORTS_SPECIAL_ORDER,
    "routeRole": ROLES.SUPERVISOR,
    "protected": true,
    "component": <ReportsSpecialOrderPage />
  },
  {
    "type": "collapse",
    "name": "Sales Tax Reports",
    "key": "sales-tax-reports",
    "route": ROUTES.REPORTS_SALES_TAX,
    "routeRole": ROLES.SUPERVISOR,
    "protected": true,
    "component": <ReportsSalesTaxPage />
  },
  // admin
  {
    name: "Analytics",
    key: "analytics",
    route: ROUTES.ADMIN_ANALYTICS,
    routeRole: 'ADMIN',
    protected: true,
    component: <AdminAnalytics />,
  },
  {
    name: "Users",
    key: "users",
    routeRole: 'ADMIN',
    route: ROUTES.ADMIN_USERS,
    protected: true,
    component: <AdminUsers />,
  },
  {
    name: "User details",
    key: "user_details",
    routeRole: 'ADMIN',
    route: ROUTES.ADMIN_USERS_DETAIL(),
    protected: true,
    component: <AdminUserDetails />,
  },
  {
    name: "Approve Accounts",
    key: "approve_accounts",
    routeRole: 'ADMIN',
    route: ROUTES.ADMIN_USERS_APPROVE_ACCOUNTS,
    protected: true,
    component: <AdminApproveAccounts />,
  },
  {
    name: "Approve Accounts details",
    key: "approve_accounts_details",
    routeRole: 'ADMIN',
    route: ROUTES.ADMIN_USERS_APPROVE_ACCOUNTS_DETAILS(),
    protected: true,
    component: <AdminApproveAccountsDetails />,
  },
  {
    name: "Subscriptions",
    key: "subscriptions",
    routeRole: 'ADMIN',
    route: ROUTES.ADMIN_SUBSCRIPTIONS,
    protected: true,
    component: <AdminSubscriptions />,
  },

  // client
  {
    name: "Appointments",
    key: "Appointments",
    routeRole: ROLES.CLIENT,
    route: ROUTES.CLIENT_APPOINTMENTS,
    protected: true,
    component: <Appointments />,
  },
  {
    name: "All Request",
    key: "All Request",
    routeRole: ROLES.CLIENT,
    route: ROUTES.CLIENT_ALL_REQUEST,
    protected: true,
    component: <ClientRequests />,
  },
  {
    name: "New Request",
    key: "New Request",
    routeRole: 'CLIENT',
    route: ROUTES.CLIENT_NEW_REQUEST,
    protected: true,
    component: <ClientCreateRequest />,
  }, {
    name: "Edit Request",
    key: "Edit Request",
    routeRole: 'CLIENT',
    route: ROUTES.CLIENT_REQUEST_EDIT(),
    protected: true,
    component: <ClientCreateRequest />,
  },
  {
    name: "All Quotes",
    key: "All Quotes",
    routeRole: ROLES.CLIENT,
    route: ROUTES.CLIENT_ALL_QUOTES,
    protected: true,
    component: <ClientQuotes />,
  },
  {
    name: "All Invoices",
    key: "All Invoices",
    routeRole: ROLES.CLIENT,
    route: ROUTES.CLIENT_ALL_INVOICES,
    protected: true,
    component: <ClientInvoices />,
  },
  {
    name: "Contact Us",
    key: "Contact Us",
    routeRole: ROLES.CLIENT,
    route: ROUTES.CLIENT_CONTACT_US,
    protected: true,
    component: <ContactUs />,
  },
  {
    name: "Wallet",
    key: "Wallet",
    routeRole: ROLES.CLIENT,
    route: ROUTES.CLIENT_WALLET,
    protected: true,
    component: <Wallet />,
  },
  {
    name: "Subscriptions",
    key: "Subscriptions",
    routeRole: ROLES.CLIENT,
    route: ROUTES.CLIENT_SUBSCRIPTIONS,
    protected: true,
    component: <Subscriptions />,
  },
];

export default routes;
