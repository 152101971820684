import React, {useEffect, useState, useRef, useCallback} from "react";
import {usePubNub} from 'pubnub-react';
import moment from 'moment';


// MD Components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Components
import AdminLayout from "components/AdminLayout";
import {Chat} from './chat';
import {ChatModals} from "./modals";
import {AddButton} from "./components/AddButton";

//Assets
import NoImage from 'assets/images/icons/no_image.png';
import pxToRem from "assets/theme/functions/pxToRem";
import Icon from "@mui/material/Icon";
import "./styles.css";

// Utils
import {showMessage, useApi} from "services/helpers";
import {useIsMobile} from "services/hooks";
import {useStores} from "models";

export default function Messages() {
  const api = useApi()
  const pubnub = usePubNub();

  const rootStore = useStores();
  const {loginStore} = rootStore;
  const isMobile = useIsMobile();
  const [Users, setUsers] = useState([]);
  const [Clients, setClients] = useState([]);
  const [selectedUser, setSelectedUser] = useState(false);

  /* popover and modal states */
  const [ShowDetail, setShowDetail] = useState(false);
  const [openChatInfo, setOpenChatInfo] = useState(false);
  const [openImagePreview, setOpenImagePreview] = useState(false);
  const [selectUsersModal, setSelectUsersModal] = useState({open: false, type: 'users'});
  const [anchorElMsg, setAnchorElMsg] = useState(null);
  /* popover and modal states */

  /* chats and messages states, refs */
  const [channels, setChannels] = useState([]);
  const [chats, setChats] = useState([]);
  const [selectedChannel, setSelectedChannel] = useState({name: null, id: null, participants: []});
  const [currentChatCustomName, setCurrentChatCustomName] = useState('');
  const [messages, setMessages] = useState({});
  const [messageValue, setMessageValue] = useState('');
  const [pinnedMessage, setPinnedMessage] = useState(null);
  const [chatPublicValue, setChatPublicValue] = useState(false);
  const selectedMessage = useRef(null);
  const lastMessageRef = useRef(null);
  const lastTimeMessageRef = useRef({});
  const pinnedMessageRef = useRef(null);
  const selectedImageMessage = useRef(null);
  /* chats and messages states, refs */

  const getUsers = useCallback(() => {
    loginStore.environment.api.getUsers('').then((result) => {
      if (result.kind === "ok") {
        let users = result.data.results.map((c) => ({'value': c.id, 'label': c.name, ...c})).filter(c => c.id !== loginStore.id);
        setUsers(users);
      } else {
        if (result.kind === "bad-data") {
          showMessage(result?.errors, 'error', true)
        } else {
          showMessage('An error occurred, please try again later')
        }
      }
    })
  }, [])

  const getClients = useCallback(() => {
    loginStore.environment.api.getClients('').then((result) => {
      if (result.kind === "ok") {
        let clients = result.data.results.map((c) => ({'value': c.id, 'label': c.name, ...c})).filter(c => c.id !== loginStore.id);
        setClients(clients);
      } else {
        if (result.kind === "bad-data") {
          showMessage(result?.errors, 'error', true)
        } else {
          showMessage('An error occurred, please try again later')
        }
      }
    })
  }, [])

  const handleNewMessageClick = () => {
    setSelectUsersModal({open: !selectUsersModal.open, type: 'users'});
  }

  const handleOpenChat = (chat) => {
    if (!ShowDetail) {
      setShowDetail(!ShowDetail);
    }

    setSelectedChannel({
      name: chat.channel?.channel_name,
      id: chat.id,
      participants: chat.participants,
      isPublic: chat.isPublic,
      customName: chat.customChannelName,
    });
    scrollToLastMessage();
    localStorage.setItem('selectedChannel.name', chat.channel?.channel_name);
  }

  const handleAddParticipantsClick = () => {
    setSelectUsersModal({open: !selectUsersModal.open, type: 'participants'});
  }

  const setupPubnub = (listenerParams) => {
    const channelNames = channels.map(c => c.channel_name);
    // initial subscription and executing the function that gets the chat information
    pubnub.addListener(listenerParams);
    pubnub.subscribe({channels: channelNames});
    getChatsInformation();
  }

  const getChannelsReq = () => {
    api.getChannels('')
      .then((result) => {
        setChannels(result.data.results);
      })
      .catch(() => showMessage())
  }

  const getChatsInformation = useCallback(async () => {
    //get the messages and the last time of message read

    const channelNames = channels.map(c => c.channel_name);

    // get actions by channel, so we can get the last time that a message was read
    for (let c of channelNames) {
      pubnub.getMessageActions({channel: c}, (status, response) => {
        getLastTimeMessageRef(status, response, c)
      })
    }
    // when we have the last time message by channel, we get all the messages, because in this way we can
    // check if a message was read
    await pubnub.fetchMessages({channels: channelNames, includeMeta: true}, getMessages)
  }, [channels])

  const getLastTimeMessageRef = (status, response, channel) => {

    // get the last message datetime
    const lastMessageDateTime = moment.max(response?.data?.map(t => moment(t.actionTimetoken / 10000))).toDate();

    // update the ref of the last time message
    lastTimeMessageRef.current = {
      ...lastTimeMessageRef.current,
      [channel]: lastMessageDateTime
    };
  }

  const getMessages = useCallback((status, response) => {
    if (status.statusCode === 200) {
      const formattedMessages = {};
      /* the server response with an object where each key is a channel with an array of messages,
        so we convert the keys to an array and loop into them */
      for (let key of Object.keys(response.channels)) {
        if (Array.isArray(response.channels[key])) {
          try {
            // we loop into each key of the response that contains an array of messages
            formattedMessages[key] = response.channels[key].map(({message, timetoken}, i, arr) => {
              const timeFormatted = timetoken / 10000;
              const previousDate = moment(arr[i - 1]?.timetoken / 10000).format('L');
              const currentDate = moment(timeFormatted).format('L');
              const messageData = {
                id: i,
                from: message?.from?.id,
                from_username: message?.from?.name ?? message?.message?.from.name,
                message: message?.text,
                url: message?.url
                  ? {source: message.url.source, label: message.url.label}
                  : null,
                date: moment(timeFormatted).format('L'),
                time: moment(timeFormatted).format('HH:mm'),
                read: moment(moment(timeFormatted).toDate()).isBefore(lastTimeMessageRef.current[key]),
                pinned: message?.pinned,
                channel: key,
                timetoken,
                is_deleted: false,
              };

              // check if the current message date is different from the previous message date, if true,
              // add the date_split only in the first message of the day
              if (currentDate !== previousDate) {
                messageData['date_split'] = moment(timetoken / 10000).format('DD/MM/YYYY');
              }
              if (message?.pinned) {
                setPinnedMessage(messageData);
              }

              // check if the message contain a file (images or plain text), if true, check the filetype and get the url of the file
              if (message?.file) {
                const fileName = message.file?.name;
                const fileNameSplitExtension = fileName?.split('.');
                const fileNameSplitFrom = fileName?.split('--')[0].split('_');
                const imageExtensions = ['png', 'jpg', 'jpeg', 'svg', 'webp'];
                const isImage = imageExtensions.some(img => img.includes(fileNameSplitExtension[fileNameSplitExtension.length - 1]));
                messageData['file'] = {
                  id: message?.file?.id,
                  src: pubnub.getFileUrl({
                    channel: key,
                    id: message?.file?.id,
                    name: fileName
                  }),
                  type: isImage ? 'image' : 'file',
                  name: fileName,
                }
                messageData.from = parseInt(fileNameSplitFrom[fileNameSplitFrom.length - 1])
              }
              return messageData;
            })
          } catch (error) {
            console.error('error', error)
          }
        }
      }
      setMessages(formattedMessages);
    }
  }, [messages, Clients]);

  const markMessagesAsRead = () => {
    try {
      let lastUnreadMessage = messages[selectedChannel.name]?.filter(m => !m.read);
      if (lastUnreadMessage?.length > 0) {
        const currentTime = Date.now();
        pubnub.addMessageAction({
          channel: selectedChannel.name,
          messageTimetoken: currentTime,
          action: {
            type: 'receipt',
            value: 'read',
          }
        })
        setMessages(prev => ({
          ...prev,
          [selectedChannel.name]: messages[selectedChannel.name]?.map(m => ({...m, read: true}))
        }));
        setupChats();
      }
    } catch (error) {
      console.error('error: ', error)
    }
  }

  const setupChats = () => {

    // convert the channels to chats
    const channelsToChats = channels.map((c) => {
      const lastMessage = messages[c.channel_name]?.findLast(_ => true);
      const lastMessageTime = lastMessage?.date === moment().format('L')
        ? lastMessage?.time
        : lastMessage?.date === moment().subtract(1, 'days').format('L')
          ? 'Yesterday' : moment(lastMessage?.date).format('DD/MM/YYYY');
      const lastMessageDate = lastMessage?.date;
      return {
        id: c.id,
        lastMessage: lastMessage?.file
          ? lastMessage?.file?.type === 'image' ? 'Photo' : lastMessage?.file?.name
          : lastMessage?.message ?? lastMessage?.url?.label,
        lastMessageIsFile: !!lastMessage?.file,
        lastMessageFileType: lastMessage?.file?.type,
        lastMessageTime,
        lastMessageDate,
        unReadMessages: messages[c.channel_name]?.filter(m => (m.from !== loginStore.id && !m.read)).length,
        userImage: NoImage,
        channel: c,
        customChannelName: c.channel_custom_name
          ? c.channel_custom_name
          : c.channel_participants?.length > 2
            ? new Array(1).fill(c.channel_participants.filter(p => p.id !== loginStore.id).map(u => u.name))
            : c.channel_participants?.find(p => p.id !== loginStore.id)?.name || '',
        isPublic: c.public,
        participants: c.channel_participants.map(p => ({...p, userImage: NoImage})),
      }

    })// .filter(c => c?.lastMessage); // filter the chats that has messages

    const chatsSortedByDateTime = channelsToChats.sort((prevValue, nextValue) => {
      const prevDate = moment(`${prevValue.lastMessageDate} ${prevValue.lastMessageTime}`, 'MM/DD/YYYY hh:mm:ss a');
      const nextDate = moment(`${nextValue.lastMessageDate} ${nextValue.lastMessageTime}`, 'MM/DD/YYYY hh:mm:ss a');
      return nextDate - prevDate;
    });

    return setChats(chatsSortedByDateTime);
  }

  const startChat = (channel_name, channelId, channelIsPublic) => {
    console.log('start chat', {channel_name, channelId, channelIsPublic});
    // add a new chat in the frontend, after the creation in the backend has finished
    setShowDetail(true);
    const new_chat = {
      id: channelId ?? channels.length + 1,
      username: selectedUser?.name,
      lastMessage: null,
      lastMessageTime: null,
      unReadMessages: 0,
      userImage: NoImage,
      channel: channel_name,
    };
    setChats([new_chat, ...chats]);
    setSelectedChannel({
      name: channel_name,
      id: channelId ?? channels.length + 1,
      participants: [
        {id: loginStore.id, name: loginStore.fullName, userImage: NoImage},
        {id: selectedUser?.id, name: selectedUser?.name, userImage: NoImage}
      ],
      isPublic: channelIsPublic ?? false,
      customName: selectedUser?.name,
    });
    getChannelsReq();
    return setSelectUsersModal({...selectUsersModal, open: false});
  }

  const createNewChannel = (channel_name, channel_participants = [], isPublic = false) => {
    api.createChannel({channel_name, public: isPublic, channel_participants})
      .then((result) => {
        if (result.kind === 'ok') {
          startChat(channel_name, result?.response?.id, result?.response?.public)
        }
      })
      .catch(() => showMessage())
  }

  const updateChatParticipants = (chatId, channel_custom_name, channel_participants = [], isPublic) => {
    if (!chatId || !Array.isArray(channel_participants) || channel_participants.length < 2) return
    api.updateChatParticipants({id: chatId, channel_custom_name, channel_participants, public: isPublic})
      .then(() => {
        showMessage('Chat updated!', 'success');
      })
      .catch(() => showMessage())
  }

  const createChat = () => {
    try {
      // this function creates the chat in the backend if it is not created already
      const participantsIds = [loginStore.id, selectedUser?.id].sort((prevValue, nextValue) => prevValue - nextValue);
      const new_channel = `from_${participantsIds[0]}_to_${participantsIds[1]}`;

      // check if the chat already has created, so we don't have to create it
      const checkedChannel = channels.find(c => c.channel_name === new_channel);
      if (checkedChannel) {
        setShowDetail(true);
        setSelectedChannel(checkedChannel.channel_name);
        return setSelectUsersModal({...selectUsersModal, open: false});
      }
      createNewChannel(new_channel, [loginStore.id, selectedUser?.id])
    } catch (error) {
      console.error('error: ', error);
    }
  }

  const handleMessage = (event) => {
    // this method fires when there are new messages in the subscribed channels
    try {
      const {message, channel, timetoken} = event;

      const messageReceived = {
        message: message?.text,
        url: message.url ? {source: message.url.source, label: message.url.label} : null,
        from: message?.from?.id,
        from_username: message.from.name,
        date: moment().format('L'),
        time: moment().format('HH:mm'),
        is_deleted: false,
        channel,
        timetoken,
      };

      const channelSelected = localStorage.getItem('selectedChannel.name');
      setMessages(prev => {
        if (prev.hasOwnProperty(channel)) {
          return {
            ...prev, [channel]: [...prev[channel], messageReceived]
              .map(m => channel === channelSelected && !m.read ? {...m, read: true} : m)
          }
        }
        return {...prev, [channel]: [messageReceived]}
      })

      pubnub.addMessageAction({
        channel: selectedChannel.name, messageTimetoken: timetoken,
        action: {
          type: 'receipt',
          value: 'read',
        }
      });
      scrollToLastMessage();
    } catch (error) {
      console.error('error getting the messages', error)
    }
  };

  const deleteMessage = (msg, showDeletedMessage = true) => {

    // this method call the pubnub delete api and then if it works
    // call the onDeleteMessage method that change the message in the screen
    const startMessage = `${parseInt(msg.timetoken) + 2}`
    const endMessage = `${parseInt(msg.timetoken) - 2}`
    pubnub.deleteMessages(
      {
        channel: selectedChannel.name,
        start: startMessage,
        end: endMessage,
      },
      (status, response) => showDeletedMessage && onDeleteMessage(status, msg, response)
    );
  }

  const onDeleteMessage = (status, msg) => {
    try {
      if (status.statusCode === 200) {
        const temp_messages = {...messages};
        const deletedMessage = messages[selectedChannel.name].find(m => m.id === msg.id);
        const deletedMessageIndex = messages[selectedChannel.name]?.indexOf(deletedMessage);
        if (!msg.file) {
          deletedMessage.message = 'You deleted this message';
          deletedMessage.is_deleted = true;
        } else {
          deletedMessage.file.src = null;
          deletedMessage.is_deleted = true;
        }
        temp_messages[selectedChannel.name][deletedMessageIndex] = deletedMessage;
        setMessages(temp_messages);
        setAnchorElMsg(null);
      }
    } catch (error) {
      showMessage(error.message);
    }
  }

  const handleChatShowInfoClick = () => {
    setOpenChatInfo(!openChatInfo);
  }

  const scrollToLastMessage = () => {
    if (lastMessageRef.current !== null) {
      lastMessageRef.current.scrollIntoView({block: 'end'});
    }
  }

  useEffect(() => {
    getClients();
    getUsers();
    getChannelsReq();
    return localStorage.removeItem('selectedChannel.name');
  }, []);
  useEffect(() => {
    const listenerParams = {message: handleMessage, status: event => event};
    setupPubnub(listenerParams);
    return () => {
      pubnub.unsubscribe({channels});
      pubnub.removeListener(listenerParams);
    }
  }, [channels]);
  useEffect(() => {
    setupChats();
  }, [channels, messages]);
  useEffect(() => {
    if (selectedChannel.name) {
      markMessagesAsRead();
      scrollToLastMessage();
      setCurrentChatCustomName(selectedChannel.customName ?? selectedChannel.name);
      setChatPublicValue(selectedChannel.isPublic)
    }
  }, [selectedChannel.name]);
  return (
    <AdminLayout title={"Messages"}>
      <MDBox pt={{xs: 8, sm: 0}} pb={{xs: 1, sm: 0}}>
        <AddButton  onClick={handleNewMessageClick}/>
      </MDBox>
      <MDBox display={"flex"} sx={{width: '100%', marginTop: 10}}>
        <MDBox
          className={`message-box-container${ShowDetail ? '-scroll' : ''}`}
          style={{
            width: ShowDetail ? isMobile ? 0 : '35%' : '100%',
            borderRight: (ShowDetail && !isMobile) && '2px solid #C6C9CE',
            overflow: 'auto'
          }}
        >
          {/* USER BOX PREVIEW */}
          {chats.map((chat, key) => (
            <MDBox
              key={chat.id}
              onClick={() => handleOpenChat(chat)}
              sx={{
                borderTop: key !== 0 ? '0.5px solid #C6C9CE' : '',
                height: {xs: '100px', xl: '80px'},
                width: {xs: '100%', sm: ShowDetail ? '400px' : '100%', xxl: '100%'},
              }}
              className={"message-box"}
            >
              <img src={chat.userImage} className={"message-user-image"} alt={'user_image'}/>
              <MDBox flex={1} ml={3} display='flex' gap={pxToRem(7)} flexDirection='column'>
                <MDTypography fontWeight={'400'} color={'black'} variant="h5" fontSize={pxToRem(16)}>
                  {chat.customChannelName}
                </MDTypography>
                <MDBox display='flex' alignItems='center' gap={pxToRem(5)}>
                  {chat?.lastMessageIsFile &&
                    <Icon fontSize='small'>{chat?.lastMessageFileType === 'image' ? 'image' : 'article'}</Icon>
                  }
                  <MDTypography
                    fontWeight={chat.unReadMessages > 0 ? 600 : 'light'}
                    color={chat.unReadMessages > 0 ? 'success' : '#303134'}
                    variant="h6"
                    fontSize={pxToRem(14)}
                    style={{textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', maxWidth: 200}}
                  >
                    {chat.lastMessage}
                  </MDTypography>
                </MDBox>
              </MDBox>
              <MDBox mr={5}>
                <MDTypography variant="h6" fontWeight={'regular'} fontSize={pxToRem(14)}>
                  {chat.lastMessageTime}
                </MDTypography>
                {chat.unReadMessages > 0 &&
                  <MDTypography
                    variant="h6"
                    fontWeight={'light'}
                    fontSize={pxToRem(14)}
                    style={{
                      margin: 'auto', background: '#60A77C', width: 20, height: 20, borderRadius: 15, color: 'white',
                      display: 'flex', alignItems: 'center', justifyContent: 'center', paddingRight: '1px',
                    }}
                  >
                    {chat.unReadMessages}
                  </MDTypography>
                }
              </MDBox>
            </MDBox>)
          )}
        </MDBox>
        {ShowDetail &&
          <Chat
            {...
              {
                pubnub,
                getChatsInformation,
                selectedChannel,
                messages,
                anchorElMsg,
                setAnchorElMsg,
                messageValue,
                setMessageValue,
                pinnedMessageRef,
                setOpenImagePreview,
                pinnedMessage,
                setPinnedMessage,
                selectedMessage,
                lastMessageRef,
                setSelectUsersModal,
                selectedUser,
                selectedImageMessage,
                setShowDetail,
                setOpenChatInfo,
                handleChatShowInfoClick,
                deleteMessage,
              }
            }
          />
        }
        <ChatModals
          {...
            {
              channels,
              selectedChannel,
              setSelectedChannel,
              chatPublicValue,
              setChatPublicValue,
              openChatInfo,
              setOpenChatInfo,
              currentChatCustomName,
              setCurrentChatCustomName,
              selectUsersModal,
              setSelectUsersModal,
              Users,
              Clients,
              selectedUser,
              setSelectedUser,
              openImagePreview,
              setOpenImagePreview,
              selectedMessage,
              setMessageValue,
              chats,
              setChats,
              createChat,
              deleteMessage,
              handleAddParticipantsClick,
              updateChatParticipants,
              userId: loginStore.id
            }
          }
        />
      </MDBox>
    </AdminLayout>
  )
}
