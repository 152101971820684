import {Box, InputAdornment, TextField, Typography} from "@mui/material";
import MDBox from "../MDBox";
import MDTypography from "../MDTypography";
import uploadIcon from "assets/images/uploadIcon.svg";
import attachmentIcon from "assets/images/icons/attachment.svg";
import {useStores} from "../../models";


const UploadDoc = ({error, success, disabled, useBrandingColor, multiple, handleChange, inputDottedType = false, inputFileStyles, ...rest}) => {
  const inputID = `input_${Math.random().toString().substring(2, 15)}`
  const rootStore = useStores()
  const { loginStore } = rootStore;
  let backgroundColor = '#60A77C'
  if (useBrandingColor) {
    try {
      backgroundColor = JSON.parse(loginStore.branding_data?.[useBrandingColor])?.hex
    } catch (error) {}
  }
  return (
    <MDBox>
      <input
        type="file"
        id={inputID}
        multiple={multiple}
        name={inputID}
        onChange={(e) => handleChange(e)}
        style={{width: 0, height: 0, display: 'contents'}}
      />
      <label htmlFor={inputID}>
        <MDBox display={'flex'} justifyContent={'center'} gap={2} p={1} sx={ useBrandingColor ? {...inputFileStyles, backgroundColor: backgroundColor} : inputFileStyles}>
          {inputDottedType && <MDTypography variant={'h6'} color={'secondary'} >Upload</MDTypography>}
          {inputDottedType && <Box component={'img'} src={uploadIcon} alt={'upload-icon'}/>}
          {!inputDottedType && <Box component={'img'} src={attachmentIcon} alt={'attachment-icon'}/>}
        </MDBox>
      </label>
    </MDBox>
  )
}
export default UploadDoc;
