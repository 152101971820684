import { useEffect, useState } from "react";
import moment from 'moment'
import { Grid, InputAdornment, IconButton } from '@mui/material';
import AdminLayout from "../../../components/AdminLayout";
import { useNavigate, Link as RouteLink } from "react-router-dom";
import MDButton from "../../../components/MDButton";
import MDTypography from "../../../components/MDTypography";
import SimpleTable from "components/SimpleTable";
import { dataTableModel, renderTableRow } from "./utils";

import { useStores } from "models";
import { showMessage, useApi } from "services/helpers";

import "../styles.css"
import { ROUTES } from "constants";

const header = [
  { title: 'Name (A-Z)', align: '', id: 1 },
  { title: 'Email', align: '', id: 2 },
  { title: 'Last login', align: '', id: 3 },
  { title: 'Actions', align: '', id: 4 }
];

export default function Team(props) {
  const navigate = useNavigate();
  const rootStore = useStores();
  const api = useApi();
  const [loading, setLoading] = useState(false);
  const { loginStore } = rootStore;

  const [Team, setTeam] = useState();
  const [Data, setData] = useState({ ...dataTableModel });

  const getTeam = (searchData, extraData) => {
    setLoading(true)
    api.getTeam(searchData, extraData).then((result) => {
      if (result.kind === "ok") {
        const { results } = result.data
        const tmp = { ...dataTableModel }
        tmp.rows = results.map(e => renderTableRow(e))
        setData(tmp);

      } else {
        if (result.kind === "bad-data") {
          showMessage(result?.errors, 'error', true)
        } else {
          showMessage('An error occurred, please try again later')
        }
      }
    }).catch(err => showMessage())
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    getTeam('')
  }, []);

  const handleClick = (event) => {
    navigate(ROUTES.SETTINGS_CREATE_USER)
  };

  return (
    <AdminLayout title={"Manage Team"}>
      <Grid container alignItems="top" paddingTop={0} marginTop={0} spacing={3}>
        <Grid item xs={12} lg={12} md={12} mb={2}>
          <MDTypography useBrandingColor={'tertiary'} variant="h2" fontWeight="medium" mb={1} ml={3} style={{ float: 'left' }}>
            Manage Team
          </MDTypography>
          <MDButton
            useBrandingColor={'primary'}
            color="green"
            style={{ float: 'right', textTransform: 'none' }}
            component={RouteLink}
            to={'/settings/create_user'}>
            Add New User +
          </MDButton>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} lg={12} md={12} ml={2}>
          <SimpleTable data={Data} />
        </Grid>
      </Grid>
    </AdminLayout>
  )
}
