import * as React from 'react';
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";

export const JobContent = ({title, name, onDragStart, index, onClick = () => {}})=>{
  return (
    <MDBox 
      bgColor={'var(--very-light-grey)'} 
      p={1} 
      mb={0} 
      mt={1} 
      borderRadius={"8px"} 
      draggable="true"
      onDragStart={() => onDragStart(name, index)}
      onClick={onClick}
    >
      <MDBox >
        <MDTypography variant="button" fontWeight={"bold"}  style={{color:"var(--grey)"}}>{title}</MDTypography>
      </MDBox>
      <MDBox>
        <MDTypography variant="h6" fontWeight={"medium"} style={{color:"var(--light-black)"}}>{name}</MDTypography>
      </MDBox>
    </MDBox>
  )

}
