import { useEffect, useState } from "react";
import moment from 'moment'
import styles from "../../scheduler/Scheduler.module.css";
import { Grid, InputAdornment, IconButton } from '@mui/material';
import ClientPanelLayout from "../../../components/ClientPanelLayout";
import { useNavigate } from "react-router-dom";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import MixedChart from "examples/Charts/MixedChart";
import LineCharts from "examples/Charts/LineCharts/DefaultLineChart";
import mixedChartData from "../data/analitycs";
import "../styles.css"

//icons
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

import { useStores } from "models";
import Toast from "components/Toast"
import MDAvatar from "components/MDAvatar";
import MonthsSwitch from "../components/MonthsSwitch";
import Percents from "../components/Percents";
import { showMessage } from "services/helpers";

export default function Invoices() {
    const rootStore = useStores()
    const { loginStore } = rootStore

    const [Data, setData] = useState([]);
    const [loading, setLoading] = useState(false)

    const getInvoices = (searchData, extraData) => {
        setLoading(true)
        loginStore.environment.api.getInvoices(searchData, extraData).then((result) => {
            if (result.kind === "ok") {
                let data = []
                result.data.results.map(r => {
                    data.push({
                        name: r?.task_for?.first_name,
                        start_time: moment(r.start_date).format('DD/MM/YYYY HH:mm'),
                        ...r
                    })
                })
                setData(data)
            } else {
                if (result.kind === "bad-data") {
                    showMessage(result?.errors, 'error', true)
                } else {
                    showMessage('An error occurred, please try again later')
                }
            }
        })
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        getInvoices('');
    }, []);

    return (
        <ClientPanelLayout title={"Invoices"} searchFunc={getInvoices} loading={loading}>
            <Grid container alignItems="top" paddingTop={0} marginTop={0}>
                <Grid item xs={12} lg={9} md={9} className="leftContainer">
                    <MDTypography variant="h2" fontWeight="medium" ml={4} mt={1}>
                        All Invoices
                    </MDTypography>
                </Grid>
            </Grid>
            {(Array.isArray(Data) && Data[0])
                ? Data.map((data, i) =>
                    <Grid container spacing={3} padding={3} className="task_container">
                        <Grid item xs={4} lg={4} md={4}>
                            <MDTypography variant="h6">{`${moment(data.start_date).format('dddd')}, ${moment(data.start_date).format('ll')}`}</MDTypography>
                        </Grid>
                        <Grid item xs={4} lg={4} md={4}>
                            <MDTypography fontWeight='regular' variant="h6">{moment(data.start_date).format('LT')}</MDTypography>
                        </Grid>
                        <Grid item xs={4} lg={4} md={4}>
                            <MDTypography fontWeight='regular' variant="h6">{data.title}</MDTypography>
                        </Grid>
                    </Grid>
                )
                : <Grid container spacing={3} padding={3} className="task_container">
                    <Grid item xs={4} lg={4} md={4}>
                        <MDTypography variant="h6">Sin datos</MDTypography>
                    </Grid>
                </Grid>
            }
        </ClientPanelLayout>
    )
}
