import {useRef, useState} from "react";

// react-router-dom
import {useNavigate} from "react-router-dom";

/* MUI components */
import {Box} from '@mui/material';
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";
import * as Yup from "yup";

/* MD components */
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

/* mobile */

/* utils */
import {useStores} from "../../models";
import {runInAction} from "mobx";
import {showMessage, useApi} from "services/helpers";
import {useIsMobile} from "services/hooks";

// Images
import man_sit_pc from '../../assets/images/man_sit_pc.png';
import man_look_pass from '../../assets/images/man_look_pass.png';
import {Form, Formik} from "formik";
import FormikInput from "../../components/FormikInput";
import MDBox from "../../components/MDBox";
import {observer} from "mobx-react-lite";
import { ROUTES } from "constants";

// Authentication layout components

function SetNewPassword() {
  const api = useApi()
  const navigate = useNavigate()
  const formikRef = useRef();
  const [loading, setLoading] = useState(false);
  const getUrls = window.location.href.split('set-new-password/')[1].split('/')

  const resetPassword = (data) => {
    const form = {
      new_password1: data.password,
      new_password2: data.new_password2,
      uidb64: getUrls[0],
      token: getUrls[1]
    }
    setLoading(true)
    api.resetPassword(form).then((result) => {
      if (result.kind === "ok") {
        showMessage('Password reset successful', 'success')
        navigate(ROUTES.LOGIN)
      } else if(result.kind === "bad-data") {
        if (result.errors.token)  showMessage(result.errors.token)
        else {
          if (result.errors?.errors?.[0]) showMessage(result.errors?.errors?.[0])
          else showMessage(result.errors?.errors, 'error', true)
          formikRef.current.setErrors(result.errors)
        }
      } else {
        showMessage()
      }
    })
      .catch(err => {})
      .finally(() => setLoading(false))
  }

  const validationSchema =
    Yup.object().shape({
      password: Yup.string().required('Password is required'),
      new_password2: Yup.string().required('Confirm password is required').oneOf([Yup.ref('password'), null], "Passwords doesn't match"),
    })

  const initialValues = {
    password: "",
    new_password2: "",
  };

  const right_login = <>
    <div style={{width: '100%'}}>
      <MDTypography color={'success'} variant="h4" fontWeight="medium" mb={3}>
        Thime
      </MDTypography>
      <MDTypography variant="h2" fontWeight="regular" mb={3}>
        Set Password
      </MDTypography>
    </div>
    <img src={man_sit_pc} alt={"woman"} className={"woman-man-image"}
         style={{height: '50%', width: 'auto', marginLeft: '100px'}}/>
  </>

  const left_login = <>
    <MDTypography variant="h2" fontWeight="medium" mb={3}>
      Set Password
    </MDTypography>
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnBlur={false}
      onSubmit={values => {
        resetPassword(values);
      }}
    >
      {({errors, isValid}) => (
        <Form>
          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={9}>
              <FormikInput name={'password'} label={'New Password'} type={'password'} errors={errors} mb={4}/>
            </Grid>
            <Grid item xs={9}>
              <FormikInput name={'new_password2'} label={'Confirm New Password'} type={'password'} errors={errors}/>
            </Grid>
            <Grid item xs={9} alignItems={'center'} display={'flex'} mt={10}>
              <MDButton
                color="green"
                type="submit"
                className={'btn-save-login'}
                disabled={loading}
                loading={loading}
              >
                Save
              </MDButton>
            </Grid>

          </Grid>
        </Form>
      )}
    </Formik>
  </>


  return (
    <Box>
      <Grid
        container
        alignItems="center"
        className={"main-container"}
        style={{height: "100%"}}
      >
        <Grid
          item
          xs={12}
          lg={7}
          md={7}
          style={{backgroundColor: "#F4F6F5", height: "100%"}}
        >
          <div className="right-content">
            {right_login}
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          lg={5}
          md={5}
          style={{backgroundColor: "white", height: "100%"}}
        >
          <div className="left-content">
            {left_login}
          </div>
        </Grid>
      </Grid>
    </Box>
  )
}

export default observer(SetNewPassword);
