import React, {Children, useCallback, useEffect, useMemo, useState} from 'react'
import {Link as Routelink} from 'react-router-dom';
import AdminLayout from "../../components/AdminLayout";
import ThimeCard from "../../components/ThimeCard";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import Grid from '@mui/material/Grid';
import { ROUTES } from 'constants';

const Separator = () => <div style={{height: 22}}/>;

export default function ReportPage() {

  return (
    <AdminLayout title={"Reports"}>
      <Grid container alignItems="top" paddingTop={0} marginTop={0}>
        <Grid item xs={12} lg={4} md={4} display={"flex"}
              pl={3} pr={3}
              flexDirection={"column"}
              justifyContent={"flex-start"}
        >
          <MDBox pl={1} pr={1} mb={3}>
            <ThimeCard color={'#FFDBA4'} title={'Select Settings'}>
              <MDBox component={Routelink} to={ROUTES.SETTINGS_COMPANY}>
                <MDTypography variant="h6" fontWeight={"medium"} style={{color: "var(--light-black)"}}>
                  Company Settings
                </MDTypography>
                <MDTypography variant="button" fontWeight={"bold"} style={{color: "var(--grey)", paddingBottom: 10}}>
                  Lorem ipsum dolor sit amet, consecte adipiscing elit, sed diam nonummy
                </MDTypography>
              </MDBox>

              <Separator/>

              <MDBox component={Routelink} to={"/settings/branding"}>
                <MDTypography variant="h6" fontWeight={"medium"}
                              style={{color: "var(--light-black)"}}>Branding</MDTypography>
                <MDTypography variant="button" fontWeight={"bold"} style={{color: "var(--grey)"}}>
                  Lorem ipsum dolor sit amet, consecte adipiscing elit, sed diam nonummy
                </MDTypography>
              </MDBox>

              <Separator/>

            </ThimeCard>
          </MDBox>
        </Grid>

        <Grid item xs={12} lg={4} md={4} display={"flex"}
              pl={3} pr={3}
              flexDirection={"column"}
              justifyContent={"flex-start"}
        >
          <MDBox p={1}>
            <ThimeCard color={'#FFDBA4'} title={'Select Settings'}>
              <MDBox component={Routelink} to={ROUTES.SETTINGS_TEAM}>
                <MDTypography variant="h6" fontWeight={"medium"} style={{color: "var(--light-black)"}}>Manage
                  Team</MDTypography>
                <MDTypography variant="button" fontWeight={"bold"} style={{color: "var(--grey)"}}>
                  Lorem ipsum dolor sit amet, consecte adipiscing elit, sed diam nonummy
                </MDTypography>
              </MDBox>

              <Separator/>

              <MDBox component={Routelink} to={"/settings/job_forms"}>
                <MDTypography variant="h6" fontWeight={"medium"} style={{color: "var(--light-black)"}}>Job
                  Forms</MDTypography>
                <MDTypography variant="button" fontWeight={"bold"} style={{color: "var(--grey)"}}>
                  Lorem ipsum dolor sit amet, consecte adipiscing elit, sed diam nonummy
                </MDTypography>
              </MDBox>

              <Separator/>

              <MDBox component={Routelink} to={ROUTES.REPORTS_CLIENT_HUB_DETAIL}>
                <MDTypography variant="h6" fontWeight={"medium"} style={{color: "var(--light-black)"}}>Client
                  Hub</MDTypography>
                <MDTypography variant="button" fontWeight={"bold"} style={{color: "var(--grey)"}}>
                  Lorem ipsum dolor sit amet, consecte adipiscing elit, sed diam nonummy
                </MDTypography>
              </MDBox>

              <Separator/>

            </ThimeCard>
          </MDBox>
        </Grid>

        <Grid item xs={12} lg={4} md={4} display={"flex"}
              pl={3} pr={3}
              flexDirection={"column"}
              justifyContent={"flex-start"}
        >
          <MDBox p={1}>
            <ThimeCard color={'#FFDBA4'} title={'Select Settings'}>
              <MDBox component={Routelink} to={ROUTES.SETTINGS_SUBSCRIPTIONS}>
                <MDTypography variant="h6" fontWeight={"medium"} style={{color: "var(--light-black)"}}>Billing &
                  Membership</MDTypography>
                <MDTypography variant="button" fontWeight={"bold"} style={{color: "var(--grey)"}}>
                  Lorem ipsum dolor sit amet, consecte adipiscing elit, sed diam nonummy
                </MDTypography>
              </MDBox>

              <Separator/>

              <MDBox component={Routelink} to={"/settings/contact_us"}>
                <MDTypography variant="h6" fontWeight={"medium"} style={{color: "var(--light-black)"}}>Contact
                  Us</MDTypography>
                <MDTypography variant="button" fontWeight={"bold"} style={{color: "var(--grey)"}}>
                  Lorem ipsum dolor sit amet, consecte adipiscing elit, sed diam nonummy
                </MDTypography>
              </MDBox>

              <Separator/>

              <MDBox component={Routelink} to={"/settings/api_settings"}>
                <MDTypography variant="h6" fontWeight={"medium"} style={{color: "var(--light-black)"}}>API
                  Settings</MDTypography>
                <MDTypography variant="button" fontWeight={"bold"} style={{color: "var(--grey)"}}>
                  Lorem ipsum dolor sit amet, consecte adipiscing elit, sed diam nonummy
                </MDTypography>
              </MDBox>

              <Separator/>

            </ThimeCard>
          </MDBox>
        </Grid>
      </Grid>
    </AdminLayout>)
}