export const styles = {
  buttonProps: {
    variant: "gradient",
    color: 'white',
    coloredShadow: '#FFBAAA',
    borderRadius: "xl",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "50px",
    height: "50px",
    mr: 2,
  },
  
  floatBoxContainer: {
    height: '80px',
    width: '350px',
    borderRadius: '15px',
    position: 'fixed',
    bottom: '30px',
    right: '30px',
    boxShadow: '1px 1px 2px rgba(145, 158, 171, 0.2), 0px 12px 12px rgba(145, 158, 171, 0.12)',
    background: 'white',
    display: 'flex',
    justifyContent: 'space-between'
  },
  
  floatBoxLeft: {
    display: 'flex',
    flexDirection: 'column',
    width: '60%',
    justifyContent: 'space-evenly'
  },
  
  floatBoxRight: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    width: '40%'
  }, 
}