
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import {useState, useContext, useEffect} from "react";
import Card from "@mui/material/Card";

// Utils
import { useStores } from "../../models";
import MDBox from "components/MDBox";

import Dial from "./dial";
import CallOngoing from "./call_ongoing";
import CallPending from "./call_pending";

import {observer} from "mobx-react-lite";
import {TwilioContext} from "../../services/phone_system";

function PhoneCard() {
  const [State, setState] = useState('dial');

  const { currentCall } = useContext(TwilioContext);

  useEffect(() => {
    if (currentCall) {
        setState('pending')
        currentCall.on('accept', () => { // handles both incoming and outgoing
            setState('on_call')
        })
    }else{
        setState('dial')
    }
  }, [currentCall])

  const PhoneTypes = {
    dial: <Dial/>,
    on_call: <CallOngoing />,
    pending: <CallPending/>,
  }

  return <MDBox
      borderRadius={'10px'}
      sx={{ alignSelf: 'center', width: '100%', height: '100%' }}
      alignItems="center"
      justifyContent="center"
      px={3}
    >
      {PhoneTypes[State]}
    </MDBox>

}

export default observer(PhoneCard);
