import { useEffect, useState } from "react";
import {Link as RouteLink} from 'react-router-dom';
import moment from 'moment'
import { Grid, InputAdornment, IconButton } from '@mui/material';
import AdminLayout from "../../../components/AdminLayout";
import { useNavigate } from "react-router-dom";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import MDInput from "../../../components/MDInput";
import MDButton from "../../../components/MDButton";
import MDDatePicker from "../../../components/MDDatePicker";
import {Header} from "../components/Header";
import {Tables} from "../components/Tables";
import "../styles.css"

const header = [
    {title: 'Number', align: '', id: 1},
    {title: 'Name (A-Z)', align: '', id: 2},
    {title: 'Created', align: '', id: 3},
    {title: 'Due', align: '', id: 4},
    {title: 'Status', align: '', id: 5},
    {title: 'Total', align: 'center', id: 6},
    {title: '', align: 'center', id: 7},
];
const rows = [
    {number: 'IN1000', name: 'Jane Cooper', created: 'July 18, 2022', due: 'July 18, 2022', status: 'Past due', class:'error', total: '$0000', actions:'project_incomes', id: 1},
    {number: 'IN1000', name: 'Jane Cooper', created: 'July 18, 2022', due: 'July 18, 2022', status: 'Draft', class:'grey', total: '$0000', actions:'project_incomes',id: 2},
    {number: 'IN1000', name: 'Jane Cooper', created: 'July 18, 2022', due: 'July 18, 2022', status: 'Sent but not due', class:'warning', total: '$0000', actions:'project_incomes', id: 3},
    {number: 'IN1000', name: 'Jane Cooper', created: 'July 18, 2022', due: 'July 18, 2022', status: 'Past due', class:'error', total: '$0000', actions:'project_incomes', id: 4},
    {number: 'IN1000', name: 'Jane Cooper', created: 'July 18, 2022', due: 'July 18, 2022', status: 'Past due', class:'error', total: '$0000', actions:'project_incomes', id: 5},
    {number: 'IN1000', name: 'Jane Cooper', created: 'July 18, 2022', due: 'July 18, 2022', status: 'Draft', class:'grey', total: '$0000', actions:'project_incomes', id: 6},
    {number: 'IN1000', name: 'Jane Cooper', created: 'July 18, 2022', due: 'July 18, 2022', status: 'Past due', class:'error', total: '$0000', actions:'project_incomes', id: 7},
    {number: 'IN1000', name: 'Jane Cooper', created: 'July 18, 2022', due: 'July 18, 2022', status: 'Sent but not due', class:'warning', total: '$0000', actions:'project_incomes', id: 8},
  ];

const headers = [
    {title: 'Late invoices', quantity: '0', content: '$000', class:'', id: 1},
    {title: 'Invoices due today', quantity: '1', content: '$000', id: 2},
    {title: 'Invoices due in less than 7 days', quantity: '10', content: '$000', class:'', id: 3},
    {title: 'Invoices due in 7 to 30 days', quantity: '3', content: '$000', class:'', id: 4},
    {title: 'Invoices due in more than 30 days', quantity: '6', content: '$000', class:'', id: 5},
    {title: 'Outstanding invoices', quantity: '8', content: '$000', class:'success', id: 6}
  ];

export default function ProjectIncome(props) {

  return (

    <AdminLayout title={"Projected Income"}>
        <Grid container alignItems="top" paddingTop={0} marginTop={0}>
            <Grid item xs={12} lg={12} md={12} mb={3}>
                <MDTypography variant="h2" fontWeight="medium" mb={1} ml={3} style={{ float: 'left' }}>
                    Projected Income
                </MDTypography>
                <MDButton color="green" style={{ float: 'right', textTransform: 'none' }} component={RouteLink} to="/reports/aged_receivables">
                    View Aged Receivables Report
                </MDButton>
            </Grid>
           
            <Grid item xs={12} lg={12} md={12} mb={3}>
                <Header data={headers} styles={{ width: 164}}/>
            </Grid>
        </Grid>
    <Grid container spacing={3}>
        <Grid item xs={12} lg={12} md={12} ml={2}>
            <MDTypography variant="h3" fontWeight="medium" mb={1}>
                List
            </MDTypography>
            <Tables body={rows} header={header}/>
        </Grid>
    </Grid>
    </AdminLayout>
  )
}
