import React, {useEffect} from 'react';
import {Navigate} from 'react-router-dom';
import {useStores} from "../../models";


const Logout = () => {
  const rootStore = useStores()
  const {loginStore} = rootStore

  useEffect(() => {
    loginStore.reset()
  } , [])

  return <Navigate to={'/'}/>
};

export default Logout;
