import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

export default function MDDialog(props) {
  const {title, actions, open, handleClose, style, titleStyles, rootStyles, ...rest} = props;
  const maxWidth = props.maxWidth === undefined ? 'md' : props.maxWidth

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={maxWidth} PaperProps={{sx: {...rootStyles}}}>
      <DialogTitle display="flex" justifyContent="center" alignItems="center" sx={titleStyles}>
        {title}
      </DialogTitle>
      <DialogContent style={{...style, ...rest}}>
        {props.children}
      </DialogContent>
      {actions && <DialogActions> </DialogActions>}
    </Dialog>
  );
}