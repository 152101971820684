import React from "react";
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';
import SimpleTable from "components/SimpleTable";
import MDButton from "components/MDButton";
import DeleteIcon from 'assets/images/icons/delete.png';
import Edit from "assets/images/icons/edit.png";
import "../styles.css";
import {ROUTES} from "constants";
import {showMessage} from "services/helpers";
import {useStores} from "models";

const datatableModel = {
  columns: [
    { Header: "Number", accessor: "id" },
    { Header: "Title (A-Z)", accessor: "name" },
    { Header: "Description", accessor: "description" },
    { Header: "Type", accessor: "type" },
    { Header: "Action", accessor: "action" },
  ],
  rows: []
}

export default function ApproveTab(props) {
  const [Data, setData] = React.useState({ ...datatableModel });
  const navigate = useNavigate();
  const rootStore = useStores();
  const { loginStore } = rootStore;

  const deleteMatrix = (id) => {
    loginStore.environment.api.deleteMatrix(id).then((result) => {
      if (result.kind === "ok") {
        showMessage('Matrix deleted successfully', 'success')
        props.deleteCallback(id)
      } else {
        if (result.kind === "bad-data") showMessage(result?.errors, 'error')
        else showMessage('An error occurred, please try again later')
      }
    })
  }

  React.useEffect(() => {
    let data = { ...datatableModel }
    data.rows = []

    if (Array.isArray(props.data)) {
      props.data.map(r => {
        data.rows.push({
          ...r,
          action: (
            <>
              <MDButton useBrandingColor={'primary'} iconOnly onClick={() => navigate(ROUTES.MATRIX_EDIT(r.id))}>
                <img width={'80%'} src={Edit} alt='Edit' />
              </MDButton>
              <MDButton useBrandingColor={'primary'} onClick={() => deleteMatrix(r.id)} iconOnly style={{ marginLeft: 15 }}>
                <img src={DeleteIcon} alt='DeleteIcon' />
              </MDButton>
            </>
          )
        })
      })
    }
    setData({ ...data })

  }, [props]);

  return (
    <SimpleTable data={Data} />
  )
}
