/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// react-router components
import { useLocation, Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";
import MDTypography from "components/MDTypography";
import Box  from "@mui/material/Box";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDBadge from "components/MDBadge";

import {ROUTES} from 'constants'

// Material Dashboard 2 PRO React examples
import NotificationItem from "examples/Items/NotificationItem";

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarRowHead,
  navbarIconButton,
  navbarDesktopMenu,
} from "./styles";

import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
} from "context";

// mobile
import { DashboardNavbarMobile } from "./mobile";

// Utils
import { useStores } from 'models'
import { useIsMobile } from "services/hooks";
import {Search} from "@mui/icons-material";
import {Input} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

function DashboardNavbar({ absolute, light, isMini, loading, searchFunc, title }) {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator, darkMode } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const route = useLocation().pathname.split("/").slice(1);
  const isMobile = useIsMobile();

  const navigate = useNavigate()
  const rootStore = useStores()
  const { loginStore } = rootStore
  let brandingBackgroundColor = '#60A77C';
  if (loginStore.branding_data) {
    try {
      brandingBackgroundColor = JSON.parse(loginStore.branding_data?.['primary'])?.hex
    } catch (error) {}
  }

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /**
     The event listener that's calling the handleTransparentNavbar function when
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);
  const handleOpenPhone = (event) => {
    event.preventDefault()
    event.stopPropagation()
    loginStore.setSidePhoneOpen(!loginStore.sidePhoneOpen)
    console.log('clicked')
  }

  // Styles for the navbar icons
  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return white.main;
    },
  });

  const iconHomeColor = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return dark.main;
    },
  });

  const mobileProps = {
    handleMiniSidenav,
    iconHomeColor,
    isMini,
    absolute,
    light,
    iconsStyle
  };

  return (
    <Box>
      {!isMobile ? (
        <AppBar
          position={'static'}
          color="inherit"
          sx={theme =>
            navbar(theme, { transparentNavbar, absolute, light, darkMode })
          }
        >
          <Toolbar sx={theme => navbarContainer(theme)}>
            <MDBox
              color="inherit"
              style={{ justifyContent: "flex-start" }}
              mb={{ xs: 1, md: 0 }}
              sx={theme => navbarRow(theme, { isMini })}
            >
              <IconButton
                sx={navbarDesktopMenu}
                onClick={handleMiniSidenav}
                size="small"
                disableRipple
              >
                <Icon fontSize="medium" sx={iconHomeColor}>
                  menu
                </Icon>
              </IconButton>

              {title && (
                <MDTypography
                  color={"yellow"}
                  variant="h4"
                  fontWeight="medium"
                  style={{ marginLeft: 20 }}
                >
                  {title}
                </MDTypography>
              )}
            </MDBox>
            <MDBox sx={theme => navbarRowHead(theme, { isMini })}>
              {searchFunc !== null && <MDBox mb={{ xs: 1, md: 0 }} sx={{backgroundColor: 'white', borderRadius: 2, position: 'relative'}} pr={1} >
                <MDInput
                  placeholder="search..."
                  style={{ width: 280, marginRight: 15, backgroundColor: 'white' }}
                  onChange={(evt) => searchFunc(evt?.target?.value)}
                />
                {loading === false ? <Search style={{position: 'absolute', bottom: 13, right: 30}}/> : <CircularProgress sx={{position: 'absolute', bottom: 15, right: 30}} size={20} color="primary" /> }
              </MDBox>}
              <MDBox color={light ? "white" : "inherit"}>
                <IconButton
                  size="small"
                  disableRipple
                  color="inherit"
                  sx={navbarIconButton}
                  aria-controls="notification-menu"
                  aria-haspopup="true"
                  variant="contained"
                  onClick={() => navigate(ROUTES.NOTIFICATIONS)}
                  style={{
                    backgroundColor: brandingBackgroundColor,
                    width: 40,
                    height: 40,
                    borderRadius: 8,
                    marginRight: 15
                  }}
                >
                  {/* <MDBadge badgeContent={9} color="error" size="xs" circular> */}
                  <MDBadge color="error" size="xs" circular>
                    <Icon sx={iconsStyle}>notifications</Icon>
                  </MDBadge>
                </IconButton>
                <IconButton
                  size="small"
                  disableRipple
                  color="inherit"
                  sx={navbarIconButton}
                  aria-controls="notification-menu"
                  aria-haspopup="true"
                  variant="contained"
                  onClick={handleOpenPhone}
                  style={{
                    backgroundColor: brandingBackgroundColor,
                    width: 40,
                    height: 40,
                    borderRadius: 8,
                    marginRight: 15
                  }}
                >
                  <Icon sx={iconsStyle}>phone</Icon>
                </IconButton>
                <IconButton
                  size="small"
                  disableRipple
                  color="inherit"
                  sx={navbarIconButton}
                  aria-controls="notification-menu"
                  aria-haspopup="true"
                  variant="contained"
                  onClick={() => navigate(ROUTES.MESSAGES)}
                  style={{
                    backgroundColor: brandingBackgroundColor,
                    width: 40,
                    height: 40,
                    borderRadius: 8,
                    marginRight: 15
                  }}
                >
                  <Icon sx={iconsStyle}>mail</Icon>
                </IconButton>
                <IconButton
                  size="small"
                  disableRipple
                  color="inherit"
                  sx={navbarIconButton}
                  aria-controls="notification-menu"
                  aria-haspopup="true"
                  variant="contained"
                  onClick={() => navigate(ROUTES.SETTINGS_UPDATE_USER(loginStore.id))}
                  style={{
                    backgroundColor: brandingBackgroundColor,
                    width: 40,
                    height: 40,
                    borderRadius: 8,
                    marginRight: 15
                  }}
                >
                  <Icon sx={iconsStyle}>person</Icon>
                </IconButton>
                <IconButton
                  size="small"
                  disableRipple
                  color="red"
                  sx={navbarIconButton}
                  component={Link}
                  to="/settings"
                  style={{
                    backgroundColor: brandingBackgroundColor,
                    width: 40,
                    height: 40,
                    borderRadius: 8,
                    marginRight: 15
                  }}
                >
                  <Icon sx={iconsStyle}>settings</Icon>
                </IconButton>
              </MDBox>
            </MDBox>
          </Toolbar>
        </AppBar>
      ) : (
        <DashboardNavbarMobile {...mobileProps} />
      )}
    </Box>
  )
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
