import Grid from "@mui/material/Grid";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import moment from "moment";
import {Button, ButtonGroup} from "@mui/material";
import {styled} from "@mui/material/styles";
import {useNavigate} from "react-router-dom";
// icons
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import MDButton from "components/MDButton";
// 
import './styles.css';

const GreenButton = styled(Button)(({ theme }) => ({
  color:'var(--white)',
  backgroundColor: "var(--green)",
  '&:hover': {
    backgroundColor:  "var(--dark-green)",
    boxShadow: 'none',
    color:'var(--white)'
  },
  '&:focus:not(:hover)': {
    color:'var(--white)',
    backgroundColor: "var(--green)",
  },
}));

const CoralButton = styled(Button)(({ theme }) => ({
  color:'var(--grey)',
  border:"none",
  width: "100px",
  boxShadow: 'none',
  borderRight:"none !important",
  backgroundColor: "var(--very-light-coral)",
  borderColor: "transparent",
  borderRadius:"16px !important",
  '&:hover': {
    // backgroundColor:  "var(--light-coral)",
    boxShadow: 'none',
    color:'var(--black-light)',
    border:"none",
  },
  '&:focus:not(:hover)': {
    backgroundColor: "var(--very-light-coral)",
    color:'var(--grey)',
  },
}));

const ActiveButton = styled(Button)(({ theme }) => ({
  color:'var(--light-black)',
  borderColor: "transparent",
  borderRight:"none !important",
  width:"120px",
  border:"none",
  backgroundColor: "var(--white)",
  zIndex:999,
  boxShadow:"0px 10px 30px 4px rgba(0,0,0,0.16)",
  borderRadius:"16px !important",
  '&:hover': {
    backgroundColor: "var(--white)",
    color:'var(--light-black)',
    border:"none",
  },
  '&:focus:not(:hover)': {
    backgroundColor: "var(--white)",
    color:'var(--light-black)',
  },

}));

export default function MonthsSwitch (props){
    const {view} = props;
  const navigate = useNavigate()

  const DateButton = ({view, title, onClick})=>{
    let callback = null;
    callback = onClick;

    return view === title.toLowerCase() ? (
      <ActiveButton onClick={callback}  style={{textTransform:"capitalize"}}>{title}</ActiveButton>
    ) : (
      <CoralButton  onClick={callback}  style={{textTransform:"capitalize"}}>{title}</CoralButton>
    )
  }


  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={5} md={5} pl={{xs: '0 !important', md: '16px !important'}}>
        <ButtonGroup className="group">
            <DateButton title={"Daily"} view={view} style={{ display: 'inline' }}/>
            <DateButton title={"Weekly"} view={view} style={{ display: 'inline' }}/>
            <DateButton title={"Monthly"} view={view} style={{ display: 'inline' }}/>
            <DateButton title={"Employee"} view={view} style={{ display: 'inline' }}/>
        </ButtonGroup>
      </Grid>
    </Grid> )
}
