import React, { useState } from 'react';
import { Grid } from '@mui/material';
import MDTypography from "components/MDTypography";
import './headerx.css'

export const Headerx = (props) => {

    return (

        <Grid container gap={'10px'} pl={2} justifyContent={{ xs: 'center', md: 'space-between' }}>
            <Grid item className="boxesx" xs={5} md={3} lg={1.5}>
                <MDTypography variant="h6">Ending within 30 days</MDTypography>
                <MDTypography variant="h2">{props?.status?.ENDING || 0}</MDTypography>
            </Grid>
            <Grid item className="boxesx" xs={5} md={3} lg={1.5}>
                <MDTypography variant="h6">Late</MDTypography>
                <MDTypography variant="h2">{props?.status?.LATE || 0}</MDTypography>
            </Grid>
            <Grid item className="boxesx" xs={5} md={3} lg={1.5}>
                <MDTypography variant="h6">Requires invoicing</MDTypography>
                <MDTypography variant="h2">{props?.status?.REQUIRES_INVOICE || 0}</MDTypography>
            </Grid>
            <Grid item className="boxesx" xs={5} md={3} lg={1.5}>
                <MDTypography variant="h6">Unscheduled</MDTypography>
                <MDTypography variant="h2">{props?.status?.UNSCHEDULED || 0}</MDTypography>
            </Grid>
            <Grid item className="boxesx" xs={5} md={3} lg={1.5}>
                <MDTypography variant="h6">Today</MDTypography>
                <MDTypography variant="h2">{props?.status?.TODAY || 0}</MDTypography>
            </Grid>
            <Grid item className="boxesx" xs={5} md={3} lg={1.5}>
                <MDTypography variant="h6">Upcoming</MDTypography>
                <MDTypography variant="h2">{props?.status?.UPCOMING || 0}</MDTypography>
            </Grid>
        </Grid>
    );
}