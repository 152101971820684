import React, { useEffect, useState } from 'react';

// @mui components
import { Grid } from '@mui/material';

// Components
import ClientPanelLayout from "components/ClientPanelLayout";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDDialog from "components/MDDialog";
import MDTypography from "components/MDTypography";

// Utils
import { useStores } from "models";
import { makeid, money_fmt, showMessage } from "services/helpers";

// icons and theme
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import pxToRem from "assets/theme/functions/pxToRem";
import "../styles.css";

export default function Subscriptions() {
  const rootStore = useStores();
  const {loginStore} = rootStore;
  const [loading, setLoading] = useState(false);
  const [showCreateSubsModal, setShowCreateSubsModal] = useState(false);
  const [showUnsubscribeModal, setShowUnsubscribeModal] = useState(false);
  const [subscriptions, setSubscriptions] = useState([]);
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [CurrentSubscription, setCurrentSubscription] = useState({});
  const [SubscriptionIsActive, setSubscriptionIsActive] = useState(true);
  
  const getSubscriptions = (searchData) => {
    setLoading(true);
    loginStore.environment.api.stripeProductsAvailables(searchData)
    .then((result) => {
      if (result.kind === "ok") {
        let results = result.data.products;
        const parsedResults = results.map((item, idx) => 
         ({...item,
          title: item.name,
          price: Number(item.price?.unit_amount)/100 || 0, 
          assets: item.metadata?.assets?.split(",") || []
            }));
        setSubscriptions(parsedResults);       
      } else {
        if (result.kind === "bad-data") {
          showMessage(result?.errors, 'error', true);
        } else {
          showMessage('An error occurred, please try again later');
        }
      }
    })
    .finally(() => setLoading(false));
  }
  
  const getCurrentSubscription = () => {
    setLoading(true);
    loginStore.environment.api.stripeActiveSubscription()
    .then((result) => {
      if (result.kind === "ok") {
        const status = result.data.status || "";
        const subscription = result.data.subscription || {};
        //
        if (status === "No subscriptions found") {
          setCurrentSubscription({});
        } else {
          if (subscription?.id) {
            const product = subscription.product;
            const parsedSubscription = {
              id: subscription.id,
              priceId: subscription.price.id,
              title: product.name,
              price: Number(subscription.price.unit_amount) / 100 || 0,
              description: product.description,
              assets: product.metadata?.assets?.split(",") || []
            }
            setCurrentSubscription(parsedSubscription);
            if (subscription?.status !== "active") {
              setSubscriptionIsActive(false);
            }            
          }
        }      
      } else {
        if (result.kind === "bad-data") {
          showMessage(result?.errors, 'error', true);
        } else {
          showMessage('An error occurred, please try again later');
        }
      }
    })
    .finally(() => setLoading(false));
  }
  
  const createSubscription = (priceId) => {
    setLoading(true);
    loginStore.environment.api.stripeCreateSubscription(priceId)
    .then((result) => {
      if (result.kind === "ok") {
       showMessage("The subscription has been made successfully!", 'success');
      } else {
        if (result.kind === "bad-data") {
          showMessage(result?.errors, 'error', true);
        } else {
          showMessage('An error occurred, please try again later');
        }
      }
    })
    .finally(() => { setLoading(false); getCurrentSubscription(); });
  }
  
  const deleteSubscription = (priceId) => {
    setLoading(true);
    loginStore.environment.api.stripeDeleteSubscription(priceId)
    .then((result) => {
      if (result.kind === "ok") {
        showMessage('The unsubscription has been made successfully.', 'success');
        if (subscriptions.length < 1) {
          getSubscriptions();
        }
      } else {
        if (result.kind === "bad-data") {
          showMessage(result?.errors, 'error', true);
        } else {
          showMessage('An error occurred, please try again later');
        }
      }
    })
    .finally(() => setLoading(false));
  }  
 
  const handleSelectSubscription = (selected) => {
    setShowCreateSubsModal(true)
    setSelectedSubscription(selected);
  }
  
  const handleCloseDialog = () => {
    setShowCreateSubsModal(false);
    setSelectedSubscription(null);
  }
  
  const handleCreateSubscription = (priceId) => {
    createSubscription(priceId);
    setShowCreateSubsModal(false);
    setSelectedSubscription(null);
  }
  
  const handleDeleteSubscription = (priceId) => {
    deleteSubscription(priceId);
    setShowUnsubscribeModal(false);
    setCurrentSubscription({});
  }

  useEffect(() => {
    getCurrentSubscription();
  }, []);
  
  useEffect(()=> {
    if(!CurrentSubscription?.id) {
      getSubscriptions();
    }    
  }, [CurrentSubscription])

  return (
    <ClientPanelLayout title={"Subscriptions"} searchFunc={getSubscriptions} loading={loading}>
      <Grid container spacing={3} justifyContent='space-between' alignItems='center'>
        <Grid item xs={1} md={5} ml={{xs: 1, sm: 2}}>
          <MDTypography variant="h3" fontWeight="medium" mb={1}>
            All subscriptions
          </MDTypography>
        </Grid>
      </Grid>
      <Grid
        className="subs-container"
        container
        flexWrap='nowrap'
        overflow='auto'
        justifyContent='start'
        pl={{xs: 0, sm: 2}}
        spacing={{xs: 45, xl: 40, xxl: 15}}
      >
        {!CurrentSubscription?.id && subscriptions.map((s) => (
          <Grid item xs={3} key={s.id}>
            <div className="subs-item">
              <MDTypography variant="h4">{s?.title}</MDTypography>
              <div className="subs-data">
                <p>{money_fmt(s?.price)}</p>
                <span>
                 {s?.description}
                </span>
              </div>
              <ul className="subs-list">
                {s.assets.map((a) => (
                  <li key={makeid(10)}>
                    <CheckCircleOutlineRoundedIcon/>
                    <p>{a}</p>
                  </li>
                ))}
              </ul>
              <MDButton
                color="green"
                onClick={() => handleSelectSubscription(s)}
              >
               Subscribe
              </MDButton>
            </div>
          </Grid>
        ))}
        {!!CurrentSubscription?.id && <Grid item xs={3} key={CurrentSubscription.id}>
            <div className="subs-item">
              <MDTypography variant="h4">{CurrentSubscription?.title}</MDTypography>
              <div className="subs-data">
                <p>{money_fmt(CurrentSubscription?.price)}</p>
                <span>
                 {CurrentSubscription?.description}
                </span>
              </div>
              <ul className="subs-list">
                {CurrentSubscription?.assets?.map((a) => (
                  <li key={makeid(10)}>
                    <CheckCircleOutlineRoundedIcon/>
                    <p>{a}</p>
                  </li>
                ))}
              </ul>
              <MDButton
                color="error"
                className="btn danger"
                onClick={() => setShowUnsubscribeModal(true)}
              >
               Unsubscribe
              </MDButton>
              {!SubscriptionIsActive && 
                <MDTypography variant="h6" color={"error"} mt={2}>
                  Your current subscription is not active. 
                  Please contact the administrator.
                </MDTypography>
              }
            </div>
          </Grid>}
      </Grid>
      {/* SUBSCRIBE MODAL */}
      <MDDialog
        open={showCreateSubsModal}
        handleClose={handleCloseDialog}
        title={'Subscribe'}
        width={pxToRem(637)}
        padding={`${pxToRem(10)} ${pxToRem(50)} ${pxToRem(26)} ${pxToRem(50)}`}
      >       
         <MDTypography
          variant="h6"
          sx={{
            color: 'black',
            fontWeight: 600,
            marginLeft: pxToRem(16),
            textAlign: 'center',
          }}
        >
          Are you sure you want to subscribe to this option?
        </MDTypography>

        <MDBox
          sx={{
            display: 'flex',
            gap: pxToRem(24),
            marginTop: pxToRem(46),
          }}
        >
          <MDButton
            useBrandingColor={'primary'}
            color="green"
            variant="outlined"
            type="submit"
            className={"button-action"}
            onClick={handleCloseDialog}
          >
            Cancel
          </MDButton>

          <MDButton
            useBrandingColor={'primary'}
            color="green"
            type="submit"
            className={"button-action"}
            onClick={() => handleCreateSubscription(selectedSubscription.default_price)}
          >
            Yes
          </MDButton>
        </MDBox>
      </MDDialog>
      {/* UNSUBSCRIBE MODAL */}
       <MDDialog
        open={showUnsubscribeModal}
        handleClose={() => setShowUnsubscribeModal(false)}
        title={'Unsubscribe'}
        width={pxToRem(637)}
        padding={`${pxToRem(10)} ${pxToRem(50)} ${pxToRem(26)} ${pxToRem(50)}`}
      >       
         <MDTypography
          variant="h6"
          sx={{
            color: 'black',
            fontWeight: 600,
            marginLeft: pxToRem(16),
            textAlign: 'center',
          }}
        >
          Are you sure you want to unsubscribe from this option?
        </MDTypography>

        <MDBox
          sx={{
            display: 'flex',
            gap: pxToRem(24),
            marginTop: pxToRem(46),
          }}
        >
          <MDButton
            useBrandingColor={'primary'}
            color="green"
            variant="outlined"
            type="submit"
            className={"button-action"}
            onClick={() => setShowUnsubscribeModal(false)}
          >
            Cancel
          </MDButton>

          <MDButton
            useBrandingColor={'primary'}
            color="green"
            type="submit"
            className={"button-action"}
            onClick={() => handleDeleteSubscription(CurrentSubscription.priceId)}
          >
            Yes
          </MDButton>
        </MDBox>
      </MDDialog>
    </ClientPanelLayout>
  )
}
