import { useEffect, useState } from "react";
import moment from 'moment'
import { Grid, TableCell, Table, TableBody, TableRow, TableFooter } from '@mui/material';
import AdminLayout from "components/AdminLayout";
import { useNavigate, useParams } from "react-router-dom";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { MenuButton } from "../components/MenuButton";
//icons
import Edit from 'assets/images/icons/edit.png';
import { ROUTES } from 'constants'
import { useStores } from "models";
import { showMessage, padToFour } from "services/helpers";
import '../styles.css';

export default function RequestOpen() {
  const navigate = useNavigate();
  const rootStore = useStores();
  const { id } = useParams();

  const { loginStore } = rootStore;

  const [ClientId, setClientId] = useState(null);
  const [Quote, setQuote] = useState(null);
  const [QuoteDetails, setQuoteDetails] = useState([]);
  const [ShowClientView, setShowClientView] = useState(false);

  const quoteId = "QN" + padToFour(id)

  const calculateMatrixPrice = (matrix) => {
    let aux = 0;
    if (matrix?.matrix_bundles && Array.isArray(matrix?.matrix_bundles)) {
      matrix.matrix_bundles.map(bundle => {
        if (bundle.bundle_items && Array.isArray(bundle.bundle_items)) {
          bundle.bundle_items.map(item => {
            let amount = item.amount || 1
            let each = item.each || 1
            let default_price = item?.product?.default_price || 0
            aux += ((1 / each * amount) * default_price)
          })
        }
      })
    }
    return parseInt(aux)
  }

  const getQuoteDetail = (id) => {
    loginStore.environment.api.getQuoteDetail(id).then((result) => {
      if (result.kind === "ok") {
        setQuote(result.data)
        if (result?.data?.quote_for?.id) setClientId(result?.data?.quote_for?.id)
        let values = []
        let subtotal = 0

        if (Array.isArray(result?.data?.quote_details)) {
          result?.data?.quote_details.map(det => {
            let temp = { ...det }
            if (temp?.product?.id) {
              temp.price = temp?.product?.default_price || 1
              temp.total = parseInt(temp?.product?.default_price || 1) * parseInt(temp.quantity)
              temp.item = temp.product.name
              temp.description = temp.product.description
              values.push(temp)
            } else if (temp?.matrix?.id) {
              temp.product = temp.matrix
              temp.price = calculateMatrixPrice(temp?.product) || 1
              temp.total = calculateMatrixPrice(temp?.product) * parseInt(temp.quantity)
              temp.item = temp.matrix.name
              temp.description = temp.matrix.description
              values.push(temp)
            } else if (temp?.custom && temp.custom !== null) {
              temp.item = temp.custom
              values.push(temp)
            }
            subtotal += temp.total
          })
        }

        setQuoteDetails(values)

      } else showMessage()
    })
  }

  useEffect(() => {
    if (id) getQuoteDetail(id);
  }, []);


  return (
    <AdminLayout title={"Quote Details"}>

      <Grid container alignItems="top" paddingTop={0} marginTop={0}>
        <Grid item xs={12} lg={12} md={12} className="leftContainer">
          <MDTypography variant="h2" fontWeight="medium" mb={1} ml={3} style={{ float: 'left' }}>
            {quoteId} Quote Details
          </MDTypography>

          <MenuButton
            style={{ float: 'right', marginRight: 30, }}
            quote_id={Quote?.id}
            client_email={Quote?.quote_for?.email}
            client_phone={Quote?.quote_for?.phone_number}
            quote_status={Quote?.status}
            client_view_action={() => setShowClientView(!ShowClientView)}
          />

          <MDButton
            color="green"
            useBrandingColor={'primary'}
            iconOnly style={{ float: 'right', marginRight: 20, marginBottom: 10 }}
            onClick={() => navigate(ROUTES.QUOTE_EDIT(id))}
          >
            <img width={'80%'} src={Edit} alt='edit' />
          </MDButton>
        </Grid>
      </Grid>

      <Grid container spacing={3} pb={3} className="task_container">
        <Grid item xs={3} lg={3} md={3}>
          <MDTypography variant="h6" fontWeight="bold">Client information</MDTypography>
          <MDTypography variant="h6">{Quote?.quote_for?.full_name || '-'}</MDTypography>
          <MDTypography variant="h6">{Quote?.quote_for?.address || '-'}</MDTypography>
        </Grid>
        <Grid item xs={3} lg={3} md={3}>
          <MDTypography variant="h6" fontWeight="bold">Date of Quote</MDTypography>
          <MDTypography variant="h6">{Quote?.created_at ? moment(Quote.created_at).format('ll') : '-'}</MDTypography>
          <br />
        </Grid>
        <Grid item xs={6} lg={6} md={6}>
          <MDTypography variant="h6" fontWeight="bold">Title</MDTypography>
          <MDTypography variant="h6">{Quote?.title}</MDTypography>
          <br />
        </Grid>
      </Grid>

      <Grid container className="task_container">
        <Grid item xs={12} lg={12} md={12}>
          <Table>
            <TableBody>
              <TableRow className="t_header_tr">
                <TableCell className="t_header">Item</TableCell>
                <TableCell className="t_header">Description</TableCell>
                <TableCell className="t_header">Quantity</TableCell>
                <TableCell className="t_header">Unit price</TableCell>
                <TableCell className="t_header">Total</TableCell>
              </TableRow>
              {QuoteDetails && QuoteDetails.map((row, i) => (
                <TableRow key={i} className="t_body_tr">
                  <TableCell className="names">{row.item}</TableCell>
                  <TableCell>{row.description}</TableCell>
                  <TableCell>{row.quantity}</TableCell>
                  <TableCell>{row.price}</TableCell>
                  <TableCell>{row.total}</TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell colSpan={5} align="right" className="names">
                  <MDTypography variant="h6">Subtotal: <span className="amount">${Quote?.subtotal}</span></MDTypography>
                  <br />
                  <MDTypography variant="h6">Discount:<span className="discount">-${Quote?.discount}</span></MDTypography>
                  <br />
                  {/* <MDTypography variant="h6">Taxes: <span className="amount">${Quote?.tax}</span></MDTypography>
                  <br /> */}
                  <MDTypography variant="h6" color="green">Total: <span className="amount">${Quote?.total}</span></MDTypography>
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </Grid>
      </Grid>

      {ShowClientView &&
        <>
          <Grid container alignItems="top" paddingTop={0} marginTop={0} >
            <Grid item xs={12} lg={12} md={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
              <MDTypography variant="h3" fontWeight="medium" ml={3} style={{ float: 'left' }}>
                Client View
              </MDTypography>
            </Grid>
          </Grid>

          <Grid container spacing={3} padding={3} className="task_container">
            <Grid item xs={4} lg={4} md={4}>
              <MDTypography variant="h6">{`${moment(Quote?.start_date).format('dddd')}, ${moment(Quote?.start_date).format('ll')}`}</MDTypography>
            </Grid>
            <Grid item xs={4} lg={4} md={4}>
              <MDTypography fontWeight='regular' variant="h6">{moment(Quote?.start_date).format('LT')}</MDTypography>
            </Grid>
            <Grid item xs={4} lg={4} md={4}>
              <MDTypography fontWeight='regular' variant="h6">{Quote?.title}</MDTypography>
            </Grid>
          </Grid>
        </>
      }

    </AdminLayout>
  )
}
