import React, { useEffect, useState } from "react";

import { Link } from 'react-router-dom'

// @mui components
import { Grid } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Form, Formik } from "formik";
import FormikInput from "components/FormikInput";
import MDDialog from "components/MDDialog";

import Chip from '@material-ui/core/Chip';


// General components
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import SimpleTable from "components/SimpleTable";
import AdminLayout from "components/AdminLayout";

// Components
import { FilterButton } from "./components/FilterButton";
import { AddButton } from "./components/AddButton";
import "./styles.css"

// import {} from '../../utils'

// Utils
import { showMessage, useApi } from "services/helpers";
import { dataTableModel, renderTableRow } from "./utils";

export default function Client() {
  const api = useApi();

  const [Data, setData] = useState({ ...dataTableModel });

  const [loading, setLoading] = useState(false);
  const [ClientsModalOpen, setClientsModalOpen] = useState(false);


  const [anchorEl, setAnchorEl] = useState(null);
  const [active, setActive] = useState(false);

  const [file, setFile] = useState(null);

  const open = Boolean(anchorEl);

  const handleUploadFile = (e) => {
    const [file] = e.target.files;
    if (file) setFile(file)
  }

  const getClients = (searchData, extraData) => {
    setLoading(true)
    api.getClients(searchData, extraData).then((result) => {
      if (result.kind === "ok") {
        const { results } = result.data
        const tmp = { ...dataTableModel }
        tmp.rows = results.map(e => renderTableRow(e))
        setData(tmp);

      } else {
        if (result.kind === "bad-data") {
          showMessage(result?.errors, 'error', true)
        } else {
          showMessage('An error occurred, please try again later')
        }
      }
    }).catch(err => showMessage())
      .finally(() => setLoading(false))
  }

  const getClientExport = (searchData, extraData) => {
    setLoading(true)
    api.getClientExport(searchData, extraData).then((result) => {
      if (result.kind === "ok") {
        result.download_file()
      } else showMessage('An error occurred, please try again later')
    }).catch(err => showMessage())
      .finally(() => setLoading(false))
  }

  const getClientImportExample = () => {
    setLoading(true)
    api.getClientImportExample().then((result) => {
      if (result.kind === "ok") {
        result.download_file()
      } else showMessage('An error occurred, please try again later')
    }).catch(err => showMessage())
      .finally(() => setLoading(false))
  }

  const importClients = () => {
    if (!file?.name) {
      showMessage('Must upload a file')
      return
    }
    setLoading(true)
    api.postImportClients({ file })
      .then((result) => {
        showMessage('Clients has been imported', 'success')
        getClients('');
        setClientsModalOpen(false)
        setFile(null)
      })
      .catch(err => showMessage())
      .finally(() => setLoading(false))
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setActive(true);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setActive(false);
  };

  const importClientsModal = <MDDialog
    open={ClientsModalOpen}
    handleClose={() => setClientsModalOpen(false)}
    title='Import Clients'
    paddingTop={10}
    style={{ width: 600, maxHeight: 1000 }}
  >
    <Formik
      validateOnBlur={false}
      validateOnChange={false}
      initialValues={{

      }}
      onSubmit={(values) => importClients(values)}
    >
      {({ errors, setFieldValue, values }) => {
        return (
          <Form>
            <Grid container spacing={{ xs: 2, md: 3, lg: 3 }} alignItems={'flex-start'}>

              <FormikInput
                inputFileStyles={{ backgroundColor: '#60A77C', borderRadius: '8px', width: '38px', height: '38px' }}
                errors={errors}
                name={'total'}
                label='Upload yout CSV file'
                type={'file'}
                useBrandingColor={'primary'}
                handleChange={(e) => handleUploadFile(e)}
                item xs={12}
              />

              <Grid item xs={11} mt={1}>
                <MDTypography variant={'subtitle2'}>{file?.name}</MDTypography>
              </Grid>

              <Grid item xs={12} lg={12} md={12} mb={3} display="flex" justifyContent="space-evenly" alignItems="center">
                <MDButton
                  useBrandingColor={'primary'}
                  color="green"
                  onClick={() => getClientImportExample()}
                  loading={loading}
                  sx={{ width: 200 }}
                >
                  CSV EXAMPLE
                </MDButton>
                <MDButton
                  useBrandingColor={'primary'}
                  color="green"
                  type="submit"
                  loading={loading}
                  sx={{ width: 200 }}
                >
                  IMPORT
                </MDButton>
              </Grid>
            </Grid>
          </Form>
        )
      }}
    </Formik>
  </MDDialog>

  useEffect(() => {
    getClients('');
  }, []);

  return (
    <AdminLayout
      title={"Clients"}
      searchFunc={getClients}
      loading={loading}
    >
      <Grid container alignItems="center" pb={3} pt={{ xs: '50px', lg: '30px' }} mx={4}>

        <Grid item xs={12} md={12} lg={7}>
          <MDTypography useBrandingColor={'tertiary'} variant="h2" fontWeight="medium" width='12rem'>
            All Clients
          </MDTypography>
        </Grid>

        <Grid item xs={12} md={12} lg={5} display='flex'>
          <MDButton
            style={{ width: 210, marginRight: '10px' }}
            color="green"
            useBrandingColor={'primary'}
            onClick={handleClick}
          >
            Import/Export Clients
          </MDButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            className="menu-basics"
            onClose={handleClose}
            MenuListProps={{ 'aria-labelledby': 'basic-button' }}
          >
            <MenuItem onClick={handleClose}>
              <MDButton
                useBrandingColor={'primary'}
                color="green"
                className={'btn-import-sub'}
                onClick={() => setClientsModalOpen(true)}
              > Import
              </MDButton>

            </MenuItem>
            <MenuItem onClick={handleClose}>
              <MDButton
                useBrandingColor={'primary'}
                color="green"
                className={'btn-import-sub'}
                onClick={() => getClientExport('')}
              > Export
              </MDButton>
            </MenuItem>
          </Menu>
          <AddButton />
          {/* <FilterButton /> */}
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} ml={3} >
          <SimpleTable data={Data} />
        </Grid>
      </Grid>
      {importClientsModal}
    </AdminLayout>
  )
}
