import React, { useState } from 'react';
import { Grid } from '@mui/material';
import './headers.css';
import MDButton from 'components/MDButton';
import MDInput from 'components/MDInput';
import MDAutocomplete from 'components/MDAutocomplete';
import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment } from '@mui/material';
import MDTypography from 'components/MDTypography';

export const HeaderFilter = (props) => {
    const { data, styles } = props;

    const Fields = (data) => {
        let myfield;
        if (data.type === 'select') {
            myfield = <MDAutocomplete
                options={data.options}
                defaultValue={data.defaultValue}
                label={data.title}
                placeholder={data.placeholder}
                error={data.error}
            />;
        }
        if (data.type === 'search') {
            myfield = <><MDTypography variant="h6" fontWeight="medium">{data.title}</MDTypography>
                <MDInput
                    fullWidth
                    placeholder={data.placeholder}
                    InputProps={{
                        placeholder: data.placeholder,
                        endAdornment: (<InputAdornment position="end"><SearchIcon fontSize="large" /></InputAdornment>)
                    }}
                /></>
        }
        return myfield;
    }

    return (<div className="boxess-d">
        <Grid container justifyContent={'space-between'} spacing={3} >
            {data.map((row, i) => (<Grid item xs> {Fields(row)} </Grid>)
            )}
            <Grid item xs={12}>
                <MDButton color="green" style={{ float: 'right' }}>Apply Options</MDButton>
            </Grid>
        </Grid>
    </div>);
}