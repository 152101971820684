export const userDetailInitial = {
    "id": null,
    "first_name": "",
    "last_name": "",
    "email": "",
    "phone_number": "",
    "company": null,
    "address_1": "",
    "address_2": "",
    "full_name": "",
    "city_name": "",
    "state_name": "",
    "country_name": "",
    "added_users": 0,
    "inventory": 0,
    "time_spent": 0,
    "subscription_days": 0,
    "trial_days": 0,
    "industry": "",
    "zip_code": ""
}