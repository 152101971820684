import { useEffect, useState } from "react";
import moment from 'moment'
import { Grid, InputAdornment, IconButton } from '@mui/material';
import AdminLayout from "../../../components/AdminLayout";
import { useNavigate } from "react-router-dom";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import MDInput from "../../../components/MDInput";
import MDButton from "../../../components/MDButton";
import MDDatePicker from "../../../components/MDDatePicker";
import {HeaderFilter} from "../components/HeaderFilter";
import {Tables} from "../components/Tables";
import {DateFilter} from "../components/DateFilter";
import "../styles.css"

const header = [
    {title: 'Number', align: '', id: 1},
    {title: 'Name (A-Z)', align: '', id: 2},
    {title: 'Last sent', align: '', id: 3},
    {title: 'Last completed', align: '', id: 4},
    {title: '', align: '', id: 5}
];
const rows = [
    {number: 'JN1000', name: 'Jane Cooper', created: 'July 18, 2022', due: 'July 18, 2022', actions: 'followup_emails', id: 1},
    {number: 'JN1000', name: 'Jane Cooper', created: 'July 18, 2022', due: 'July 18, 2022', actions: 'followup_emails', id: 2},
    {number: 'JN1000', name: 'Jane Cooper', created: 'July 18, 2022', due: 'July 18, 2022', actions: 'followup_emails', id: 3},
    {number: 'JN1000', name: 'Jane Cooper', created: 'July 18, 2022', due: 'July 18, 2022', actions: 'followup_emails', id: 4},
    {number: 'JN1000', name: 'Jane Cooper', created: 'July 18, 2022', due: 'July 18, 2022', actions: 'followup_emails', id: 5},
    {number: 'JN1000', name: 'Jane Cooper', created: 'July 18, 2022', due: 'July 18, 2022', actions: 'followup_emails', id: 6},
    {number: 'JN1000', name: 'Jane Cooper', created: 'July 18, 2022', due: 'July 18, 2022', actions: 'followup_emails', id: 7},
    {number: 'JN1000', name: 'Jane Cooper', created: 'July 18, 2022', due: 'July 18, 2022', actions: 'followup_emails', id: 8},
  ];

const options = [
    {label: 'Option 1', value: 1}, 
    {label: 'Option 2', value: 2},
    {label: 'Option 3', value: 3},
    {label: 'Option 4', value: 4}
];

const data = [
    {title: '-', type: 'search', options: null, class:'', error: true, placeholder:'search....', defaultValue: '', id: 1},
    {title: 'Visits within', type: 'select', options: options, class:'', error: true, placeholder:'This week', defaultValue: '', id: 2}
  ];

export default function ClientBalanceSummary(props) {
    const navigate = useNavigate();

  return (
    <AdminLayout title={"Follow up Emails Report"}>
        <Grid container alignItems="top" paddingTop={0} marginTop={0} spacing={3}>
            <Grid item xs={12} lg={12} md={12} mb={2}>
                <MDTypography variant="h2" fontWeight="medium" mb={1} ml={3} style={{ float: 'left' }}>
                    Follow up Emails Report
                </MDTypography>
            </Grid>
           
            <Grid item xs={12} lg={12} md={12} mb={3}>
                <HeaderFilter data={data}/>
            </Grid>
        </Grid>
        <Grid container spacing={3}>
            <Grid item xs={12} lg={12} md={12} ml={2}>
                <MDTypography variant="h3" fontWeight="medium" mb={1} style={{ float: 'left' }}>
                    List
                </MDTypography>
                <DateFilter style={{ float: 'right', display: 'inline', marginBottom: 10 }} range={false}/>
                <Tables body={rows} header={header}/>
            </Grid>
        </Grid>
    </AdminLayout>
  )
}
