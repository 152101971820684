import React, { Children, useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from "react-router-dom";
import AdminLayout from "../../components/AdminLayout";
import { CalendarToolbar } from "./component/CalendarToolbar";
import { JobContent } from "./component/JobContent";
import ThimeCard from "../../components/ThimeCard";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import moment from 'moment'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import Grid from '@mui/material/Grid';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css'
import "react-big-calendar/lib/css/react-big-calendar.css"
import "./react-big-calendar.css"
import styles from './Scheduler.module.css'
import WeeksHeader from "./component/WeeksHeader";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import MDButton from "components/MDButton";
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import EventIcon from '@mui/icons-material/Event';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import { Link } from 'react-router-dom';
import TimelinePage from "screens/scheduler/timeline";

import { useStores } from "models";
import { showMessage } from "services/helpers";
import { ROUTES } from "../../constants";

const DragAndDropCalendar = withDragAndDrop(Calendar)

export default function SchedulerPage() {
  const navigate = useNavigate();
  const rootStore = useStores();
  const { loginStore } = rootStore;
  const [myEvents, setMyEvents] = useState([]);
  const [Unscheduled, setUnscheduled] = useState([])
  const [draggedEvent, setDraggedEvent] = useState()
  const [start, setStart] = useState("start")
  const [end, setEnd] = useState("end")
  const [views, setViews] = useState("month")
  const [counters, setCounters] = useState({ item1: 0, item2: 0 })
  const [contextMenu, setContextMenu] = useState(null);
  const [thisDate, setThisDate] = useState();
  const [thisGroup, setThisGroup] = useState();
  const [Unsigned, setUnsigned] = useState([]);
  const [Team, setTeam] = useState([]);

  moment.locale("es-es", { week: { dow: 1 } })

  const localizer = momentLocalizer(moment)

  const formats = {
    dayFormat: (date, culture, localizer) => localizer.format(date, 'D ddd', culture),
    weekdayFormat: (date, culture, localizer) => localizer.format(date, 'dddd', culture),
    timeGutterFormat: (date, culture, localizer) => localizer.format(date, 'h A', culture),
  }

  const onNavigate = useCallback((date, view) => {
    getTasks('')
    if (view === 'week') {
      setStart(moment(date).startOf('month').startOf('week'));
      setEnd(moment(date).endOf('month').endOf('week'));
    }
    setViews(view);
  }, []);

  const eventPropGetter = useCallback(
    (event) => ({
      style: {
        borderRadius: "16px",
        backgroundColor: event.color,
        marginLeft: "5px",
        marginRight: "5px",
        width: event.repeat > 0 ? "20px" : "95%",
      }
    }), []);

  const handleDragStart = useCallback((event) => {
    setDraggedEvent(event)
  }, [])

  const dragFromOutsideItem = useCallback(() => draggedEvent, [draggedEvent])

  const moveEvent = useCallback(
    ({ event, start, end, isAllDay: droppedOnAllDaySlot = false }) => {
      const { allDay } = event
      if (!allDay && droppedOnAllDaySlot) event.allDay = true
      updateItem(event, start, end)
      setMyEvents((prev) => {
        const existing = prev.find((ev) => ev.id === event.id) ?? {}
        const filtered = prev.filter((ev) => ev.id !== event.id)
        return [...filtered, { ...existing, start, end, allDay }]
      })
    },
    [setMyEvents]
  )

  const newEvent = useCallback(
    (event) => {
      setMyEvents((prev) => {
        const idList = prev.map((item) => item.id)
        const newId = Math.max(...idList) + 1
        return [...prev, { ...event, id: newId }]
      })
    },
    [setMyEvents]
  )

  const onDropFromOutside = useCallback(
    ({ start, end, allDay: isAllDay }) => {
      if (draggedEvent === 'undroppable' || draggedEvent.assign_to === null) {
        setDraggedEvent(null)
        return
      }
      updateItem(draggedEvent, start, end)
      const { name } = draggedEvent
      const event = {
        title: draggedEvent.title,
        start,
        end,
        isAllDay,
      }
      setDraggedEvent(null)
      setCounters((prev) => {
        const { [name]: count } = prev
        return {
          ...prev,
          [name]: count + 1,
        }
      })
      newEvent(event)
    },
    [draggedEvent, counters, setDraggedEvent, setCounters, newEvent]
  )

  const resizeEvent = useCallback(
    ({ event, start, end }) => {
      setMyEvents((prev) => {
        updateItem(event, start, end)
        const existing = prev.find((ev) => ev.id === event.id) ?? {}
        const filtered = prev.filter((ev) => ev.id !== event.id)
        return [...filtered, { ...existing, start, end }]
      })
    },
    [setMyEvents]
  )

  const EventView = ({ event }) =>
    event.repeat > 0
      ? <MDBox
        style={{ display: "flex", flexDirection: "column", padding: 6, marginLeft: "5px", marginTop: "2px", marginBottom: "2px", height: 5, width: 5 }}
        onClick={event.action}
      />
      : <MDBox
        style={{ display: "flex", flexDirection: "column", marginTop: "2px", marginBottom: "2px", height: 40, paddingLeft: 7 }}
        // onClick={() => navigate(ROUTES.EDIT_TASK(event.id))}
        onClick={event.action}
      >
        <MDTypography variant={"button"} color={"#303134"} fontWeight={"regular"} style={{ fontSize: 14 }}>
          {moment(event.start).format("hh:mm A")}
        </MDTypography>
        <MDTypography variant={"button"} color={"#303134"} fontWeight={"medium"} style={{ fontSize: 14, textTransform: "uppercase" }}>
          {event.title}
        </MDTypography>
      </MDBox>


  const EventViewDayWeek = ({ event }) =>
    event.repeat > 0
      ? <MDBox
        onClick={event.action}
        style={{ display: "flex", flexDirection: "column", padding: 6, marginLeft: "5px", marginTop: "2px", marginBottom: "2px", height: 5, width: 5 }}
      />
      : <MDBox
        style={{ display: "flex", flexDirection: "column", marginTop: "2px", marginBottom: "2px", height: 40, paddingLeft: 7 }}
        onClick={event.action}
      >
        <MDTypography variant={"button"} color={"#303134"} fontWeight={"regular"} style={{ fontSize: 18 }}>
          {event.title}
        </MDTypography>
        <MDTypography variant={"button"} color={"var(--grey)"} fontWeight={"medium"} style={{ fontSize: 14 }}>
          {moment(event.start).format("hh:mm A")} - {moment(event.end).format("hh:mm A")}
        </MDTypography>
      </MDBox>

  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
          mouseX: event.clientX + 2,
          mouseY: event.clientY - 6,
        }
        : null
    );
  };

  const ItemContextMenu = (groupId, time, e) => {
    setThisDate(moment(time).format('YYYY-MM-DD_HH:mm'));
    setThisGroup(groupId);
  }

  const getJobs = (data, unscheduled, unsigned) => {
    loginStore.environment.api.getJobs('')
      .then((result) => {
        if (result.kind === "ok") {
          let results = result?.data?.results || []
          results.map(t => {
            // unscheduled
            if (t.start_date == null) {
              unscheduled.push({
                id: t.id,
                type: 'job',

                title: t.title,
                desc: t.instructions,
                isDraggable: 1,
                for: { id: t?.quote?.client_id, name: t?.quote?.quote_for },
                color: "var(--mint)",
                action: () => navigate(ROUTES.EDIT_JOB(t.id))
              })
            } else {
              data.push({
                id: t.id,
                type: 'job',
                title: t.title,
                start: new Date(t.start_date?.replace(':00Z', '')),
                end: new Date(t.end_date?.replace(':00Z', '')),
                desc: t.instructions,
                isDraggable: 1,
                for: { id: t?.quote?.client_id, name: t?.quote?.quote_for },
                color: "var(--mint)",
                action: () => navigate(ROUTES.EDIT_JOB(t.id))
              })
            }
          });
          // unsigned
          if (results.some(r => r.assign_to === null)) {
            unsigned = results
              .filter(r => r.assign_to === null)
              .map(t => ({ action: () => navigate(ROUTES.EDIT_JOB(t.id)), title: t.title, name: t?.task_for?.full_name, ...t }))
          }
        }
      })
      .catch(() => showMessage())
      .finally(() => {
        setUnscheduled(unscheduled)
        setUnsigned(unsigned)
        setMyEvents(data)
      })
  }

  const getEvents = (data, unscheduled, unsigned) => {
    loginStore.environment.api.getAllEvents()
      .then((result) => {
        if (result.kind === "ok") {
          const events = [];
          let results = result?.data?.results || []
          results.map(t => {
            // let repeats = results.filter(e => moment(e.start).format('YYY-MM-DD') === moment(t.start_date).format('YYY-MM-DD')).length;
            events.push({
              id: t.id,
              type: 'event',
              title: t.title,
              start: new Date(t.start_date?.replace(':00Z', '')),
              end: new Date(t.end_date?.replace(':00Z', '')),
              desc: t.instructions,
              isDraggable: 1,
              repeat: t.repeats,
              color: "var(--pink)",
              action: () => navigate(ROUTES.EDIT_EVENT(t.id))
            })
          });
          data = [...data, ...events]
        }
      })
      .catch(() => showMessage())
      .finally(() => getRequests(data, unscheduled, unsigned))
  }

  const getRequests = (data, unscheduled, unsigned) => {

    loginStore.environment.api.getRequests('')
      .then((result) => {
        if (result.kind === "ok") {
          const requests = [];
          const requestsUnscheduled = [];
          const requestsUnsigned = [];

          let results = result?.data?.results || []
          results.map(t => {


            if (t.start_date == null) {
              requestsUnscheduled.push({
                id: t.id,
                type: 'request',
                title: t.title,
                desc: t.instructions,
                isDraggable: 1,
                color: "var(--purple)",
                action: () => navigate(ROUTES.REQUEST_EDIT(t.id))
              })
            } else {
              requests.push({
                id: t.id,
                type: 'request',
                title: t.title,
                start: new Date(t.start_date?.replace(':00Z', '')),
                end: new Date(t.end_date?.replace(':00Z', '')),
                desc: t.instructions,
                isDraggable: 1,
                color: "var(--purple)",
                action: () => navigate(ROUTES.REQUEST_EDIT(t.id))
              })

              if (t.assign_to === null) {
                requestsUnsigned.push({
                  id: t.id,
                  type: 'request',
                  title: t.title,
                  start: new Date(t.start_date?.replace(':00Z', '')),
                  end: new Date(t.end_date?.replace(':00Z', '')),
                  desc: t.instructions,
                  isDraggable: 1,
                  color: "var(--purple)",
                  action: () => navigate(ROUTES.REQUEST_EDIT(t.id))
                })
              }

            }

          });
          data = [...data, ...requests]
          unscheduled = [...unscheduled, ...requestsUnscheduled]
          unsigned = [...unsigned, ...requestsUnsigned]
        }
      })
      .catch(() => showMessage())
      .finally(() => getJobs(data, unscheduled, unsigned))
  }


  const getTasks = (searchData, extraData) => {
    // get all Tasks
    let data = [];
    let unscheduled = [];
    let unsigned = [];

    loginStore.environment.api.getAllTasks(searchData, extraData)
      .then((result) => {
        if (result.kind === "ok") {
          let results = result?.data?.results || []
          results.map(t => {
            // unscheduled
            if (t.start_date == null) {
              unscheduled.push({
                id: t.id,
                group: t?.task_for?.id,
                type: 'task',
                title: t.title,
                desc: t.instructions,
                isDraggable: 1,
                for: t.task_for,
                color: "var(--mint)",
                action: () => navigate(ROUTES.EDIT_TASK(t.id))
              })
            } else {
              let repeats = results.filter(e => moment(e.start).format('YYY-MM-DD') === moment(t.start_date).format('YYY-MM-DD')).length;
              data.push({
                id: t.id,
                type: 'task',
                group: t?.task_for?.id,
                title: t.title,
                start: new Date(t.start_date?.replace(':00Z', '')),
                end: new Date(t.end_date?.replace(':00Z', '')),
                desc: t.instructions,
                isDraggable: 1,
                for: t.task_for,
                repeat: repeats,
                color: "var(--light-green)",
                action: () => navigate(ROUTES.EDIT_TASK(t.id))
              })
            }
          });
          // unsigned
          if (results.some(r => r.assign_to === null)) {
            unsigned = results
              .filter(r => r.assign_to === null)
              .map(t => ({ action: () => navigate(ROUTES.EDIT_TASK(t.id)), title: t.title, name: t?.task_for?.full_name, ...t }))
          }
        }
      })
      .catch(() => showMessage())
      .finally(() => getEvents(data, unscheduled, unsigned))
  }

  const calendarComponents = {
    toolbar: CalendarToolbar,
    day: {
      header: ({ date, localizer }) => localizer.format(date, 'dddd'),
      event: EventViewDayWeek,
    },
    week: {
      header: WeeksHeader,
      event: EventViewDayWeek,
    },
    month: {
      event: EventView,
    },
  }

  const eventMenu = <Menu
    id="basic-menu"
    className="menu-basics"
    onClose={() => setContextMenu(null)}
    MenuListProps={{ 'aria-labelledby': 'basic-button' }}
    open={contextMenu !== null}
    anchorReference="anchorPosition"
    anchorPosition={
      contextMenu !== null
        ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
        : undefined
    }
  >
    <Typography className="menu-title">Add to date</Typography>
    <MenuItem onClick={() => setContextMenu(null)}>
      <MDButton
        color="green"
        className={'btn-import-sub'}
        startIcon={<BusinessCenterIcon className="btn-import-sub-icon" />}
        component={Link}
        to={ROUTES.NEW_JOB}
      >
        New Job
      </MDButton>
    </MenuItem>
    <MenuItem onClick={() => setContextMenu(null)}>
      <MDButton
        color="green"
        className={'btn-import-sub'}
        startIcon={<EventAvailableIcon fontSize="large" />}
        component={Link}
        to={ROUTES.NEW_TASK(thisDate, thisGroup)}
      >
        New Task
      </MDButton>
    </MenuItem>
    <MenuItem onClick={() => setContextMenu(null)}>
      <MDButton
        color="green"
        className={'btn-import-sub'}
        startIcon={<EventIcon fontSize="large" />}
        onClick={() => navigate(ROUTES.NEW_EVENT)}
      >
        New Event
      </MDButton>
    </MenuItem>
  </Menu>

  const unscheduledBox = <MDBox pl={1} pr={1} mb={3}>
    <ThimeCard color={'var(--light-green)'} title={'Unscheduled'}>
      {Unscheduled.length > 0
        ? Unscheduled.map((value, i) =>
          <React.Fragment key={i}>
            <JobContent
              title={value.title}
              name={value.title}
              index={i}
              onDragStart={() => handleDragStart({ ...value })}
            />
          </React.Fragment>
        )
        : <MDTypography
          variant="h6" fontWeight={"medium"}
          style={{ color: "var(--light-black)", textAlign: 'center', paddingTop: '1rem' }}
        >
          There are no unscheduled jobs
        </MDTypography>
      }
    </ThimeCard>
  </MDBox>

  const unsignedBox = <MDBox p={1}>
    <ThimeCard color={'var(--mint)'} title={'Unsigned Jobs'}>
      {Unsigned.length > 0
        ? Unsigned.map((value, i) => <React.Fragment key={i} >
          <JobContent
            onClick={value.action}
            index={i}
            title={value.title}
            name={value.name}
            onDragStart={() => handleDragStart({ ...value })}

          />
        </React.Fragment>
        )
        : <MDTypography
          variant="h6" fontWeight={"medium"}
          style={{ color: "var(--light-black)", textAlign: 'center', paddingTop: '1rem' }}
        >
          There are no unsigned jobs
        </MDTypography>
      }
    </ThimeCard>
  </MDBox>

  const updateItem = (event, start, end) => {
    let data = {
      id: event.id,
      end_date: moment(end).format('YYYY-MM-DD hh:mm:ss'),
      start_date: moment(start).format('YYYY-MM-DD hh:mm:ss')
    }

    switch (event?.type) {
      case 'event':
        loginStore.environment.api.updateEvent(data).then((result) => {
          if (result.kind === 'ok') {
            showMessage('The Event has been updated', 'success')
          } else showMessage(result?.errors, 'error', true)
        })
        break;
      case 'job':
        loginStore.environment.api.updateJob(data).then((result) => {
          if (result.kind === 'ok') {
            showMessage('The Job has been updated', 'success')
          } else showMessage(result?.errors, 'error', true)
        })
        break;
      case 'task':
        loginStore.environment.api.updateTask(data).then((result) => {
          if (result.kind === 'ok') {
            showMessage('The Task has been updated', 'success')
          } else showMessage(result?.errors, 'error', true)
        })
        break;
      case 'request':
        loginStore.environment.api.updateRequest({ ...data, sight_assessment_required: true }).then((result) => {
          if (result.kind === 'ok') {
            showMessage('The Request has been updated', 'success')
          } else showMessage(result?.errors, 'error', true)
        })
        break;
      default:
        break;
    }
  }

  const getEmployes = () => {
    loginStore.environment.api.getTeamMembers('')
      .then((result) => {
        const { data } = result
        const { results } = data
        setTeam(results.map((r => ({ ...r, title: r.name }))))
      })
      .catch(() => showMessage())
  }

  useEffect(() => {
    getTasks('')
    getEmployes()
  }, []);

  return (
    <AdminLayout title={"Scheduler"}>
      <Grid container alignItems="top" paddingTop={0} marginTop={0}>
        <Grid item xs={12} lg={9} md={9} className={styles.leftContainer}>
          <div onContextMenu={handleContextMenu} style={{ cursor: 'context-menu' }}>
            {views === 'agenda'
              ? <TimelinePage
                onView={(newView) => setViews(newView)}
                events={myEvents}
                groups={Team}
              />
              : <DragAndDropCalendar
                defaultView={views}
                view={views}
                dragFromOutsideItem={true}
                events={myEvents}
                components={calendarComponents}
                showMultiDayTimes={true}
                showAllEvents={false}
                localizer={localizer}
                onDropFromOutside={onDropFromOutside}
                onEventDrop={moveEvent}
                onEventResize={resizeEvent}
                onSelectSlot={ItemContextMenu}
                startAccessor={start}
                endAccessor={end}
                formats={formats}
                onNavigate={onNavigate}
                onView={(newView) => setViews(newView)}
                onShowMore={() => setViews("week")}
                resizable
                selectable
                style={{ minHeight: 500 }}
                eventPropGetter={eventPropGetter}
              />
            }
            {eventMenu}
          </div>
        </Grid>
        <Grid item xs={12} lg={3} md={3} display={"flex"} p={3}
          flexDirection={"column"}
          justifyContent={"flex-start"}
          className={styles.rightContainer}
          style={{ marginTop: 80 }}
        >
          {unscheduledBox}
          {unsignedBox}
        </Grid>
      </Grid>

    </AdminLayout>
  )
}
