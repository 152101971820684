import { useEffect, useState } from "react";
import moment from 'moment'
import { Grid } from '@mui/material';
import ClientPanelLayout from "components/ClientPanelLayout";
import MDTypography from "components/MDTypography";
import "./styles.css"

import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

import { useStores } from "models";
import { showMessage } from "services/helpers";

export default function Quotes() {
  const rootStore = useStores()
  const { loginStore } = rootStore

  return (
    <ClientPanelLayout title={"Contact Us"}>
      
      <Grid container alignItems="top" paddingTop={0} marginTop={0}>
        <Grid item xs={12} lg={9} md={9} className="leftContainer">
          <MDTypography variant="h2" fontWeight="medium" ml={4} mt={1}>
            Contact information
          </MDTypography>
        </Grid>
      </Grid>

      <Grid container spacing={3} pb={3} className="task_container">
        <Grid item xs={12} lg={12} md={12}>
          <MDTypography variant="h6" fontWeight="bold">Message</MDTypography>
          <MDInput
            fullWidth
            placeholder='input message here'
            multiline rows={3
            }
          />
        </Grid>
        <Grid item xs={12} lg={12} md={12} mt={3} display="flex" justifyContent="center" alignItems="center">
          <MDButton
            color="green"
            type="submit"
            variant="outlined"
            className="btn-save"

          >
            Cancel
          </MDButton>
          <MDButton
            color="green"
            type="submit"
            className="btn-save"

          >
            Send
          </MDButton>
        </Grid>
      </Grid>

      <Grid container alignItems="top" paddingTop={0} marginTop={0}>
        <Grid item xs={12} lg={9} md={9} className="leftContainer">
          <MDTypography variant="h2" fontWeight="medium" ml={4} mt={1}>
            Feedback
          </MDTypography>
        </Grid>
      </Grid>

      <Grid container spacing={3} pb={3} className="task_container">
        <Grid item xs={12} lg={6} md={6}>
          <MDTypography variant="h6" fontWeight="bold">Email Address</MDTypography>
          <MDInput
            fullWidth
            placeholder='input email address'
          />
        </Grid>
        <Grid item xs={12} lg={6} md={6}>
          <MDTypography variant="h6" fontWeight="bold">Details</MDTypography>
          <MDInput
            fullWidth
            placeholder='input feedback here'
            multiline rows={3}
          />
        </Grid>
        <Grid item xs={12} lg={12} md={12} mt={3} display="flex" justifyContent="center" alignItems="center">
          <MDButton
            color="green"
            type="submit"
            variant="outlined"
            className="btn-save"

          >
            Cancel
          </MDButton>
          <MDButton
            color="green"
            type="submit"
            className="btn-save"

          >
            Send
          </MDButton>
        </Grid>
      </Grid>

    </ClientPanelLayout>
  )
}
