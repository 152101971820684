
import { useState, useEffect, useRef, useContext } from "react";

import { observer } from "mobx-react-lite";

import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import Sidenav from "components/Sidenav";
import PhoneCard from "components/PhoneCard";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "./DashboardNavbar";

import { useIsMobile } from "services/hooks";

import { useStores } from 'models'
import { ProgressLoading } from "../ProgressLoading";
import MDDialog from "components/MDDialog";

import { ROUTES } from 'constants'
import { formatTimeSeconds } from 'services/helpers'

import CallFloatBox from './callFloatBox'

const AdminLayout = observer(function AdminLayout({ children, title, loading = false, searchFunc = null }) {
  
  const rootStore = useStores()
  const modalRef = useRef();

  const { loginStore } = rootStore
  const isMobile = useIsMobile();

  const phoneModal = <MDDialog
    open={loginStore.sidePhoneOpen}
    handleClose={() => loginStore.setSidePhoneOpen(false)}
  
    paddingTop={10}
    style={{ width: '20rem', height: '45rem' }}
  >
    <PhoneCard />
  </MDDialog>


  return (
    <DashboardLayout>
      <Sidenav
        brand={loginStore.branding_data?.logo}
        brandName={loginStore.branding_data && loginStore.branding_data.name_on_logo ? loginStore.company_name : ''}
        routes={[
          {
            type: "collapse",
            hasAction: true,
            noCollapse: true,
            name: "Create",
            key: "create",
            route: "",
            icon: <Icon fontSize="medium">add_box</Icon>,
            collapse: [
              {
                name: "New client",
                key: "new-client",
                icon: 'person',
                route: ROUTES.CLIENTS_NEW,
              },
              {
                name: "New quote",
                key: "new-quote",
                icon: 'assignment',
                route: ROUTES.NEW_QUOTES,
              },
              {
                name: "New request",
                key: "new-request",
                icon: 'room_service',
                route: ROUTES.NEW_REQUESTS,
              },
            ],
          },
          {
            type: "collapse",
            noCollapse: true,
            name: "Home",
            key: "home",
            route: ROUTES.HOME,
            icon: <Icon fontSize="medium">home</Icon>,
          },
          {
            type: "collapse",
            noCollapse: true,
            name: "Scheduler",
            key: ["scheduler", "timeline"],
            route: ROUTES.SCHEDULER,
            icon: <Icon fontSize="medium">calendar_month</Icon>,
          },
          {
            type: "collapse",
            noCollapse: true,
            name: "Clients",
            key: "clients",
            route: ROUTES.CLIENTS,
            icon: <Icon fontSize="medium">person</Icon>,
          },

          {
            type: "collapse",
            noCollapse: true,
            name: "Requests",
            key: "requests",
            route: ROUTES.REQUESTS,
            icon: <Icon fontSize="medium">notifications_active</Icon>,
          },
          {
            type: "collapse",
            noCollapse: true,
            name: "Quotes",
            key: "quotes",
            route: ROUTES.QUOTES,
            icon: <Icon fontSize="medium">history_edu</Icon>,
          },
          {
            type: "collapse",
            noCollapse: true,
            name: "Jobs",
            key: "jobs",
            route: ROUTES.JOBS,
            icon: <Icon fontSize="medium">work_outline</Icon>,
          },
          {
            type: "collapse",
            noCollapse: true,
            name: "Invoices",
            key: "invoices",
            route: ROUTES.INVOICES,
            icon: <Icon fontSize="medium">request_quote</Icon>,
          },
          {
            type: "collapse",
            noCollapse: true,
            name: "Inventory",
            key: ['inventory', 'matrix', 'item'],
            route: ROUTES.INVENTORY,
            icon: <Icon fontSize="medium">inventory_2</Icon>,
          },
          {
            type: "collapse",
            noCollapse: true,
            name: "Messages",
            key: "messages",
            route: ROUTES.MESSAGES,
            icon: <Icon fontSize="medium">mail</Icon>,
          },

          {
            type: "collapse",
            noCollapse: true,
            name: "Timesheet",
            key: "timesheet",
            route: ROUTES.TIMESHEET,
            icon: <Icon fontSize="medium">scheduler</Icon>,
          },
          {
            type: "collapse",
            noCollapse: true,
            name: "Reports",
            key: "reports",
            route: ROUTES.REPORTS,
            icon: <Icon fontSize="medium">article</Icon>,
          },
          {
            type: "collapse",
            noCollapse: true,
            name: "Expenses",
            key: "expenses",
            route: ROUTES.EXPENSES,
            icon: <Icon fontSize="medium">receipt_long</Icon>,
          },
          {
            type: "collapse",
            noCollapse: true,
            name: "Logout",
            key: "logout",
            route: "",
            icon: <Icon fontSize="medium">logout</Icon>,
          },
        ]}
      />
      <DashboardNavbar title={title} loading={loading} searchFunc={searchFunc} />
      <ProgressLoading show={loading} />

      <MDBox py={3} style={{ borderTop: !isMobile && '1px solid #303134' }}>
        {children}
        {phoneModal}
      </MDBox>

        <CallFloatBox />

    </DashboardLayout>
  );
})

export default AdminLayout;
