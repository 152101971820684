import { useState, useEffect, useRef } from "react";
import { observer } from "mobx-react-lite";
import moment from 'moment'

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";
import Modal from "@mui/material/Modal";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import HomeCard from "components/HomeCard";
import PhoneCard from "components/PhoneCard";
import AdminLayout from "components/AdminLayout";
import SimpleTable from "components/SimpleTable";

// Utils
import { useStores } from "models";
import { showMessage } from "services/helpers";
import { useIsMobile } from "services/hooks";
import { useNavigate } from "react-router-dom";
import { ROUTES } from 'constants'

// Images
import ClockIcon from 'assets/images/icons/clock.svg';

const datatableModel = {
  columns: [
    { Header: "Name (A-Z)", accessor: "name", width: "25%" },
    { Header: "Title", accessor: "title", width: "25%" },
    { Header: "Time", accessor: "start_time", width: "25%" },
    { Header: "", accessor: "action", width: "25%", sorted: false },
  ],
  rows: []
}

const Home = observer(function HomeScreen() {

  const modalRef = useRef();
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  const rootStore = useStores()
  const { loginStore } = rootStore

  const [loading, setLoading] = useState(false);
  const [GridSize, setGridSize] = useState(12);
  const [Data, setData] = useState({ ...datatableModel });

  const [RequestCard, setRequestCard] = useState({
    new: 0,
    assessment_completed: 0,
    overdue: 0,
  })
  const [QuotesCard, setQuotesCard] = useState({
    approved: 0,
    changes_requested: 0,
    drafts: 0,
  })
  const [JobsCard, setJobsCard] = useState({
    requires_invoicing: 0,
    late: 0,
    active_jobs: 0,
  })
  const [InvoicesCard, setInvoicesCard] = useState({
    past_due: 0,
    awaiting_payment: 0,
    draft: 0,
  })

  const getHomeCardValues = () => {
    // request
    loginStore.environment.api.getRequestStatus().then((result) => {
      if (result.kind === "ok") {
        setRequestCard({
          new: result?.data?.ALL || 0,
          assessment_completed: result?.data?.assessment_completed || 0,
          overdue: result?.data?.overdue || 0,
        })
      }
    })
    // quotes
    loginStore.environment.api.getQuoteStatus().then((result) => {
      if (result.kind === "ok") {
        setQuotesCard({
          approved: result?.data?.APPROVED || 0,
          changes_requested: result?.data?.CHANGES_REQUESTED || 0,
          drafts: result?.data?.DRAFT || 0,
        })
      }
    })
    // jobs
    loginStore.environment.api.getJobStatus().then((result) => {
      if (result.kind === "ok") {
        setJobsCard({
          requires_invoicing: result?.data?.REQUIRES_INVOICE || 0,
          late: result?.data?.LATE || 0,
          active_jobs: result?.data?.TODAY || 0,
        })
      }
    })
    // invoice
    loginStore.environment.api.getInvoiceStatus().then((result) => {
      if (result.kind === "ok") {
        setInvoicesCard({
          past_due: result?.data?.PAST_DUE || 0,
          awaiting_payment: result?.data?.AWAITING_PAYMENT || 0,
          draft: result?.data?.DRAFT || 0,
        })
      }
    })
  }



  const createTimeEntryReq = (data, currentClocking) => {
    setData({ ...datatableModel })
    let temp
    currentClocking
      ? temp = {
        ...currentClocking,
        start_user: currentClocking?.start_user?.id,
        finish_user: loginStore.id,
        end_date: moment().format(),
      }
      : temp = {
        task: data.id,
        start_date: moment().format(),
        end_date: null,
        start_user: loginStore.id,
        finish_user: null,
      }
    loginStore.environment.api.createTimeEntry(temp).then((result) => {
      if (result.kind === "ok") {
        getAllTasks()
      } else {
        if (result.kind === "bad-data") {
          showMessage(result?.errors, 'error', true)
        } else {
          showMessage('An error occurred, please try again later')
        }
      }
    })
  }

  const getAllTasks = (searchData, extraData) => {
    setLoading(true)
    loginStore.environment.api.getAllTasks(searchData, extraData)
      .then((result) => {
        if (result.kind === "ok") {
          let data = { ...datatableModel }
          data.rows = result.data.results.map(r => {

            // get current clocking
            let currentClocking
            if (Array.isArray(r.task_timeEntries)) {
              currentClocking = r.task_timeEntries.find(c => c.end_date === null)
            }

            let props = {
              onClick: () => createTimeEntryReq(r, currentClocking),
              variant: "gradient",
              color: currentClocking ? 'error' : "success",
              // useBrandingColor: 'primary'
            }

            if (!currentClocking) props.useBrandingColor = 'primary'

            return {
              name: r?.task_for?.first_name,
              start_time: moment(r.start_date).format('DD/MM/YYYY HH:mm'),
              currentClocking,
              actionFunc: () => createTimeEntryReq(r, currentClocking),
              action: <MDButton
                {...props}
              >{
                  !isMobile
                    ? currentClocking ? 'End Clocking' : "Start Clocking"
                    : <img src={ClockIcon} alt='clock icon' />
                }</MDButton>,
              ...r
            }
          })
          setData(data)
        } else {
          if (result.kind === "bad-data") {
            showMessage(result?.errors, 'error', true)
          } else {
            showMessage('An error occurred, please try again later')
          }
        }
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    getAllTasks('');
    getHomeCardValues()
  }, []);

  // useEffect(() => {
  //   !isMobile && setGridSize(loginStore.sidePhoneOpen ? 9 : 12)
  // }, [loginStore.sidePhoneOpen])

  return (
    <AdminLayout
      title={"Clients"}
      searchFunc={getAllTasks}
      loading={loading}
    >
      <MDBox>
        <Grid container spacing={3}>
          <Grid item xs={GridSize} md={GridSize}>
            <MDBox mt={1.5}>
              <Grid item xs={12} md={12} lg={12}>
                <Grid container spacing={{ xs: 35, lg: 15, xl: 25, xxl: 3 }} flexWrap='nowrap' overflow='auto'>
                  <Grid item xs={3} md={3} lg={3}>
                    <MDBox mb={1.5}>
                      <HomeCard
                        color="#FFDBA4"
                        iconColor="success"
                        icon="add_box"
                        icon_navigate={() => navigate(ROUTES.NEW_REQUESTS)}
                        title="Requests"
                        info={[
                          {
                            color: "#366B65",
                            amount: RequestCard?.new || 0,
                            label: "New",
                            icon: <Icon className="material-icons-outlined" fontSize="large"
                              style={{ color: 'white' }}>calendar_month</Icon>,
                            icon_navigate: () => navigate(ROUTES.SCHEDULER)
                          },
                          {
                            color: "#366B65",
                            amount: RequestCard?.assessment_completed || 0,
                            label: "Assesments completed",
                            icon: <Icon className="material-icons-outlined" fontSize="large"
                              style={{ color: 'white' }}>visibility</Icon>,
                            icon_navigate: () => navigate(`${ROUTES.REQUESTS}/assessment_completed`)
                          },
                          {
                            color: "#366B65",
                            amount: RequestCard?.overdue || 0,
                            label: "Overdue",
                            icon: <Icon className="material-icons-outlined" fontSize="large"
                              style={{ color: 'white' }}>visibility</Icon>,
                              icon_navigate: () => navigate(`${ROUTES.REQUESTS}/overdue`)
                          }
                        ]}
                      />
              
                    </MDBox>
                  </Grid>
                  <Grid item xs={3} md={3} lg={3}>
                    <MDBox mb={1.5}>
                      <HomeCard
                        color="#FFBAAA"
                        iconColor="success"
                        icon="add_box"
                        icon_navigate={() => navigate(ROUTES.NEW_QUOTES)}
                        title="Quotes"
                        info={[
                          {
                            color: "#366B65",
                            amount: QuotesCard?.approved || 0,
                            label: "Approved",
                            icon: <Icon className="material-icons-outlined" fontSize="large"
                              style={{ color: 'white' }}>work_outline</Icon>,
                            icon_navigate: () => navigate(`${ROUTES.QUOTES}/approved`)
                          },
                          {
                            color: "#366B65",
                            amount: QuotesCard?.changes_requested || 0,
                            label: "Changes Requested"
                          },
                          {
                            color: "#366B65",
                            amount: QuotesCard?.drafts || 0,
                            label: "Drafts",
                            icon: <Icon className="material-icons-outlined" fontSize="large"
                              style={{ color: 'white' }}>visibility</Icon>,
                            icon_navigate: () => navigate(`${ROUTES.QUOTES}/draft`)
                          }
                        ]}
                      />
                    </MDBox>
                  </Grid>
                  <Grid item xs={3} md={3} lg={3}>
                    <MDBox mb={1.5}>
                      <HomeCard
                        color="#B8F0EA"
                        iconColor="success"
                        icon="add_box"
                        title="Jobs"
                        icon_navigate={() => navigate(ROUTES.NEW_JOB)}
                        info={[
                          {
                            color: "#366B65",
                            amount: JobsCard?.requires_invoicing || 0,
                            label: "Requires Invoicing"
                          },
                          {
                            color: "#366B65",
                            amount: JobsCard?.action_required || 0,
                            label: "Late"
                          },
                          {
                            color: "#366B65",
                            amount: JobsCard?.active_jobs || 0,
                            label: "Active Jobs"
                          }
                        ]}
                      />
                    </MDBox>
                  </Grid>
                  <Grid item xs={3} md={3} lg={3}>
                    <MDBox mb={1.5}>
                      <HomeCard
                        color="#BFE3CD"
                        iconColor="success"
                        icon="add_box"
                        title="Invoices"
                        icon_navigate={() => navigate(ROUTES.NEW_INVOICE)}
                        info={[
                          {
                            color: "#366B65",
                            amount: InvoicesCard.past_due || 0,
                            label: "Past Due",
                            icon: <Icon className="material-icons-outlined" fontSize="large"
                              style={{ color: 'white' }}>visibility</Icon>,
                            icon_navigate: () => navigate(`${ROUTES.INVOICES}/past_due`)
                          },
                          {
                            color: "#366B65",
                            amount: InvoicesCard.awaiting_payment || 0,
                            label: "Awaiting Payment",
                            icon: <Icon className="material-icons-outlined" fontSize="large"
                              style={{ color: 'white' }}>visibility</Icon>,
                            icon_navigate: () => navigate(`${ROUTES.INVOICES}/awaiting_payment`)
                          },
                          {
                            color: "#366B65",
                            amount: InvoicesCard.draft || 0,
                            label: "Draft",
                            icon: <Icon className="material-icons-outlined" fontSize="large"
                              style={{ color: 'white' }}>visibility</Icon>,
                            icon_navigate: () => navigate(`${ROUTES.INVOICES}/draft`)
                          }
                        ]}
                      />
                    </MDBox>
                  </Grid>
                </Grid>
              </Grid>
            </MDBox>

            <MDBox p={3} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
                Today's Appointments
              </MDTypography>
            </MDBox>
            <Card>
              <SimpleTable data={Data} />
            </Card>
          </Grid>

        </Grid>
      </MDBox>
    </AdminLayout>
  )
})

export default Home;
