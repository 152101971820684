import {useState} from "react";

// react-router-dom
import {useNavigate} from "react-router-dom";

/* MUI components */
import {Box} from '@mui/material';
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";
import * as Yup from "yup";

/* MD components */
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

/* utils */
import {useStores} from "../../models";
import {runInAction} from "mobx";
import {showMessage, useApi} from "services/helpers";
import {useIsMobile} from "services/hooks";

// Images
import man_sit_pc from '../../assets/images/man_sit_pc.png';
import man_look_pass from '../../assets/images/man_look_pass.png';
import './signup.css'
import {Form, Formik} from "formik";
import FormikInput from "../../components/FormikInput";
import MDBox from "../../components/MDBox";
import { ROUTES } from "constants";

// Authentication layout components

function Login() {
  const navigate = useNavigate()
  const rootStore = useStores()
  const {loginStore} = rootStore
  const api = useApi()
  const [Recovery, setRecovery] = useState(false);
  const [loading, setLoading] = useState(false);
  const isMobile = useIsMobile();

  const loginValidationSchema =
    Yup.object().shape({
      email: Yup.string().email().required(),
      password: Yup.string().required(),
    })

  const loginInitialValues = {
    email: "",
    password: "",
  };

  const forgotValidationSchema =
    Yup.object().shape({
      email: Yup.string().email().required(),
    })

  const forgotInitialValues = {
    email: "",
  };

  const login = (data) => {
    setLoading(true)
    api.login(data.email, data.password).then((result) => {
      if (result.kind === "ok") {
        const {response: {user, access_token}} = result
        runInAction(() => {
          loginStore.setUser(user);
          loginStore.setApiToken(access_token);
          loginStore.setCompany(user.company);
          loginStore.setBranding(user.branding);
        })

        if (user.role === 'ADMIN') navigate(ROUTES.ADMIN_ANALYTICS)
        else if (user.role === 'CLIENT') navigate(ROUTES.CLIENT_APPOINTMENTS)
        else navigate(ROUTES.HOME)

      } else {
        if (result.kind === "bad-data") {
          if (result.errors.non_field_errors) {
            showMessage('Invalid user o password')
          }
        }else if(result.kind === "forbidden"){
          showMessage('User is not active')
        }
        else {
          showMessage()
        }
      }
    }).catch((err) => {showMessage(); console.log(err)}).finally(() => setLoading(false))
  }

  const sendResetPassEmail = (data) => {
    loginStore.environment.api.forgotPassword(data.email).then((result) => {
      if (result.kind === "ok") {
        showMessage('A recovery password email has been sent', 'success')
        navigate(ROUTES.HOME)
      }
    })
  }

  const right_login = <>
    <Box
      sx={{
        alignItems: {xs: 'center', lg: 'flex-start'},
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
      }}
    >
      <MDTypography color={'success'} variant="h4" fontWeight="medium" mb={3}>
        Thime
      </MDTypography>
      <MDTypography variant="h2" fontWeight="regular" mb={3}>
        Welcome Back!
      </MDTypography>
    </Box>
    <img src={man_sit_pc} alt={"woman"} className={"woman-man-image"}
         style={{height: '50%', width: 'auto', display: isMobile ? 'none' : 'static'}}/>
  </>

  const right_recovery = <>
    <Box  sx={{
      alignItems: {xs: 'center', md: 'flex-start'},
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      width: '100%',
    }}>
      <MDTypography color={'success'} variant="h4" fontWeight="medium" mb={3}>
        Thime
      </MDTypography>
      <MDTypography variant="h2" fontWeight="regular" mb={3} sx={{textAlign: {xs: 'center', md: 'start'}}}>
        Have you forgotten your password?
      </MDTypography>
    </Box>
    <img src={man_look_pass} alt={"woman"} className={"woman-man-image"}
         style={{height: '50%', width: 'auto', marginLeft: '100px', display: isMobile ? 'none' : 'static'}}/>
  </>

  const left_login = <>
    <MDTypography variant="h2" fontWeight="medium" mb={3}>
      Log In
    </MDTypography>
    <Formik
      initialValues={loginInitialValues}
      validationSchema={loginValidationSchema}
      validateOnBlur={false}
      onSubmit={values => {
        login(values);
      }}
    >
      {({errors, isValid}) => (
        <Form>
          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={9}>
              <FormikInput
                name={'email'}
                label={'Email Address'}
                placeholder={'input email'}
                type={'email'}
                errors={errors}
              />
            </Grid>
            <Grid item xs={9}>
              <FormikInput
                name={'password'}
                placeholder={'input your password in here'}
                label={'Password'}
                type={'password'}
                errors={errors}
              />
              <MDBox textAlign="right">
                <MDTypography variant="button" color="text">
                  <MDTypography
                    onClick={() => setRecovery(true)}
                    variant="button"
                    fontWeight="regular"
                    style={{textAlign: 'right', color: '#DD816B', cursor: 'pointer'}} mt={2}
                  >
                    Forgot Password?
                  </MDTypography>
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={9} lg={9} md={9} alignItems={'center'} display={'flex'}>
              <MDButton
                useBrandingColor={'primary'}
                color="green"
                type="submit"
                className={'btn-save-login'}
                disabled={loading}
                loading={loading}
              >
                Log In
              </MDButton>
            </Grid>
            <Grid item xs={7} lg={7} md={7} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                  mt={3}>
              <Checkbox fontSize={'small'}/>
              <MDTypography variant="h6" fontWeight="regular" style={{textAlign: 'center', color: '#DD816B'}}>
                Keep me logged in
              </MDTypography>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>

  </>

  const left_recovery = <>
    <MDTypography variant="h2" fontWeight="medium" mb={3}>
      Forgot Password
    </MDTypography>

    <Formik
      initialValues={forgotInitialValues}
      validationSchema={forgotValidationSchema}
      validateOnBlur={false}
      onSubmit={values => {
        sendResetPassEmail(values);
      }}
    >
      {({errors, isValid}) => (
        <Form>
          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={9} lg={9} md={9} mt={3}>
              <FormikInput
                name={'email'}
                label={'Email Address'}
                placeholder={'input email'}
                type={'email'}
                errors={errors}
              />
            </Grid>
            <Grid item xs={9} lg={9} md={9} mt={5} alignItems={'center'} display={'flex'}>
              <MDButton
                useBrandingColor={'primary'}
                color="green"
                type="submit"
                className={'btn-save-login'}
              >
                Reset Password
              </MDButton>
            </Grid>
            <Grid item xs={9} lg={9} md={9} alignItems={'center'} display={'flex'}>
              <MDButton
                useBrandingColor={'primary'}
                color="green"
                variant="outlined"
                type="submit"
                className={'btn-save-login'}
                onClick={() => setRecovery(false)}
              >
                Back
              </MDButton>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  </>

  return (
    <Box>
      <Grid
        container
        alignItems="center"
        className={"main-container"}
        style={{height: "100%"}}
      >
        <Grid
          item
          xs={12}
          lg={7}
          md={5}
          sx={{background: '#F4F6F5', height: {xs: 'fit-content', md: '100%'}}}
        >
          <Box className="right-content">
            {Recovery ? right_recovery : right_login}
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          lg={5}
          md={7}
          style={{backgroundColor: "white", height: "100%"}}
        >
          <Box
            className="left-content"
            sx={{justifyContent: {xs: 'flex-start', md: 'center'}}}
          >
            {Recovery ? left_recovery : left_login}
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Login;
