import React, { useState } from 'react';
import { ButtonGroup } from '@mui/material';
import MDButton from 'components/MDButton';
import MDInput from 'components/MDInput';
//icons
import PrintIcon from "assets/images/icons/print.png";
import DownloadIcon from "assets/images/icons/download.png";
import MDDatesPicker from 'components/MDDatesPicker';

export const DateFilter = (props) => {

    return (<div {...props}>
                {props.range !== false && (
                  <ButtonGroup aria-label="outlined primary button group">
                    <MDDatesPicker placeholder="from"/>
                    <MDDatesPicker placeholder="to"/>
                </ButtonGroup>)}
                {props.extra !== false && (<>
                    <MDButton color="green" iconOnly style={{ marginLeft: 16 }}><img src={PrintIcon} /></MDButton>
                    <MDButton color="green" iconOnly style={{ marginLeft: 16 }}><img src={DownloadIcon} /></MDButton>
                </>)}
            </div>);
}