/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import { useState, useEffect, useMemo } from "react";

// @mui material components
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Sidenav from "components/Sidenav";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "./DashboardNavbar";
import { ProgressLoading } from "../ProgressLoading";

import { useMaterialUIController, setMiniSidenav } from "context";

// icons
import Analytics from "assets/images/icons/analytics.png";
import Users from "assets/images/icons/user_list.png";
import Approved from "assets/images/icons/approved.png";
import Subscription from "assets/images/icons/subscriptions.png";


const AdminLayout = ({ children, title, url, loading = false, ...props }) => {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
  } = controller;

  const [onMouseEnter, setOnMouseEnter] = useState(false);

  // Action buttons for the BookingCard
  const actionButtons = (
    <>
      <Tooltip title="Refresh" placement="bottom">
        <MDTypography
          variant="body1"
          color="primary"
          lineHeight={1}
          sx={{ cursor: "pointer", mx: 3 }}
        >
          <Icon color="inherit">refresh</Icon>
        </MDTypography>
      </Tooltip>
      <Tooltip title="Edit" placement="bottom">
        <MDTypography variant="body1" color="info" lineHeight={1} sx={{ cursor: "pointer", mx: 3 }}>
          <Icon color="inherit">edit</Icon>
        </MDTypography>
      </Tooltip>
    </>
  );

  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  return (
    <DashboardLayout>
      <Sidenav
        routes={[
          {
            type: "collapse",
            noCollapse: true,
            name: "Analytics",
            key: "analytics",
            route: "/admin/analytics",
            icon: <Icon fontSize="medium">insights</Icon>,
          },
          {
            type: "collapse",
            noCollapse: true,
            name: "User List",
            key: "users",
            route: "/admin/users",
            icon: <Icon fontSize="medium">person</Icon>,
          },
          {
            type: "collapse",
            noCollapse: true,
            name: "Approve Accounts",
            key: "approve_accounts",
            route: "/admin/approve_accounts",
            icon: <Icon fontSize="medium">check_box</Icon>,
          },
          {
            type: "collapse",
            noCollapse: true,
            name: "Subscriptions",
            key: "subscriptions",
            route: "/admin/subscriptions",
            icon: <Icon fontSize="medium">storage</Icon>,
          },
          {
            type: "collapse",
            noCollapse: true,
            name: "Logout",
            key: "logout",
            route: "",
            icon: <Icon fontSize="medium">logout</Icon>,
          },
        ]}
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
      />
      <DashboardNavbar title={title} url={url} {...props}/>
      <ProgressLoading show={loading} />
      <MDBox py={3} style={{ borderTop: '1px solid #303134' }} >
        {children}
      </MDBox>
      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default AdminLayout;
