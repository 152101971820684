
import { useState, useEffect, useRef, useContext } from "react";

import { observer } from "mobx-react-lite";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import { useStores } from 'models'

import { formatTimeSeconds } from 'services/helpers'

import { TwilioContext } from "../../services/phone_system";
import endCall from "assets/images/icons/end_call.svg";
import phoneIcon from 'assets/images/icons/phone.svg';
import mute from "assets/images/icons/mute.svg";
import speaker from "assets/images/icons/speaker.svg";
import { display } from "@mui/system";
import { styles } from './callFloatBoxStyle'

const CallFloatBox = observer(function CallFloatBox() {

  const { currentCall, currentClient, finishCall, currentNumber, currentCallTime } = useContext(TwilioContext);
  const rootStore = useStores()


  const [muted, setMuted] = useState(false);
  const [state, setState] = useState('Pending');

  useEffect(() => {
    console.log('useEffect current ==>> ', currentCall)
    if (currentCall) {
      setState('Pending')

      currentCall.on('accept', () => {
        setState('In call')
      })

      currentCall.on('mute', (isMuted, call) => {
        setMuted(isMuted)
      });

      currentCall.on('reconnecting', () => {
        setState('Reconnecting...')
      })

      currentCall.on('reconnected', () => {
        setState('In call')
      })
    }
  }, [currentCall])


  const currentCallFloatBox = () => {
    console.log('current ==>> ', currentCall)
    if (!currentCall) return
    let content
    switch (state) {
      case 'Pending':
        content = <>
          <MDBox sx={styles.floatBoxLeft}>
            <MDTypography variant="h6" ml={2}>
              {state}
            </MDTypography>
            <MDTypography variant="h6" ml={2}>
              {currentClient?.name || currentNumber}
            </MDTypography>
          </MDBox>

          <MDBox sx={styles.floatBoxRight}>
            <MDBox
              {...styles.buttonProps}
              sx={{ cursor: 'pointer'}}
              bgColor={'success'}
              onClick={() => currentCall.accept()}
            >
              <img src={phoneIcon} alt='hangup' style={{ width: 20 }} />
            </MDBox>
            <MDBox
              {...styles.buttonProps}
              sx={{ cursor: 'pointer'}}
              bgColor={'error'}
              onClick={finishCall}
            >
              <img src={endCall} alt='hangup' style={{ width: 20 }} />
            </MDBox>
          </MDBox>
        </>
        break;

      case 'In call':
        content = <>
          <MDBox sx={styles.floatBoxLeft}>
            <MDTypography variant="h6" ml={2}>
              {state}: {formatTimeSeconds(currentCallTime)}
            </MDTypography>
            <MDTypography variant="h6" ml={2}>
              {currentClient?.name || currentNumber}
            </MDTypography>
          </MDBox>

          <MDBox sx={styles.floatBoxRight}>
            <MDBox
              {...styles.buttonProps}
              sx={{ cursor: 'pointer'}}
              bgColor={state ? 'error' : 'success'}
              onClick={finishCall}
            >
              <img src={state ? endCall : phoneIcon} alt='hangup' style={{ width: 20 }} />
            </MDBox>

            <MDBox
              {...styles.buttonProps}
              sx={{ cursor: 'pointer'}}
              bgColor={'error'}
              onClick={() => currentCall?.mute(!muted)}
            >
              <img src={muted ? mute : speaker} alt='hangup' style={{ width: 20 }} />
            </MDBox>

          </MDBox>
        </>
        break;

      case 'Reconnecting...':
        content = <>
          <MDBox sx={styles.floatBoxLeft}>
            <MDTypography variant="h6" ml={2}>
              {state}
            </MDTypography>
          </MDBox>

          <MDBox sx={styles.floatBoxRight}>
            <MDBox
              {...styles.buttonProps}
              sx={{ cursor: 'pointer'}}
              bgColor={state ? 'error' : 'success'}
              onClick={finishCall}
            >
              <img src={state ? endCall : phoneIcon} alt='hangup' style={{ width: 20 }} />
            </MDBox>

          </MDBox>
        </>
        break;

      default:
        break;
    }
    return (
      <MDBox sx={styles.floatBoxContainer}>
        {content}
      </MDBox>
    )
  }

  return currentCallFloatBox()
})

export default CallFloatBox;
