import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Chip from '@mui/material/Chip';
import NoimageIcon from 'assets/images/profiles/empty.png';
import MDCheckbox from 'components/MDCheckbox';
import MDTypography from "components/MDTypography";


export const Tables = ()=> {
  return (
    <TableContainer>
      <Table>
        <TableBody>
          {[1,2,3,4,5,6].map((row,i) => (
            <TableRow
              key={i}
              className="t_body_tr"
            >
              <TableCell className="names"><img src={NoimageIcon} /></TableCell>
              <TableCell>
                <MDTypography variant="h6+" fontWeight="medium">
                  Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod...
                </MDTypography>
                <br/>
                <span>12 Minutes ago</span>
              </TableCell>
              <TableCell align="center">
                <MDCheckbox />
                <span>Mark as read</span>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
