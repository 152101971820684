import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// @mui components
import { Link, useMediaQuery, Grid } from '@mui/material';

// components
import AdminLayout from "components/AdminLayout";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { AddButton } from "./components/AddButton";
import { SwitchView } from "./components/SwitchView";

import { Form, Formik } from "formik";
import FormikInput from "components/FormikInput";
import MDDialog from "components/MDDialog";

import "./styles.css";

//icons
import SearchIcon from 'assets/images/icons/search.png';
import DeleteIcon from 'assets/images/icons/delete.png';
import Edit from "assets/images/icons/edit.png";

//Pages
import AllInventoryTab from './pages/AllInventoryTab';
import ImportItemsTab from './pages/ImportItemsTab';
import MatrixTab from './pages/MatrixTab';

import { useStores } from "../../models";
import { showMessage } from "services/helpers";
import theme from "assets/theme";
import { ROUTES } from "constants";
import Matrix from "./pages/CreateMatrix";

export default function Inventory() {
  const rootStore = useStores();
  const { loginStore } = rootStore;
  const navigate = useNavigate();

  const [value, setValue] = React.useState(1);
  const [current, setCurrent] = React.useState('list');

  const [loading, setLoading] = useState(false);
  const [ItemsModalOpen, setItemsModalOpen] = useState(false);
  const [file, setFile] = useState(null);

  const [DataItems, setDataItems] = useState([]);
  const [DataMatrix, setDataMatrix] = useState([]);
  const [DataInventoryProducts, setDataInventoryProducts] = useState([]);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleChange = (val) => {
    setValue(val);
  };

  const handleUploadFile = (e) => {
    const [file] = e.target.files;
    if (file) setFile(file)
  }

  const deleteItem = (id) => {
    loginStore.environment.api.deleteItem(id).then((result) => {
      if (result.kind === "ok") {
        showMessage('Item deleted successfully', 'success')
        setDataItems(item => {
          return item.filter(e => e.id !== id)
        });
      } else {
        if (result.kind === "bad-data") {
          showMessage(result?.errors, 'error')
        } else {
          showMessage('An error occurred, please try again later')
        }
      }
    })
  }

  const getItems = (searchData, extraData) => {
    loginStore.environment.api.getItems(searchData, extraData).then((result) => {
      if (result.kind === "ok") {
        let data = result.data.results.map((d) => {
          console.log('d: ', d);
          return ({
            ...d,
            category: <div className='chips'>Category Name</div>,
            in_stock: d?.product_inventory_values?.in_stock,
            action: (
              <>
                <MDButton
                  useBrandingColor={'primary'}
                  color="green"
                  iconOnly
                  onClick={() => navigate(ROUTES.ITEM_EDIT(d.id))}
                >
                  <img width={'80%'} src={Edit} alt='edit' />
                </MDButton>
                <MDButton
                  useBrandingColor={'primary'}
                  color="green"
                  iconOnly
                  style={{ marginLeft: 15 }}
                  onClick={() => deleteItem(d.id)}
                >
                  <img src={DeleteIcon} alt='delete' />
                </MDButton>
              </>
            )
          })
        })
        setDataItems(data);
      } else {
        if (result.kind === "bad-data") {
          showMessage(result?.errors, 'error', true)
        } else {
          showMessage('An error occurred, please try again later')
        }
      }
    })
  }

  const getMatrix = (searchData, extraData) => {
    loginStore.environment.api.getMatrix(searchData, extraData).then((result) => {
      if (result.kind === "ok") {
        setDataMatrix(result.data.results);
      } else {
        if (result.kind === "bad-data") {
          showMessage(result?.errors, 'error', true)
        } else {
          showMessage('An error occurred, please try again later')
        }
      }
    })
  }

  const getItemImportExample = () => {
    setLoading(true)
    loginStore.environment.api.getItemImportExample().then((result) => {
      if (result.kind === "ok") {
        result.download_file()
      } else showMessage('An error occurred, please try again later')
    }).catch(err => showMessage())
      .finally(() => setLoading(false))
  }

  const importItems = () => {
    if (!file?.name) {
      showMessage('Must upload a file')
      return
    }
    setLoading(true)
    loginStore.environment.api.postImportItems({ file })
      .then((result) => {
        showMessage('Items has been imported', 'success')
        getItems('');
        getMatrix('');
        setItemsModalOpen(false)
        setFile(null)
      })
      .catch(err => showMessage())
      .finally(() => setLoading(false))
  }

  const handleClick = (event) => {
    setCurrent(event.target.value);
  };

  const handleClickAdd = (event) => {
    if (value === 1) navigate(ROUTES.ITEM_CREATE)
    if (value === 2) setItemsModalOpen(true)
    if (value === 3) navigate(ROUTES.MATRIX_CREATE)
  };

  const importItemsModal = <MDDialog
    open={ItemsModalOpen}
    handleClose={() => setItemsModalOpen(false)}
    title='Import Products'
    paddingTop={10}
    style={{ width: 600, maxHeight: 1000 }}
  >
    <Formik
      validateOnBlur={false}
      validateOnChange={false}
      initialValues={{}}
      onSubmit={(values) => importItems(values)}
    >
      {({ errors, setFieldValue, values }) => {
        return (
          <Form>
            <Grid container spacing={{ xs: 2, md: 3, lg: 3 }} alignItems={'flex-start'}>
              <FormikInput
                inputFileStyles={{ backgroundColor: '#60A77C', borderRadius: '8px', width: '38px', height: '38px' }}
                errors={errors}
                name={'total'}
                label='Upload yout CSV file'
                type={'file'}
                useBrandingColor={'primary'}
                handleChange={(e) => handleUploadFile(e)}
                item xs={12}
              />
              <Grid item xs={11} mt={1}>
                <MDTypography variant={'subtitle2'}>{file?.name}</MDTypography>
              </Grid>
              <Grid item xs={12} lg={12} md={12} mb={3} display="flex" justifyContent="space-evenly" alignItems="center">
                <MDButton
                  useBrandingColor={'primary'}
                  color="green"
                  onClick={() => getItemImportExample()}
                  loading={loading}
                  sx={{ width: 200 }}
                >
                  CSV EXAMPLE
                </MDButton>
                <MDButton
                  useBrandingColor={'primary'}
                  color="green"
                  type="submit"
                  loading={loading}
                  sx={{ width: 200 }}
                >
                  IMPORT
                </MDButton>
              </Grid>
            </Grid>
          </Form>
        )
      }}
    </Formik>
  </MDDialog>

  const search = e => {
    getItems(e)
    getMatrix(e)
  }

  useEffect(() => {
    getItems('');
    getMatrix('');
  }, []);

  return (
    <AdminLayout
      title={"Inventory"}
      loading={loading}
      searchFunc={search}
    >
      <Grid container className="tabs" alignItems='center' justifyContent={'space-between'}>
        {isMobile
          ? <Grid item xs={12} justifyContent='space-between' mb={2}>
            <MDBox
              justifyContent={{ xs: 'space-between' }}
              display={'flex'}
              alignItems='flex-end'
              pt={'2px'}
              gap={'10px'}
            >
              <MDButton
                useBrandingColor={'primary'}
                color="green"
                size='small'
                onClick={() => handleChange(1)}
                className={value === 1 ? 'is-on' : ''}
                sx={{ width: '125px' }}
              >
                All Inventory
              </MDButton>
              <MDButton
                useBrandingColor={'primary'}
                color="green"
                size='small'
                onClick={() => handleChange(2)}
                className={value === 2 ? 'is-on' : ''}
                sx={{ width: '125px' }}
              >
                Import Items
              </MDButton>
              <MDButton
                useBrandingColor={'primary'}
                color="green"
                size='small'
                onClick={() => handleChange(3)}
                className={value === 3 ? 'is-on' : ''}
                sx={{ width: '125px' }}
              >
                Matrix
              </MDButton>
            </MDBox>
          </Grid>
          : <Grid item xs={12} md={8} lg={7}>
            <Link component="button" onClick={() => handleChange(1)}>
              <MDTypography className={["tab", value === 1 ? "active" : ""].join(" ")}>All Inventory</MDTypography>
            </Link>
            <Link component="button" onClick={() => handleChange(2)}>
              <MDTypography className={["tab", value === 2 ? "active" : ""].join(" ")}>Import Items</MDTypography>
            </Link>
            <Link component="button" onClick={() => handleChange(3)}>
              <MDTypography className={["tab", value === 3 ? "active" : ""].join(" ")}>Matrix</MDTypography>
            </Link>
          </Grid>
        }

        <Grid item xs={2} lg={5} display='flex' justifyContent={'flex-end'}>
          <AddButton tab={value} handleClick={handleClickAdd} onClick={handleClick.bind(this)} />
          <SwitchView current={current} onClick={handleClick.bind(this)} />
        </Grid>
      </Grid>

      <MDBox className={value === 1 ? "is-active" : "not-active"} mt={5}>
        <AllInventoryTab current={current} data={DataItems} />
      </MDBox>
      <MDBox className={value === 2 ? "is-active" : "not-active"} mt={5}>
        <ImportItemsTab data={DataItems} />
      </MDBox>
      <MDBox className={value === 3 ? "is-active" : "not-active"} mt={5}>
        <MatrixTab data={DataMatrix} deleteCallback={(id) => {
          // getMatrix()
          setDataMatrix(item => {
            return item.filter(e => e.id !== id)
          });
        }} />
      </MDBox>


      {importItemsModal}
    </AdminLayout>
  )
}
