import React, { useEffect, useState, useRef } from "react"
import 'react-calendar-timeline/lib/Timeline.css'
import Icon from "@mui/material/Icon"
import { Grid, Box } from '@mui/material'
import AdminLayout from "../../../components/AdminLayout"
import { useNavigate, useParams } from "react-router-dom"
import MDTypography from "../../../components/MDTypography"
import MDButton from "../../../components/MDButton"
import { BarcodeExample } from "../../../components/Barcode"
//icons
import SimpleTable from "components/SimpleTable";
import { Formik, useFormik } from "formik"
import * as Yup from "yup"
import { useStores } from "models"
import { itemInitialValues, itemValidationSchema } from './form'
import { showMessage, dateRange, generateSerialNumber, generateOptionsFromString, getFieldInitialValue, money_fmt } from "services/helpers"
import FormikInput from "../../../components/FormikInput"
import MDBox from "../../../components/MDBox"
import { ROUTES } from "constants"
import deleteIcon from 'assets/images/icons/delete_background.svg'
import default_img from 'assets/images/icons/default_img.svg'

//custom fields
import { CustomFieldModal } from "components/CustomFieldModal"
import { RenderCustomFields } from "components/RenderCustomFields"
import moment from "moment"; 

const FORM_ID = 'PRODUCT'

const inventoryHistoryModel = {
    columns: [
        { Header: "Date", accessor: "date" },
        { Header: "User", accessor: "user" },
        { Header: "Quantity", accessor: "quantity" },
        { Header: "Cost", accessor: "cost" },
    ],
    rows: []
}

const transactionHistoryModel = {
    columns: [
        { Header: "Date", accessor: "date" },
        { Header: "User", accessor: "user" },
        { Header: "Quantity", accessor: "quantity" },
        { Header: "Cost", accessor: "cost" },
    ],
    rows: []
}

export default function CreateItem() {
    const { id } = useParams();
    const navigate = useNavigate();
    const rootStore = useStores();
    const { loginStore } = rootStore;
    const containerRef = useRef(null);
    
    const [items, setItems] = useState();
    const [categories, setCategories] = useState();
    const [vendors, setVendors] = useState();
    const [files, setFiles] = useState([]);
    const [Loading, setLoading] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);

    const [CustomFieldModalOpen, setCustomFieldModalOpen] = useState(false);
    const [customFields, setCustomFields] = useState([]);
    const [customFieldsInitialValues, setCustomFieldsInitialValues] = useState({});

    const [InventoryData, setInventoryData] = useState({ ...inventoryHistoryModel });
    const [TransactionData, setTransactionData] = useState({ ...transactionHistoryModel });

    const onCloseCustomModal = () => {
        setCustomFieldModalOpen(false)
        getCustomFields()
    }

    const getCustomFields = () => {
        loginStore.environment.api.getCustomFields({ type: FORM_ID, object_id: id }).then((result) => {
            if (result.kind === "ok") {
                const { data } = result
                const { results } = data
                if (results.length > 0) {
                    const fields = results[0]['fields']
                    setCustomFields(fields)
                    setCustomFieldsInitialValues(getFieldInitialValue(fields))
                }
            }
        })
    }

    const handleAddCustomField = () => setCustomFieldModalOpen(true);

    const renderCustomField = ({ index, field, errors, setFieldValue }) => {
        const optionsForField = generateOptionsFromString(field['field_options'])
        const value = field['default_value'].value
        return (
            <React.Fragment key={index}>
                <FormikInput
                    useBrandingColor={'secondary'}
                    name={field['field_name']}
                    label={field['field_label']}
                    type={field['field_type_input']}
                    setFieldValue={setFieldValue}
                    options={optionsForField}
                    labelFieldName={'label'}
                    errors={errors}
                    initialValue={value}
                    item
                    xs={12}
                    md={6}
                />
            </React.Fragment>
        )
    }

    const getItems = (id) => {
        loginStore.environment.api.getItemById(id).then((result) => {
            if (result.kind === "ok") {
                setItems(result.data);
                if (result?.data?.product_images) {
                    let temp = result?.data?.product_images.filter(i => i.image)
                    if (temp && temp.length > 0) {
                        setSelectedImage(result.data.product_images?.[0])
                        setFiles(result.data.product_images);
                    }
                }
                if (Array.isArray(result?.data?.product_inventories)) {
                    const tmp = { ...inventoryHistoryModel }
                    tmp.rows = result?.data?.product_inventories.map(i => {
                        return ({
                            date: moment(i.created_at).format('MMMM Do YYYY'),
                            user: i?.user?.full_name || '',
                            quantity: i.quantity,
                            cost: money_fmt(i.default_cost)
                        })
                    })
                    setInventoryData(tmp)
                }
            } else {
                if (result.kind === "bad-data") {
                    showMessage(result?.errors, 'error', true)
                } else {
                    showMessage('An error occurred, please try again later')
                }
            }
        })
    }

    const getCategories = () => {
        loginStore.environment.api.getCategories()
            .then((result) => {
                const { data } = result
                const results = data?.results?.map((c, i) => ({ ...c, 'value': c[0] }));
                setCategories(results)
            })
            .catch(() => showMessage())
    }

    const getTags = () => {
        loginStore.environment.api.getTags()
            .then((result) => {
                const { data } = result
                const results = data?.results?.map((c, i) => ({ ...c, 'value': c[0] }));
                setCategories(results)
            })
            .catch(() => showMessage())
    }

    const formatValues = (values) => {
        let data = { ...values }
        if (id) data.id = id
        data.inventory = {}
        data.category = data?.category?.id

        data.default_price = parseFloat(data?.default_price || 0)
        data.markup = parseFloat(data?.markup || 0)
        data.margin = parseFloat(data?.margin || 0)
        data.default_cost = parseFloat(data?.default_cost || 0)
        data.desired_inventory_level = parseFloat(data?.desired_inventory_level || 0)

        if (!data?.serial_number) data.serial_number = generateSerialNumber()

        if (values.inventory) {
            data.inventory.quantity = parseFloat(values.inventory.quantity)
            data.inventory.default_cost = parseFloat(values.inventory.default_cost)
            data.inventory.category = parseFloat(values.inventory.category)
        }


        data['product_images'] = files.map(image => {
            if (image?.id) return ({ ...image })
            else return ({ image })

        })

        return data
    }

    // create
    const saveItem = (values) => {
        setLoading(true)
        let data = formatValues(values)

        loginStore.environment.api.createItem(data)
            .then((result) => {
                if (result.kind === "ok") {
                    showMessage('Product created successfully', 'success')
                    navigate(ROUTES.INVENTORY)
                } else showMessage()
            })
            .finally(() => setLoading(false))
    }

    const updateItem = (values) => {
        setLoading(true)
        let data = formatValues(values)
        loginStore.environment.api.updateItem(data)
            .then((result) => {
                if (result.kind === "ok") {
                    showMessage('Product updated successfully', 'success');
                    navigate(ROUTES.INVENTORY)
                } else showMessage()
            })
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        getCategories()
        getTags()
        getCustomFields();
        if (id) getItems(id)
    }, [])

    const scroll = (scrollOffset) => {
        containerRef.current.scrollLeft += scrollOffset;
    };
    const handleDeleteImages = () => {
        setFiles(item => {
            return item.filter(e => e !== selectedImage)
        });
        setSelectedImage(null);
    }
    const handleDrop = (e) => {
        e.preventDefault();
        const newFiles = [...e.dataTransfer.files];
        setFiles([...files, ...newFiles]);
    }
    const handleInputFile = (event) => {
        if (event.target.files?.length) {
            const newFiles = [...event.target.files];
            setFiles([...files, ...newFiles]);
        }
    }

    const sectionHead = (formik) => (
        <Grid container item xs={12} spacing={2} pt={4} pl={3}>
            <FormikInput
                useBrandingColor={'secondary'}
                name={'total_inventory_value'}
                label={'Total Inventory Value'}
                errors={formik.errors}
                disabled={true}
                item xs={12} lg={3} md={6}
            />
            <FormikInput
                useBrandingColor={'secondary'}
                name={'total_cost_value'}
                label={'Total Cost Value'}
                errors={formik.errors}
                disabled={true}
                item xs={12} lg={3} md={6}
            />
            <FormikInput
                useBrandingColor={'secondary'}
                name={'in_stock'}
                label={'In Stock'}
                errors={formik.errors}
                disabled={true}
                item xs={12} lg={3} md={6}
            />
            <FormikInput
                useBrandingColor={'secondary'}
                name={'average_cost'}
                label={'Average Cost'}
                errors={formik.errors}
                disabled={true}
                item xs={12} lg={3} md={6}
            />
        </Grid>
    )

    const sectionItem = (formik) => (
        <>
            <Grid item xs={12} lg={6} md={12}>
                <MDBox
                    // onDragOver={(e) => e.preventDefault()}
                    onDrop={(e) => handleDrop(e)}
                    sx={{ height: '200px', position: 'relative' }}
                >
                    <input type='file' accept='image/*' id='images' multiple style={{ display: 'none' }}
                        onChange={(event) => handleInputFile(event)}
                        onClick={(event) => {
                            event.target.value = null
                        }}
                    />
                    <Box component={'img'} src={deleteIcon} alt={'delete-icon'} sx={{ position: 'absolute', right: 15, top: 15, zIndex: 99 }} onClick={handleDeleteImages} />
                    <label htmlFor={'images'}>
                        <MDBox sx={{ width: '100%', height: 270, borderRadius: '8px', objectFit: 'cover', backgroundColor: '#c7c7c7', position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1 }}>
                            <Box component={'img'} src={selectedImage ? selectedImage?.image || URL.createObjectURL(selectedImage) : default_img} alt={'default_img'} sx={
                                selectedImage ? { width: '100%', height: '100%', objectFit: 'cover' } : {}
                            } />
                        </MDBox>
                    </label>
                </MDBox>
                <MDBox sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: 15,
                    height: '80px',
                    width: '100%',
                }} >
                    <Icon sx={{ fontSize: '60px' }} fontSize="60px" onClick={() => scroll(-100)}>arrow_left</Icon>
                    <MDBox sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        gap: 1,
                        height: '80px',
                        width: '100%',
                        overflowX: 'auto'
                    }} ref={containerRef}>
                        {files.map((item, index) => (
                            <Box
                                key={`id-${index}`}
                                component={'img'}
                                src={item?.image || URL.createObjectURL(item) || default_img}
                                alt={'Image-uploaded'}
                                sx={{ width: '60px', height: '60px', borderRadius: '10px', objectFit: 'cover', boxShadow: 2 }}
                                onClick={() => setSelectedImage(item)}
                            />
                        ))}
                    </MDBox>
                    <Icon sx={{ fontSize: '60px' }} fontSize="60px" onClick={() => scroll(100)}>arrow_right</Icon>
                </MDBox>
            </Grid>

            <Grid container item xs={12} lg={6} md={12}>
                <FormikInput
                    useBrandingColor={'secondary'}
                    name={'name'}
                    label={'Name'}
                    type={'text'}
                    placeholder='input name'
                    errors={formik.errors}
                    disabled={false}

                    item xs={12} lg={12} md={12}
                />
                <FormikInput
                    useBrandingColor={'secondary'}
                    name={'description'}
                    label={'Description'}
                    type={'textarea'}
                    rows={5}
                    placeholder='Description'
                    errors={formik.errors}
                    disabled={false}

                    item xs={12} lg={12} md={12}
                />
                <Grid container item xs={12} lg={12} md={12} pt={10} spacing={1}>
                    <FormikInput
                        useBrandingColor={'secondary'}
                        name={'default_price'}
                        type={'numeric'}
                        label={'Default Price'}
                        placeholder='pricing'
                        errors={formik.errors}
                        disabled={false}

                        item xs={12} lg={4} md={12}
                    />
                    <FormikInput
                        useBrandingColor={'secondary'}
                        name={'markup'}
                        type={'numeric'}
                        placeholder='markup'
                        errors={formik.errors}
                        disabled={false}

                        item xs={12} lg={4} md={12}
                        mt={3}
                    />
                    <FormikInput
                        useBrandingColor={'secondary'}
                        name={'margin'}
                        type={'numeric'}
                        placeholder='margin'
                        errors={formik.errors}
                        disabled={false}

                        item xs={12} lg={4} md={12}
                        mt={3}
                    />
                </Grid>
            </Grid>
            <FormikInput
                type={'file'}
                errors={formik.errors}
                label={'Add Images'}
                disabled={false}
                value={Image}
                multiple={true}
                inputDottedType={true}
                inputFileStyles={{ backgroundColor: '#FFF5F3', border: '2px dashed #DD816B', borderRadius: '8px' }}
                handleChange={(e) => handleInputFile(e)}

                item xs={12} lg={6} md={12}
                mt={3}
            />
            <Grid item xs={12} lg={6} md={12} mt={3} display={'flex'} justifyContent={'center'} >
                <BarcodeExample generatedValue={formik.values.serial_number} />
            </Grid>
        </>)

    const sectionOrganize = (formik) => (
        <Grid container item xs={12} lg={12} md={12} mt={1} spacing={3}>
            <Grid item xs={12} lg={12} md={12} mt={2}>
                <MDTypography variant="h5" fontWeight="bold">
                    Organize
                </MDTypography>
            </Grid>
            <FormikInput
                useBrandingColor={'secondary'}
                name={'category'}
                type={'select'}
                labelFieldName={'name'}
                label={'Category'}
                options={categories}
                setFieldValue={formik.setFieldValue}
                placeholder='Select category'
                errors={formik.errors}
                disabled={false}
                item xs={12} lg={3} md={6}
            />
            <FormikInput
                useBrandingColor={'secondary'}
                name={'serial_number'}
                label={'Serial Number'}
                onChange={formik.handleChange}
                placeholder='xxx'
                errors={formik.errors}
                disabled={true}
                item xs={12} lg={3} md={6}
            />
            <FormikInput
                useBrandingColor={'secondary'}
                name={'tags'}
                label={'Tags'}
                onChange={formik.handleChange}
                placeholder='input tags'
                errors={formik.errors}
                disabled={false}
                item xs={12} lg={3} md={6}
            />
            <FormikInput
                useBrandingColor={'secondary'}
                name={'ucp_code'}
                label={'UPC Code'}
                onChange={formik.handleChange}
                placeholder='input UPC code'
                errors={formik.errors}
                disabled={false}
                item xs={12} lg={3} md={6}
            />
        </Grid>
    )

    const sectionDefaults = (formik) => (<>
        <Grid item xs={12} lg={12} md={12} mt={2}>
            <MDTypography variant="h5" fontWeight="bold">
                Inventory Defaults
            </MDTypography>
        </Grid>
        <FormikInput
            useBrandingColor={'secondary'}
            name={'default_cost'}
            label={'Default Cost'}
            onChange={formik.handleChange}
            type={'numeric'}
            placeholder='input cost'
            errors={formik.errors}
            disabled={false}
            item xs={12} lg={6} md={12}
        />
          <FormikInput
            useBrandingColor={'secondary'}
            name={'preferred_vendor'}
            label={'Preferred Vendor'}
            onChange={formik.handleChange}
            placeholder='input vendor'
            errors={formik.errors}
            disabled={false}
            item xs={12} lg={6} md={12}
        />
        <Grid item xs={12} lg={12} md={12}>
            <hr className="MuiDivider-root MuiDivider-fullWidth" style={{ background: '#C6C9CE', margin: '12px 0' }} />
        </Grid>
        <FormikInput
            useBrandingColor={'secondary'}
            name={'desired_inventory_level'}
            label={'Desired Inventory Level'}
            type={'numeric'}
            onChange={formik.handleChange}
            placeholder='input inventory level'
            errors={formik.errors}
            disabled={true}
            item xs={12} lg={3} md={12}
            mt={1}
        />
        <FormikInput
            useBrandingColor={'secondary'}
            name={'item_location'}
            label={'Item Location'}
            onChange={formik.handleChange}
            placeholder='input item location'
            errors={formik.errors}
            disabled={true}
            item xs={12} lg={3} md={12}
            mt={1}
        />
        <Grid item xs={6} lg={6} md={6} mt={4 }>
            <MDButton type={'button'} onClick={handleAddCustomField} useBrandingColor={'primary'} color="green" styel={{ float: 'right' }}>
                Add Custom Field
            </MDButton>
        </Grid>
        {customFields.map((field, index) => renderCustomField({ ...formik, field, index }))}
        <CustomFieldModal modalOpen={CustomFieldModalOpen} handleClose={onCloseCustomModal} />
        <RenderCustomFields form_id={FORM_ID} />
    </>)

    const sectionInventory = (formik) => (<>

        <Grid item xs={12} lg={12} md={12} mt={2}>
            <MDTypography variant="h5" fontWeight="bold">
                Add Inventory
            </MDTypography>
        </Grid>
        <FormikInput
            useBrandingColor={'secondary'}
            name={'inventory.quantity'}
            label={'Quantity'}
            onChange={formik.handleChange}
            type={'numeric'}
            placeholder='input quantiy'
            errors={formik.errors}
            disabled={false}
            item xs={12} lg={3} md={12}
        />
        <FormikInput
            useBrandingColor={'secondary'}
            name={'inventory.default_cost'}
            label={'Default Cost'}
            type={'numeric'}
            onChange={formik.handleChange}
            placeholder='input cost'
            errors={formik.errors}
            disabled={false}
            item xs={12} lg={3} md={12}
        />
        <FormikInput
            useBrandingColor={'secondary'}
            name={'inventory.category'} // TODO: change this for its corresponding name
            label={'Category'}
            onChange={formik.handleChange}
            placeholder='input category'
            errors={formik.errors}
            disabled={false}
            item xs={12} lg={6} md={12}
        />
    </>)

    return (
        <AdminLayout title={"New Task"}>
            <Grid container alignItems="top" paddingTop={0} marginTop={0}>
                <Grid item xs={12} lg={9} md={9}>
                    <MDTypography useBrandingColor={'tertiary'} variant="h2" fontWeight="medium" mb={1}>
                        {id ? 'Edit Item' : 'Create New Item'}
                    </MDTypography>
                </Grid>
            </Grid>
            <Formik
                initialValues={Object.assign({}, itemInitialValues(items), customFieldsInitialValues)}
                // {itemInitialValues(items)}
                validationSchema={Yup.object(itemValidationSchema)}
                validateOnBlur={false}
                validateOnChange={false}
                enableReinitialize
                onSubmit={values => id ? updateItem(values) : saveItem(values)}
            >
                {(formik) => (
                    <Box component={'form'} onSubmit={formik.handleSubmit}>
                        <Grid container spacing={3} pb={3} className="task_container">
                            {sectionHead(formik)}
                            {sectionItem(formik)}
                            {sectionOrganize(formik)}
                            {sectionDefaults(formik)}
                            {sectionInventory(formik)}
                            <Grid item display={{ xs: 'block', lg: 'flex' }} xs={12} lg={12} md={12} mb={3} justifyContent={'space-evenly'}>
                                <Grid item xs={12} lg={3} md={5} mb={{ xs: 2 }}>
                                    <MDButton
                                        useBrandingColor={'primary'}
                                        color="green"
                                        variant="outlined"
                                        onClick={() => navigate(ROUTES.INVENTORY)}
                                        className={'btn-save'}
                                        fullWidth
                                        loading={Loading}
                                    >
                                        Cancel
                                    </MDButton>
                                </Grid>

                                <Grid item xs={12} lg={3} md={5} mb={{ xs: 2 }}>
                                    <MDButton
                                        useBrandingColor={'primary'}
                                        color="green"
                                        type="submit"
                                        className={'btn-save'}
                                        fullWidth
                                        loading={Loading}
                                    >
                                        Save
                                    </MDButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                )}
            </Formik>

            {id &&
                <Grid container spacing={3} pb={3} px={4}>
                    <Grid item xs={6} lg={6} md={6}>
                        <MDTypography variant="h4" mb={1} mt={2} fontWeight="medium" style={{ float: 'left' }}>
                            Inventory History adding product
                        </MDTypography>
                        <SimpleTable data={InventoryData} />
                    </Grid>
                    <Grid item xs={6} lg={6} md={6}>
                        <MDTypography variant="h4" mb={1} mt={2} fontWeight="medium" style={{ float: 'left' }}>
                            Transaction History
                        </MDTypography>
                        <SimpleTable data={TransactionData} />
                    </Grid>
                </Grid>
            }
        </AdminLayout>
    )
}
