import React, { useEffect, useState } from "react";
import { Box, Button, Card, Link, Modal } from '@mui/material';
import { Grid, InputAdornment, IconButton } from '@mui/material';
import MDSwitch from "components/MDSwitch";
import AdminLayout from "components/AdminLayout";
import SimpleTable from "components/SimpleTable";
import MDDialog from "components/MDDialog";
import ThimeCard from "components/ThimeCard";
import MDAutocomplete from "components/MDAutocomplete";
import UploadDoc from "components/MDInputUploadFile/UploadDoc";
import { useNavigate, useParams } from "react-router-dom";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import Sms from 'assets/images/icons/sms.png';
import Phone from 'assets/images/icons/phone.png';
import MessageText from 'assets/images/icons/message-text.png';
import Edit from 'assets/images/icons/edit.png';
import phone from "assets/images/icons/phone_grey.svg";
import email from "assets/images/icons/mail.svg";
import location from "assets/images/icons/location.svg";
import close from "assets/images/icons/close-circle.svg";
import moment from 'moment';
import { useStores } from "models";
import {showMessage, cardBrandIcon, useApi } from "services/helpers";
import MoreIcon from "assets/images/icons/more.png";
import Checkbox from "@mui/material/Checkbox";

import OverviewTabs from './OverviewTabs'
import DetailCards from './DetailCards'
import { ROUTES, DOWNLOAD_ROUTES } from 'constants'

import "../styles.css"
import MDBadge from "../../../components/MDBadge";
import MDInput from "../../../components/MDInput";
import pxToRem from "../../../assets/theme/functions/pxToRem";
import { AddButton } from "./AddButton"

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import StripeAddCard from 'components/StripeAddCard'

const { Device } = require('twilio-client');

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC);

const datatableBillingModel = {
  columns: [
    { Header: "Name (A-Z)", accessor: "name" },
    { Header: "Address", accessor: "address" },
    { Header: "Contact", accessor: "contact" },
  ],
  rows: []
}

const datatableScheduleModel = {
  columns: [
    { Header: "Title", accessor: "title" },
    { Header: "Scheduled", accessor: "scheduled" },
    { Header: "Type", accessor: "type" },
  ],
  rows: []
}

const lastClientComunication = {
  columns: [
    { Header: "Title", accessor: "title" },
    { Header: "Date", accessor: "Date" },
  ],
  rows: [],
}

export default function ClientHubDetail(props) {
  const navigate = useNavigate();
  const rootStore = useStores();
  const { loginStore } = rootStore;
  const { id } = useParams();
  const api = useApi();

  const [stream, setStream] = useState(null);
  const [SettingsModalOpen, setSettingsModalOpen] = useState(false);
  const [device, setDevice] = useState(null);

  const [file, setFile] = useState(null);
  const [NoteArrachments, setNoteArrachments] = useState(null);
  const [ClientNote, setClientNote] = useState('');
  const [LinkNoteTo, setLinkNoteTo] = useState('');

  const [Client, setClient] = useState(null);
  const [ClientSettings, setClientSettings] = useState({
    quote_follow_up: false,
    job_follow_up: false,
    appointment_reminders: false,
    invoice_follow_up: false,
  });

  const [tags, setTags] = useState([]); // added tags

  const [tagsOptions, setTagsOptions] = useState([]); // options for select
  const [tagName, setTagName] = useState(''); // select option
  const [tag, setTag] = useState(null); // text input

  const [open, setOpen] = useState(false);

  const [SMSText, setSMSText] = useState('');
  const [SMSModalOpen, setSMSModalOpen] = useState(false);

  const [clientsecret, setClientSecret] = useState('')
  const options = { clientSecret: clientsecret };

  let brandingBackgroundColor = '#BFE3CD';
  if (loginStore.branding_data) {
    try {
      brandingBackgroundColor = JSON.parse(loginStore.branding_data?.['primary'])?.hex
    } catch (error) { }
  }

  const stripePaymentIntentClient = (id) => {
    api.stripePaymentIntentClient({client_id: id}).then((result) => {
      if (result.kind === "ok") setClientSecret(result.response?.clientSecret)
      else showMessage('There was an error getting the information from stripe')
    })
  }

  const handleUploadFile = (e) => {
    const [file] = e.target.files;
    if (file) setFile(file)
  }

  const sendMessage = () => {
    api.sendMessage({ client: Client, text: SMSText }).handle({
      successMessage: 'The text message has been sent',
      errorMessage: 'An error occurred, please try again later',
      onSuccess: () => { setSMSModalOpen(false) },
      // onError: () => { formik result?.errors },
    })
  }

const getClientNotesFile = (id) => {
  api.getClientNotesFile(id).then((result) => {
    if (result.kind === "ok") {
      result.download_file()
    } else showMessage('An error occurred, please try again later')
  }).catch(err => showMessage())
  
}

  const getClientDetail = (id) => {
    loginStore.environment.api.getClientDetail(id).then((result) => {
      if (result.kind === "ok") {
        setClient(result.data)
        setClientSettings({
          quote_follow_up: result?.data?.quote_follow_up || false,
          job_follow_up: result?.data?.job_follow_up || false,
          appointment_reminders: result?.data?.appointment_reminders || false,
          invoice_follow_up: result?.data?.invoice_follow_up || false,
        })
        if (result?.data?.client_notes_attachments?.[0]?.id) {
          setNoteArrachments(result?.data?.client_notes_attachments?.[0])
          setClientNote(result?.data?.client_notes_attachments?.[0]?.description)
          setLinkNoteTo(result?.data?.client_notes_attachments?.[0]?.link_to)
        }
        setTags([...result?.data?.user_tags])
      } else {
        if (result.kind === "bad-data") showMessage(result?.errors, 'error', true)
        else showMessage('An error occurred, please try again later')
      }
    })
  }

  const getTags = () => {
    loginStore.environment.api.getTags()
      .then((result) => {
        const { data } = result
        const results = data.results.map((c, i) => ({ ...c, 'value': c.id, 'label': c.name || '-' }));
        setTagsOptions(results)
      })
      .catch(() => showMessage())
  }

  const createTag = () => {
    loginStore.environment.api.postTag({ name: tagName }).then((result) => {
      if (result.kind === "ok") {
        showMessage('Tag created successfully', 'success')
        addHandleTags(result.response)
      }
      else showMessage()
    })
  }

  const addUserTag = (data) => {
    loginStore.environment.api.addUserTag({ id: data.id, client_id: id }).then((result) => {
      if (result.kind === "ok") {
        showMessage('Tag created successfully', 'success')
        setTags([...tags, data])
        setTagName('')
        setTag(null)
      }
      else showMessage()
    })
  }

  const setupTwilioDevice = () => {
    const d = new Device()
    d.setup(process.env.TWILIO_TOKEN);
    setDevice(d)
  }

  const updateClientSettings = (data) => {
    loginStore.environment.api.updateClientSettings({ ...data, id }).then((result) => {
      if (result.kind === "ok") {
        showMessage('Setting has been updated', 'success')
        getClientDetail(id)
      }
      else showMessage()
    })
  }

  const saveClientNotes = () => {
    let data = {
      description: ClientNote,
      link_to: LinkNoteTo
    }
    if (file) data.attachment = file

    if (NoteArrachments?.id) {
      data.id = NoteArrachments?.id
      loginStore.environment.api.updateClientNotes({ ...data, user: id }).then((result) => {
        if (result.kind === "ok") {
          showMessage('Notes & Attachments has been saved', 'success')
          getClientDetail(id)
        }
        else showMessage()
      })
    } else {
      loginStore.environment.api.createClientNotes({ ...data, user: id }).then((result) => {
        if (result.kind === "ok") {
          showMessage('Notes & Attachments has been saved', 'success')
          getClientDetail(id)
        }
        else showMessage()
      })
    }
  }

  useEffect(() => {
    getClientDetail(id)
    stripePaymentIntentClient(id)
    getTags()
  }, [])

  useEffect(() => {
    if (stream) setupTwilioDevice()
  }, [stream])


  const addHandleTags = (newTag) => {
    tags.length < 6
      ? addUserTag(newTag)
      : showMessage('You have reached the number of tags permitted', 'warning')
  }

  const handleDeleteTags = (tagId) => {
    loginStore.environment.api.removeUserTag({ id: tagId, client_id: id }).then((result) => {
      if (result.kind === "ok") {
        showMessage('Tag removed successfully', 'success')
        let temp = [...tags].filter(t => t.id !== tagId)
        setTags(temp)
      }
      else showMessage()
    })
  }

  const startMediaStream = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      setStream(stream);
    } catch (error) {
      console.error('Failed to get media stream:', error);
    }
  };

  const sendSMSModal = () => (
    <MDDialog
      open={SMSModalOpen}
      handleClose={() => setSMSModalOpen(false)}
      title={'Type the message'}
      titleStyles={{ fontSize: pxToRem(24) }}
      rootStyles={{ overflowY: 'unset' }}
      style={{ width: '100%', paddingBlock: `${pxToRem(10)} ${pxToRem(30)}`, paddingInline: pxToRem(50) }}
    >
      <MDInput
        fullWidth
        placeholder='input text message'
        value={SMSText}
        onChange={evt => setSMSText(evt.target.value)}
      />
      <MDBox sx={{ display: 'flex', gap: pxToRem(24), marginTop: pxToRem(46) }}>
        <MDButton
          useBrandingColor={'primary'}
          color="green"
          variant="outlined"
          type="submit"
          className={"button-action"}
          onClick={() => setSMSModalOpen(false)}
        >
          Cancel
        </MDButton>

        <MDButton
          useBrandingColor={'primary'}
          color="green"
          type="submit"
          className={"button-action"}
          onClick={() => sendMessage()}
          disabled={!SMSText || SMSText === ''}
        >
          Continue
        </MDButton>
      </MDBox>
    </MDDialog>
  )

  const SettingsModal = () => (
    <MDDialog
      open={SettingsModalOpen}
      handleClose={() => setSettingsModalOpen(false)}
      // title={'Type the message'}/
      titleStyles={{ fontSize: pxToRem(24), background: 'rgba(0, 0, 0, 0.5)', display: 'none' }}
      // rootStyles={{ overflowY: 'unset' }}
      style={{ width: '100%', background: 'rgba(0, 0, 0, 0.5)' }}
    >

      <ThimeCard color={'#FFDBA4'} title={'Getting the Work'}>
        <Grid container >
          <Grid item lg={11}>
            <MDTypography variant="h6" fontWeight={"medium"} style={{ color: "var(--light-black)" }}>
              Quote follow-up
            </MDTypography>
            <MDTypography variant="h6" fontWeight={"regular"} style={{ color: "var(--light-black)" }}>
              Send a notification to your client following up on an outstanding quote
            </MDTypography>
            <MDTypography variant="button" fontWeight={"bold"} style={{ color: "var(--grey)", paddingBottom: 10 }}>
              • Send a follow up 3 days after the quote was sent by text message or email at 10:30 AM<br />
              • Send a follow up 9 days after the quote was sent by text message or email at 7:00 PM
            </MDTypography>
          </Grid>
          <Grid item lg={1}>
            <MDSwitch
              onChange={(e) => {
                let temp = { ...ClientSettings }
                temp.quote_follow_up = e.target.checked
                updateClientSettings(temp)
              }}
              checked={ClientSettings.quote_follow_up}
              type="ios" />
            <MDButton
              color="green"
              useBrandingColor={'primary'}
              iconOnly
              style={{ marginBottom: 10 }}
              onClick={() => navigate(ROUTES.CLIENTS_EDIT(Client.id))}
            >
              <img width={'80%'} src={Edit} />
            </MDButton>
          </Grid>
        </Grid>
      </ThimeCard>

      <ThimeCard color={'#FFDBA4'} title={'Doing the Work'}>
        <Grid container >
          <Grid item lg={11}>
            <MDTypography variant="h6" fontWeight={"medium"} style={{ color: "var(--light-black)" }}>
              Assessment & visit reminders
            </MDTypography>
            <MDTypography variant="h6" fontWeight={"regular"} style={{ color: "var(--light-black)" }}>
              Send a notification to your client to remind them of an upcoming<br />assessment or visit
            </MDTypography>
            <MDTypography variant="button" fontWeight={"bold"} style={{ color: "var(--grey)", paddingBottom: 10 }}>
              • Send a reminder 3 days before the appointment by email at 10:30 AM<br />
              • Send a reminder 2 hours before the appointment by text message
            </MDTypography>
          </Grid>
          <Grid item lg={1}>
            <MDSwitch
              onChange={(e) => {
                let temp = { ...ClientSettings }
                temp.appointment_reminders = e.target.checked
                updateClientSettings(temp)
              }}
              checked={ClientSettings.appointment_reminders}
              type="ios" />
            <MDButton
              color="green"
              useBrandingColor={'primary'}
              iconOnly
              style={{ marginBottom: 10 }}
              onClick={() => navigate(ROUTES.CLIENTS_EDIT(Client.id))}
            >
              <img width={'80%'} src={Edit} />
            </MDButton>
          </Grid>
        </Grid>
      </ThimeCard>

      <ThimeCard color={'#FFDBA4'} title={'Getting Paid'}>
        <Grid container >
          <Grid item lg={11}>
            <MDTypography variant="h6" fontWeight={"medium"} style={{ color: "var(--light-black)" }}>
              Job follow-up
            </MDTypography>
            <MDTypography variant="h6" fontWeight={"regular"} style={{ color: "var(--light-black)" }}>
              Gather feedback from your clients with a follow-up email and an optional<br /> survey. Read about Job Follow-Ups to learn more.
            </MDTypography>
            <MDTypography variant="button" fontWeight={"bold"} style={{ color: "var(--grey)", paddingBottom: 10 }}>
              • Send a follow-up after dosing a job
            </MDTypography>
          </Grid>
          <Grid item lg={1}>
            <MDSwitch
              onChange={(e) => {
                let temp = { ...ClientSettings }
                temp.job_follow_up = e.target.checked
                updateClientSettings(temp)
              }}
              checked={ClientSettings.job_follow_up}
              type="ios" />
            <MDButton
              color="green"
              useBrandingColor={'primary'}
              iconOnly
              style={{ marginBottom: 10 }}
              onClick={() => navigate(ROUTES.CLIENTS_EDIT(Client.id))}
            >
              <img width={'80%'} src={Edit} />
            </MDButton>
          </Grid>

          <br />
          <Grid item lg={11}>
            <MDTypography variant="h6" fontWeight={"medium"} style={{ color: "var(--light-black)" }}>
              Invoice follow-up
            </MDTypography>
            <MDTypography variant="h6" fontWeight={"regular"} style={{ color: "var(--light-black)" }}>
              Send a notification to your client to follow up on an overdue invoice
            </MDTypography>
            <MDTypography variant="button" fontWeight={"bold"} style={{ color: "var(--grey)", paddingBottom: 10 }}>
              • Send a follow-up 8 days after the invoice is due by text message or email at 10:30 AM<br />
              • Send a follow-up 10 days after the invoice is due by text message or email at 10:30 AM
            </MDTypography>
          </Grid>
          <Grid item lg={1}>
            <MDSwitch
              onChange={(e) => {
                let temp = { ...ClientSettings }
                temp.invoice_follow_up = e.target.checked
                updateClientSettings(temp)
              }}
              checked={ClientSettings.invoice_follow_up}
              type="ios" />
            <MDButton
              color="green"
              useBrandingColor={'primary'}
              iconOnly
              style={{ marginBottom: 10 }}
              onClick={() => navigate(ROUTES.CLIENTS_EDIT(Client.id))}
            >
              <img width={'80%'} src={Edit} />
            </MDButton>
          </Grid>

        </Grid>
      </ThimeCard>

    </MDDialog>
  )

  const tagsModal = () => {
    return (
      <MDDialog
        open={open}
        handleClose={() => setOpen(false)}
        title={'Add tag'}
        titleStyles={{ fontSize: pxToRem(24) }}
        rootStyles={{ overflowY: 'unset' }}
        style={{ width: '100%', paddingBlock: `${pxToRem(10)} ${pxToRem(30)}`, paddingInline: pxToRem(50) }}
      >
        <MDInput
          useBrandingColor={'secondary'}
          fullWidth
          placeholder='create new tag'
          value={tagName}
          onChange={(e) => setTagName(e.target.value)}
          sx={{ marginBottom: 3 }}
        />

        <MDAutocomplete
          useBrandingColor={'secondary'}
          fullWidth
          placeholder='search for tag'
          options={tagsOptions}
          onChange={(event, newValue) => setTag(newValue)}
        />

        <MDBox sx={{ display: 'flex', gap: pxToRem(24), marginTop: pxToRem(46) }}>
          <MDButton
            useBrandingColor={'primary'}
            color="green"
            variant="outlined"
            type="submit"
            className={"button-action"}
            onClick={() => setOpen(false)}
          >
            Cancel
          </MDButton>

          <MDButton
            useBrandingColor={'primary'}
            color="green"
            type="submit"
            className={"button-action"}
            onClick={(e) => handleSubmit(e)}
            disabled={!tagName && tagName === '' && !tag}
          >
            Continue
          </MDButton>
        </MDBox>
      </MDDialog>
    )

  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if (tagName && tagName !== '') createTag()

    else addHandleTags(tag);

    setOpen(false);
  }

  return (
    <AdminLayout title={"Manage Team"}>
      <Grid container alignItems="top" paddingTop={0} marginTop={0} spacing={3}>
        <Grid item xs={12} lg={12} md={12} mb={2} style={{ display: 'flex', justifyContent: 'space-between' }}>
          <MDTypography variant="h2" fontWeight="medium" mb={1} ml={3} style={{ float: 'left' }}>
            {Client?.name ?? Client?.full_name}
          </MDTypography>

          <MDBox>
          <MDButton
              color="green"
              useBrandingColor={'primary'}
              iconOnly style={{ float: 'right', marginRight: 20, marginBottom: 10 }}
              onClick={() => {}}
            >
              <img width={'80%'} src={Phone} alt='Phone' />
            </MDButton>
            <MDButton
              color="green"
              useBrandingColor={'primary'}
              iconOnly style={{ float: 'right', marginRight: 20, marginBottom: 10 }}
              onClick={() => navigate(ROUTES.CLIENTS_EDIT(Client.id))}
            >
              <img width={'80%'} src={Edit} alt='Edit' />
            </MDButton>
            {Client?.phone_number &&
              <MDButton
                color="green"
                useBrandingColor={'primary'}
                iconOnly
                style={{ float: 'right', marginRight: 20, marginBottom: 10 }}
                onClick={() => setSMSModalOpen(true)}
              >
                <img width={'80%'} src={MessageText} alt='MessageText' />
              </MDButton>
            }
            {Client?.email &&
              <MDButton
                color="green"
                useBrandingColor={'primary'}
                iconOnly
                style={{ float: 'right', marginRight: 20, marginBottom: 10 }}
                onClick={() => window.location = `mailto:${Client?.email}`}
              >
                <img width={'80%'} src={Sms} alt='Sms' />
              </MDButton>
            }

            <MDButton
              useBrandingColor={'primary'}
              color="green"
              style={{ width: 150, marginRight: 27, height: 48 }}
              component={Link}
              onClick={() => navigate(ROUTES.REPORTS_CLIENT_HUB_DETAIL)}
            > Client Hub
            </MDButton>
            <AddButton />
            <MDButton
              useBrandingColor={'primary'}
              color="green"
              style={{ width: 130, marginRight: 27, height: 48 }}
              component={Link}
              onClick={() => setSettingsModalOpen(true)}
            > Settings
            </MDButton>
          </MDBox>
        </Grid>

        <Grid container display={'flex'} justifyContent={'space-evenly'} item xs={12} md={12} lg={12} p={2}>
          <DetailCards xs={12} md={6} lg={4} client={Client} label={'Client'}>
            <MDBox display={'flex'} justifyContent={'space-between'} alignItems={'center'} height={'100%'} gap={1} p={1}>
              <Box component={'img'} src={location} alt={'location-icon'} />
              <MDTypography variant="h6" fontWeight="regular" >
                {Client?.address || 'Number, Street, City, State, Zip'}
              </MDTypography>
            </MDBox>
          </DetailCards>
          <DetailCards xs={12} md={6} lg={4} client={Client} label={'Contact Info'}>
            <MDBox display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'flex-start'} height={'100%'} width={'100%'} >
              <MDBox gap={1} p={1} display={'flex'} alignItems={'center'}>
                <Box component={'img'} src={phone} alt={'phone-icon'} sx={{ width: '24px' }} />
                <MDTypography variant="h6" fontWeight="regular" >
                  {Client?.phone_number}
                </MDTypography>
              </MDBox>
              <MDBox gap={1} p={1} display={'flex'} alignItems={'center'}>
                <Box component={'img'} src={email} alt={'email-icon'} sx={{ width: '24px' }} />
                <MDTypography variant="h6" fontWeight="regular" >
                  {Client?.email}
                </MDTypography>
              </MDBox>
            </MDBox>
          </DetailCards>
          <DetailCards xs={12} md={12} lg={4} client={Client} label={'Tags'} button={{ label: 'Add more +', action: () => setOpen(true) }}>
            {tags.map((t, key) => (
              <MDBadge
                sx={{ cursor: 'pointer', '.MuiBadge-badge': { backgroundColor: brandingBackgroundColor, marginTop: '2px' }, width: pxToRem(80), height: pxToRem(30) }}
                key={`id-${t?.id}`}
                badgeContent={
                  <MDBox display={'flex'}>
                    <MDTypography
                      variant={'subtitle2'}
                      color='white'
                      sx={{ width: pxToRem(50), whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                      {t?.name}
                    </MDTypography>
                    <Box component={'img'} src={close} alt={'close'} onClick={() => handleDeleteTags(t.id)} />
                  </MDBox>
                }
              />
            ))}
          </DetailCards>
        </Grid>
        
        <Grid item xs={12} lg={12} md={12}>
          <OverviewTabs client={Client} actionCallback={() => getClientDetail(id)} />
        </Grid>

        <Grid item xs={12} lg={12} md={12} style={{ display: 'flow-root', justifyContent: 'space-between' }}>
          <MDTypography variant="h3" fontWeight="medium" style={{ float: 'left' }}>
            Scheduled
          </MDTypography>
          {/* <MDButton
            useBrandingColor={'primary'}
            color="green"
            style={{ width: 150, height: 48, float: 'right', marginBottom: 10 }}
            component={Link}
            onClick={() => { }}
          > Add New +
          </MDButton> */}
          <MDBox sx={{ marginTop: '50px' }}>
            <SimpleTable data={datatableScheduleModel} />
          </MDBox>
        </Grid>

        <Grid item xs={6} lg={6} md={6} style={{ display: 'flow-root' }}>
          <MDTypography variant="h3" fontWeight="medium" style={{ float: 'left' }}>
            Billing History
          </MDTypography>
          <MDButton
            useBrandingColor={'primary'}
            color="green"
            style={{ width: 150, height: 48, float: 'right', marginBottom: 10 }}
            component={Link}
            onClick={() => navigate(ROUTES.NEW_INVOICE)}
          > Add New +
          </MDButton>
          <MDBox sx={{ marginTop: '50px' }}>
            <SimpleTable data={datatableBillingModel} />
          </MDBox>
        </Grid>

        <Grid item xs={6} lg={6} md={6} style={{ display: 'flow-root', justifyContent: 'space-between' }}>
          <MDTypography variant="h3" fontWeight="medium" style={{ float: 'left' }}>
            Payment Methods
          </MDTypography>
          {clientsecret &&
            <Grid container item xs={12} lg={12} md={12}  className="white_background_shadow" alignItems={'center'} display={'flex'}>
              <Elements stripe={stripePromise} options={options}>
                <StripeAddCard client_id={id} callback={() => getClientDetail(id)} />
              </Elements>
            </Grid>
          }

          <Grid item xs={12} lg={12} md={12} mt={2}>
            <div style={{ overflowX: 'auto', width: '100%', display: 'flex' }}>
              {Array.isArray(Client?.stripe_cards) && Client?.stripe_cards.map((card, i) =>
                <MDBox
                  key={`card_${i}`}
                  style={{
                    background: '#BFE3CD',
                    minWidth: 300,
                    height: 120,
                    borderRadius: 16,
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                    marginRight: 10,
                    marginBottom: 10
                  }}
                >
                  <MDBox style={{ width: '85%', display: 'grid', paddingLeft: 10, gap: 10 }} mb={3}>
                    <img src={cardBrandIcon(card?.brand)} style={{ height: 15, marginTop: 10 }} />
                    <MDTypography variant="h6" fontWeight="medium" style={{ float: 'left' }}>
                      {`**** **** **** ${card?.last4}`}
                    </MDTypography>
                  </MDBox>
                </MDBox>
              )}
            </div>
          </Grid>
        </Grid>

        <Grid item xs={6} lg={6} md={6} style={{ display: 'flow-root' }}>
          <MDTypography variant="h3" fontWeight="medium" style={{ float: 'left' }}>
            Notes & Attachments
          </MDTypography>

          <MDButton
            useBrandingColor={'primary'}
            color="green"
            style={{ width: 150, height: 48, float: 'right', marginBottom: 10 }}
            component={Link}
            onClick={() => saveClientNotes()}
          > Save
          </MDButton>

          <MDBox sx={{
            height: '280px',
            marginTop: '50px',
            background: '#FFFFFF',
            boxShadow: '0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px rgba(145, 158, 171, 0.12)',
            borderRadius: '16px',
            display: 'flex',
            flexDirection: 'column',
            width: '100%'
          }} >

            <Grid item xs={12} mt={3} mx={3}>
              <MDTypography variant="h6" fontWeight="bold">
                Notes
              </MDTypography>
              <MDInput
                useBrandingColor={'secondary'}
                fullWidth
                placeholder='input note details'
                value={ClientNote}
                onChange={e => setClientNote(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} mx={3} mt={2}>
              <UploadDoc
                fullWidth
                handleChange={(e) => handleUploadFile(e)}
                useBrandingColor={'secondary'}
                label={'Drag files her or click to select'}
                multiple
                inputDottedType
                inputFileStyles={{
                  borderRadius: '8px',
                }}
              />

            </Grid>
            {file?.name
              ? <MDTypography mx={3} variant={'subtitle2'}>{file?.name}</MDTypography>
              : NoteArrachments?.attachment
                ? <MDTypography
                  mx={3}
                  variant="h6"
                  fontWeight={"medium"}
                  color='primary'
                  sx={{ cursor: 'pointer' }}
                  onClick={() => {
                    getClientNotesFile(NoteArrachments?.id)
                  }}
                >Download File</MDTypography>
                : <></>
            }


            <Grid item xs={12} my={3} mx={3}>
              <MDTypography variant="h6" fontWeight="bold">
                Link note to
              </MDTypography>

              <Grid item lg={12} display={'flex'} alignItems={'center'} >

                <MDTypography variant="h6" fontWeight="regular">
                  Jobs
                </MDTypography>
                <Checkbox
                  fontSize={'small'}
                  checked={LinkNoteTo.includes('Jobs')}
                  onChange={(e) => {
                    e.target.checked
                      ? setLinkNoteTo(`${LinkNoteTo}|Jobs`)
                      : setLinkNoteTo(LinkNoteTo.replace('|Jobs', ''))
                  }}
                />

                <MDTypography ml={4} variant="h6" fontWeight="regular">
                  Quote
                </MDTypography>
                <Checkbox
                  fontSize={'small'}
                  checked={LinkNoteTo.includes('Quote')}
                  onChange={(e) => {
                    e.target.checked
                      ? setLinkNoteTo(`${LinkNoteTo}|Quote`)
                      : setLinkNoteTo(LinkNoteTo.replace('|Quote', ''))
                  }}
                />

                <MDTypography ml={4} variant="h6" fontWeight="regular">
                  Requests
                </MDTypography>
                <Checkbox
                  fontSize={'small'}
                  checked={LinkNoteTo.includes('Requests')}
                  onChange={(e) => {
                    e.target.checked
                      ? setLinkNoteTo(`${LinkNoteTo}|Requests`)
                      : setLinkNoteTo(LinkNoteTo.replace('|Requests', ''))
                  }}
                />

                <MDTypography ml={4} variant="h6" fontWeight="regular">
                  Invoices
                </MDTypography>
                <Checkbox
                  fontSize={'small'}
                  checked={LinkNoteTo.includes('Invoices')}
                  onChange={(e) => {
                    e.target.checked
                      ? setLinkNoteTo(`${LinkNoteTo}|Invoices`)
                      : setLinkNoteTo(LinkNoteTo.replace('|Invoices', ''))
                  }}
                />
              </Grid>
            </Grid>
          </MDBox>
        </Grid>

        <Grid item xs={6} lg={6} md={6} style={{ display: 'flow-root' }}>
          <MDTypography variant="h3" fontWeight="medium" style={{ float: 'left' }}>
            Last client communication
          </MDTypography>
          <MDBox sx={{ marginTop: '50px' }}>
            <SimpleTable data={lastClientComunication} />
          </MDBox>
        </Grid>

        <Grid item xs={6} lg={6} md={6} style={{ display: 'flow-root' }}>
          <MDTypography variant="h3" fontWeight="medium" style={{ float: 'left' }}>
            Check List
          </MDTypography>
          <MDButton
            useBrandingColor={'primary'}
            color="green"
            style={{ width: 150, height: 48, float: 'right', marginBottom: 10 }}
            component={Link}
            onClick={() => { }}
          > Add New +
          </MDButton>
          <MDBox sx={{ marginTop: '50px' }}>
            <SimpleTable data={datatableBillingModel} />
          </MDBox>
        </Grid>

        <Grid item xs={6} lg={6} md={6} style={{ display: 'flow-root', justifyContent: 'space-between' }}>
          <MDTypography variant="h3" fontWeight="medium" style={{ float: 'left' }}>
            Activity Log
          </MDTypography>
          <MDBox sx={{ marginTop: '50px' }}>
            <SimpleTable data={{
              columns: [
                { Header: "Date", accessor: "date", width: "25%" },
              ],
              rows: [
                {
                  date: Client?.last_login
                    ? Client?.last_login instanceof Date
                      ? moment(Client?.last_login).format('lll')
                      : Client?.last_login
                    : '-'
                }
              ],
            }} />
          </MDBox>
        </Grid>

      </Grid>
      {sendSMSModal()}
      {SettingsModal()}
      {tagsModal()}
    </AdminLayout>
  )
}
