import { useEffect, useState } from "react";
import {Link} from "react-router-dom";
import moment from 'moment'
import { Grid, InputAdornment, IconButton } from '@mui/material';
import AdminPanelLayout from "../../../components/AdminPanelLayout";
import { useNavigate } from "react-router-dom";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import MDInput from "../../../components/MDInput";
import MDButton from "../../../components/MDButton";
import MDDatePicker from "../../../components/MDDatePicker";
import {Tables} from "../components/Tables";
import {useStores} from "models";
import { ROUTES } from 'constants'
import "../styles.css"
// icons
import ExportIcon from "assets/images/icons/export.png";
// Material Dashboard 2 PRO React examples
import SimpleTable from "components/SimpleTable";
// Utils
import {showMessage} from "services/helpers";

const datatableModel = {
    columns: [
      {Header: "Name (A-Z)", accessor: "full_name"},
      {Header: "Address", accessor: "address_1", sorted: false},
      {Header: "Phone Number", accessor: "phone_number", sorted: false},
      {Header: "Email", accessor: "email", sorted: false},
      {Header: "Industry", accessor: "industry", sorted: false},
    ],
    rows: []
}

export default function UserList(props) {
  const rootStore = useStores();
  const {loginStore} = rootStore;
  const navigate = useNavigate();
  const [usersData, setUsersData] = useState(datatableModel);
  const [loading, setLoading] = useState(false);

  const getUsers = (searchData = "", extraData) => {
    setLoading(true);
    const search = searchData + "&approved=True";
    loginStore.environment.api.getUsersAdmin(search, extraData)
    .then((result) => {
      if (result.kind === "ok") {
        let users = result.data.results;        
        const usersDataCopy = {...usersData};
        users = users.map((user, udix) => ({...user, 
          full_name: <Link to={ROUTES.ADMIN_USERS_DETAIL(user.id)} className='base-item link-item'>{user.full_name}</Link>
        }));
        usersDataCopy.rows = users;
        setUsersData(usersDataCopy);
      } else {
        if (result.kind === "bad-data") {
          showMessage(result?.errors, 'error', true);
        } else {
          showMessage('An error occurred, please try again later');
        }
      }
    })
    .finally(() => setLoading(false));
  }

   useEffect(() => {
    getUsers();
  }, []);

  return (
    <AdminPanelLayout title={"User List"} searchFunc={getUsers} loading={loading}>
        <Grid container spacing={3}>
            <Grid item xs={12} lg={12} md={12} ml={2}>
                <MDTypography variant="h3" fontWeight="medium" mb={1} style={{ float: 'left' }}>
                All Users
                </MDTypography>
                <MDButton useBrandingColor={'primary'} color="green" style={{ float: 'right' }} iconOnly>
                    <img src={ExportIcon} />
                </MDButton>
                <SimpleTable data={usersData} />
            </Grid>
        </Grid>
    </AdminPanelLayout>
  )
}
