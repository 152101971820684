export const mixedChartData = {
    labels: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
    datasets: [
      {
        chartType: "thin-bar",
        label: "Projects",
        color: "warning",
        data: [70, 40, 56, 34, 90, 15, 88],
      },
      {
        chartType: "thin-bar",
        label: "Jobs",
        color: "success",
        data: [80, 30, 60, 20, 82, 55, 39],
      },
    ],
};

export const analitycsInitialData = {
    "new_users": 0,
    "new_jobs": 0,
    "completed_jobs": 0,
    "new_expenses": 0,
    "jobs_total": 0,
    "jobs_ongoing": 0,
    "jobs_unfinished": 0,
    "jobs_statistics": {
        "daily": {
            "sunday": {
                "total": 0,
                "ongoing": 0
            },
            "monday": {
                "total": 0,
                "ongoing": 0
            },
            "tuesday": {
                "total": 0,
                "ongoing": 0
            },
            "wednesday": {
                "total": 0,
                "ongoing": 0
            },
            "thursday": {
                "total": 0,
                "ongoing": 0
            },
            "friday": {
                "total": 0,
                "ongoing": 0
            },
            "saturday": {
                "total": 0,
                "ongoing": 0
            }
        }
    }
}
