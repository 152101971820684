import React from 'react';
import { useNavigate } from "react-router-dom";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

export const AddButton = () => {
  const navigate = useNavigate();
  const handleClick = (event) => {
    navigate('/quotes/new')
  };

  return (
    <MDBox mr={7}>
      <MDButton
        useBrandingColor={'primary'}
        color="green"
        className='btn-import-client'
        onClick={handleClick}
      >
        {'Add New +'}
      </MDButton>
    </MDBox>
  );
}
