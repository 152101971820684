import {useEffect, useState} from "react";
import moment from 'moment'
import {Grid} from '@mui/material';
import ClientPanelLayout from "../../../components/ClientPanelLayout";
import MDTypography from "../../../components/MDTypography";
import "../styles.css"

//icons
import ClockIcon from "assets/images/icons/clock.png";
import CalendarIcon from "assets/images/icons/scheduler_black.png";

import {useStores} from "models";
import {showMessage} from "services/helpers";

export default function Appointments() {
  const rootStore = useStores()
  const {loginStore} = rootStore

  const [Data, setData] = useState([]);
  const [loading, setLoading] = useState(false)

  const getAllTasks = (searchData, extraData) => {
    setLoading(true)
    loginStore.environment.api.getAllTasks(searchData, extraData).then((result) => {
      if (result.kind === "ok") {
        let data = []
        result.data.results.map(r => {
          data.push({
            name: r?.task_for?.first_name,
            start_time: moment(r.start_date).format('DD/MM/YYYY HH:mm'),
            ...r
          })
        })
        setData(data)
      } else {
        if (result.kind === "bad-data") {
          showMessage(result?.errors, 'error', true)
        } else {
          showMessage('An error occurred, please try again later')
        }
      }
    })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    getAllTasks('');
  }, []);

  return (
    <ClientPanelLayout title={"Appointments"}>
      <Grid container alignItems="top" paddingTop={0} marginTop={0}>
        <Grid item xs={12} lg={9} md={9} className="leftContainer">
          <MDTypography variant="h2" fontWeight="medium" ml={4} mt={1}>
            Past
          </MDTypography>
        </Grid>
      </Grid>
      {(Array.isArray(Data) && Data[0])
        ? Data.map((data, i) =>
          <Grid key={data.id} container spacing={3} padding={3} className="task_container">
            <Grid item xs={4} lg={4} md={4}>
              <MDTypography variant="h6" style={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
              }}>
                <img src={CalendarIcon} style={{paddingRight: 10}}/>
                {`${moment(data.start_date).format('dddd')}, ${moment(data.start_date).format('ll')}`}
              </MDTypography>
            </Grid>
            <Grid item xs={4} lg={4} md={4}>
              <MDTypography fontWeight='regular' variant="h6" style={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
              }}>
                <img src={ClockIcon} style={{paddingRight: 10}}/>
                {moment(data.start_date).format('LT')}
              </MDTypography>
            </Grid>
            <Grid item xs={4} lg={4} md={4}>
              <MDTypography fontWeight='regular' variant="h6">{data.title}</MDTypography>
            </Grid>
          </Grid>
        )
        : <Grid container spacing={3} padding={3} className="task_container">
          <Grid item xs={4} lg={4} md={4}>
            <MDTypography variant="h6">Sin datos</MDTypography>
          </Grid>
        </Grid>
      }
    </ClientPanelLayout>
  )
}
