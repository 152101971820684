import React, { useEffect, useState } from "react";
import 'react-calendar-timeline/lib/Timeline.css'
import moment from 'moment'
import { Grid, InputAdornment, IconButton } from '@mui/material';
import AdminLayout from "components/AdminLayout";
import { useNavigate, useParams } from "react-router-dom";
import MDBox from "components/MDBox";
import ThimeCard from "components/ThimeCard";
import { Form, Formik } from "formik";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDDatePicker from "components/MDDatePicker";
import { ROUTES } from "constants";
import FormikInput from "components/FormikInput";
import { showMessage, useApi, dateRange, generateSerialNumber, generateOptionsFromString, getFieldInitialValue, money_fmt } from "services/helpers"
import { useApiMessages } from "services/api/api-messages";
import { getJobCheckListValues, jobCheckListValidationSchema, } from "./form";

//custom fields
import { CustomFieldModal } from "components/CustomFieldModal"
import { RenderCustomFields } from "components/RenderCustomFields"

const FORM_ID = 'JOB_CHECK_LIST'

export default function JobForm() {
  const navigate = useNavigate();
  const { id } = useParams();
  const api = useApi()
  const messages = useApiMessages('job check list', 'job check lists')

  const [loading, setLoading] = useState(false);
  const [Jobs, setJobs] = useState([]);
  const [JobCheckList, setJobCheckList] = useState(null);

  const [CustomFieldModalOpen, setCustomFieldModalOpen] = useState(false);
  const [customFields, setCustomFields] = useState([]);
  const [customFieldsInitialValues, setCustomFieldsInitialValues] = useState({});

  const onCloseCustomModal = () => {
    setCustomFieldModalOpen(false)
    getCustomFields()
  }

  const getCustomFields = () => {
    api.getCustomFields({ type: FORM_ID, object_id: id }).then((result) => {
      if (result.kind === "ok") {
        const { data } = result
        const { results } = data
        if (results.length > 0) {
          const fields = results[0]['fields']
          setCustomFields(fields)
          setCustomFieldsInitialValues(getFieldInitialValue(fields))
        }
      }
    })
  }

  const deleteCustomField = (id) => {
    setLoading(true)
    api.deleteCustomField(id).then((result) => {
      if (result.kind === "ok") {
        showMessage('Custom field deleted successfully', 'success')
        setCustomFields([])
        getCustomFields();
      } else showMessage()
    }).finally(() => setLoading(false))
  }

  const handleAddCustomField = () => setCustomFieldModalOpen(true);

  const renderCustomField = ({ index, field, errors, setFieldValue }) => {
    const optionsForField = generateOptionsFromString(field['field_options'])
    const value = field['default_value'].value

    console.log(' field ===>>> ', field)

    return (
      <React.Fragment key={index}>
        <FormikInput
          labelPos={
            <MDTypography
            variant="h6"
            color="error"
            style={{ float: 'right', cursor: 'pointer' }}
            onClick={() => deleteCustomField(field.id)}
            >
              <u>Delete</u>
            </MDTypography>
          }
          useBrandingColor={'secondary'}
          name={field['field_name']}
          label={field['field_label']}
          type={field['field_type_input']}
          setFieldValue={setFieldValue}
          options={optionsForField}
          labelFieldName={'label'}
          errors={errors}
          initialValue={value}
          item
          xs={12}
          md={6}
        />
      </React.Fragment>
    )
  }

  const getJobCheckListDetail = (id) => {
    setLoading(true)
    api.getClientEditDetail(id).then((result) => {
      if (result.kind === "ok") {
        setJobCheckList(result.data)
      } else {
        showMessage()
      }
    }).finally(() => setLoading(false))
  }

  const postJobCheckList = (values, formik) => {
    setLoading(true)
    let data = { ...values }
    // dont send if is not a new file
    if (id) {
      api.updateTeam(data).handle({
        successMessage: messages.update.success,
        errorMessage: messages.update.error,
        onSuccess: (e) => {
          console.log(' =======>>>>>>>>> ', e)
          // navigate(ROUTES.SETTINGS_TEAM)
        },
        onError: (result) => { formik.setErrors(result?.errors) },
        onFinally: () => { setLoading(false) }
      })
    } else {
      api.createTeam(data).handle({
        successMessage: messages.create.success,
        errorMessage: messages.create.error,
        onSuccess: (e) => {
          console.log(' =======>>>>>>>>> ', e)
          // navigate(ROUTES.SETTINGS_TEAM)
        },
        onError: (result) => { formik.setErrors(result?.errors) },
        onFinally: () => { setLoading(false) }
      })
    }
  }

  const getJobs = () => {
    api.getJobs('')
      .then((result) => {
        const { data } = result
        const { results } = data
        setJobs(results)
      })
      .catch(() => showMessage())
  }

  useEffect(() => {
    getJobs('');
    getCustomFields();
    if (id) getJobCheckListDetail(id);
  }, [])


  return (

    <AdminLayout
    title={"Check List"}
    loading={loading}
    >
      <Grid container alignItems="top" paddingTop={0} marginTop={0}>
        <Grid ml={5} item xs={12} lg={9} md={9}>
          <MDTypography useBrandingColor={'tertiary'} variant="h2" fontWeight="medium" mb={1}>
            Job Check List
          </MDTypography>
        </Grid>
      </Grid>
      <Formik
        initialValues={Object.assign({}, getJobCheckListValues(JobCheckList), customFieldsInitialValues)}
        validationSchema={jobCheckListValidationSchema}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={postJobCheckList}
        enableReinitialize
      >
        {({ errors, setFieldValue, values }) => {

          return (
            <Form>

              <Grid container spacing={3} pb={3} className="task_container">


                <FormikInput
                  useBrandingColor={'secondary'}
                  name={'job'}
                  label={'Job'}
                  labelFieldName={'title'}
                  type={'select'}
                  placeholder={'select job'}
                  setFieldValue={setFieldValue}
                  options={Jobs}
                  errors={errors}
                  item xs={12} lg={6} md={6}
                />

                <FormikInput
                  useBrandingColor={'secondary'}
                  name={'title'}
                  label={'Job Form Title'}
                  placeholder={'input job form title'}
                  errors={errors}
                  item
                  xs={12}
                  md={6}
                />


                <FormikInput
                  useBrandingColor={'secondary'}
                  name={'section_name'}
                  label={'Section Name'}
                  placeholder={'input section name'}
                  errors={errors}
                  item
                  xs={12}
                  md={6}
                />

                <Grid item xs={12} lg={12} md={12}>
                  <hr className="MuiDivider-root MuiDivider-fullWidth" style={{ background: '#C6C9CE', margin: '16px 0' }} />
                </Grid>

                {customFields.map((field, index) => renderCustomField({ ...{ errors, setFieldValue, values }, field, index }))}
                <Grid item xs={6} lg={6} md={6} mt={4}>
                  <MDButton
                    type={'button'}
                    onClick={handleAddCustomField}
                    useBrandingColor={'primary'}
                    color="green"
                    styel={{ float: 'right' }}
                  >
                    ADD OPTION +
                  </MDButton>
                </Grid>
                <CustomFieldModal modalOpen={CustomFieldModalOpen} handleClose={onCloseCustomModal} />
                <RenderCustomFields form_id={FORM_ID} />

                <Grid item display={{ xs: 'block', lg: 'flex' }} xs={12} lg={12} md={12} mb={3} justifyContent={'space-evenly'}>
                  <Grid item xs={12} lg={3} md={5} mb={{ xs: 2 }}>
                    <MDButton
                      fullWidth
                      useBrandingColor={'primary'}
                      color="green"
                      variant="outlined"
                      type="submit"
                    >
                      Cancel
                    </MDButton>
                  </Grid>
                  <Grid item xs={12} lg={3} md={5} mb={{ xs: 2 }}>
                    <MDButton
                      fullWidth
                      useBrandingColor={'primary'}
                      color="green"
                      type="submit"
                    >
                      Create Job Check List
                    </MDButton>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          )
        }}
      </Formik>
    </AdminLayout>
  )
}
