import moment from "moment";
import numeral from "numeral";
import Toast from "../components/Toast";
import { useStores } from "../models";

import bankofamerica from "assets/images/icons/cards_brand/bank-of-america.png";
import visa from "assets/images/icons/cards_brand/visa.png";
import amex from "assets/images/icons/cards_brand/amex.png";
import mastercard from "assets/images/icons/cards_brand/mastercard.png";
import generic from "assets/images/icons/cards_brand/generic.png";
import {wrappifyApiCall} from "./helpers_ts";

export const downloadFile = (url) => {
  fetch(url)
    .then(response => response.blob())
    .then(blob => {
      const blobUrl = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = url.substring(url.lastIndexOf('/') + 1).split('?uuid')[0];
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
}

export const cardBrandIcon = (brand) => {
  const brands = {
    'bank-of-america': bankofamerica,
    'visa': visa,
    'amex': amex,
    'mastercard': mastercard
  }
  return !brand ? generic : brands[brand.toLocaleLowerCase()] || generic
}

export const timeout = (prom, time, error = 'Timeout') =>
  Promise.race([prom, new Promise((_r, rej) => setTimeout(rej, time, error))]);


export function makeid(length) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}


export const showMessage = (error = 'An error occurred, please try again later', type = 'error', checkError = false) => {
  let msg = error
  if (checkError) {
    const key = Object.keys(error)[0]
    msg = `${key}: ${error?.[key][0]}`
  }
  Toast.fire({
    icon: type,
    title: msg
  })
}

export const capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
}


export const dateRange = (start, end) => {
  var g1 = new Date(start);
  var g2 = new Date(end);
  if (g1.getTime() > g2.getTime()) {
    return false;
  } else {
    return true;
  }
}

export const truncate = (input, size) => input && input.length > size ? `${input.substring(0, size)}...` : input;

export const useApi = () => {
  const rootStore = useStores()
  return rootStore.environment.api
}

export const padToFour = number => number <= 9999 ? `000${number}`.slice(-4) : number;

export const money_fmt = (amount, hideSimbol = false) => {

  if (amount !== null && amount !== undefined) {
    
    return hideSimbol
      ? numeral(amount).format('0,0.00')
      : numeral(amount).format('$0,0.00')
  }
  return '-'
}

export const count_fmt = (amount) => {
  return numeral(amount).format('0,0')
}

export const checkDuplicatesInArray = (arrayOfValues, keyToCheck) => {
  let seen = new Set();
  return arrayOfValues.some(function(currentObject) {
    return seen.size === seen.add(currentObject[keyToCheck]).size;
  });
}

export const formatFormikArrayErrors = (index, errors, parentField, fields = []) => {
  let errorsFormatted = {}
  if (errors[parentField] !== undefined && errors[parentField][index] !== undefined) {
    const rawError = errors[parentField][index]
    fields.map(field_name => {
      if (rawError[field_name] !== undefined){
        errorsFormatted[`${parentField}[${index}].${field_name}`] = rawError[field_name]
      }
    })
  }
  return errorsFormatted
}

export const generateOptionsFromString = (string) => {
  const initialArray =  [...new Set(string.split(','))]
  const returnArray = []
  initialArray.map((item, index) => {
    const newObj = {
      label: item,
      value: item
    }
    returnArray.push(newObj)
  })
  return returnArray
}

export const getFieldInitialValue = (fields) => {
  const data = {}
  fields.map(field => {
    data[field['field_name']] = field['default_value'].value
  })
  return data
}

export const collect_files = (data) => {
  const files = []
  data = collect_files_internal(data, '', files)
  return [data, files]
}

const collect_files_internal = (objekt, current_path, collector) => {
  if (objekt === null || objekt === undefined){
    return objekt
  }
  if (objekt instanceof File){
    collector.push([current_path, objekt])
    return null
  }
  const sep = current_path === '' ? '' : '.'
  if (objekt.constructor === Array && objekt.map){
    return objekt.map((el, index) => {
      return collect_files_internal(el, `${current_path}${sep}[${index}]`, collector)
    })
  }
  if (typeof objekt === 'object'){
    const res = {}
    Object.entries(objekt).forEach(([key, el]) => {
      res[key] = collect_files_internal(el, `${current_path}${sep}${key}`, collector)
    })
    return res
  }
  return objekt
}


export const generateEventsByDay = (events) => {
  let eventsByDay = [];
  if (events && events.length > 0) {
    events.forEach((e, i) => {
      const eventDurationInDays = moment(events[i].end).diff(moment(events[i].start), 'days');
      const baseInitialDatetime = new Date(e.start);
      const baseEndDatetime = new Date(e.end);
      for (let i = 0; i <= eventDurationInDays - 1; i++) {
        const initialDatetime = new Date(new Date(baseInitialDatetime).setDate(baseInitialDatetime.getDate() + i));
        const endDatetime = new Date(
          new Date(baseEndDatetime).setDate(baseEndDatetime.getDate() + i)).setFullYear(initialDatetime.getFullYear());
        eventsByDay[i] = {
          ...e,
          start: initialDatetime,
          end: endDatetime,
        }
      }
    })
  }
  return eventsByDay;
}


export const generateSerialNumber = () => {
  let chars = '1234567890ABCDEFGHIJKLMNOPQRSTUVXWZabcdefghijklmnopqrstuvxwz';
  let serialNumbers = 20;
  let count = 0;
  let serial = "";
  for (count; count < serialNumbers; count++) {
    let random = Math.floor(Math.random() * chars.length);
    serial += chars[random];
  }
  return serial;
}

export const formatTimeSeconds = (time) => {
  const hours = Math.floor(time / 3600);
  const minutes = Math.floor((time % 3600) / 60);
  const seconds = time % 60;

  const formattedHours = String(hours).padStart(2, '0');
  const formattedMinutes = String(minutes).padStart(2, '0');
  const formattedSeconds = String(seconds).padStart(2, '0');

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
};
