/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import {useEffect} from "react";

// react-router components
import {Navigate, Route, Routes, useLocation} from "react-router-dom";

// @mui material components
import {ThemeProvider} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Dashboard 2 PRO React themes
import theme from "assets/theme";

// Material Dashboard 2 PRO React Dark Mode themes
import themeDark from "assets/theme-dark";

// Material Dashboard 2 PRO React routes
import routes from "routes";

// Material Dashboard 2 PRO React contexts
import {useMaterialUIController} from "context";

import {useStores} from "models";

// PubNub
import PubNub from "pubnub";
import {PubNubProvider} from "pubnub-react";

// Screens
import SetNewPassword from "./screens/set-new-password";
import ActivateUser from "./screens/activate-user";
import Logout from "./screens/logout";

// Utils
import {useApi} from "services/helpers";
import {ROUTES} from "./constants";

const pubnub = new PubNub({
  publishKey: process.env.REACT_APP_PUBNUB_PUBLISH_KEY,
  subscribeKey: process.env.REACT_APP_PUBNUB_SUBSCRIBE_KEY,
  uuid: process.env.REACT_APP_PUBNUB_UUID,
});

export default function App() {
  const api = useApi()

  const [controller, dispatch] = useMaterialUIController();
  const {
    direction,
    darkMode,
  } = controller;
  const {pathname} = useLocation();

  const rootStore = useStores()
  const { loginStore } = rootStore

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) => {
    const loginRedirect = <Route exact path="*" element={<Navigate to="/"/>}/>

    const redirectRole = {
      ADMIN: <Route exact path="*" element={<Navigate to="/admin/analytics"/>} key={'admin'}/>,
      CLIENT: <Route exact path="*" element={<Navigate to="/client/appointments"/>} key={'client'}/>,
      SUPERVISOR: <Route exact path="*" element={<Navigate to="/home"/>} key={'supervisor'}/>,
    }

    return allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }
      if (route.route) {
        // not logged
        if (route.protected && !loginStore.isLoggedIn) {
          return loginRedirect
        }
        // logged
        else if (!route.protected && loginStore.isLoggedIn) {
          return redirectRole[loginStore.role] || route.route
        } else {
          if (route.routeRole && (route.routeRole !== loginStore.role)) return redirectRole[loginStore.role] || route.route
          else return <Route exact path={route.route} element={route.component} key={route.key}/>;
        }
      }
      return null;
    })
  }

  return <ThemeProvider theme={darkMode ? themeDark : theme}>
    <CssBaseline/>
    <PubNubProvider client={pubnub}>
      <Routes>
        {getRoutes(routes)}
        <Route path={ROUTES.SET_NEW_PASSWORD} element={<SetNewPassword/>}/>
        <Route path={ROUTES.ACTIVATE_USER} element={<ActivateUser/>}/>
        <Route path={ROUTES.LOGOUT} element={<Logout/>}/>
        <Route path="*" element={<Navigate to={ROUTES.ROOT}/>}/>
      </Routes>
    </PubNubProvider>
  </ThemeProvider>
}
