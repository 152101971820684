import React, {useEffect, useState} from "react";
import {useNavigate, Link as ReactRouterLink} from "react-router-dom";
import moment from 'moment'

// @mui material components
import CheckIcon from "@mui/icons-material/Check";
import {Link, Grid, useMediaQuery} from '@mui/material';

// Components
import AdminLayout from "components/AdminLayout";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

import TimeSheetsTab from './pages/TimesheetsTab';
import ApproveTab from './pages/ApproveTab';
import PayrollTab from './pages/PayrollTab';

// Utils
import {useStores} from "models";
import {showMessage} from "services/helpers";
import {ROUTES} from "constants";

// Assets
import theme from "assets/theme";
import "./styles.css";

export default function Timesheets() {
  const navigate = useNavigate();
  const rootStore = useStores();
  const {loginStore} = rootStore;
  const [value, setValue] = React.useState(1);
  const [allData, setAllData] = useState({approve: [], payroll: []});
  const [currentClient, setCurrentClient] = useState(null);
  const [currentJob, setCurrentJob] = useState(null);

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const handleChange = (val) => {
    setValue(val);
  };

  const updateTimeEntryStatus = (id, data) => {
    loginStore.environment.api.updateTimeEntryStatus(id, data)
      .then(() => {
        showMessage('Action has been executed', 'success')
        getAllTimeEntries()
      })
      .catch(() => showMessage())
  }

  const calculateExpenses = (data) => {
    let amount = ''
    if (!data.end_date || !data.finish_user) return amount
    amount = parseInt(data.finish_user.compensation) * parseInt(moment(data.end_date).diff(moment(data.start_date), 'hours'))
    return `$${amount}`

  }

  const getAllTimeEntries = () => {
    setAllData({approve: [], payroll: []})
    loginStore.environment.api.getTimeEntry()
      .then((result) => {
        const {data} = result
        const {results} = data
        const resultsFormatted = results.map(r => ({
          id: r.id,
          task: r.task,
          job: r.job,
          start_user: r?.start_user?.name,
          finish_user: r?.finish_user?.name,
          start_date: r.start_date ? moment(r.start_date).format('DD/MM/YYYY HH:mm') : '',
          end_date: r.end_date ? moment(r.end_date).format('DD/MM/YYYY HH:mm') : '',
          time: r.end_date ? `${moment(r.end_date).diff(moment(r.start_date), 'minutes')} minutes` : '',
          approved: r?.approved,
          paid: r?.paid,
          expenses: calculateExpenses(r), 
          link_job_id: <ReactRouterLink to={ROUTES.EDIT_JOB(r?.job)} className='base-item link-item'>{r.job_title}</ReactRouterLink>,
          link_task_id: <ReactRouterLink to={`/home`} className='base-item link-item'>{r.task_title}</ReactRouterLink>
        }))

        const approveTimeSheetsData = resultsFormatted.map(r => ({
          ...r,
          action: r.approved
            ? <MDTypography
            sx={{ textAlign: 'center', marginRight: 5 }}
              useBrandingColor={'primary'}
              variant="h6">
              <CheckIcon sx={{ marginRight: 1 }} />Approved
            </MDTypography>
            : <MDButton
              useBrandingColor={'primary'}
              onClick={() => updateTimeEntryStatus(r.id, { approved: true })}
              color={'success'}
              disabled={r.approved}
            >
              Approve
            </MDButton>,
        }));

        const payrollData = resultsFormatted.map(r => ({
          ...r,
          action: r.paid
            ? <MDTypography
              sx={{ textAlign: 'center', marginRight: 5 }}
              useBrandingColor={'primary'}
              variant="h6">
              <CheckIcon sx={{ marginRight: 1 }} />Paid
            </MDTypography>
            : (
              <MDButton
                disabled={r.paid}
                useBrandingColor={'primary'}
                color={'success'}
                onClick={() => updateTimeEntryStatus(r.id, { paid: true })}
              >
                {r.paid ? 'Paid' : 'Mark as paid'}
              </MDButton>
            )
        }));

        setAllData({ payroll: payrollData?.filter(p => p.approved), approve: approveTimeSheetsData })
      })
      .catch(() => showMessage())
  }

  useEffect(() => {
    getAllTimeEntries();
  }, []);

  return (
    <AdminLayout title={"TimeSheets"}>
      <MDBox sx={{width: '100%'}} pt={{xs: 7.5, sm: 0}}>
        <Grid container alignItems='center' justifyContent='space-between' className="tabs">
          {isMobile
            ? (
              <Grid item xs={12} justifyContent='space-between' mb={2}>
                <MDBox
                  justifyContent={{xs: 'space-between'}}
                  display={'flex'}
                  alignItems='flex-end'
                  pt={'2px'}
                  gap={'10px'}
                >
                  <MDButton
                    useBrandingColor={'primary'}
                    color="green"
                    size='small'
                    onClick={() => handleChange(1)}
                    className={value === 1 ? 'is-on' : ''}
                    sx={{width: '125px'}}
                  >
                    TimeSheets
                  </MDButton>
                  <MDButton
                    useBrandingColor={'primary'}
                    color="green"
                    size='small'
                    onClick={() => handleChange(2)}
                    className={value === 2 ? 'is-on' : ''}
                    sx={{width: '125px'}}
                  >
                    Approve
                  </MDButton>
                  <MDButton
                    useBrandingColor={'primary'}
                    color="green"
                    size='small'
                    onClick={() => handleChange(3)}
                    className={value === 3 ? 'is-on' : ''}
                    sx={{width: '125px'}}
                  >
                    Payroll
                  </MDButton>
                </MDBox>
              </Grid>
            )
            : (
              <Grid item xs={12} md={9} lg={11} xxl={11}>
                <Link component="button" onClick={() => handleChange(1)}>
                  <MDTypography
                    className={["tab", value === 1 ? "active" : ""].join(" ")}>TimeSheets</MDTypography>
                </Link>
                <Link component="button" onClick={() => handleChange(2)}>
                  <MDTypography className={["tab", value === 2 ? "active" : ""].join(" ")}>Approve
                    TimeSheets</MDTypography>
                </Link>
                <Link component="button" onClick={() => handleChange(3)}>
                  <MDTypography
                    className={["tab", value === 3 ? "active" : ""].join(" ")}>Payroll</MDTypography>
                </Link>
              </Grid>
            )}
        </Grid>
        <MDBox className={value === 1 ? "is-active" : "not-active"}>
          <TimeSheetsTab {...{currentJob, setCurrentJob, currentClient, setCurrentClient}} />
        </MDBox>
        <MDBox className={value === 2 ? "is-active" : "not-active"} mt={8}>
          <ApproveTab {...{data: allData.approve, currentJob, currentClient}} />
        </MDBox>
        <MDBox className={value === 3 ? "is-active" : "not-active"} mt={8}>
          <PayrollTab {...{data: allData.payroll, currentJob, currentClient}} />
        </MDBox>
      </MDBox>
    </AdminLayout>
  )
}
