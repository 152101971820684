import * as Yup from 'yup';
import moment from 'moment'


export const getRequestInitialValues = (request) => {

  let data = {
    title: request ? request.title : '',
    service_details: request ? request.service_details : '',
    sight_assessment_required: request ? request.sight_assessment_required : false,
    request_for: request ? request.request_for : null,
    start_date: request?.start_date ? moment(request.start_date).format('YYYY-MM-DD HH:mm:ss') : '',
    end_date: request?.end_date ? moment(request.end_date).format('YYYY-MM-DD HH:mm:ss') : '',
    assign_to: request ? request.assign_to : null,
    internal_note: request ? request?.internal_note : '',
    disable_client_notifications: request?.disable_client_notifications || false
  }


  console.log('=======>>>>>>>>> ', { request, data })

  return data
};

export const requestValidationSchema = Yup.object().shape({
  title: Yup.string().required(),
  service_details: Yup.string().required(),
  sight_assessment_required: Yup.boolean(),
  start_date: Yup.string(),
  end_date: Yup.string(),
});