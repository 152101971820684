
import {forwardRef} from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Custom styles for MDButton
import MDButtonRoot from "components/MDButton/MDButtonRoot";

// Material Dashboard 2 PRO React contexts
import {useMaterialUIController} from "context";
import {CircularProgress} from "@mui/material";
import { useStores } from "models";


const MDButton = forwardRef(
  ({ loading = false, color, useBrandingColor = null,  backgroundColor, variant, size, circular, iconOnly, children, ...rest }, ref) => {
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;
    const rootStore = useStores()
    const { loginStore } = rootStore;

    if (useBrandingColor) {
      try {
        backgroundColor = JSON.parse(loginStore.branding_data?.[useBrandingColor])?.hex
      } catch (error) {}
    }
    return (
      <MDButtonRoot
        {...rest}
        ref={ref}
        color={'primary'}
        backgroundColor={backgroundColor}
        variant={variant === "gradient" ? "contained" : variant}
        size={size}
        disabled={loading}
        ownerState={{ color, variant, size, circular, iconOnly, darkMode }}
      >
        {loading ? <CircularProgress color="inherit" size={15} /> : children}
      </MDButtonRoot>
    );
  }
);

// Setting default values for the props of MDButton
MDButton.defaultProps = {
  size: "medium",
  variant: "contained",
  color: "white",
  circular: false,
  iconOnly: false,
};

// Typechecking props for the MDButton
MDButton.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),
  variant: PropTypes.oneOf(["text", "contained", "outlined", "gradient", "standard", 'transparent']),
  color: PropTypes.oneOf([
    "white",
    "primary",
    "secondary",
    "secondaryWhite",
    "tertiary",
    "info",
    "success",
    "warning",
    "error",
    "pink",
    "light",
    "dark",
    "gray",
    "green",
  ]),
  circular: PropTypes.bool,
  iconOnly: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default MDButton;
