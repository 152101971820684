import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Chip from '@mui/material/Chip';
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import { navbarIconButton } from "examples/Navbars/DashboardNavbar/styles";
import MDButton from "../../../components/MDButton";
import MDTypography from 'components/MDTypography';
import { ActionButton } from './ActionButton';
import "./tables.css";
//icons
import DownloadIcon from "assets/images/icons/download.png";
import EyeIcon from "assets/images/icons/eye.png";


export const Tables = (props) => {
  const { body, header, total, view } = props;
  const [body_keys, setBody_keys] = React.useState();
  React.useEffect(() => {
    if(body.length){
      setBody_keys(Object.keys(body[0]))
    }
  }, [body])
  const ActionButtons = (actions) => {
    if (actions === 'team') return <ActionButton />
  }

  return (
    <TableContainer>
      <Table>
        <TableBody>
          <TableRow className="t_header_tr">
            {header.map((row, i) => (
              <TableCell align={row.align} className="t_header">{row.title}</TableCell>
            ))}
          </TableRow>

          {/* No data */}
          {!body_keys && (
            <TableRow
              key="1"
              className="t_body_tr"
            >
              <TableCell align="left">No data</TableCell>
            </TableRow>
          )}

          {/* Has data */}
          {body_keys && body.map((row, i) => (
            <TableRow
              key={i}
              className="t_body_tr"
            >
              {body_keys && body_keys.map((d, ii) =>
                <TableCell key={`d_${ii}`} align={d == 'actions' ? 'center' : 'left'}>{d == 'actions' ? ActionButtons(row[d]) : row[d]}</TableCell>
              )}
            </TableRow>
          ))}
          {total && <TableRow className="t_header_tr">
            {total.map((row, i) => (<>
              {row.col && <TableCell colSpan={row.col}></TableCell>}
              <TableCell align={row.align} className="t_header">
                {row.class ? (
                  <MDTypography color={row.class} variant="h6" fontWeight="bold">
                    {row.title}{" "}{row.total}
                  </MDTypography>
                ) : (
                  row.title + " " + row.total
                )
                }
              </TableCell>
            </>))}
          </TableRow>}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
