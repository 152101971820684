import React, { useState } from 'react';
import { Grid } from '@mui/material';
import './headers.css';

export const Header = (props) => {
    const {data, styles} = props;

    return (

        <Grid container justifyContent={'space-between'} spacing={1}>
            {data.map((row, i) => (
                <div key={row.title} className={['boxess-d', row.class && row.class].join(" ")} style={styles}>
                <h6 style={{ display: "flex", justifyContent: "flex-start" }}>{row.title}</h6>
                {row.description && <p style={{ display: "flex", justifyContent: "flex-start", fontSize:14 }}>{row.description}</p>}
                <span style={{ display: "flex", alignItems: "center" }}>{row.quantity}</span>
                <p style={{ display: "flex", justifyContent: "flex-start" }}>{row.content}</p>
            </div>
            ))}
        </Grid>
    );
}