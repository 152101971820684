import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Formik, Form} from 'formik';
import moment from "moment";
import * as Yup from 'yup';


// @mui material components
import {Grid, Tabs, Tab} from '@mui/material';

// Components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDDialog from "components/MDDialog";
import MDAutocomplete from "components/MDAutocomplete";
import FormikInput from "components/FormikInput";
import MonthsSwitch from "../components/MonthsSwitch";

// Utils
import {useStores} from "models";
import {showMessage} from "services/helpers";

// Assets
import SchedulerIcon from 'assets/images/icons/scheduler.png';
import pxToRem from "assets/theme/functions/pxToRem";
import "../styles.css";


export default function TimeSheetsTab({currentJob, setCurrentJob, currentClient, setCurrentClient}) {
  const navigate = useNavigate();
  const rootStore = useStores();
  const {loginStore} = rootStore;


  /* data states */
  const [Clients, setClients] = useState([]);
  const [allJobs, setAllJobs] = useState([]);
  const [filteredJobs, setFilteredJobs] = useState([]);
  const [Tasks, setTasks] = useState([]);
  /* data states */

  const [start, setStart] = React.useState(false);
  const [openAddTimeModal, setOpenAddTimeModal] = useState(false);
  const [tabValue, setTabValue] = useState(0);

  const AddTimeInitialValues = {
    task: null,
    job: null,
    start_date: null,
    end_date: null,
    start_user: loginStore.id,
    finish_user: loginStore.id,
  };

  const AddTimeValidationSchema = Yup.object().shape({
    start_date: Yup.date().min(moment().add(1, "hours"), 'The start datetime must be greater than the current time').required('This is a required field'),
    end_date: Yup.date().min(moment().add(2, "hours"), "End datetime must be greater than initial date"),
    job: Yup.number().nullable(),
    task: Yup.number().nullable(),
    start_user: Yup.number(),
    finish_user: Yup.number(),
  });

  const handleStart = () => {
    setStart(!start);
    createTimeEntryReq();
  }

  const getClients = () => {
    loginStore.environment.api.getClients('')
      .then((result) => {
        const {data} = result;
        let results = data.results.map(d => ({...d, label: d.name, value: d.id}));
        setClients(results)
      })
      .catch(() => showMessage())
  }

  const getJobs = () => {
    loginStore.environment.api.getJobs('')
      .then(result => {
        const {data} = result;
        let results = data.results.map(d => ({label: d.title, value: d.id, ...d}));
        setFilteredJobs(results);
        setAllJobs(results);
      })
  }

  const getTasks = (searchData, extraData) => {
    loginStore.environment.api.getAllTasks(searchData, extraData)
      .then(response => {
        const tasksData = response.data.results.map(t => ({value: t.id, label: t.title, ...t}));
        setTasks(tasksData);
      })
      .catch(() => showMessage())
  }

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  }

  const createTimeEntryReq = (values = null) => {
    let data
    if (values) {
      data = {...values}
    }
    else {
      const currentClocking = currentJob.job_timeEntries?.find(j => !j?.end_date);
      currentClocking
        ? data = {
          ...currentClocking,
          start_user: currentClocking?.start_user?.id,
          finish_user: loginStore.id,
          end_date: moment().format(),
        }
        : data = {
          job: currentJob.id,
          start_date: moment().format(),
          end_date: null,
          start_user: loginStore.id,
          finish_user: null,
        }
    }
    loginStore.environment.api.createTimeEntry(data).then((result) => {
      if (result.kind === "ok") {
        showMessage('Time Entry created', 'success');
        return setOpenAddTimeModal(false)
      } else {
        if (result.kind === "bad-data") {
          showMessage(result?.errors, 'error', true)
        } else {
          showMessage('An error occurred, please try again later')
        }
      }
    })
  }

  const manageTimeEntryView = () => {
    if(currentJob?.job_timeEntries?.find(j => !j.end_date)) {
      return setStart(true);
    }
    return setStart(false);
  }

  const filterJobsBySelectedClient = () => {
    return setFilteredJobs(allJobs.filter(j => j?.quote?.client_id === currentClient?.id))
  }

  useEffect(() => {
    getClients();
    getJobs();
    getTasks('');
  }, []);

  useEffect(() => {
    if (currentClient) {
      filterJobsBySelectedClient();
    }
  }, [currentClient])

  useEffect(() => {
    if(currentJob) {
      manageTimeEntryView();
    }
  }, [currentJob])

  return (
    <MDBox>
      <Grid container className="tabs-container" spacing={3} pb={3}>
        <Grid item xs={12} md={12}>
          <MDAutocomplete
            useBrandingColor={'secondary'}
            fullWidth
            placeholder='search for client'
            options={Clients}
            onChange={(event, newValue) => setCurrentClient(newValue)}
            name='clients'
          />
        </Grid>
        {currentClient &&
          <Grid item xs={12} md={12}>
            <div className="myavatar">
              <MDTypography className="myavatar-name">
                {currentClient?.name}
                <br/>
                <span className="myavatar-email">{currentClient?.email}</span>
              </MDTypography>
            </div>
          </Grid>
        }

        <Grid item xs={12} md={6}>
          <MonthsSwitch view={"daily"}/>
        </Grid>
        <Grid
          item
          display={'flex'}
          justifyContent={{xs: 'space-between', md: 'flex-end'}}
          gap={{xs: 0, md: 4}}
          xs={12} md={6}
        >
          <MDButton useBrandingColor={'primary'} color="green" className="icon-button">
            <img src={SchedulerIcon}/>
          </MDButton>
          <MDButton useBrandingColor={'primary'} color="green">Today</MDButton>
        </Grid>

        <Grid item xs={12} md={12}>
          <hr className="MuiDivider-root MuiDivider-fullWidth" style={{background: '#C6C9CE', margin: '12px 0'}}/>
        </Grid>

        <Grid item xs={4} md={4} display="flex" justifyContent="start" alignItems="start">
          <MDTypography variant="h6" style={{float: 'left'}}>My hours for today</MDTypography>
        </Grid>
        <Grid item xs={4} md={4} display="flex" justifyContent="center" alignItems="center">
          <MDButton useBrandingColor={'primary'} color="green" onClick={() => setOpenAddTimeModal(true)}>Add Time +</MDButton>
        </Grid>
        <Grid item xs={4} md={4} display="flex" justifyContent="end" alignItems="end">
          <MDTypography variant="h6">Day total: 10h</MDTypography>
        </Grid>
        <Grid item xs={12} md={12}>
          <Grid container className={["myavatar", start ? "on" : " "].join(" ")}>
            <Grid item xs={12} md={6}>
              <MDTypography variant="h6">Job Name</MDTypography>
              <MDAutocomplete
                useBrandingColor={'secondary'}
                placeholder="select job"
                options={filteredJobs}
                onChange={(e, newValue) => setCurrentJob(newValue)}
              />
            </Grid>
            <Grid
              item
              display="flex"
              alignItems="flex-end"
              justifyContent={{xs: 'center', md: 'flex-end'}}
              mt={{xs: 2, md: 0}}
              xs={12} md={6}
            >
              <MDButton useBrandingColor={'primary'} color={start ? "error" : "green"} onClick={handleStart} disabled={!currentJob}>
                {start ? 'End' : 'Start'} Time
              </MDButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <MDDialog
        open={openAddTimeModal}
        handleClose={() => [setOpenAddTimeModal(false)]}
        title='Add Time Entry'
        style={{width: 600, height: 300}}
      >
        <Formik
          validateOnBlur={false}
          validateOnMount={true}
          initialValues={AddTimeInitialValues}
          validationSchema={AddTimeValidationSchema}
          onSubmit={(values) => createTimeEntryReq(values)}
          enableReinitialize
        >
          {({values, errors, handleChange, setFieldValue}) => {
            const handleChangeDate = (e) => {
              const newEvent = {...e, target: {...e.target, value: moment(e.target.value)} }
              handleChange(newEvent)
            }
            return (
              <Form>
                <Tabs value={tabValue} onChange={handleChangeTab} variant='fullWidth'>
                  <Tab label='Jobs' value={0}/>
                  <Tab label='Tasks' value={1}/>
                </Tabs>
                <MDBox mt={2}>
                  <MDAutocomplete
                    useBrandingColor={'secondary'}
                    options={tabValue === 0 ? allJobs : Tasks}
                    name={tabValue === 0 ? 'job' : 'task'}
                    label={tabValue === 0 ? 'Job' : 'Task'}
                    onChange={(e, newValue) => {
                      setFieldValue(tabValue === 0 ? 'job' : 'task', newValue.value)
                    }}
                    errors={errors}
                  />
                </MDBox>
                <MDBox display='flex' gap={pxToRem(10)} mt={2}>
                  <FormikInput useBrandingColor={'secondary'} name='start_date' label='Start date' onChange={handleChangeDate} errors={errors} type='datetime-local' flex={1}/>
                  <FormikInput useBrandingColor={'secondary'} name='end_date' label='End date' onChange={handleChangeDate} errors={errors} type='datetime-local' flex={1}/>
                </MDBox>
                <MDBox display='flex' justifyContent='center' mt={2}>
                  <MDButton useBrandingColor={'primary'} type='submit' variant='contained' color='green' size='medium'>Add time</MDButton>
                </MDBox>
              </Form>
            )
          }}
        </Formik>
      </MDDialog>
    </MDBox>
  )
}
