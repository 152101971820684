import * as Yup from "yup";
import MDButton from "../../../components/MDButton";


export const clientValidationSchema =
  Yup.object().shape({
    first_name: Yup.string().required(),
    last_name: Yup.string().required(),
    phone_number: Yup.string().required(),
    email: Yup.string().required(),
    address: Yup.string(),
    is_staff: Yup.boolean(),
    compensation: Yup.string(),
    extension_number: Yup.number().min(0, "Minimum 1").max(999, "maximum allowed is 999"),
    default_calling_group: Yup.boolean(),

  })

export const clientInitialValues = (data = null) => ({
  id: data ? data.id : null,
  first_name: data ? data.first_name : '',
  last_name: data ? data.last_name : '',
  phone_number: data ? data.phone_number : '',
  email: data ? data.email : '',
  // TODO: CHANGE TO REAL ADDRESS
  address: data ? '' : '',
  extension_number: data ? data.extension_number : '',
    default_calling_group: data ? data.default_calling_group : false,

  is_staff: data ? data.is_staff : false,
  compensation: data ? data.compensation : 0,
})
