

export const inputs = [
    [
        {
            type: 'select',
            key: 'header_layout',
            label: 'Header Layout',
            options: [
                { value: 'BASIC', label: 'Basic' },
                { value: 'COMPACT', label: 'Compact' }
            ],
            value: null,
        },
        {
            type: 'select',
            key: 'header_style',
            label: 'Header Style',
            options: [
                { value: 'MODERN', label: 'Modern' },
                { value: 'CLEAN', label: 'Clean' }
            ],
            value: null,
        },
        {
            type: 'select',
            key: 'header_color',
            label: 'Header Color',
            options: [
                { value: 'DEFAULT', label: 'Default' },
                { value: 'BLUE', label: 'Blue' },
                { value: 'RED', label: 'Red' },
                { value: 'GREEN', label: 'Green' },
                { value: 'ORANGE', label: 'Orange' },
                { value: 'PURPLE', label: 'Purple' },
            ],
            value: null,
        },
        {
            type: 'select',
            key: 'logo_size',
            label: 'Logo Size',
            options: [
                { value: 'SMALL', label: 'Small' },
                { value: 'MEDIUM', label: 'Medium' }
            ],
            value: null,
        },
        {
            type: 'check',
            key: 'show_name_with_logo',
            label: 'Show name with logo',
            value: null,
        },
        {
            type: 'check',
            key: 'show_phone_number_header',
            label: 'Show your phone number in header',
            value: null,
        },
        {
            type: 'check',
            key: 'show_email_header',
            label: 'Show your email in header',
            value: null,
        },
        {
            type: 'check',
            key: 'show_website_header',
            label: 'Show your website in header',
            value: null,
        },

        // TODO
        {
            type: 'select',
            key: 'footer_font_size',
            label: 'Footer Font Size',
            options: [
                { value: '10', label: '10' },
                { value: '11', label: '11' },
                { value: '12', label: '12' },
                { value: '13', label: '13' },
                { value: '14', label: '14' },
                { value: '15', label: '15' },
            ],
            value: null,
        },
    ],
    [
        {
            type: 'check',
            key: 'quote_refer_quotes_as_estimated',
            label: 'Refer to "Quote" as "Estimate" in PDFs, client hub and customer communications',
            value: null,
        },
        {
            type: 'check',
            key: 'quote_show_qty_on_lines',
            label: 'Show QTY on line items',
            value: null,
        },
        {
            type: 'check',
            key: 'quote_show_unit_on_lines',
            label: 'Show unit price on line items',
            value: null,
        },
        {
            type: 'check',
            key: 'quote_show_total_cost_per_items',
            label: 'Show total cost per line items',
            value: null,
        },
        {
            type: 'text',
            key: 'quote_contract_disclaimer',
            label: 'Contract/Disclaimer',
            value: null,
        },
        {
            type: 'text_multiline',
            key: 'quote_message',
            lines: 5,
            label: 'Message',
            value: null,
        },

    ],
    [
        {
            type: 'check',
            key: 'job_include_client_signature_line',
            label: 'Include client signature line',
            value: null,
        },
        {
            type: 'text',
            key: 'job_contract_disclaimer',
            label: 'Contract/Disclaimer',
            value: null,
        },
        {
            type: 'text_multiline',
            key: 'job_message',
            lines: 5,
            label: 'Message',
            value: null,
        },
    ],
    [
        {
            type: 'check',
            key: 'invoice_refer_quotes_as_estimated',
            label: 'Refer to "Quote" as "Estimate" in PDFs, client hub and customer communications',
            value: null,
        },
        {
            type: 'check',
            key: 'invoice_show_qty_on_lines',
            label: 'Show QTY on line items',
            value: null,
        },
        {
            type: 'check',
            key: 'invoice_show_unit_on_lines',
            label: 'Show unit price on line items',
            value: null,
        },
        {
            type: 'check',
            key: 'invoice_show_total_cost_per_items',
            label: 'Show total cost per line items',
            value: null,
        },
        {
            type: 'text',
            key: 'invoice_contract_disclaimer',
            label: 'Contract/Disclaimer',
            value: null,
        },
        {
            type: 'text_multiline',
            key: 'invoice_message',
            lines: 5,
            label: 'Message',
            value: null,
        },

    ],
]