import React, { useState } from 'react';

// @mui components
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import {styled} from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TuneIcon from "@mui/icons-material/Tune";
import Menu from '@mui/material/Menu';

const GreenOutlinedButton = styled(Button)(({ theme }) => ({
  backgroundColor: "transparent",
  borderColor:"var(--green)",
  color:'var(--green)',
  display: 'flex',
  '&:hover': {
    borderColor:  "var(--dark-green)",
    boxShadow: 'none',
    color:'var(--dark-green)'
  },
  marginLeft: 'auto'
}));

export const FilterButton = ({...rest})=> {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <GreenOutlinedButton
        variant="outlined"
        endIcon={<ExpandMoreIcon />}
        startIcon={<TuneIcon />}
        onClick={handleClick}
        className={'btn-filter'}
        {...rest}
      >
        Filter
      </GreenOutlinedButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleClose}>Team A</MenuItem>
        <MenuItem onClick={handleClose}>Team B</MenuItem>
        <MenuItem onClick={handleClose}>Team C</MenuItem>
      </Menu>
    </div>
  );
}
