import { useEffect, useState } from "react";
import moment from 'moment'
import styles from "../scheduler/Scheduler.module.css";
import { Grid, InputAdornment, IconButton } from '@mui/material';
import AdminLayout from "../../components/AdminLayout";
import { useNavigate } from "react-router-dom";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import MDInput from "../../components/MDInput";
import MDButton from "../../components/MDButton";
import MDDatePicker from "../../components/MDDatePicker";
import MDAutocomplete from "../../components/MDAutocomplete";
import {FilterButton} from "./components/FilterButton";
import {Tables} from "./components/Tables";
//icons
import AccessTimeIcon from '@mui/icons-material/AccessTime';


export default function AllNotifications() {
    const navigate = useNavigate();
    const [Step, setStep] = useState(1);
    const [ShowTermsConditions, setShowTermsConditions] = useState(false);
    const [ShowPrivacyPolicy, setShowPrivacyPolicy] = useState(false);

  return (

    <AdminLayout title={"All Notifications"}>
        <Grid container alignItems="top" paddingTop={0} marginTop={0}>
            <Grid xs={5} lg={5} md={5} mb={3}>
                <MDTypography variant="h2" fontWeight="medium" mb={1} ml={3}>
                All Notifications
                </MDTypography>
            </Grid>
            <Grid xs={7} lg={7} md={7} justifyContent={"right"} display={"flex"} flexDirection={"row"}>
                
                <FilterButton />
                
            </Grid>
        </Grid>
    <Grid container spacing={3}>
        <Grid item xs={12} lg={12} md={12} ml={2}>
            <Tables />
        </Grid>
    </Grid>
    </AdminLayout>
  )
}
