import { useEffect, useState } from "react";
import moment from 'moment'
import { Grid, InputAdornment, IconButton } from '@mui/material';
import AdminLayout from "../../../components/AdminLayout";
import { useNavigate } from "react-router-dom";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import MDInput from "../../../components/MDInput";
import MDButton from "../../../components/MDButton";
import MDDatePicker from "../../../components/MDDatePicker";
import {HeaderFilter} from "../components/HeaderFilter";
import {Tables} from "../components/Tables";
import {DateFilter} from "../components/DateFilter";
import "../styles.css"

const header = [
    {title: 'Name (A-Z)', align: '', id: 1},
    {title: 'Phone', align: '', id: 2},
    {title: 'Email', align: '', id: 3},
    {title: 'Billing Address', align: '', id: 4},
    {title: 'Last Job Closed on', align: '', id: 5},
    {title: '', align: '', id: 6}
];
const rows = [
    {number: 'Jane Cooper', name: 'Lorem Ipsum', created: 'Lorem Ipsum', total: 'Lorem Ipsum', due: 'July 18, 2022', actions: 'client_contact_info', id: 1},
    {number: 'Jane Cooper', name: 'Lorem Ipsum', created: 'Lorem Ipsum', total: 'Lorem Ipsum', due: 'July 18, 2022', actions: 'client_contact_info', id: 2},
    {number: 'Jane Cooper', name: 'Lorem Ipsum', created: 'Lorem Ipsum', total: 'Lorem Ipsum', due: 'July 18, 2022', actions: 'client_contact_info', id: 3},
    {number: 'Jane Cooper', name: 'Lorem Ipsum', created: 'Lorem Ipsum', total: 'Lorem Ipsum', due: 'July 18, 2022', actions: 'client_contact_info', id: 4},
    {number: 'Jane Cooper', name: 'Lorem Ipsum', created: 'Lorem Ipsum', total: 'Lorem Ipsum', due: 'July 18, 2022', actions: 'client_contact_info', id: 5},
    {number: 'Jane Cooper', name: 'Lorem Ipsum', created: 'Lorem Ipsum', total: 'Lorem Ipsum', due: 'July 18, 2022', actions: 'client_contact_info', id: 6},
    {number: 'Jane Cooper', name: 'Lorem Ipsum', created: 'Lorem Ipsum', total: 'Lorem Ipsum', due: 'July 18, 2022', actions: 'client_contact_info', id: 7},
    {number: 'Jane Cooper', name: 'Lorem Ipsum', created: 'Lorem Ipsum', total: 'Lorem Ipsum', due: 'July 18, 2022', actions: 'client_contact_info', id: 8},
  ];


export default function ClientBalanceSummary(props) {
    const navigate = useNavigate();

  return (
    <AdminLayout title={"Client Re-Engagement"}>
        <Grid container alignItems="top" paddingTop={0} marginTop={0} spacing={3}>
            <Grid item xs={12} lg={12} md={12} mb={2}>
                <MDTypography variant="h2" fontWeight="medium" mb={1} ml={3} style={{ float: 'left' }}>
                    Client Re-Engagement
                </MDTypography>
            </Grid>
        </Grid>
        <Grid container spacing={3}>
            <Grid item xs={12} lg={12} md={12} ml={2}>
                <MDTypography variant="h3" fontWeight="medium" mb={1} style={{ float: 'left' }}>
                    List
                </MDTypography>
                <Tables body={rows} header={header}/>
            </Grid>
        </Grid>
    </AdminLayout>
  )
}
