import React, { useEffect } from 'react';
import { MaterialPicker, HuePicker, AlphaPicker, ChromePicker, CirclePicker } from 'react-color';
import { Grid, InputAdornment, FormGroup } from '@mui/material';
import '../styles.css';
import MDInput from 'components/MDInput';
// icons
import DoubleIcons from 'assets/images/icons/arrow-double.png'
import MDTypography from 'components/MDTypography';

function convertoColor(val, hue, type){
    if(type === 'hex'){
        return hexToRgbA(val, hue);
    }else{
        return rgbaToHex(val, hue);
    }
}

function hexToRgbA(hex, hue){
    var c;
    if(/^#([A-Fa-f0-9]{6}){1,2}$/.test(hex)){
        c= hex.substring(1).split('');
        // if(c.length== 3){
        //     c= [c[0], c[0], c[1], c[1], c[2], c[2]];
        // }
        c= '0x'+c.join('');
       
        let color = {
            hex: hex,
            rgb: {r:(c>>16)&255, g:(c>>8)&255, b:c&255,a:parseInt(hue) / 100},
            source: 'hex'
        }
        return color;
    }
    return false;
}

function rgbaToHex(rgba, hue) {
    var matchColors = /rgb\((\d{1,3}), (\d{1,3}), (\d{1,3})\)/;
    var match = matchColors.exec(rgba);
    if (match !== null) {
        var a, isPercent,
        rgb = rgba.replace(/\s/g, '').match(/^rgba?\((\d+),(\d+),(\d+),?([^,\s)]+)?/i),
        alpha = (rgb && rgb[4] || "").trim(),
        hex = rgb ?
        (rgb[1] | 1 << 8).toString(16).slice(1) +
        (rgb[2] | 1 << 8).toString(16).slice(1) +
        (rgb[3] | 1 << 8).toString(16).slice(1) : rgba;
    
        a = parseInt(hue) / 100;
        // multiply before convert to HEX
        a = ((a * 255) | 1 << 8).toString(16).slice(1)
        hex = hex + a;
    
        let color = {
            hex: hex,
            rgb: rgba,
            source: 'rgb'
        }
        return color;
    }else{
        return false;
    }
  }
  

export default function ColorPickerGfg(props){
    const [background, setBackground] = React.useState({
        hex: '#ffffff', 
        rgb: {r:255,g:255,b:255,a:1}
    });
    const [backgrounds, setBackgrounds] = React.useState();
    const [colorType, setColorType] = React.useState('Hex');
    const [colorHue, setColorHue] = React.useState(100);
    const ref = React.useRef(null);

    useEffect(() => {
        if(props.current){
            setBackground({
                hex: props.current.hex, 
                rgb: props.current.rgb
            });
        }else{
            setBackground({
                hex: '#ffffff', 
                rgb: {r:255,g:255,b:255,a:1}
            });
        }
    }, [props])

    const handleChange = (color) => {
        setBackground(color);
        setColorHue((color.rgb.a * 100).toFixed(0));
        props.onChange(color);
    };

    const handleChangeColor = (e) => {
        let type = e.target.name;
        let val = e.target.value;
        let color;
        if (type === 'type'){
            let newColorType = colorType == 'Hex' ? 'RGB' : 'Hex';
            setColorType(newColorType);
            setBackgrounds(background[newColorType.toLowerCase()]);
        }else{
            let convertedColors = convertoColor(val, colorHue, type);
            if(!convertedColors){
                setBackgrounds(val);
            }else{
                setBackground(convertedColors);
            }
        }
    }


    return (<Grid container spacing={3}>
                <Grid item xs={12} lg={6} md={7}>
                    <ChromePicker
                        disableAlpha={true}
                        color={ background }
                        onChange={ handleChange }
                    />
                    <HuePicker style={{ marginTop: 15 }}
                        color={ background }
                        onChange={ handleChange }
                    />
                    <br />
                    <AlphaPicker
                        color={ background.rgb }
                        onChange={ handleChange } 
                    />
                </Grid>
                <Grid item xs={12} lg={6} md={5}>
                    <MDInput 
                        value={colorType}
                        className="color-indicator"
                        InputProps={{
                            placeholder:props.placeholder,
                            endAdornment: (<InputAdornment position="end"><img className="double-icon" value={colorType} name="type" src={DoubleIcons} onClick={handleChangeColor}/></InputAdornment>)
                        }}
                    />
                    {colorType === 'RGB' ? (<div className="rgba-inputs">
                        <MDInput 
                            value={backgrounds.r}
                            name="r"
                            className="color-indicator-rgb"
                            onChange={handleChangeColor}
                        />
                        <MDInput 
                            value={backgrounds.g}
                            name="g"
                            className="color-indicator-rgb"
                            onChange={handleChangeColor}
                        />
                        <MDInput 
                            value={backgrounds.b}
                            name="b"
                            className="color-indicator-rgb"
                            onChange={handleChangeColor}
                        />
                    </div>):(
                        <MDInput 
                            value={backgrounds ? backgrounds : background[colorType.toLowerCase()]}
                            name={colorType.toLowerCase()}
                            className="color-indicator"
                            onChange={handleChangeColor}
                        />
                    )}
                    
                    {/* <input type="hidden" value={background} onChange={handleChange} /> */}
                    <MDInput 
                        value={colorHue}
                        id={colorType.toLowerCase()}
                        name="rgb"
                        className="color-indicator"
                        onChange={ handleChange } 
                        InputProps={{ 
                            endAdornment: (<InputAdornment position="end">%</InputAdornment>)
                        }}
                    />
                    
                    <MDTypography variant="h6" style={{ float: 'left', marginLeft: 30, marginTop: 15 }}>
                    Saved colors:
                    </MDTypography>
                    <MDTypography variant="h6" style={{ float: 'right', marginTop: 15 }}>
                    + Add
                    </MDTypography>
                    <CirclePicker 
                        className="color-selected"
                        color={ background }
                        onChange={ handleChange } 
                    />
                </Grid>
            </Grid>);
}