import { useEffect, useState } from "react";
import moment from 'moment'
import { Grid, TableCell, Table, TableBody, TableRow, TableFooter, Link } from '@mui/material';
import AdminLayout from "components/AdminLayout";
import { useNavigate, useParams } from "react-router-dom";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { MenuButton } from "../components/MenuButton";
//icons
import Edit from 'assets/images/icons/edit.png';
import { ROUTES } from 'constants'
import { useStores } from "models";
import { showMessage, downloadFile } from "services/helpers";
import '../styles.css';

export default function RequestOpen() {
  const navigate = useNavigate();
  const rootStore = useStores();
  const { id } = useParams();

  const { loginStore } = rootStore;

  const [value, setValue] = useState(1);
  const [ClientId, setClientId] = useState(null);
  const [Job, setJob] = useState(null);
  const [JobTasks, setJobTasks] = useState([]);
  const [JobExpenses, setJobExpenses] = useState([]);
  const [ShowClientView, setShowClientView] = useState(false);

  const handleChange = (val) => {
    setValue(val);
  };

  const getJobDetail = (id) => {
    loginStore.environment.api.getJobDetail(id).then((result) => {
      if (result.kind === "ok") {
        setJob(result.data)
        if (result?.data?.quote_for?.id) setClientId(result?.data?.quote_for?.id)

        if (Array.isArray(result?.data?.job_tasks)) setJobTasks(result?.data?.job_tasks)

        if (Array.isArray(result?.data?.job_expenses)) setJobExpenses(result?.data?.job_expenses)

      } else showMessage()
    })
  }

  useEffect(() => {
    if (id) getJobDetail(id)
  }, []);


  return (
    <AdminLayout title={"Job Details"}>

      <Grid container alignItems="top" paddingTop={0} marginTop={0}>
        <Grid item xs={12} lg={12} md={12} className="leftContainer">
          <MDTypography variant="h2" fontWeight="medium" mb={1} ml={3} style={{ float: 'left' }}>
            Job Details
          </MDTypography>

          <MDButton
            color="green"
            useBrandingColor={'primary'}
            iconOnly style={{ float: 'right', marginRight: 20, marginBottom: 10 }}
            onClick={() => navigate(ROUTES.EDIT_JOB(id))}
          >
            <img width={'80%'} src={Edit} alt='edit' />
          </MDButton>
        </Grid>
      </Grid>

      <Grid container spacing={3} pb={3} className="task_container">
        <Grid item xs={3} lg={3} md={3}>
          <MDTypography variant="h6" fontWeight="bold">Job information</MDTypography>
          <MDTypography variant="h6">{Job?.title}</MDTypography>
          <MDTypography variant="h6">{Job?.instructions || '-'}</MDTypography>
        </Grid>
        <Grid item xs={3} lg={3} md={3}>
          <MDTypography variant="h6" fontWeight="bold">Client information</MDTypography>
          <MDTypography variant="h6">{Job?.client?.full_name || '-'}</MDTypography>
          <MDTypography variant="h6">{Job?.client?.address || '-'}</MDTypography>
        </Grid>
        <Grid item xs={3} lg={3} md={3}>
          <MDTypography variant="h6" fontWeight="bold">Date of Request</MDTypography>
          <MDTypography variant="h6">{Job?.start_date ? moment(Job.start_date).format('ll') : '-'}</MDTypography>
          <br />
        </Grid>
        <Grid item xs={3} lg={3} md={3}>
          <MDTypography variant="h6" fontWeight="bold">Date of Assesment</MDTypography>
          <MDTypography variant="h6">{Job?.end_date ? moment(Job.end_date).format('ll') : '-'}</MDTypography>
          <br />
        </Grid>
      </Grid>

      <Link component="button" onClick={() => handleChange(1)} sx={{ marginLeft: 5 }}>
        <MDTypography className={["tab", value === 1 ? "active" : ""].join(" ")}>Timesheet</MDTypography>
      </Link>
      <Link component="button" onClick={() => handleChange(2)}>
        <MDTypography className={["tab", value === 2 ? "active" : ""].join(" ")}>Expenses</MDTypography>
      </Link>

      <Grid container className="task_container" mt={2}>
        <Grid item xs={12} lg={12} md={12}>
          <Table>

            {value === 1
              ? <TableBody>
                <TableRow className="t_header_tr">
                  <TableCell className="t_header">Title</TableCell>
                  <TableCell className="t_header">Date</TableCell>
                  <TableCell className="t_header">Description</TableCell>
                  <TableCell className="t_header">Task For</TableCell>
                </TableRow>
                {JobTasks && JobTasks.map((row, i) => (
                  <TableRow key={i} className="t_body_tr">
                    <TableCell className="names">{row?.title}</TableCell>
                    <TableCell>{row?.start_date ? moment(row.start_date).format('ll') : '-'}</TableCell>
                    <TableCell>{row.instructions || '-'}</TableCell>
                    <TableCell>{row?.assign_to?.full_name}</TableCell>
                  </TableRow>
                ))}
              </TableBody>

              : <TableBody>
                <TableRow className="t_header_tr">
                  <TableCell className="t_header">Title</TableCell>
                  <TableCell className="t_header">Description</TableCell>
                  <TableCell className="t_header">Date</TableCell>
                  <TableCell className="t_header">Reimburse For</TableCell>
                  <TableCell className="t_header">File</TableCell>
                </TableRow>
                {JobExpenses && JobExpenses.map((row, i) => (
                  <TableRow key={i} className="t_body_tr">
                    <TableCell className="names">{row?.item_name}</TableCell>
                    <TableCell>{row?.item_description}</TableCell>
                    <TableCell>{row?.date ? moment(row.date).format('ll') : '-'}</TableCell>
                    <TableCell>{row?.reimburse_for?.full_name}</TableCell>
                    <TableCell>{row.attachment
                      ? <MDTypography variant="h6" sx={{ cursor: 'pointer' }} fontWeight="medium" color='success' onClick={() => downloadFile(row.attachment)}>
                        DOWNLOAD
                      </MDTypography>
                      : ''}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            }

          </Table>
        </Grid>
      </Grid>

    </AdminLayout>
  )
}
