import { useEffect, useState } from "react";
import moment from 'moment'
import { Grid, InputAdornment, IconButton } from '@mui/material';
import AdminLayout from "../../../components/AdminLayout";
import { useNavigate } from "react-router-dom";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import MDInput from "../../../components/MDInput";
import MDButton from "../../../components/MDButton";
import MDDatePicker from "../../../components/MDDatePicker";
import {Header} from "../components/Header";
import {Tables} from "../components/Tables";
import {DateFilter} from "../components/DateFilter";
import "../styles.css"
// Accu
const header = [
    {title: 'Tax name', align: '', id: 1},
    {title: 'Taxable', align: '', id: 2},
    {title: 'Tax', align: '', id: 3},
    {title: 'Bad debt taxable', align: '', view: 'accural', id: 4},
    {title: 'Bad debt tax', align: 'center', view: 'accural', id: 5}
];
const rows = [
    {number: 'Lorem ipsum', name: '$0000', created: '$0000', due: '$0000', total: '$0000', id: 1},
    {number: 'Lorem ipsum', name: '$0000', created: '$0000', due: '$0000', total: '$0000', id: 2},
    {number: 'Lorem ipsum', name: '$0000', created: '$0000', due: '$0000', total: '$0000', id: 3},
    {number: 'Lorem ipsum', name: '$0000', created: '$0000', due: '$0000', total: '$0000', id: 4},
    {number: 'Lorem ipsum', name: '$0000', created: '$0000', due: '$0000', total: '$0000', id: 5},
    {number: 'Lorem ipsum', name: '$0000', created: '$0000', due: '$0000', total: '$0000', id: 6},
    {number: 'Lorem ipsum', name: '$0000', created: '$0000', due: '$0000', total: '$0000', id: 7}
  ];
const totals = [
    {title: 'Total:', total: '$0000', class:'green', col:2, id: 1},
    {title: 'Bad debt:', total: '$0000', class:'error', id: 2},
    {title: '', total: '', class:'', id: 3},
];
// Cash
const header2 = [
    {title: 'Tax name', align: '', id: 1},
    {title: 'Taxable', align: '', id: 2},
    {title: 'Tax', align: '', id: 3}
];
const rows2 = [
    {number: 'Lorem ipsum', name: '$0000', created: '$0000', id: 1},
    {number: 'Lorem ipsum', name: '$0000', created: '$0000', id: 2},
    {number: 'Lorem ipsum', name: '$0000', created: '$0000', id: 3},
    {number: 'Lorem ipsum', name: '$0000', created: '$0000', id: 4},
    {number: 'Lorem ipsum', name: '$0000', created: '$0000', id: 5},
    {number: 'Lorem ipsum', name: '$0000', created: '$0000', id: 6},
    {number: 'Lorem ipsum', name: '$0000', created: '$0000', id: 7}
  ];
const totals2 = [
    {title: 'Total:', total: '$0000', class:'green', col:1, id: 1},
    {title: '', total: '', class:'', id: 2},
];


export default function Taxation(props) {
    const[view, setView] = useState('accural');
    const handlechange = (data) => {
        setView(data);
    };

  return (

    <AdminLayout title={"Taxation Report"}>
        <Grid container alignItems="top" paddingTop={0} marginTop={0}>
            <Grid item xs={12} lg={12} md={12} mb={3}>
                <MDTypography variant="h3" fontWeight="medium" mb={1} ml={3} style={{ float: 'left' }}>
                    Taxation Report
                </MDTypography>
            </Grid>
            <Grid item xs={12} lg={5} md={5} mb={3} ml={2}>
                <MDTypography variant="h6" fontWeight="medium">
                    Accural Report:
                </MDTypography>
                <MDTypography>
                    Includes expected revenue for all invoice that have been marked as sent
                </MDTypography>
            </Grid>
            <Grid item xs={12} lg={5} md={5} mb={3} ml={2}>
                <MDTypography variant="h6" fontWeight="medium">
                    Cash Report:
                </MDTypography>
                <MDTypography>
                    Includes only payments and deposits that have been collected
                </MDTypography>
            </Grid>
           
        </Grid>
    <Grid container spacing={3}>
        <Grid item xs={12} lg={12} md={12} ml={2}>
            <MDTypography variant="h3" fontWeight="medium" mb={1} style={{ float: 'left' }}>
                Accual Report
            </MDTypography>
            <DateFilter style={{ float: 'right', display: 'inline', marginBottom: 10 }} extra={false}/>
            <MDButton 
                color={view === 'cash' ? "dark" : "green"}
                style={{ float: 'right', marginRight: 20 }}
                onClick={() => handlechange('cash')} 
            >
                Cash
            </MDButton>
            <MDButton 
                color={view === 'accural' ? "dark" : "green"}
                style={{ float: 'right', marginRight: 20 }}
                onClick={() => handlechange('accural')} 
            >
                Accural
            </MDButton>
            <Tables body={view === 'cash' ? rows2 : rows} header={view === 'cash' ? header2 : header} total={view === 'cash' ? totals2 : totals}/>
        </Grid>
    </Grid>
    </AdminLayout>
  )
}
