import * as Yup from "yup";

export const quoteValidationSchema =
  Yup.object().shape({
    title: Yup.string().required(),
    quote_for: Yup.object().required('A client is required').typeError('A client is required'),
    quote_products: Yup.array().of(Yup.object().shape(
      {
        product: Yup.object(),
        quantity: Yup.string(),
        total: Yup.string(),
      }
    ).required('asdasdasdasd')),
    quote_custom: Yup.array().of(Yup.object().shape(
      {
        custom: Yup.string(),
        quantity: Yup.string(),
        total: Yup.string(),
      }
    )),
    note_for_client: Yup.string(),
    discount: Yup.string().required(),
  })

export const initialQuoteProduct = [
  {
    product: {},
    quantity: 0,
    total: 0,
    price: 0,
    subtotal: true,
    recommended: false
  }
]

export const initialQuoteMatrix = [
  {
    custom: '',
    quantity: 0,
    total: 0,
    price: 0,
    subtotal: true,
    recommended: false
  }
]

export const calculateMatrixPrice = (matrix) => {
  let aux = 0;
  if (matrix?.matrix_bundles && Array.isArray(matrix?.matrix_bundles)) {
    matrix.matrix_bundles.map(bundle => {
      if (bundle.bundle_items && Array.isArray(bundle.bundle_items)) {
        bundle.bundle_items.map(item => {
          let amount = item.amount || 1
          let each = item.each || 1
          let default_price = item?.product?.default_price || 0
          aux += ((1 / each * amount) * default_price)
        })
      }
    })
  }
  return parseInt(aux)
}

const calculateProductUsage = (matrix, quantity) => {
  let amountPerItem = {}

  if (Array.isArray(matrix?.matrix_bundles)) {
    matrix.matrix_bundles.map(mb => {
      let amount = mb.amount || 1
      let each = mb.each || 1

      if (amountPerItem[mb?.product?.id]) amountPerItem[mb?.product?.id] += (quantity / each * amount)
      else amountPerItem[mb?.product?.id] = (quantity / each * amount)
    })
  }


  return amountPerItem
}

export const quoteInitialValues = (data = null) => {

  let quote_products = []
  let quote_custom = []

  if (data && Array.isArray(data?.quote_details)) {
    data.quote_details.map(det => {
      let temp = { ...det }
      if (temp?.product?.id) {
        temp.product.type = 'product'
        temp.price = temp?.product?.default_price || 1
        temp.total = parseInt(temp?.product?.default_price || 1) * parseInt(temp.quantity)
        quote_products.push(temp)
      } else if (temp?.matrix?.id) {
        calculateProductUsage(temp.matrix, parseInt(temp.quantity))
        temp.product = temp.matrix
        temp.product.type = 'matrix'
        temp.price = calculateMatrixPrice(temp?.product) || 1
        temp.total = calculateMatrixPrice(temp?.product) * parseInt(temp.quantity)
        quote_products.push(temp)
      } else if (temp?.custom && temp.custom !== null) quote_custom.push(temp)
    })
  }

  let arr = {
    id: data ? data.id : null,
    title: data ? data.title : "",
    quote_for: data ? data.quote_for : null,
    tax: data ? data.tax : null,
    quote_products: quote_products.length === 0 ? [...initialQuoteProduct] : quote_products,
    quote_custom: quote_custom.length === 0 ? [...initialQuoteMatrix] : quote_custom,
    note_for_client: data ? data.note_for_client : "",
    // subtotal: data ? data.subtotal : null,
    internal_note: data ? data.internal_note : '',
    total: data ? data.total : null,
    discount: data ? data.discount : 0,
    required_deposit: data ? data.required_deposit : 0,
    required_deposit_percentage: data ? data.required_deposit_percentage : false,
  }
  return arr
}