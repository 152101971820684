import React, { useEffect, useState } from "react";
import 'react-calendar-timeline/lib/Timeline.css'
import moment from 'moment';
import { Form, Formik } from "formik";
import { Grid } from '@mui/material';
import AdminLayout from "../../../components/AdminLayout";
import { useNavigate, useParams } from "react-router-dom";
import styles from "../Scheduler.module.css";
import MDTypography from "../../../components/MDTypography";
import MDInput from "../../../components/MDInput";
import MDButton from "../../../components/MDButton";
import FormikInput from "components/FormikInput";
import MDCheckbox from "../../../components/MDCheckbox";
//icons
import AttachFileIcon from '@mui/icons-material/AttachFile';

//custom fields
import { CustomFieldModal } from "components/CustomFieldModal"
import { RenderCustomFields } from "components/RenderCustomFields"
import { getRequestInitialValues, requestValidationSchema } from "./form";

// helpers
import { ROUTES } from 'constants'
import { useStores } from "../../../models";
import { generateOptionsFromString, getFieldInitialValue, showMessage } from "services/helpers";

const FORM_ID = 'Request'

export default function Request() {
  const { id } = useParams();
  const navigate = useNavigate();
  const rootStore = useStores();
  const { loginStore } = rootStore;


  const [RequestDetail, setRequestDetail] = useState(null);

  const [RequestForOptions, setRequestForOptions] = React.useState([]);
  const [CustomFieldModalOpen, setCustomFieldModalOpen] = useState(false);
  const [customFields, setCustomFields] = useState([]);
  const [customFieldsInitialValues, setCustomFieldsInitialValues] = useState({});

  const [file, setFile] = useState(null);

  const [AssignToOptions, setAssignToOptions] = React.useState([]);

  const onCloseCustomModal = () => {
    setCustomFieldModalOpen(false)
    getCustomFields()
  }

  const handleAddCustomField = () => setCustomFieldModalOpen(true);

  const handleUploadFile = (e) => {
    const [file] = e.target.files;
    if (file) setFile(file)
  }

  const createRequest = (values) => {
    let data = {
      ...values,
      assign_to: values?.assign_to?.id,
      request_for: values?.request_for?.id,
      user: loginStore.id,
    }

    if (!data.sight_assessment_required) {
      delete data['end_date']
      delete data['start_date']
    }
    if (file) data.attachment = file
    loginStore.environment.api.createRequest(data).then((result) => {
      if (result.kind === "ok") {
        showMessage('The request has been created', 'success')
        navigate(ROUTES.REQUESTS)
      } else {
        if (result.kind === "bad-data") showMessage(result?.errors, 'error', true)
        else showMessage('An error occurred, please try again later')
      }
    })
  }
  const editRequest = (values) => {
    let data = {
      ...values,
      assign_to: values?.assign_to?.id,
      request_for: values?.request_for?.id,
      id: RequestDetail.id,
    }
    if (!data.sight_assessment_required) {
      delete data['end_date']
      delete data['start_date']
    }
    if (file) data.attachment = file
    loginStore.environment.api.updateRequest(data).then((result) => {
      if (result.kind === "ok") {
        showMessage('The request has been updated', 'success');
        navigate(ROUTES.REQUESTS)
      } else {
        if (result.kind === "bad-data") {
          showMessage(result?.errors, 'error', true)
        }
      }
    }).catch(error => showMessage(error.message))
  }
  const getRequestFile = (id) => {
    loginStore.environment.api.getRequestFile(id).then((result) => {
      if (result.kind === "ok") {
        result.download_file()
      } else showMessage('An error occurred, please try again later')
    }).catch(err => showMessage())
  }
  const getClients = () => {
    loginStore.environment.api.getClients('')
      .then((result) => {
        const { data } = result
        const { results } = data
        setRequestForOptions(results)
      })
      .catch(() => showMessage())
  }
  const getEmployes = () => {
    loginStore.environment.api.getTeamMembers('')
      .then((result) => {
        const { data } = result
        const { results } = data
        setAssignToOptions(results)
      })
      .catch(() => showMessage())
  }
  const getRequestDetail = (id) => {
    loginStore.environment.api.getRequestDetail(id).then((result) => {
      if (result.kind === "ok") {
        const data = {
          ...result.data,
          start_date: moment(result.data.start_date).format(),
          end_date: moment(result.data.end_date).format(),
        }
        setRequestDetail(data);
      } else {
        showMessage()
      }
    })
  }
  const getCustomFields = () => {
    loginStore.environment.api.getCustomFields({ type: 'REQUEST', object_id: id }).then((result) => {
      if (result.kind === "ok") {
        const { data } = result
        const { results } = data
        if (results.length > 0) {
          const fields = results[0]['fields']
          setCustomFields(fields)
          setCustomFieldsInitialValues(getFieldInitialValue(fields))
        }
      }
    })
  }

  const mainInformation = (setFieldValue, errors, values) => <>
    <FormikInput
      useBrandingColor={'secondary'}
      name={`request_for`}
      label={'Request for'}
      labelFieldName={'full_name'}
      type={'select'}
      placeholder={id ? values?.request_for?.full_name : 'select a client'}
      setFieldValue={setFieldValue}
      options={RequestForOptions}
      errors={errors}
      item xs={12} lg={6} md={6}
    />

    <Grid item xs={12} lg={6} md={6}>
      <MDTypography variant="h6" fontWeight="bold">
        Title
      </MDTypography>
      <FormikInput
        useBrandingColor={'secondary'}
        fullWidth
        placeholder='input title'
        name='title'
        errors={errors}
      />
    </Grid>

    <FormikInput
      useBrandingColor={'secondary'}
      name={'assign_to'}
      label={'Assign to'}
      labelFieldName={'full_name'}
      type={'select'}
      placeholder='a team member will be assign to your request'
      setFieldValue={setFieldValue}
      options={AssignToOptions}
      errors={errors}
      item xs={12} lg={6} md={6}
    />


    <Grid item xs={12} lg={6} md={6}>
      <MDTypography variant="h6" fontWeight="bold">
        Service Details
      </MDTypography>
      <FormikInput
        useBrandingColor={'secondary'}
        fullWidth
        placeholder='input service details'
        type='textarea'
        name='service_details'
        errors={errors}
      />
    </Grid>
  </>

  const dateRangeInformation = (setFieldValue, errors, values) => <>
    <Grid item xs={12} lg={6} display={'flex'} alignItems={'center'}>
      <MDTypography sx={{ mr: 5 }} variant="h6" fontWeight="bold">
        On sight assesment required
      </MDTypography>

      <FormikInput
        useBrandingColor={'secondary'}
        sx={{ mt: 3 }}
        name={'sight_assessment_required'}
        type={'boolean'}
        setFieldValue={setFieldValue}
        errors={errors}
      />
    </Grid>
    {values.sight_assessment_required &&
      <>
        <Grid item xs={12} lg={3}>
          <MDTypography variant="h6" fontWeight="bold">
            Start Date
          </MDTypography>
          <FormikInput
            useBrandingColor={'secondary'}
            fullWidth
            input={{ placeholder: "Select date" }}
            type='datetime-local'
            name='start_date'
            errors={errors}
          />
        </Grid>
        <Grid item xs={12} lg={3} >
          <MDTypography variant="h6" fontWeight="bold">
            End Date
          </MDTypography>
          <FormikInput
            useBrandingColor={'secondary'}
            fullWidth
            input={{ placeholder: "Select date" }}
            type='datetime-local'
            name='end_date'
            errors={errors}
          />
        </Grid>
      </>
    }
  </>

  const renderCustomField = (index, field, errors, setFieldValue) => {
    const optionsForField = generateOptionsFromString(field['field_options'])
    const value = field['default_value'].value
    return (
      <React.Fragment key={index}>
        <FormikInput
          useBrandingColor={'secondary'}
          name={field['field_name']}
          label={field['field_label']}
          type={field['field_type_input']}
          setFieldValue={setFieldValue}
          options={optionsForField}
          labelFieldName={'label'}
          errors={errors}
          initialValue={value}
          item
          xs={12}
          md={6}
        />
      </React.Fragment>
    )
  }

  const internalNoteInformation = (setFieldValue, errors, values) => <>
    <FormikInput
      useBrandingColor={'secondary'}
      name={'internal_note'}
      label={'Internal Notes & Attachments'}
      placeholder={'input internal note'}
      errors={errors}
      type={'textarea'}
      rows={8}
      helperInput={
        <Grid item xs={9} lg={6} md={6} >
        {file?.name
          ? <MDTypography mx={3} variant={'subtitle2'}>{file?.name}</MDTypography>
          : RequestDetail?.attachment
            ? <MDTypography
              variant="h6"
              fontWeight={"medium"}
              color='primary'
              sx={{ cursor: 'pointer' }}
              onClick={() => getRequestFile(RequestDetail?.id)}
            >Download Attachment</MDTypography>
            : <></>
        }
      </Grid>

        // <Grid item xs={9} lg={6} md={6} >
        //   <MDTypography variant={'subtitle2'}>{file?.name}</MDTypography>
        // </Grid>
      }
      item xs={9} lg={5} md={5}
    />
    <FormikInput
      inputFileStyles={{ borderRadius: '8px', width: '38px', height: '38px' }}
      errors={errors}
      useBrandingColor={'primary'}
      name={'total'}
      type={'file'}
      handleChange={(e) => handleUploadFile(e)}
      item xs={3} lg={1} md={1}

      mt={4}
    />

    <Grid item xs={12} lg={6} display={'flex'} alignItems={'center'}>
      <MDTypography sx={{ mr: 5 }} variant="h6" fontWeight="bold">
        Disable Client Notifications
      </MDTypography>
      <FormikInput
        useBrandingColor={'secondary'}
        sx={{ mt: 3 }}
        name={'disable_client_notifications'}
        type={'boolean'}
        setFieldValue={setFieldValue}
        errors={errors}
      />
    </Grid>


  </>

  useEffect(() => {
    getClients();
    getEmployes();
    getCustomFields();
    if (id) getRequestDetail(id);
  }, []);

  return (
    <AdminLayout title={"New Event"}>
      <Grid container alignItems="top" paddingTop={0} marginTop={0}>
        <Grid ml={5} item xs={12} lg={9} md={9} className={styles.leftContainer}>
          <MDTypography useBrandingColor={'tertiary'} variant="h2" fontWeight="medium" mb={1}>
            {id ? 'Edit Request' : 'Create New Request'}
          </MDTypography>
        </Grid>
      </Grid>
      <Formik
        initialValues={Object.assign({}, getRequestInitialValues(RequestDetail), customFieldsInitialValues)}
        validationSchema={requestValidationSchema}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={values => !id ? createRequest(values) : editRequest(values)}
        enableReinitialize
      >
        {({ errors, setFieldValue, values }) => {
          console.log(' ========>>>>>>>>>>>> ', values)
          return (
            <Form>
              <Grid container spacing={{ xs: 2, md: 3, lg: 3 }} className="task_container" alignItems={'flex-start'}>
                {mainInformation(setFieldValue, errors, values)}

                {customFields.map((field, index) => renderCustomField(index, field, errors, setFieldValue))}
                <CustomFieldModal modalOpen={CustomFieldModalOpen} handleClose={onCloseCustomModal} />
                <Grid item xs={12} lg={12} md={12}>
                  <MDButton type={'button'} onClick={handleAddCustomField} useBrandingColor={'primary'} color="green" styel={{ float: 'right' }}>
                    Add Custom Field
                  </MDButton>
                </Grid>

                <RenderCustomFields form_id={FORM_ID} />

                <Grid item xs={12} lg={12} md={12}>
                  <hr className="MuiDivider-root MuiDivider-fullWidth" style={{ background: '#C6C9CE', margin: '20px 0' }} />
                </Grid>

                {dateRangeInformation(setFieldValue, errors, values)}

                <Grid item xs={12} lg={12} md={12}>
                  <hr className="MuiDivider-root MuiDivider-fullWidth" style={{ background: '#C6C9CE', margin: '20px 0' }} />
                </Grid>

                {internalNoteInformation(setFieldValue, errors, values)}
                <Grid item display={{ xs: 'block', lg: 'flex' }} xs={12} lg={12} md={12} mb={3} justifyContent={'space-evenly'}>
                  <Grid item xs={12} lg={3} md={5} mb={{ xs: 2 }}>
                    <MDButton
                      useBrandingColor={'primary'}
                      color="green"
                      onClick={() => navigate(ROUTES.REQUESTS)}
                      fullWidth
                      variant="outlined"
                    >
                      Cancel
                    </MDButton>
                  </Grid>
                  <Grid item xs={12} lg={3} md={5} mb={{ xs: 2 }}>
                    <MDButton
                      useBrandingColor={'primary'}
                      color="green"
                      type="submit"
                      fullWidth
                    >
                      {`${id ? 'EDIT' : 'CREATE'} Request`}
                    </MDButton>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          )
        }}
      </Formik>
    </AdminLayout>
  )
}
