/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "App";

// Material Dashboard 2 PRO React Context Provider
import { MaterialUIControllerProvider } from "context";
import StoreApp from "./StoreApp";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import "./colors.css"
import {TwilioContextProvider} from "./services/phone_system";


if (process.env.NODE_ENV !== 'development'){
  Sentry.init({
    dsn: "https://5e1b95dc943c4f669ea22bd07a377e66@sentry.innovatica.com.py//24",
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <MaterialUIControllerProvider>
    <StoreApp>
        <TwilioContextProvider>
          <App />
        </TwilioContextProvider>
    </StoreApp>
    </MaterialUIControllerProvider>
  </BrowserRouter>
);
