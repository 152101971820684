import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {styled} from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TuneIcon from "@mui/icons-material/Tune";
import { Typography } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import "./tables.css";
import {GreenOutlinedButton} from "../../quotes/components/FilterButton";

export const FilterButton = ()=> {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open2, setOpen2] = React.useState({
    date: false,
    tipe: false
  });
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  const handleClick2 = (data) => {
    setOpen2(false);
    setOpen2({
      [data]: !open2[data]
    });
  };


  return (
    <div>
      <GreenOutlinedButton variant="outlined"
                           useBrandingColor={'primary'}
                           endIcon={<ExpandMoreIcon />}
                           startIcon={<TuneIcon />}
                           onClick={handleClick}
                           className={'btn-filter'}
      > Filter </GreenOutlinedButton>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <Typography className="menus-title" onClick={() => handleClick2('date')}>
          By Date {open2.date ? <ExpandLess /> : <ExpandMore />}
        </Typography>
        <Collapse in={open2.date} timeout="auto" unmountOnExit>
          <MenuItem onClick={handleClose}>All</MenuItem>
          <MenuItem onClick={handleClose}>Last Month</MenuItem>
          <MenuItem onClick={handleClose}>This Year</MenuItem>
        </Collapse>
        <Typography className="menus-title" onClick={() => handleClick2('tipe')}>
          By Type {open2.tipe ? <ExpandLess /> : <ExpandMore />}
        </Typography>
        <Collapse in={open2.tipe} timeout="auto" unmountOnExit>
          <MenuItem onClick={handleClose}>Past Due</MenuItem>
          <MenuItem onClick={handleClose}>Send but not due</MenuItem>
          <MenuItem onClick={handleClose}>Draft</MenuItem>
        </Collapse>
      </Menu>
    </div>
  );
}
