import {useEffect, useState} from "react";
import {Grid} from '@mui/material';
import AdminLayout from "../../components/AdminLayout";
import {useNavigate} from "react-router-dom";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import SimpleTable from "components/SimpleTable";
import {FilterButton} from "./components/FilterButton";
import {AddButton} from "./components/AddButton";
import {Header} from "./components/Header";
import {Tables} from "./components/Tables";
import "./styles.css"
// Utils
import {useStores} from "../../models";
import {showMessage} from "services/helpers";
//icons
import {Link} from 'react-router-dom';
import { ROUTES } from "constants";

const datatableModel = {
  columns: [
      { Header: "Invoice For", accessor: "client_name"},
      { Header: "Title", accessor: "title"},
      { Header: "Status", accessor: "status"},
      { Header: "Subtotal", accessor: "subtotal"},
      { Header: "Discount", accessor: "discount"},
      { Header: "Total", accessor: "total"},
  ],
  rows: []
}

export default function Invoice() {
  const navigate = useNavigate();
  const rootStore = useStores()
  const {loginStore} = rootStore
  const [loading, setLoading] = useState(false);
  const [Data, setData] = useState([]);
  const [createInv, setCreateInv] = useState(false);
  const [deliverInv, setDeliverInv] = useState(false);
  const [InvoiceStatus, setInvoiceStatus] = useState({
    TOTAL: 0,
    PAST_DUE: 0,
    SENT_NOT_DUE: 0,
    DRAFT: 0,
});

  const handleCreateInv = (type, status) => {
    if (type === 'create') setCreateInv(status);
    if (type === 'deliver') setDeliverInv(status);
  }

  const getInvoices = (searchData) => {
    setLoading(true)

    let filter = {}
    if (window.location.pathname.includes('past_due')) filter.past_due = true
    if (window.location.pathname.includes('awaiting_payment')) filter.awaiting_payment = true
    if (window.location.pathname.includes('draft')) filter.draft = true

    loginStore.environment.api.getInvoices(searchData, filter).then((result) => {
      if (result.kind === "ok") {
        const responseData = result?.data?.results;
        let data = { ...datatableModel };
        data.rows = responseData.length > 0
          ? responseData.map((r, i) => {
            return {
              ...r,
              'client_name': <Link
                to={ROUTES.CLIENTS_PROFILE(r?.invoice_for?.id)}
                className='base-item link-item'
              >
                {r?.invoice_for?.company_name_primary ? r?.invoice_for?.company_name : r?.invoice_for?.full_name}
              </Link>,
              title: <Link
              to={ROUTES.INVOICE_EDIT(r?.id)}
              className='base-item link-item'
            >
              {r.title}
            </Link>,
            }
          }) : [];

        setData(data);
      }
    }).catch(err => showMessage())
    .finally(() => setLoading(false))
  }

  const getInvoiceStatus = () => {
    loginStore.environment.api.getInvoiceStatus().then((result) => {
        if (result.kind === "ok") {
            setInvoiceStatus(result.data)
        }
    })
}

  useEffect(() => {
    getInvoices('');
    getInvoiceStatus();
  }, [])

  return (
    <AdminLayout
    title={"Invoices"}
    searchFunc={getInvoices}
      loading={loading}
      >
      <Grid container alignItems="top" marginTop={0}>
        <Grid item xs={12} lg={12} md={12} mb={1}>
          <Header status={InvoiceStatus} onClick={handleCreateInv.bind(this)}/>
        </Grid>
        <Grid item xs={10} md={6} lg={6} mb={3}>
          <MDTypography useBrandingColor={'tertiary'} variant="h2" fontWeight="medium" mb={1} ml={{xs: 0, lg: 3}}>
            {createInv ? 'Batch Create Invoices' : (deliverInv ? 'Batch Delivery Invoices' : 'All Invoices')}
          </MDTypography>
        </Grid>
        <Grid item xs={6} md={3} lg={4}>
          <AddButton />
        </Grid>
        <Grid item xs={6} md={3} lg={2}>
          <FilterButton />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={12} md={12} pt={{xs: '48px !important', lg: '24px !important'}} ml={{xs: 0, lg: 2}}>
          <SimpleTable data={Data}/>
        </Grid>
      </Grid>
    </AdminLayout>
  )
}
