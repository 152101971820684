import MDBox from "components/MDBox";
import {CircularProgress, Input} from "@mui/material";
import {Search} from "@mui/icons-material";
import TextField from "@mui/material/TextField";
import {styled} from "@mui/material/styles";
import pxToRem from "../../assets/theme/functions/pxToRem";

const CssTextField = styled(TextField)({
    '.MuiInputBase-root': {
        backgroundColor: 'white',
        borderRadius: pxToRem(4),
        width:"345px",
        minWidth: '100%',
        marginLeft:"10px"
    },
    '& label.Mui-focused': {
        color: '#dbdadb',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#dbdadb',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#dbdadb',
        },
        '&:hover fieldset': {
            borderColor: '#dbdadb',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#dbdadb',
        },
    },
});

export const SearchBar = ({search, loading, setSearchQuery}) => {

    const searchFunc = (text = '') => {
        // if (text.length === 0 || text.length >= 3) {
        //   search(text)
        // }
        setSearchQuery(text)
        search?.(text)
    }

    return (
        <MDBox mr={3} >
            <CssTextField
                fullWidth
                placeholder="Search"
                type="text"
                onChange={(evt) => searchFunc(evt?.target?.value)}
                size="small"
                InputProps={{
                    endAdornment: loading === false ? <Search/> : <CircularProgress size={14} color="primary"/>,
                }}
            />
        </MDBox>
    )

}

