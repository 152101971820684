import MDBox from "../MDBox";
import Autocomplete from "@mui/material/Autocomplete";
import React from "react";
import MDInputAutocomplete from "../MDInputAutocomplete";

export const AutocompleteFormik = (opts) => {
  const {
    placeholder,
    onChange,
    options,
    renderInput,
    labelFieldName,
    setFieldValue,
    initialValue,
    isOptionEqualToValue,
    useBrandingColor,
    touched,
    field,
    label,
    errors,
    ...params
  } = opts

  return (
    <MDBox>
      <Autocomplete
        {...params}
        id={field.name}
        name={field.name}
        options={options}
        onChange={(e, value) => {
          setFieldValue(field.name, value !== null ? value : initialValue)
          if (onChange) {
            onChange(e, value)
          }
        }}
        defaultValue={initialValue}
        getOptionLabel={option => option[labelFieldName] ?? ""}
        isOptionEqualToValue={(option, value) => (option?.id ? option?.id === value?.id : option?.value === value?.value)}
        renderInput={(params) => (
          <MDInputAutocomplete
            placeholder={placeholder}
            type="text"
            useBrandingColor={useBrandingColor}
            label={label}
            name={field.name}
            fullWidth
            onChange={(e) => {
              setFieldValue(field.name, e.target.value)
            }}
            error={errors[field.name] !== undefined}
            helperText={errors[field.name] && errors[field.name]}
            {...field}
            {...params}
          />
        )}
      />
    </MDBox>
  )
}

