
import { useEffect, useState } from "react";
import { Grid, InputAdornment, IconButton } from '@mui/material';
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { PaymentElement } from '@stripe/react-stripe-js';

import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

import { useApi, showMessage, cardBrandIcon } from "services/helpers";
import MoreIcon from "assets/images/icons/more.png";


const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC);

const StripeListCards = ({ children }) => {
  const api = useApi()
  const stripe = useStripe();
  const elements = useElements();

  const [UserCards, setUserCards] = useState('')

  useEffect(() => {
    api.stripeGetCards().then(result => {
      if (result.kind === "ok") {
        setUserCards(result.response)
      } else {
        showMessage(null)
      }
    })
  }, [])

  return (
    <>
      {children &&
        <Grid item xs={12} lg={12} md={12} style={{ borderRadius: 5}}>
          {children}
        </Grid>
      }
      <Grid item xs={12} lg={12} md={12} >
        <div style={{ overflowX: 'auto', width: '100%', display: 'flex' }}>
          {Array.isArray(UserCards) && UserCards.map((card, i) =>
            <MDBox
              key={`card_${i}`}
              style={{
                background: '#BFE3CD',
                minWidth: 300,
                height: 120,
                borderRadius: 16,
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
                marginRight: 10,
                marginBottom: 10
              }}
            >
              <MDBox style={{ width: '85%', display: 'grid', paddingLeft: 10, gap: 10 }}>
              <img src={cardBrandIcon(card.brand)} style={{ height: 15, marginTop: 10 }} />
                <MDTypography variant="h6" fontWeight="medium" style={{ float: 'left' }}>
                  {`**** **** **** ${card.last4}`}
                </MDTypography>
              </MDBox>

              <MDBox style={{ width: '15%', height: '100%' }}>
                <img src={MoreIcon} style={{ width: 25, height: 25, marginTop: 15 }} />
              </MDBox>
            </MDBox>
          )}

        </div>
      </Grid>

      </>
  );
}

export default StripeListCards
