import * as React from 'react';
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import AccessTimeIcon from '@mui/icons-material/AccessTime';

export const HourBox = ({title, color})=>{
  return (
    <MDBox bgColor={color} mb={0} borderRadius={8}
           ml={1} pl={2} pr={2} pt={1}
           style={{display:"flex", flexDirection:"row",
             paddingTop:"4px",paddingBottom:"4px",
             alignItems:"center", justifyContent:"center"}}>
      <AccessTimeIcon fontSize={"small"} color={"white"} />
      <MDBox pl={1}>
        <MDTypography variant="h6" fontWeight={"medium"} color={"white"}>{title}h</MDTypography>
      </MDBox>
    </MDBox>
  )

}
