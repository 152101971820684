import React, { useState } from 'react';
import { Link } from "react-router-dom";
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MDButton from "components/MDButton";
import { styled } from "@mui/material/styles";
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import RuleIcon from '@mui/icons-material/Rule';
import { Typography } from '@mui/material';
import MenuIcon from 'assets/images/icons/menu.png';
import GridIcon from 'assets/images/icons/grid.png';
import "./tables.css";


export const SwitchView = (props) => {
  const { current } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [active, setActive] = useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setActive(true);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setActive(false);
  };

  return (
    <div>
      <MDButton
        useBrandingColor={'primary'}
        color="green"
        onClick={handleClick}
        iconOnly
        sx={{ marginLeft: '40px'}}
      ><img src={current === 'list' ? MenuIcon : GridIcon} /></MDButton>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        className="menu-basics"
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleClose}>
          <MDButton
            color="green"
            useBrandingColor={'primary'}
            className={['btn-import-sub', current === 'list' ? 'active' : ''].join(" ")}
            startIcon={<img src={MenuIcon} className="btn-import-sub-icon" />}
            value="list"
            onClick={props.onClick}
          > View as list
          </MDButton>

        </MenuItem>
        <MenuItem onClick={handleClose}>
          <MDButton
            color="green"
            useBrandingColor={'primary'}
            className={['btn-import-sub', current === 'grid' ? 'active' : ''].join(" ")}
            startIcon={<img src={GridIcon} />}
            value="grid"
            onClick={props.onClick}
          > View as tiles
          </MDButton>
        </MenuItem>
      </Menu>
    </div>
  );
}
