/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

// react-router-dom components
import {useLocation, NavLink} from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import SidenavCollapse from "./SidenavCollapse";
import SidenavList from "./SidenavList";
import SidenavItem from "./SidenavItem";

// Custom styles for the Sidenav
import SidenavRoot from "./SidenavRoot";
import sidenavLogoLabel from "./styles/sidenav";
import logo from '../../assets/images/logo.png';
import logo_small from '../../assets/images/logo_small.png';
import pxToRem from "../../assets/theme/functions/pxToRem";
import {ROUTES} from 'constants'

// Material Dashboard 2 PRO React context
import {
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
} from "context";

import {useStores} from "models";
import MDButton from "../MDButton";
import MDDialog from "../MDDialog";

function Sidenav({color, brand, brandName, routes, ...rest}) {
  const {loginStore} = useStores();
  const navigate = useNavigate();
  const [openLogoutModal, setOpenLogoutModal] = useState(false);
  const [openCollapse, setOpenCollapse] = useState(false);
  const [openNestedCollapse, setOpenNestedCollapse] = useState(false);
  const [controller, dispatch] = useMaterialUIController();
  const {miniSidenav, transparentSidenav, whiteSidenav, darkMode} = controller;
  const location = useLocation();
  const {pathname} = location;
  const collapseName = pathname.split("/").slice(1)[0];
  const items = pathname.split("/").slice(1);
  const itemParentName = items[1];
  const itemName = items[items.length - 1];
  let textColor = "black";

  const closeSidenav = () => setMiniSidenav(dispatch, true);

  useEffect(() => {
    setOpenCollapse(collapseName);
    setOpenNestedCollapse(itemParentName);
  }, []);

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
      setTransparentSidenav(dispatch, window.innerWidth < 1200 ? false : transparentSidenav);
      setWhiteSidenav(dispatch, window.innerWidth < 1200 ? false : whiteSidenav);
    }

    /**
     The event listener that's calling the handleMiniSidenav function when resizing the window.
     */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location, brand]);

  // Render all the nested collapse items from the routes.js
  const renderNestedCollapse = (collapse) => {
    const template = collapse.map(({name, route, key, href}) =>
      href ? (
        <Link
          key={key}
          href={href}
          target="_blank"
          rel="noreferrer"
          sx={{textDecoration: "none"}}
        >
          <SidenavItem name={name} nested/>
        </Link>
      ) : (
        <NavLink to={route} key={key} sx={{textDecoration: "none"}}>
          <SidenavItem name={name} active={route === pathname} nested/>
        </NavLink>
      )
    );

    return template;
  };
  // Render the all the collpases from the routes.js
  const renderCollapse = (collapses) =>
    collapses.map(({name, collapse, route, href, key, icon}) => {
      let returnValue;
      if (collapse) {
        returnValue = (
          <SidenavItem
            key={key}
            icon={icon}
            color={color}
            name={name}
            active={key === itemParentName ? "isParent" : false}
            open={openNestedCollapse === key}
            onClick={({currentTarget}) =>
              openNestedCollapse === key && currentTarget.classList.contains("MuiListItem-root")
                ? setOpenNestedCollapse(false)
                : setOpenNestedCollapse(key)
            }
          >
            {renderNestedCollapse(collapse)}
          </SidenavItem>
        );
      } else {
        returnValue = href ? (
          <Link
            href={href}
            key={key}
            target="_blank"
            rel="noreferrer"
            sx={{textDecoration: "none"}}
          >
            <SidenavItem color={color} name={name} active={key === itemName} icon={icon}/>
          </Link>
        ) : (
          <NavLink to={route} key={key} sx={{textDecoration: "none"}}>
            <SidenavItem color={color} name={name} active={key === itemName} icon={icon}/>
          </NavLink>
        );
      }
      return <SidenavList key={key}>{returnValue}</SidenavList>;
    });

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = routes.map(
    ({type, name, icon, title, collapse, noCollapse, key, href, route}) => {
      let returnValue;
      if (type === "collapse") {
        if (href) {
          returnValue = (
            <Link
              href={href}
              key={key}
              target="_blank"
              rel="noreferrer"
              sx={{textDecoration: "none"}}
            >
              <SidenavCollapse
                name={name}
                icon={icon}
                active={
                  key === collapseName ||
                  (Array.isArray(key) && key.some(k => [collapseName, itemParentName, itemName].includes(k)))
                }
                noCollapse={noCollapse}
                collapse={collapse}
              />
            </Link>
          );
        } else if (noCollapse && route) {
          returnValue = (
            <NavLink to={route} key={key}>
              <SidenavCollapse
                name={name}
                icon={icon}
                noCollapse={noCollapse}
                active={
                  key === collapseName ||
                  (Array.isArray(key) && key.some(k => [collapseName, itemParentName, itemName].includes(k)))
                  || key === itemName
                }
                collapse={collapse}
                style={{marginTop: key === 'logout' && pxToRem(60)}}
                onClick={() => key === 'logout' && setOpenLogoutModal(true)}
              >
                {collapse ? renderCollapse(collapse) : null}
              </SidenavCollapse>
            </NavLink>
          );
        } else {
          returnValue = (
            <SidenavCollapse
              key={key}
              name={name}
              icon={icon}
              active={
                (key !== 'logout' &&
                  (key === collapseName ||
                    (Array.isArray(key) && key.some(k => [collapseName, itemParentName, itemName].includes(k)))
                    || openCollapse === key)
                )
              }
              open={openCollapse === key}
              onClick={() => [
                (openCollapse === key ? setOpenCollapse(false) : setOpenCollapse(key)),
                key === 'logout' && setOpenLogoutModal(true)
              ]}
              collapse={collapse}
              style={{marginTop: key === 'logout' && pxToRem(60)}}
            >
              {collapse ? renderCollapse(collapse) : null}
              {Array.isArray(key) && console.log('KEYS', key)}
            </SidenavCollapse>
          );
        }
      } else if (type === "title") {
        returnValue = (
          <MDTypography
            key={key}
            color={textColor}
            display="block"
            variant="caption"
            fontWeight="bold"
            textTransform="uppercase"
            pl={3}
            mt={2}
            mb={1}
            ml={1}
          >
            {title}
          </MDTypography>
        );
      } else if (type === "divider") {
        returnValue = (
          <Divider
            key={key}
            light={
              (!darkMode && !whiteSidenav && !transparentSidenav) ||
              (darkMode && !transparentSidenav && whiteSidenav)
            }
          />
        );
      }

      return returnValue;
    }
  );

  return (
    <SidenavRoot
      {...rest}
      variant="permanent"
      ownerState={{transparentSidenav, whiteSidenav, miniSidenav, darkMode}}
    >
      <MDBox
        pt={3}
        pb={1}
        px={4}
        textAlign="center"
        marginTop={pxToRem(44)}
      >
        <MDBox
          display={{xs: "block", xl: "none"}}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{cursor: "pointer"}}
        >
          <MDTypography variant="h6" color="secondary">
            <Icon sx={{fontWeight: "bold"}}>close</Icon>
          </MDTypography>
        </MDBox>
        <MDBox component={NavLink} to="/" display="flex" alignItems="center">
          <MDBox
            component="img"
            src={brand ? brand : (miniSidenav ? logo_small : logo)}
            alt="Brand"
            width={miniSidenav ? 31 : 180}
          />
          <MDBox
            width={brandName && 110}
            sx={(theme) => sidenavLogoLabel(theme, {miniSidenav})}
          >
            <MDTypography variant="h5" fontWeight="bold" color={textColor}>
              {brandName}
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
      <Divider
        light={
          (!darkMode && !whiteSidenav && !transparentSidenav) ||
          (darkMode && !transparentSidenav && whiteSidenav)
        }
      />
      <List>{renderRoutes}</List>
      <MDDialog
        open={openLogoutModal}
        handleClose={() => setOpenLogoutModal(false)}
        title={'Log Out'}
        titleStyles={{fontSize: pxToRem(24)}}
        style={{
          width: '100%',
          paddingBlock: `${pxToRem(10)} ${pxToRem(30)}`,
          paddingInline: pxToRem(50),
        }}
      >
        <MDTypography
          variant="h6"
          sx={{
            color: 'black',
            fontWeight: 600,
            marginLeft: pxToRem(16),
            textAlign: 'center',
          }}
        >
          Are you sure you want to Log Out?
        </MDTypography>

        <MDBox
          sx={{
            display: 'flex',
            gap: pxToRem(24),
            marginTop: pxToRem(46),
          }}
        >
          <MDButton
            useBrandingColor={'primary'}
            color="green"
            variant="outlined"
            type="submit"
            className={"button-action"}
            onClick={() => setOpenLogoutModal(false)}
          >
            Cancel
          </MDButton>

          <MDButton
            useBrandingColor={'primary'}
            color="green"
            type="submit"
            className={"button-action"}
            onClick={() => [loginStore.reset(), navigate(ROUTES.ROOT)]}
          >
            Yes
          </MDButton>
        </MDBox>
      </MDDialog>
    </SidenavRoot>
  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: "info",
  brand: "",
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  brand: PropTypes.string,
  brandName: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};


export default Sidenav;
