import React, {useEffect, useState} from "react";
import {Card, Grid} from '@mui/material';
import "../styles.css";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import pxToRem from "../../../assets/theme/functions/pxToRem";
import MDButton from "../../../components/MDButton";




export default function DetailCards({  xs, md, lg, children, label, client, button = null }) {
  const [Client, setClient] = useState('Active Work');
  useEffect(() => {
    if (client) setClient(client)
  }, [client])
  return (
    <Grid container item xs={xs} md={md} lg={lg} p={3}>
        <Card sx={{ width: '100%', minWidth: pxToRem(250), height: pxToRem(192), p: 3 }} >
        <MDBox display={'flex'} justifyContent={'space-between'}>
          <MDTypography variant={'h4'}>{label}</MDTypography>
          {button && <MDButton color={"green"} useBrandingColor={'primary'} onClick={button.action}>{button?.label}</MDButton>}
        </MDBox>
        <MDBox borderRadius={'xl'} sx={{ border: '1px solid #DD816B', overflow: 'auto' }} mt={2} p={1} height={pxToRem(90)} >
          {children}
        </MDBox>
      </Card>
    </Grid>
  )
}

