
import { useContext} from "react";

// prop-types is a library for typechecking of props

// @mui material components

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// Utils
import { useIsMobile } from "../../services/hooks";

// Assets
import phoneIcon from 'assets/images/icons/phone.svg';
import endCall from 'assets/images/icons/end_call.svg';
import pxToRem from "../../assets/theme/functions/pxToRem";
import user from 'assets/images/icons/user.svg'
import {TwilioContext} from "../../services/phone_system";

function CallPending({  }) {
  const isMobile = useIsMobile();

  const { currentCall, currentClient, finishCall, currentNumber } = useContext(TwilioContext);
  const status = currentCall?.status?.();
  const outgoing = currentCall?.direction === 'OUTGOING';


  return (
    <>
    <MDBox sx={{ height: '84%'}}>
      <MDTypography sx={{ textAlign: 'center' }} variant="h4">{ outgoing ? 'Calling...': 'Incoming call'}</MDTypography>

        <MDBox sx={{ width: '100%', height: 300, backgroundColor: '#c7c7c7' }}>
          <MDTypography sx={{ textAlign: 'center' }} mt={{ lg: 10, xs: pxToRem(20) }} variant="h6">
            {currentNumber}
            <br />
            {currentClient?.name}
          </MDTypography>
          <MDBox
            component={'img'} alt={'user'}
            src={user}
            sx={{ width: '100%', height: '16rem', objectFit: 'contain' }}
          />
        </MDBox>
      </MDBox>


      <MDBox pb={2}>
        <MDBox
          display="flex"
          alignItems="center"
          justifyContent={{ lg: 'space-around', xs: 'center' }}
          pt={1}
        >
          {!outgoing &&
              <MDBox
                  variant="gradient"
                  color={'white'}
                  coloredShadow={'#FFBAAA'}
                  borderRadius="xl"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="4rem"
                  height="4rem"
                  ml={2}
                  bgColor={'success'}
                  onClick={() => currentCall.accept() }
              >
                <img src={phoneIcon} alt='answer' />
              </MDBox>
          }
          <MDBox
              variant="gradient"
              color={'white'}
              coloredShadow={'#FFBAAA'}
              borderRadius="xl"
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="3rem"
              height="3rem"
              ml={2}
              bgColor={'error'}
              onClick={() => finishCall() }
          >
            <img src={endCall} alt='reject' />
          </MDBox>
        </MDBox>

      </MDBox>
    </>
  );
}

export default CallPending;
