import {Field} from "formik";
import MDInput from "../MDInput";
import InputMask from "react-input-mask";
import {AutocompleteFormik} from "../AutocompleteFormik";
import UploadDoc from "../MDInputUploadFile/UploadDoc";
import MDTypography from "../MDTypography";
import Grid from "@mui/material/Grid";
import MDSwitch from "../MDSwitch";
import {NumericFormat} from "react-number-format";
function FormikInput(
  {
    name,
    errors,
    type = 'text',
    label,
    fullWidth = true,
    options = [],
    handleChange,
    setFieldValue,
    initialValue,
    multiline,
    multiple,
    useBrandingColor,
    backgroundColor,
    rows,
    onChangeSelect,
    placeholder,
    labelFieldName,
    inputFileStyles,
    inputDottedType,
    extraParamsInputContainer,
    helperInput,
    InputProps,
    disabled = false,
    labelPos,
    ...rest
  }) {
  return (
    <Field name={name}>
      {({field}) => {
        if (type === 'phone_input') {
          return (
            <Grid {...rest}>
              <MDTypography variant="h6" fontWeight="bold">
                {label}
              {labelPos}
              </MDTypography>
              <InputMask
                mask="+1(999)-999-9999"
                disabled={false}
                {...field}
              >
                <MDInput
                  type={type}
                  placeholder={placeholder}
                  useBrandingColor={useBrandingColor}
                  fullWidth={fullWidth}
                  error={errors[name] !== undefined}
                  helperText={errors[name] && errors[name]}
                />
              </InputMask>
              {helperInput}
            </Grid>
          )
        } else if (type === 'select') {
          return (
            <Grid {...rest}>
              <MDTypography variant="h6" fontWeight="bold">
                {label}
              {labelPos}
              </MDTypography>
              <AutocompleteFormik
                placeholder={placeholder}
                options={options}
                useBrandingColor={useBrandingColor}
                labelFieldName={labelFieldName}
                field={field}
                setFieldValue={(field, value) => {
                  setFieldValue(field, value)
                }}
                initialValue={initialValue}
                touched
                errors={errors}
                onChange={onChangeSelect}
                {...field}
              />
              {helperInput}
            </Grid>
          )

        } else if (type === 'file') {
          return (
            <Grid {...rest}>
              <MDTypography variant="h6" fontWeight="bold">
                {label}
              {labelPos}
              </MDTypography>
              <UploadDoc
                fullWidth={fullWidth}
                handleChange={handleChange}
                useBrandingColor={useBrandingColor}
                label={label}
                multiple={multiple}
                inputDottedType={inputDottedType}
                inputFileStyles={inputFileStyles}
                error={errors[name] !== undefined}
                helperText={errors[name] && errors[name]}
                {...field}
              />
              {helperInput}
            </Grid>
          )

        } else if (type === 'textarea') {
          return (
            <Grid {...rest}>
              <MDTypography variant="h6" fontWeight="bold">
                {label}
              {labelPos}
              </MDTypography>
              <MDInput
                type={type}
                placeholder={placeholder}
                useBrandingColor={useBrandingColor}
                backgroundColor={backgroundColor}
                multiline
                numberOfLines={3}
                rows={rows}
                fullWidth={fullWidth}
                error={errors[name] !== undefined}
                helperText={errors[name] && errors[name]}
                {...field}
              />
              {helperInput}
            </Grid>
          )
        } else if (type === 'numeric') {
          return (
            <Grid {...rest}>
              <MDTypography variant="h6" fontWeight="bold">
                {label}
              {labelPos}
              </MDTypography>
              <NumericFormat
                customInput={MDInput}
                type="text"
                placeholder={placeholder}
                useBrandingColor={useBrandingColor}
                fullWidth={fullWidth}
                thousandsGroupStyle="thousand"
                thousandSeparator=","
                decimalScale={2}
                error={errors[name] !== undefined}
                helperText={errors[name] && errors[name]}
                {...field}

              />
              {helperInput}
            </Grid>
          )
        } else if (type === 'boolean') {
          return (
            <Grid {...rest}>
              <Grid container>
                <Grid item xs={10} sx={{display: 'flex', alignItems: 'flex-start', flexDirection: 'column'}}>
                  <MDTypography variant="h6" fontWeight="bold">
                    {label}
                  {labelPos}
                  </MDTypography>
                  {helperInput}
                </Grid>
                <Grid item xs={2} sx={{display: 'flex', justifyContent: 'flex-end'}}>
                  <MDSwitch
                    onChange={(e) => {
                      e.preventDefault()
                      setFieldValue(field.name, e.target.checked)
                    }}
                    checked={field.value}
                    type="ios"/>
                </Grid>
              </Grid>
            </Grid>
          )
        } else {
          return (
            <Grid {...rest}>
              <MDTypography variant="h6" fontWeight="bold">
                {label}
              {labelPos}
              </MDTypography>
              <MDInput
                type={type}
                placeholder={placeholder}
                useBrandingColor={useBrandingColor}
                fullWidth={fullWidth}
                error={errors[name] !== undefined}
                helperText={errors[name] && errors[name]}
                InputProps={InputProps}
                disabled={disabled}
                {...field}
              />
              {helperInput}
            </Grid>
          )}}}
    </Field>
  )
}

export default FormikInput
