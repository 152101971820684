import { Grid } from '@mui/material';
import SimpleTable from "components/SimpleTable";
import { useStores } from "models";
import { useEffect, useState } from "react";
import { showMessage } from "services/helpers";
import AdminLayout from "../../../components/AdminLayout";
import MDInput from "../../../components/MDInput";
import MDTypography from "../../../components/MDTypography";
import "../styles.css";

 
export const invoiceDataTableModel = {
  columns: [
    { Header: "# Invoice", accessor: "invoice" },
    { Header: "Product", accessor: "product" },
    { Header: "Matrix", accessor: "matrix" },
    { Header: "Quantity", accessor: "quantity" },
    { Header: "Price", accessor: "price" },   
    { Header: "Subtotal", accessor: "subtotal" },
    { Header: "Total", accessor: "total" },      
  ],
  rows: []
}


export default function SalesRep(props) {
    const rootStore = useStores();
    const {loginStore} = rootStore;
    const [Invoices, setInvoices] = useState({...invoiceDataTableModel});
    const [loading, setLoading] = useState(false);
    const [StartDate, setStartDate] = useState(`${new Date().getFullYear()}-01-01`);
    const [EndDate, setEndDate] = useState(`${new Date().getFullYear()}-12-31`);
    //
    const getSalesRepReport = (startDate, endDate) => {
        setLoading(true);
        loginStore.environment.api.getSalesRepReport(startDate, endDate)
        .then((result) => {
            if (result.kind === "ok") {
                console.log("result", result);
                const {invoices_detail} = result.data;
                const parsedInvoices = invoices_detail.map((detail, iidix) => 
                ({...detail, 
                    invoice: detail.invoice.id,
                    product: detail.product.name, 
                    matrix: detail.matrix.name
                }))
                setInvoices({...Invoices, rows: parsedInvoices});                
            } else {
                if (result.kind === "bad-data") {
                    showMessage(result?.errors, 'error', true);
                } else {
                    showMessage('An error occurred, please try again later');
                }
            }
        })
        .finally(() => setLoading(false))
    }
    
    useEffect(()=> {
        if (!!StartDate && !!EndDate) {
            getSalesRepReport(StartDate, EndDate);
        }
    } ,[StartDate, EndDate]);

    return (

    <AdminLayout title={"Sales Rep"} loading={loading}>
         <Grid container ml={2} mb={2}>
            <Grid item xs={6} lg={3} py={{xs: 4, lg: 0}} pr={{xs: 2, lg: 0}} mr={{lg: 2, xs: 0}}>
                <MDTypography variant="h6" fontWeight="bold">
                    Start Date
                </MDTypography>
                <MDInput
                    fullWidth
                    useBrandingColor={'secondary'}
                    input={{placeholder: "Select date"}}
                    type='date'
                    value={StartDate}
                    onChange={evt => setStartDate(evt.target.value)}
                />
            </Grid>
            <Grid item xs={6} lg={3} py={{xs: 4, lg: 0}}>
                <MDTypography variant="h6" fontWeight="bold">
                    End Date
                </MDTypography>
                <MDInput
                    fullWidth
                    useBrandingColor={'secondary'}
                    input={{placeholder: "Select date"}}
                    type="date"
                    value={EndDate}
                    onChange={evt => setEndDate(evt.target.value)}
                />
            </Grid>
        </Grid>
        {/* INVOICES */}
        <Grid container spacing={3} mt={2}> 
            <Grid item xs={12} lg={12} md={12} ml={2}>
                <SimpleTable data={Invoices} emptyLabelText={'No invoices found'}/>
            </Grid>
        </Grid>
    </AdminLayout>
    )
}
