import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { Grid } from '@mui/material';
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDDialog from "components/MDDialog";
import { useStores } from "../../models";
import { formatFormikArrayErrors, generateOptionsFromString, showMessage } from "services/helpers";

import "./index.css";
import { CUSTOM_FIELD_TYPE, CUSTOM_FIELDS_APPLY_TO } from "../../constants";
import * as Yup from "yup";
import { FieldArray, Form, Formik } from "formik";
import FormikInput from "../FormikInput";

export const CustomFieldModal = ({ modalOpen, handleClose }) => {
  const rootStore = useStores()
  const { loginStore } = rootStore
  const formikRef = useRef();

  const validationSchema =
    Yup.object().shape({
      model_name: Yup.object().required('Applies to is a required field').typeError('Applies to is a required field'),
      fields: Yup.array().of(Yup.object().shape(
        {
          field_name: Yup.string().required('Field name is a required field'),
          field_type: Yup.object().required('Field type is a required field').typeError('Field type is a required field'),
          field_options: Yup.string(),
          default_value: Yup.mixed().required('Default value is a required field'),
          transferable: Yup.bool(),
        }
      )),
    })

  const initialValues = {
    model_name: '',
    fields: [
      {
        field_name: '',
        field_type: '',
        field_options: '',
        default_value: '',
        transferable: false,
      }
    ]
  }
  const preProcessDataBeforeSubmit = (data) => {
    const default_value = { value: data.fields[0].default_value }
    return {
      model_name: data.model_name.value,
      fields: [
        { ...data.fields[0], field_type: data.fields[0].field_type.value, default_value }
      ]
    }
  }

  const createCustomField = (data) => {
    const dataToSend = preProcessDataBeforeSubmit(data)

    loginStore.environment.api.createCustomField(dataToSend).then((result) => {
      if (result.kind === "ok") {
        showMessage('The custom field had been created', 'success')
        handleClose()
      } else {
        if (result.kind === "bad-data") {
          formikRef.current.setErrors(result.errors);
          showMessage('Validation errors found')
        } else {
          showMessage()
        }
      }
    })
  }


  const renderFieldInput = (index, setFieldValue, errors, values, field) => {
    let defaultInputType
    const field_type = field['field_type']?.value

    const field_options = field['field_options']
    const fields = ['field_name', 'transferable', 'field_type', 'field_options', 'default_value']
    const errorsFormatted = formatFormikArrayErrors(index, errors, 'fields', fields)
    const optionsForField = generateOptionsFromString(field_options)

    switch (field_type) {
      case 'BOOLEAN':
        defaultInputType = 'boolean'
        if (typeof field['default_value'] !== 'boolean') {
          setFieldValue(`fields[${index}].default_value`, true)
        }
        break
      case 'NUMERIC':
        defaultInputType = 'numeric'
        break
      case 'TEXT':
        defaultInputType = 'text'
        if (field['default_value'] !== null && typeof field['default_value'] !== 'string') {
          setFieldValue(`fields[${index}].default_value`, '')
        }
        break
      case 'DATE':
        defaultInputType = 'date'
        break
      case 'DATETIME':
        defaultInputType = 'datetime-local'
        break
      case 'SELECT':
        defaultInputType = 'select'
        break
      default: break
    }


    return (
      <React.Fragment key={index}>
        <FormikInput
          useBrandingColor={'secondary'}
          name={`fields[${index}].transferable`}
          label={'Transferable field'}
          type={'boolean'}
          setFieldValue={setFieldValue}
          errors={errorsFormatted}
          item
          xs={12}
        />
        <FormikInput
          useBrandingColor={'secondary'}
          name={`fields[${index}].field_name`}
          label={'Field name'}
          placeholder={'input custom field name'}
          errors={errorsFormatted}
          item
          xs={12}
        />
        <FormikInput
          useBrandingColor={'secondary'}
          name={`fields[${index}].field_type`}
          label={'Field type'}
          placeholder={'True / False'}
          labelFieldName={'label'}
          type={'select'}
          setFieldValue={setFieldValue}
          options={CUSTOM_FIELD_TYPE}
          errors={errorsFormatted}
          item
          xs={12}
        />
        {field_type === 'SELECT' && <FormikInput
          useBrandingColor={'secondary'}
          name={`fields[${index}].field_options`}
          label={'Field options'}
          placeholder={'input the options for the field'}
          errors={errorsFormatted}
          type={'textarea'}
          rows={3}
          item
          xs={12}
          helperInput={
            <MDTypography variant={'text'} sx={{ fontSize: '12px' }}>
              Type de values separated by comma ","
            </MDTypography>
          }
        />}
        {defaultInputType && <FormikInput
          useBrandingColor={'secondary'}
          name={`fields[${index}].default_value`}
          label={'Default value'}
          type={defaultInputType}
          placeholder={'input a default value for the field'}
          setFieldValue={setFieldValue}
          labelFieldName={'label'}
          options={optionsForField}
          errors={errorsFormatted}
          item
          xs={12}
        />}
      </React.Fragment>
    )
  }


  return (
    <MDDialog
      open={modalOpen}
      title={"New Custom Field"}
      handleClose={handleClose}
    >
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnBlur={false}
        validateOnChange={false}
        enableReinitialize
        onSubmit={values => {
          createCustomField(values)
        }}
      >
        {({ errors, setFieldValue, values }) => {
          console.log(' ==========>>>>>>>> ', { errors, values })
          return (
            <Form>
              <Grid container spacing={1}>
                <FormikInput
                  useBrandingColor={'secondary'}
                  name={'model_name'}
                  label={'Applies to'}
                  placeholder={'Quotes'}
                  labelFieldName={'label'}
                  type={'select'}
                  setFieldValue={setFieldValue}
                  options={CUSTOM_FIELDS_APPLY_TO}
                  errors={errors}
                  item
                  xs={12}
                />

                <FieldArray
                  name="fields"
                  render={() => {
                    return values.fields.map((field, index) => renderFieldInput(index, setFieldValue, errors, values, field))
                  }}
                />
                <Grid item xs={12} md={12} sm={12} mt={2}>
                  <MDTypography variant="h6" fontWeight="bold">All custom fields can be adite and reordered
                    in</MDTypography>
                  <MDTypography color={'success'} variant="h6"
                    fontWeight="bold">{'Settings > Custom Fields'}</MDTypography>
                </Grid>
                <Grid item xs={12} md={12} sm={12} mt={3} display="flex" justifyContent="center" alignItems="center">
                  <MDButton
                    useBrandingColor={'primary'}
                    color="green"
                    type="button"
                    className={'btn-save-dialog'}
                    variant="outlined"
                    fullWidth
                    style={{ margin: 10 }}
                    onClick={handleClose}
                  >
                    Cancel
                  </MDButton>
                  <MDButton
                    useBrandingColor={'primary'}
                    color="green"
                    type="submit"
                    className={'btn-save-dialog'}
                    fullWidth
                    style={{ margin: 10 }}
                  >
                    Create Custom Field
                  </MDButton>
                </Grid>
              </Grid>
            </Form>
          )
        }}
      </Formik>
    </MDDialog>
  );
}
