import { useEffect, useState } from "react";
import moment from 'moment'
import { Grid, InputAdornment, IconButton } from '@mui/material';
import AdminLayout from "components/AdminLayout";
import { useNavigate, useParams } from "react-router-dom";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDCheckbox from "components/MDCheckbox";
import '../styles.css';
import { useStores } from "models";
import { showMessage } from "services/helpers";
import UploadDoc from "components/MDInputUploadFile/UploadDoc";

export default function RequestOpen() {
  const { id } = useParams();
  const navigate = useNavigate();
  const rootStore = useStores();
  const { loginStore } = rootStore;

  const [RequestDetail, setRequestDetail] = useState(null);
  const [Loading, setLoading] = useState(false);

  const [file, setFile] = useState(null);
  const [NoteArrachments, setNoteArrachments] = useState(null);
  const [ClientNote, setClientNote] = useState('');
  const [LinkNoteTo, setLinkNoteTo] = useState('');

  const getClientDetail = (id) => {
    loginStore.environment.api.getClientDetail(id).then((result) => {
      if (result.kind === "ok") {
        if (result?.data?.client_notes_attachments?.[0]?.id) {
          setNoteArrachments(result?.data?.client_notes_attachments?.[0])
          setClientNote(result?.data?.client_notes_attachments?.[0]?.description)
          setLinkNoteTo(result?.data?.client_notes_attachments?.[0]?.link_to)
        }
      } else {
        if (result.kind === "bad-data") showMessage(result?.errors, 'error', true)
        else showMessage('An error occurred, please try again later')
      }
    })
  }

  const getRequestDetail = (id) => {
    loginStore.environment.api.getRequestDetail(id).then((result) => {
      if (result.kind === "ok") {
        const data = {
          ...result.data,
        }
        if (result?.data?.request_for?.id) getClientDetail(result?.data?.request_for?.id)
        setRequestDetail(data);
      } else {
        showMessage()
      }
    })
  }

  const updateRequestAssessment = (completed) => {
    setLoading(true)
    loginStore.environment.api.updateRequestAssessment({ completed, id })
      .then((result) => {
        if (result.kind === "ok") {
          showMessage('Request was updated', 'success')
          window.location.reload()
        }
        else showMessage()
      })
      .finally(() => setLoading(false))
  }

  const handleUploadFile = (e) => {
    const [file] = e.target.files;
    if (file) setFile(file)
  }

  const downloadFile = (url) => {
    fetch(url)
      .then(response => response.blob())
      .then(blob => {
        const blobUrl = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = url.substring(url.lastIndexOf('/') + 1).split('?uuid')[0];
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
  }

  const saveClientNotes = () => {
    setLoading(true)
    let data = {
      description: ClientNote,
      link_to: LinkNoteTo
    }
    if (file) data.attachment = file

    if (NoteArrachments?.id) {
      data.id = NoteArrachments?.id
      loginStore.environment.api.updateClientNotes({ ...data, user: RequestDetail?.request_for?.id })
        .then((result) => {
          if (result.kind === "ok") {
            showMessage('Notes & Attachments has been saved', 'success')
            getRequestDetail(id)
          }
          else showMessage()
        })
        .finally(() => setLoading(false))
    } else {
      loginStore.environment.api.createClientNotes({ ...data, user: id })
        .then((result) => {
          if (result.kind === "ok") {
            showMessage('Notes & Attachments has been saved', 'success')
            getRequestDetail(id)
          }
          else showMessage()
        })
        .finally(() => setLoading(false))
    }
  }

  useEffect(() => {
    if (id) getRequestDetail(id);
  }, []);

  return (
    <AdminLayout title={"Request Details"} loading={Loading}>

      <Grid container alignItems="top" paddingTop={0} marginTop={0} spacing={3}>
        <Grid item xs={12} lg={12} md={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
          <MDTypography variant="h2" fontWeight="medium" mb={1} ml={3} style={{ float: 'left' }}>
            Request Details
          </MDTypography>
        </Grid>
      </Grid>

      <Grid container spacing={3} pb={3} className="task_container">

        <Grid item xs={3} lg={3} md={3}>
          <MDTypography variant="h6" fontWeight="bold">Client information</MDTypography>
          <MDTypography variant="h6">{RequestDetail?.request_for?.full_name || '-'}</MDTypography>
          <MDTypography variant="h6">{RequestDetail?.request_for?.address || '-'}</MDTypography>
        </Grid>

        <Grid item xs={3} lg={3} md={3}>
          <MDTypography variant="h6" fontWeight="bold">Date of Request</MDTypography>
          <MDTypography variant="h6">{RequestDetail?.created_at ? moment(RequestDetail.created_at).format('ll') : '-'}</MDTypography>
          <br />
        </Grid>

        <Grid item xs={6} lg={6} md={6}>
          <MDTypography variant="h6" fontWeight="bold">Date of Assesment</MDTypography>
          <MDTypography variant="h6">
            {RequestDetail?.start_date
              ? `${moment(RequestDetail?.start_date).format('ll')} to ${moment(RequestDetail?.end_date).format('ll')}`
              : '-'}
          </MDTypography>
          <br />
        </Grid>

        <Grid item xs={12} lg={12} md={12}>
          <hr className="MuiDivider-root MuiDivider-fullWidth" style={{ background: '#C6C9CE', margin: '16px 0' }} />
        </Grid>

        <Grid item xs={2} lg={2} md={2}>
          <MDTypography variant="h6" fontWeight="bold">
            Assessment
          </MDTypography>
        </Grid>
        <Grid item xs={2} lg={2} md={2}>
          <MDTypography variant="h6" fontWeight="bold">
            Instructions
          </MDTypography>
        </Grid>
        <Grid item xs={2} lg={2} md={2}>
          <MDTypography variant="h6" fontWeight="bold">
            Schedule
          </MDTypography>
        </Grid>
        <Grid item xs={2} lg={2} md={2}>
          <MDTypography variant="h6" fontWeight="bold">
            Team
          </MDTypography>
        </Grid>
        <Grid item xs={2} lg={2} md={2}>
          <MDTypography variant="h6" fontWeight="bold">
            Completed
          </MDTypography>
        </Grid>
        <Grid item xs={2} lg={2} md={2}>
          <MDTypography variant="h6" fontWeight="bold">
            Visit reminders
          </MDTypography>
        </Grid>
        {/* Assessment */}
        <Grid item xs={2} lg={2} md={2} className="iq-height">
          <MDTypography mt={1} className="paragraphs" style={{ float: 'left' }}>Completed?</MDTypography>
          <MDCheckbox
            style={{ float: 'right' }}
            onChange={(e) => updateRequestAssessment(e.target.checked)}
            checked={RequestDetail?.assessment_completed}
          // checked={true}
          />
        </Grid>
        {/* Instructions */}
        <Grid item xs={2} lg={2} md={2} className="iq-height">
          <MDTypography className="paragraphs">{RequestDetail?.service_details}</MDTypography>
        </Grid>
        {/* Schedule */}
        <Grid item xs={2} lg={2} md={2} className="iq-height">
          <MDTypography className="paragraphs">{RequestDetail?.start_date ? moment(RequestDetail?.start_date).format('ll') : '-'}</MDTypography>
        </Grid>
        {/* Team */}
        <Grid item xs={2} lg={2} md={2} className="iq-height">
          <MDTypography className="paragraphs">{RequestDetail?.assign_to?.full_name}</MDTypography>
        </Grid>
        {/* Completed */}
        <Grid item xs={2} lg={2} md={2} className="iq-height">
          <MDTypography className="paragraphs">{RequestDetail?.assessment_completed_date ? moment(RequestDetail?.assessment_completed_date).format('ll') : '-'}</MDTypography>
        </Grid>
        {/* Visit reminders */}
        <Grid item xs={2} lg={2} md={2} className="iq-height">
          <MDTypography className="paragraphs">-
          </MDTypography>
        </Grid>
      </Grid>

      <Grid container alignItems="top" paddingTop={0} marginTop={0} >
        <Grid item xs={12} lg={12} md={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
          <MDTypography variant="h3" fontWeight="medium" ml={3} style={{ float: 'left' }}>
            Request Details
          </MDTypography>
        </Grid>
      </Grid>

      <Grid container spacing={3} pb={3} className="task_container">

        <Grid item xs={6} lg={6} md={6}>
          <MDTypography variant="h6" fontWeight="bold">
            Notes
          </MDTypography>
          <MDInput
            useBrandingColor={'secondary'}
            fullWidth
            placeholder='input note details'
            multiline rows={5}
            value={ClientNote}
            onChange={e => setClientNote(e.target.value)}
          />
        </Grid>
        <Grid item xs={6} lg={6} md={6}>
          <UploadDoc
            fullWidth
            handleChange={(e) => handleUploadFile(e)}
            useBrandingColor={'secondary'}
            label={'Drag files her or click to select'}
            multiple
            inputDottedType
            inputFileStyles={{
              borderRadius: '8px',
            }}
          />

          {file?.name
            ? <MDTypography mx={3} variant={'subtitle2'}>{file?.name}</MDTypography>
            : NoteArrachments?.attachment
              ? <MDTypography
                mx={3}
                variant="h6"
                fontWeight={"medium"}
                color='primary'
                sx={{ cursor: 'pointer' }}
                onClick={() => downloadFile(NoteArrachments?.attachment)}
              >Download File</MDTypography>
              : <></>
          }
        </Grid>



        <Grid item xs={6} lg={6} md={6}>
          <MDTypography variant="h6" fontWeight="bold">
            Link note to
          </MDTypography>
          <Grid item lg={12} display={'flex'} alignItems={'center'} >
            <MDTypography variant="h6" fontWeight="regular">
              Jobs
            </MDTypography>
            <MDCheckbox
              fontSize={'small'}
              checked={LinkNoteTo.includes('Jobs')}
              onChange={(e) => {
                e.target.checked
                  ? setLinkNoteTo(`${LinkNoteTo}|Jobs`)
                  : setLinkNoteTo(LinkNoteTo.replace('|Jobs', ''))
              }}
            />
            <MDTypography ml={4} variant="h6" fontWeight="regular">
              Quote
            </MDTypography>
            <MDCheckbox
              fontSize={'small'}
              checked={LinkNoteTo.includes('Quote')}
              onChange={(e) => {
                e.target.checked
                  ? setLinkNoteTo(`${LinkNoteTo}|Quote`)
                  : setLinkNoteTo(LinkNoteTo.replace('|Quote', ''))
              }}
            />
            <MDTypography ml={4} variant="h6" fontWeight="regular">
              Requests
            </MDTypography>
            <MDCheckbox
              fontSize={'small'}
              checked={LinkNoteTo.includes('Requests')}
              onChange={(e) => {
                e.target.checked
                  ? setLinkNoteTo(`${LinkNoteTo}|Requests`)
                  : setLinkNoteTo(LinkNoteTo.replace('|Requests', ''))
              }}
            />
            <MDTypography ml={4} variant="h6" fontWeight="regular">
              Invoices
            </MDTypography>
            <MDCheckbox
              fontSize={'small'}
              checked={LinkNoteTo.includes('Invoices')}
              onChange={(e) => {
                e.target.checked
                  ? setLinkNoteTo(`${LinkNoteTo}|Invoices`)
                  : setLinkNoteTo(LinkNoteTo.replace('|Invoices', ''))
              }}
            />
          </Grid>
        </Grid>

        <Grid item xs={12} lg={12} md={12} mt={5} display="flex" justifyContent="space-evenly" alignItems="center">
          <MDButton
            color="green"
            useBrandingColor={'primary'}

            variant="outlined"
            className="btn-save"
            sx={{ width: 200 }}
          >
            Cancel
          </MDButton>
          <MDButton
            color="green"
            useBrandingColor={'primary'}
            onClick={() => saveClientNotes()}
            className="btn-save"
            sx={{ width: 200 }}
          >
            Save
          </MDButton>
        </Grid>

      </Grid>
    </AdminLayout>
  )
}
