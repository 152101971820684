import React, { useState } from 'react';
import { Link } from "react-router-dom";
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MDButton from "components/MDButton";
import {styled} from "@mui/material/styles";
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import RuleIcon from '@mui/icons-material/Rule';
import { Typography } from '@mui/material';
import MoreIcon from "assets/images/icons/more.png";
import EditIcon from 'assets/images/icons/edit.png';
import DeleteIcon from 'assets/images/icons/delete.png';
import "./tables.css";


export const ActionButton = (props)=> {
    const{current} = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [active, setActive] = useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setActive(true);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setActive(false);
  };


  return (
    <div style={{ marginLeft: 27 }}>     
      <MDButton 
        color="green"
        onClick={handleClick}
        iconOnly
        key={1}
      > 
      <img src={MoreIcon} />
      </MDButton>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        className="menu-basics"
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleClose}>
            <MDButton 
                color="green"
                className={['btn-import-sub', current === 'list' ? 'active' : ''].join(" ")}
                startIcon={<img src={EditIcon} className="btn-import-sub-icon"/>}
                value="list"
                onClick={props.onClick}
            > Edit
            </MDButton>
            
        </MenuItem>
        <MenuItem onClick={handleClose}>
            <MDButton 
                color="error"
                className={['btn-import-sub', current === 'grid' ? 'active' : ''].join(" ")}
                startIcon={<img src={DeleteIcon}/>}
                value="grid"
                // onClick={props.onClick}
            > Deactivate
            </MDButton>
        </MenuItem>
      </Menu>
    </div>
  );
}
