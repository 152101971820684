import * as Yup from "yup";
import MDButton from "../../../components/MDButton";


export const clientValidationSchema =
  Yup.object().shape({
    company_name_primary: Yup.boolean(),
    first_name: Yup.string().when("company_name_primary", {is: false, then: Yup.string().required('First name is a required field')}),
    last_name: Yup.string().when("company_name_primary", {is: false, then: Yup.string().required('Last name is a required field')}),
    company_name: Yup.string().when("company_name_primary", {is: true, then: Yup.string().required('Company name is a required field')}),
    phone_numbers: Yup.array().min(1, "at least 1").of(Yup.object().shape({phone_number: Yup.string().required('Phone number is a required field')})),
    emails: Yup.array().min(1, "at least 1").of(Yup.object().shape({email: Yup.string().email().required('Email is a required field')})),
    address: Yup.string(),
    quote_follow_up: Yup.boolean(),
    job_follow_up: Yup.boolean(),
    invoice_follow_up: Yup.boolean(),
    appointment_reminders: Yup.boolean(),
    // referred_by: Yup.string(),
    // how_did_you_hear_about_us: Yup.string(),
  })

export const clientInitialValues = (data = null) => ({
  id: data ? data.id : "",
  company_name_primary: data ? data.company_name_primary : false,
  first_name: data ? data.first_name : "",
  last_name: data ? data.last_name : "",
  company_name: data ? data.company_name : "",
  phone_numbers: data ? data.client_extra_phone_numbers : [{phone_number: ""}],
  emails: data ? data.client_extra_emails : [{email: ""}],
  // TODO: CHANGE TO REAL ADDRESS
  address: data ? "" : "",
  quote_follow_up: data ? data.quote_follow_up : false,
  job_follow_up: data ? data.job_follow_up : false,
  invoice_follow_up: data ? data.invoice_follow_up : false,
  appointment_reminders: data ? data.appointment_reminders : false,
  referred_by: data ? data.referred_by : "",
  how_did_you_hear_about_us: data ? data.how_did_you_hear_about_us : "",
})

const phoneField = {
  name: 'phone_number',
  initial_value: '',
  label: '',
  type: 'phone_input',
  placeholder:'input phone number',
  extraParamsInputContainer: {item: true, xs: 12, md:12},
}

export const clientForm = [
  {
    name: 'first_name',
    initial_value: '',
    label: 'First Name',
    placeholder:'input first name',
    extraParamsInputContainer: {item: true, xs: 12, md:6},
    validationRule: Yup.string().when("company_name_primary", {is: false, then: Yup.string().required('First name is required')}),
  },
  {
    name: 'last_name',
    initial_value: '',
    label: 'Last Name',
    placeholder:'input last name',
    extraParamsInputContainer: {item: true, xs: 12, md:6},
    validationRule: Yup.string().when("company_name_primary", {is: false, then: Yup.string().required('Last name is required')}),
  },
  {
    name: 'company_name',
    initial_value: '',
    label: 'Company Name',
    placeholder:'input company name',
    extraParamsInputContainer: {item: true, xs: 12, md:6},
    validationRule: Yup.string().when("company_name_primary", {is: true, then: Yup.string().required('Company name is required')}),
  },
  {
    empty: true,
    extraParamsInputContainer: {item: true, xs: 12, md:6}
  },
  {
    name: 'company_name_primary',
    initial_value: false,
    label: 'Use company name as the primary name',
    type: 'boolean',
    extraParamsInputContainer: {item: true, xs: 12, md:6},
    validationRule: Yup.boolean()
  },
  {
    name: 'phone_numbers',
    initial_value: [phoneField],
    label: 'Phone number',
    type: 'array',
    extraParamsInputContainer: {item: true, xs: 12, md:6},
    validationRule: Yup.array().of(Yup.object().shape({phone_number: Yup.string().required('Phone number is required')})),
    helperInput: <MDButton color="green" className="mini-button" onClick={() => console.log('OthersPhoneNumber')}>Add phone +</MDButton>
  },
]


// export const renderClientForm = (idx, input, errors, setFieldValue) => {
//   if (input.empty) {
//     return (
//       <Grid key={idx} {...input.extraParamsInputContainer}/>
//     )
//   } if (input.type === 'array') {
//     console.log('input ==>  ', input)
//     return (
//       <Field name={input.name} key={idx}>
//         {({field}) => {
//           console.log('field ==>  ', field)
//           return (
//             <Grid {...input.extraParamsInputContainer}>
//               <MDTypography variant="h6" fontWeight="bold">
//                 {input.label}
//               </MDTypography>
//               {input.initial_value.map((item, idx2) => {
//                 return (
//                   <FormikInput key={idx2} {...item} errors={errors} />
//                 )
//               })}
//               {input.helperInput}
//             </Grid>
//           )}}
//       </Field>
//     )
//   } else {
//     return (
//       <FormikInput key={idx} {...input} errors={errors} setFieldValue={setFieldValue}/>
//     )
//   }
// }
