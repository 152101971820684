import React, { useState } from 'react';
import { Grid } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import MDButton from "../../../components/MDButton";
import './header.css'
import Percents from './Percents'
import MDBox from "../../../components/MDBox";
// icons
const icon1 = require('../icons/icon_1.png');
const icon2 = require('../icons/icon_2.png');
const icon3 = require('../icons/icon_3.png');
const icon4 = require('../icons/icon_4.png');

export const Header = (props) => {
  const [inactive, setInactive] = useState(false);
  const [createInv, setCreateInv] = useState(false);
  const [deliverInv, setDeliverInv] = useState(false);

  const expand = () => {
    setInactive(!inactive);
  }
  const handleCreate = () => {
    props.onClick('create', !createInv);
    setCreateInv(!createInv);
    if (deliverInv) {
      setDeliverInv(!deliverInv);
      props.onClick('deliver', !deliverInv);
    }
  }
  const handleDeliver = () => {
    props.onClick('deliver', !deliverInv);
    setDeliverInv(!deliverInv);
    if (createInv) {
      setCreateInv(!createInv);
      props.onClick('create', !createInv);
    }
  }

  return (
    <Grid container justifyContent={{ xs: 'center', md: 'space-between' }} pl={{ xs: 0, lg: 3 }}>
      <Grid item xs={10} md={6} lg={3} xl={6} xxl={2}>
        <MDBox className="boxes" px={4} pb={1} mt={{ xs: 2, lg: 0, xl: 2, xxl: 0 }} >
          <div className="images"><Percents perc={80} icon={<img src={icon1} />} colors="#60A77C" /></div>
          <div className="texts">
            <h6>Total</h6>
            <p><b>{props?.status?.TOTAL || 0}</b> <span>invoices</span></p>
            <p className="success">$0</p>
          </div>
        </MDBox>
      </Grid>
      <Grid item xs={10} md={6} lg={3} xl={6} xxl={2}>
        <MDBox className="boxes" px={4} pb={1} mt={{ xs: 2, lg: 0, xl: 2, xxl: 0 }} >
          <div className="images"><Percents perc={70} icon={<img src={icon2} />} colors="#DD816B" /></div>
          <div className="texts">
            <h6>Past due</h6>
            <p><b>{props?.status?.PAST_DUE || 0}</b> <span>invoices</span></p>
            <p className="danger">$0</p>
          </div>
        </MDBox>
      </Grid>
      <Grid item xs={10} md={6} lg={3} xl={6} xxl={2}>
        <MDBox className="boxes" px={4} pb={1} mt={{ xs: 2, lg: 0, xl: 2, xxl: 0 }}>
          <div className="images"><Percents perc={60} icon={<img src={icon3} />} colors="#F1AB40" /></div>
          <div className="texts">
            <h6>Sent but not due</h6>
            <p><b>{props?.status?.SENT_NOT_DUE || 0}</b> <span>invoices</span></p>
            <p className="warnings2">$0</p>
          </div>
        </MDBox>
      </Grid>
      <Grid item xs={10} md={6} lg={3} xl={6} xxl={2}>
        <MDBox className="boxes" px={4} pb={1} mt={{ xs: 2, lg: 0, xl: 2, xxl: 0 }}>
          <div className="images"><Percents perc={50} icon={<img src={icon4} />} colors="#95979B" /></div>
          <div className="texts">
            <h6>Draft</h6>
            <p><b>{props?.status?.DRAFT || 0}</b> <span>invoices</span></p>
            <p className="black">$0</p>
          </div>
        </MDBox>
      </Grid>
      {inactive && (
        <Grid item xs={3} sm={4} md={10} lg={8} xl={6} xxl={3}>
          <MDBox
            gap={3}
            justifyContent={{ xs: 'space-between' }}
            display={{ xs: 'block', md: 'flex', xxl: 'block' }}
            ml={{ xs: 0, md: 3, lg: 3, xxl: 7 }}
            mt={{ xs: 2, xxl: 0 }}

          >
            <MDButton
              color="white"
              className={["headers-buttons", createInv ? 'is-on' : ''].join(" ")}
              style={{ marginBottom: 9 }}
              onClick={handleCreate}
            >
              Batch Create Invoice
            </MDButton>
            <MDButton
              color="white"
              className={["headers-buttons", deliverInv ? 'is-on' : ''].join(" ")}
              onClick={handleDeliver}
            >
              Batch Delivery Invoice
            </MDButton>
          </MDBox>
        </Grid>
      )}

      <Grid item xs={inactive ? 5 : 9} sm={inactive ? 5 : 9} md={inactive ? 1 : 11} xxl={inactive ? 1 : 0.2}      >
        <MDBox
          className="boxes boxes-off contents"
          alignItems='center'
          p={0}
          py={{ xs: 4, lg: 0 }}
          pb={{ xs: 0, md: 8, lg: 4, xxl: 0 }}
          ml={{ xs: 'auto', md: inactive ? 0 : 'auto', lg: inactive ? 0 : 'auto', xxl: inactive ? 12 : 0 }}
        >
          <MoreVertIcon
            className={inactive ? 'inactive' : ''}
            onClick={() => expand()}
          />
          <KeyboardArrowLeftIcon
            className={['boxes-off-back', inactive ? '' : 'inactive'].join(" ")}
            onClick={() => expand()}
          />
        </MDBox>
      </Grid>
    </Grid>
  );
}