
import { useEffect, useState } from "react";
import { Grid, InputAdornment, IconButton } from '@mui/material';
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { PaymentElement } from '@stripe/react-stripe-js';

import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

import { useApi, showMessage } from "services/helpers";


const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC);

const StripeAddCard = ({ children, client_id, callback }) => {
  const api = useApi()
  const stripe = useStripe();
  const elements = useElements();

  const [loading, setLoading] = useState(false);
  
  const saveCard = async () => {
    const response = await stripe.createToken(elements.getElement('card'))
    
    if (client_id) {
      let data = { card_token: response.token.id, client_id }
      setLoading(true)
      api.stripePaymentClient(data).then((result) => {
        if (result.kind === "ok") {
          callback.call()
          showMessage('User card has been created', 'success')
        } else {
          showMessage('There was an error while saving the user card')
        }
      }).finally(() => setLoading(false))
    } else {
      let data = { card_token: response.token.id }
          setLoading(true)
      api.stripePayment(data).then((result) => {
        if (result.kind === "ok") {
          showMessage('User card has been created', 'success')
        } else {
          showMessage('There was an error while saving the user card')
        }
      }).finally(() => setLoading(false))
    
    }
  }

  return (
    <>
      <Grid item xs={12} lg={12} md={12} style={{  borderRadius: 5}}>
        {children}
      </Grid>
      <Grid item xs={8} lg={8} md={8} style={{  borderRadius: 5 }}>
        <CardElement />
      </Grid>
      <Grid item xs={4} lg={4} md={4} style={{ display: 'flex', justifyContent: 'center'}}>
        <MDButton  onClick={() => saveCard()} color="green"  useBrandingColor={'primary'} loading={loading}>
          Add Card
        </MDButton>

      </Grid>

    </>
  );
}

export default StripeAddCard
