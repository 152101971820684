import React, { useState } from 'react';
import MDButton from "components/MDButton";
import { useNavigate } from "react-router-dom";
import "./tables.css";
import { ROUTES } from 'constants';


export const AddButton = (props) => {
  const { tab } = props;
  const navigate = useNavigate()
  const [active, setActive] = useState(false);

  

  return (
    <div>
      <MDButton
        useBrandingColor={'primary'}
        color="green"
        sx={{ height: '48px', width: { md: '120px', lg: '200px' } }}
        onClick={props.handleClick}
      >{
          tab === 1
            ? 'Add New Item +'
            : tab === 2
              ? 'New Import +'
              : 'Add New Matrix +'
        }
      </MDButton>
    </div>
  );
}
