import React, { useEffect, useState } from 'react';
import MDButton from "components/MDButton";
import MDDialog from "components/MDDialog";
import { Grid } from '@mui/material';
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import { inputs } from './pdf_utils'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import MDAutocomplete from "components/MDAutocomplete";
import MDSwitch from "components/MDSwitch";
import MDBox from 'components/MDBox';
import { useStores } from "models";
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { showMessage, useApi } from "services/helpers";


const defaultValues = {
  "header_layout": "COMPACT",
  "header_style": "MODERN",
  "header_color": "PURPLE",
  "logo_size": "MEDIUM",
  "show_name_with_logo": true,
  "show_phone_number_header": true,
  "show_email_header": true,
  "show_website_header":true,
  "company":2,
  "quote_refer_quotes_as_estimated":true,
  "quote_show_qty_on_lines":true,
  "quote_show_unit_on_lines":true,
  "quote_show_total_cost_per_items":true,
  "quote_contract_disclaimer":"QUOTEEEEEE Contract Disclaimer",
  "quote_message":"messageeees quotes",
  "job_include_client_signature_line":true,
  "job_contract_disclaimer":"JOB CONTRACT DISCLAIMEEEEEEEER",
  "job_message": "messageeees joooobs",
  "invoice_refer_quotes_as_estimated":true,
  "invoice_show_qty_on_lines":true,
  "invoice_show_unit_on_lines":true,
  "invoice_show_total_cost_per_items":true,
  "invoice_contract_disclaimer":"INVOICEEEEEEEEEE Contract Disclaimer",
  "invoice_message":"INVOICEEEEE quotes"
}

const PDFStyle = (props) => {
  const rootStore = useStores();
  const { loginStore } = rootStore;
  const api = useApi();

  const [loading, setLoading] = useState(false);
  const [value, setValue] = React.useState(0);

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const [examplePdfFile, setExamplePdfFile] = useState(null);

  const [PDFValues, setPDFValues] = useState({});

  const handleChange = (event, newValue) => setValue(newValue)

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const createPdfStyle = () => {
    api.createPdfStyle(PDFValues).handle({
      onSuccess: (result) => {
        getPdfStyle()
      },
      successMessage: 'Style updated successfully!',
      errorMessage: 'Error updating styles, please try again.',
    })
  }

  const previewPdfStyle = (id, data = PDFValues) => {
    api.previewPdfStyle(id, data).handle({
      onSuccess: (res) => {
        setExamplePdfFile(res.data)
        // getPdfStyle()
      },
      successMessage: 'Preview updated',
      errorMessage: 'Error updating preview, please try again.',
    })
  }

  const renderField = (input, key) => {
    let field
    let value

    const onChange = (key, value) => {
      let temp = { ...PDFValues }
      temp[key] = value
      setPDFValues({ ...temp, company: loginStore.getCompany.id })
    }

    switch (input.type) {
      case 'select':
        value = input.options.find(o => o.value === PDFValues?.[input.key]) || null
        field = <MDBox key={`input_${key}`} mt={2}>
          <MDTypography variant="h6" fontWeight="bold">
            {input.label}
          </MDTypography>
          <MDAutocomplete
            value={value}
            placeholder={input.placeholder}
            options={input.options}
            onChange={(e,v) => {
              onChange(input.key, v?.value || '')
              let temp = { ...PDFValues }
      temp[input.key] = v?.value || ''
              previewPdfStyle(PDFValues?.id, temp)
              
            }}
            isOptionEqualToValue={(option, value) => (option?.value === value?.value)}
          />
        </MDBox>
        break;
      case 'check':
        value = PDFValues?.[input.key] || false
        field = <MDBox key={`input_${key}`} mt={2} style={{ display: 'flex' }}>
          <MDSwitch
          type="ios"
          onChange={e => {
            onChange(input.key, e?.target?.checked)
            let temp = { ...PDFValues }
            temp[input.key] = e?.target?.checked
            previewPdfStyle(PDFValues?.id, temp)
          }}
          checked={value}
          />
          <MDTypography mt={1} variant="h6" fontWeight="bold">
            {input.label}
          </MDTypography>
        </MDBox>
        break;
      case 'text_multiline':
        value = PDFValues?.[input.key] || null
        field = <MDBox key={`input_${key}`} mt={2}>
          <MDTypography variant="h6" fontWeight="bold">
            {input.label}
          </MDTypography>
          <MDInput
            value={value}
            onChange={e => onChange(input.key, e?.target?.value)}
            fullWidth
            placeholder={input.placeholder}
            multiline
            onBlur={() => previewPdfStyle(PDFValues?.id)}
            rows={5}
          />
        </MDBox>
        break;
      default:
        value = PDFValues?.[input.key] || null
        field = <MDBox key={`input_${key}`} mt={2}>
          <MDTypography variant="h6" fontWeight="bold">
            {input.label}
          </MDTypography>
          <MDInput
            value={value}
            onChange={e => onChange(input.key, e?.target?.value)}
            fullWidth
            onBlur={() => previewPdfStyle(PDFValues?.id)}
            placeholder={input.placeholder}
          />
        </MDBox>
    }

    return field
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const getExample = () => {
    setLoading(true)
    api.getPdfExample().handle({
      onSuccess: (res) => {
        setExamplePdfFile(res.data)
       },
      errorMessage: 'Error',
      onFinally: () => setLoading(false),
    })
  }

  const getPdfStyle = () => {
    setLoading(true)
    api.getPdfStyle().handle({
      onSuccess: (res) => {
        setPDFValues(res.data?.results?.[0] || {...defaultValues, company: loginStore.company_data.id})
      },
      errorMessage: 'Error',
      onFinally: () => setLoading(false),
    })
  }


  useEffect(() => {
    getExample()
    getPdfStyle()
  }, [])

  return (
    <MDDialog
      open={props.open}
      title={"PDF Style"}
      handleClose={props.handleClose}
      maxWidth={false}
      style={{ width: 1200, height: 800 }}
    >
      <Grid container mt={1}>
        {/*  */}
        <Grid
          container item sx={12} lg={12} md={12} spacing={2} pl={4} pr={4}
          style={{ maxHeight: 700, overflow: 'auto' }}
        >
          <Grid item xs={6} md={6} sm={12} >
            <Tabs value={value} onChange={handleChange}>
              <Tab label="Style" {...a11yProps(0)} />
              <Tab label="Quote" {...a11yProps(1)} />
              <Tab label="Jobs" {...a11yProps(2)} />
              <Tab label="Invoice" {...a11yProps(3)} />
            </Tabs>
            {(inputs[value] && Array.isArray(inputs[value])) &&
              inputs[value].map((input, i) => renderField(input, i))
            }
          </Grid>
          <Grid item xs={5} md={6} sm={12}>
            <MDBox>
              <Document file={examplePdfFile} onLoadSuccess={onDocumentLoadSuccess}>
                <Page pageNumber={pageNumber} scale={0.7} renderTextLayer={false}/>
              </Document>

            </MDBox>
          </Grid>
        </Grid>
        {/*  */}
        <Grid item xs={12} lg={12} md={12} display={{ lg: "flex", xs: "block" }} justifyContent="space-evenly" alignItems="center">
          <Grid item xs={12} lg={3} md={12} display='flex' mt={{ xs: 1, lg: 2 }}>
            <MDButton
              useBrandingColor={'primary'}
              color="green"
              variant="outlined"
              fullWidth
              onClick={props.handleClose}
            
              className="btn-save"
            >
              Cancel
            </MDButton>
          </Grid>
          <Grid item xs={12} lg={3} md={12} display='flex' mt={{ xs: 1, lg: 2 }}>
            <MDButton
              useBrandingColor={'primary'}
              color="green"
              fullWidth
              className="btn-save"
              onClick={() => createPdfStyle()}
            >
              Save
            </MDButton>
          </Grid>
        </Grid>
      </Grid>
    </MDDialog>

  );
}


export default PDFStyle
