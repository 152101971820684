import {useState, useEffect} from "react";
import styles from "../../scheduler/Scheduler.module.css";
import {Grid} from '@mui/material';

import AdminPanelLayout from "components/AdminPanelLayout";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MixedChart from "examples/Charts/MixedChart";
import LineCharts from "examples/Charts/LineCharts/DefaultLineChart";
import {analitycsInitialData} from "../data/analitycs";
import "../styles.css"

//icons
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

import {useStores} from "models";
import MonthsSwitch from "../components/MonthsSwitch";
import Percents from "../components/Percents";

// Utils
import {showMessage} from "services/helpers";


export default function AdminPanel() {
  const rootStore = useStores()
  const {loginStore} = rootStore;
  const [loading, setLoading] = useState(false);
  const [analyticsData, setAnalyticsData] = useState(analitycsInitialData);
  const {new_users: newUsers, new_jobs: newJobs, 
  completed_jobs: completedJobs, new_expenses: newExpenses,
  jobs_total: jobsTotal, jobs_ongoing: jobsOngoing, 
  jobs_unfinished: jobsUnfinished, jobs_statistics: jobsStatistics} = analyticsData

  const getAnalyticsData = () => {
    setLoading(true);
    loginStore.environment.api.getAnalyticsData()
    .then((result) => {
      if (result.kind === "ok") {
        setAnalyticsData(result.data);
      } else {
        if (result.kind === "bad-data") {
          showMessage(result?.errors, 'error', true);
        } else {
          showMessage('An error occurred, please try again later');
        }
      }
    })
    .finally(() => setLoading(false))
  }

  const getJobsStatisticsData = () => {
    const {daily} = jobsStatistics;
    let chartData = {};
    const labels = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    chartData.labels = labels;
    chartData.datasets = [{
        chartType: "thin-bar",
        label: "Projects",
        color: "warning",
        data: labels.map((label, lidx) => daily[label.toLowerCase()].total),
      },
      {
        chartType: "thin-bar",
        label: "Jobs",
        color: "success",
        data: labels.map((label, lidx) => daily[label.toLowerCase()].ongoing),
      },
    ]
    return chartData
  }

  useEffect(() => {
    getAnalyticsData();
  }, []);

  return (
    <AdminPanelLayout title={"Analytics"} >
      <Grid container alignItems="top" pb={1} flexWrap='nowrap' overflow='auto' spacing={{xs: 35, lg: 15, xl: 25, xxl: 3}}>
        <Grid item xs={3} className={styles.leftContainer}>
          <MDBox className="admin-anlitycs-header success">
            <MDAvatar className="icons success"/>
            <h2>{newUsers}</h2>
            <p>New Users</p>
          </MDBox>
        </Grid>
        <Grid item xs={3} className={styles.leftContainer}>
          <MDBox className="admin-anlitycs-header info">
            <MDAvatar className="icons info"/>
            <h2>{newJobs}</h2>
            <p>New Jobs</p>
          </MDBox>
        </Grid>
        <Grid item xs={3} className={styles.leftContainer}>
          <MDBox className="admin-anlitycs-header danger">
            <MDAvatar className="icons danger"/>
            <h2>{completedJobs}</h2>
            <p>Completed Jobs</p>
          </MDBox>
        </Grid>
        <Grid item xs={3} className={styles.leftContainer}>
          <MDBox className="admin-anlitycs-header warning">
            <MDAvatar className="icons warning"/>
            <h2>{newExpenses}</h2>
            <p>Expenses</p>
          </MDBox>
        </Grid>
      </Grid>
      <MDTypography variant="h4" fontWeight="medium" my={2} ml={2}>Job Statistics</MDTypography>
      <Grid container alignItems="top" paddingTop={0} marginTop={0} px={2}>
        <Grid item xs={12} mb={3} px={4} className="container" pt={4}>
          <MonthsSwitch view={"daily"}/>
          <MDBox mb={6}>
            <Grid container spacing={3} p={'34px 0 0 0 !important'}>
              <Grid item xs={6} md={6} >
                <MDBox className="contents" p={{xs: '34px 0', md: '34px'}}>
                  <div className="images"><Percents perc={jobsTotal} colors="#60A77C"/></div>
                  <div className="texts">
                    <h3>{jobsTotal}</h3>
                    <p>Total jobs</p>
                  </div>
                </MDBox>
              </Grid>
              <Grid item xs={3} md={3}>
                <MDBox className="contents" p={{xs: '34px 0', md: '34px'}}>
                  <div className="images"><Percents perc={jobsOngoing} colors="#F88C2B"/></div>
                  <div className="texts">
                   <h3>{jobsOngoing}</h3>
                   <p>On Going</p>
                  </div>
                </MDBox>
              </Grid>
              <Grid item xs={3} md={3}>
                <MDBox className="contents" p={{xs: '34px 0', md: '34px'}}>
                  <div className="images"><Percents perc={jobsUnfinished} colors="#DD816B"/></div>
                  <div className="texts">
                    <h3>{jobsUnfinished}</h3>
                    <p>Unfinished</p>
                  </div>
                </MDBox>
              </Grid>
              <Grid item xs={12} md={12}>
                <MixedChart chart={getJobsStatisticsData()}/>
              </Grid>
            </Grid>
          </MDBox>
        </Grid>
      </Grid>

      <MDTypography variant="h4" fontWeight="medium" my={2} ml={2}>
        Job Completion Rate
      </MDTypography>
      <Grid container alignItems="top" paddingTop={0} marginTop={0} px={2}>
        <Grid item xs={12} mb={3} className="container" pt={4}>
          <MDBox mb={6}>
            <LineCharts
              height="19.75rem"
              chart={getJobsStatisticsData()}
            />
          </MDBox>
        </Grid>
      </Grid>
    </AdminPanelLayout>
  )
}
