import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Chip from '@mui/material/Chip';
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import { navbarIconButton } from "examples/Navbars/DashboardNavbar/styles";
import CheckIcon from '@mui/icons-material/Check';
import MDButton from "../../../components/MDButton";
import MDBox from "components/MDBox";
import MDTypography from "../../../components/MDTypography";
import DeleteIcon from 'assets/images/icons/delete.png';
import ImageRatio from 'assets/images/ImageRatio.png';
import "./tables.css";

const defaultHeader = [
  { label: 'ID', accesor: 'id' },
  { label: 'Number', accesor: 'number' },
  { label: 'Name (A-Z)', accesor: 'name' },
  { label: 'Category', accesor: 'category' },
  { label: 'Quantity', accesor: 'price' },
  { label: 'Price', accesor: 'action' },
  { label: 'Amount', accesor: 'qty' },
]


export const Tables = (props) => {
  const { data, current, header } = props;
  const hideDelete = props.hideDelete || false

  return (
    current === 'grid' ? (<>
      {data.map((row, i) => (<MDBox className="gridView">
        <img src={ImageRatio} />
        <ul>
          <li>
            <span style={{ float: 'left' }}>I100 • Item Name</span>
            <span style={{ float: 'right', color: '#60A77C' }}>Category Name</span>
          </li>
          <br />
          <li>
            <span style={{ float: 'left' }}>Quantity: 100</span>
            <span style={{ float: 'right' }}>Price: $30.00</span>
          </li>
          <br />
        </ul>

      </MDBox>))}
    </>) : (<>
      <TableContainer>
        <Table>
          <TableBody>
            {header
              ? <TableRow className="t_header_tr">
                {header.map((h, key) => <TableCell key={`h_${key}`} className="t_header">{h.label}</TableCell>)}
                <TableCell className="t_header"></TableCell>
              </TableRow>
              : <TableRow className="t_header_tr">
                {defaultHeader.map((h, key) => <TableCell key={`h_${key}`} className="t_header">{h.label}</TableCell>)}
                <TableCell className="t_header"></TableCell>
              </TableRow>
            }
            {data.map((row, i) => (
              <TableRow
                key={i}
                className="t_body_tr"
              >
                {header
                  ? header.map((d, ii) => <TableCell key={`d_${ii}`} className="names">{row[d.accesor]}</TableCell>)
                  : defaultHeader.map((d, ii) => <TableCell key={`d_${ii}`} className="names">{row[d.accesor]}</TableCell>)
                }
                {!hideDelete &&
                  <TableCell>
                    <MDButton color="error" iconOnly><img src={DeleteIcon} /></MDButton>
                  </TableCell>
                }
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>));
}
