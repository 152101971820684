
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useIsMobile } from "services/hooks";
import MDButton from "components/MDButton";
function HomeCard({ color, title, info, icon, icon_navigate, iconColor }) {

  const iconStyle = { width: 50, minWidth: 50, height: 50, borderRadius: 8, margin: 5 }


  return (
    <Card sx={{ minWidth: { xs: '255px' } }}>
      <MDBox borderRadius={'10px 10px 0px 0px'} bgColor={color} display="flex" alignItems="center" justifyContent="space-between" pt={1} px={3} height="5rem" >
        <MDBox textAlign="right" >
          <MDTypography variant="h4">{title}</MDTypography>
        </MDBox>
        <MDButton
          size="large"
          useBrandingColor={'primary'}
          onClick={icon_navigate}
          style={iconStyle}
        >
          <Icon className="material-icons-outlined" fontSize="large" style={{ color: 'white' }}>
            {icon}
          </Icon>
        </MDButton>
      </MDBox>
      {/* <Divider /> */}
      <MDBox pb={2} px={1} mt={3}>
        {info.map((i, key) => (
          <MDBox key={key + '_info'} borderRadius={'10px 10px 0px 0px'} display="flex" alignItems="center" justifyContent="space-between" pt={1} px={2} height="5rem" >
            <MDTypography component="p" variant="button" color="text" display="flex" flexDirection="column" mt={1}>
              <MDTypography
                component="span"
                variant="100"
                fontWeight="500"
              // color={percentage.color}
              >
                {i.label}
              </MDTypography>
              <MDTypography
                component="span"
                fontWeight="bold"
                variant="h2"
                color={i.color}
              >
                {i.amount}
              </MDTypography>
            </MDTypography>
            {i.icon &&
              <MDButton
                size="large"
                useBrandingColor={'primary'}
                onClick={i.icon_navigate}
                style={iconStyle}
              >
                {i.icon}
              </MDButton>
            }
          </MDBox>
        ))}
      </MDBox>
    </Card>
  );
}

// Setting default values for the props of HomeCard
HomeCard.defaultProps = {
  color: "info",
  percentage: {
    color: "success",
    text: "",
    label: "",
  },
};

// Typechecking props for the HomeCard
HomeCard.propTypes = {
  color: PropTypes.string,
  title: PropTypes.string,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  percentage: PropTypes.shape({
    color: PropTypes.string,
    amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
  }),
  icon: PropTypes.node,
};

export default HomeCard;
