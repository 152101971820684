import { useEffect, useState, useRef } from "react";
import { Grid, InputAdornment, IconButton } from '@mui/material';
import AdminLayout from "components/AdminLayout";
import MDTypography from "../../../components/MDTypography";
import MDInput from "../../../components/MDInput";
import MDButton from "../../../components/MDButton";
// styles
import '../styles.css';
//icons
import RightIcon from 'assets/images/icons/right-white.png';
import MDCheckbox from "components/MDCheckbox";
import ColorPicker from "../components/ColorPicker";
import PDFStyle from "../components/PDFStyle";
import UploadIcon from 'assets/images/icons/upload.png';

import { useStores } from "models";
import { runInAction } from "mobx";
import { showMessage, useApi } from "services/helpers";

const imageMimeType = /image\/(png|jpg|jpeg)/i;

export default function Branding() {
    const rootStore = useStores()
    const api = useApi();
    const { loginStore } = rootStore;
    const [fileDataURL, setFileDataURL] = useState(null);
    const [theme, setTheme] = useState('primary');
    const [extraInputs, setExtraInputs] = useState([]);
    const [social, setSocial] = useState([]);

    const [PDFStyleOpen, setPDFStyleOpen] = useState(false);

    const [values, setValues] = useState({
        company: loginStore.company_id,
        logo: null,
        pdf: "",
        name_on_logo: false,
        social: [],
        primary: [],
        secondary: [],
        tertiary: [],
        quaternary: []
    });


    useEffect(() => {
        if (loginStore.branding_data) {
            // logo preview
            if (loginStore.branding_data.logo) setFileDataURL(loginStore.branding_data.logo);
            // social
            if (loginStore.branding_data.social) {
                let socials = JSON.parse(loginStore.branding_data.social);
                setSocial(socials);
                Object.keys(socials).forEach((s, i) => {
                    if (i > 1) setExtraInputs({ ...extraInputs, ['social']: i })
                })
            }
            // values
            setValues({
                ...values,
                name_on_logo: loginStore.branding_data.name_on_logo,
                primary: JSON.parse(loginStore.branding_data?.primary),
                secondary: JSON.parse(loginStore.branding_data?.secondary),
                tertiary: JSON.parse(loginStore.branding_data?.tertiary),
                quaternary: JSON.parse(loginStore.branding_data?.quaternary),
                company: loginStore.company
            })
        }
    }, []);

    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        if (!file.type.match(imageMimeType)) {
            alert("Image type is not valid");
            return;
        }
        setValues({ ...values, ['logo']: e.target.files[0] });
        if (file) {
            const fileReader = new FileReader();
            fileReader.onload = (e) => {
                const { result } = e.target;
                if (result) setFileDataURL(result)
            }
            fileReader.readAsDataURL(file);
        }
    };

    const hanldeChangeLogo = (e) => setFileDataURL(null)

    const handleTheme = (e) => {
        setValues({
            ...values,
            [theme]: { hex: e.hex, rgb: e.rgb }
        })
    }

    const addInput = (field) => {
        let index = extraInputs[field] !== undefined ? extraInputs[field] + 1 : 2;
        setExtraInputs({ ...extraInputs, [field]: index })
    }

    const handleSubmit = () => {
        const data = {
            pdf: values.pdf,
            name_on_logo: values.name_on_logo,
            social: JSON.stringify(social),
            primary: JSON.stringify(values.primary),
            secondary: JSON.stringify(values.secondary),
            tertiary: JSON.stringify(values.tertiary),
            quaternary: JSON.stringify(values.quaternary),
            company: values.company
        }

        if (loginStore?.branding_data?.id) data['id'] = loginStore.branding_data.id
        // dont send if is not a new file
        if (values?.logo?.name) data.logo = values?.logo

        api.updateBranding(data).then((result) => {
            if (result.kind === 'ok') {
                loginStore.setBranding(result.response)
                if (values?.logo?.name) window.location.reload()
                showMessage('The company has been updated', 'success')
            } else showMessage(result?.errors, 'error', true)
        })
    }
    return (
        <AdminLayout title={"Branding"}>
            <Grid container alignItems="top" paddingTop={0} marginTop={0}>
                <Grid item xs={12} lg={9} md={9} className="leftContainer">
                    <MDTypography useBrandingColor={'tertiary'} variant="h2" fontWeight="medium" mb={1}>
                        Branding
                    </MDTypography>
                </Grid>
            </Grid>

            <Grid container spacing={3} pb={3} className="task_container">

                <Grid item xs={6} lg={6} md={6}>
                    <MDTypography variant="h6" fontWeight="bold" style={{ float: 'left' }}>
                        Add Company Logo
                    </MDTypography>
                    {fileDataURL && <MDTypography
                        color="green"
                        variant="h6"
                        fontWeight="bold"
                        style={{ float: 'right', paddingRight: 30, cursor: 'pointer' }}
                        onClick={hanldeChangeLogo}
                    >
                        <u>Change</u>
                    </MDTypography>}
                    {fileDataURL && <img
                        src={fileDataURL}
                        style={{
                            width: '100%', height: 136,
                            background: '#FFF5F3',
                            borderRadius: 8,
                            border: '1px solid #DD816B',
                            objectFit: 'scale-down',
                            objectPosition: 'center'
                        }}
                    />}
                    {!fileDataURL && <label htmlFor="upload-photo">
                        <input
                            style={{ display: 'none' }}
                            id="upload-photo"
                            name="upload-photo"
                            type="file"
                            accept="image/*"
                            onChange={e => handleImageUpload(e)}
                        />
                        <MDButton
                            sx={{ backgroundColor: '#fff' }}
                            component="span"
                            className="upload-button"
                            fullWidth
                        >
                            Upload <img src={UploadIcon} style={{ paddingLeft: 16 }} />
                        </MDButton>
                    </label>}
                </Grid>



                <Grid item xs={6} lg={6} md={6}>
                    <MDTypography variant="h6" fontWeight="bold">
                        PDF Style
                    </MDTypography>
                    <MDButton
                        sx={{ backgroundColor: '#fff' }}
                        component="span"
                        className="upload-button"
                        fullWidth
                        onClick={() => setPDFStyleOpen(true)}
                    >
                        Upload <img src={UploadIcon} style={{ paddingLeft: 16 }} />
                    </MDButton>
                </Grid>

                <Grid item xs={6} lg={6} md={6}>
                    <MDTypography variant="h6" fontWeight="bold" style={{ float: 'left', paddingLeft: 10 }}>
                        Show company name alongside logo
                    </MDTypography>
                    <MDCheckbox
                        alignment={"right"}
                        checked={values.name_on_logo}
                        onChange={evt => setValues({ ...values, ['name_on_logo']: evt.target.checked })}
                    />
                </Grid>

                <Grid item xs={12} lg={12} md={12}>
                    <hr className="MuiDivider-root MuiDivider-fullWidth" style={{ background: '#C6C9CE', margin: '12px 0' }} />
                </Grid>


                <Grid item xs={12} lg={12} md={12}>
                    <MDButton color="green" useBrandingColor={'primary'} style={{ float: 'right' }} onClick={() => addInput('social')}>
                        Add Another +
                    </MDButton>
                </Grid>

                <Grid item xs={6} lg={6} md={6}>
                    <MDTypography variant="h6" fontWeight="bold">
                        Social Account 1
                    </MDTypography>
                    <MDInput
                        useBrandingColor={'secondary'}
                        fullWidth
                        placeholder='https://www.facebook.com/username'
                        name="social"
                        value={social[0]}
                        onChange={(e) => setSocial({ ...social, [0]: e.target.value })}
                    />
                </Grid>
                <Grid item xs={6} lg={6} md={6}>
                    <MDTypography variant="h6" fontWeight="bold">
                        Social Account 2
                    </MDTypography>
                    <MDInput
                        useBrandingColor={'secondary'}
                        fullWidth
                        placeholder='copy social account URL here'
                        name="social2"
                        value={social[1]}
                        onChange={e => setSocial({ ...social, [1]: e.target.value })}
                    />
                </Grid>

                {[...Array(extraInputs.social)].map((item, i) => i > 0 && (
                    <Grid item xs={12} lg={6} md={6}>
                        <MDTypography variant="h6" fontWeight="bold">
                            Social Account {i + 2}
                        </MDTypography>
                        <MDInput
                            useBrandingColor={'secondary'}
                            key={i}
                            fullWidth
                            placeholder={`input email address #${i + 2}`}
                            value={social[i + 1]}
                            onChange={e => setSocial({ ...social, [i + 1]: e.target.value })}
                        />
                    </Grid>
                ))}

                <Grid item xs={12} lg={12} md={12} mt={10} display="flex" justifyContent="center" alignItems="center">

                    <MDButton
                        color="green"
                        useBrandingColor={'primary'}
                        type="submit"
                        className="btn-save margins"
                        onClick={() => handleSubmit()}
                    >
                        Save Changes
                    </MDButton>
                </Grid>
            </Grid>

            <Grid container alignItems="top" paddingTop={0} marginTop={0}>
                <Grid item xs={12} lg={9} md={9} className="leftContainer">
                    <MDTypography useBrandingColor={'tertiary'} variant="h2" fontWeight="medium" mb={1}>
                        Colors
                    </MDTypography>
                </Grid>
            </Grid>

            <Grid container spacing={3} pb={3} className="task_container">
                <Grid item xs={12} sm={12} md={12} lg={3}>

                    <MDButton color={theme === 'primary' ? "greenDark" : "green"} useBrandingColor={'primary'} style={{ width: 200, marginBottom: 16 }} onClick={(e) => setTheme('primary')}>
                        Buttons Color <img src={RightIcon} style={{ paddingLeft: 10 }} />
                    </MDButton>
                    <MDButton color={theme === 'secondary' ? "greenDark" : "green"} useBrandingColor={'primary'} style={{ width: 200, marginBottom: 16 }} onClick={(e) => setTheme('secondary')}>
                        Inputs Color <img src={RightIcon} style={{ paddingLeft: 10 }} />
                    </MDButton>
                    <MDButton color={theme === 'tertiary' ? "greenDark" : "green"} useBrandingColor={'primary'} style={{ width: 200, marginBottom: 16 }} onClick={(e) => setTheme('tertiary')}>
                        Titles Color <img src={RightIcon} style={{ paddingLeft: 10 }} />
                    </MDButton>
                    <MDButton color={theme === 'quaternary' ? "greenDark" : "green"} useBrandingColor={'primary'} style={{ width: 200, marginBottom: 16 }} onClick={(e) => setTheme('quaternary')}>
                        Event Color <img src={RightIcon} style={{ paddingLeft: 10 }} />
                    </MDButton>
                </Grid>

                <Grid item xs={12} lg={9} md={12} style={{ paddingLeft: 0 }}>
                    <ColorPicker onChange={handleTheme} theme={theme} current={values[theme]} />
                </Grid>
            </Grid>

            <PDFStyle open={PDFStyleOpen} handleClose={() => setPDFStyleOpen(false)} />
        </AdminLayout>
    )
}

// xs, extra-small: 0px
// sm, small: 600px
// md, medium: 900px
// lg, large: 1200px
// xl, extra-large: 1536px