import { useEffect, useState } from "react";
import moment from 'moment'
import { Grid, InputAdornment, IconButton } from '@mui/material';
import AdminPanelLayout from "../../../components/AdminPanelLayout";
import { useNavigate, useParams } from "react-router-dom";
import MDBox from "../../../components/MDBox";
import MDDialog from "../../../components/MDDialog";
import MDTypography from "../../../components/MDTypography";
import MDInput from "../../../components/MDInput";
import MDButton from "../../../components/MDButton";
import MDDatePicker from "../../../components/MDDatePicker";
import pxToRem from "../../../assets/theme/functions/pxToRem";
import { ROUTES } from 'constants'
import "../styles.css"
// icons
import UserIcon from "assets/images/icons/user_back.png";
import InventoryIcon from "assets/images/icons/inventory.png";
import ClockIcon from "assets/images/icons/clock.png";
import SubscriptionIcon from "assets/images/icons/subscriptions.png";
import { useStores } from "models";
import { showMessage } from "services/helpers";
import {userDetailInitial} from "../data/userDetails";

export default function UserDetails(props) {
    const navigate = useNavigate();
    const { id } = useParams();
    const rootStore = useStores();
    const { loginStore } = rootStore;
    const [UserDetail, setUserDetail] = useState(userDetailInitial);
    const [openApproveModal, setOpenApproveModal] = useState(false);
    const [openDenyModal, setOpenDenyModal] = useState(false);
    const [openMoreInfoModal, setOpenMoreInfoModal] = useState(false);    
    const [moreInfoEmail, setMoreInfoEmail] = useState("");
    
    const getUserDetails = (id) => {
        loginStore.environment.api.getUserAdmin(id).then((result) => {
            if (result.kind === "ok") {
                setUserDetail(result.data);
            } else {
                if (result.kind === "bad-data") 
                showMessage(result?.errors, 'error', true);
                else showMessage('An error occurred, please try again later');
            }
        });
    }
    
    const setUserApproval = (approved) => {
         loginStore.environment.api.approvalUserAdmin(id, approved).then((result) => {
            if (result.kind === "ok") {
                showMessage(`User ${!!approved ? "approved" : "denied"} successfully`, 'success');
            } else {
                if (result.kind === "bad-data") 
                showMessage(result?.errors, 'error', true);
                else showMessage('An error occurred, please try again later');
            }
        });
    }
    
    const sendInfoEmail = (id, message) => {
        loginStore.environment.api.sendMoreInfoEmail(id, message).then((result) => {
            if (result.kind === "ok") {
                showMessage("Email sent succesfully", 'success');
                setOpenMoreInfoModal(false);
                setMoreInfoEmail("");
            } else {
                if (result.kind === "bad-data") 
                showMessage(result?.errors, 'error', true);
                else showMessage('An error occurred, please try again later');
            }
        });
    }
    
    const printAddress = () => {
        const {address_1, address_2, city_name, state_name} = UserDetail;
        let address1 = "N/A", address2= "N/A", city = "N/A", state = "N/A";
        if (address_1)
            address1 = address_1;
        if (address_2)
            address2 = address_2;
        if (city_name)
            city = city_name;
        if (state_name)
            state = state_name;
        return <div>
            <div>{`${address1}, ${address2}, ${city}`}</div>
            <div>{`${state}`}</div>
        </div>
    }
    
    const approveUser = () => {
        setUserApproval(true);
        setOpenApproveModal(false);
    }
    
    const denyUser = () => {
        setUserApproval(false);
        setOpenDenyModal(false);
    }
    
    const sendMoreInfoEmail = () => {
        if (moreInfoEmail.length > 0) {
            sendInfoEmail(id, moreInfoEmail);
        } else {
            showMessage("A message for the email is required.", "error")
        }
    }
    
    useEffect(()=> {
        getUserDetails(id);
    }, [])

    return (
    <AdminPanelLayout title={"Back to Approve Accounts"} url={ROUTES.ADMIN_USERS_APPROVE_ACCOUNTS}>
        <Grid container spacing={3} pl={5} pr={4} mt={1}>
            <MDBox sx={{width: '100%', display: 'flex', justifyContent: 'space-between',  alignItems: 'center'}}>
                <MDTypography variant="h3" fontWeight="medium" mb={1} >
                    {UserDetail.full_name}
                </MDTypography>
                <MDBox sx={{display: 'flex', alignItems: 'center', gap: 3}}>
                <MDButton onClick={() => setOpenApproveModal(true)} color="green">
                    Approve
                </MDButton>
                <MDButton onClick={() => setOpenDenyModal(true)} className="btn danger">
                    Deny
                </MDButton>
                <MDButton className="btn info" onClick={() => setOpenMoreInfoModal(true)}>
                    Need more info
                </MDButton>
                </MDBox>
            </MDBox>
        </Grid>        
        <MDBox pl={5} pr={4} mt={5}>
            <Grid container spacing={3} className="container" px={2} pt={1} pb={3}>
                <Grid item xs={12} md={6} lg={3} >
                    <MDTypography variant="h6" mb={1}>Address</MDTypography>
                    <MDTypography className="p-variant">{printAddress()}</MDTypography>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <MDTypography variant="h6"  mb={1}>Contact</MDTypography>
                    <MDTypography className="p-variant">{UserDetail.email || "No email"} <br /> {UserDetail.phone_number || "No phone number"}</MDTypography>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <MDTypography variant="h6" mb={1}>Industry</MDTypography>
                     <MDTypography className="p-variant">{UserDetail.industry}</MDTypography>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <MDTypography variant="h6" mb={1}>Bank Account Information</MDTypography>
                    <MDTypography className="p-variant">Lorem ipsum Lorem ipsum</MDTypography>
                </Grid>
            </Grid>
        </MDBox>
        {/* Modals */}
        <MDDialog
            open={openApproveModal}
            handleClose={() => setOpenApproveModal(false)}
            title={'Approve user'}
            titleStyles={{fontSize: pxToRem(24)}}
            style={styles.modalDialog}
        >
            <MDTypography
            variant="h6"
            sx={styles.modalText}
            >
            Are you sure you want to approve this user?
            </MDTypography>

            <MDBox
            sx={{
                display: 'flex',
                gap: pxToRem(24),
                marginTop: pxToRem(46),
            }}
            >
            <MDButton
                useBrandingColor={'primary'}
                color="green"
                variant="outlined"
                type="submit"
                className={"button-action"}
                onClick={() => setOpenApproveModal(false)}
            >
                Cancel
            </MDButton>

            <MDButton
                useBrandingColor={'primary'}
                color="error"
                type="submit"
                className={"button-action"}
                onClick={approveUser}
            >
                Yes
            </MDButton>
            </MDBox>
        </MDDialog>
        <MDDialog
            open={openDenyModal}
            handleClose={() => setOpenDenyModal(false)}
            title={'Deny user'}
            titleStyles={{fontSize: pxToRem(24)}}
            style={styles.modalDialog}
        >
            <MDTypography
            variant="h6"
            sx={styles.modalText}
            >
            Are you sure you want to deny this user?
            </MDTypography>

            <MDBox
            sx={{
                display: 'flex',
                gap: pxToRem(24),
                marginTop: pxToRem(46),
            }}
            >
            <MDButton
                useBrandingColor={'primary'}
                color="green"
                variant="outlined"
                type="submit"
                className={"button-action"}
                onClick={() => setOpenDenyModal(false)}
            >
                Cancel
            </MDButton>

            <MDButton
                useBrandingColor={'primary'}
                color="error"
                type="submit"
                className={"button-action"}
                onClick={denyUser}
            >
                Yes
            </MDButton>
            </MDBox>
        </MDDialog>        
        <MDDialog
            open={openMoreInfoModal}
            handleClose={() => setOpenMoreInfoModal(false)}
            title={'Need more info'}
            titleStyles={{fontSize: pxToRem(24)}}
            style={styles.modalDialog}
        >
            <MDTypography
            variant="h6"
            sx={styles.modalText}
            >
                Request for Additional Information
            </MDTypography>
            
            <MDInput
                type="text"
                value={moreInfoEmail}
                style={{ width: '100%', marginRight: 15, backgroundColor: 'white', border: '1px solid lightgrey' }}
                onChange={(e) => setMoreInfoEmail(e.target?.value)}
            />

            <MDBox
                sx={{
                    display: 'flex',
                    gap: pxToRem(24),
                    marginTop: pxToRem(46),
                }}
            >
            <MDButton
                useBrandingColor={'primary'}
                color="green"
                variant="outlined"
                type="submit"
                className={"button-action"}
                onClick={() => setOpenMoreInfoModal(false)}
            >
                Cancel
            </MDButton>

            <MDButton
                useBrandingColor={'primary'}
                color="error"
                type="submit"
                className={"button-action"}
                onClick={sendMoreInfoEmail}
                disabled={moreInfoEmail.length < 1}
            >
                Send
            </MDButton>
            </MDBox>
        </MDDialog>        
    </AdminPanelLayout>
    )
}

const styles = {
    modalDialog: {
        width: '100%',
        paddingBlock: `${pxToRem(10)} ${pxToRem(30)}`,
        paddingInline: pxToRem(50),
    },
    modalText: {
        color: 'black',
        fontWeight: 600,
        marginLeft: pxToRem(16),
        textAlign: 'center',
    }
}
