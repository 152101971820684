import * as Yup from 'yup';

export const jobSectionModel = {
  section_name: '',
  section_items: []
}

export const jobSectionItemModel = {
  type: '',
  label: '',
  value: '',
}

export const getJobCheckListValues = (data) => ({
    company_name: data ? data.company_name : '',
    phone_number: data ? data.phone_number :  '',
    email: data ? data.email : '',
    website: data ? data.website : '',
    address: data ? data.address :  '',
    timezone: data ? data.timezone :  '',
});

export const jobCheckListValidationSchema = Yup.object().shape({
  company_name: Yup.string(),
  phone_number: Yup.string(),
  email: Yup.string(),
  website: Yup.string(),
  address: Yup.string(),
});