import { useEffect, useState } from "react";
import moment from 'moment'
import { Grid, Box, InputAdornment, IconButton } from '@mui/material';
import AdminPanelLayout from "../../../components/AdminPanelLayout";
import { useNavigate, useParams } from "react-router-dom";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import MDInput from "../../../components/MDInput";
import MDButton from "../../../components/MDButton";
import MDDatePicker from "../../../components/MDDatePicker";
import MDDialog from "../../../components/MDDialog";
import { useStores } from "models";
import { showMessage } from "services/helpers";
import {userDetailInitial} from "../data/userDetails";
import pxToRem from "../../../assets/theme/functions/pxToRem";
import { ROUTES } from 'constants'
import "../styles.css"
// icons
import UserIcon from "assets/images/icons/user_back.png";
import InventoryIcon from "assets/images/icons/inventory.png";
import ClockIcon from "assets/images/icons/clock.png";
import SubscriptionIcon from "assets/images/icons/subscriptions.png";


export default function UserDetails(props) {
    const navigate = useNavigate();
    const { id } = useParams();
    const rootStore = useStores();
    const { loginStore } = rootStore;
    const [UserDetail, setUserDetail] = useState(userDetailInitial);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [openAddTrialDaysModal, setOpenAddTrialDaysModal] = useState(false);
    const [extraTrialDays, setExtraTrialDays] = useState(null);
    
    const getUserDetails = (id) => {
        loginStore.environment.api.getUserAdmin(id).then((result) => {
            if (result.kind === "ok") {
                setUserDetail(result.data);
            } else {
                if (result.kind === "bad-data") 
                showMessage(result?.errors, 'error', true);
                else showMessage('An error occurred, please try again later');
            }
        });
    }
    
    const printAddress = () => {
        const {address_1, address_2, city_name, state_name} = UserDetail;
        let address1 = "N/A", address2= "N/A", city = "N/A", state = "N/A";
        if (address_1)
            address1 = address_1;
        if (address_2)
            address2 = address_2;
        if (city_name)
            city = city_name;
        if (state_name)
            state = state_name;
        return <div>
            <div>{`${address1}, ${address2}, ${city}`}</div>
            <div>{`${state}`}</div>
        </div>
    }
    
    const deleteUser = () => {
        loginStore.environment.api.deleteUserAdmin(id).then((result) => {
            if (result.kind === "ok") {
                navigate(ROUTES.ADMIN_USERS);
                showMessage("User deleted successfully", 'success');
            } else {
                if (result.kind === "bad-data") showMessage(result?.errors, 'error', true);
                else showMessage('An error occurred, please try again later');
            }
        });        
    }
    
    const addTrialDays = () => {
        console.log("addTrialDays...", extraTrialDays);
        const days = Number(extraTrialDays);
        if (days > 90) {
            showMessage("You can only add up to 90 days", 'error');
            return;
        }
        //        
        loginStore.environment.api.addTrialDays(UserDetail.company, days).then((result) => {
            if (result.kind === "ok") {
                setOpenAddTrialDaysModal(false);
                setExtraTrialDays(null);
                showMessage("Trial days added successfully", 'success');
                getUserDetails(id);
            } else {
            if (result.kind === "bad-data") 
                showMessage(result?.errors, 'error', true);
            else showMessage('An error occurred, please try again later');
            }
        });        
    }
    
    useEffect(()=> {
        getUserDetails(id);
    }, [])
    //
    return (
    <AdminPanelLayout title={"Back to User List"} url={ROUTES.ADMIN_USERS}>
        <Box pl={3} pr={5}>
            <Grid container spacing={0} alignItems="center" justifyContent="space-between" mb={4}>
                <Grid item xs={6}>
                    <MDTypography variant="h3" fontWeight="medium" mb={1}>
                        {UserDetail.full_name}
                    </MDTypography>
                </Grid>
                <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'end' }}>
                    <MDButton color="error" onClick={() => setOpenDeleteModal(true)}>
                        Delete User
                    </MDButton>
                </Grid>
            </Grid>
        </Box>

        <Box px={5}>
            <Grid container spacing={3} className="container" px={2} pt={1} pb={3}>
                <Grid item xs={12} md={6} lg={3} >
                    <MDTypography variant="h6" mb={1}>Address</MDTypography>
                    <MDTypography className="p-variant">{printAddress()}</MDTypography>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <MDTypography variant="h6"  mb={1}>Contact</MDTypography>
                    <MDTypography className="p-variant">{UserDetail.email || "No email"} <br /> {UserDetail.phone_number || "No phone number"}</MDTypography>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <MDTypography variant="h6" mb={1}>Industry</MDTypography>
                     <MDTypography className="p-variant">{UserDetail.industry}</MDTypography>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <MDTypography variant="h6" mb={1}>Bank Account Information</MDTypography>
                    <MDTypography className="p-variant">Lorem ipsum Lorem ipsum</MDTypography>
                </Grid>
            </Grid>
        </Box>

        <Box pl={3} pr={5} mt={3}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={3}>
                    <div className="container-users-box">
                        <MDTypography variant="h6">Added Users</MDTypography>
                        <div className="users-box">
                            <img src={UserIcon} className="user-box-img"/>
                            <p>{UserDetail.added_users}</p>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <div className="container-users-box">
                        <MDTypography variant="h6">Inventory</MDTypography>
                        <div className="users-box">
                            <img src={InventoryIcon} className="user-box-img"/>
                            <p>{UserDetail.inventory}</p>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <div className="container-users-box">
                        <MDTypography variant="h6">Time spent</MDTypography>
                        <div className="users-box">
                            <img src={ClockIcon} className="user-box-img"/>
                            <p>{UserDetail.time_spent}h</p>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <div className="container-users-box">
                        <MDTypography variant="h6">Subscription</MDTypography>
                        <div className="users-box">
                            <img src={SubscriptionIcon} className="user-box-img"/>
                            {UserDetail.trial_days && <>
                                <p>{UserDetail.trial_days}</p>
                                <span>Trial days left</span>
                            </>}
                            {!UserDetail.trial_days && <>
                                <p>{UserDetail.subscription_days}</p>
                            </>}
                        </div>
                        <MDButton onClick={() => setOpenAddTrialDaysModal(true)} style={{marginTop: ".5rem"}} size="small" color="green">
                            Add trial days +
                        </MDButton>
                    </div>
                </Grid>
            </Grid>
        </Box>
        
        <MDDialog
            open={openDeleteModal}
            handleClose={() => setOpenDeleteModal(false)}
            title={'Delete user'}
            titleStyles={{fontSize: pxToRem(24)}}
            style={{
            width: '100%',
            paddingBlock: `${pxToRem(10)} ${pxToRem(30)}`,
            paddingInline: pxToRem(50),
            }}
        >
            <MDTypography
            variant="h6"
            sx={{
                color: 'black',
                fontWeight: 600,
                marginLeft: pxToRem(16),
                textAlign: 'center',
            }}
            >
            Are you sure you want to delete this user?
            </MDTypography>

            <MDBox
            sx={{
                display: 'flex',
                gap: pxToRem(24),
                marginTop: pxToRem(46),
            }}
            >
            <MDButton
                useBrandingColor={'primary'}
                color="green"
                variant="outlined"
                type="submit"
                className={"button-action"}
                onClick={() => setOpenDeleteModal(false)}
            >
                Cancel
            </MDButton>

            <MDButton
                useBrandingColor={'primary'}
                color="error"
                type="submit"
                className={"button-action"}
                onClick={deleteUser}
            >
                Yes
            </MDButton>
            </MDBox>
        </MDDialog>
        
        <MDDialog
            open={openAddTrialDaysModal}
            handleClose={() => setOpenAddTrialDaysModal(false)}
            title={'Add trial days'}
            titleStyles={{fontSize: pxToRem(24)}}
            style={{
            width: '100%',
            paddingBlock: `${pxToRem(10)} ${pxToRem(30)}`,
            paddingInline: pxToRem(50),
            }}
        >
             <MDInput
                type="number"
                value={extraTrialDays}
                style={{ width: 280, marginRight: 15, backgroundColor: 'white', border: '1px solid lightgrey' }}
                onChange={(e) => setExtraTrialDays(e.target?.value)}
                />

            <MDBox
            sx={{
                display: 'flex',
                gap: pxToRem(24),
                marginTop: pxToRem(46),
            }}
            >
            <MDButton
                disabled={!extraTrialDays}
                useBrandingColor={'primary'}
                color="error"
                type="submit"
                className={"button-action"}
                onClick={addTrialDays}
            >
                Add
            </MDButton>
            </MDBox>
        </MDDialog>

    </AdminPanelLayout>
    )
}
