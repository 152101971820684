import {useEffect, useState} from "react";
import 'react-calendar-timeline/lib/Timeline.css'
import moment from 'moment'
import {Grid, InputAdornment, IconButton} from '@mui/material';
import AdminLayout from "components/AdminLayout";
import {useNavigate, useParams} from "react-router-dom";
import styles from "../Scheduler.module.css";
import "./react-calendar-timeline.css"
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import FormikInput from "components/FormikInput";
import {Form, Formik} from "formik";
import MDAutocomplete from "components/MDAutocomplete";

import {useStores} from "models";
import {showMessage, dateRange} from "services/helpers";
import {getTaskInitialValues, taskValidationSchemaTask} from "./form";
import {ROUTES} from "constants";

const Repeats = [
    {value: 'NEVER', label: 'Never'},
    {value: 'DAILY', label: 'Daily'},
    {value: 'WEEK', label: 'Week'},
    {value: 'MONTH', label: 'Month'},
]

const Reminder = [
    {value: 'NONE', label: 'None'},
    {value: 'START', label: 'Start'},
    {value: 'HOUR', label: 'Hour'},
    {value: 'HOUR2', label: 'Hour2'},
    {value: 'HOUR5', label: 'Hour5'},
    {value: 'DAY', label: 'Day'},
]

export default function Task(props) {
    const navigate = useNavigate();
    const rootStore = useStores();
    const {id} = useParams();
    const {loginStore} = rootStore;

    let {dates, group} = useParams();

    const [Task, setTask] = useState(null);
    const [customFieldsInitialValues, setCustomFieldsInitialValues] = useState({});

    const [Jobs, setJobs] = useState([]);
    const [AssignToOptions, setAssignToOptions] = useState([]);
    const [Clients, setClients] = useState(false);


    const getEmployes = () => {
        loginStore.environment.api.getTeamMembers('')
            .then((result) => {
                const {data} = result
                const {results} = data
                setAssignToOptions(results)
            })
            .catch(() => showMessage())
    }
    const getClients = () => {
        loginStore.environment.api.getClients('')
            .then((result) => {
                const {data} = result
                const {results} = data
                setClients(results)
            })
            .catch(() => showMessage())
    }
    const getJobs = () => {
        loginStore.environment.api.getJobs('')
            .then((result) => {
                const {data} = result
                const {results} = data
                setJobs(results)
            })
            .catch(() => showMessage())
    }
    const getTask = () => {
        loginStore.environment.api.getTask(id)
            .then((result) => {
                setTask(result.data)
            })
            .catch(() => showMessage())
    }

    useEffect(() => {
        getEmployes()
        getClients()
        getJobs();
        if (id) getTask()
    }, [loginStore]);

    const createTask = (values) => {
        let data = {
            ...values,
            assign_to: values?.assign_to?.id,
            task_for: values?.task_for?.id,
            job: values?.job?.id,
            repeats: values?.repeats?.value,
            reminders: values?.reminders?.value
        }

        loginStore.environment.api.createTask(data).then((result) => {
            if (result.kind === "ok") {
                showMessage('The task has been created', 'success')
                navigate(ROUTES.HOME)
            } else {
                if (result.kind === "bad-data") showMessage(result?.errors, 'error', true)
                else showMessage('An error occurred, please try again later')
            }
        })
    }
    const editTask = (values) => {
        let data = {
            ...values,
            assign_to: values?.assign_to?.id,
            task_for: values?.task_for?.id,
            job: values?.job?.id,
            repeats: values?.repeats?.value,
            reminders: values?.reminders?.value
        }
        loginStore.environment.api.createTask(data).then((result) => {
            if (result.kind === "ok") {
                showMessage('The task has been updated', 'success');
                // navigate(ROUTES.REQUESTS)
            } else {
                if (result.kind === "bad-data") {
                    showMessage(result?.errors, 'error', true)
                }
            }
        }).catch(error => showMessage(error.message))
    }
    return (
        <AdminLayout title={"New Task"}>
            <Grid container alignItems="top" paddingTop={0} marginTop={0}>
                <Grid ml={5} item xs={12} lg={9} md={9} className={styles.leftContainer}>
                    <MDTypography variant="h2" fontWeight="medium">
                        Create New Task
                    </MDTypography>
                </Grid>
            </Grid>


            <Formik
                initialValues={Object.assign({}, getTaskInitialValues(Task), customFieldsInitialValues)}
                validationSchema={taskValidationSchemaTask}
                validateOnBlur={false}
                validateOnChange={false}
                onSubmit={values => !id ? createTask(values) : editTask(values)}
                enableReinitialize
            >
                {({errors, setFieldValue, values}) => {
                    console.log(' values ====>>>> ', { values })
                    return (
                        <Form>
                            <Grid container spacing={3} pb={2}  className="task_container">
                                <FormikInput
                                    useBrandingColor={'secondary'}
                                    name={'job'}
                                    label={'Job'}
                                    labelFieldName={'title'}
                                    type={'select'}
                                    placeholder={'select job'}
                                    setFieldValue={setFieldValue}
                                    options={Jobs}
                                    errors={errors}
                                    item xs={12} md={6} lg={6}
                                />
                                <FormikInput
                                    useBrandingColor={'secondary'}
                                    name={'task_for'}
                                    label={'Task For'}
                                    labelFieldName={'name'}
                                    type={'select'}
                                    placeholder={'select client'}
                                    setFieldValue={setFieldValue}
                                    // value={values.task_for}
                                    options={Clients}
                                    errors={errors}
                                    item xs={12} md={6} lg={6}
                                />
                                <FormikInput
                                    useBrandingColor={'secondary'}
                                    name={'title'}
                                    label={'Title'}
                                    placeholder={'input title'}
                                    errors={errors}
                                    item xs={12} md={6} lg={6}
                                />
                                <FormikInput
                                    useBrandingColor={'secondary'}
                                    name={'repeats'}
                                    label={'Repeats'}
                                    labelFieldName={'label'}
                                    type={'select'}
                                    placeholder={'select from dropdown'}
                                    setFieldValue={setFieldValue}
                                    options={Repeats}
                                    errors={errors}
                                    item xs={12} md={6} lg={6}
                                />
                                <FormikInput
                                    useBrandingColor={'secondary'}
                                    name={'instructions'}
                                    label={'Internal Notes & Attachments'}
                                    placeholder={'input internal note'}
                                    errors={errors}
                                    type={'textarea'}
                                    rows={3}
                                    item xs={12} md={6} lg={6}
                                />

                                <Grid item xs={12} lg={12} md={12}>
                                    <hr className="MuiDivider-root MuiDivider-fullWidth"
                                        style={{background: '#C6C9CE', margin: '32px 0'}}/>
                                </Grid>

                                <FormikInput
                                    fullWidth
                                    input={{placeholder: "Select date"}}
                                    label={'Start Date'}
                                    type='datetime-local'
                                    useBrandingColor={'secondary'}
                                    name='start_date'
                                    errors={errors}
                                    item xs={12} md={6} lg={6}
                                />
                                <FormikInput
                                    fullWidth
                                    input={{placeholder: "Select date"}}
                                    label={'End Date'}
                                    type='datetime-local'
                                    useBrandingColor={'secondary'}
                                    name='end_date'
                                    errors={errors}
                                    item xs={12} md={6} lg={6}
                                />

                                <Grid item xs={12} lg={12} md={12}>
                                    <hr className="MuiDivider-root MuiDivider-fullWidth"
                                        style={{background: '#C6C9CE', margin: '32px 0'}}/>
                                </Grid>

                                <FormikInput
                                    useBrandingColor={'secondary'}
                                    name={'assign_to'}
                                    label={'Assign to'}
                                    labelFieldName={'name'}
                                    type={'select'}
                                    placeholder='a team member will be assign to your request'
                                    setFieldValue={setFieldValue}
                                    options={AssignToOptions}
                                    errors={errors}
                                    item xs={12} lg={6} md={6}
                                />
                                <FormikInput
                                    useBrandingColor={'secondary'}
                                    name={'reminders'}
                                    label={'Set Reminders'}
                                    labelFieldName={'label'}
                                    type={'select'}
                                    placeholder='select from dropdown'
                                    setFieldValue={setFieldValue}
                                    options={Reminder}
                                    errors={errors}
                                    item xs={12} lg={6} md={6}
                                />

                                <Grid item xs={12} lg={12} md={12} display="flex" justifyContent="center"
                                      alignItems="center">
                                    <MDButton
                                      useBrandingColor={'primary'}
                                      color="green"
                                      type="submit"
                                      className={'btn-save-task'}
                                    >
                                        Create Task
                                    </MDButton>
                                </Grid>

                            </Grid>
                        </Form>
                    )
                }}
            </Formik>

        </AdminLayout>
    )
}
