import { useEffect, useState } from "react";
import {Link as RouteLink} from 'react-router-dom';
import moment from 'moment'
import { Grid, InputAdornment, IconButton } from '@mui/material';
import AdminLayout from "../../../components/AdminLayout";
import { useNavigate } from "react-router-dom";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import MDInput from "../../../components/MDInput";
import MDButton from "../../../components/MDButton";
import MDDatePicker from "../../../components/MDDatePicker";
import {Header} from "../components/Header";
import {Tables} from "../components/Tables";
import {DateFilter} from "../components/DateFilter";
import "../styles.css"

const header = [
    {title: 'Number', align: '', id: 1},
    {title: 'Name (A-Z)', align: '', id: 2},
    {title: 'Created', align: '', id: 3},
    {title: 'Due', align: '', id: 4},
    {title: 'Late by', align: '', id: 5},
    {title: 'Contact', align: '', id: 6}
];
const rows = [
    {number: 'IN1000', name: 'Jane Cooper', created: 'July 18, 2022', due: 'July 18, 2022', status: '30 days', total: 'Lorem Ipsum', id: 1},
    {number: 'IN1000', name: 'Jane Cooper', created: 'July 18, 2022', due: 'July 18, 2022', status: '50 days', total: 'Lorem Ipsum', id: 2},
    {number: 'IN1000', name: 'Jane Cooper', created: 'July 18, 2022', due: 'July 18, 2022', status: '20 days', total: 'Lorem Ipsum', id: 3},
    {number: 'IN1000', name: 'Jane Cooper', created: 'July 18, 2022', due: 'July 18, 2022', status: '30 days', total: 'Lorem Ipsum', id: 4},
    {number: 'IN1000', name: 'Jane Cooper', created: 'July 18, 2022', due: 'July 18, 2022', status: '30 days', total: 'Lorem Ipsum', id: 5},
    {number: 'IN1000', name: 'Jane Cooper', created: 'July 18, 2022', due: 'July 18, 2022', status: '50 days', total: 'Lorem Ipsum', id: 6},
    {number: 'IN1000', name: 'Jane Cooper', created: 'July 18, 2022', due: 'July 18, 2022', status: '30 days', total: 'Lorem Ipsum', id: 7},
    {number: 'IN1000', name: 'Jane Cooper', created: 'July 18, 2022', due: 'July 18, 2022', status: '20 days', total: 'Lorem Ipsum', id: 8},
  ];

const headers = [
    {title: 'Overdue by less than 30 days', quantity: '0', content: '$000', class:'', id: 1},
    {title: 'Overdue by 30 to 60 days', quantity: '1', content: '$000', id: 2},
    {title: 'Overdue by 60 to 90 days', quantity: '8', content: '$000', class:'', id: 3},
    {title: 'Overdue by greater than 90 days', quantity: '8', content: '$000', class:'', id: 4},
    {title: 'Late invoices', quantity: '8', content: '$000', class:'error', id: 5}
  ];

export default function AgedReceivables(props) {
    const navigate = useNavigate();

  return (

    <AdminLayout title={"Aged Receivables"}>
        <Grid container alignItems="top" paddingTop={0} marginTop={0}>
            <Grid item xs={12} lg={12} md={12} mb={3}>
                <MDTypography variant="h2" fontWeight="medium" mb={1} ml={3} style={{ float: 'left' }}>
                    Aged Receivables
                </MDTypography>
                <MDButton color="green" style={{ float: 'right', textTransform: 'none' }} component={RouteLink} to="/reports/projected_income">
                    View Projected Income Report
                </MDButton>
            </Grid>
           
            <Grid item xs={12} lg={12} md={12} mb={3}>
                <Header data={headers} styles={{ width: 200}}/>
            </Grid>
        </Grid>
    <Grid container spacing={3}>
        <Grid item xs={12} lg={12} md={12} ml={2}>
            <MDTypography variant="h3" fontWeight="medium" mb={1} style={{ float: 'left' }}>
                List
            </MDTypography>
            <DateFilter style={{ float: 'right', display: 'inline', marginBottom: 10 }} />
            <Tables body={rows} header={header}/>
        </Grid>
    </Grid>
    </AdminLayout>
  )
}
