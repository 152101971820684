import React from "react";
import { Grid } from '@mui/material';
import "../styles.css";
import SimpleTable from "components/SimpleTable";

const datatableModel = {
  columns: [
    { Header: "Name", accessor: "name", width: "25%" },
    { Header: "UCP Code", accessor: "ucp_code", width: "25%" },
    { Header: "Item Location", accessor: "item_location", width: "25%" },
    { Header: '', accessor: 'action', width: '25%' },
  ],
  rows: [],
  spacing: 15,
}

export default function ImportItemsTab(props) {

  const data = {
    ...datatableModel,
    rows: [...props.data],
  };


  return (
    <SimpleTable data={data} />
  )
}
