import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import MDBox from "../MDBox";
import { CardHeader } from "@mui/material";

export default function ThimeCard({ color, title, children }) {
  return (
    <Card sx={{ borderRadius: 5, backgroundColor: "white", marginBottom: 2 }}>
      <CardHeader
        title={title}
        titleTypographyProps={{ color: "blackLight", variant: 'p', fontSize: 20 }}
        style={{
          backgroundColor: color,
          borderTopLeftRadius: 16,
          borderTopRightRadius: 16,
          minHeight: 40,
          color: "black"
        }}>
      </CardHeader>
      <CardContent p={0}>
        {children}
      </CardContent>
    </Card>
  );
}
