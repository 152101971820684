import React, { useState } from 'react';
import {Link} from 'react-router-dom';
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import "./tables.css";


export const AddButton = ({...rest})=> {
  const [anchorEl, setAnchorEl] = useState(null);
  const [active, setActive] = useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setActive(true);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setActive(false);
  };

  return (
    <MDBox
      display='flex'
      justifyContent={{xs: 'flex-start', lg: 'flex-end'}}
      {...rest}
    >
      <MDButton
        useBrandingColor={'primary'}
        color="green"
        className={['btn-import-client', active && 'active'].join(" ")}
        component={Link}
        to="/invoices/new"
      > New Invoice + </MDButton>
    </MDBox>
  );
}
