import PropTypes from "prop-types";
import React from "react";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import moment from 'moment'

const Label = ({ label, drilldownView, onDrillDown }) => {
    let labels = label.split(" ");
    let istoday = false;
    if(moment().format('D') === labels[0]){
        istoday = true;
      }
  if (!drilldownView) {
    return (
      <>
        <MDTypography
          fontWeight={"bold"}
          style={{
            fontSize:24,
            color: istoday ? "var(--dark-green)" : "#303134",
          }}
        >
          {labels[0]}
        </MDTypography>

        <MDTypography fontWeight={istoday ? "bold" : "regular" } style={{fontSize:18, color: istoday ? "var(--dark-green)" : "var(--light-black)"}}>
          {labels[1]}
        </MDTypography>
      </>
    );
  }

  return <a onClick={onDrillDown}>{label}</a>;
};

Label.propTypes = {
  label: PropTypes.node,
  date: PropTypes.instanceOf(Date),
  drilldownView: PropTypes.string,
  onDrillDown: PropTypes.func,
  isOffRange: PropTypes.bool
};

let letter = 0;
var abc = ['A', 'B', 'C', 'D', 'E', 'F', 'G'];
const DateHeader = ({ label, drilldownView, onDrillDown }) => {
    letter++;
    let labels = label.split(" ");
    let istoday = false;
    if(moment().format('D') === labels[0]){
        istoday = true;
      };
      
  return (<>
    <MDBox
            style={{
            // height:"260px",
              display:"flex",
              flexDirection:"column",
              alignItems:"center",
              justifyContent:"center",
              boxShadow:"none",
              borderRadius:"16px",
              paddingTop:"8px",
              paddingBottom:"8px",
              color: istoday?"var(--dark-green)":"var(--light-black)",
              backgroundColor: istoday?"var(--light-green)":"var(--very-light-grey)",
              marginBottom: "14px"
            }}
            variant="gradient"
          >
        <Label {...{ label, drilldownView, onDrillDown }} />
    </MDBox>
    <MDTypography fontWeight="bold" style={{fontSize:18, color: "#60A77C"}}>
      Name A
    </MDTypography>
  </>);
};

DateHeader.propTypes = {
  label: PropTypes.node,
  date: PropTypes.instanceOf(Date),
  drilldownView: PropTypes.string,
  onDrillDown: PropTypes.func,
  isOffRange: PropTypes.bool
};

export default DateHeader;
