import React from "react";
import SimpleTable from "components/SimpleTable";
import "../styles.css";

const datatableModel = {
  columns: [
    { Header: "Job", accessor: "link_job_id"},
    { Header: "Task", accessor: "link_task_id"},
    { Header: "Start User", accessor: "start_user"},
    { Header: "Start Date", accessor: "start_date"},
    { Header: "Finish User", accessor: "finish_user"},
    { Header: "End Date", accessor: "end_date"},
    { Header: "Time", accessor: "time"},
    { Header: "Expenses", accessor: "expenses"},
    { Header: '', accessor: 'action', width: '25%' },
  ],
  rows: [],
  spacing: 15,
};

export default function ApproveTab({data, currentJob, currentClient}) {
  const filteredData = {
    ...datatableModel,
    rows: !currentJob && !currentClient ? data : data?.filter(d => d?.job === currentJob?.id),
  };
  return <SimpleTable data={filteredData} />
}
