import React, {useEffect, useRef, useState} from "react";
import 'react-calendar-timeline/lib/Timeline.css'
import {Grid} from '@mui/material';
import AdminLayout from "../../../components/AdminLayout";
import {Tables} from "../components/Tables";
import {Form, Formik, FieldArray, getIn} from "formik";
import {bundleInitialValues, bundleValidationSchema, initialBundle} from "./form";

import MDTypography from "../../../components/MDTypography";
import MDInput from "../../../components/MDInput";
import MDButton from "../../../components/MDButton";

// helpers
import {useNavigate, useLocation, useParams} from "react-router-dom";
import {useStores} from "models";
import {showMessage} from "services/helpers";
import FormikInput from "../../../components/FormikInput";
import { ROUTES } from "constants";

const matrixTypes = [
    { label: 'Single', value: 'Single' },
    { label: 'Box', value: 'Box' },
    { label: 'Asembly', value: 'Asembly' },
    { label: 'Non-Inventory', value: 'Non-Inventory' }
]

export default function Matrix() {
    const {id} = useParams();
    const formikRef = useRef();
    const navigate = useNavigate();
    const rootStore = useStores();
    const {loginStore} = rootStore;

    const [Bundles, setBundles] = useState([{...initialBundle}])
    const [Items, setItems] = useState({})
    const [matrix, setMatrix] = useState(null);
    const [Products, setProducts] = useState([]);
    const [matrixOptions, setMatrixOptions] = useState([]);
    const setBundleValue = (indexBundle, value, key) => {
        let bundles = [...Bundles]
        if (!bundles?.[indexBundle]) return
        bundles[indexBundle][key] = value
        setBundles(bundles)
    }

    const formatValues = (values) => {
        console.log(' formatValues ==>>> ', values)
        let data = {}
        data.name = values.name
        data.description = values.description
        data.type = values?.type?.value
        data.bundle = []
        if (values.id) data.id = values.id
        if (Array.isArray(values?.bundle)) {
            values.bundle.map(b => {
                let bundle = {
                    name: b.name,
                    boolean: b.boolean,
                    bundle_items: []
                }
                if (Array.isArray(b?.bundle_items)) {
                    b.bundle_items.map(i => {
                        bundle.bundle_items.push({
                            amount: i.amount ? `${i.amount}`.replace(',', '') : 1,
                            each: i.each ? `${i.each}`.replace(',', '') : 1,
                            round_up: i.round_up || false,
                            product: i?.product_id?.id,
                            matrix: i?.matrix_id?.id
                        })
                    })
                }
                data.bundle.push(bundle)
            })
        }
        return data
    }

    const createMatrix = (values) => {
        let data = formatValues(values)
    
        loginStore.environment.api.createMatrix(data).then((result) => {
            if (result.kind === "ok") {
                showMessage('The matrix has been created', 'success');
                navigate(ROUTES.INVENTORY)
            } else {
                if (result.kind === "bad-data") {
                    showMessage(result?.errors, 'error', true)
                } else {
                    showMessage('An error occurred, please try again later', 'error')
                }
            }
        })
    }

    const updateMatrix = (values) => {
        let data = formatValues(values)
        
        loginStore.environment.api.updateMatrix(data).then((result) => {
            if (result.kind === "ok") {
                showMessage('The matrix has been updated', 'success')
                navigate(ROUTES.INVENTORY)
            } else {
                if (result.kind === "bad-data") {
                    showMessage(result?.errors, 'error', true)
                } else {
                    showMessage('An error occurred, please try again later', 'error')
                }
            }
        })
    }

    const getProducts = () => loginStore.environment.api.getProducts().then((result) => {
        if (result.kind === "ok") {
            let products = result.data.results.map((c, i) => ({...c, 'value': c.id, 'label': c.name}));
            setProducts(products);
        } else {
            if (result.kind === "bad-data") {
                showMessage(result?.errors, 'error', true)
            } else {
                showMessage('An error occurred, please try again later', 'error')
            }
        }
    });
    const getMatrix = () => loginStore.environment.api.getMatrix('').then((result) => {
        if (result.kind === "ok") {
            let matrix = result.data.results.map(c => ({...c, 'value': c.id, 'label': c.name}));
            setMatrixOptions(matrix);
        } else {
            if (result.kind === "bad-data") {
                showMessage(result?.errors, 'error', true)
            } else {
                showMessage('An error occurred, please try again later', 'error')
            }
        }
    });

    const getMatrixId = (id) => {
        loginStore.environment.api.getMatrixDetail(id).then((result) => {
                if (result.kind === "ok") {
                    setMatrix(result.data)
                } else {
                    if (result.kind === "bad-data") {
                        showMessage(result?.errors, 'error', true)
                    } else {
                        showMessage('An error occurred, please try again later', 'error')
                    }
                }
            }
        )
    };
    useEffect(() => {
        getProducts('');
        getMatrix('');
        if (id) getMatrixId(id);
    }, []);

    const renderItems = (item, parent_path, formik) => {
        const values = getIn(formik.values, parent_path)
        return (
            <React.Fragment key={`item_${parent_path}`}>
                <FormikInput
                  useBrandingColor={'secondary'}
                    name={`${parent_path}.product_id`}
                    label={'Products'}
                    labelFieldName={'name'}
                    setFieldValue={(e, val) => formik.setFieldValue(e, val)}
                    type={'select'}
                    initialValue={values.product_id}
                    placeholder={'select product'}
                    options={Products}
                    errors={formik.errors}
                    item xs={4} lg={4} md={4}
                />
                <FormikInput
                  useBrandingColor={'secondary'}
                    name={`${parent_path}.matrix_id`}
                    label={'Matrix'}
                    labelFieldName={'name'}
                    setFieldValue={(e, val) => formik.setFieldValue(e, val)}
                    type={'select'}
                    initialValue={values.matrix_id}
                    placeholder={'select matrix'}
                    options={matrixOptions}
                    errors={formik.errors}
                    item xs={4} lg={4} md={4}
                />
                <FormikInput
                  useBrandingColor={'secondary'}
                    name={`${parent_path}.each`}
                    label={'Each'}
                    type={'numeric'}
                    errors={formik.errors}
                    placeholder={'each'}
                    item xs={1} lg={1} md={1}
                />
                <FormikInput
                  useBrandingColor={'secondary'}
                    name={`${parent_path}.amount`}
                    label={'Amount'}
                    type={'numeric'}
                    errors={formik.errors}
                    placeholder={'amount'}
                    item xs={1} lg={1} md={1}
                />
                <Grid item xs={12} lg={2} pt={4} mt={3}>
                    <MDTypography variant="h6" fontWeight="bold">
                        Round Up
                    </MDTypography>
                    <FormikInput
                      useBrandingColor={'secondary'}
                        name={`${parent_path}.round_up`}
                        type={'boolean'}
                        setFieldValue={(e, value) => formik.setFieldValue(`${parent_path}.round_up`, value.value)}
                        errors={formik.errors}
                        item xs={1} lg={5} md={1}
                    />
                </Grid>
            </React.Fragment>
        )
    }

    const renderBundles = ((bundle, index, formik, bundleArrayProps) => {
        const current_path = `${bundleArrayProps.name}.${index}`
        return (
            <React.Fragment key={`bundle_${index}`}>
                <FormikInput
                  useBrandingColor={'secondary'}
                    name={`${current_path}.name`}
                    label={'Bundle'}
                    errors={formik.errors}
                    placeholder='description'
                    className="top-grid"
                    item xs={10} lg={10} md={10} mt={4}
                />

                <FieldArray
                    name={`${current_path}.bundle_items`}
                    errors={formik.errors}
                >
                    {(bundleItemsArrayProps) => {
                        const parent_path = bundleItemsArrayProps.name
                        const items = getIn(formik.values, parent_path)
                        return (<>

                            <Grid item xs={2} lg={2} md={2} mt={3} key={`${index}_product`}>
                                <MDButton useBrandingColor={'primary'} color="green" style={{width: '100%', height: 50}}
                                          onClick={() => bundleItemsArrayProps.push({})}
                                >Add Item</MDButton>
                            </Grid>
                            {items.map((item, index_2) => renderItems(item, `${parent_path}.${index_2}`, formik))}
                        </>)
                    }}
                </FieldArray>
            </React.Fragment>
        )
    })


    const matrixHeader = (formik) => (<>
        <FormikInput
          useBrandingColor={'secondary'}
            name={'name'}
            label={'Name'}
            placeholder={'input name'}
            errors={formik.errors}
            item xs={12} lg={6} md={6}
        />
        <FormikInput
          useBrandingColor={'secondary'}
            name={'description'}
            label={'Description'}
            placeholder={'description'}
            errors={formik.errors}
            item xs={12} lg={6} md={6}
        />
        <FormikInput
            useBrandingColor={'secondary'}
            name={'type'}
            label={'Type'}
            labelFieldName={'label'}
            type={'select'}
            placeholder='select type'
            options={matrixTypes}
            errors={formik.errors}
            setFieldValue={(e, val) => formik.setFieldValue(e, val)}
            item xs={12} lg={6} md={12}
            disabled={true}
        />
    </>)

    const matrixFooter =
        <Grid item display={{ xs: 'block', lg: 'flex' }} xs={12} lg={12} md={12} mb={3} justifyContent={'space-evenly'}>
            <Grid item xs={12} lg={3} md={5} mb={{ xs: 2 }}>
                <MDButton
                    useBrandingColor={'primary'}
                    color="green"
                    variant="outlined"
                    className={'btn-save'}
                    fullWidth
                    onClick={() => navigate(ROUTES.INVENTORY)}
                >
                    Cancel
                </MDButton>
            </Grid>
            <Grid item xs={12} lg={3} md={5} mb={{xs: 2}}>
                <MDButton
                  useBrandingColor={'primary'}
                    color="green"
                    type="submit"
                    className={'btn-save'}
                    fullWidth
                >
                    Confirm
                </MDButton>
            </Grid>
        </Grid>

    const matrixItemsPreview = () => {
        const headers = ['Item', 'Amount']
        let rows = []
        Bundles.map((b, bundleKey) => {
            if (b.name !== '') {
                Items[bundleKey] && Items[bundleKey].map((item, itemKey) => {
                    if (!item?.item?.label) return null
                    else {
                        let value
                        try {
                            value = item.amount * b.amount / item.each
                        } catch {
                            value = 0
                        }
                        rows.push({
                            item: item.item.label,
                            amount: value
                        })
                    }
                })
            }
        })
        return <Tables header={headers} data={rows[0] ? rows : [{}]} style={{width: '100%'}} hideDelete/>
    }

    const matrixPreview = <Grid container spacing={3} pb={3} justifyContent="center" className="task_container">
        {Bundles.map((q, i) => {
            if (!q.name || q.name === '') return null
            return ([
                <Grid key={`${i}_question_preview_amount`} item xs={2} lg={2} md={2}>
                    <MDInput
                      useBrandingColor={'secondary'}
                        fullWidth
                        placeholder='Amount'
                        value={q.amount ?? 1}
                        onChange={newValue => setBundleValue(i, newValue.target.value, 'amount')}
                    />
                </Grid>,
                <Grid key={`${i}_question_preview`} item xs={10} lg={10} md={10}>
                    <MDTypography variant="h6" fontWeight="bold">
                        {q.name}
                    </MDTypography>
                </Grid>
            ])
        })}
        {matrixItemsPreview()}
    </Grid>

    return (

        <AdminLayout title={"New Task"}>
            <Grid container paddingTop={0} marginTop={0}>
                <Grid item xs={12} lg={9} md={9}>
                    <MDTypography useBrandingColor={'tertiary'} variant="h2" fontWeight="medium" mt={1} ml={5}>
                        {id ? 'Edit Matrix' : 'Create New Matrix'}
                    </MDTypography>
                </Grid>
            </Grid>
            <Formik
                innerRef={formikRef}
                initialValues={bundleInitialValues(matrix)}
                validationSchema={bundleValidationSchema}
                validateOnBlur={false}
                validateOnChange={false}
                enableReinitialize
                onSubmit={values => id ? updateMatrix(values) : createMatrix(values)}
            >
                {(formik) => {
                    return (
                        <Form>
                            <Grid container spacing={3} pb={3} className="task_container">
                                {matrixHeader(formik)}
                                <FieldArray
                                    name="bundle"
                                    errors={formik.errors}
                                >
                                    {(bundleArrayProps) => {
                                        const bundles = getIn(formik.values, bundleArrayProps.name)
                                        return <>
                                            {bundles.map((bundle, index) => renderBundles(bundle, index, formik, bundleArrayProps))}

                                            <Grid item xs={12} lg={12} md={12} mt={3}>
                                                <MDButton
                                                    useBrandingColor={'primary'}
                                                    onClick={() => bundleArrayProps.push(initialBundle)}
                                                    color="green"
                                                    style={{ width: 150 }}
                                                >Add Bundle</MDButton>
                                            </Grid>
                                        </>
                                    }}
                                </FieldArray>
                                {matrixFooter}
                            </Grid>
                        </Form>
                    )
                }}
            </Formik>
            {matrixPreview}

        </AdminLayout>
    )
}
