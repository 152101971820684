import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {styled} from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TuneIcon from "@mui/icons-material/Tune";

const GreenOutlinedButton = styled(Button)(({ theme }) => ({
  color:'var(--green)',
  backgroundColor: "transparent",
  borderColor:"var(--green)",
  '&:hover': {
    borderColor:  "var(--dark-green)",
    boxShadow: 'none',
    color:'var(--dark-green)'
  },

}));


export const FilterButton = ()=> {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <GreenOutlinedButton variant="outlined"
                           endIcon={<ExpandMoreIcon />}
                           startIcon={<TuneIcon />}
                           onClick={handleClick}
                           className={'btn-filter'}
      > Filter </GreenOutlinedButton>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleClose}>Team A</MenuItem>
        <MenuItem onClick={handleClose}>Team B</MenuItem>
        <MenuItem onClick={handleClose}>Team C</MenuItem>
      </Menu>
    </div>
  );
}
