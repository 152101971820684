import { Grid } from '@mui/material';
import SimpleTable from "components/SimpleTable";
import { useStores } from "models";
import moment from 'moment';
import { useEffect, useState } from "react";
import { showMessage } from "services/helpers";
import AdminLayout from "../../../components/AdminLayout";
import MDTypography from "../../../components/MDTypography";
import "../styles.css";

 
export const invoiceDataTableModel = {
  columns: [
    { Header: "Year", accessor: "year" },
    { Header: "Invoices", accessor: "count" },
    { Header: "Total", accessor: "sum_total" },      
  ],
  rows: []
}

export default function SelectedSales() {
    const rootStore = useStores();
    const {loginStore} = rootStore;
    const [Invoices, setInvoices] = useState({...invoiceDataTableModel});
    const [loading, setLoading] = useState(false);
    //
    const getSelectSalesReport = () => {
        setLoading(true);
        loginStore.environment.api.getSelectSalesReport()
        .then((result) => {
            if (result.kind === "ok") {
                console.log("result", result);
                const {invoices, } = result.data;
                  const parsedInvoices = invoices.map((invoice, iidix) => 
                    ({...invoice, 
                        year: moment(invoice.year).year()
                    }));
                 setInvoices({...Invoices, rows: parsedInvoices});
            } else {
                if (result.kind === "bad-data") {
                    showMessage(result?.errors, 'error', true);
                } else {
                    showMessage('An error occurred, please try again later');
                }
            }
        })
        .finally(() => setLoading(false))
    }
    
    useEffect(()=> {
         getSelectSalesReport();
    } ,[]);

    return (

    <AdminLayout title={"Selected Sales"} loading={loading}>
        {/* INVOICES */}
        <Grid container spacing={3} mt={2}>
            <Grid item xs={7} md={3} lg={10} mt='auto' pb={{ xs: '20px', md: 0 }}>
                <MDTypography useBrandingColor={'tertiary'} variant="h2" fontWeight="medium" width='12rem' ml={{ xs: 0, lg: 2 }}>
                    Sales
                </MDTypography>
            </Grid>   
            <Grid item xs={12} lg={12} md={12} ml={2}>
                <SimpleTable data={Invoices} emptyLabelText={'No invoices found'}/>
            </Grid>
        </Grid>
    </AdminLayout>
    )
}
