import {useEffect, useRef, useState} from "react";

// react-router-dom components
import { useNavigate } from "react-router-dom";

import MDButton from "components/MDButton";
import Grid from "@mui/material/Grid";
import Box from '@mui/material/Box';
import MDTypography from "components/MDTypography";
import {showMessage, useApi} from "services/helpers";

// Images
import woman_pc from '../../assets/images/woman_pc.png';
import man_woman from '../../assets/images/man_woman.png';
import calendar from '../../assets/images/calendar.png';
import man_carrying_paper from '../../assets/images/man_carrying_paper.png';
import woman_carrying_paper from '../../assets/images/woman_carrying_paper.png';
import './signup.css'

import * as Yup from "yup";
import {Form, Formik} from "formik";
import FormikInput from "../../components/FormikInput";
import {industries} from "../../constants";
import {ROUTES} from 'constants'
import {TERMS_CONDITIONS} from './terms_conditions'

function SignUp() {
  const navigate = useNavigate()
  const api = useApi()
  const firstFormikRef = useRef()
  const secondFormikRef = useRef()
  // step
  const [Step, setStep] = useState(1);
  // informative
  const [ShowTermsConditions, setShowTermsConditions] = useState(false);
  const [loading, setLoading] = useState(false);
  const [ShowPrivacyPolicy, setShowPrivacyPolicy] = useState(false);

  const firstForm = ['email', 'phone_number', 'password', 'password_confirm']


  const firstValidationSchema =
    Yup.object().shape({
      email: Yup.string().email().required('Email is a required field'),
      phone_number: Yup.string().required('Phone number is a required field'),
      password: Yup.string().required('Password is a required field'),
      password_confirm: Yup.string().required('Password confirmation is a required field').oneOf([Yup.ref('password'), null], "Passwords doesn't match"),
    })

  const secondValidationSchema =
    Yup.object().shape({
      first_name: Yup.string().required('First name is a required field'),
      last_name: Yup.string().required('Last name is a required field'),
      company_name: Yup.string(),
      industry: Yup.object().required(),
    })

  const firstInitialValues = {
    email: "",
    phone_number: "",
    password: "",
    password_confirm: "",
  };

  const secondInitialValues = {
    first_name: "",
    last_name: "",
    company_name: "",
    industry: null,
  };

  const signup = (values) => {
    const formatedValues = {...values, industry: values?.industry?.name, role: 'SUPERVISOR'}
    api.register(formatedValues).then((result) => {
      if (result.kind === "ok") {
        navigate(ROUTES.ROOT)
        showMessage('User registered successfully, please verify your email account', 'success')
      } else {
        if (result.kind === "bad-data") {
          const errors = result.errors
          const firstFormErrors = {}
          const secondFormErrors = {}
          Object.entries(errors).forEach(([key, value]) => {
            if (firstForm.includes(key)) {
              firstFormErrors[key] = value
            } else {
              secondFormErrors[key] = value
            }
          });
          const errorsDict = Object.assign({}, firstFormErrors, secondFormErrors);
          secondFormikRef.current.setErrors(errorsDict)
          if (Object.keys(firstFormErrors).length > 0){
            setStep(1)
          }
          showMessage('Validation errors found')
        } else showMessage('An error occurred, please try again later')
      }
    }).catch((err) => {
      showMessage()
    }).finally(() => setLoading(false))
  }


  const right_1 = (
    <Box sx={{
      width: '100%',
      display: {xs: 'none', lg: 'flex'},
      flexDirection: 'column',
      justifyContent: 'flex-start',
      gap: '183px'
    }}>
      <Box width='100%'>
        <MDTypography
          color={'success'}
          variant="h4"
          fontWeight="medium"
          mb={3}
          style={{ cursor: 'pointer'}}
          onClick={() => navigate(ROUTES.ROOT)}
        >
          Thime
        </MDTypography>
        <MDTypography variant="h2" fontWeight="regular" mb={3}>
          Management software and communication platform for small-medium on-demand businesses
        </MDTypography>
      </Box>
      <img src={woman_pc} alt={"woman"} className={"woman-man-image"}
           style={{height: '45%', width: 'auto'}}/>
    </Box>
  )

  const right_2 = <>
    <div style={{width: '100%'}}>
      <MDTypography
        color={'success'}
        variant="h4"
        fontWeight="medium"
        mb={3}
        style={{ cursor: 'pointer'}}
        onClick={{ }}
      >
        Thime
      </MDTypography>
      <MDTypography variant="h2" fontWeight="regular" mb={3}>
        Manage your business the easy way
      </MDTypography>
    </div>
    <div style={{height: '65%', marginLeft: '100px', flexDirection: 'column', display: 'flex', alignItems: 'center'}}>
      <img src={calendar} res alt={"calendar"} style={{height: '60%', width: '60%', marginBottom: -30}}/>
      <img src={man_woman} alt={"man_woman"} style={{height: '80%', width: '80%'}}/>
    </div>
  </>

  const right_terms = <>
    <div style={{width: '100%'}}>
      <MDTypography color={'success'} variant="h4" fontWeight="medium" mb={3}>
        Thime
      </MDTypography>
      <MDTypography variant="h2" fontWeight="regular" mb={3}>
        Please read our<br></br> Terms and Conditions
      </MDTypography>
    </div>
    <img src={man_carrying_paper} alt={"woman"} className={"woman-man-image"}
         style={{height: '50%', width: 'auto', marginLeft: '100px'}}/>
  </>

  const right_privacy = <>
    <div style={{width: '100%'}}>
      <MDTypography color={'success'} variant="h4" fontWeight="medium" mb={3}>
        Thime
      </MDTypography>
      <MDTypography variant="h2" fontWeight="regular" mb={3}>
        Please read our<br></br>Privacy Policy
      </MDTypography>
    </div>
    <img src={woman_carrying_paper} alt={"woman"} className={"woman-man-image"}
         style={{height: '50%', width: 'auto', marginLeft: '100px'}}/>
  </>

  const left_1 = (
    <>
      <MDTypography variant="h2" fontWeight="medium" mb={3}>
        Create your account
      </MDTypography>

      <Formik
        initialValues={firstInitialValues}
        validationSchema={firstValidationSchema}
        validateOnBlur={false}
        innerRef={firstFormikRef}
        onSubmit={values => {
          setStep(2)
        }}
      >
        {({errors, isValid}) => (
          <Form>
            <Grid container spacing={3} justifyContent="center">
              <Grid item xs={15} md={10} lg={9}>
                <FormikInput
                  name={'email'}
                  key={'email'}
                  label={'Email Address'}
                  placeholder={'input email'}
                  type={'email'}
                  errors={errors}
                />
              </Grid>
              <Grid item xs={15} md={10} lg={9}>
                <FormikInput
                  name={'phone_number'}
                  key={'phone_number'}
                  label={'Phone number'}
                  placeholder={'input phone number'}
                  type={'phone_input'}
                  errors={errors}
                />
              </Grid>
              <Grid item xs={15} md={10} lg={9}>
                <FormikInput
                  name={'password'}
                  key={'password'}
                  placeholder={'input password'}
                  label={'Set Password'}
                  type={'password'}
                  errors={errors}
                />
              </Grid>
              <Grid item xs={15} md={10} lg={9}>
                <FormikInput
                  name={'password_confirm'}
                  key={'password_confirm'}
                  placeholder={'input password'}
                  label={'Password Confirm'}
                  type={'password'}
                  errors={errors}
                />
              </Grid>
              <Grid item xs={15} md={10} lg={9} mt={2} alignItems={"center"} display={"flex"}>
                <MDButton
                  color="green"
                  type="submit"
                  disabled={loading}
                  loading={loading}
                  className={"btn-save-signup"}
                >
                  {`Continue  ➔`}
                </MDButton>
              </Grid>
              <Grid item xs={15} md={10} lg={9} alignItems={"center"} display={"flex"}>
                <MDButton
                  color="green"
                  variant="outlined"
                  type="button"
                  disabled={loading}
                  className={"btn-save-signup"}
                  onClick={() => navigate(ROUTES.ROOT)}
                >
                  Cancel
                </MDButton>
              </Grid>
              <Grid item xs={15} md={10} lg={9}>
                <MDTypography
                  variant="h6"
                  fontWeight="regular"
                  style={{ textAlign: "center", color: "#DD816B", cursor: 'pointer' }}
                >
                  By signing up, you agree to our
                  <u onClick={() => setShowTermsConditions(true)}>
                    <b> Terms and Conditions </b>
                    and
                    <b> Privacy Policy</b>
                  </u>
                </MDTypography>
              </Grid>
              <Grid item xs={7} style={{ flexDirection: "row", display: "flex", justifyContent: "center" }}>
                <div style={{ height: 6, width: 70, borderRadius: 3, backgroundColor: "#DD816B" }}></div>
                <div style={{ height: 6, width: 70, borderRadius: 3, backgroundColor: "#C6C9CE", marginLeft: 10 }}></div>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  )

  const left_2 = (
    <>
      <MDTypography variant="h2" fontWeight="medium" mb={3}>
        Create your account
      </MDTypography>
      <Formik
        initialValues={secondInitialValues}
        validationSchema={secondValidationSchema}
        validateOnBlur={false}
        innerRef={secondFormikRef}
        onSubmit={values => {
          signup(values)
        }}
      >
        {({errors, isValid, setFieldValue}) => (
          <Form>
            <Grid container spacing={3} justifyContent="center">
              <Grid item xs={9}>
                <FormikInput
                  name={'company_name'}
                  key={'company_name'}
                  label={'Company Name (Optional)'}
                  placeholder={'input company name'}
                  errors={errors}
                />
              </Grid>
              <Grid item xs={9}>
                <FormikInput
                  name={'first_name'}
                  key={'first_name'}
                  label={'First Name'}
                  placeholder={'input first name'}
                  errors={errors}
                />
              </Grid>
              <Grid item xs={9}>
                <FormikInput
                  name={'last_name'}
                  key={'last_name'}
                  label={'Last Name'}
                  placeholder={'input last name'}
                  errors={errors}
                />
              </Grid>
              <Grid item xs={9}>
                <FormikInput
                  name={'industry'}
                  label={'Industry'}
                  labelFieldName={'name'}
                  type={'select'}
                  setFieldValue={setFieldValue}
                  options={industries}
                  errors={errors}
                />
              </Grid>
              <Grid item xs={9} mt={2} alignItems={"center"} display={"flex"}>
                <MDButton
                  color="green"
                  type="submit"
                  disabled={loading}
                  loading={loading}
                  className={"btn-save-signup"}
                >
                  Sign Up
                </MDButton>
              </Grid>
              <Grid item xs={9} lg={9} md={9} alignItems={"center"} display={"flex"}>
                <MDButton
                  color="green"
                  variant="outlined"
                  type="button"
                  className={"btn-save-signup"}
                  disabled={loading}
                  onClick={() => setStep(1)}
                >
                  Cancel
                </MDButton>
              </Grid>
              <Grid item xs={7} style={{flexDirection: "row", display: "flex", justifyContent: "center"}}>
                <div style={{height: 6, width: 70, borderRadius: 3, backgroundColor: "#DD816B"}}></div>
                <div style={{height: 6, width: 70, borderRadius: 3, backgroundColor: "#DD816B", marginLeft: 10}}></div>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  )

  const left_privacy = <>
    <MDTypography variant="h2" fontWeight="medium" mb={5}>
      Privacy Policy
    </MDTypography>
    <div style={{height: '100%', overflow: 'auto'}}>
      <MDTypography color={'success'} variant="h4" fontWeight="medium" mb={3}>
        Lorem Ipsum
      </MDTypography>
      <MDTypography variant="h6" fontWeight="regular" mb={3}>
        Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet
        dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper
        suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in
        vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et
        iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.
      </MDTypography>
      <MDTypography color={'success'} variant="h4" fontWeight="medium" mb={3}>
        Lorem Ips
      </MDTypography>
      <MDTypography variant="h6" fontWeight="regular" mb={3}>
        Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet
        dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper
        suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in
        vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et.
        Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu
        feugiat nulla facilisis at vero eros et.
      </MDTypography>
      <MDTypography color={'success'} variant="h4" fontWeight="medium" mb={3}>
        Lorem Ipsum
      </MDTypography>
      < MDTypography variant="h6" fontWeight="regular">
        Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet
        dolore magna aliquam erat volutpat.Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit
        lobortis nisl ut aliquip ex ea commodo consequat.Duis autem vel eum iriure dolor in hendrerit in vulputate velit
        esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et.
        Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu
        feugiat nulla facilisis at vero eros et.
      </MDTypography>
    </div>
  </>

  return (
    <Box>
      <Grid
        container
        alignItems="center"
        className={"main-container"}
        style={{height: "100%", overflowY: 'hidden'}}
        width={'100%'}
      >
        {/* right div */}
        <Grid
          item
          xs={12}
          md={7}
          sx={{
            backgroundColor: "#F4F6F5",
            height: {xs: 0, lg: ShowPrivacyPolicy || ShowTermsConditions ? "90%" : "100%"},
          }}
        >
          <Box className="right-content" sx={{height: {xs: 0, lg: '90%'}}}>
            {ShowPrivacyPolicy || ShowTermsConditions
              ? ShowTermsConditions
                ? right_terms
                : right_privacy
              : Step === 1
                ? right_1
                : right_2}
          </Box>
        </Grid>
        {/* left div */}
        <Grid
          item
          xs={12}
          lg={5}
          style={{
            backgroundColor: "white",
            height: ShowPrivacyPolicy || ShowTermsConditions ? "90%" : "100%",
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column"
          }}
        >
          <div className="left-content">
            {ShowPrivacyPolicy || ShowTermsConditions
              ? ShowTermsConditions
                ? TERMS_CONDITIONS
                : left_privacy
              : Step === 1
                ? left_1
                : left_2}
          </div>
        </Grid>
        {(ShowPrivacyPolicy || ShowTermsConditions) && (
          <Grid
            item
            xs={12}
            style={{
              backgroundColor: "#F4F6F5",
              height: "10%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderTop: "3px solid #C6C9CE"
            }}
          >
            <Grid item xs={12} lg={7} md={7}></Grid>
            <Grid
              item
              xs={12}
              lg={5}
              md={5}
              style={{display: "flex", justifyContent: "space-evenly"}}
            >
              <MDButton
                color="green"
                variant="outlined"
                type="submit"
                onClick={() => [
                  setShowPrivacyPolicy(false),
                  setShowTermsConditions(false)
                ]}
                style={{width: 150}}
              >
                Back
              </MDButton>
              <MDButton
                color="green"
                type="submit"
                onClick={() => [
                  setShowPrivacyPolicy(false),
                  setShowTermsConditions(false)
                ]}
                style={{width: 250}}
              >
                Accept
              </MDButton>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Box>
  )
}

export default SignUp;
