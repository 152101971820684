import {memo, useState} from "react";
import {IconButton} from '@mui/material';
import MDDialog from "components/MDDialog";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDSwitch from "components/MDSwitch";
import MDAutocomplete from "components/MDAutocomplete";
import pxToRem from "assets/theme/functions/pxToRem";
import NoImage from 'assets/images/icons/no_image.png';

export const ChatModals = memo(
  ({
     channels,
     selectedChannel,
     setSelectedChannel,
     chatPublicValue,
     setChatPublicValue,
     openChatInfo,
     setOpenChatInfo,
     currentChatCustomName,
     setCurrentChatCustomName,
     selectUsersModal,
     setSelectUsersModal,
     Users,
     Clients,
     selectedUser,
     setSelectedUser,
     openImagePreview,
     setOpenImagePreview,
     selectedMessage,
     setMessageValue,
     chats,
     setChats,
     createChat,
     deleteMessage,
     handleAddParticipantsClick,
     updateChatParticipants,
     userId,
   }) => {

    const [showRestrictionMessage, setShowRestrictionMessage] = useState({onlyParticipant: false, chatName: false});

    const openAddNewParticipantsDialog = () => {
      handleAddParticipantsClick();
      setShowRestrictionMessage({onlyParticipant: false, chatName: false});
    }

    const addChatParticipant = () => {
      setSelectedChannel(prev => ({
        ...prev,
        participants: [...prev.participants, {...selectedUser, userImage: NoImage}]
      }));
      return setSelectUsersModal({...selectUsersModal, open: false});
    }

    const removeParticipantFromChat = (participantId) =>  {
      if (selectedChannel.participants.length > 2) {
        if(selectedChannel.participants.length === 3) {
          setShowRestrictionMessage({chatName: false, onlyParticipant: false});
        }
        return setSelectedChannel(prev => ({
          ...prev,
          participants: prev.participants.filter(p => p.id !== participantId)
        }));
      }
      setShowRestrictionMessage({chatName: false, onlyParticipant: true});
    }

    const onChangeChatName = (e) => {
      setCurrentChatCustomName(e.target.value);
      if(showRestrictionMessage.chatName) {
       setShowRestrictionMessage({...showRestrictionMessage, chatName: false});
      }
      if(e.target.value === '' && selectedChannel.participants.length > 2) {
        setShowRestrictionMessage({...showRestrictionMessage, chatName: true})
      }
    }

    const saveChatInformation = () => {

      const participantsIds = selectedChannel.participants.map(p => p.id);

      if(selectedChannel.participants.length > 2 && currentChatCustomName === '') {
        return setShowRestrictionMessage({...showRestrictionMessage, chatName: true})
      }
      const setChatName = () => {
        const singleParticipant = selectedChannel.participants?.find(participant => participant.id !== userId)?.name;
        if (currentChatCustomName === '') {
          if (selectedChannel.participants.length === 2) {
            setCurrentChatCustomName(singleParticipant);
            return singleParticipant;
          }
        }
        if(selectedChannel.participants.length === 2 && !Array.isArray(currentChatCustomName)) {
          if(selectedChannel.participants?.some(p => currentChatCustomName.split(',').some(c => c.includes(p.name)))
            || channels.find(c => c.channel_participants.find(p => p.name.includes(currentChatCustomName)))
              ?.channel_participants?.some(p => currentChatCustomName.split(',').some(c => c.includes(p.name)))
          ) {
            setCurrentChatCustomName(singleParticipant);
            return singleParticipant;
          }
        }
        if(Array.isArray(currentChatCustomName) && selectedChannel.participants.length === 2) {
          setCurrentChatCustomName(singleParticipant);
          return singleParticipant;
        }

        return currentChatCustomName
      }

      const chatNameResult = setChatName();
      const chatNameValidated = Array.isArray(chatNameResult) ? chatNameResult.join(', ') : chatNameResult;
      const updatedChatIndex = chats.findIndex(c => c.id === selectedChannel.id);
      const tempChats = [...chats];

      updateChatParticipants(selectedChannel.id, chatNameValidated, participantsIds, chatPublicValue);
      tempChats[updatedChatIndex].customChannelName = chatNameResult;
      setChats(tempChats);
      setSelectedChannel({...selectedChannel, customName: chatNameResult});
      setShowRestrictionMessage({onlyParticipant: false, chatName: false});
      return setOpenChatInfo(false);
    }

    const createCustomerProfileURL = () => {
      const customerProfileUrl = `http://localhost:3000/clients/profile/${selectedUser?.id}`;
      setMessageValue(customerProfileUrl);
      return setSelectUsersModal({...selectUsersModal, open: false});
    }

    const handleCloseSelectUsers = () => {
      setSelectUsersModal({...selectUsersModal, open: false});
      setSelectedUser(null);
    }

    return (
      <>
        {/*SELECTED CHAT INFORMATION DIALOG*/}
        <MDDialog
          open={openChatInfo}
          handleClose={() => [setOpenChatInfo(false), setShowRestrictionMessage({onlyParticipant: false, chatName: false})]}
          titleStyles={{fontSize: pxToRem(24)}}
          rootStyles={{maxHeight: '50%'}}
          style={{width: '100%', paddingBlock: `${pxToRem(10)} ${pxToRem(30)}`, paddingInline: pxToRem(50)}}
        >
          <MDBox display='flex' gap={pxToRem(20)} flexDirection='column' justifyContent='center' width={pxToRem(400)}>
            <MDBox display='flex' gap={pxToRem(20)} alignItems='center' justifyContent='space-between'>
              <MDInput
                placeholder='Type to set current chat name...'
                value={currentChatCustomName}
                onChange={onChangeChatName}
              />
              <MDBox display='flex' alignItems='center' gap={pxToRem(10)}>
                <MDSwitch type='ios' label='Public' checked={chatPublicValue}
                          onChange={e => setChatPublicValue(e.target.checked)}/>
              </MDBox>
              <IconButton
                className='base-icon-button icon-button-green-light'
                onClick={openAddNewParticipantsDialog}
              >
                <MDTypography color='white' variant='h4'>+</MDTypography>
              </IconButton>
            </MDBox>
            {selectedChannel.participants?.map((participant) => (
              <MDBox
                key={participant.id}
                alignItems='center'
                borderRadius='30px'
                display='flex'
                justifyContent='space-between'
                gap={pxToRem(70)}
                mt={1}
              >
                <MDBox display='flex' gap={pxToRem(20)} alignItems='center'>
                  <MDBox component='img' src={participant.userImage} width={pxToRem(52)} height={pxToRem(52)}/>
                  <MDTypography fontSize={pxToRem(16)}>{participant.name}</MDTypography>
                </MDBox>
                {participant.id !== userId && (
                  <IconButton onClick={() => removeParticipantFromChat(participant.id)}>
                    <MDTypography fontSize={pxToRem(14)} mr={1} sx={{cursor: 'pointer'}}>X</MDTypography>
                  </IconButton>
                )}
              </MDBox>
            ))}
            {showRestrictionMessage.onlyParticipant &&
              <MDTypography variant='button' sx={{textAlign: 'center'}}>
                You can't delete the only participant in the chat
              </MDTypography>
            }
            {showRestrictionMessage.chatName &&
              <MDTypography variant='button' sx={{textAlign: 'center'}}>
                You need to set a custom name in a chat with more than two participants
              </MDTypography>
            }

            <MDButton
              useBrandingColor={'primary'}
              color="green"
              size='small'
              sx={{margin: 'auto', width: pxToRem(200)}}
              onClick={saveChatInformation}
              disabled={showRestrictionMessage.chatName}
            >
              Save
            </MDButton>
          </MDBox>
        </MDDialog>

        {/* SELECT USERS DIALOG */}
        <MDDialog
          open={selectUsersModal.open}
          handleClose={handleCloseSelectUsers}
          title={selectUsersModal.type === 'users'
            ? 'Create New Message'
            : selectUsersModal.type === 'participants'
              ? 'Add New Participant'
              : 'Send Customer'}
          titleStyles={{fontSize: pxToRem(24)}}
          rootStyles={{overflowY: 'unset'}}
          style={{width: '100%', paddingBlock: `${pxToRem(10)} ${pxToRem(30)}`, paddingInline: pxToRem(50)}}
        >
          <MDTypography
            variant="h6"
            sx={{color: 'black', fontWeight: 600, marginLeft: pxToRem(16)}}
          >
            {selectUsersModal.type === 'users'
              ? 'Select User'
              : selectUsersModal.type === 'participants'
                ? 'Select Participant'
                : 'Select Customer'
            }
          </MDTypography>

          <MDAutocomplete
            useBrandingColor={'secondary'}
            fullWidth
            placeholder={selectUsersModal.type === 'clients' ? 'search for customer' : 'search for user'}
            options={selectUsersModal.type === 'clients' ? Clients : Users}
            onChange={(event, newValue) => setSelectedUser(newValue)}
            name='clients'
          />

          <MDBox sx={{display: 'flex', gap: pxToRem(24), marginTop: pxToRem(46)}}>
            <MDButton
              useBrandingColor={'primary'}
              color="green"
              variant="outlined"
              type="submit"
              className={"button-action"}
              onClick={() => setSelectUsersModal({...selectUsersModal, open: false})}
            >
              Cancel
            </MDButton>

            <MDButton
              useBrandingColor={'primary'}
              color="green"
              type="submit"
              className={"button-action"}
              onClick={selectUsersModal.type === 'users'
                ? createChat
                : selectUsersModal.type === 'participants'
                  ? addChatParticipant
                  : createCustomerProfileURL
              }
            >
              Continue
            </MDButton>
          </MDBox>
        </MDDialog>

        {/* IMAGE PREVIEW DIALOG */}
        <MDDialog
          open={openImagePreview}
          handleClose={() => setOpenImagePreview(false)}
          title='Image Preview'
          titleStyles={{fontSize: pxToRem(24)}}
          style={{width: '100%', paddingBlock: `${pxToRem(10)} ${pxToRem(30)}`, paddingInline: pxToRem(50)}}
        >
          <MDBox display='flex' justifyContent='center' alignItems='center'>
            <MDBox
              component='img'
              src={selectedMessage.current?.file?.src}
              borderRadius='10px'
              height={pxToRem(300)}
              width={pxToRem(300)}
            />
          </MDBox>
          <MDBox display='flex' justifyContent='space-between' alignItems='center' gap={pxToRem(10)} mt={2}>
            <MDButton
              useBrandingColor={'primary'}
              color="error"
              variant="outlined"
              type="submit"
              className={"button-action"}
              onClick={() => {
                setOpenImagePreview(false);
                deleteMessage(selectedMessage.current);
              }}
            >
              Delete
            </MDButton>

            <MDButton
              useBrandingColor={'primary'}
              color="green"
              type="submit"
              className={"button-action"}
            >
              Pin
            </MDButton>
          </MDBox>
        </MDDialog>
      </>
    )
  })
