import MDTypography from "components/MDTypography";

export const TERMS_CONDITIONS = <>
  <MDTypography variant="h2" fontWeight="medium" mb={5}>
    Terms and Conditions
  </MDTypography>
  <div style={{ height: '100%', overflow: 'auto' }}>
    <MDTypography color={'success'} variant="h4" fontWeight="medium" mt={3} mb={1}>
      Agreement between User and Thime
    </MDTypography>
    <MDTypography variant="h6" fontWeight="regular">
      Welcome to Thime. The Thime website (the "Site") is comprised of various web pages operated
      by Thime ("Thime"). Thime is offered to you conditioned on your acceptance without modification
      of the terms, conditions, and notices contained herein (the "Terms"). Your use of Thime constitutes
      your agreement to all such Terms. Please read these terms carefully, and keep a copy of them for
      your reference.
      <br/>
      <br/>
      Thime is an E-Commerce Site.
      <br/>
      <br/>
      CRM platform for day to day business operations. Schedule, keep track, inventory, etc.
    </MDTypography>
    <MDTypography color={'success'} variant="h4" fontWeight="medium" mt={3} mb={1}>
      Privacy
    </MDTypography>
    <MDTypography variant="h6" fontWeight="regular">
      Your use of Thime is subject to Thime's Privacy Policy. Please review our Privacy Policy, which
      also governs the Site and informs users of our data collection practices.
    </MDTypography>
    <MDTypography color={'success'} variant="h4" fontWeight="medium" mt={3} mb={1}>
      Electronic Communications
    </MDTypography>
    <MDTypography variant="h6" fontWeight="regular">
      Visiting Thime or sending emails to Thime constitutes electronic communications. You consent to
      receive electronic communications and you agree that all agreements, notices, disclosures and
      other communications that we provide to you electronically, via email and on the Site, satisfy any
      legal requirement that such communications be in writing.
    </MDTypography>
    <MDTypography color={'success'} variant="h4" fontWeight="medium" mt={3} mb={1}>
      Your Account
    </MDTypography>
    <MDTypography variant="h6" fontWeight="regular">
      If you use this site, you are responsible for maintaining the confidentiality of your account and
      password and for restricting access to your computer, and you agree to accept responsibility for
      all activities that occur under your account or password. You may not assign or otherwise transfer
      your account to any other person or entity. You acknowledge that Thime is not responsible for
      third party access to your account that results from theft or misappropriation of your account.
      Thime and its associates reserve the right to refuse or cancel service, terminate accounts, or
      remove or edit content in our sole discretion.
    </MDTypography>
    <MDTypography color={'success'} variant="h4" fontWeight="medium" mt={3} mb={1}>
      Children Under Thirteen
    </MDTypography>
    <MDTypography variant="h6" fontWeight="regular">
      Thime does not knowingly collect, either online or offline, personal information from persons under
      the age of thirteen. If you are under 18, you may use Thime only with permission of a parent or
      guardian.
    </MDTypography>
    <MDTypography color={'success'} variant="h4" fontWeight="medium" mt={3} mb={1}>
      Cancellation/Refund Policy
    </MDTypography>
    <MDTypography variant="h6" fontWeight="regular">
      a) Information on the current fees for Users who are registered for the Service can be found in the
      billing section of your account. If you are a new User or prospective User of the Service and
      would like more information about our pricing models and products, please see our pricing page,
      located at https://getjobber.com/pricing/. From time to time, we may offer a free trial of the
      Service, in our sole discretion granting access to the Service for a period of time determined by
      Time. A valid credit card is required for paying accounts. If you sign up for a recurring (e.g., monthly or annual)
      paid account, and you do not cancel that account before the end of any
      provided trial period, you will be billed starting on the first day following the trial period. If you
      cancel prior to the processing of your first invoice (i.e., up to and including the last day of your free
      trial), your access to the Service will terminate, and your credit card will not be charged.
      <br/>
      <br/>
      b) Automatic Renewal. Your subscription will continue indefinitely until terminated in accordance
      with Section 17 below. After your initial subscription period, and again after any subsequent
      subscription period, your subscription will automatically commence on the first day following the
      end of such period (each a “Renewal Commencement Date”) and continue for an additional
      equivalent period, at Thime's then-current price for such subscription. You agree that your account
      will be subject to this automatic renewal feature unless (i) in the case of monthly subscriptions, you
      cancel your subscription before the Renewal Commencement Date, and (ii) in the case of annual
      subscriptions, you cancel your subscription at least thirty (30) days prior to the Renewal
      Commencement Date (or, in the event that you receive a notice from Thime that your annual
      subscription will be automatically renewed)
    </MDTypography>
    <MDTypography color={'success'} variant="h4" fontWeight="medium" mt={3} mb={1}>
      Links to Third Party Sites/Third Party Services
    </MDTypography>
    <MDTypography variant="h6" fontWeight="regular">
      Thime may contain links to other websites ("Linked Sites"). The Linked Sites are not under the
      control of Thime and Thime is not responsible for the contents of any Linked Site, including
      without limitation any link contained in a Linked Site, or any changes or updates to a Linked Site.
      Thime is providing these links to you only as a convenience, and the inclusion of any link does not
      imply endorsement by Thime of the site or any association with its operators.
      <br/>
      <br/>
      Certain services made available via Thime are delivered by third party sites and organizations. By
      using any product, service or functionality originating from the Thime domain, you hereby
      acknowledge and consent that Thime may share such information and data with any third party
      with whom Thime has a contractual relationship to provide the requested product, service or
      functionality on behalf of Thime users and customers.
    </MDTypography>
    <MDTypography color={'success'} variant="h4" fontWeight="medium" mt={3} mb={1}>
      No Unlawful or Prohibited Use/Intellectual Property
    </MDTypography>
    <MDTypography variant="h6" fontWeight="regular">
      You are granted a non-exclusive, non-transferable, revocable license to access and use Thime
      strictly in accordance with these terms of use. As a condition of your use of the Site, you warrant
      to Thime that you will not use the Site for any purpose that is unlawful or prohibited by these
      Terms. You may not use the Site in any manner which could damage, disable, overburden, or
      impair the Site or interfere with any other party's use and enjoyment of the Site. You may not
      obtain or attempt to obtain any materials or information through any means not intentionally made
      available or provided for through the Site.

      All content included as part of the Service, such as text, graphics, logos, images, as well as the
      compilation thereof, and any software used on the Site, is the property of Thime or its suppliers
      and protected by copyright and other laws that protect intellectual property and proprietary rights.
      You agree to observe and abide by all copyright and other proprietary notices, legends or other
      restrictions contained in any such content and will not make any changes thereto.

      You will not modify, publish, transmit, reverse engineer, participate in the transfer or sale, create
      derivative works, or in any way exploit any of the content, in whole or in part, found on the Site.
      Thime content is not for resale. Your use of the Site does not entitle you to make any unauthorized
      use of any protected content, and in particular you will not delete or alter any proprietary rights or
      attribution notices in any content. You will use protected content solely for your personal use, and
      will make no other use of the content without the express written permission of Thime and the
      copyright owner. You agree that you do not acquire any ownership rights in any protected content.
      We do not grant you any licenses, express or implied, to the intellectual property of Thime or our
      licensors except as expressly authorized by these Terms.
    </MDTypography>
    <MDTypography color={'success'} variant="h4" fontWeight="medium" mt={3} mb={1}>
      Use of Communication Services
    </MDTypography>
    <MDTypography variant="h6" fontWeight="regular">
      The Site may contain bulletin board services, chat areas, news groups, forums, communities,
      personal web pages, calendars, and/or other message or communication facilities designed to
      enable you to communicate with the public at large or with a group (collectively, "Communication
      Services"). You agree to use the Communication Services only to post, send and receive messages
      and material that are proper and related to the particular Communication Service.
      <br/>
      <br/>
      By way of example, and not as a limitation, you agree that when using a Communication Service,
      you will not: defame, abuse, harass, stalk, threaten or otherwise violate the legal rights (such as
      rights of privacy and publicity) of others; publish, post, upload, distribute or disseminate any
      inappropriate, profane, defamatory, infringing, obscene, indecent or unlawful topic, name, material
      or information; upload files that contain software or other material protected by intellectual
      property laws (or by rights of privacy of publicity) unless you own or control the rights thereto or
      have received all necessary consents; upload files that contain viruses, corrupted files, or any other
      similar software or programs that may damage the operation of another's computer; advertise or
      offer to sell or buy any goods or services for any business purpose, unless such Communication
      Service specifically allows such messages; conduct or forward surveys, contests, pyramid schemes
      or chain letters; download any file posted by another user of a Communication Service that you
      know, or reasonably should know, cannot be legally distributed in such manner; falsify or delete
      any author attributions, legal or other proper notices or proprietary designations or labels of the
      origin or source of software or other material contained in a file that is uploaded; restrict or inhibit
      any other user from using and enjoying the Communication Services; violate any code of conduct
      or other guidelines which may be applicable for any particular Communication Service; harvest or
      otherwise collect information about others, including e-mail addresses, without their consent;
      violate any applicable laws or regulations.
      <br/>
      <br/>
      Thime has no obligation to monitor the Communication Services. However, Thime reserves the
      right to review materials posted to a Communication Service and to remove any materials in its
      sole discretion. Thime reserves the right to terminate your access to any or all of the
      Communication Services at any time without notice for any reason whatsoever.
      <br/>
      <br/>
      Thime reserves the right at all times to disclose any information as necessary to satisfy any
      applicable law, regulation, legal process or governmental request, or to edit, refuse to post or to
      remove any information or materials, in whole or in part, in Thime's sole discretion.
      <br/>
      <br/>
      Always use caution when giving out any personally identifying information about yourself or your
      children in any Communication Service. Thime does not control or endorse the content, messages
      or information found in any Communication Service and, therefore, Thime specifically disclaims
      any liability with regard to the Communication Services and any actions resulting from your
      participation in any Communication Service. Managers and hosts are not authorized Thime
      spokespersons, and their views do not necessarily reflect those of Thime.
      <br/>
      <br/>
      Materials uploaded to a Communication Service may be subject to posted limitations on usage,
      reproduction and/or dissemination. You are responsible for adhering to such limitations if you
      upload the materials.
    </MDTypography>
    <MDTypography color={'success'} variant="h4" fontWeight="medium" mt={3} mb={1}>
      Materials Provided to Thime or Posted on Any Thime Web Page
    </MDTypography>
    <MDTypography variant="h6" fontWeight="regular">
      Thime does not claim ownership of the materials you provide to Thime (including feedback and
      suggestions) or post, upload, input or submit to any Thime Site or our associated services
      (collectively "Submissions"). However, by posting, uploading, inputting, providing or submitting
      your Submission you are granting Thime, our affiliated companies and necessary sublicensees
      permission to use your Submission in connection with the operation of their Internet businesses
      including, without limitation, the rights to: copy, distribute, transmit, publicly display, publicly
      perform, reproduce, edit, translate and reformat your Submission; and to publish your name in
      connection with your Submission.
      <br/>
      <br/>
      No compensation will be paid with respect to the use of your Submission, as provided herein.
      Thime is under no obligation to post or use any Submission you may provide and may remove any
      Submission at any time in Thime's sole discretion.
      <br/>
      <br/>
      By posting, uploading, inputting, providing or submitting your Submission you warrant and
      represent that you own or otherwise control all of the rights to your Submission as described in this
      section including, without limitation, all the rights necessary for you to provide, post, upload, input
      or submit the Submissions.
    </MDTypography>
    <MDTypography color={'success'} variant="h4" fontWeight="medium" mt={3} mb={1}>
      Third Party Accounts
    </MDTypography>
    <MDTypography variant="h6" fontWeight="regular">
      You will be able to connect your Thime account to third party accounts. By connecting your Thime
      account to your third party account, you acknowledge and agree that you are consenting to the
      continuous release of information about you to others (in accordance with your privacy settings on
      those third party sites). If you do not want information about you to be shared in this manner, do
      not use this feature.
    </MDTypography>
    <MDTypography color={'success'} variant="h4" fontWeight="medium" mt={3} mb={1}>
      International Users
    </MDTypography>
    <MDTypography variant="h6" fontWeight="regular">
      The Service is controlled, operated and administered by Thime from our offices within the USA. If
      you access the Service from a location outside the USA, you are responsible for compliance with
      all local laws. You agree that you will not use the Thime Content accessed through Thime in any
      country or in any manner prohibited by any applicable laws, restrictions or regulations.
    </MDTypography>
    <MDTypography color={'success'} variant="h4" fontWeight="medium" mt={3} mb={1}>
      Indemnification
    </MDTypography>
    <MDTypography variant="h6" fontWeight="regular">
      You agree to indemnify, defend and hold harmless Thime, its officers, directors, employees, agents
      and third parties, for any losses, costs, liabilities and expenses (including reasonable attorney's
      fees) relating to or arising out of your use of or inability to use the Site or services, any user
      postings made by you, your violation of any terms of this Agreement or your violation of any rights
      of a third party, or your violation of any applicable laws, rules or regulations. Thime reserves the
      right, at its own cost, to assume the exclusive defense and control of any matter otherwise subject
      to indemnification by you, in which event you will fully cooperate with Thime in asserting any
      available defenses.
    </MDTypography>
    <MDTypography color={'success'} variant="h4" fontWeight="medium" mt={3} mb={1}>
      Class Action Waiver
    </MDTypography>
    <MDTypography variant="h6" fontWeight="regular">
      Any arbitration under these Terms and Conditions will take place on an individual basis; class
      arbitrations and class/representative/collective actions are not permitted. THE PARTIES AGREE
      THAT A PARTY MAY BRING CLAIMS AGAINST THE OTHER ONLY IN EACH'S
      INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY
      PUTATIVE CLASS, COLLECTIVE AND/ OR REPRESENTATIVE PROCEEDING, SUCH
      AS IN THE FORM OF A PRIVATE ATTORNEY GENERAL ACTION AGAINST THE
      OTHER. Further, unless both you and Thime agree otherwise, the arbitrator may not consolidate
      more than one person's claims, and may not otherwise preside over any form of a representative
      or class proceeding.
    </MDTypography>
    <MDTypography color={'success'} variant="h4" fontWeight="medium" mt={3} mb={1}>
      Liability Disclaimer
    </MDTypography>
    <MDTypography variant="h6" fontWeight="regular">
      THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES INCLUDED IN OR
      AVAILABLE THROUGH THE SITE MAY INCLUDE INACCURACIES OR
      TYPOGRAPHICAL ERRORS. CHANGES ARE PERIODICALLY ADDED TO THE
      INFORMATION HEREIN. THIME AND/OR ITS SUPPLIERS MAY MAKE
      IMPROVEMENTS AND/OR CHANGES IN THE SITE AT ANY TIME.
      <br/>
      <br/>
      THIME AND/OR ITS SUPPLIERS MAKE NO REPRESENTATIONS ABOUT THE
      SUITABILITY, RELIABILITY, AVAILABILITY, TIMELINESS, AND ACCURACY OF
      THE INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED
      GRAPHICS CONTAINED ON THE SITE FOR ANY PURPOSE. TO THE MAXIMUM
      EXTENT PERMITTED BY APPLICABLE LAW, ALL SUCH INFORMATION,
      SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS ARE PROVIDED "AS
      IS" WITHOUT WARRANTY OR CONDITION OF ANY KIND. THIME AND/OR ITS
      SUPPLIERS HEREBY DISCLAIM ALL WARRANTIES AND CONDITIONS WITH
      REGARD TO THIS INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND
      RELATED GRAPHICS, INCLUDING ALL IMPLIED WARRANTIES OR CONDITIONS
      OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND
      NON-INFRINGEMENT.
      <br/>
      <br/>
      TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT
      SHALL THIME AND/OR ITS SUPPLIERS BE LIABLE FOR ANY DIRECT, INDIRECT,
      PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL DAMAGES OR ANY
      DAMAGES WHATSOEVER INCLUDING, WITHOUT LIMITATION, DAMAGES FOR
      LOSS OF USE, DATA OR PROFITS, ARISING OUT OF OR IN ANY WAY
      CONNECTED WITH THE USE OR PERFORMANCE OF THE SITE, WITH THE DELAY
      OR INABILITY TO USE THE SITE OR RELATED SERVICES, THE PROVISION OF OR
      FAILURE TO PROVIDE SERVICES, OR FOR ANY INFORMATION, SOFTWARE,
      PRODUCTS, SERVICES AND RELATED GRAPHICS OBTAINED THROUGH THE SITE,
      OR OTHERWISE ARISING OUT OF THE USE OF THE SITE, WHETHER BASED ON
      CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, EVEN IF
      THIME OR ANY OF ITS SUPPLIERS HAS BEEN ADVISED OF THE POSSIBILITY OF
      DAMAGES. BECAUSE SOME STATES/JURISDICTIONS DO NOT ALLOW THE
      EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR
      INCIDENTAL DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU. IF
      YOU ARE DISSATISFIED WITH ANY PORTION OF THE SITE, OR WITH ANY OF
      THESE TERMS OF USE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO
      DISCONTINUE USING THE SITE.
    </MDTypography>
    <MDTypography color={'success'} variant="h4" fontWeight="medium" mt={3} mb={1}>
      Termination/Access Restriction
    </MDTypography>
    <MDTypography variant="h6" fontWeight="regular">
      Thime reserves the right, in its sole discretion, to terminate your access to the Site and the related
      services or any portion thereof at any time, without notice. To the maximum extent permitted by
      law, this agreement is governed by the laws of the State of Tennessee and you hereby consent to
      the exclusive jurisdiction and venue of courts in Tennessee in all disputes arising out of or relating
      to the use of the Site. Use of the Site is unauthorized in any jurisdiction that does not give effect to
      all provisions of these Terms, including, without limitation, this section.
      <br/>
      <br/>
      You agree that no joint venture, partnership, employment, or agency relationship exists between
      you and Thime as a result of this agreement or use of the Site. Thime's performance of this
      agreement is subject to existing laws and legal process, and nothing contained in this agreement is
      in derogation of Thime's right to comply with governmental, court and law enforcement requests or
      requirements relating to your use of the Site or information provided to or gathered by Thime with
      respect to such use. If any part of this agreement is determined to be invalid or unenforceable
      pursuant to applicable law including, but not limited to, the warranty disclaimers and liability
      limitations set forth above, then the invalid or unenforceable provision will be deemed superseded
      by a valid, enforceable provision that most closely matches the intent of the original provision and
      the remainder of the agreement shall continue in effect.
      <br/>
      <br/>
      Unless otherwise specified herein, this agreement constitutes the entire agreement between the user
      and Thime with respect to the Site and it supersedes all prior or contemporaneous communications
      and proposals, whether electronic, oral or written, between the user and Thime with respect to the
      Site. A printed version of this agreement and of any notice given in electronic form shall be
      admissible in judicial or administrative proceedings based upon or relating to this agreement to the
      same extent and subject to the same conditions as other business documents and records originally
      generated and maintained in printed form. It is the express wish to the parties that this agreement
      and all related documents be written in English.
    </MDTypography>
    <MDTypography color={'success'} variant="h4" fontWeight="medium" mt={3} mb={1}>
      Changes to Terms
    </MDTypography>
    <MDTypography variant="h6" fontWeight="regular">
      Thime reserves the right, in its sole discretion, to change the Terms under which Thime is offered.
      The most current version of the Terms will supersede all previous versions. Thime encourages you
      to periodically review the Terms to stay informed of our updates.
    </MDTypography>
    <MDTypography color={'success'} variant="h4" fontWeight="medium" mt={3} mb={1}>
      Contact Us
    </MDTypography>
    <MDTypography variant="h6" fontWeight="regular">
      Thime welcomes your questions or comments regarding the Terms:
      <br/>
      <br/>
      Thime
      <br/>
      <br/>
      <br/>
      Effective as of January 01, 2023
    </MDTypography>
  </div>
</>