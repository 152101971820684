import * as Yup from 'yup';

export const getRequestInitialValues = (request) => ({
  title: request ? request.title : '',
  service_details: request ? request.service_details :  '',
  sight_assessment_required: request ? request.sight_assessment_required : false,
  start_date: request ? request.start_date :  '',
  end_date: request ? request.end_date :  '',
  assign_to: request ? request.assign_to :  null,
});

export const requestValidationSchema = Yup.object().shape({
  title: Yup.string().required(),
  service_details: Yup.string().required(),
  sight_assessment_required: Yup.boolean(),
  start_date: Yup.string(),
  end_date: Yup.string(),
});