
import { useContext, useState, useEffect } from "react";
import { IMaskInput } from "react-imask";

// prop-types is a library for typechecking of props

// @mui material components
import MDInput from "components/MDInput";
import { Grid, Box } from '@mui/material';

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAutocomplete from "components/MDAutocomplete";

// Utils
import { useStores } from "../../models";
import { useIsMobile } from "../../services/hooks";

// Assets
import clear from 'assets/images/icons/clear.svg';
import phoneIcon from 'assets/images/icons/phone.svg';
import pxToRem from "../../assets/theme/functions/pxToRem";
import colors from "../../assets/theme/base/colors";
import {showMessage} from "services/helpers";

import { TwilioContext } from "../../services/phone_system";


function Dial({ }) {
  const { loginStore } = useStores();
  const isMobile = useIsMobile();

  const [currentClient, setCurrentClient] = useState(null);
  const [Clients, setClients] = useState([]);
  const [phone, setPhone] = useState('');
  const { deviceReady, makeCall } = useContext(TwilioContext);

  const closeDial = () => {
    loginStore.setSidePhoneOpen(false)
  }

  const handleCall = () => {
    makeCall(phone)
  }

  const getClients = () => {
    loginStore.environment.api.getClients('')
      .then((result) => {
        const {data} = result;
        let results = data.results.map(d => ({...d, label: d.name, value: d.id}));
        setClients(results)
      })
      .catch(() => showMessage())
  }

  useEffect(() => {
    getClients();
  }, []);

  useEffect(() => {
    if (currentClient?.phone_number) setPhone(currentClient?.phone_number)
    else setPhone('')
  }, [currentClient]);

  return (
    <>
      <MDBox sx={{ height: '84%' }}>
        <MDTypography sx={{ textAlign: 'center' }} variant="h4">Call ({deviceReady ? 'Ready' : 'Connecting'})</MDTypography>
        <MDTypography
          variant="h6"
          sx={{ textAlign: 'center', fontSize: { xs: pxToRem(14) } }}
          mt={4}
        >
          Type a phone number
        </MDTypography>

        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginLeft: pxToRem(19),
          }}>
          <IMaskInput
            mask="{+0(000) 000-0000}"
            style={{
              background: 'inherit',
              color: colors.button.signup_continue,
              fontSize: pxToRem(18),
              letterSpacing: '1px',
              fontWeight: 700,
              border: 'none',
            }}
            disabled={true}
            value={phone}
          />
        </Box>
        <Grid container spacing={3} alignItems='center'>
          <Grid mt={{ lg: 5, xs: pxToRem(16) }} item xs={12} lg={12} md={12}>
            <MDTypography ml={1} variant="h6" fontWeight="bold">
              Or select client
            </MDTypography>
            <MDAutocomplete
            useBrandingColor={'secondary'}
            fullWidth
            placeholder='search for client'
            options={Clients}
            onChange={(event, newValue) => setCurrentClient(newValue)}
            name='clients'
          />

            {/* <MDInput
              fullWidth
              placeholder='search for client'
            /> */}

          </Grid>

          {[1, 2, 3, 4, 5, 6, 7, 8, 9, '+', 0, clear].map((value, key) => (
            <Grid mt={0} item xs={4} lg={4} md={4} key={`_${key}`} margin={{ xs: 'auto' }}>
              <Box
                onClick={(e) => setPhone(prev => prev + e.target.innerText)}
                sx={{
                  cursor: 'pointer',
                  background: '#EDEDED',
                  borderRadius: 25,
                  height: { lg: 50, xs: 40 },
                  width: { lg: 50, xs: 40 },
                  alignItems: 'center',
                  justifyContent: 'center',
                  display: 'flex',
                  margin: 'auto'
                }}>
                {!value.toString().includes('.svg')
                  ? (
                    <MDTypography
                      value={value}
                      variant="h6"
                    >
                      {value}
                    </MDTypography>
                  )
                  : (
                    <img src={value} onClick={() => setPhone(prev => prev.slice(0, -1))} />
                  )
                }

              </Box>
            </Grid>
          ))}

        </Grid>
      </MDBox>
      <MDBox pb={2} >
        <MDBox
          display="flex"
          alignItems="center"
          justifyContent={{ lg: 'space-around', xs: 'center' }}
          pt={1}
        >
          <MDBox
            variant="gradient"
            color={'white'}
            coloredShadow={'#FFBAAA'}
            borderRadius="xl"
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="4rem"
            height="4rem"
            ml={2}
            bgColor={'info'}
            onClick={handleCall}
          >
            <img src={phoneIcon} alt='dial' />
          </MDBox>
        </MDBox>

      </MDBox>
    </>
  );
}

export default Dial;
