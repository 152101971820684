/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import { useState, useEffect, useMemo } from "react";

// @mui material components
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Sidenav from "components/Sidenav";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "./DashboardNavbar";

import { useMaterialUIController, setMiniSidenav } from "context";

import {ROUTES} from 'constants'


const AdminLayout = ({ children, title, url, loading = false, searchFunc = null }) => {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
  } = controller;

  const [onMouseEnter, setOnMouseEnter] = useState(false);

  // Action buttons for the BookingCard
  const actionButtons = (
    <>
      <Tooltip title="Refresh" placement="bottom">
        <MDTypography
          variant="body1"
          color="primary"
          lineHeight={1}
          sx={{ cursor: "pointer", mx: 3 }}
        >
          <Icon color="inherit">refresh</Icon>
        </MDTypography>
      </Tooltip>
      <Tooltip title="Edit" placement="bottom">
        <MDTypography variant="body1" color="info" lineHeight={1} sx={{ cursor: "pointer", mx: 3 }}>
          <Icon color="inherit">edit</Icon>
        </MDTypography>
      </Tooltip>
    </>
  );

  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  return (
    <DashboardLayout>
      <Sidenav
        routes={[

          // {
          //   type: "collapse",
          //   noCollapse: true,
          //   name: "Appointments",
          //   key: "appointments",
          //   route: ROUTES.CLIENT_APPOINTMENTS,
          //   icon: <Icon fontSize="medium">calendar_month</Icon>,
          // },
          {
            type: "collapse",
            noCollapse: true,
            name: "New Request",
            key: ['all_requests', 'new_request', 'edit'],
            route: ROUTES.CLIENT_ALL_REQUEST,
            icon: <Icon fontSize="medium">room_service</Icon>,
          },
          {
            type: "collapse",
            noCollapse: true,
            name: "All Quotes",
            key: "all_quotes",
            route: ROUTES.CLIENT_ALL_QUOTES,
            icon: <Icon fontSize="medium">receipt</Icon>,
          },
          {
            type: "collapse",
            noCollapse: true,
            name: "All Invoices",
            key: "all_invoices",
            route: ROUTES.CLIENT_ALL_INVOICES,
            icon: <Icon fontSize="medium">description</Icon>,
          },
          {
            type: "collapse",
            noCollapse: true,
            name: "Contact Us",
            key: "contact_us",
            route: ROUTES.CLIENT_CONTACT_US,
            icon: <Icon fontSize="medium">phone</Icon>,
          },
          {
            type: "collapse",
            noCollapse: true,
            name: "Wallet",
            key: "wallet",
            route: ROUTES.CLIENT_WALLET,
            icon: <Icon fontSize="medium">wallet</Icon>,
          },
          {
            type: "collapse",
            noCollapse: true,
            name: "Subscriptions",
            key: "Subscriptions",
            route: ROUTES.CLIENT_SUBSCRIPTIONS, // ROUTES.CLIENT_SUBSCRIPTION,
            icon: <Icon fontSize="medium">category</Icon>,
          },

          {
            type: "collapse",
            noCollapse: true,
            name: "Logout",
            key: "logout",
            route: "",
            icon: <Icon fontSize="medium">logout</Icon>,
          },

        ]}
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
      />
      <DashboardNavbar title={title} url={url} loading={loading} searchFunc={searchFunc}/>
      <MDBox py={3} style={{ borderTop: '1px solid #303134' }} >
        {children}
      </MDBox>
      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default AdminLayout;
