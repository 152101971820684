import * as Yup from 'yup';

export const getCompanyInitialValues = (data) => ({
    company_name: data ? data.company_name : '',
    phone_number: data ? data.phone_number :  '',
    email: data ? data.email : '',
    website: data ? data.website : '',
    address: data ? data.address :  '',
    timezone: data ? data.timezone :  '',
});

export const companyValidationSchema = Yup.object().shape({
  company_name: Yup.string(),
  phone_number: Yup.string(),
  email: Yup.string(),
  website: Yup.string(),
  address: Yup.string(),
});