import { useEffect, useState, useContext } from "react";
import moment from 'moment';
import { useNavigate } from "react-router-dom";
import {Link} from "react-router-dom";

// @mui components
import { Grid, IconButton } from '@mui/material';
import Icon from "@mui/material/Icon";
import MessageText from 'assets/images/icons/message-text.png';
import MDDialog from "components/MDDialog";
import pxToRem from "assets/theme/functions/pxToRem";

// General components
import AdminLayout from "components/AdminLayout";
import SimpleTable from "components/SimpleTable";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";

// Custom components
import { AddButton } from "./components/AddButton";
import { FilterButton } from "./components/FilterButton";
import { Header } from "./components/Header";
import "./styles.css"

// helpers
import { useStores } from "../../models";
import { showMessage, useApi } from "services/helpers";
import { TwilioContext } from "services/phone_system";
import { ROUTES } from 'constants'

const datatableModel = {
    columns: [
        {
            Header: "Name (A-Z)",
            accessor: "request_for",
            link: true,
            link_to: '/clients/profile/',
            state_key: 'client',
            state_accesor: 'request_for'
        },
        { Header: "Title", accessor: "title" },
        { Header: "Requested date/time", accessor: "start_date" },
        { Header: "Assign To", accessor: "assign_to", link: true, link_to: 'home', state_key: 'functionary' },
        { Header: "Created at", accessor: "create_at" },
        { Header: "Contact", accessor: "contact", sorted: false },
    ],
    rows: [],
    spacing: 10,
}

export default function Requests() {
    const rootStore = useStores();
    const { loginStore } = rootStore;
    const navigate = useNavigate();
    const api = useApi();
    const { deviceReady, makeCall } = useContext(TwilioContext);


    const [loading, setLoading] = useState(false);
    const [Data, setData] = useState([]);
    const [SMSText, setSMSText] = useState('');
    const [SMSModalOpen, setSMSModalOpen] = useState(false);
    const [Client, setClient] = useState(null);
    const [RequestStatus, setRequestStatus] = useState({
        DRAFT: 0,
        AWAITING_RESPONSE: 0,
        CHANGES_REQUESTED: 0,
        APPROVED: 0,
        CONVERTED: 0,
        ARCHIVED: 0,
    });

    const iconStyle = { width: 40, minWidth: 40, height: 40, borderRadius: 8, margin: 5 }

    const call = (number) => {
        loginStore.setSidePhoneOpen(true)
        makeCall(number)
    }

    const sendMessage = () => {
        api.sendMessage({ client:Client, text: SMSText }).handle({
          successMessage: 'The text message has been sent',
          errorMessage: 'An error occurred, please try again later',
          onSuccess: () => { setSMSModalOpen(false) },
          // onError: () => { formik result?.errors },
        })
      }

      const sendSMSModal = () => (
        <MDDialog
          open={SMSModalOpen}
          handleClose={() => setSMSModalOpen(false)}
          title={'Type the message'}
          titleStyles={{ fontSize: pxToRem(24) }}
          rootStyles={{ overflowY: 'unset' }}
          style={{ width: '100%', paddingBlock: `${pxToRem(10)} ${pxToRem(30)}`, paddingInline: pxToRem(50) }}
        >
          <MDInput
            fullWidth
            placeholder='input text message'
            value={SMSText}
            onChange={evt => setSMSText(evt.target.value)}
          />
          <MDBox sx={{ display: 'flex', gap: pxToRem(24), marginTop: pxToRem(46) }}>
            <MDButton
              useBrandingColor={'primary'}
              color="green"
              variant="outlined"
              type="submit"
              className={"button-action"}
              onClick={() => setSMSModalOpen(false)}
            >
              Cancel
            </MDButton>
    
            <MDButton
              useBrandingColor={'primary'}
              color="green"
              type="submit"
              className={"button-action"}
              onClick={() => sendMessage()}
              disabled={!SMSText || SMSText === ''}
            >
              Continue
            </MDButton>
          </MDBox>
        </MDDialog>
      )

    const actions = (data) =>
        <>
            {data?.request_for?.phone_number &&
                <MDButton useBrandingColor={'primary'} size="small" style={iconStyle} onClick={() => call(data?.request_for?.phone_number)}>
                    <Icon sx={{ color: '#fff' }}>phone</Icon>
                </MDButton>
            }
            {data?.request_for?.email &&
                <MDButton useBrandingColor={'primary'} size="small" style={iconStyle} onClick={() => {setSMSModalOpen(true); setClient(data?.request_for)}}>
                    <Icon sx={{ color: '#fff' }}>sms</Icon>
                </MDButton>
            }
            <MDButton useBrandingColor={'primary'} size="small" onClick={() => navigate(ROUTES.REQUEST_EDIT(data.id))} style={iconStyle}>
                <Icon sx={{ color: '#fff' }}>edit</Icon>
            </MDButton>
        </>

    const getExtraData = () => {
        let filter = {}
        if (window.location.pathname.includes('assessment_completed')) filter.assessment_completed = true
        if (window.location.pathname.includes('overdue')) filter.end_date = moment(new Date()).format('YYYY-MM-DD')
        return filter
    }

    const getRequests = (searchData) => {
        setLoading(true)

        loginStore.environment.api.getRequests(searchData, getExtraData())
            .then((result) => {
                if (result.kind === "ok") {
                    const responseData = result?.data?.results;
                    let data = { ...datatableModel };
                    data.rows = responseData.length > 0
                        ? responseData.map(r => {
                            return {
                                request_for: r?.request_for?.full_name
                                    ? <Link to={ROUTES.CLIENTS_PROFILE(r.request_for.id)} className='base-item link-item'>{r?.request_for?.full_name}</Link>
                                    : '',
                                title: <Link to={ROUTES.REQUEST_EDIT(r.id)} className='base-item link-item'>{r?.title}</Link>,
                                start_date: r?.start_date ? moment(r?.start_date).format('MMMM DD, YYYY') : '-',
                                end_date: r?.end_date ? moment(r?.end_date).format('MMMM DD, YYYY') : '-',
                                create_at: r?.created_at ? moment(r.created_at).format('ll') : '-',
                                assign_to: r?.assign_to?.first_name
                                    ? <Link to={ROUTES.SETTINGS_UPDATE_USER(r.assign_to.id)} className='base-item link-item'>{r?.assign_to?.first_name}</Link>
                                    : '',
                                contact: actions(r),
                            }
                        }) : [];
                    setData(data)
                } else {
                    if (result.kind === "bad-data") showMessage(result?.errors, 'error', true)
                    else showMessage('An error occurred, please try again later')
                }
            }).finally(() => setLoading(false))
    }

    const getRequestStatus = () => {
        loginStore.environment.api.getRequestStatus().then((result) => {
            if (result.kind === "ok") {
                setRequestStatus(result.data)
            }
        })
    }

    useEffect(() => {
        getRequests('')
        getRequestStatus();
    }, []);

    return (

        <AdminLayout
            title={"Requests"}
            searchFunc={getRequests}
            loading={loading}
        >
            <Grid container alignItems="top" marginTop={0} pt={{ xs: 7.5, sm: 0 }}>
                <Grid item xs={12} lg={12} md={12} mb={3}>
                    <Header status={RequestStatus} />
                </Grid>
                <Grid item xs={7} md={9} lg={10} mt='auto' pb={{ xs: '20px', md: 0 }}>
                    <MDTypography useBrandingColor={'tertiary'} variant="h2" fontWeight="medium" ml={{ xs: 0, lg: 3 }}>
                        All Requests
                    </MDTypography>
                </Grid>
                <Grid item xs={6} md={3} lg={2} mt={{ xs: 'auto', md: 0, lg: 'auto' }}>
                    <MDButton useBrandingColor={'primary'} color="green" onClick={() => navigate(ROUTES.NEW_REQUESTS)}> Add New +</MDButton>
                </Grid>
            </Grid>
            <Grid container spacing={3} mt={2}>
                <Grid item xs={12} lg={12} md={12} pt={{ xs: '48px !important', lg: '24px !important' }}
                    ml={{ xs: 0, lg: 2 }}>
                    <SimpleTable data={Data} />
                </Grid>
            </Grid>
            {sendSMSModal()}
        </AdminLayout>
    )
}
