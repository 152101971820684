import { useEffect, useState } from "react";
import moment from 'moment'
import { Grid, InputAdornment, IconButton } from '@mui/material';
import AdminLayout from "../../../components/AdminLayout";
import { useNavigate } from "react-router-dom";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import MDInput from "../../../components/MDInput";
import MDButton from "../../../components/MDButton";
import MDDatePicker from "../../../components/MDDatePicker";
import {Header} from "../components/Header";
import {Tables} from "../components/Tables";
import {DateFilter} from "../components/DateFilter";
import "../styles.css"

const header = [
    {title: 'Name (A-Z)', align: '', id: 1},
    {title: 'Sent Date', align: '', id: 2},
    {title: 'To', align: '', id: 3},
    {title: 'Subject', align: '', id: 4},
    {title: 'Status', align: '', id: 5},
    {title: 'Type', align: '', id: 6},
    {title: 'Opened Date', align: '', id: 7},
];
const rows = [
    {number: 'Jane Cooper', name: 'July 18, 2022', created: 'Lorem Ipsum', due: 'Lorem Ipsum', asigned: 'Lorem Ipsum', total: 'Lorem Ipsum', completed: 'Lorem Ipsum', id: 1},
    {number: 'Jane Cooper', name: 'July 18, 2022', created: 'Lorem Ipsum', due: 'Lorem Ipsum', asigned: 'Lorem Ipsum', total: 'Lorem Ipsum', completed: 'Lorem Ipsum', id: 2},
    {number: 'Jane Cooper', name: 'July 18, 2022', created: 'Lorem Ipsum', due: 'Lorem Ipsum', asigned: 'Lorem Ipsum', total: 'Lorem Ipsum', completed: 'Lorem Ipsum', id: 3},
    {number: 'Jane Cooper', name: 'July 18, 2022', created: 'Lorem Ipsum', due: 'Lorem Ipsum', asigned: 'Lorem Ipsum', total: 'Lorem Ipsum', completed: 'Lorem Ipsum', id: 4},
    {number: 'Jane Cooper', name: 'July 18, 2022', created: 'Lorem Ipsum', due: 'Lorem Ipsum', asigned: 'Lorem Ipsum', total: 'Lorem Ipsum', completed: 'Lorem Ipsum', id: 5},
    {number: 'Jane Cooper', name: 'July 18, 2022', created: 'Lorem Ipsum', due: 'Lorem Ipsum', asigned: 'Lorem Ipsum', total: 'Lorem Ipsum', completed: 'Lorem Ipsum', id: 6},
    {number: 'Jane Cooper', name: 'July 18, 2022', created: 'Lorem Ipsum', due: 'Lorem Ipsum', asigned: 'Lorem Ipsum', total: 'Lorem Ipsum', completed: 'Lorem Ipsum', id: 7},
    {number: 'Jane Cooper', name: 'July 18, 2022', created: 'Lorem Ipsum', due: 'Lorem Ipsum', asigned: 'Lorem Ipsum', total: 'Lorem Ipsum', completed: 'Lorem Ipsum', id: 8},
  ];

const headers = [
    {title: 'Sent', description: 'Emails sent to clients', quantity: '0', content: '', class:'', id: 1},
    {title: 'Delivered', description: 'Emails sent to clients', quantity: '1', content: '', id: 2},
    {title: 'Opened', description: 'Emails sent to clients', quantity: '3', content: '', class: '', id: 3},
    {title: '', quantity: '', content: '', class: 'empty', id: 4},
    {title: '', quantity: '', content: '', class: 'empty', id: 5},
  ];

export default function ClientBalanceSummary(props) {
    const navigate = useNavigate();

  return (

    <AdminLayout title={"Client Communications"}>
        <Grid container alignItems="top" paddingTop={0} marginTop={0}>
            <Grid item xs={12} lg={12} md={12} mb={3}>
                <MDTypography variant="h2" fontWeight="medium" mb={1} ml={3} style={{ float: 'left' }}>
                Client Communications
                </MDTypography>
            </Grid>
           
            <Grid item xs={12} lg={12} md={12} mb={3}>
                <Header data={headers} styles={{ width: 200}}/>
            </Grid>
        </Grid>
    <Grid container spacing={3}>
        <Grid item xs={12} lg={12} md={12} ml={2}>
            <MDTypography variant="h3" fontWeight="medium" mb={1} style={{ float: 'left' }}>
                List
            </MDTypography>
            <DateFilter style={{ float: 'right', display: 'inline', marginBottom: 10 }} />
            <Tables body={rows} header={header}/>
        </Grid>
    </Grid>
    </AdminLayout>
  )
}
