import React, { useState } from 'react';
import { Grid, Link } from '@mui/material';
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import './headers.css';

export const HeaderTab = (props) => {
    const {data, styles} = props;
    const [value, setValue] = React.useState(1);
    const [start, setStart] = React.useState(false);
    const handleChange = (val) => {
        setValue(val);
    };
    const handleStart = () => {
        setStart(!start);
    }

    return (<MDBox >
                {data.map((row, i) => (
                    <Link component="button" onClick={() => handleChange(i)}>
                        <MDTypography className={["tab", value == i ? "active" : ""].join(" ")}>{row.title}</MDTypography>
                    </Link>
                ))}
            </MDBox>);
}