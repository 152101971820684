import React, { useEffect, useState } from "react";
import moment from 'moment';
import { Form, Formik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { Grid } from '@mui/material';

// Components
import ClientLayout from "components/ClientPanelLayout/";
import MDSwitch from "components/MDSwitch";
import FormikInput from "components/FormikInput";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

//custom fields
import { RenderCustomFields } from "components/RenderCustomFields"

// helpers
import { useStores } from "models";
import { generateOptionsFromString, getFieldInitialValue, showMessage } from "services/helpers";
import { getRequestInitialValues, requestValidationSchema } from "./form";
import MDInput from "components/MDInput";
import {ROUTES} from 'constants'

// styles
import styles from "screens/requests/Scheduler.module.css";

const FORM_ID = 'Client_Request';

export default function ClientRequest() {
  const { id } = useParams();
  const navigate = useNavigate();
  const rootStore = useStores();
  const { loginStore } = rootStore;

  const [RequestForOptions, setRequestForOptions] = React.useState([]);
  const [AssignToOptions, setAssignToOptions] = React.useState([]);
  const [RequestDetail, setRequestDetail] = useState(null);

  const [SightAssessmentRequired, setSightAssessmentRequired] = React.useState(false);
  const [CustomFieldModalOpen, setCustomFieldModalOpen] = useState(false);
  const [customFields, setCustomFields] = useState([]);
  const [customFieldsInitialValues, setCustomFieldsInitialValues] = useState({});
  const [loading, setLoading] = useState(false);

  const createRequest = (values) => {
    let data = {
      ...values,
      user: loginStore.id,
      disable_client_notifications: false
    }

    if (!data.sight_assessment_required) {
      delete data['end_date']
      delete data['start_date']
    }

    loginStore.environment.api.createRequest(data).then((result) => {
      if (result.kind === "ok") {
        showMessage('The request has been created', 'success')
        navigate(ROUTES.CLIENT_ALL_REQUEST)
      } else {
        if (result.kind === "bad-data") {
          showMessage(result?.errors, 'error', true)
        } else {
          showMessage('An error occurred, please try again later')
        }
      }
    })
  }

  const getClients = () => {
    loginStore.environment.api.getClients().then((result) => {
      if (result.kind === "ok") {
        let data = result.data.results.map(r => ({ label: r.first_name + ' ' + r.last_name, value: r.id }))
        setRequestForOptions(data)
      } else {
        if (result.kind === "bad-data") {
          showMessage(result?.errors, 'error', true)
        } else {
          showMessage('An error occurred, please try again later')
        }
      }
    })
  }
  const getEmployes = () => {
    loginStore.environment.api.getClients().then((result) => {
      if (result.kind === "ok") {
        let data = result.data.results.map(r => ({ label: r.first_name + ' ' + r.last_name, value: r.value }))
        setAssignToOptions(data)
      } else {
        if (result.kind === "bad-data") {
          showMessage(result?.errors, 'error', true)
        } else {
          showMessage('An error occurred, please try again later')
        }
      }
    })
  }

  const getCustomFields = () => {
    setLoading(true)
    loginStore.environment.api.getCustomFields({ type: 'REQUEST', object_id: id }).then((result) => {
      if (result.kind === "ok") {
        console.log(' result => ', result)
        const { data } = result
        const { results } = data
        if (results.length > 0) {
          const fields = results[0]['fields']
          setCustomFields(fields)
          setCustomFieldsInitialValues(getFieldInitialValue(fields))
        }
      }
    }).finally(() => setLoading(false))
  }

  const mainInformation = (setFieldValue, errors, values) => <>
    <Grid item xs={12} lg={6} md={6}>
      <MDTypography variant="h6" fontWeight="bold">
        Request for
      </MDTypography>
      <MDInput
        fullWidth
        placeholder='search for client'
        disabled={true}
        value={loginStore.fullName}
      />
    </Grid>
    <Grid item xs={12} lg={6} md={6}>
      <MDTypography variant="h6" fontWeight="bold">
        Title
      </MDTypography>
      <FormikInput
        fullWidth
        placeholder='input title'
        name='title'
        errors={errors}
      />
    </Grid>
    <Grid item xs={12} lg={6} md={6}>
      <MDTypography variant="h6" fontWeight="bold">
        Assign to
      </MDTypography>
       <FormikInput
        fullWidth
        placeholder='a team member will be assign to your request'
        name='assign_to'
        errors={errors}
        disabled={true}
      />
    </Grid>
    <Grid item xs={12} lg={6} md={6}>
      <MDTypography variant="h6" fontWeight="bold">
        Service Details
      </MDTypography>
      <FormikInput
        fullWidth
        placeholder='input service details'
        type='textarea'
        name='service_details'
        errors={errors}
      />
    </Grid>
  </>

  const dateRangeInformation = (setFieldValue, errors, values) => <>
    <Grid item xs={12} lg={6} display={'flex'} alignItems={'center'}>
      <MDTypography sx={{ mr: 5 }} variant="h6" fontWeight="bold">
        On sight assesment required
      </MDTypography>

      <FormikInput
      sx={{ mt: 3}}
        name={'sight_assessment_required'}
        type={'boolean'}
        setFieldValue={setFieldValue}
        errors={errors}
      />
    </Grid>
    {values.sight_assessment_required &&
      <>
        <Grid item xs={12} lg={3}>
          <MDTypography variant="h6" fontWeight="bold">
            Start Date
          </MDTypography>
          <FormikInput
            fullWidth
            input={{ placeholder: "Select date" }}
            type='datetime-local'
            name='start_date'
            errors={errors}
          />
        </Grid>
        <Grid item xs={12} lg={3} >
          <MDTypography variant="h6" fontWeight="bold">
            End Date
          </MDTypography>
          <FormikInput
            fullWidth
            input={{ placeholder: "Select date" }}
            type='datetime-local'
            name='end_date'
            errors={errors}
          />
        </Grid>
      </>
    }
  </>

  const renderCustomField = (index, field, errors, setFieldValue) => {
    const optionsForField = generateOptionsFromString(field['field_options'])
    const value = field['default_value'].value
    return (
      <React.Fragment key={index}>
        <FormikInput
          name={field['field_name']}
          label={field['field_label']}
          type={field['field_type_input']}
          setFieldValue={setFieldValue}
          options={optionsForField}
          labelFieldName={'label'}
          errors={errors}
          initialValue={value}
          item
          xs={12}
          md={6}
        />
      </React.Fragment>
    )
  }

  const getRequestDetail = (id) => {
    setLoading(true)
    loginStore.environment.api.getRequestDetail(id).then((result) => {
      if (result.kind === "ok") {
        const data = {
          ...result.data,
          start_date: moment(result.data.start_date).format(),
          end_date: moment(result.data.end_date).format(),
        }
        setRequestDetail(data);
        if (result.data.sight_assessment_required) {
          setSightAssessmentRequired(true);
        }
      } else {
        showMessage()
      }
    }).finally(() => setLoading(false))
  }

  const editRequest = (values) => {
    const data = {
      ...values,
      id: RequestDetail.id,
    }
    loginStore.environment.api.updateRequest(data).then((result) => {
      if (result.kind === "ok") {
        showMessage('The request has been updated', 'success');
        navigate(ROUTES.CLIENT_ALL_REQUEST);
      } else {
        if (result.kind === "bad-data") {
          showMessage(result?.errors, 'error', true)
        }
      }
    }).catch(error => showMessage(error.message))
  }

  useEffect(() => {
    getClients();
    getEmployes();
    getCustomFields();
    if (id) {
      getRequestDetail(id);
    }
  }, []);

  return (
    <ClientLayout title={"New Request"}>
      <Grid container alignItems="top" paddingTop={0} marginTop={0}>
        <Grid ml={5} item xs={12} lg={9} md={9} className={styles.leftContainer}>
          <MDTypography variant="h2" fontWeight="medium" mb={1}>
              {id ? 'Edit Request' : 'Create New Request'}
          </MDTypography>
        </Grid>
      </Grid>

      <Formik
        initialValues={Object.assign({}, getRequestInitialValues(RequestDetail), customFieldsInitialValues)}
        validationSchema={requestValidationSchema}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={values => !id ? createRequest(values) : editRequest(values)}
        enableReinitialize
      >
        {({ errors, setFieldValue, values }) => {
          console.log(' errors ', errors)
          return (
            <Form>
              <Grid container spacing={{ xs: 2, md: 3, lg: 3 }} className="task_container" alignItems={'flex-start'}>
                {mainInformation(setFieldValue, errors)}
                {customFields.map((field, index) => {
                  return renderCustomField(index, field, errors, setFieldValue)
                })}
                <RenderCustomFields form_id={FORM_ID} />

                <Grid item xs={12} lg={12} md={12}>
                  <hr className="MuiDivider-root MuiDivider-fullWidth"
                    style={{ background: '#C6C9CE', margin: '20px 0' }} />
                </Grid>
                {dateRangeInformation(setFieldValue, errors, values)}
                <Grid item display={{ xs: 'block', lg: 'flex' }} xs={12} lg={12} md={12} mb={3}
                  justifyContent={'space-evenly'}>
                  <Grid item xs={12} lg={3} md={5} mb={{ xs: 2 }}>
                    <MDButton
                      color="green"
                      type="submit"
                      fullWidth
                      variant="outlined"
                    >
                      Cancel
                    </MDButton>
                  </Grid>

                  <Grid item xs={12} lg={3} md={5} mb={{ xs: 2 }}>
                    <MDButton
                      color="green"
                      type="submit"
                      fullWidth
                    >
                      Create Request
                    </MDButton>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          )
        }}
      </Formik>
    </ClientLayout>
  )
}
