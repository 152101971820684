
import React, { useEffect, useRef, useState } from "react";
import { Grid, InputAdornment } from '@mui/material';
import AdminLayout from "../../../components/AdminLayout";
import { useNavigate, useParams } from "react-router-dom";
import "./style.css";
import MDTypography from "../../../components/MDTypography";
import MDInput from "../../../components/MDInput";
import MDButton from "../../../components/MDButton";
import AttachIcon from "assets/images/icons/attach-icon.png";
import FormikInput from "components/FormikInput";
//icons
import MDDialog from "../../../components/MDDialog";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import MDCheckbox from "../../../components/MDCheckbox";

import { runInAction } from "mobx";
import { useStores } from "models";
import { showMessage, dateRange, checkDuplicatesInArray, downloadFile } from "services/helpers";
import { Form, Formik, FieldArray } from "formik";
import { invoiceInitialValues, invoiceValidationSchema, initialInvoiceMatrix, initialInvoiceProduct, calculateMatrixPrice } from "./form";
import { ROUTES } from "constants";

export default function Event() {
  const navigate = useNavigate();
  const rootStore = useStores();
  const { id } = useParams();
  const { loginStore } = rootStore;
  const arrayHelpersRef = useRef()
  const arrayHelpersMatrixRef = useRef()
  const formikRef = useRef()

  const [Clients, setClients] = useState([]);
  const [Taxes, setTaxes] = useState([]);
  const [Templates, setTemplates] = useState([]);
  const [Products, setProducts] = useState([]);
  const [Matrix, setMatrix] = useState([]);
  const [Invoice, setInvoice] = useState(null);
  const [file, setFile] = useState(null);
  const [DepositRequired, setDepositRequired] = useState(false);
  const [open, setOpen] = useState(false);
  const [openSendEmail, setOpenSendEmail] = useState(false);
  const [openSendText, setOpenSendText] = useState(false);
  const [openTax, setOpenTax] = useState(false);

  const [SaveAnd, setSaveAnd] = useState({
    email: false,
    sms: false,
    title: '',
    text: ''
  })

  const saveInvoice = (info, template = false) => {
    let data = { ...info, is_template: template }
    data.invoice_for = data?.invoice_for?.id
    data.tax = data?.tax?.id 
    data.info = data?.info?.id
    data.subtotal = getSubTotal(data, 'invoice_custom') + getSubTotal(data, 'invoice_products')
    data.tax_amount = calculateTax(
      (getSubTotal(info, 'invoice_custom') + getSubTotal(info, 'invoice_products') - info.discount),
      info.tax
      )
    data.total = data.subtotal - parseInt(data.discount) + data.tax_amount

    if (!data.id) delete data.id

    data.invoice_details = []

    data.invoice_products.map(t => {
      if (t?.product?.id && t.quantity > 0) {
        let detail = {
          quantity: parseInt(t.quantity),
          total: parseInt(t.quantity) * parseInt(t.price),
          subtotal: t.subtotal,
          recommended: t.recommended
        }
        t?.product?.type === 'product'
          ? detail.product = t.product.id
          : detail.matrix = t.product.id
        data.invoice_details.push(detail)
      }
    })

    data.invoice_custom.map(t => {
      if (t?.custom && t.total > 0) {
        data.invoice_details.push({
          custom: t.custom,
          quantity: parseInt(t.quantity),
          total: parseInt(t.quantity) * parseInt(t.price),
          subtotal: t.subtotal,
          price: t.price,
          recommended: t.recommended
        })
      }
    })

    if (file) data.attachment = file

    delete data['invoice_products']
    delete data['invoice_custom']

    if (data?.required_deposit_percentage?.label) data.required_deposit_percentage = data.required_deposit_percentage.id

    const errors = getErrors(data)
    if (errors) return
    
    loginStore.environment.api.createInvoice(data).then((result) => {
      if (result.kind === "ok") {
        runInAction(() => {
          showMessage('Invoice created', 'success')
          navigate('/invoices')
        })
      } else {
        if (result.kind === "bad-data") {
          showMessage(result?.errors, 'error', true)
        } else {
          showMessage('An error occurred, please try again later')
        }
      }
    })
  }

  const getErrors = (data) => {
    let error = false

    if (!data.title || data.title === '') {
      showMessage('The quote must have a title')
      error = true 
    }

    if (data.invoice_details.length < 1) {
      showMessage('The invoice must have at least 1 detail')
      error = true
    }
    if (data.total < 1) {
      showMessage('The invoice total cannot be less then 0')
      error = true
    }

    return error
  }

  const getInvoiceFile = (id) => {
    loginStore.environment.api.getInvoiceFile(id).then((result) => {
      if (result.kind === "ok") {
        result.download_file()
      } else showMessage('An error occurred, please try again later')
    }).catch(err => showMessage())
  }

  const getClients = () => {
    loginStore.environment.api.getClients('')
      .then((result) => {
        const { data } = result
        const { results } = data
        setClients(results)
      })
      .catch(() => showMessage())
  }

  const getTaxes = () => {
    loginStore.environment.api.getTaxes()
      .then((result) => {
        const { data } = result
        const { results } = data
        setTaxes(results)
      })
      .catch(() => showMessage())
  }

  const getProducts = () => {
    loginStore.environment.api.getProducts('')
      .then((result) => {
        const { data } = result
        const { results } = data
        setProducts(results.map(r => ({...r, type: 'product'})))
      })
      .catch(() => showMessage())
  }

  // const getInvoiceTemplates = () => {
  //   loginStore.environment.api.getInvoiceTemplates('')
  //     .then((result) => {
  //       const { data } = result
  //       setTemplates(data)
  //     })
  //     .catch(() => showMessage())
  // }

  const getMatrix = () => {
    loginStore.environment.api.getMatrix('')
      .then((result) => {
        const { data } = result
        const { results } = data
        setMatrix(results.map(r => ({...r, type: 'matrix'})))
      })
      .catch(() => showMessage())
  }

  const getInvoiceDetail = (id) => {
    loginStore.environment.api.getInvoiceDetail(id).then((result) => {
      if (result.kind === "ok") {
        setInvoice(result.data)
      } else showMessage()
    })
  }

  const saveTax = (values) => {
    let data = { ...values }
    data['company'] = loginStore.getCompany.id

    loginStore.environment.api.createTax(data)
        .then((result) => {
            if (result.kind === "ok") {
                // setSelectedTax(result.data)
                showMessage('Tax Created!', 'success')
            } else showMessage('An error occurred, please try again later')
            getTaxes()
        })
        .catch(() => showMessage())
        .finally(() => setOpenTax(false))
}

  useEffect(() => {
    getClients()
    getTaxes()
    getMatrix()
    getProducts()
    // getInvoiceTemplates()
    if (id) getInvoiceDetail(id);
  }, []);

  const handleUploadFile = (e) => {
    const [file] = e.target.files;
    if (file) setFile(file)
  }
  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleCloseTax = () => setOpenTax(false);

  const getSubTotal = (data, label) => {

    let total = 0
    if (data[label] && Array.isArray(data[label])) {
      data[label].map(p => {
        if (p.subtotal || p.recommended) total += p.price * p.quantity
      })
      return total
    } else return parseInt(total)
  }

  const calculateTax = (total, tax) => {
    if (!total || total === 0 || !tax) return 0
    
    let value = 0
    if (!tax.percentage) value = Number(tax.tax_rate)
    else value = (Number(tax.tax_rate) * total / 100)

    return value
  }

  const setValuesFromTemplate = (setFieldValue, template) => {
    let temp = { ...template }
    delete temp['id']
    setInvoice(temp)
  }

  const invoiceProductField = (index, setFieldValue, errors, values, field) => {
    return (
      <React.Fragment key={`matrix_${index}`}>
        <Grid item xs={12} lg={6} md={6} mt={3}>
          <Grid container height={100}>
            <FormikInput
              useBrandingColor={'secondary'}
              name={`invoice_products[${index}].product`}
              setFieldValue={(e, val) => {
                const price = val?.type === 'product' ? val?.default_price : calculateMatrixPrice(val)
                setFieldValue(e, val)
                setFieldValue(`invoice_products[${index}].price`, price)
                setFieldValue(`invoice_products[${index}].total`, parseInt(price) * parseInt(field.quantity))
              }}
              label={''}
              labelFieldName={'name'}
              type={'select'}
              placeholder={'start typing to get the dropdown menu'}
              options={[...Products, ...Matrix]}
              errors={errors}
              item xs={11} lg={12} md={12}
            />
            <Grid item xs={10} lg={5} md={6} display={'flex'}>
              <MDButton
                color="green"
                className={`mini-button ${field?.subtotal ? 'sub' : ''}`}
                size="small"
                onClick={() => [
                  setFieldValue(`invoice_products[${index}].subtotal`, true),
                  setFieldValue(`invoice_products[${index}].recommended`, false)
                ]}
              >Subtotal</MDButton>
              <MDButton
                color="green"
                useBrandingColor={'primary'}
                className={`mini-button ${!field?.subtotal ? 'sub' : ''}`}
                size="small"
                onClick={() => setFieldValue(`invoice_products[${index}].subtotal`, false)}
              >Optional</MDButton>
            </Grid>
            {!field?.subtotal &&
              <Grid item xs={12} lg={7} md={6} display={'flex'}>
                <MDTypography style={{ fontSize: 14, fontWeight: 600, float: 'left', marginRight: 10, marginTop: 10, maxWidth: 200 }}>
                  Recommended / (If selected include in the total)
                </MDTypography>
                <FormikInput
                  name={'recommended'}
                  type={'boolean'}
                  setFieldValue={(e, val) => setFieldValue(`invoice_products[${index}].recommended`, val)}
                  errors={errors}
                />
              </Grid>
            }
          </Grid>
        </Grid>
        <FormikInput
          useBrandingColor={'secondary'}
          name={`invoice_products[${index}].quantity`}
          onChange={e => {
            setFieldValue(`invoice_products[${index}].quantity`, e.target.value)
            setFieldValue(`invoice_products[${index}].total`, parseInt(e.target.value || 0) * parseInt(field.price || 0))
          }}
          label={'Quantity'}
          placeholder={'0'}
          errors={errors}
          type={'numeric'}
          className="top-grid"
          item xs={6} lg={2} md={2}
        />
        <FormikInput
          useBrandingColor={'secondary'}
          name={`invoice_products[${index}].price`}
          label={'Price'}
          errors={errors}
          disabled={true}
          className="top-grid"
          item xs={6} lg={2} md={2}
        />
        <FormikInput
          name={`invoice_products[${index}].total`}
          label={'Total'}
          errors={errors}
          disabled={true}
          className="top-grid"
          item xs={6} lg={2} md={2}
          helperInput={values?.invoice_products?.[1] &&
            <MDButton
              variant="contained"
              className="mini-button error"
              size="small"
              style={{ float: 'right' }}
              onClick={() => {
                let temp = [...values.invoice_products]
                temp.splice(index, 1)
                setFieldValue(`invoice_products`, temp)
              }}
            >Delete</MDButton>
          }
        />

      </React.Fragment>
    )
  }

  const invoiceCustomField = (index, setFieldValue, errors, values, field) => {
    return (
      <React.Fragment key={`custom_${index}`}>
        <Grid item xs={12} lg={6} md={6} mt={3}>
          <Grid container height={100}>
            <FormikInput
              useBrandingColor={'secondary'}
              name={`invoice_custom[${index}].custom`}
              setFieldValue={e => setFieldValue(`invoice_custom[${index}].custom`, e.target.val)}
              label={''}
              labelFieldName={'name'}
              placeholder={'line item'}
              errors={errors}
              item xs={11} lg={12} md={12}
            />
            <Grid item xs={10} lg={5} md={6} display={'flex'}>
              <MDButton
                color="green"
                className={`mini-button ${field?.subtotal ? 'sub' : ''}`}
                size="small"
                onClick={() => [
                  setFieldValue(`invoice_custom[${index}].subtotal`, true),
                  setFieldValue(`invoice_custom[${index}].recommended`, false)
                ]}
              >Subtotal</MDButton>
              <MDButton
                color="green"
                useBrandingColor={'primary'}
                className={`mini-button ${!field?.subtotal ? 'sub' : ''}`}
                size="small"
                onClick={() => setFieldValue(`invoice_custom[${index}].subtotal`, false)}
              >Optional</MDButton>
            </Grid>
            {!field?.subtotal &&
              <Grid item xs={12} lg={7} md={6} display={'flex'}>
                <MDTypography style={{ fontSize: 14, fontWeight: 600, float: 'left', marginRight: 10, marginTop: 10, maxWidth: 200 }}>
                  Recommended / (If selected include in the total)
                </MDTypography>
                <FormikInput
                  name={'recommended'}
                  type={'boolean'}
                  setFieldValue={(e, val) => setFieldValue(`invoice_custom[${index}].recommended`, val)}
                  errors={errors}
                />
              </Grid>
            }
          </Grid>
        </Grid>
        <FormikInput
          useBrandingColor={'secondary'}
          name={`invoice_custom[${index}].quantity`}
          onChange={e => {
            setFieldValue(`invoice_custom[${index}].quantity`, e.target.value)
            setFieldValue(`invoice_custom[${index}].total`, parseInt(e.target.value || 0) * parseInt(field.price || 0))
          }}
          label={'Quantity'}
          placeholder={'0'}
          errors={errors}
          type={'numeric'}
          className="top-grid"
          item xs={6} lg={2} md={2}
        />
        <FormikInput
          useBrandingColor={'secondary'}
          name={`invoice_custom[${index}].price`}
          onChange={e => {
            setFieldValue(`invoice_custom[${index}].price`, e.target.value)
            setFieldValue(`invoice_custom[${index}].total`, parseInt(e.target.value || 0) * parseInt(field.quantity || 0))
          }}
          label={'Price'}
          errors={errors}
          className="top-grid"
          item xs={6} lg={2} md={2}
        />
        <FormikInput
          name={`invoice_custom[${index}].total`}
          label={'Total'}
          errors={errors}
          disabled={true}
          className="top-grid"
          item xs={6} lg={2} md={2}
          helperInput={values?.invoice_custom?.[1] &&
            <MDButton
              variant="contained"
              className="mini-button error"
              size="small"
              style={{ float: 'right' }}
              onClick={() => {
                let temp = [...values.invoice_custom]
                temp.splice(index, 1)
                setFieldValue(`invoice_custom`, temp)
              }}
            >Delete</MDButton>
          }
        />

      </React.Fragment>
    )
  }

  const saveDialog = <MDDialog
    open={open}
    title={"Create"}
    handleClose={handleClose}
  >
    <Grid container spacing={2} pl={4} pr={4}>
      <Grid item xs={6} md={6} sm={6}>
        <MDTypography>Send by Email </MDTypography>
      </Grid>
      <Grid item xs={6} md={6} sm={6} justifyContent="right" alignItems="end">
        <MDCheckbox
          alignment={"right"}
          onChange={(e) => setSaveAnd({ ...SaveAnd, email: e.target.checked })}
          checked={SaveAnd?.email}
        />
      </Grid>
      <Grid item xs={6} md={6} sm={6}>
        <MDTypography>Send by Text</MDTypography>
      </Grid>
      <Grid item xs={6} md={6} sm={6}>
        <MDCheckbox
          alignment={"right"}
          onChange={(e) => setSaveAnd({ ...SaveAnd, sms: e.target.checked })}
          checked={SaveAnd?.sms}
        />
      </Grid>
      <Grid item xs={6} md={6} sm={6}>
        <MDTypography>Both</MDTypography>
      </Grid>
      <Grid item xs={6} md={6} sm={6}>
        <MDCheckbox
          alignment={"right"}
          onChange={(e) => setSaveAnd({ ...SaveAnd, email: e.target.checked, sms: e.target.checked })}
          checked={SaveAnd?.sms && SaveAnd?.email}
          name={"sendBoth"}
        />
      </Grid>
      <Grid item xs={12} md={12} sm={12} display="flex" justifyContent="center" alignItems="center">
        <MDButton
          color="green"
          type="submit"
          className={'btn-save-dialog'}
          variant="outlined"
          onClick={handleClose}
        >
          Cancelar
        </MDButton>
        <MDButton
          color="green"
          type="submit"
          className={'btn-save-dialog'}
          style={{ marginLeft: 20, marginRight: 20 }}
          disabled={!SaveAnd?.sms && !SaveAnd?.email}
          onClick={() => setOpenSendEmail(true)}
        >
          Select
        </MDButton>
      </Grid>
    </Grid>
  </MDDialog>

  const sendEmailDialog = <MDDialog
    open={openSendEmail}
    title={
      (SaveAnd?.sms && SaveAnd?.email)
        ? "Send By Email and Text"
        : `Send By ${SaveAnd?.email ? 'Email' : 'Text'}`
    }
    handleClose={() => setOpenSendEmail(false)}
  >
    <Grid container spacing={2} pl={4} pr={4}>
      {SaveAnd?.email &&
        <Grid item xs={12} md={12} sm={12}>
          <MDTypography variant="h6" fontWeight="bold">
            Title
          </MDTypography>
          <MDInput
            fullWidth
            placeholder='type title'
          />
        </Grid>
      }
      <Grid item xs={12} md={12} sm={12}>
        <MDTypography variant="h6" fontWeight="bold">
          Text
        </MDTypography>
        <MDInput
          fullWidth
          multiline rows={5}
          placeholder='type message'
        />
      </Grid>
      <Grid item xs={12} md={12} sm={12} mt={4} display="flex" justifyContent="center" alignItems="center">
        <MDButton
          color="green"
          type="submit"
          className={'btn-save-dialog'}
          variant="outlined"
          onClick={() => setOpenSendEmail(false)}
        >
          Cancelar
        </MDButton>
        <MDButton
          color="green"
          type="submit"
          className={'btn-save-dialog'}
          style={{ marginLeft: 20, marginRight: 20 }}
        >
          Send
        </MDButton>
      </Grid>
    </Grid>
  </MDDialog>

  const taxRateDialog = <MDDialog
    open={openTax}
    title={"Create Tax Rate"}
    handleClose={handleCloseTax}
  >
     <Formik
            validateOnBlur={false}
            validateOnChange={false}
            initialValues={{}}
            onSubmit={(values) => saveTax(values)}
        >
            {({ errors, setFieldValue, values }) => {
                return (
                    <Form>
                        <Grid container spacing={{ xs: 2, md: 3, lg: 3 }} alignItems={'flex-start'}>
                            <FormikInput
                                useBrandingColor={'secondary'}
                                name={'title'}
                                label={'Title'}
                                placeholder={'input tax title'}
                                errors={errors}
                                item xs={12}
                            />
                            <FormikInput
                                useBrandingColor={'secondary'}
                                name={'tax_rate'}
                                label={'Tax Rate'}
                                placeholder={'input tax rate'}
                                type={'numeric'}
                                errors={errors}
                                item xs={12}
                            />
                            <FormikInput
                                useBrandingColor={'secondary'}
                                name={'percentage'}
                                label={'Percentage'}
                                type={'boolean'}
                                values={values.checked}
                                setFieldValue={setFieldValue}
                                errors={errors}
                                item xs={6}
                            />

                            <Grid item xs={12} lg={12} md={12} mb={3} display="flex" justifyContent="center" alignItems="center">
                                <MDButton
                                    useBrandingColor={'primary'}
                                    color="green"
                                    type="submit"
                                    className={'btn-save-task'}
                                >
                                    SAVE
                                </MDButton>
                            </Grid>
                        </Grid>
                    </Form>
                )
            }}
        </Formik>
  </MDDialog>

  return (
    <AdminLayout title={id ? "Edit Invoice" : "New Invoice"}>
      <Grid container alignItems="top" paddingTop={0} marginTop={0}>
        <Grid item xs={12} lg={9} md={9} ml={4}>
          <MDTypography useBrandingColor={'tertiary'} variant="h2" fontWeight="medium" mb={1}>
            {id ? "Edit Invoice" : "New Invoice"}
          </MDTypography>

        </Grid>
      </Grid>
      <Formik
        innerRef={formikRef}
        initialValues={invoiceInitialValues(Invoice)}
        validationSchema={invoiceValidationSchema}
        validateOnBlur={false}
        validateOnChange={false}
        enableReinitialize
        onSubmit={values => {
          saveInvoice(values)
        }}
      >
        {({ errors, setFieldValue, values }) => {
          return (
            <Form>
              <Grid container spacing={3} pb={3} className="task_container">
                {values?.invoice_for
                  ? [
                    <Grid item xs={12} lg={3} md={6}>
                      <MDTypography variant="h6" fontWeight="bold">Invoice for</MDTypography>
                      <MDTypography variant="h6" color="green">{values?.invoice_for?.name || values?.invoice_for?.full_name}</MDTypography>
                      <MDTypography variant="h6">
                        {values?.invoice_for?.address || ' '}
                        <BorderColorOutlinedIcon
                          onClick={() => setFieldValue('invoice_for', null)}
                          color="green"
                        />
                      </MDTypography>
                    </Grid>,
                    <Grid item xs={12} lg={3} md={6}>
                      <MDTypography variant="h6" fontWeight="bold">Contact</MDTypography>
                      <MDTypography variant="h6">{values?.invoice_for?.email}</MDTypography>
                      <MDTypography variant="h6">{values?.invoice_for?.phone_number}</MDTypography>
                    </Grid>
                  ] : <FormikInput
                    useBrandingColor={'secondary'}
                    name={'invoice_for'}
                    label={'Invoice For'}
                    labelFieldName={'name'}
                    type={'select'}
                    placeholder={'select client'}
                    setFieldValue={setFieldValue}
                    options={Clients}
                    errors={errors}
                    item xs={12} lg={6} md={6}
                  />
                }
                {id !== undefined
                  ? <Grid item xs={12} lg={6} md={6}>
                    <MDTypography variant="h6" fontWeight="bold" align="right">{`Invoice #QN${id}`}</MDTypography>
                    <MDTypography variant="h6" align="right">{`Sales Rep: ${loginStore.name}`}</MDTypography>
                  </Grid>
                  : <Grid item xs={12} lg={6} md={6} />
                }

                <FormikInput
                  useBrandingColor={'secondary'}
                  name={'title'}
                  label={'Title'}
                  placeholder={'input title'}
                  errors={errors}
                  item xs={12} md={6} lg={6}
                />
                <FormikInput
                  useBrandingColor={'secondary'}
                  name={'template'}
                  label={'Template'}
                  labelFieldName={'title'}
                  placeholder={'select template'}
                  type={'select'}
                  setFieldValue={(e, val) => setValuesFromTemplate(setFieldValue, val)}
                  options={Templates}
                  errors={errors}
                  item xs={12} lg={6} md={6}
                />
              </Grid>

              <Grid container spacing={3} pb={3} className="task_container">

                {/* invoice products */}
                <Grid item xs={12} lg={12} md={12}>
                  <MDTypography variant="h6" fontWeight="bold">
                    Product / Service / Matrix
                  </MDTypography>
                </Grid>
                <FieldArray
                  name="invoice_products"
                  errors={errors}
                  render={arrayHelpers => {
                    arrayHelpersRef.current = arrayHelpers
                    return values.invoice_products.map((field, index) => invoiceProductField(index, setFieldValue, errors, values, field))
                  }}
                />
                <Grid item xs={6} lg={6} md={6} />
                <Grid item xs={12} lg={6} md={6}>
                  <hr className="MuiDivider-root MuiDivider-fullWidth" style={{ background: '#C6C9CE', margin: '16px 0' }} />
                  <MDTypography variant="h6" fontWeight="bold" style={{ float: 'left' }}>
                    Product/Service subtotal:
                  </MDTypography>
                  <MDTypography variant="h6" fontWeight="bold" color="green" style={{ float: 'right' }}>
                    <u>${getSubTotal(values, 'invoice_products')}</u>
                  </MDTypography>
                </Grid>
                {/*  */}

                {/* invoice custom */}
                <Grid item xs={12} lg={12} md={12}>
                  <MDTypography variant="h6" fontWeight="bold">
                    Custom
                  </MDTypography>
                </Grid>
                <FieldArray
                  name="invoice_custom"
                  render={arrayHelpers => {
                    arrayHelpersMatrixRef.current = arrayHelpers
                    return values.invoice_custom.map((field, index) => invoiceCustomField(index, setFieldValue, errors, values, field))
                  }}
                />
                {/* subtotal */}
                <Grid item xs={6} lg={6} md={6} />
                <Grid item xs={12} lg={6} md={6}>
                  <hr className="MuiDivider-root MuiDivider-fullWidth" style={{ background: '#C6C9CE', margin: '16px 0' }} />
                  <MDTypography variant="h6" fontWeight="bold" style={{ float: 'left' }}>
                    Matrix subtotal:
                  </MDTypography>
                  <MDTypography variant="h6" fontWeight="bold" color="green" style={{ float: 'right' }}>
                    <u>${getSubTotal(values, 'invoice_custom')}</u>
                  </MDTypography>
                </Grid>

                <Grid item xs={6} lg={6} md={6} />
                <Grid item xs={12} lg={3} md={6}>
                  <MDButton
                    useBrandingColor={'primary'}
                    onClick={() => arrayHelpersRef?.current?.push(...initialInvoiceProduct)}
                    fullWidth color="green"
                  >Add Item +</MDButton>
                </Grid>
                <Grid item xs={12} lg={3} md={6} >
                  <MDButton
                    useBrandingColor={'primary'}
                    onClick={() => arrayHelpersMatrixRef?.current?.push(...initialInvoiceMatrix)}
                    fullWidth color="green"
                  >Add Custom +</MDButton>
                </Grid>

                <Grid item xs={12} lg={12} md={12}>
                  <hr className="MuiDivider-root MuiDivider-fullWidth" style={{ background: '#C6C9CE', margin: '12px 0' }} />
                </Grid>

                <FormikInput
                  useBrandingColor={'secondary'}
                  name={'note_for_client'}
                  label={'Note for the Client'}
                  placeholder={'input note'}
                  errors={errors}
                  type={'textarea'}
                  rows={5}
                  item xs={12} lg={6} md={6}
                />
                <Grid item container xs={12} lg={6} md={6} spacing={2}>
                  <FormikInput
                    useBrandingColor={'secondary'}
                    name={`subtotal`}
                    label={'Subtotal'}
                    placeholder={`$${getSubTotal(values, 'invoice_custom') + getSubTotal(values, 'invoice_products')}`}
                    errors={errors}
                    disabled={true}
                    className="top-grid"
                    item xs={6} lg={12} md={2}
                  />
                  <FormikInput
                    useBrandingColor={'secondary'}
                    name={'discount'}
                    label={'Discount'}
                    labelFieldName={'name'}
                    placeholder='input discount'
                    type={'numeric'}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    item xs={6} lg={12} md={2}
                  />

                  <FormikInput
                    useBrandingColor={'secondary'}
                    name={'tax'}
                    label={'Tax'}
                    labelFieldName={'title'}
                    placeholder='auto tax calculated based on user settings'
                    type={'select'}
                    setFieldValue={setFieldValue}
                    options={Taxes}
                    errors={errors}
                    item xs={12} lg={12} md={2}
                    helperInput={
                      <MDTypography
                        sx={{ textDecoration: "underline", cursor: 'pointer' }}
                        color='success'
                        variant={'h6'}
                        onClick={() => setOpenTax(true)}
                      >Create Tax</MDTypography>
                    }
                  />

                {values?.tax &&
                  <FormikInput
                  useBrandingColor={'secondary'}
                  name={`tax_amount`}
                  label={'Tax Amount'}
                  placeholder={`$${
                    calculateTax(
                      (getSubTotal(values, 'invoice_custom') + getSubTotal(values, 'invoice_products') - values.discount),
                      values.tax
                    )}`}
                  errors={errors}
                  disabled={true}
                  className="top-grid"
                  item xs={6} lg={12} md={2} mt={2}
                  />
                }
                </Grid>

                <Grid item xs={12} lg={12} md={12}>
                  <hr className="MuiDivider-root MuiDivider-fullWidth" style={{ background: '#BFE3CD', margin: '12px 0' }} />
                </Grid>

                <FormikInput
                  useBrandingColor={'secondary'}
                  name={'internal_note'}
                  label={'Internal Notes & Attachments'}
                  placeholder={'input internal note'}
                  errors={errors}
                  type={'textarea'}
                  item xs={9} lg={5} md={5}
                />
                <FormikInput
                  inputFileStyles={{ borderRadius: '8px', width: '38px', height: '38px' }}
                  errors={errors}
                  useBrandingColor={'primary'}
                  name={'total'}
                  type={'file'}
                  handleChange={(e) => handleUploadFile(e)}
                  item xs={3} lg={1} md={1}
                  p={4}
                  mt={7}
                />
                <FormikInput
                  name={`total`}
                  label={'Total'}
                  placeholder={`$${getSubTotal(values, 'invoice_custom')
                    + getSubTotal(values, 'invoice_products')
                    - values.discount
                    + calculateTax(
                      (getSubTotal(values, 'invoice_custom') + getSubTotal(values, 'invoice_products') - values.discount),
                      values.tax)
                    }`}
                  errors={errors}
                  field={{ className: "input-success" }}
                  item xs={12} lg={6} md={6} mt={3}
                  helperInput={
                    <hr className="MuiDivider-root MuiDivider-fullWidth" style={{ background: '#C6C9CE', margin: '16px 0' }} />
                  }
                />

                <Grid item xs={9} lg={6} md={6} >
                  {file?.name
                    ? <MDTypography mx={3} variant={'subtitle2'}>{file?.name}</MDTypography>
                    : Invoice?.attachment
                      ? <MDTypography
                        variant="h6"
                        fontWeight={"medium"}
                        color='primary'
                        sx={{ cursor: 'pointer' }}
                        onClick={() => getInvoiceFile(Invoice?.id)}
                      >Download Attachment</MDTypography>
                      : <></>
                  }
                </Grid>

                <Grid item xs={9} lg={6} md={6} gap={1} display='flex' justifyContent={'space-between'}>
                  {DepositRequired
                    ? <>
                      <FormikInput
                        name={'required_deposit'}
                        label={'Amount'}
                        placeholder={'input amount'}
                        errors={errors}
                        type={'numeric'}
                        item xs={9} lg={9} md={9}
                      />
                      <FormikInput
                        name={'required_deposit_percentage'}
                        label={'Type'}
                        labelFieldName={'label'}
                        type={'select'}
                        placeholder={'$'}
                        setFieldValue={setFieldValue}
                        options={[{ id: false, label: '$' }, { id: true, label: '%' }]}
                        errors={errors}
                        item xs={3} lg={3} md={3}
                      />
                    </>
                    : <>
                      <MDTypography variant={'h6'}>Required Deposit</MDTypography>
                      <MDTypography
                        sx={{ textDecoration: "underline", cursor: 'pointer' }}
                        color='success'
                        variant={'h6'}
                        onClick={() => setDepositRequired(true)}
                      >Add Deposit</MDTypography>
                    </>
                  }
                </Grid>

                <Grid item xs={12} lg={12} md={12} mt={5} display={{ lg: "flex", xs: "block" }} justifyContent="space-evenly" alignItems="center">
                  <Grid item xs={12} lg={3} md={12} display='flex' mt={{ xs: 1 }}>
                    <MDButton
                      useBrandingColor={'primary'}
                      color="green"
                      variant="outlined"
                      fullWidth
                      className="btn-save"
                      onClick={() => navigate(ROUTES.INVOICES)}
                    >
                      Cancel
                    </MDButton>
                  </Grid>
                  <Grid item xs={12} lg={3} md={12} display='flex' mt={{ xs: 1 }}>
                    <MDButton
                      useBrandingColor={'primary'}
                      color="green"
                      type="submit"
                      fullWidth
                      className="btn-save"
                    >
                      Save Invoice
                    </MDButton>
                  </Grid>
                  <Grid item xs={12} lg={3} md={12} display='flex' mt={{ xs: 1 }}>
                    <MDButton
                      useBrandingColor={'primary'}
                      color="green"
                      fullWidth
                      onClick={handleClickOpen}
                      className="btn-save"
                    >
                      Save and Send
                    </MDButton>
                  </Grid>
                </Grid>
                <Grid item xs={12} lg={12} md={12} mt={2} mb={2} display="flex" justifyContent="center" alignItems="center">
                  <MDButton
                    useBrandingColor={'primary'}
                    color="green"
                    onClick={() => saveInvoice(values, true)}
                    className={'btn-save'}
                    width={100}
                  >
                    Create Template
                  </MDButton>
                </Grid>
              </Grid>
            </Form>
          )
        }}
      </Formik>
      {saveDialog}
      {sendEmailDialog}
      {taxRateDialog}
    </AdminLayout >
  )
}
