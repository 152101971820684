import React, {useState, useRef, useEffect} from 'react';
import {useFormik, FormikProvider, FieldArray} from 'formik';
import * as Yup from 'yup';

// @mui components
import {Grid, InputAdornment, IconButton} from '@mui/material';

// Components
import AdminLayout from "../../../components/AdminLayout";
import MDDialog from "components/MDDialog";
import FormikInput from "components/FormikInput";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Utils
import {makeid, money_fmt, showMessage} from "services/helpers";
import {useStores} from "models";

// icons and theme
import MoneyIcon from "assets/images/icons/money.svg";
import EditIcon from "assets/images/icons/edit.png";
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import pxToRem from "assets/theme/functions/pxToRem";
import "../styles.css";

const baseSubscription = {
  title: 'Lorem Ipsum',
  price: 10,
  billed: 'Month',
  description: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit.',
  assets: [
    {asset: 'Lorem ipsum'},
    {asset: 'Lorem ipsum'},
    {asset: 'Lorem ipsum'},
    {asset: 'Lorem ipsum'},
  ],
};

const billedOptions = [
  { id: 1, value: 1, label: 'Monthly' },
  { id: 2, value: 2, label: 'Yearly' },
];

const validationSchema = Yup.object().shape({
  title: Yup.string().required(),
  price: Yup.number().required(),
  billed: Yup.object().required(),
  description: Yup.string(),
  // assets: Yup.array(),
});


export default function TeamSettingsPage() {
  const rootStore = useStores();
  const {loginStore} = rootStore;
  const [loading, setLoading] = useState(false);
  const [showCreateSubsModal, setShowCreateSubsModal] = useState(false);
  const [subscriptions, setSubscriptions] = useState([]);
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const arrayHelperAsset = useRef(null);
  const formik = useFormik({
    initialValues: {
      id: selectedSubscription ? selectedSubscription.id : '',
      title: selectedSubscription ? selectedSubscription.title : '',
      price: selectedSubscription ? selectedSubscription.price : 0,
      billed: selectedSubscription ? selectedSubscription.billed : '',
      description: selectedSubscription ? selectedSubscription.description : '',
      assets: selectedSubscription ? selectedSubscription.assets : [],
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      let valuesCopy = {...values};
      valuesCopy.billed = values.billed.value;
      valuesCopy.assets = JSON.stringify(values.assets);      
      createSubscription(valuesCopy);
    }
  });
  
  const handleCloseForm = () => {
    setShowCreateSubsModal(false);
    setSelectedSubscription(null);
    formik.resetForm();
  }
  
  const getSubscriptions = (searchData) => {
    setLoading(true);
    loginStore.environment.api.getSubscriptions(searchData)
    .then((result) => {
      if (result.kind === "ok") {
        let results = result.data.results;
        // assets must be a parseable array
        results = results.map((su, sidx) => ({...su, assets: JSON.parse(su.assets ? su.assets : "[]")}) );
        setSubscriptions(results);
      } else {
        if (result.kind === "bad-data") {
          showMessage(result?.errors, 'error', true);
        } else {
          showMessage('An error occurred, please try again later');
        }
      }
    })
    .finally(() => setLoading(false));
  }
  
  const createSubscription = (data) => {
    setLoading(true);
    loginStore.environment.api.createSubscription(data)
    .then((result) => {
      if (result.kind === "ok") {
       showMessage("Subscription created successfully", 'success');
       getSubscriptions();
       setShowCreateSubsModal(false);
      } else {
        if (result.kind === "bad-data") {
          showMessage(result?.errors, 'error', true);
        } else {
          showMessage('An error occurred, please try again later');
        }
      }
    })
    .finally(() => setLoading(false));
  }
  
  const updateSubscription = (data) => {
    setLoading(true);
    loginStore.environment.api.updateSubscription(data)
    .then((result) => {
      if (result.kind === "ok") {
       showMessage("Subscription edited successfully", 'success');
       getSubscriptions();
       setShowCreateSubsModal(false);
      } else {
        if (result.kind === "bad-data") {
          showMessage(result?.errors, 'error', true);
        } else {
          showMessage('An error occurred, please try again later');
        }
      }
    })
    .finally(() => setLoading(false));
  }
  
  const handleSelectSubscription = (selected) => {
    let selectedCopy = {...selected};
    const billedOption = billedOptions.find((opt, oidx)=> opt.label === selectedCopy.billed);
    selectedCopy.billed = billedOption;
    setShowCreateSubsModal(true)
    setSelectedSubscription(selectedCopy);
  }

  const editSubscription = () => {
    let formikValues = {...formik.values}
    formikValues.billed = formikValues.billed.value;
    formikValues.assets = JSON.stringify(formikValues.assets);
    updateSubscription(formikValues);
  }

  useEffect(() => {
    getSubscriptions();
  }, []);

  return (
    <AdminLayout title={"Subscriptions"} searchFunc={getSubscriptions} loading={loading}>
      <Grid container spacing={3} justifyContent='space-between' alignItems='center'>
        <Grid item xs={1} md={5} ml={{xs: 1, sm: 2}}>
          <MDTypography variant="h3" fontWeight="medium" mb={1}>
            Avaiable Subscriptions
          </MDTypography>
        </Grid>
      </Grid>
      <Grid
        className="subs-container"
        container
        flexWrap='nowrap'
        overflow='auto'
        justifyContent='start'
        pl={{xs: 0, sm: 2}}
        spacing={{xs: 45, xl: 40, xxl: 15}}
      >
        {subscriptions.map((s) => (
          <Grid item xs={3} key={s.id}>
            <div className="subs-item">
              <MDTypography variant="h4">{s?.title}</MDTypography>
              <div className="subs-data">
                <p>{money_fmt(s?.price)}<span>/ {s?.billed}</span></p>
                <span>
                 {s?.description}
                </span>
              </div>
              <ul className="subs-list">
                {s.assets.map((a) => (
                  <li key={makeid(10)}>
                    <CheckCircleOutlineRoundedIcon/>
                    <p>{a}</p>
                  </li>
                ))}
              </ul>
              <MDButton
                color="green"
                useBrandingColor={'primary'}
                onClick={() => handleSelectSubscription(s)}
              >
                Choose Plan
              </MDButton>
            </div>
          </Grid>
        ))}
      </Grid>
    </AdminLayout>
  )
}
