import React, { useState } from 'react';
import { Link } from "react-router-dom";
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MDButton from "components/MDButton";
import { useNavigate, useParams } from "react-router-dom";
import { Grid, Typography } from '@mui/material';
import MDDialog from "components/MDDialog";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MenuIcon from 'assets/images/icons/menu.png';
import CopyIcon from 'assets/images/icons/copy.png';
import DepositIcon from 'assets/images/icons/deposit.png';
import JobsIcon from 'assets/images/icons/jobs.png';
import MessageIcon from 'assets/images/icons/messages.png';
import TextIcon from 'assets/images/icons/text.png';
import ClientsIcon from 'assets/images/icons/clients.png';
import SignIcon from 'assets/images/icons/signature.png';
import PrintIcon from 'assets/images/icons/print.png';
import DownloadIcon from 'assets/images/icons/download.png';
import "./tables.css";
import MDCheckbox from 'components/MDCheckbox';
import MDTypography from 'components/MDTypography';
import pxToRem from "assets/theme/functions/pxToRem";
import { ROUTES } from 'constants'
import { useStores } from "models";
import { showMessage, cardBrandIcon, useApi } from "services/helpers";

export const MenuButton = (props) => {
    const navigate = useNavigate();
    const rootStore = useStores();
    const api = useApi()

    const { loginStore } = rootStore;

    const [anchorEl, setAnchorEl] = useState(null);
    const [active, setActive] = useState(false);

    const [SMSModalOpen, setSMSModalOpen] = useState(false);
    const [SMSText, setSMSText] = useState('');

    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setActive(true);
    };
    const handleClose = () => {
        setAnchorEl(null);
        setActive(false);
    };

    const executeQuoteAction = (code) => {
        api.updateQuoteStatus(props.quote_id, {code})
          .then(() => window.location.reload())
          .catch(() => showMessage())
          .finally(() => window.location.reload(false))
      }

    const sendMessage = () => {
        loginStore.environment.api.sendMessage({ phone: props.client_phone, text: SMSText }).then((result) => {
            if (result.kind === "ok") {
                showMessage('The text message has been sent', 'success')
                setSMSModalOpen(false)
            }
            else {
                if (result.kind === "bad-data") showMessage(result?.errors, 'error', true)
                else showMessage('An error occurred, please try again later')
            }
        })
    }

    const sendSMSModal = () => (
        <MDDialog
            open={SMSModalOpen}
            handleClose={() => setSMSModalOpen(false)}
            title={'Type the message'}
            titleStyles={{ fontSize: pxToRem(24) }}
            rootStyles={{ overflowY: 'unset' }}
            style={{ width: '100%', paddingBlock: `${pxToRem(10)} ${pxToRem(30)}`, paddingInline: pxToRem(50) }}
        >
            <MDInput
                fullWidth
                placeholder='input text message'
                value={SMSText}
                onChange={evt => setSMSText(evt.target.value)}
            />
            <MDBox sx={{ display: 'flex', gap: pxToRem(24), marginTop: pxToRem(46) }}>
                <MDButton
                    useBrandingColor={'primary'}
                    color="green"
                    variant="outlined"
                    type="submit"
                    className={"button-action"}
                    onClick={() => setSMSModalOpen(false)}
                >
                    Cancel
                </MDButton>

                <MDButton
                    useBrandingColor={'primary'}
                    color="green"
                    type="submit"
                    className={"button-action"}
                    onClick={() => sendMessage()}
                    disabled={!SMSText || SMSText === ''}
                >
                    Continue
                </MDButton>
            </MDBox>
        </MDDialog>
    )

    return (
        <div>
            <MDButton
                color="green"
                onClick={handleClick}
                iconOnly
                useBrandingColor={'primary'}
                style={props.style}
            > <img src={MenuIcon} alt='menu' /> </MDButton>

            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                className="menu-basics"
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={handleClose}>
                    <MDButton
                        color="green"
                        className={'btn-import-sub'}
                        sx={{ display: 'flex', justifyContent: 'flex-start' }}
                        useBrandingColor={'primary'}
                        startIcon={<img src={CopyIcon} className="btn-import-sub-icon" style={{ marginRight: '10px' }} alt='CopyIcon' />}
                        component={Link}
                        onClick={() => navigate(ROUTES.NEW_QUOTES)}
                        to="/"
                    > Create similar
                    </MDButton>

                </MenuItem>
                <MenuItem onClick={handleClose}>
                    <MDButton
                        color="green"
                        className={'btn-import-sub'}
                        sx={{ display: 'flex', justifyContent: 'flex-start' }}
                        useBrandingColor={'primary'}
                        startIcon={<img src={DepositIcon} className="btn-import-sub-icon" style={{ marginRight: '10px' }} alt='DepositIcon' />}
                        onClick={() => navigate(ROUTES.QUOTE_EDIT(props.quote_id))}
                    > Collect deposit
                    </MDButton>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    <MDButton
                        color="green"
                        className={'btn-import-sub'}
                        sx={{ display: 'flex', justifyContent: 'flex-start' }}
                        useBrandingColor={'primary'}
                        startIcon={<img src={JobsIcon} className="btn-import-sub-icon" style={{ marginRight: '10px' }} alt='JobsIcon' />}
                        onClick={() => { }}
                    > Convert to Job
                    </MDButton>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    <MDButton
                        color="green"
                        className={'btn-import-sub'}
                        sx={{ display: 'flex', justifyContent: 'flex-start' }}
                        useBrandingColor={'primary'}
                        startIcon={<img src={MessageIcon} className="btn-import-sub-icon" style={{ marginRight: '10px' }} alt='MessageIcon' />}
                        onClick={() => window.location = `mailto:${props?.client_email}?body=${window.location.href}`}
                    > Send as message
                    </MDButton>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    <MDButton
                        color="green"
                        className={'btn-import-sub'}
                        sx={{ display: 'flex', justifyContent: 'flex-start' }}
                        useBrandingColor={'primary'}
                        startIcon={<img src={TextIcon} className="btn-import-sub-icon" style={{ marginRight: '10px' }} alt='TextIcon' />}
                        onClick={() => [setSMSModalOpen(true), setSMSText(window.location.href)]}
                    > Send as text
                    </MDButton>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    <MDButton
                        color="green"
                        className={'btn-import-sub'}
                        sx={{ display: 'flex', justifyContent: 'flex-start' }}
                        useBrandingColor={'primary'}
                        startIcon={<img src={ClientsIcon} className="btn-import-sub-icon" style={{ marginRight: '10px' }} alt='ClientsIcon' />}
                        onClick={props.client_view_action}
                    > View as client
                    </MDButton>
                </MenuItem>
                {/* <MenuItem onClick={handleClose}>
                    <MDButton
                        color="green"
                        className={'btn-import-sub'}
                        sx={{ display: 'flex', justifyContent: 'flex-start'}}
                        useBrandingColor={'primary'}
                        startIcon={<img src={SignIcon} className="btn-import-sub-icon" style={{ marginRight: '10px'}} alt='SignIcon' />}
                        onClick={() => {}}
                    > Collect signature
                    </MDButton>
                </MenuItem> */}
                <Grid container p={2} style={{ maxWidth: 250 }}>
                    <Grid item xs={6}>
                        <MDTypography variant="h6" style={{ float: 'left' }}>Approved </MDTypography>
                    </Grid>
                    <Grid item xs={6}>
                        <MDCheckbox
                            alignment={'right'}
                            onChange={(e) => {
                                console.log(' e ===>>> ', e.target.checked)
                                if (e.target.checked) executeQuoteAction('APPROVED')
                            }}
                            checked={props.quote_status === 'Approved'}
                            />
                    </Grid>
                    <Grid item xs={6}>
                        <MDTypography variant="h6" style={{ float: 'left' }}>Archived </MDTypography>
                    </Grid>
                    <Grid item xs={6}>
                        <MDCheckbox
                            alignment={'right'}
                            onChange={(e) => {
                                console.log(' e ===>>> ', e.target.checked)
                                if (e.target.checked) executeQuoteAction('ARCHIVED')
                            }}
                            checked={props.quote_status === 'Archived'}
                            />
                    </Grid>
                    <Grid item xs={6}>
                        <MDTypography variant="h6" style={{ float: 'left' }}>Mark As Sent </MDTypography>
                    </Grid>
                    <Grid item xs={6}>
                        <MDCheckbox
                            alignment={'right'}
                            onChange={(e) => {
                                console.log(' e ===>>> ', e.target.checked)
                                if (e.target.checked) executeQuoteAction('AWAITING_RESPONSE')
                            }}
                            checked={props.quote_status === 'Awaiting response'}
                            />
                    </Grid>
                </Grid>
                <hr className="MuiDivider-root MuiDivider-fullWidth" style={{ background: '#C6C9CE', margin: '10px 0' }} />
                <Grid item xs={12} display='flex' justifyContent={'space-around'}>
                    <MDButton
                        color="green"
                        iconOnly
                        useBrandingColor={'primary'}
                        onClick={() => window.location = `mailto:${props?.client_email}`}
                    >
                        <img src={MessageIcon} alt='MessageIcon' />
                    </MDButton>
                    <MDButton
                        color="green"
                        iconOnly
                        useBrandingColor={'primary'}
                        onClick={() => setSMSModalOpen(true)}
                    >
                        <img src={TextIcon} alt='TextIcon' />
                    </MDButton>
                    <MDButton
                        color="green"
                        iconOnly
                        useBrandingColor={'primary'}
                    >
                        <img src={PrintIcon} alt='PrintIcon' />
                    </MDButton>
                    <MDButton
                        color="green"
                        iconOnly
                        useBrandingColor={'primary'}
                    >
                        <img src={DownloadIcon} alt='DownloadIcon' />
                    </MDButton>
                </Grid>
            </Menu>
            {sendSMSModal()}
        </div>
    );
}
