import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { styled } from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TuneIcon from "@mui/icons-material/Tune";
import { Typography } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import "./tables.css";
import MDButton from 'components/MDButton';
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import Grid from "@mui/material/Grid";
import MDDialog from "components/MDDialog";

export const FilterButton = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open2, setOpen2] = React.useState({
    date: false,
    tipe: false
  });

  const [DateRangeModalOpen, setDateRangeModalOpen] = useState(false);
  const [DateFrom, setDateFrom] = useState(null);
  const [DateTo, setDateTo] = useState(null);

  const [ProjectTotalModalOpen, setProjectTotalModalOpen] = useState(false);
  const [TotalFrom, setTotalFrom] = useState(null);
  const [TotalTo, setTotalTo] = useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  const handleClick2 = (data) => {
    setOpen2(false);
    setOpen2({
      [data]: !open2[data]
    });
  };

  const dateRangeModal = <MDDialog
    open={DateRangeModalOpen}
    handleClose={() => setDateRangeModalOpen(false)}
    title='Date Range'
    paddingTop={10}
    style={{ width: 600, maxHeight: 1000 }}
  >
    <Grid container spacing={{ xs: 2, md: 3, lg: 3 }} alignItems={'flex-start'}>
      <Grid item xs={6} lg={6} md={6}>
        <MDTypography variant="h6" fontWeight="bold">
          From
        </MDTypography>
        <MDInput
          fullWidth
          type="date"
          value={DateFrom}
          onChange={evt => setDateFrom(evt.target.value)}
        />
      </Grid>
      <Grid item xs={6} lg={6} md={6}>
        <MDTypography variant="h6" fontWeight="bold">
          To
        </MDTypography>
        <MDInput
          fullWidth
          type="date"
          value={DateTo}
          onChange={evt => setDateTo(evt.target.value)}
        />
      </Grid>
      <Grid item xs={12} lg={12} md={12} mb={3} display="flex" justifyContent="center" alignItems="center">
        <MDButton
          useBrandingColor={'primary'}
          color="green"
          type="submit"
          className={'btn-save-task'}
        >
          FILTER
        </MDButton>
      </Grid>
    </Grid>
  </MDDialog>

  const projectTotalRangeModal = <MDDialog
    open={ProjectTotalModalOpen}
    handleClose={() => setProjectTotalModalOpen(false)}
    title='Custom $ Range'
    paddingTop={10}
    style={{ width: 600, maxHeight: 1000 }}
  >
    <Grid container spacing={{ xs: 2, md: 3, lg: 3 }} alignItems={'flex-start'}>
      <Grid item xs={6} lg={6} md={6}>
        <MDTypography variant="h6" fontWeight="bold">
          From
        </MDTypography>
        <MDInput
          fullWidth
          type="number"
          value={TotalFrom}
          onChange={evt => setTotalFrom(evt.target.value)}
        />
      </Grid>
      <Grid item xs={6} lg={6} md={6}>
        <MDTypography variant="h6" fontWeight="bold">
          To
        </MDTypography>
        <MDInput
          fullWidth
          type="number"
          value={TotalTo}
          onChange={evt => setTotalTo(evt.target.value)}
        />
      </Grid>
      <Grid item xs={12} lg={12} md={12} mb={3} display="flex" justifyContent="center" alignItems="center">
        <MDButton
          useBrandingColor={'primary'}
          color="green"
          type="submit"
          className={'btn-save-task'}
        >
          FILTER
        </MDButton>
      </Grid>
    </Grid>
  </MDDialog>

  return (
    <>
      <MDButton
        variant="outlined"
        color="green"
        useBrandingColor={'primary'}
        endIcon={<ExpandMoreIcon />}
        startIcon={<TuneIcon />}
        onClick={handleClick}
        className={'btn-filter'}
      > short </MDButton>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <Typography className="menus-title" onClick={() => setDateRangeModalOpen(true)} sx={{ cursor: 'pointer' }}>
          By Date Created
        </Typography>
        <Typography className="menus-title" onClick={() => handleClick2('tipe')} sx={{ cursor: 'pointer' }}>
          Project Total {open2.tipe ? <ExpandLess /> : <ExpandMore />}
        </Typography>
        <Collapse in={open2.tipe} timeout="auto" unmountOnExit>
          <MenuItem onClick={handleClose}>Ascending</MenuItem>
          <MenuItem onClick={handleClose}>Descending</MenuItem>
          <MenuItem onClick={() => setProjectTotalModalOpen(true)}>Custom $ Range</MenuItem>
        </Collapse>
      </Menu>
      {dateRangeModal}
      {projectTotalRangeModal}
    </>
  );
}
