
import {useState, useEffect, useContext} from "react";

// prop-types is a library for typechecking of props

// @mui material components

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// Utils
import { useIsMobile } from "../../services/hooks";

// Assets
import phoneIcon from 'assets/images/icons/phone.svg';
import pxToRem from "../../assets/theme/functions/pxToRem";
import user from 'assets/images/icons/user.svg'
import { formatTimeSeconds } from 'services/helpers'
import mute from "../../assets/images/icons/mute.svg";
import speaker from "../../assets/images/icons/speaker.svg";
import {TwilioContext} from "../../services/phone_system";
import endCall from "../../assets/images/icons/end_call.svg";

function CallOngoing({  }) {
  const isMobile = useIsMobile();
  const [state, setState] = useState('In call');

  const [muted, setMuted] = useState(false);
  const { currentCall, currentClient, finishCall, currentNumber, currentCallTime } = useContext(TwilioContext);

  useEffect(() => {
    if (currentCall) {
      currentCall.on('mute', (isMuted, call) => {
        setMuted(isMuted)
      });
      currentCall.on('reconnecting', () => {
        setState('Reconnecting...')
      })
      currentCall.on('reconnected', () => {
        setState('In call')
      })
    }
  }, [currentCall])

  return (
    <>
    <MDBox sx={{ height: '84%'}}>
      <MDTypography sx={{ textAlign: 'center' }} variant="h4">{state}</MDTypography>
      <MDTypography sx={{ textAlign: 'center' }} mt={{ lg: 10, xs: pxToRem(20) }} variant="h4">{formatTimeSeconds(currentCallTime)}</MDTypography>

      <MDBox sx={{ width: '100%', height: 300, backgroundColor: '#c7c7c7' }}>
        <MDTypography sx={{ textAlign: 'center' }} mt={{ lg: 10, xs: pxToRem(20) }} variant="h6">
          {currentNumber}
          <br />
          {currentClient?.name}
        </MDTypography>
        <MDBox
          component={'img'} alt={'user'}
          src={user}
          sx={{ width: '100%', height: '16rem', objectFit: 'contain' }}
        />
      </MDBox>
      </MDBox>


      <MDBox pb={2}>
        <MDBox
          display="flex"
          alignItems="center"
          justifyContent={{ lg: 'space-around', xs: 'center' }}
          pt={1}
        >
          <MDBox
              variant="gradient"
              color={'white'}
              coloredShadow={'#FFBAAA'}
              borderRadius="xl"
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="4rem"
              height="4rem"
              ml={2}
              bgColor={'error'}
              onClick={finishCall}
          >
            <img src={endCall} alt='hangup' />
          </MDBox>

          <MDBox
              variant="gradient"
              color={'white'}
              coloredShadow={'#FFBAAA'}
              borderRadius="xl"
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="3rem"
              height="3rem"
              ml={2}
              bgColor={muted ? 'warning' : 'success'}
              onClick={() => currentCall?.mute(!muted) }
          >
            <img src={muted ? mute : speaker} alt={'mute'} />
          </MDBox>
        </MDBox>

      </MDBox>
    </>
  );
}

export default CallOngoing;
