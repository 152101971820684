import pxToRem from 'assets/theme/functions/pxToRem';
import colors from 'assets/theme/base/colors';
const {yellow, button, white, transparent} = colors;

export const styles = {
    company_name: {
      color: yellow.main,
      fontSize: pxToRem(24),
      fontWeight: 700
    },
    box_menu_handler: {
      alignItems: "center",
      borderRadius: '10px',
      cursor: 'pointer',
      display: "flex",
      flexDirection: "column",
      gap: '2px',
      height: pxToRem(45),
      justifyContent: "center",
      width: '35px !important',
    },
    dot: {
      borderRadius: '100px',
      border: `1.5px solid ${white.main}`,
      height: pxToRem(4),
      width: pxToRem(4),
    },
    popper: {
      borderRadius: '10px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      gap: '10px',
      marginLeft: '15px',
      marginTop: '10px',
    },
    menu: {
      background: `${transparent.main} !important`,
      padding: '0px !important',
      width: pxToRem(85),
      '.MuiList-root': {
          background: white.main,
          borderRadius: '10px',
          display: 'flex',
          flexDirection: 'column',
          gap: pxToRem(10),
          padding: pxToRem(10),
          paddingInline: pxToRem(7)
      },
      '.MuiPaper-root': {
          padding: 0,
          background: `${transparent.main} !important`,
      },
      display: 'flex',
      flexDirection: 'column',
      gap: pxToRem(10),
    },
    menu_item: {
      background:`${transparent.main} !important`,
      padding:' 0.3rem 1.2rem',
    }
  };
