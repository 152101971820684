import {ApiConfig, DEFAULT_API_CONFIG} from "./api-config"
import * as Types from "./api.types"
import {ApiBase} from "./api-base";
import {API_VERSION_PREFIX} from "../../constants";


/**
 * Manages all requests to the API.
 */
export class Api extends ApiBase {

    constructor(config: ApiConfig = DEFAULT_API_CONFIG) {
        super(config)
    }

    logout(device_id: string): Promise<Types.LogoutResult> {
        return this.noresult_post(`/logout/`, {device_id})
    }

    login(email: string, password: string) {
        return this.simple_post(`/dj-rest-auth/login/`, {
            email,
            password,
        })
    }

    adminLogin(email: string, password: string) {
        return this.simple_post(`${API_VERSION_PREFIX}/login/?admin=true`, {
            username: email,
            email,
            password,
        })
    }

    register(data: any) {
        return this.simple_post(`/dj-rest-auth/registration/`, data)
    }

    signup(data: any) {
        return this.simple_post(`${API_VERSION_PREFIX}/registration/set-company/`, data)
    }

    forgotPassword(email: string) {
        return this.simple_post(`${API_VERSION_PREFIX}/reset-password/`, {email: email})
    }

    resetPassword(data: any) {
        return this.simple_post(`${API_VERSION_PREFIX}/set-new-password/`, data)
    }

    activateAccount(data: any) {
        return this.simple_get(`${API_VERSION_PREFIX}/activate-user/`, data)
    }

    setPass(data: any) {
        return this.simple_post(`${API_VERSION_PREFIX}/registration/set-pass/`, data)
    }

    setClientPass(data: any) {
        return this.simple_post(`${API_VERSION_PREFIX}/registration/set-pass/?client=true`, data)
    }

    createClient(data: any) {
        return this.simple_post(`${API_VERSION_PREFIX}/users/`, data)
    }

    getClientDetail(id: any) {
        return this.simple_get(`${API_VERSION_PREFIX}/users/${id}/`)
    }

    getClientEditDetail(id: number) {
        return this.simple_get(`${API_VERSION_PREFIX}/users/${id}/detail_edit/`)
    }

    updateClient(data: any) {
        return this.simple_patch(`${API_VERSION_PREFIX}/users/${data.id}/`, data)
    }

    updateClientSettings(data: any) {
        return this.simple_post(`${API_VERSION_PREFIX}/users/${data.id}/update_settings/`, data)
    }

    getClients(searchData: string, extraData: any = {}) {
        const filters = `?role=CLIENT&search=${searchData}`
        return this.simple_get(`${API_VERSION_PREFIX}/users/${filters}`, extraData)
    }

    getClientExport(searchData: string, extraData: any = {}): Promise<Types.DownloadFileResult> {
        const filters = `?role=CLIENT&search=${searchData}`
        return this.download_file_get(`${API_VERSION_PREFIX}/users/export/${filters}`, extraData)
    }

    getClientNotesFile(id: number): Promise<Types.DownloadFileResult> {
        return this.download_file_get(`${API_VERSION_PREFIX}/client_notes/${id}/download/`)
    }

    getClientImportExample(): Promise<Types.DownloadFileResult> {
        return this.download_file_get(`${API_VERSION_PREFIX}/users/import_example/`)
    }

    postImportClients(data: any) {

        return this.post_collected_multipart_form_data(`${API_VERSION_PREFIX}/users/import_clients/`, data)
    }

    getUsers(searchData: string, extraData: any = {}) {
        const filters = `?search=${searchData}`
        return this.simple_get(`${API_VERSION_PREFIX}/users/${filters}`, extraData)
    }

    getClientsData(id: number) {
        return this.simple_get(`${API_VERSION_PREFIX}/users/${id}`)
    }

    getTeamMembers(searchData: string, extraData: any = {}) {
        const filters = `?role=EMPLOYEE&search=${searchData}`
        return this.simple_get(`${API_VERSION_PREFIX}/users/${filters}`, extraData)
    }

    getTimeEntry() {
        return this.simple_get(`${API_VERSION_PREFIX}/time_entry/`)
    }

    createTimeEntry(data: any) {
        return data?.id
            ? this.simple_patch(`${API_VERSION_PREFIX}/time_entry/${data.id}/`, data)
            : this.simple_post(`${API_VERSION_PREFIX}/time_entry/`, data)
    }

    updateTimeEntry(data: any) {
        return this.simple_patch(`${API_VERSION_PREFIX}/time_entry/${data.id}/`, data)
    }

    updateTimeEntryStatus(id: number, data: any) {
        return this.simple_post(`${API_VERSION_PREFIX}/time_entry/${id}/execute_action/`, data)
    }

    createRequest(data: any) {
        return this.post_collected_multipart_form_data(`${API_VERSION_PREFIX}/request/`, data)
    }

    createClientNotes(data: any) {
        return this.post_collected_multipart_form_data(`${API_VERSION_PREFIX}/client_notes/`, data)
    }

    updateClientNotes(data: any) {
        return this.patch_collected_multipart_form_data(`${API_VERSION_PREFIX}/client_notes/${data.id}/`, data)
    }

    getRequestStatus() {
        return this.simple_get(`${API_VERSION_PREFIX}/request/requests_status/`)
    }

    getRequests(searchData: string, extraData: any = {}) {
        const filters = `?search=${searchData || ''}`
        return this.simple_get(`${API_VERSION_PREFIX}/request/${filters}`, extraData)
    }

    getRequestDetail(id: number) {
        return this.simple_get(`${API_VERSION_PREFIX}/request/${id}/`)
    }

    getRequestFile(id: number): Promise<Types.DownloadFileResult> {
        return this.download_file_get(`${API_VERSION_PREFIX}/request/${id}/download/`)
    }

    updateRequestAssessment(data: any) {
        return this.simple_post(`${API_VERSION_PREFIX}/request/${data.id}/update_assessment/`, data)
    }

    updateRequest(data: any) {
        return this.patch_collected_multipart_form_data(`${API_VERSION_PREFIX}/request/${data.id}/`, data)
    }

    getCountries() {
        return this.simple_get(`${API_VERSION_PREFIX}/country/`)
    }

    getStates(country: number) {
        return this.simple_get(`${API_VERSION_PREFIX}/state/?country=${country}`)
    }

    getCities(country: number, state: number) {
        return this.simple_get(`${API_VERSION_PREFIX}/city/?country=${country}&state=${state}`)
    }

    updateUser(data: any) {
        return this.simple_patch(`${API_VERSION_PREFIX}/users/${data.id}/`, data)
    }

    createCustomField(data: any) {
        return this.simple_post(`${API_VERSION_PREFIX}/custom-field/`, data)
    }

    getCustomFields(data: any = {}) {
        return this.simple_get(`${API_VERSION_PREFIX}/custom-field/`, data)
    }

    deleteCustomField(id: number) {
        return this.simple_delete(`${API_VERSION_PREFIX}/custom-field/${id}/`)
    }

    deleteUser(idUser: number) {
        return this.simple_delete(`${API_VERSION_PREFIX}/users/${idUser}/`)
    }

    getAllTasks(searchData: string = '', extraData: any = {}) {
        const filters = `?search=${searchData}`
        return this.simple_get(`${API_VERSION_PREFIX}/task/${filters}`, extraData)
    }

    getCategories() {
        return this.simple_get(`${API_VERSION_PREFIX}/category/`)
    }

    getTags() {
        return this.simple_get(`${API_VERSION_PREFIX}/tags/`)
    }

    postTag(data: any) {
        return this.simple_post(`${API_VERSION_PREFIX}/tags/`, data)
    }

    addUserTag(data: any) {
        return this.simple_post(`${API_VERSION_PREFIX}/tags/${data.id}/add_user_tag/`, data)
    }

    removeUserTag(data: any) {
        return this.simple_post(`${API_VERSION_PREFIX}/tags/${data.id}/remove_user_tag/`, data)
    }

    getVendors() {
        return this.simple_get(`${API_VERSION_PREFIX}/vendor/`)
    }

    getAllEvents() {
        return this.simple_get(`${API_VERSION_PREFIX}/event/`)
    }
    getEventById(id: number) {
        return this.simple_get(`${API_VERSION_PREFIX}/event/${id}/`)
    }

    getProducts() {
        return this.simple_get(`${API_VERSION_PREFIX}/product/`)
    }

    getTeam() {
        return this.simple_get(`${API_VERSION_PREFIX}/team/`)
    }

    createTask(data: any) {
        return this.simple_post(`${API_VERSION_PREFIX}/task/`, data)
    }

    getTask(id: number) {
        return this.simple_get(`${API_VERSION_PREFIX}/task/${id}/`)
    }

    updateTask(data: any) {
        return this.simple_patch(`${API_VERSION_PREFIX}/task/${data.id}/`, data)
    }

    createEvent(data: any) {
        return this.simple_post(`${API_VERSION_PREFIX}/event/`, data)
    }

    updateEvent(data: any) {
        return this.simple_patch(`${API_VERSION_PREFIX}/event/${data.id}/`, data)
    }

    getItems(searchData: string, extraData: any = {}) {
        const filters = `?search=${searchData}`
        return this.simple_get(`${API_VERSION_PREFIX}/product/${filters}`, extraData)
    }

    getItemImportExample(): Promise<Types.DownloadFileResult> {
        return this.download_file_get(`${API_VERSION_PREFIX}/product/import_example/`)
    }

    postImportItems(data: any) {
        return this.post_collected_multipart_form_data(`${API_VERSION_PREFIX}/product/import_products/`, data)
    }

    getItemById(id: any) {
        return this.simple_get(`${API_VERSION_PREFIX}/product/${id}/`)
    }

    createItem(data: any) {
        return this.post_collected_multipart_form_data(`${API_VERSION_PREFIX}/product/`, data)
    }

    updateItem(data: any) {
        return this.patch_collected_multipart_form_data(`${API_VERSION_PREFIX}/product/${data.id}/`, data);
    }

    createTeam(data: any) {
        return this.post_collected_multipart_form_data(`${API_VERSION_PREFIX}/team/`, data)
    }

    updateTeam(data: any) {
        return this.patch_collected_multipart_form_data(`${API_VERSION_PREFIX}/team/${data.id}/`, data)
    }

    deleteItem(id: number) {
        return this.simple_delete(`${API_VERSION_PREFIX}/product/${id}/`)
    }

    deleteMatrix(id: number) {
        return this.simple_delete(`${API_VERSION_PREFIX}/matrix/${id}/`)
    }

    createMatrix(data: any) {
        return this.simple_post(`${API_VERSION_PREFIX}/matrix/`, data)
    }

    updateMatrix(data: any) {
        return this.simple_patch(`${API_VERSION_PREFIX}/matrix/${data.id}/`, data)
    }

    getMatrix(searchData: string, extraData: any = {}) {
        const filters = `?search=${searchData}`
        return this.simple_get(`${API_VERSION_PREFIX}/matrix/${filters}`, extraData)
    }

    getMatrixDetail(id: any) {
        return this.simple_get(`${API_VERSION_PREFIX}/matrix/${id}/`)
    }

    getCompany(data: any) {
        return this.simple_get(`${API_VERSION_PREFIX}/company/${data}/`)
    }

    updateCompany(data: any) {
        return this.simple_patch(`${API_VERSION_PREFIX}/company/${data.id}/`, data)
    }

    getQuoteFile(id: number): Promise<Types.DownloadFileResult> {
        return this.download_file_get(`${API_VERSION_PREFIX}/quote/${id}/download/`)
    }

    getPrintQuote(id: number): Promise<Types.DownloadFileResult> {
        return this.download_file_get(`${API_VERSION_PREFIX}/quote/${id}/print/`, {}, `quote_${id}.pdf`)
    }

    createQuote(data: any) {
        return data?.id
            ? this.patch_collected_multipart_form_data(`${API_VERSION_PREFIX}/quote/${data.id}/`, data)
            : this.post_collected_multipart_form_data(`${API_VERSION_PREFIX}/quote/`, data)
    }

    getQuotes(searchData: string, extraData: any = {}) {
        const filters = `?search=${searchData || ''}`
        return this.simple_get(`${API_VERSION_PREFIX}/quote/${filters}`, extraData)
    }

    getQuoteTemplates(searchData: string = '', extraData: any = {}) {
        const filters = `?search=${searchData}`
        return this.simple_get(`${API_VERSION_PREFIX}/quote/template_list/${filters}`, extraData)
    }

    updateQuoteStatus(id: number, data: any) {
        return this.simple_post(`${API_VERSION_PREFIX}/quote/${id}/execute_action/`, data)
    }

    getQuoteDetail(id: number) {
        return this.simple_get(`${API_VERSION_PREFIX}/quote/${id}/`)
    }

    getQuoteStatus() {
        return this.simple_get(`${API_VERSION_PREFIX}/quote/quotes_status/`)
    }

    getQuotesData(id: number) {
        return this.simple_get(`${API_VERSION_PREFIX}/quote/${id}`)
    }

    createInvoice(data: any) {
        return data?.id
            ? this.patch_collected_multipart_form_data(`${API_VERSION_PREFIX}/invoice/${data.id}/`, data)
            : this.post_collected_multipart_form_data(`${API_VERSION_PREFIX}/invoice/`, data)
    }

    getInvoiceDetail(id: number) {
        return this.simple_get(`${API_VERSION_PREFIX}/invoice/${id}/`)
    }

    getInvoiceFile(id: number): Promise<Types.DownloadFileResult> {
        return this.download_file_get(`${API_VERSION_PREFIX}/invoice/${id}/download/`)
    }

    getInvoiceStatus() {
        return this.simple_get(`${API_VERSION_PREFIX}/invoice/invoices_status/`)
    }

    getInvoices(searchData: string, extraData: any = {}) {
        const filters = `?search=${searchData}`
        return this.simple_get(`${API_VERSION_PREFIX}/invoice/${filters}`, extraData)
    }

    getInvoicesData(id: number) {
        return this.simple_get(`${API_VERSION_PREFIX}/invoice/${id}`)
    }

    getBranding(company_id) {
        return this.simple_get(`${API_VERSION_PREFIX}/branding/?company=${company_id}`)
    }

    createBranding(data: any) {
        return this.post_collected_multipart_form_data(`${API_VERSION_PREFIX}/branding/`, data)
    }

    updateBranding(data: any) {
        return data?.id
         ? this.patch_collected_multipart_form_data(`${API_VERSION_PREFIX}/branding/${data.id}/`, data)
         : this.post_collected_multipart_form_data(`${API_VERSION_PREFIX}/branding/`, data)
    }

    getPdfExample(){
        return this.download_file_by_method(`${API_VERSION_PREFIX}/pdf/pdf_example/`)
    }

    createPdfStyle(data: any) {
        return data?.id
            ? this.simple_patch(`${API_VERSION_PREFIX}/pdf/pdf_style/${data.id}/`, data)
            : this.simple_post(`${API_VERSION_PREFIX}/pdf/pdf_style/`, data)
    }

    previewPdfStyle(id: number, data: any) {
        return this.download_file_by_method(`${API_VERSION_PREFIX}/pdf/pdf_style/${id}/show/`, data, null, this.apisauce.post)
    }

    getPdfStyle() {
        return this.simple_get(`${API_VERSION_PREFIX}/pdf/pdf_style/`)
    }

    getPDF() {
        return this.simple_get(`${API_VERSION_PREFIX}/pdf/pdf_example/`)
    }

    getJobs(searchData: string, extraData: any = {}) {
        const filters = `?search=${searchData}`
        return this.simple_get(`${API_VERSION_PREFIX}/job/${filters}`, extraData)
    }

    getJobStatus() {
        return this.simple_get(`${API_VERSION_PREFIX}/job/jobs_status/`)
    }

    createJob(data: any) {
        return this.simple_post(`${API_VERSION_PREFIX}/job/`, data)
    }

    updateJob(data: any) {
        return this.simple_patch(`${API_VERSION_PREFIX}/job/${data.id}/`, data);
    }

    getJobDetail(id: number) {
        return this.simple_get(`${API_VERSION_PREFIX}/job/${id}/`)
    }

    createExpense(data: any) {
        return this.post_collected_multipart_form_data(`${API_VERSION_PREFIX}/expenses/`, data)
    }

    getExpenses() {
        return this.simple_get(`${API_VERSION_PREFIX}/expenses/`)
    }

    getExpensesFile(id: number): Promise<Types.DownloadFileResult> {
        return this.download_file_get(`${API_VERSION_PREFIX}/expenses/${id}/download/`)
    }

    createTax(data: any) {
        return this.simple_post(`${API_VERSION_PREFIX}/company_tax/`, data)
    }

    getTaxes() {
        return this.simple_get(`${API_VERSION_PREFIX}/company_tax/`)
    }

    deleteTax(id: number) {
        return this.simple_delete(`${API_VERSION_PREFIX}/company_tax/${id}/`)
    }

    // stripe
    stripePayment(data: any) {
        return this.simple_post(`${API_VERSION_PREFIX}/stripe-payment/`, data)
    }

    stripePaymentClient(data: any) {
        return this.simple_post(`${API_VERSION_PREFIX}/stripe-payment-client/`, data)
    }

    stripeGetCards() {
        return this.simple_post(`${API_VERSION_PREFIX}/stripe-cards/`)
    }

    stripeDeleteCard() {
        return this.simple_post(`${API_VERSION_PREFIX}/stripe-delete-card/`)
    }

    stripePaymentIntent(data: any) {
        return this.simple_post(`${API_VERSION_PREFIX}/create-payment-intent/`, data)
    }

    stripePaymentIntentClient(data: any) {
        return this.simple_post(`${API_VERSION_PREFIX}/create-payment-intent-client/`, data)
    }
    
    stripeProductsAvailables() {
        return this.simple_get(`${API_VERSION_PREFIX}/stripe-products-availables/`)
    }
    
    stripeCreateSubscription(priceId: string) {
        return this.simple_post(`${API_VERSION_PREFIX}/stripe-create-subscription/`, {price_id: priceId})
    }
    
     stripeDeleteSubscription(priceId: string) {
        return this.simple_delete(`${API_VERSION_PREFIX}/stripe-create-subscription/`, {price_id: priceId})
    }
    
    stripeActiveSubscription() {
        return this.simple_get(`${API_VERSION_PREFIX}/stripe-active-subscription/?detail=true`)
    }

    // pubnub
    getChannels() {
        return this.simple_get(`${API_VERSION_PREFIX}/channels/`)
    }

    createChannel(data: any) {
        return this.simple_post(`${API_VERSION_PREFIX}/channels/`, data)
    }

    updateChatParticipants(data: any) {
        return this.simple_patch(`${API_VERSION_PREFIX}/channels/${data.id}/`, data)
    }

    sendMessage(data: any) {
        return this.simple_post(`${API_VERSION_PREFIX}/send-text-message/`, data)
    }

    phoneCall(data: any) {
        return this.simple_post(`${API_VERSION_PREFIX}/phone-call/`, data)
    }

    getAvailableNumbers(search: string) {
        return this.simple_get(`${API_VERSION_PREFIX}/phone-number/`, {q: search})
    }

    buyNumber(data: any) {
        return this.simple_post(`${API_VERSION_PREFIX}/phone-number/buy/`, data)
    }

    releasePhoneNumber(data: any) {
        return this.simple_post(`${API_VERSION_PREFIX}/phone-number/release/`, data)
    }

    getTwilioVoiceToken(company_id: any) {
        return this.simple_post(`${API_VERSION_PREFIX}/phone-system/get_token/`, {company:company_id})
    }

    getPersonByNumber(number: any) {
        return this.simple_post(`${API_VERSION_PREFIX}/phone-system/get_person/`, {number})
    }
    
    // REPORTS
    getScheduleReport(startDate: string, endDate: string) {
        return this.simple_get(`${API_VERSION_PREFIX}/report/schedule/${!!startDate && !!endDate? `?start_date=${startDate}&end_date=${endDate}` : ""}`);
    }

    getDailyTransactionReport() {
        return this.simple_get(`${API_VERSION_PREFIX}/report/dailytransaction/`);
    }

    getSalesReport(startDate: string, endDate: string) {
        return this.simple_get(`${API_VERSION_PREFIX}/report/sales/?start_date=${startDate}&end_date=${endDate}`);
    }

    getOpenWorkReport(startDate: string, endDate: string) {
        return this.simple_get(`${API_VERSION_PREFIX}/report/openwork/?start_date=${startDate}&end_date=${endDate}`);
    }

    getSelectSalesReport() {
        return this.simple_get(`${API_VERSION_PREFIX}/report/selectsales/`);
    }

    getSalesRepReport(startDate: string, endDate: string) {
        return this.simple_get(`${API_VERSION_PREFIX}/report/sales_rep/?start_date=${startDate}&end_date=${endDate}`);
    }

    getSalesTaxReport(startDate: string, endDate: string) {
        return this.simple_get(`${API_VERSION_PREFIX}/report/salestax/?start_date=${startDate}&end_date=${endDate}`);
    }
    
    // ADMIN
    getAnalyticsData(id: number) {
        return this.simple_get(`${API_VERSION_PREFIX}/admin/analytics/`);
    }

    getUsersAdmin(searchData: string = '', extraData: any = {}) {
        const filters = `?search=${searchData}`
        return this.simple_get(`${API_VERSION_PREFIX}/admin/users/${filters}`, extraData)
    }

    getUserAdmin(id: number) {
        return this.simple_get(`${API_VERSION_PREFIX}/admin/users/${id}/`)
    }

    approvalUserAdmin(id: number, approved: boolean) {
        return this.simple_put(`${API_VERSION_PREFIX}/admin/users/${id}/approval/`, {approved:approved})
    }

    sendMoreInfoEmail(id: number, message: string) {
        return this.simple_post(`${API_VERSION_PREFIX}/admin/users/${id}/more-info-email/`, {message:message})
    }

    addTrialDays(companyId: number, days: number) {
        return this.simple_post(`${API_VERSION_PREFIX}/admin/company/${companyId}/add-trial_days/`, {extra_days:days})
    }

    deleteUserAdmin(id: number) {
        return this.simple_delete(`${API_VERSION_PREFIX}/admin/users/${id}/`)
    }

    // Subscription
    getSubscriptions(searchData: string = '') {
        const filters = `?search=${searchData}`
        return this.simple_get(`${API_VERSION_PREFIX}/admin/subscription/${filters}`)
    }

    createSubscription(data: any) {
        return this.simple_post(`${API_VERSION_PREFIX}/admin/subscription/`, data)
    }

    updateSubscription(data: any) {
        return this.simple_put(`${API_VERSION_PREFIX}/admin/subscription/${data.id}/`, data)
    }

    deleteSubscription(id: number) {
        return this.simple_delete(`${API_VERSION_PREFIX}/admin/subscription/${id}/`)
    }

}
