
// react-router-dom components
import { Link } from "react-router-dom";

// Material Dashboard 2 PRO React components
import MDButton from "components/MDButton";

// react
import { useNavigate } from "react-router-dom";

// Components
import {InitialMobile} from "./mobile";

// Images
import woman from 'assets/images/woman.png';
import man from 'assets/images/man.png';
import logo from 'assets/images/logo.png';
import './login.css'
import {ROUTES} from 'constants'

// Utils
import {useIsMobile} from "services/hooks";


function Initial() {
  const navigate = useNavigate()
  const isMobile = useIsMobile();
  return (
    <div>
      {isMobile ? (
        <InitialMobile />
      ) : (
        <div className={"login-container"}>
          <img
            src={woman}
            alt={"woman"}
            className={"woman-man-image"}
            style={{ height: 700, width: "auto" }}
          />
          <div className={"mt-1 justify-content-center center-container"}>
            <img src={logo} alt={"logo"} className={"logo-home img-fluid"} />
            <MDButton
              color="primary"
              type="submit"
              className={"btn-save-initial"}
              onClick={() => navigate(ROUTES.SIGNUP)}
            >
              Sign Up
            </MDButton>
            <MDButton
              color="primary"
              variant="outlined"
              type="submit"
              className={"btn-save-initial"}
              onClick={() => navigate(ROUTES.LOGIN)}
            >
              Log in
            </MDButton>
          </div>
          <img src={man} alt={"man"} className={"woman-man-image img-fluid"} />
        </div>
      )}
    </div>
  )
}

export default Initial;
