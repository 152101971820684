import React, { useEffect } from "react";
import { Grid } from '@mui/material';
import { useNavigate } from "react-router-dom";
import "../styles.css";
import SimpleTable from "components/SimpleTable";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import { Link } from '@mui/material';
import MDBadge from "components/MDBadge";
import { money_fmt, padToFour, truncate } from 'services/helpers'
import moment from "moment";
import { useApi, showMessage } from "services/helpers";
import { ROUTES } from 'constants'



const OverviewTabs = props => {
  const api = useApi()
  const navigate = useNavigate();


  const initialOverview = {
    'Active Work': {
      columns: [
        { Header: "Job", accessor: "link_job_id" },
        { Header: "Task", accessor: "link_task_id" },
        { Header: "Start User", accessor: "start_user" },
        { Header: "Start Date", accessor: "start_date" },
        { Header: "Finish User", accessor: "finish_user" },
        { Header: "End Date", accessor: "end_date" },
      ],
      rows: [],
      function: () => navigate(ROUTES.TIMESHEET)
    },
    'Requests': {
      columns: [
        { Header: "Title", accessor: "title" },
        { Header: "Created at", accessor: "created_at" },
        { Header: "Requested date/time", accessor: "start_date" },
        { Header: "Assign To", accessor: "assign_to", link: true, link_to: 'home', state_key: 'functionary' },
      ],
      rows: [],
      function: () => navigate(ROUTES.REQUESTS)
    },
    'Quotes': {
      columns: [
        { Header: "Number", accessor: "id" },
        { Header: "Date", accessor: "created_at" },
        { Header: "Title", accessor: "title" },
        { Header: "Status", accessor: "status" },
        { Header: "Subtotal", accessor: "subtotal" },
        { Header: "Discount", accessor: "discount" },
        { Header: "Total", accessor: "total" },
        { Header: "", accessor: "action", sorted: false },
      ],
      rows: [],
      function: () => navigate(ROUTES.QUOTES)
    },
    'Jobs': {
      columns: [
        { Header: "Number", accessor: "number" },
        { Header: "Name (A-Z)", accessor: "title" },
        { Header: "Quote", accessor: "quote" },
        { Header: "Subtotal", accessor: "subtotal" },
        { Header: "Discount", accessor: "discount" },
        { Header: "Total", accessor: "total" },
        { Header: "Start Date", accessor: "start_date" },
      ],
      rows: [],
      function: () => navigate(ROUTES.JOBS)
    },
    'Invoices': {
      columns: [
        { Header: "Name (A-Z)", accessor: "name" },
        { Header: "Subtotal", accessor: "subtotal" },
        { Header: "Discount", accessor: "discount" },
        { Header: 'Total', accessor: 'total' },
      ],
      rows: [],
      function: () => navigate(ROUTES.INVOICES)
    },
    'Products Bought': {
      columns: [
        { Header: "Name (A-Z)", accessor: "name" },
        { Header: "Title", accessor: "title" },
      ],
      rows: [],
      // function: navigate(ROUTES.INVOICES)
    },
    'P.O.': {
      columns: [
        { Header: "Name", accessor: "name" },
        { Header: "UCP Code", accessor: "ucp_code" },
        { Header: "Item Location", accessor: "item_location" },
        { Header: "Serial Number", accessor: "serial_number" },
      ],
      rows: [],
      // function: navigate(ROUTES.INVOICES)
    },
  }

  const [OverviewKey, setOverviewKey] = React.useState('');
  const [OverviewData, setOverviewData] = React.useState({ ...initialOverview });

  const executeQuoteAction = (id, data) => {
    setOverviewData({ ...initialOverview })
    api.updateQuoteStatus(id, data)
      .then(() => showMessage('Action has been executed', 'success'))
      .catch(() => showMessage())
      .finally(() => window.location.reload(false))
  }

  const badgeBoxStatus = (item) => {
    if (item.status === 'Draft') {
      return <MDBadge variant="contained" color="dark" badgeContent={item.status} container />
    } else if (item.status === 'Approved' || item.status === 'Converted') {
      return <MDBadge variant="contained" color="success" badgeContent={item.status} container />
    } else if (item.status === 'Archived') {
      return <MDBadge variant="contained" color="error" badgeContent={item.status} container />
    } else {
      return <MDBadge variant="contained" color="warning" badgeContent={item.status} container />
    }
  }

  const getItemAction = (item) => {
    let data = { label: '' }

    if (item?.status === 'Draft') {
      data.label = 'Sent to Client'
      data.action = () => executeQuoteAction(item.id, data)
    }
    if (item?.status === 'Sent to Client') {
      data.label = 'Awaiting response'
      data.action = () => executeQuoteAction(item.id, data)
    }
    if (item?.status === 'Awaiting response') {
      data.label = 'Approve'
      data.action = () => executeQuoteAction(item.id, { ...data, approve: true })
      data.label2 = 'Changes Requested'
      data.action2 = () => executeQuoteAction(item.id, { ...data, approve: false })
    }
    if (item?.status === 'Changes requested') {
      data.label = 'Awaiting response'
      data.action = () => executeQuoteAction(item.id, data)
    }
    if (item?.status === 'Approved') {
      data.label = 'Convert'
      data.action = () => executeQuoteAction(item.id, data)
    }
    if (item?.status === 'Converted') {
      data.label = 'Archive'
      data.action = () => executeQuoteAction(item.id, data)
    }
    if (item?.status === 'Archived') { }

    return <>
      {data.label &&
        <MDButton
          onClick={data.action}
          style={{ marginRight: 3 }}
          variant="gradient"
          color={"success"}
        >{data.label}
        </MDButton>
      }
      {data.label2 &&
        <MDButton
          onClick={data.action2}
          style={{ marginTop: 3 }}
          variant="gradient"
          color={"success"}
        >{data.label2}
        </MDButton>
      }
    </>
  }

  const nameLinkBox = (name, link) => (
    <MDTypography onClick={() => navigate(link)} sx={{ color: '#60A77C', fontWeight: 'bold', cursor: 'pointer', fontSize: '1.2rem' }}>
      {name}
    </MDTypography>
  )

  const renderActiveWorkRow = (item) => {
    const data = {
      id: item.id,
      task: item.task,
      job: item.job,
      start_user: item?.start_user?.name,
      finish_user: item?.finish_user?.name,
      start_date: item.start_date ? moment(item.start_date).format('DD/MM/YYYY HH:mm') : '',
      end_date: item.end_date ? moment(item.end_date).format('DD/MM/YYYY HH:mm') : '',
      time: item.end_date ? `${moment(item.end_date).diff(moment(item.start_date), 'minutes')} minutes` : '',
      link_job_id: nameLinkBox(item.job_title, ROUTES.EDIT_JOB(item?.job)),
      link_task_id: nameLinkBox(item.task_title, ROUTES.EDIT_TASK(item?.task))
    }
    return data
  }

  const renderQuoteRow = (item) => {
    const data = {
      id: nameLinkBox("QN" + padToFour(item.id), ROUTES.CLIENTS_QUOTE_DETAILS(item.id)),
      created_at: moment(item.created_at).format('MMMM Do YYYY, hh:mm A'),
      title: truncate(item.title, 15),
      status: badgeBoxStatus(item),
      subtotal: money_fmt(item.subtotal),
      discount: money_fmt(item.discount),
      total: money_fmt(item.total),
      action: getItemAction(item)
    }
    return data
  }

  const renderJobRow = (item) => {
    const data = {
      number: nameLinkBox(`JN${item.id}`, ROUTES.CLIENTS_JOB_DETAILS(item.id)),
      title: item.title,
      quote: item?.quote?.title,
      subtotal: money_fmt(item?.quote?.subtotal),
      discount: money_fmt(item?.quote?.discount),
      total: money_fmt(item?.quote?.total),
      start_date: item?.start_date ? moment(item.start_date).format('ll') : '-',
    }
    return data
  }

  const renderRequestRow = (item) => {
    const data = {
      title: nameLinkBox(item?.title, ROUTES.CLIENTS_REQUEST_OPEN(item.id)),
      start_date: item?.start_date ? moment(item?.start_date).format('MMMM DD, YYYY') : '-',
      end_date: item?.end_date ? moment(item?.end_date).format('MMMM DD, YYYY') : '-',
      created_at: item?.created_at ? moment(item?.created_at).format('MMMM DD, YYYY') : '-',
      assign_to: item?.assign_to?.full_name || '-',
    }
    return data
  }

  const renderInvoiceRow = (item) =>{
    const data = {
      name: nameLinkBox(item.title, ROUTES.INVOICE_EDIT(item.id)),
      subtotal: money_fmt(item?.subtotal),
      discount: money_fmt(item?.discount),
      total: money_fmt(item?.total),
    }
    return data
  }

  useEffect(() => {
    if (props.client) {
      let temp = { ...OverviewData }

      if (Array.isArray(props?.client?.overview_active_work))
        temp['Active Work'].rows = props.client.overview_active_work.map(e => renderActiveWorkRow(e))

      if (Array.isArray(props?.client?.overview_invoices))
        temp['Invoices'].rows = props.client.overview_invoices.map(e => renderInvoiceRow(e))

      if (Array.isArray(props?.client?.overview_jobs))
        temp['Jobs'].rows = props.client.overview_jobs.map(e => renderJobRow(e))

      if (Array.isArray(props?.client?.overview_requests))
        temp['Requests'].rows = props.client.overview_requests.map(e => renderRequestRow(e))

      if (Array.isArray(props?.client?.overview_quotes))
        temp['Quotes'].rows = props.client.overview_quotes.map(e => renderQuoteRow(e))

      if (Array.isArray(props?.client?.overview_p_o))
        temp['P.O.'].rows = props.client.overview_p_o.map(e => e)

      setOverviewData(temp)
    }
  }, [props])

  return <Grid container>
    <Grid item xs={12} lg={12} md={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
      <MDTypography variant="h3" fontWeight="medium" ml={3} style={{ float: 'left' }}>
        Overview
      </MDTypography>
    </Grid>
    <Grid item xs={12} lg={12} md={12}>
      <MDBox sx={{ width: '100%' }}>
        <MDBox mb={-5}>
          {Object.keys(initialOverview).map(key => (
            <Link
              key={`link_${key}`}
              style={{ width: `${100 / Object.keys(initialOverview).length}%`, height: 70, borderBottom: '5px solid #C6C9CE' }}
              component="button"
              onClick={() => setOverviewKey(key)}
              className={`tab_client_container ${OverviewKey === key ? "active" : ""}`}
            >
              <MDTypography className={`tab_client ${OverviewKey === key ? "active" : ""}`}>
                {key}
              </MDTypography>
            </Link>
          ))}
        </MDBox>
      </MDBox>
      <MDBox sx={{ marginTop: '70px' }} >
        <SimpleTable data={OverviewData[OverviewKey]} />

        {OverviewData?.[OverviewKey]?.function &&
          <Grid item xs={12} lg={12} md={12} my={5} display="flex" justifyContent="center" alignItems="center">
            <MDButton
              useBrandingColor={'primary'}
              color="green"
              onClick={OverviewData?.[OverviewKey]?.function}
              className={'btn-save'}
            >
              View All {OverviewKey}
            </MDButton>
          </Grid>
        }
      </MDBox>
    </Grid>
  </Grid>
}

export default OverviewTabs
