import * as React from 'react';
import { observer } from "mobx-react-lite";
import { useState, useEffect } from "react";
import { Grid, InputAdornment } from '@mui/material';
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDDialog from "components/MDDialog";
import MDAutocomplete from "components/MDAutocomplete";
import MDCheckbox from "components/MDCheckbox";
import { useStores } from "../../models";

import Toast from "components/Toast"

import "./index.css";

export const RenderCustomFields = observer(function RenderCustomField({ form_id }) {
  const rootStore = useStores()
  const { loginStore } = rootStore

  const [CustomFields, setCustomFields] = useState([]);

  const getCustomFields = (form_id) => {
    let formFields = loginStore?.getCustomFieldsClient
    if (formFields) setCustomFields(formFields)

    switch (form_id) {
      case 'Task':
        setCustomFields(loginStore.getCustomFieldsTask)
        break;
      case 'Client':
        setCustomFields(loginStore.getCustomFieldsClient)
        break;
      case 'Request':
        setCustomFields(loginStore.getCustomFieldsRequest)
        break;
      case 'Event':
        setCustomFields(loginStore.getCustomFieldsEvent)
        break;
      case 'Job':
        setCustomFields(loginStore.getCustomFieldsJob)
        break;
      case 'Category':
        setCustomFields(loginStore.getCustomFieldsCategory)
        break;
      case 'Product':
        setCustomFields(loginStore.getCustomFieldsProduct)
        break;
      case 'Invoice':
        setCustomFields(loginStore.getCustomFieldsInvoice)
        break;
      case 'Quote':
        setCustomFields(loginStore.getCustomFieldsQuote)
        break;
      case 'Inventory':
        setCustomFields(loginStore.getCustomFieldsInventory)
        break;
      default: break;
    }
  }

  const fetchCustomFields = () => {
    loginStore.environment.api.getCustomFields().then((result) => {
      if (result.kind === "ok") { }
    })
  }

  useEffect(() => {
    getCustomFields(form_id);
    fetchCustomFields();
  }, [loginStore.custom_fields_clients]);

  return (
    <>
      {(Array.isArray(CustomFields) && CustomFields.length > 0) &&
        <Grid item xs={12} md={12} sm={12} mt={2}>
          <MDTypography variant="h4" fontWeight="bold">
            Custom Fields
          </MDTypography>
        </Grid>
      }
      {Array.isArray(CustomFields) && CustomFields.filter(f => f.field_type).map((field, key) => (
        <Grid key={key} item xs={6} md={6} sm={6}>
          {field.field_type && <MDTypography variant="h6" fontWeight="bold">{field.field_name}</MDTypography>}
          {(field.field_type === 'NUMBER' || field.field_type === 'TEXT') &&
            <MDInput
              placeholder='value'
              fullWidth
              defaultValue={field.default_value}
            // value={DefaultValue}
            // onChange={evt => setDefaultValue(evt.target.value)}
            />
          }
          {(field.field_type === 'BOOLEAN' || field.field_type === 'SELECT') &&
            <MDAutocomplete
              placeholder='-'
              defaultValue={field.defaultValue}
              options={
                field.field_type === 'BOOLEAN'
                  ? [
                    { value: true, label: 'True' },
                    { value: false, label: 'False' },
                  ]
                  : field.field_options
              }
            // value={DefaultValue}
            // onChange={(event, newValue) => setDefaultValue(newValue)}
            />
          }
        </Grid>
      ))}
    </>
  );
})
