import React, { useEffect, useState } from "react";
import moment from 'moment-timezone'
import {debounce, Grid} from '@mui/material';
import AdminLayout from "components/AdminLayout";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDSwitch from "components/MDSwitch";
import SimpleTable from "components/SimpleTable";
import MDDialog from "components/MDDialog";
import FormikInput from "components/FormikInput";
import { Form, Formik } from "formik";
import { getCompanyInitialValues, companyValidationSchema } from "./form";

// store
import { useStores } from "models";
import {showMessage, useApi} from "services/helpers";
// styles
import '../styles.css';
//icons
import DeleteIcon from 'assets/images/icons/delete.png';
import MDInput from "../../../components/MDInput";
import {SearchBar} from "../../../components/SearchBar";
import {observer} from "mobx-react-lite";

const datatableModel = {
    columns: [
        { Header: "Name", accessor: "title" },
        { Header: "Tax Rate", accessor: "tax_rate" },
        { Header: "Percentage", accessor: "percentage" },
        { Header: "", accessor: "action" },
    ],
    rows: []
}

const numberOptionsModel = {
    columns: [
        // { Header: "#", accessor: "id", disableOrdering: true },
        { Header: "Number", accessor: "number" },
        { Header: "Action", accessor: "action" },
    ],
    rows: []
}


export default observer(function Company() {
    const rootStore = useStores()
    const { loginStore } = rootStore
    const company = loginStore.getCompany
    const api = useApi();
    const [loading, setLoading] = useState(false);

    const [Timezones, setTimezones] = useState();
    const [DataTaxes, setDataTaxes] = useState({ ...datatableModel });

    const [TaxesModalOpen, setTaxesModalOpen] = useState(false);

    const [BuyNumberModalOpen, setBuyNumberModalOpen] = useState(false);
    const [NumberOptions, setNumberOptions] = useState(false);
    const [NumberSearch, setNumberSearch] = useState('');

    useEffect(() => {
        getCompany()
    }, [])


    const deleteTax = (id) => {
        api.deleteTax(id).handle({
            successMessage: 'Tax Deleted successfully!',
            onSuccess: () => getCompanyTaxes(),
            errorMessage:  'Error deleting tax, please try again.',
        })
    }

    const updateCompany = (data) => {
        data['id'] = company.id
        api.updateCompany(data).handle({
            successMessage: 'Company Updated successfully!',
            onSuccess: (result) => {
                loginStore.setCompany(result.response)
            },
            errorMessage:  'Error updating company, please try again.',
        })
    }

    const getCompany = () => {
        api.getCompany(company.id).handle({
            onSuccess: (result) => { loginStore.setCompany(result.data); console.log(result.data) },
            errorMessage:  'Error fetching company, please try again.',
        })
    }

    const saveTax = (values, formik) => {
        let data = { ...values }
        data['company'] = company.id

        api.createTax(data).handle({
          successMessage: 'Tax Created successfully!',
            onSuccess: () => {
              getCompanyTaxes()
              formik.resetForm()
              setTaxesModalOpen(false)
            },
            errorMessage:  'Error creating tax, please try again.',

        })
    }

    const getCompanyTaxes = () => {
        api.getTaxes().handle({
            onSuccess: (result) => {
                const table = {
                    ...datatableModel,
                    rows: result.data.results.map(r => ({
                        ...r,
                        percentage: r.percentage ? 'Yes' : 'No',
                        action: <MDButton
                            useBrandingColor={'primary'}
                            iconOnly
                            onClick={() => deleteTax(r.id)}
                        >
                            <img src={DeleteIcon} alt='delete_tax' />
                        </MDButton>
                    })) || [],
                }
                setDataTaxes(table)
            },
            errorMessage:  'Error getting taxes, please try again.',
        })
    }

    const buyNumber = (number) => {
        api.buyNumber({number, company}).handle({
            onSuccess: (result) => {
                getCompany()
                setBuyNumberModalOpen(false)
            },
            successMessage: 'Number bought successfully!',
            errorMessage:  'Error buying number, please try again.',
        })
    }

    const releasePhoneNumber = () => {
        api.releasePhoneNumber({company}).handle({
            onSuccess: (result) => {
                getCompany()
            },
            successMessage: 'Number released successfully!',
            errorMessage:  'Error releasing number, please try again.',
        })
    }

    const getAvailableNumbers = () => {
        if (loading)
            return
        setLoading(true)
        console.log('getAvailableNumbers')
        api.getAvailableNumbers(NumberSearch).handle({
            onSuccess: (result) => {
                const table = {
                    ...numberOptionsModel,
                    rows: result.data.map?.(r => ({
                        ...r,
                        action: <MDButton
                            useBrandingColor={'primary'}
                            color="greenLight"
                            onClick={() => buyNumber(r.number)}
                        >
                            Buy
                        </MDButton>
                    })) || [],
                }
                setNumberOptions(table)
            },
            errorMessage:  'Error getting available numbers, please try again.',
            onFinally: () => setLoading(false)
        })
    }

    const debouncedReceiveValue = debounce(getAvailableNumbers, 700);

    useEffect(() => {
        debouncedReceiveValue(NumberSearch);
        // Clean up function to cancel the debounce function when the component unmounts.
        return () => debouncedReceiveValue.clear();
    }, [NumberSearch]);




    const addExpenseModal = <MDDialog
        open={TaxesModalOpen}
        handleClose={() => setTaxesModalOpen(false)}
        title='Add Tax'
        paddingTop={10}
        style={{ width: 600, maxHeight: 1000 }}
    >
        <Formik
            validateOnBlur={false}
            validateOnChange={false}
            initialValues={{

            }}
            onSubmit={saveTax}
        >
            {({ errors, setFieldValue, values }) => {
                return (
                    <Form>
                        <Grid container spacing={{ xs: 2, md: 3, lg: 3 }} alignItems={'flex-start'}>
                            <FormikInput
                                useBrandingColor={'secondary'}
                                name={'title'}
                                label={'Title'}
                                placeholder={'input tax title'}
                                errors={errors}
                                item xs={12}
                            />
                            <FormikInput
                                useBrandingColor={'secondary'}
                                name={'tax_rate'}
                                label={'Tax Rate'}
                                placeholder={'input tax rate'}
                                type={'numeric'}
                                errors={errors}
                                item xs={12}
                            />
                            <FormikInput
                                useBrandingColor={'secondary'}
                                name={'percentage'}
                                label={'Percentage'}
                                type={'boolean'}
                                setFieldValue={setFieldValue}
                                errors={errors}
                                item xs={6}
                            />

                            <Grid item xs={12} lg={12} md={12} mb={3} display="flex" justifyContent="center" alignItems="center">
                                <MDButton
                                    useBrandingColor={'primary'}
                                    color="green"
                                    type="submit"
                                    className={'btn-save-task'}
                                >
                                    SAVE
                                </MDButton>
                            </Grid>
                        </Grid>
                    </Form>
                )
            }}
        </Formik>
    </MDDialog>

    const buyNumberModal = <MDDialog
        open={BuyNumberModalOpen}
        handleClose={() => {setBuyNumberModalOpen(false); setNumberSearch('')}}
        title='Buy Number'
        paddingTop={10}
        style={{ maxHeight: 1000 }}
    >
        <Grid container spacing={{ xs: 2, md: 3, lg: 3 }} alignItems={'flex-start'}>
            <Grid item xs={12} lg={12} md={12} mb={3} display="flex" justifyContent="center" alignItems="center">
                <SearchBar loading={loading} setSearchQuery={setNumberSearch}/>
            </Grid>
            <Grid item xs={12} lg={12} md={12} mb={3} display="flex" justifyContent="center" alignItems="center" disabled={loading}>
                <SimpleTable data={NumberOptions} emptyLabelText={'No numbers available'}/>
            </Grid>
        </Grid>
    </MDDialog>


    useEffect(() => {
        // timezones
        let tmz = moment.tz.zonesForCountry('US');
        let tmzutc = tmz.map((t, i) => {
            let code = moment.tz(t).zoneAbbr();
            let gmt = moment.tz(t).toString().match(/([A-Z]+[\+-][0-9]+)/)[1].slice(0, -2).split("-");
            let gmt_name = gmt[0] + " - " + gmt[1] + ":00";
            let name = "(" + gmt_name + ") " + t.split("_").join(" ");
            let value = t.toLowerCase().split("/").join("_")
            return { value, name }
        });
        setTimezones(tmzutc);

        getCompanyTaxes()

    }, []);

    return (
        <AdminLayout title={"Company Settings"}>
            <Grid container alignItems="top" paddingTop={0} marginTop={0}>
                <Grid ml={5} item xs={12} lg={9} md={9} className="leftContainer">
                    <MDTypography variant="h2" fontWeight="medium" mb={1}>
                        Company Settings
                    </MDTypography>
                </Grid>
            </Grid>

            <Formik
                initialValues={Object.assign({}, getCompanyInitialValues(loginStore.getCompany))}
                validationSchema={companyValidationSchema}
                validateOnBlur={false}
                validateOnChange={false}
                onSubmit={values => updateCompany(values)}
                enableReinitialize
            >
                {({ errors, setFieldValue, values }) => {
                    return (
                        <Form>
                            <Grid container spacing={{ xs: 2, md: 3, lg: 3 }} className="task_container" alignItems={'flex-start'}>

                                <Grid item xs={12} lg={12} md={12} display="flex" justifyContent="center" alignItems="center">
                                    <MDTypography variant="h3" color="green">Company ID #{loginStore.company_id ? loginStore.company_id : ''}</MDTypography>
                                </Grid>

                                <FormikInput
                                    name='company_name'
                                    useBrandingColor={'secondary'}
                                    label={'Company Name'}
                                    setFieldValue={setFieldValue}
                                    placeholder='input name'
                                    errors={errors}
                                    item xs={12} lg={6} md={6}
                                />
                                {/* <FormikInput
                                    name={'phone_number'}
                                    useBrandingColor={'secondary'}
                                    label={'Phone number'}
                                    type={'phone_input'}
                                    placeholder={'input phone number'}
                                    setFieldValue={setFieldValue}
                                    errors={errors}
                                    item xs={12} lg={6} md={6}
                                /> */}

                                <Grid item xs={12} lg={6} md={6} style={{ height: 50 }} >
                                    <MDTypography variant="h6" fontWeight="bold">
                                        Phone Number
                                    </MDTypography>
                                    {company.phone_number ?
                                        <MDTypography fontWeight={'bold'}>
                                            {company.phone_number}
                                            <MDButton
                                                useBrandingColor={'primary'}
                                                color="greenLight"
                                                ml={2}
                                                onClick={releasePhoneNumber}
                                                sx={{ float: 'right' }}
                                            >
                                                Release Number
                                            </MDButton>
                                        </MDTypography>
                                        : <MDButton
                                            useBrandingColor={'primary'}
                                            color="greenLight"
                                            onClick={() => {
                                                setBuyNumberModalOpen(true);
                                                getAvailableNumbers()
                                            }}
                                        >
                                            Buy Phone Number
                                        </MDButton>
                                    }
                                </Grid>
                                <FormikInput
                                    name={'email'}
                                    useBrandingColor={'secondary'}
                                    label={'Email Address'}
                                    placeholder={'input email address'}
                                    type={'email'}
                                    setFieldValue={setFieldValue}
                                    errors={errors}
                                    item xs={12} lg={6} md={6}
                                />
                                <FormikInput
                                    name='website'
                                    useBrandingColor={'secondary'}
                                    label={'Website URL'}
                                    setFieldValue={setFieldValue}
                                    placeholder='copy website URL here'
                                    errors={errors}
                                    item xs={12} lg={6} md={6}
                                />

                                <Grid item xs={12} lg={12} md={12}>
                                    <hr className="MuiDivider-root MuiDivider-fullWidth" style={{ background: '#C6C9CE', margin: '12px 0' }} />
                                </Grid>


                                <FormikInput
                                    useBrandingColor={'secondary'}
                                    name={'address'}
                                    label={'Address'}
                                    placeholder={'input address'}
                                    setFieldValue={setFieldValue}
                                    errors={errors}
                                    item xs={12} lg={6} md={6}
                                />
                                <FormikInput
                                    useBrandingColor={'secondary'}
                                    name={'timezone'}
                                    labelFieldName={'label'}
                                    label={'Timezone'}
                                    setFieldValue={setFieldValue}
                                    placeholder={'(GMT - 08:00) America/Los Angeles'}
                                    options={Timezones || []}
                                    errors={errors}
                                    item xs={12} lg={6} md={6}
                                />

                                <Grid item xs={12} lg={12} md={12}>
                                    <hr className="MuiDivider-root MuiDivider-fullWidth" style={{ background: '#C6C9CE', margin: '12px 0' }} />
                                </Grid>

                                <Grid item xs={12} lg={6} md={6} mt={4} style={{ height: 50 }} >
                                    <MDButton useBrandingColor={'primary'} color="greenLight" className={'btn-save'} onClick={() => setTaxesModalOpen(true)} >
                                        Add Tax Value
                                    </MDButton>
                                </Grid>

                                <Grid item xs={12} lg={12} md={12} mt={3} mb={3} display="flex" justifyContent="center" alignItems="center">
                                    <MDButton
                                        useBrandingColor={'primary'}
                                        type="submit"
                                        color="greenLight"
                                        className={'btn-save-task'}
                                        onClick={() => updateCompany(true)}
                                    >
                                        Save Changes
                                    </MDButton>
                                </Grid>

                            </Grid>
                        </Form>
                    )
                }}
            </Formik>

            <Grid container padding={4}>
                <SimpleTable data={DataTaxes} emptyLabelText={'No taxes created'} />
            </Grid>
            {addExpenseModal}
            {buyNumberModal}
        </AdminLayout>
    )
})
