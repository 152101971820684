import { useEffect, useState } from "react";
import moment from 'moment'
import { Grid, InputAdornment, IconButton } from '@mui/material';
import AdminLayout from "../../../components/AdminLayout";
import { useNavigate } from "react-router-dom";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import MDInput from "../../../components/MDInput";
import MDButton from "../../../components/MDButton";
import MDDatePicker from "../../../components/MDDatePicker";
import {HeaderTab} from "../components/HeaderTab";
import {Tables} from "../components/Tables";
import {DateFilter} from "../components/DateFilter";
import "../styles.css"

const header = [
    {title: 'Contact', align: '', id: 1},
    {title: 'Company', align: '', id: 2},
    {title: 'Lead', align: '', id: 3},
    {title: 'Phone', align: '', id: 4},
    {title: 'Email', align: '', id: 5},
    {title: 'Referred By', align: '', id: 6},
];
const rows = [
    {number: 'Lorem Ipsum', name: 'Lorem Ipsum', created: 'Lorem Ipsum', due: 'Lorem Ipsum', total: 'Lorem Ipsum', completed: 'Lorem Ipsum', id: 1},
    {number: 'Lorem Ipsum', name: 'Lorem Ipsum', created: 'Lorem Ipsum', due: 'Lorem Ipsum', total: 'Lorem Ipsum', completed: 'Lorem Ipsum',  id: 2},
    {number: 'Lorem Ipsum', name: 'Lorem Ipsum', created: 'Lorem Ipsum', due: 'Lorem Ipsum', total: 'Lorem Ipsum', completed: 'Lorem Ipsum',  id: 3},
    {number: 'Lorem Ipsum', name: 'Lorem Ipsum', created: 'Lorem Ipsum', due: 'Lorem Ipsum', total: 'Lorem Ipsum', completed: 'Lorem Ipsum',  id: 4},
    {number: 'Lorem Ipsum', name: 'Lorem Ipsum', created: 'Lorem Ipsum', due: 'Lorem Ipsum', total: 'Lorem Ipsum', completed: 'Lorem Ipsum',  id: 5},
    {number: 'Lorem Ipsum', name: 'Lorem Ipsum', created: 'Lorem Ipsum', due: 'Lorem Ipsum', total: 'Lorem Ipsum', completed: 'Lorem Ipsum',  id: 6},
    {number: 'Lorem Ipsum', name: 'Lorem Ipsum', created: 'Lorem Ipsum', due: 'Lorem Ipsum', total: 'Lorem Ipsum', completed: 'Lorem Ipsum',  id: 7},
    {number: 'Lorem Ipsum', name: 'Lorem Ipsum', created: 'Lorem Ipsum', due: 'Lorem Ipsum', total: 'Lorem Ipsum', completed: 'Lorem Ipsum',  id: 8},
  ];

const options = [
    {label: 'Option 1', value: 1}, 
    {label: 'Option 2', value: 2},
    {label: 'Option 3', value: 3},
    {label: 'Option 4', value: 4}
];

const data = [
    {title: 'Contact Info', active: 1, id: 1},
    {title: 'Properties', active: 1, id: 2}
  ];

export default function ClientContact(props) {
    const navigate = useNavigate();

  return (
    <AdminLayout title={"Client Contact Info"}>
        <Grid container alignItems="top" paddingTop={0} marginTop={0} spacing={3}>
            <Grid item xs={12} lg={12} md={12} mb={2}>
                <MDTypography variant="h2" fontWeight="medium" mb={1} ml={3} style={{ float: 'left' }}>
                    Client Contact Info
                </MDTypography>
            </Grid>
           
            <Grid item xs={12} lg={12} md={12} mb={3}>
                <HeaderTab data={data}/>
            </Grid>
        </Grid>
        <Grid container spacing={3}>
            <Grid item xs={12} lg={12} md={12} ml={2}>
                <MDTypography variant="h3" fontWeight="medium" mb={1} style={{ float: 'left' }}>
                    List
                </MDTypography>
                <DateFilter style={{ float: 'right', display: 'inline', marginBottom: 10 }} range={false}/>
                <Tables body={rows} header={header}/>
            </Grid>
        </Grid>
    </AdminLayout>
  )
}
