import { useEffect, useState } from "react";
import moment from 'moment'
import { Grid, InputAdornment, IconButton } from '@mui/material';
import AdminLayout from "../../components/AdminLayout";
import { useNavigate } from "react-router-dom";
import MDTypography from "../../components/MDTypography";
import MDButton from "../../components/MDButton";
import MDAutocomplete from "../../components/MDAutocomplete";
import MDInput from "../../components/MDInput";
import { FilterButton } from "./components/FilterButton";
import pxToRem from "../../assets/theme/functions/pxToRem";
import SimpleTable from "components/SimpleTable";
import "./styles.css"

// Utils
import { useStores } from "models";
import { showMessage } from "services/helpers";

const datatableModelExpenses = {
  columns: [
    { Header: "Item Name", accessor: "item_name" },
    { Header: "Job Title", accessor: "job_title" },
    { Header: "Date", accessor: "date" },
    { Header: "Reimburse To", accessor: "reimburse_for" },
    { Header: "Receipt", accessor: "attachment", sorted: false },
  ],
  rows: []
}

export default function Expenses() {
  const rootStore = useStores();
  const { loginStore } = rootStore;

  const navigate = useNavigate();

  const [ExpenseData, setExpenseData] = useState([]);
  const [Expenses, setExpenses] = useState({ ...datatableModelExpenses });
  const [Jobs, setJobs] = useState([]);
  const [SelectedJob, setSelectedJob] = useState();
  const [StartDate, setStartDate] = useState();
  const [EndDate, setEndDate] = useState();
  const [DateFilters, setDateFilters] = useState(false);

  const getJobs = () => {
    loginStore.environment.api.getJobs('')
      .then(result => {
        const { data } = result;
        let results = data.results.map(d => ({ label: d.title, value: d.id }));
        setJobs(results);
      })
  }

  const getExpenses = () => {
    loginStore.environment.api.getExpenses()
      .then((result) => {
        const { data } = result
        const { results } = data

        setExpenseData(results)
        setTableData(results)

      })
      .catch(() => showMessage())
  }

  const getExpensesFile = (id) => {
    loginStore.environment.api.getExpensesFile(id).then((result) => {
      if (result.kind === "ok") {
        result.download_file()
      } else showMessage('An error occurred, please try again later')
    }).catch(err => showMessage())
  }

  const setTableData = data => {

    let dataEx = { ...datatableModelExpenses }
    dataEx.rows = []

    data.map(r => {
      dataEx.rows.push({
        id: r.id,
        item_name: r.item_name,
        job_title: r?.job?.title,
        date: moment(r.date).format('DD/MM/YYYY HH:mm'),
        reimburse_for: r?.reimburse_for?.full_name,
        attachment: r.attachment
          ? <MDTypography variant="h6" sx={{ cursor: 'pointer' }} fontWeight="medium" color='success' onClick={() => getExpensesFile(r.id)}>
            DOWNLOAD
          </MDTypography>
          : ''

      })
    })
    setExpenses(dataEx)
  }

  const applyFilters = () => {
    let data = SelectedJob ? ExpenseData.filter(e => e?.job?.id === SelectedJob?.value) : ExpenseData
    if (StartDate) data = data.filter(d => d.date >= StartDate)
    if (EndDate) data = data.filter(d => d.date <= EndDate)
    setTableData(data)
  }

  useEffect(() => {
    getExpenses()
    getJobs()
  }, [])

  return (
    <AdminLayout title={"Expenses"}>
      <Grid container alignItems="top" marginTop={0} pt={{ xs: 7.5, sm: 0 }}>
        <Grid item xs={12} lg={12} md={12} mb={3}>
          <Grid container style={{ background: '#fff', borderRadius: 16, padding: 22 }}>
            <Grid item xs={12} md={4} lg={4} p={2}>
              <MDAutocomplete
                useBrandingColor={'secondary'}
                label="Job Name"
                placeholder="select job"
                options={Jobs}
                value={SelectedJob}
                onChange={(e, val) => setSelectedJob(val)}
                isOptionEqualToValue={(option, value) => (option?.value === value?.value)}
              />
            </Grid>

            {DateFilters
              ? <>
                <Grid item xs={6} md={3} lg={3} p={2}>
                  <MDTypography variant="h6" fontWeight="bold">
                    Start Date
                  </MDTypography>
                  <MDInput
                    useBrandingColor={'secondary'}
                    fullWidth
                    input={{ placeholder: "Select date" }}
                    type='date'
                    value={StartDate}
                    onChange={e => setStartDate(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6} md={3} lg={3} p={2}>
                  <MDTypography variant="h6" fontWeight="bold">
                    End Date
                  </MDTypography>
                  <MDInput
                    useBrandingColor={'secondary'}
                    fullWidth
                    input={{ placeholder: "Select date" }}
                    type='date'
                    value={EndDate}
                    onChange={e => setEndDate(e.target.value)}
                  />
                </Grid>
              </>
              : <Grid item xs={12} md={4} lg={4} p={2}>
                <MDButton
                  useBrandingColor={'primary'}
                  variant="outlined"
                  fullWidth
                  color="green"
                  style={{ float: 'left', marginTop: 30 }}
                  onClick={() => setDateFilters(true)}
                >
                  Select Date Range
                </MDButton>
              </Grid>
            }

            <Grid item xs={12} md={2} lg={2} p={2}>
              <MDButton
                useBrandingColor={'primary'}
                fullWidth
                color="green"
                style={{ marginTop: 30 }}
                onClick={() => applyFilters()}
              >
                Apply
              </MDButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={5} lg={5} md={5} mb={3}>
          <MDTypography useBrandingColor={'tertiary'} variant="h2" fontWeight="medium" mb={1} ml={3}>
            All Jobs
          </MDTypography>
        </Grid>
        <Grid item xs={7} lg={7} md={7} justifyContent={"right"} display={"flex"} flexDirection={"row"}>
          <FilterButton />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={12} md={12} ml={2}>
          <SimpleTable data={Expenses} />
        </Grid>
      </Grid>
    </AdminLayout>
  )
}
