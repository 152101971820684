import { useEffect, useState } from "react";
import moment from 'moment'
import { Grid, InputAdornment, IconButton } from '@mui/material';
import AdminLayout from "../../../components/AdminLayout";
import { useNavigate } from "react-router-dom";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import MDInput from "../../../components/MDInput";
import MDButton from "../../../components/MDButton";
import MDDatesPicker from 'components/MDDatesPicker';
import {HeaderFilter} from "../components/HeaderFilter";
import {Tables} from "../components/Tables";
import {DateFilter} from "../components/DateFilter";
import "../styles.css"

const NewIcon = (props) => (
    <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.9181 0.950195L10.3981 7.4702C9.62812 8.2402 8.36813 8.2402 7.59813 7.4702L1.07812 0.950195" stroke="#fff" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );


export default function ClientBalanceSummary(props) {
    const navigate = useNavigate();

  return (
    <AdminLayout title={"GPS Waypoints"}>
        <Grid container alignItems="top" paddingTop={0} marginTop={0} spacing={3}>
            <Grid item xs={12} lg={12} md={12} mb={2}>
                <MDTypography variant="h2" fontWeight="medium" mb={1} ml={3} style={{ float: 'left' }}>
                    GPS Waypoints
                </MDTypography>
            </Grid>
        </Grid>
        <MDBox style={{ background: '#fff', padding: 34, borderRadius: 16 }}>
        <Grid container spacing={3}>
            <Grid item xs={12} lg={5} md={6}>
            
            <MDTypography variant="h6" fontWeight="bold">
                Date
            </MDTypography>
            <MDDatesPicker placeholder='input date' />

            <hr className="MuiDivider-root MuiDivider-fullWidth" style={{background: '#C6C9CE', margin: '16px 0'}} />

            <MDTypography variant="h6" fontWeight="bold" style={{ float: 'left' }}>Jane Cooper</MDTypography> 
            <MDButton color="dark" style={{ float: 'right', marginLeft: 10 }} iconOnly><NewIcon /></MDButton>
            <MDButton color="green" style={{ float: 'right' }} iconOnly>8</MDButton>

            <ul style={{ listStyle: 'none', marginTop:80 }}>
                <li>10:20 AM <span style={{ color: '#60A77C' }}>Clocked In</span></li>
                <li>15:00 PM <span style={{ color: 'red' }}>Clocked Out</span></li>
                <li>10:20 AM <span style={{ color: '#60A77C' }}>Clocked In</span></li>
                <li>15:00 PM <span style={{ color: 'red' }}>Clocked Out</span></li>
                <li>10:20 AM <span style={{ color: '#60A77C' }}>Clocked In</span></li>
                <li>15:00 PM <span style={{ color: 'red' }}>Clocked Out</span></li>
                <li>10:20 AM <span style={{ color: '#60A77C' }}>Clocked In</span></li>
                <li>15:00 PM <span style={{ color: 'red' }}>Clocked Out</span></li>
            </ul>
                
            </Grid>
            <Grid item xs={12} lg={6} md={6}>
                <div className="google-map-code">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15282225.79979123!2d73.7250245393691!3d20.750301298393563!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30635ff06b92b791%3A0xd78c4fa1854213a6!2sIndia!5e0!3m2!1sen!2sin!4v1587818542745!5m2!1sen!2sin" width="600" height="450" frameborder="0" style={{border:0}} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
                </div>
            </Grid>
        </Grid>
        </MDBox>
    </AdminLayout>
  )
}
