import React, { useEffect, useState } from "react";
import {Link as RouterLink} from "react-router-dom";
import moment from 'moment'
import { Grid, InputAdornment, IconButton, Link } from '@mui/material';
import "../styles.css";
// import MonthsSwitch from "components/MonthsSwitch";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
// import {AddButton} from "./components/AddButton";
//icons
import SearchIcon from 'assets/images/icons/search.png';
//Pages
import AllInventoryTab from './tabs/AllInventoryTab';
import ImportItemsTab from './tabs/ImportItemsTab';
import PayrollTab from './tabs/PayrollTab';
import {SwitchView} from "../../inventory/components/SwitchView";
import {AddButton} from "../../inventory/components/AddButton";
import AdminLayout from "../../../components/AdminLayout";
import AdminPanelLayout from "../../../components/AdminPanelLayout";
import {dataTableModel, renderTableRow} from "../../clients/utils";
import {showMessage, useApi} from "../../../services/helpers";
import SimpleTable from "../../../components/SimpleTable";
import {useStores} from "models";
import { ROUTES } from 'constants';
// import "../styles.css"

const datatableModel = {
    columns: [
      {Header: "Name (A-Z)", accessor: "full_name"},
      {Header: "Address", accessor: "address_1", sorted: false},
      {Header: "Phone Number", accessor: "phone_number", sorted: false},
      {Header: "Email", accessor: "email", sorted: false},
      {Header: "Industry", accessor: "industry", sorted: false},
    ],
    rows: []
}


export default function ApproveAccounts() {
  const rootStore = useStores();
  const {loginStore} = rootStore;
  const [usersData, setUsersData] = useState(datatableModel);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = React.useState(1);
  const [current, setCurrent] = React.useState('list');
  
  const handleChange = (val) => {
    setValue(val);
    const approvedFilters = [
      "&approved__isnull=True",
      "&approved=True",
      "&approved=False"
    ]
    getUsers(approvedFilters[val-1]);
  };
  
  const getUsers = (searchData, extraData) => {
    setLoading(true);
    loginStore.environment.api.getUsersAdmin(searchData, extraData)
    .then((result) => {
      if (result.kind === "ok") {
        let users = result.data.results;        
        const usersDataCopy = {...usersData};
        users = users.map((user, udix) => ({...user, 
          full_name: <RouterLink to={ROUTES.ADMIN_USERS_APPROVE_ACCOUNTS_DETAILS(user.id)} className='base-item link-item'>{user.full_name}</RouterLink>
        }));
        usersDataCopy.rows = users;
        setUsersData(usersDataCopy);
      } else {
        if (result.kind === "bad-data") {
          showMessage(result?.errors, 'error', true);
        } else {
          showMessage('An error occurred, please try again later');
        }
      }
    })
    .finally(() => setLoading(false))
  }

   useEffect(() => {
    getUsers();
  }, []);

  return (
    <AdminPanelLayout title={"Approve Accounts"} searchFunc={getUsers} loading={loading}>
      <MDBox sx={{ width: '100%' }} px={2}>
        <MDBox className="tabs">
          <Link component="button" onClick={() => handleChange(1)}>
            <MDTypography className={["tab", value === 1 ? "active" : ""].join(" ")}>All Requests</MDTypography>
          </Link>
          <Link component="button" onClick={() => handleChange(2)}>
            <MDTypography className={["tab", value === 2 ? "active" : ""].join(" ")}>Approved</MDTypography>
          </Link>
          <Link component="button" onClick={() => handleChange(3)}>
            <MDTypography className={["tab", value === 3 ? "active" : ""].join(" ")}>Denied</MDTypography>
          </Link>
        </MDBox>
        <MDBox mt={8}>
          <SimpleTable data={usersData}/>
        </MDBox>
      </MDBox>
    </AdminPanelLayout>
  )
}
