import React, { useEffect, useRef, useState } from "react";
import 'react-calendar-timeline/lib/Timeline.css'
import { Grid } from '@mui/material';
import AdminLayout from "../../../components/AdminLayout";
import moment from 'moment'

import "./react-calendar-timeline.css"
import MDTypography from "../../../components/MDTypography";
import { Form, Formik } from "formik";
import FormikInput from "components/FormikInput";
import MDDialog from "components/MDDialog";
import { jobInitialValues, jobValidationSchema } from "./form";
import { useNavigate, useParams } from "react-router-dom";
import { useStores } from "../../../models";
import { showMessage, downloadFile } from "../../../services/helpers";
import { useIsMobile } from "services/hooks";
import MDButton from "../../../components/MDButton";
import { ROUTES } from "constants";
import ClockIcon from 'assets/images/icons/clock.svg';
import SimpleTable from "../../../components/SimpleTable";
import Expenses from "screens/expenses";

const datatableModel = {
    columns: [
        { Header: "Name (A-Z)", accessor: "name" },
        { Header: "Title", accessor: "title" },
        { Header: "Time", accessor: "start_time" },
        { Header: "", accessor: "action", sorted: false },
    ],
    rows: []
}

const datatableModelExpenses = {
    columns: [
        { Header: "Name (A-Z)", accessor: "item_name" },
        { Header: "Date", accessor: "date" },
        { Header: "Reimburse To", accessor: "reimburse_for" },
        { Header: "Receipt", accessor: "attachment", sorted: false },
    ],
    rows: []
}

export default function Jobs() {
    const navigate = useNavigate();
    const rootStore = useStores();
    const { id } = useParams();
    const isMobile = useIsMobile();
    const { loginStore } = rootStore;
    const formikRef = useRef()

    const [Quotes, setQuotes] = React.useState([]);
    const [Job, setJob] = useState(null);

    const [DataTasks, setDataTasks] = useState({ ...datatableModel });

    const [Expenses, setExpenses] = useState({ ...datatableModelExpenses });
    const [ExpensesModal, setExpensesModal] = useState(false);
    const [ReimburseToOptions, setReimburseToOptions] = React.useState([]);
    const [file, setFile] = useState(null);

    const handleUploadFile = (e) => {
        const [file] = e.target.files;
        if (file) setFile(file)
    }

    const getExpensesFile = (id) => {
        loginStore.environment.api.getExpensesFile(id).then((result) => {
          if (result.kind === "ok") {
            result.download_file()
          } else showMessage('An error occurred, please try again later')
        }).catch(err => showMessage())
      }

    const saveExpense = (values) => {
        let data = {...values}

        if (file) data.attachment = file
        data.job = id
        data.reimburse_for = values?.reimburse_for?.id

        loginStore.environment.api.createExpense(data)
            .then((result) => getJobDetail(id))
            .catch(() => showMessage())
            .finally(() => setExpensesModal(false))
    }

    const saveJob = (values) => {
        let data = {
            ...values,
            quote: values?.quote?.id,
            assign_to: values?.assign_to?.id,
        }

        loginStore.environment.api.createJob(data).then((result) => {
            if (result.kind === "ok") {
                showMessage('The job has been created', 'success')
                navigate(ROUTES.JOBS)
            } else {
                if (result.kind === "bad-data") showMessage(result?.errors, 'error', true)
                else showMessage('An error occurred, please try again later')
            }
        })
    }
    const editJob = (values) => {
        let data = {
            ...values,
            assign_to: values?.assign_to?.id,
            quote: values?.quote?.id,
            id: Job.id,
        }
        
        loginStore.environment.api.updateJob(data).then((result) => {
            if (result.kind === "ok") {
                showMessage('The job has been updated', 'success');
                navigate(ROUTES.JOBS)
            } else {
                if (result.kind === "bad-data") {
                    showMessage(result?.errors, 'error', true)
                }
            }
        }).catch(error => showMessage(error.message))
    }

    const getEmployes = () => {
        loginStore.environment.api.getTeamMembers('')
            .then((result) => {
                const { data } = result
                const { results } = data
                setReimburseToOptions(results)
            })
            .catch(() => showMessage())
    }

    const getQuotes = () => {
        loginStore.environment.api.getQuotes('')
            .then((result) => {
                const { data } = result
                const { results } = data
                setQuotes(results)
            })
            .catch(() => showMessage())
    }

    const createTimeEntryReq = (data, currentClocking) => {
        setDataTasks({ ...datatableModel })
        let temp
        currentClocking
            ? temp = {
                ...currentClocking,
                start_user: currentClocking?.start_user?.id,
                end_date: moment().format(),
                finish_user: loginStore.id,
            }
            : temp = {
                task: data.id,
                start_date: moment().format(),
                end_date: null,
                start_user: loginStore.id,
                finish_user: null,
            }

        loginStore.environment.api.createTimeEntry(temp).then((result) => {
            if (result.kind === "ok") getJobDetail(id)
            else {
                if (result.kind === "bad-data") showMessage(result?.errors, 'error', true)
                else showMessage('An error occurred, please try again later')
            }
        })
    }

    const getJobDetail = (id) => {
        loginStore.environment.api.getJobDetail(id)
            .then((result) => {
                if (result.kind === 'ok') setJob(result.data)
                // tasks
                if (result.data?.job_tasks) {
                    let data = { ...datatableModel }
                    data.rows = []
                    result.data?.job_tasks.map(r => {
                        // get current clocking
                        let currentClocking
                        if (Array.isArray(r.task_timeEntries)) currentClocking = r.task_timeEntries.find(c => c.end_date === null)
                        data.rows.push({
                            name: r?.task_for?.first_name,
                            start_time: moment(r.start_date).format('DD/MM/YYYY HH:mm'),
                            currentClocking,
                            actionFunc: () => createTimeEntryReq(r, currentClocking),
                            action: <MDButton
                                onClick={() => createTimeEntryReq(r, currentClocking)}
                                variant="gradient"
                                color={currentClocking ? 'error' : "success"}
                            >{
                                    !isMobile
                                        ? currentClocking ? 'End Clocking' : "Start Clocking"
                                        : <img src={ClockIcon} />
                                }</MDButton>,
                            ...r
                        })
                    })
                    setDataTasks(data)
                }

                // expenses
                if (result.data?.job_expenses) {
                    let data = { ...datatableModelExpenses }
                    data.rows = []
                    result.data?.job_expenses.map(r => {
                        data.rows.push({
                            id: r.id,
                            item_name: r.item_name,
                            date: moment(r.date).format('DD/MM/YYYY HH:mm'),
                            reimburse_for: r?.reimburse_for?.full_name,
                            attachment: r.attachment
                                ? <MDTypography variant="h6" sx={{ cursor: 'pointer' }} fontWeight="medium" color='success' onClick={() => getExpensesFile(r.id)}>
                                    DOWNLOAD
                                </MDTypography>
                                : ''

                        })
                    })
                    setExpenses(data)
                }

            })
            .catch(() => showMessage())
    }

    const addExpenseModal = (
        <MDDialog
            open={ExpensesModal}
            handleClose={() => setExpensesModal(false)}
            title='Add Expense'
            paddingTop={10}
            style={{ width: 600, maxHeight: 1000 }}
        >
            <Formik
                validateOnBlur={false}
                validateOnChange={false}
                initialValues={{

                }}
                onSubmit={(values) => saveExpense(values)}
            >
                {({ errors, setFieldValue, values }) => {
                    return (
                        <Form>
                            <Grid container spacing={{ xs: 2, md: 3, lg: 3 }} alignItems={'flex-start'}>
                                <FormikInput
                                  useBrandingColor={'secondary'}
                                  name={'item_name'}
                                  label={'Item Name'}
                                  placeholder={'input item name'}
                                  errors={errors}
                                  item xs={12}
                                />
                                <FormikInput
                                  useBrandingColor={'secondary'}
                                    name={'description'}
                                    label={'Description'}
                                    placeholder={'input description'}
                                    errors={errors}
                                    item xs={12}
                                />
                                <FormikInput
                                  useBrandingColor={'secondary'}
                                    fullWidth
                                    label='Date'
                                    placeholder='input date'
                                    type='datetime-local'
                                    name='date'
                                    errors={errors}
                                    item xs={12}
                                />
                                <FormikInput
                                    useBrandingColor={'secondary'}
                                    name={'reimburse_for'}
                                    label={'Reimburse for'}
                                    labelFieldName={'name'}
                                    type={'select'}
                                    placeholder='a team member will be assign to your request'
                                    setFieldValue={setFieldValue}
                                    options={ReimburseToOptions}
                                    errors={errors}
                                    item xs={12}
                                />
                                <FormikInput
                                    inputFileStyles={{ backgroundColor: '#60A77C', borderRadius: '8px', width: '38px', height: '38px' }}
                                    errors={errors}
                                    name={'total'}
                                    type={'file'}
                                    handleChange={(e) => handleUploadFile(e)}
                                    item xs={1}
                                />

                                {/* <Grid item xs={12} lg={6} display={'flex'} alignItems={'center'}>
                                    <MDTypography sx={{ mr: 5 }} variant="h6" fontWeight="bold">
                                        On sight assesment required
                                    </MDTypography>

                                    <FormikInput
                                        useBrandingColor={'secondary'}
                                        sx={{ mt: 3 }}
                                        name={'sight_assessment_required'}
                                        type={'boolean'}
                                        setFieldValue={setFieldValue}
                                        errors={errors}
                                    />
                                </Grid>
                                 */}

                                <Grid item xs={11} mt={1}>
                                    <MDTypography variant={'subtitle2'}>{file?.name}</MDTypography>
                                </Grid>
                                <Grid item xs={12} lg={12} md={12} mb={3} display="flex" justifyContent="center" alignItems="center">
                                    <MDButton
                                      useBrandingColor={'primary'}
                                        color="green"
                                        type="submit"
                                        className={'btn-save-task'}
                                    >
                                        SAVE
                                    </MDButton>
                                </Grid>
                            </Grid>
                        </Form>
                    )
                }}
            </Formik>
        </MDDialog>
    )

    useEffect(() => {
        getQuotes()
        getEmployes()
        if (id) getJobDetail(id)
    }, [loginStore]);

    return (

        <AdminLayout title={"Job"}>
            <Grid container alignItems="top" paddingTop={0} marginTop={0}>
                <Grid item xs={12} lg={9} md={9} ml={4}>
                    <MDTypography variant="h2" fontWeight="medium">
                        {`${id ? 'Edit' : 'Create New'} Job`}
                    </MDTypography>
                </Grid>
            </Grid>

            <Formik
                innerRef={formikRef}
                initialValues={jobInitialValues(Job)}
                validationSchema={jobValidationSchema}
                validateOnBlur={false}
                validateOnChange={false}
                enableReinitialize
                onSubmit={values => {
                    id ? editJob(values) : saveJob(values)
                }}
                  
            >
                {({ errors, setFieldValue, values }) => {
                    return (
                        <Form>
                            <Grid container spacing={3} pb={3} className="task_container">

                                <FormikInput
                                  useBrandingColor={'secondary'}
                                    name={'title'}
                                    label={'Title'}
                                    placeholder={'input title'}
                                    errors={errors}
                                    item xs={12} md={6} lg={6}
                                />

                                <FormikInput
                                  useBrandingColor={'secondary'}
                                    name={'quote'}
                                    label={'Quote'}
                                    labelFieldName={'title'}
                                    placeholder={'select quote'}
                                    type={'select'}
                                    setFieldValue={setFieldValue}
                                    options={Quotes}
                                    errors={errors}
                                    value={values.quote}
                                    item xs={12} lg={6} md={6}
                                />

                                <FormikInput
                                  useBrandingColor={'secondary'}
                                    label={'Start Date'}
                                    input={{ placeholder: "Select date" }}
                                    type='datetime-local'
                                    name='start_date'
                                    errors={errors}
                                    item xs={12} lg={6} md={6}
                                />
                                <FormikInput
                                  useBrandingColor={'secondary'}
                                    label={'End Date'}
                                    input={{ placeholder: "Select date" }}
                                    type='datetime-local'
                                    name='end_date'
                                    errors={errors}
                                    item xs={12} lg={6} md={6}
                                />

                                <FormikInput
                                    useBrandingColor={'secondary'}
                                    name={'assign_to'}
                                    label={'Assign to'}
                                    labelFieldName={'name'}
                                    type={'select'}
                                    placeholder='a team member will be assign to your job'
                                    setFieldValue={setFieldValue}
                                    options={ReimburseToOptions}
                                    errors={errors}
                                    item xs={12} lg={6} md={6}
                                />
                                <Grid item xs={12} lg={6} md={6} mt={2} display={'flex'} alignItems={'center'}>
                                    <MDTypography sx={{ mr: 5 }} variant="h6" fontWeight="bold">
                                        Assign to all team members
                                    </MDTypography>
                                    <FormikInput
                                        useBrandingColor={'secondary'}
                                        sx={{ mt: 3 }}
                                        name={'assign_to_all'}
                                        type={'boolean'}
                                        setFieldValue={setFieldValue}
                                        errors={errors}
                                    />
                                </Grid>



                                <Grid item xs={12} lg={12} md={12} display="flex" justifyContent="center"
                                    alignItems="center">
                                    <MDButton
                                        useBrandingColor={'primary'}
                                        color="green"
                                        type="submit"
                                        className={'btn-save-task'}
                                    // onClick={() => handleCreate(true)}
                                    >
                                        {`${id ? 'EDIT' : 'CREATE'} JOB`}
                                    </MDButton>
                                </Grid>
                            </Grid>
                        </Form>
                    )
                }}
            </Formik>

            <Grid container padding={4}>
                <SimpleTable data={DataTasks} emptyLabelText={'No tasks created'} />
            </Grid>

            {id &&
                <Grid container padding={4}>
                    <Grid item xs={12} lg={12} md={12} mb={2}>
                        <MDButton useBrandingColor={'primary'} color="green" className={'btn-save-task'} onClick={() => setExpensesModal(true)}>
                            NEW EXPENSE
                        </MDButton>
                    </Grid>
                    <SimpleTable data={Expenses} emptyLabelText={'No expenses created'} />
                </Grid>
            }

            {addExpenseModal}
        </AdminLayout>
    )
}
