import {useEffect, useRef, useState} from "react";

// react-router-dom
import {useNavigate} from "react-router-dom";

/* MUI components */
import {Box} from '@mui/material';
import Grid from "@mui/material/Grid";

/* MD components */
import MDTypography from "components/MDTypography";

/* utils */
import {showMessage, useApi} from "services/helpers";

// Images
import man_sit_pc from '../../assets/images/man_sit_pc.png';
import {observer} from "mobx-react-lite";
import {ROUTES} from 'constants'

// Authentication layout components

function ActivateUser() {
  const api = useApi()
  const navigate = useNavigate()
  const getUrls = window.location.href.split('activate-user/')[1].split('/')
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const activateAccount = () => {
    const data = {
      uidb64: getUrls[0],
      token: getUrls[1]
    }
    api.activateAccount(data).then((result) => {
      if (result.kind === "ok") {
        setSuccess(true)
        showMessage('Account verified successfully', 'success')
      } else if(result.kind === "bad-data") {
        setError(true)
        if (result.errors.token) {
          showMessage(result.errors.token)
        } else {
          showMessage(result.errors?.errors)
        }
      } else {
        setError(true)
        showMessage()
      }
    })
      .catch(err => {
        showMessage()
        setError(true)
      }).finally(() => setTimeout(() => navigate(ROUTES.LOGIN), 4000))
  }

  useEffect(() => {
    activateAccount()
  }, [])

  const right_login = <>
    <div style={{width: '100%'}}>
      <MDTypography color={'success'} variant="h4" fontWeight="medium" mb={3}>
        Thime
      </MDTypography>
      <MDTypography variant="h2" fontWeight="regular" mb={3}>
        Account Activation
      </MDTypography>
    </div>
    <img src={man_sit_pc} alt={"woman"} className={"woman-man-image"}
         style={{height: '50%', width: 'auto', marginLeft: '100px'}}/>
  </>

  const left_login = <>
    <MDTypography variant="h2" fontWeight="medium" mb={3}>
      {success && 'Account Verified' }
      {error && 'Error'}
    </MDTypography>
  </>


  return (
    <Box>
      <Grid
        container
        alignItems="center"
        className={"main-container"}
        style={{height: "100%"}}
      >
        <Grid
          item
          xs={12}
          lg={7}
          md={7}
          style={{backgroundColor: "#F4F6F5", height: "100%"}}
        >
          <div className="right-content">
            {right_login}
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          lg={5}
          md={5}
          style={{backgroundColor: "white", height: "100%"}}
        >
          <div className="left-content">
            {left_login}
          </div>
        </Grid>
      </Grid>
    </Box>
  )
}

export default observer(ActivateUser);
