import { Grid } from '@mui/material';
import SimpleTable from "components/SimpleTable";
import { useStores } from "models";
import moment from 'moment';
import { useEffect, useState } from "react";
import { showMessage } from "services/helpers";
import AdminLayout from "../../../components/AdminLayout";
import MDTypography from "../../../components/MDTypography";
import { Header } from "../components/Header";
import "../styles.css";

 
export const invoiceDataTableModel = {
  columns: [
    { Header: "Number", accessor: "id" },
    { Header: "Invoice for", accessor: "invoice_for" },
    { Header: "Title", accessor: "title" },
    { Header: "Status", accessor: "status" }, 
    { Header: "Subtotal", accessor: "subtotal" },
    { Header: "Discount", accessor: "discount" },
    { Header: "Total", accessor: "total" },      
  ],
  rows: []
}

export const jobDataTableModel = {
  columns: [
    { Header: "Number", accessor: "id" },
    { Header: "Title", accessor: "title" },
    { Header: "Client", accessor: "client" },
    { Header: "Quote", accessor: "quote" },
    { Header: "Subtotal", accessor: "subtotal" },
    { Header: "Discount", accessor: "discount" },
    { Header: "Total", accessor: "total" },
    { Header: "End Date", accessor: "end_date" },
    ],
  rows: []
}

const headers = [
    {title: 'Total Invoices', quantity: '0', content: '', id: 2},
    {title: 'Total jobs', quantity: '0', content: '', class:'', id: 1},    
  ];

export default function DailyTransaction(props) {
    const rootStore = useStores();
    const {loginStore} = rootStore;
    const [Invoices, setInvoices] = useState({...invoiceDataTableModel});
    const [Jobs, setJobs] = useState({...jobDataTableModel});
    const [Headers, setHeaders] = useState(headers);
    const [loading, setLoading] = useState(false);
    //
    const getDailyTransactionReport = () => {
        setLoading(true);
        loginStore.environment.api.getDailyTransactionReport()
        .then((result) => {
            if (result.kind === "ok") {
                const {invoices, jobs, total_jobs, total_invoices} = result.data;
                const parsedInvoices = invoices.map((invoice, iidix) => 
                ({...invoice, 
                    invoice_for: invoice.invoice_for?.full_name
                }))
                setInvoices({...Invoices, rows: parsedInvoices});
                const parsedJobs = jobs.map((job, jidx) => ({...job, 
                    client: job.quote.client_name,
                    quote: job.quote.title,
                    subtotal: job.quote.subtotal,
                    discount: job.quote.discount,
                    total: job.quote.total,
                    end_date: moment(job.end_date).format('MM/DD/YYYY')                    
                }))
                setJobs({...Jobs, rows: parsedJobs});
                setHeaders([
                    {title: 'Total Invoices', quantity: total_invoices, content: '', id: 2},
                    {title: 'Total jobs', quantity: total_jobs, content: '', class:'', id: 1},                    
                ]);
            } else {
                if (result.kind === "bad-data") {
                    showMessage(result?.errors, 'error', true);
                } else {
                    showMessage('An error occurred, please try again later');
                }
            }
        })
        .finally(() => setLoading(false))
    }
    
    useEffect(()=> {
        getDailyTransactionReport();
    } ,[]);

    return (

    <AdminLayout title={"Daily Transactions"} loading={loading}>
        <Grid item xs={12} lg={12} md={12} mb={3}>
            <Header data={Headers} styles={{ width: 164}}/>
        </Grid>
        {/* INVOICES */}
        <Grid container spacing={3} mt={2}>
            <Grid item xs={7} md={3} lg={10} mt='auto' pb={{ xs: '20px', md: 0 }}>
                <MDTypography useBrandingColor={'tertiary'} variant="h2" fontWeight="medium" width='12rem' ml={{ xs: 0, lg: 2 }}>
                    Invoices
                </MDTypography>
            </Grid>   
            <Grid item xs={12} lg={12} md={12} ml={2}>
                <SimpleTable data={Invoices} emptyLabelText={'No invoices found'}/>
            </Grid>
        </Grid>
        {/* JOBS */}
        <Grid container spacing={3} mt={4}> 
            <Grid item xs={7} md={3} lg={10} mt='auto' pb={{ xs: '20px', md: 0 }}>
                <MDTypography useBrandingColor={'tertiary'} variant="h2" fontWeight="medium" width='12rem' ml={{ xs: 0, lg: 2 }}>
                    Jobs
                </MDTypography>
            </Grid>      
            <Grid item xs={12} lg={12} md={12} ml={2}>
                <SimpleTable data={Jobs} emptyLabelText={'No jobs found'}/>
            </Grid>
        </Grid>
    </AdminLayout>
    )
}
