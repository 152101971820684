import * as React from 'react';
import Timeline, { DateHeader, TimelineHeaders } from 'react-calendar-timeline'
import 'react-calendar-timeline/lib/Timeline.css'
import moment from 'moment'
import Grid from '@mui/material/Grid';
import { CalendarToolbar } from "../component/CalendarToolbar";
import styles from "../Scheduler.module.css";
import MDBox from "../../../components/MDBox";
import "./react-calendar-timeline.css"
import MDTypography from "../../../components/MDTypography";
import { HourBox } from "../component/HourBox";
import Weeks from "../component/Weeks";

export default function TimelinePage({ onView, ItemContextMenu, events, groups}) {
  
  const today = moment(new Date()).format('YYYY-MM-DD');
  const defaultTimeStart = moment(today + ' ' + '07:00');
  const defaultTimeEnd = moment(today + ' ' + '15:00');

  const defaultTimeRange = defaultTimeEnd - defaultTimeStart;
  const itemRenderer = ({
    item,
    timelineContext,
    itemContext,
    getItemProps,
    getResizeProps,
    ...props
  }) => {
    const { left: leftResizeProps, right: rightResizeProps } = getResizeProps();
    let style = item.itemProps.style
    style.display = "flex"
    style.flexDirection = "row"
    style.alignItems = "center"

    return (
      <MDBox
        {...getItemProps({
          className: "timeline-item2",
          style: style,
          onMouseDown: () => { }
        })}
      >
        {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : null}
        <MDBox
          className={"timeline-item"}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
            overflow: "hidden",
            paddingLeft: 3,
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            fontSize: 14,
            marginLeft: 10,
            color: "var(--light-black)",
          }}
        >
          <MDTypography style={{ marginLeft: item.members.length * 15 + 5, }}>
            {itemContext.title}
          </MDTypography>
          <MDBox>
            <HourBox title={item.hours} color={item.contrast} />
          </MDBox>
        </MDBox>
        {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : null}
      </MDBox>
    );
  };

  const [draggedEvent, setDraggedEvent] = React.useState()
  const [counters, setCounters] = React.useState({ item1: 0, item2: 0 })

  const onDropFromOutside = React.useCallback(
    ({ start, end, allDay: isAllDay }) => {

      console.log(' draggedEvent ===>>> ', draggedEvent)

      // if (draggedEvent === 'undroppable') {
      //   setDraggedEvent(null)
      //   return
      // }
      // updateItem(draggedEvent, start, end)
      // const { name } = draggedEvent
      // const event = {
      //   title: draggedEvent.title,
      //   start,
      //   end,
      //   isAllDay,
      // }
      // setDraggedEvent(null)
      // setCounters((prev) => {
      //   const { [name]: count } = prev
      //   return {
      //     ...prev,
      //     [name]: count + 1,
      //   }
      // })
      // newEvent(event)
    },
    [draggedEvent, counters, setDraggedEvent, setCounters]
  )

  return (
    <Grid container alignItems="top" paddingTop={0} marginTop={0}>
      <Grid item xs={12} lg={12} md={12} className={styles.leftContainer}>
        <CalendarToolbar date={moment()} view={"team"} onNavigate={() => { }} onView={onView} />
        <Weeks style={{ marginTop: 80 }} />
          <Timeline
            onCanvasContextMenu={ItemContextMenu}
            style={{ marginTop: 22, height: 700 }}
            minZoom={defaultTimeRange}
            maxZoom={defaultTimeRange}
            items={events}
            itemHeightRatio={0.99}
            groups={groups}
            lineHeight={60}
            canMove
            showCursorLine
            stackItems
            onDropFromOutside={onDropFromOutside}
            itemRenderer={itemRenderer}
            defaultTimeStart={defaultTimeStart}
            defaultTimeEnd={defaultTimeEnd}
            onTimeChange={(_start, _end, updateScrollCanvas) => {
              updateScrollCanvas(
                moment(defaultTimeStart).valueOf(),
                moment(defaultTimeEnd).valueOf(),
              );
            }}
          >
            <TimelineHeaders>
              <DateHeader varient="hour" labelFormat={'h A'} />
            </TimelineHeaders>
          </Timeline>
      </Grid>
    </Grid>
  )
}
