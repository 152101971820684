

import React, {useEffect, useState} from 'react';
import {useStores} from "../models";
import { Device } from '@twilio/voice-sdk';
import {showMessage, useApi} from "./helpers";
import {observer} from "mobx-react-lite";
import {parsePhoneNumber} from "awesome-phonenumber";

export const TwilioContext = React.createContext();

export const TwilioContextProvider = observer(({ children }) => {
    const rootStore = useStores()
    const { loginStore } = rootStore
    const api = useApi();
    const [device, setDevice] = useState(null);
    const [token, setToken] = useState(null);
    const [deviceReady, setDeviceReady] = useState(false);
    const [currentCall, setCurrentCall] = useState(null);
    const [currentClient, setCurrentClient] = useState(null);
    const [currentNumber, setCurrentNumber] = useState(null);
    const [currentCallTime, setCurrentCallTime] = useState(0);

    useEffect(() => {
        if (currentCall){
            const interval = setInterval(() => {
                if(currentCall.status() === 'open') {
                    setCurrentCallTime((prevSeconds) => prevSeconds + 1);
                }
            }, 1000);
            return () => clearInterval(interval);
        }else{
            setCurrentCallTime(0)
        }
    }, [currentCall])

    const getAccessToken = () => {
        api.getTwilioVoiceToken(loginStore.company_id).handle({
            onSuccess: (result) => {
                setToken(result.response.token)
            }
        })
    }

    const getPerson = (phone) => {
        api.getPersonByNumber(phone).handle({
            onSuccess: (result) => { setCurrentClient(result.data) }
        })
    }

    useEffect(() => {
        if (!token)
            getAccessToken()
        // console.log('hicont')

        //
        if (device !== null || !loginStore.side_phone_open || !token)
        // if (device !== null || !token)
            return
        const deviceNew = new Device(token, {
            codecPreferences: ["opus", "pcmu"],
            fakeLocalDTMF: true,
            // Use `enableRingingState` to enable the device to emit the `ringing`
            // state. The TwiML backend also needs to have the attribute
            // `answerOnBridge` also set to true in the `Dial` verb. This option
            // changes the behavior of the SDK to consider a call `ringing` starting
            // from the connection to the TwiML backend to when the recipient of
            // the `Dial` verb answers.
            enableRingingState: true,
            debug: true,
        });



        deviceNew.on("error", function (error) {
            if (error.message.includes('TransportError (31009)')) // ignore messages error
                return
            setDeviceReady(false)
            setTimeout(() => {
                setDevice(null)
            }, 5000)
            showMessage('Phone system error, retrying in 5s...<br/>' + error.message, 'error')
            console.log("Twilio.Device Error: " + error.message);
        });

        deviceNew.on(Device.EventName.TokenWillExpire, function (conn) {
            // console.log("toekn will expire");
            getAccessToken()
        })

        deviceNew.on(Device.EventName.Incoming, function (call) {
            // console.log("incomming call", call);
            const pn = parsePhoneNumber( call.parameters?.From );
            if (pn.valid) {
                setCurrentNumber(pn.number.international)
            }else{
                setCurrentNumber(call.parameters?.From)
            }
            getPerson(call.parameters?.From)
            setCurrentCall(call)
            call.on('error', (e) => {
                showMessage(e.message)
            })
            call.on('disconnect', () => {
                // console.log('call disconnected')
                setCurrentCall(null)
                setCurrentNumber(null)
                setCurrentClient(null)
            })
        })
        deviceNew.on(Device.EventName.Registered, function (conn) {
            // console.log("registered");
            setDeviceReady(true)
        })

        deviceNew.register()

        setDevice(deviceNew)

    }, [token]);


    useEffect(() => {
        if (device && token)
            device.updateToken(token);
    }, [token, device]);

    const makeCall = (number) => {
        console.log(' make call number => ', device)
        if (device) {
            const outgoingConnection = device.connect({params:{To: number}});
            if (!outgoingConnection) {
                // console.log('failed to connect')
                return
            }
            console.log('connected call promise: ', outgoingConnection)
            outgoingConnection.then((call) => {
                setCurrentCall(call)
                getPerson(number)
                const pn = parsePhoneNumber( number );
                if (pn.valid) {
                    setCurrentNumber(pn.number.international)
                }else{
                    setCurrentNumber(call.parameters?.From)
                }
                call.on('error', (e) => {
                    // console.log(' call error ==>>>  ', e)
                    showMessage(e.message)
                })
                call.on('disconnect', () => {
                    // console.log('call disconnected')
                    setCurrentCall(null)
                    setCurrentNumber(null)
                    setCurrentClient(null)
                })

            })
            return outgoingConnection
        }
    }

    const finishCall = (ignore=false) => {
        if (currentCall) {
            if (currentCall.direction === 'OUTGOING'){
                currentCall.disconnect()

            }else{ // INCOMING
                if (currentCall.status() === 'pending') {
                    if (ignore)
                        currentCall.ignore()
                    else
                        currentCall.reject()
                }else{
                    currentCall.disconnect()
                }
            }
            setCurrentCall(null)
        }
    }

    return (
        <TwilioContext.Provider value={{
            device, token, deviceReady,
            makeCall, finishCall,
            currentCall, currentClient, currentNumber, currentCallTime
        }}>
            {children}
        </TwilioContext.Provider>
    );
});
