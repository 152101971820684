import React, {useState, Fragment, useRef} from "react";
import {Link} from 'react-router-dom';

// @mui components
import {IconButton, Icon, Popover as Actions, CircularProgress} from "@mui/material";

// Components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

// Images
import NoImageGreen from "assets/images/icons/no_image_green.png";
import attachment from "assets/images/icons/attachment.svg";
import uploadImage from "assets/images/icons/images.svg";
import send from "assets/images/icons/send.svg";
import unread from "assets/images/icons/unread.svg";
import pin from "assets/images/icons/pin.svg";
import deleteIcon from "assets/images/icons/delete.png";

// Utils
import {useStores} from "models";
import pxToRem from "assets/theme/functions/pxToRem";
import {useIsMobile} from "services/hooks";
import {showMessage} from "../../services/helpers";

export const Chat =
  ({
     pubnub,
     getChatsInformation,
     selectedChannel,
     messages,
     anchorElMsg,
     setAnchorElMsg,
     messageValue,
     setMessageValue,
     pinnedMessageRef,
     setOpenImagePreview,
     pinnedMessage,
     setPinnedMessage,
     selectedMessage,
     lastMessageRef,
     setSelectUsersModal,
     selectedUser,
     selectedImageMessage,
     setShowDetail,
     handleChatShowInfoClick,
     deleteMessage,
   }) => {

    const {loginStore} = useStores();
    const isMobile = useIsMobile();
    const [anchorElAttachment, setAnchorElAttachment] = useState(null);
    const openMsg = Boolean(anchorElMsg);
    const openAttachment = Boolean(anchorElAttachment);
    const inputFileRef = useRef(null);
    const fileValueHandler = useRef(null);
    const [loading, setLoading] = useState(false);

    const scrollToPinnedMessage = () => {
      if (!pinnedMessageRef.current) return
      pinnedMessageRef.current.scrollIntoView({block: 'start'})
    }

    const handleClickSendCustomer = () => {
      setSelectUsersModal(prev => ({open: !prev.open, type: 'clients'}));
      setAnchorElAttachment(null);
    }

    const handleClickMessage = (e, msg) => {
      selectedMessage.current = msg;

      // if the msg is a file, we'll do different actions
      if (msg.file) {
        if (msg.file.type === 'image') {

          // when we deleted the message, we should not be able to open the open image preview modal
          if (msg.file.src) {
            selectedImageMessage.current = e.currentTarget;
            return setOpenImagePreview(true)
          }
          return
        }
      }
      setAnchorElMsg(e.currentTarget);
    }

    const onChangeInput = (e) => {
      const value = e.target.value;

      // if there is a ref of some file, and the value of the input
      // it's different from the name of the file ref, the value of the input
      // will be empty
      if (fileValueHandler.current) {
        if (value !== fileValueHandler.current?.name) {
          setMessageValue('');
          fileValueHandler.current = null;
          inputFileRef.current.value = null;
          return
        }
      }
      setMessageValue(value)
    }

    const onChangeFile = (e) => {
      setMessageValue(e.target.files[0]?.name);
      fileValueHandler.current = e.target.files[0];
      setAnchorElAttachment(null);
    }

    const sendMessage = msg => {

      setLoading(true);
      const isUrlHandler = () => {
        try {
          new URL(msg);
          return true;
        } catch (error) {
          return false
        }
      };

      const isLink = isUrlHandler();

      if ((typeof msg === 'string' && msg?.length > 0) || isLink) {
        const message = {
          text: isLink ? null : msg,
          url: isLink ? {source: new URL(msg).pathname, label: `${selectedUser?.name} profile`} : null,
          from: {
            id: loginStore.id,
            name: loginStore.fullName,
          },
          channel: selectedChannel.name,
          pinned: false,
        };

        return pubnub.publish(
          {channel: selectedChannel.name, message},
          (status) => {
            if (status.statusCode === 200) {
              setMessageValue('');
              return setLoading(false);
            }
            showMessage('Something went wrong with the message')
          })
      }

      const file = new File([msg], `from_${loginStore.id}--${msg.name}`, {
        type: msg.type,
      });

      pubnub.sendFile({
        channel: selectedChannel.name,
        file,
        message: {
          from: {
            id: loginStore.id,
            name: loginStore.fullName,
          },
        }
      }, () => {
        setMessageValue('');
        setAnchorElAttachment(null);
        fileValueHandler.current = null;
        if (inputFileRef.current) {
          inputFileRef.current.value = null;
        }
        setLoading(false);
        return getChatsInformation();
      })

    }

    const downloadFile = (url) => {
      setAnchorElMsg(null);
      fetch(url)
        .then(response => response.blob())
        .then(blob => {
          const blobUrl = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = blobUrl;
          link.download = url.substring(url.lastIndexOf('/') + 1).split('?uuid')[0];
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
    }

    const onPinMessage = (msg) => {

      let pinnedCurrentValue = false;
      if (msg?.pinned) {
        pinnedCurrentValue = true
      }

      // we send the same information as the send message method sends,
      // and the opposite of the current value of pinned
      const updatedMessage = {
        text: msg.message,
        from: {
          id: msg?.from,
          name: msg?.from_username
        },
        pinned: !pinnedCurrentValue,
      };

      pubnub.publish({
        channel: selectedChannel.name,
        message: updatedMessage,
      });
      deleteMessage(msg, false);
      setPinnedMessage({...msg, pinned: !pinnedCurrentValue});
    }

    const onMarkAsUnreadMessage = async (msg) => {
      const updatedMessage = {
        from: {
          id: msg?.from,
          name: msg?.from_username
        },
        text: 'updated msg',
      }

      pubnub.publish({
        channel: selectedChannel.name,
        message: updatedMessage,
      })

      deleteMessage(msg, false);
    }

    const handleGoBack = () => {
      setShowDetail(false);
    }

    const inputImageButton = (
      <>
        <IconButton
          className='base-icon-button icon-button-green-bold'
          onClick={(e) => setAnchorElAttachment(e.currentTarget)}
        >
          <img src={attachment} alt='attachment icon'/>
        </IconButton>

        <input
          type='file'
          accept="image/*"
          className='hide-input'
          id='image'
          ref={inputFileRef}
          onChange={(e) => onChangeFile(e)}
        />
      </>
    );

    const inputFileButton = (
      <>
        <input
          type='file'
          accept="image/*"
          className='hide-input'
          id='image'
          ref={inputFileRef}
          onChange={(e) => onChangeFile(e)}
        />

        <MDBox
          component='label'
          htmlFor='image'
          className='base-icon-button icon-button-green-light'
        >
          <img src={uploadImage} alt='upload icon'/>
        </MDBox>
      </>
    );


    const inputTextMessage = (
      <MDBox
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
        }}
      >
        <MDInput
          type='text'
          placeholder='Type message...'
          onChange={(e) => onChangeInput(e)}
          value={messageValue}
          onKeyPress={(e) => (e.key === 'Enter' && messageValue.length > 0) ? sendMessage(messageValue) : ''}
          sx={{flex: 1}}
        />
      </MDBox>
    );

    const sendButton = (
      <IconButton
        className='base-icon-button icon-button-green-light'
        onClick={() => sendMessage(fileValueHandler.current ? fileValueHandler.current : messageValue)}
        disabled={loading}
      >
        {loading
          ? <CircularProgress size={'1.5rem'} sx={{'.MuiCircularProgress-svg': {color: 'white !important'}}}/>
          : <img src={send} alt='send icon'/>
        }
      </IconButton>
    );

    return (
      <MDBox
        className={"message-detail-box-container"}
        sx={{
          width: {xs: '100%', sm: '65%'},
          borderRadius: {xs: '16px', sm: '0 16px 16px 0'},
          height: {xs: '46rem', sm: '42rem'}
        }}
      >
        {/* USER BOX */}
        <MDBox
          alignItems='center'
          display='flex'
          justifyContent='space-between'
          style={{borderBottom: '0.5px solid #C6C9CE', height: pxToRem(81), cursor: 'pointer'}}
        >
          {isMobile && (
            <MDBox ml={1} display='flex' alignItems='center' onClick={handleGoBack}>
              <Icon>arrow_back</Icon>
            </MDBox>
          )}
          <MDBox className={"message-box"} onClick={handleChatShowInfoClick}>
            <img src={NoImageGreen} className={"message-user-image"} alt={'user_image'}/>
            <MDBox flex={1} ml={3} display='flex' gap={pxToRem(5)}>
              <MDTypography fontWeight={'400'} color={'black'} variant="h5" fontSize={pxToRem(16)}>
                {selectedChannel.customName}
              </MDTypography>
            </MDBox>
            <MDBox/>
          </MDBox>
          <MDBox mr={3} display='flex' alignItems='center'>
            <Icon onClick={scrollToPinnedMessage} color={pinnedMessage ? 'success' : ''}>push_pin</Icon>
          </MDBox>
        </MDBox>

        {/* MESSAGES CONTAINER */}
        <MDBox className={"messages-list-container"} position='relative'>
          {messages?.[selectedChannel.name]?.map((message, key) => (
              <Fragment key={key}>
                {message?.date_split &&
                  <MDBox mt={1} display='flex' justifyContent='center'>
                    <MDTypography variant="caption">{message.date_split}</MDTypography>
                  </MDBox>
                }
                <MDBox
                  style={{
                    display: 'flex',
                    margin: 10,
                    marginBottom: (key === messages?.[selectedChannel.name].length - 1) && 82,
                    marginLeft: (message.from !== loginStore.id) && 30,
                    marginRight: (message.from === loginStore.id) && 15,
                    justifyContent: message.from === loginStore.id ? 'flex-end' : 'flex-start',
                  }}
                >
                  <MDBox
                    className={'message-from-container'}
                    style={{
                      display: 'block',
                      background: message.from === loginStore.id
                        ? message.is_deleted
                          ? '#FFF5F3'
                          : '#BFE3CD'
                        : '#F4F6F5',
                      lineBreak: 'anywhere',
                    }}
                    onClick={(e) => handleClickMessage(e, message)}
                    ref={message?.pinned ? pinnedMessageRef : null}
                  >
                    {message?.file
                      ? message.file?.type === 'image'
                        ? message.file?.src
                          ? <MDBox
                            alt={message?.file?.name}
                            component='img'
                            cursor='pointer'
                            height={pxToRem(110)}
                            src={message?.file?.src}
                            title={message?.file?.name}
                            width={pxToRem(110)}
                          />
                          : <MDTypography
                            variant="h6"
                            fontWeight={'regular'}
                            fontSize={pxToRem(14)}
                          >
                            You deleted this message
                          </MDTypography>
                        : (
                          <MDTypography
                            color='primary'
                            variant="h6"
                            fontSize={pxToRem(14)}
                            sx={{cursor: 'pointer', textDecoration: 'underline'}}
                            onClick={() => downloadFile(message.file?.src)}
                          >
                            Filename: {message.file?.name}
                          </MDTypography>
                        )
                      : message?.url
                        ? (
                          <Link to={message.url.source} target='_blank'>
                            <MDTypography
                              color='primary'
                              variant="h6"
                              fontWeight='regular'
                              fontSize={pxToRem(14)}
                              sx={{cursor: 'pointer'}}
                            >
                              {message.url.label}
                            </MDTypography>
                          </Link>
                        )
                        : (
                          <MDTypography
                            variant="h6"
                            fontWeight={'regular'}
                            fontSize={pxToRem(14)}
                          >
                            {message.message}
                          </MDTypography>
                        )
                    }
                    <MDTypography
                      style={{textAlign: message.from !== loginStore.id ? 'end' : 'start'}}
                      variant="h6"
                      fontWeight={'light'}
                      fontSize={pxToRem(11)}
                    >
                      {message.time} {selectedChannel.participants.length > 2 && `- ${message.from_username}`}
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </Fragment>
            )
          )}
          <div ref={lastMessageRef}/>
        </MDBox>


        {/* MESSAGE INPUT */}
        {isMobile
          ? (
            <MDBox
              height={pxToRem(100)} display='flex' flexDirection='column' gap={pxToRem(10)}
              p={`${pxToRem(36)} ${pxToRem(30)}`}
              width={'100%'} sx={{borderTop: '1px solid #C6C9CE'}}
            >
              <MDBox display='flex' justifyContent='space-evenly'>
                {inputImageButton}
                {inputFileButton}
                {sendButton}
              </MDBox>
              {inputTextMessage}
            </MDBox>
          )
          : (
            <MDBox
              sx={{
                alignItems: 'center',
                borderTop: '1px solid #C6C9CE',
                display: 'flex',
                gap: pxToRem(30),
                height: pxToRem(100),
                padding: `${pxToRem(36)} ${pxToRem(30)}`,
                width: '100%',
              }}
            >
              <MDBox
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  gap: pxToRem(16),
                  height: '100%',
                }}
              >
                {inputImageButton}
                {inputFileButton}
              </MDBox>
              {inputTextMessage}
              {sendButton}
            </MDBox>
          )
        }
        {/* MESSAGE INPUT */}

        {/*SEND ATTACHMENT OPTIONS */}
        <Actions
          open={openAttachment}
          anchorEl={anchorElAttachment}
          onClose={() => setAnchorElAttachment(null)}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          PaperProps={{
            style: {
              background: 'none',
              boxShadow: 'none',
              marginLeft: '80px'
            },
          }}
        >
          <MDBox className='base-popover popover-attachment'>
            <input
              type='file'
              accept=".doc,.docx,.xml,.pdf,.txt"
              className='hide-input'
              id='attachment'
              ref={inputFileRef}
              onChange={(e) => onChangeFile(e)}
            />
            <MDBox
              component='label'
              htmlFor='attachment'
              className='base-button button-green'
            >
              <Icon fontSize="medium">article</Icon>
              File
            </MDBox>
            <MDBox
              className='base-button button-green'
              onClick={handleClickSendCustomer}
            >
              <Icon fontSize="medium">person</Icon>
              Customer
            </MDBox>
          </MDBox>
        </Actions>

        {/* MESSAGE ACTIONS */}
        <Actions
          open={openMsg}
          onClose={() => setAnchorElMsg(null)}
          anchorEl={anchorElMsg}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          PaperProps={{
            style: {
              background: 'none',
              boxShadow: 'none',
              marginTop: '25px',
            },
          }}
        >
          <MDBox className='base-popover popover-content'>

            {selectedMessage.current?.from !== loginStore.id && (
              <MDBox
                className='base-button button-green'
                onClick={() => onMarkAsUnreadMessage(selectedMessage.current)}
              >
                <img src={unread} alt='unread icon'/>
                Mark as unread
              </MDBox>
            )}
            <MDBox
              className='base-button button-green'
              onClick={() => onPinMessage(selectedMessage.current)}
            >
              <img src={pin} alt='pin icon'/>
              {selectedMessage.current?.timetoken !== pinnedMessage?.timetoken ? 'Pin' : 'UnPin'}
            </MDBox>
            {selectedMessage.current?.from === loginStore.id && (
              <MDBox
                className='base-button button-delete'
                onClick={() => deleteMessage(selectedMessage.current)}
              >
                <img src={deleteIcon} alt='delete icon'/>
                Delete
              </MDBox>
            )}
          </MDBox>
        </Actions>
      </MDBox>
    )
  }