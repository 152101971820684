import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { Link } from 'react-router-dom';
import "./tables.css";
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import Box from '@mui/material/Box';

const SimpleTable = ({ data, emptyLabelText = 'No data' }) => {
  
  const [Headers, setHeaders] = React.useState([])
  const [Rows, setRows] = React.useState([])

  const [ShortAccessor, setShortAccessor] = React.useState('')
  const [ShortOrder, setShortOrder] = React.useState('desc')

  React.useEffect(() => {
    if (Array.isArray(data?.columns)) {
      setShortAccessor(data?.columns?.[0]?.accessor)
      setHeaders([...data?.columns])
    }
    if (Array.isArray(data?.rows)) setRows([...data?.rows])
  }, [data]);

  const shortData = (d, accessor, isAsc) => {
    let data = [...d]
    data = data.sort((a, b) => {
      let valueA = a[accessor]
      let valueB = b[accessor]
      if (React.isValidElement(a[accessor])) {
        valueA = a[accessor]?.props?.children
        valueB = b[accessor]?.props?.children
      }
      if (isAsc) {
        if (valueA < valueB) return 1
        else if ((valueB < valueA)) return -1
        else return 0
      } else {
        if (valueA > valueB) return 1
        else if ((valueB > valueA)) return -1
        else return 0
      }
    })
    return data
}

const updateOrder = (accessor) => {
  if (accessor === ShortAccessor) {
    const isAsc = ShortOrder === 'asc'
    setShortOrder(isAsc ? 'desc' : 'asc')
      const dataShorted = shortData(Rows, accessor, isAsc)
      setRows(dataShorted)
    } else {
      setShortAccessor(accessor)
      setShortOrder('desc')
      const dataShorted = shortData(Rows, accessor, false)
      setRows(dataShorted)
    }
  }

  return (
    <TableContainer>
      <Table>
        <TableBody>
          <TableRow className="t_header_tr">
            {Headers.map((k, key) => (
              <TableCell
                key={`${key}_label_${k}`}
                className="t_header"
                sortDirection={ShortOrder}
              >
                <TableSortLabel
                  active={k.accessor === ShortAccessor}
                  direction={ShortOrder}
                  onClick={() => updateOrder(k.accessor)}
                >
                  {k.Header}
                  {ShortAccessor === ShortAccessor ? (
                    <Box component="span" sx={visuallyHidden}>
                      {ShortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
          {(!Rows || !Rows[0])
            ? <TableRow className="t_body_tr">
              <TableCell>{emptyLabelText}</TableCell>
            </TableRow>
            : Rows.map((row, i) => (
              <TableRow
                key={i}
                className="t_body_tr_default"
              >
                {Headers.map((k, ii) => {
                  return (
                    <TableCell key={`${i}_${ii}`}>
                      <div className='base-item normal-item' style={{ paddingLeft: data?.spacing || '0.5rem' }} >
                        {row[k.accessor]}
                      </div>
                    </TableCell>
                  )
                })}
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}


export default SimpleTable