import * as React from 'react';
import {Link as RouteLink} from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Chip from '@mui/material/Chip';
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import { navbarIconButton } from "examples/Navbars/DashboardNavbar/styles";
import MDButton from "../../../components/MDButton";
import MDTypography from 'components/MDTypography';
import "./tables.css";
    //icons
    import PrintIcon from "assets/images/icons/print.png";
    import DownloadIcon from "assets/images/icons/download.png";
    import EyeIcon from "assets/images/icons/eye.png";
    

export const Tables = (props) => {
    const {body, header, total, view, url} = props;
    const ActionButtons = (actions) => {
      if (actions === 'project_incomes'){
        return (<>
            <MDButton color="green" style={{ width: 48, height: 48 }}><img src={PrintIcon} /></MDButton>
            <MDButton color="green" style={{ width: 48, height: 48, marginLeft: 16 }}><img src={DownloadIcon} /></MDButton>
        </>);
      }
      if (actions === 'followup_emails'){
        return (<>
            <MDButton color="green" style={{ width: 20, height: 20 }} size="small" circular>1</MDButton>
            <MDButton color="green" style={{ width: 48, height: 48, marginLeft: 16 }}><img src={EyeIcon} /></MDButton>
        </>);
      }
      if (actions === 'client_contact_info'){
        return (<>
            <MDButton color="green" iconOnly={true} style={{ marginLeft: 16 }}><img src={PrintIcon} /></MDButton>
            <MDButton color="green" iconOnly={true} style={{ marginLeft: 16 }}><img src={DownloadIcon} /></MDButton>
        </>);
      }
    }


  return (
    <TableContainer>
      <Table>
        <TableBody>
          <TableRow className="t_header_tr">
            {header.map((row,i) => (
              <TableCell align={row.align} className="t_header">{row.title}</TableCell>
            ))}
          </TableRow>
          {body.map((row,i) => (
            <TableRow
              key={i}
              className="t_body_tr"
            >
              <TableCell align={row.align} className="names" component={RouteLink} to={`/admin/${url}/details`}>{row.name}</TableCell>
              <TableCell align={row.align}>{row.address}</TableCell>
              <TableCell align={row.align}>{row.phone}</TableCell>
              <TableCell align={row.align}>{row.email}</TableCell>
              <TableCell align={row.align}>{row.industry}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
