import React, { useState } from 'react';
import { Link } from "react-router-dom";
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MDButton from "components/MDButton";
import { useNavigate } from "react-router-dom";
import { ROUTES } from 'constants';


export const AddButton = ({ style = {}, label }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [active, setActive] = useState(false);
  const navigate = useNavigate();

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setActive(true);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setActive(false);
  };

  return (
    <>


      <MDButton
        style={{ width: 160, marginRight: 27, height: 48 }}
        color="green"
        useBrandingColor={'primary'}
        onClick={handleClick}
      >{label || 'Add New +'}</MDButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        className="menu-basics"
        onClose={handleClose}
        MenuListProps={{ 'aria-labelledby': 'basic-button' }}
      >
        <MenuItem onClick={handleClose}>
          <MDButton
            useBrandingColor={'primary'}
            color="green"
            className={'btn-import-sub'}
            onClick={() => navigate(ROUTES.NEW_REQUESTS)}
          > REQUEST
          </MDButton>

        </MenuItem>
        <MenuItem onClick={handleClose}>
          <MDButton
            useBrandingColor={'primary'}
            color="green"
            className={'btn-import-sub'}
            onClick={() => navigate(ROUTES.NEW_QUOTES)}
          > QUOTE
          </MDButton>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <MDButton
            useBrandingColor={'primary'}
            color="green"
            className={'btn-import-sub'}
            onClick={() => navigate(ROUTES.NEW_INVOICE)}
          > INVOICE
          </MDButton>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <MDButton
            useBrandingColor={'primary'}
            color="green"
            className={'btn-import-sub'}
            onClick={() => navigate(ROUTES.NEW_TASK())}
          > TASK
          </MDButton>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <MDButton
            useBrandingColor={'primary'}
            color="green"
            className={'btn-import-sub'}
            onClick={() => navigate(ROUTES.NEW_EVENT())}
          > EVENT
          </MDButton>
        </MenuItem>
      </Menu>
    </>
  );
}
