import { useEffect, useState } from 'react'
import moment from 'moment'
import MDBox from "components/MDBox";
import MDTypography from "../../../components/MDTypography";

export default function Weeks() {
    const [dateArr, setDateArr] = useState([]);
    const [dates, setDates] = useState([]);
    const [loading, setLoading] = useState(false);

    const setdates = () => {
      var dates = [];
      let prevDate = moment().subtract(4, 'days');//15 days back date from today(This is the from date)
      let nextDate = moment().add(5, 'days');//Date after 15 days from today (This is the end date)
      let todayDate = moment();//Date after 15 days from today (This is the end date)

      //extracting date from objects in MM-DD-YYYY format
      prevDate = moment(prevDate._d).format('MM-DD-YYYY');
      nextDate = moment(nextDate._d).format('MM-DD-YYYY');
      todayDate = moment(todayDate).format('MM-DD-YYYY');

      //creating JS date objects
      var start = new Date(prevDate);
      var today = new Date(todayDate);
      var end = new Date(nextDate);

      //Logic for getting rest of the dates between two dates("FromDate" to "EndDate")
      while(start < end){
        let istoday = false;
        if(moment().isSame(moment(start), 'd')){
          istoday = true;
        }
        dates.push({'date': moment(start).format('DD'), 'day': moment(start).format('ddd'), 'today': istoday});
        var newDate = start.setDate(start.getDate() + 1);
        start = new Date(newDate);
      }
      setDateArr(dates);
  }
  useEffect(()=>{
    setdates();
  },[]);

    return (
      <>
      <div style={{display: "flex", margin:'80px 0px 0px 50px', flexDirection:"row", justifyContent:"flex-end"}}>
        {dateArr && dateArr.map((d,i) => (
          <MDBox
            key={i}
            style={{
              // height:"60px",
              display:"flex",
              flexDirection:"column",
              alignItems:"center",
              justifyContent:"center",
              boxShadow:"none",
              width:"10%",
              borderRadius:"16px",
              paddingTop:"8px",
              paddingBottom:"8px",
              color: d.today?"var(--dark-green)":"var(--light-black)",
              backgroundColor: d.today?"var(--light-green)":"var(--very-light-grey)"
            }}
            // bgColor={d.today ? "info" : ""}
            variant="gradient"
            // opacity={1}
          >
            <MDTypography
              fontWeight={"bold"}
              style={{
                fontSize:24,
                color: d.today?"var(--dark-green)":"var(--grey)",
              }}
            >
              {d.date}
            </MDTypography>
            <MDTypography fontWeight={d.today?"bold":"regular"} style={{fontSize:18, color: d.today?"var(--dark-green)":"var(--light-black)"}}>
              {d.day}
            </MDTypography>
          </MDBox>

        ))}
        </div>
      </>
  )
}
