import React, { useEffect, useRef, useState } from "react";
import { Grid } from '@mui/material';
import AdminLayout from "../../../components/AdminLayout";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "./style.css";
import MDTypography from "../../../components/MDTypography";
import MDButton from "../../../components/MDButton";
import MDBox from "../../../components/MDBox";
import { runInAction } from "mobx";
import {
  checkDuplicatesInArray,
  generateOptionsFromString,
  getFieldInitialValue,
  showMessage,
  useApi
} from "services/helpers";

//custom fields
import { CustomFieldModal } from "components/CustomFieldModal"
import { FieldArray, Form, Formik } from "formik";
import { clientInitialValues, clientValidationSchema, } from "./form";
import FormikInput from "../../../components/FormikInput";
import { observer } from "mobx-react-lite";
import { ROUTES } from "../../../constants";
import {useStores} from "models";

import { debounce } from '@mui/material/utils';
import MDAutocomplete from "../../../components/MDAutocomplete";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import TextField from '@mui/material/TextField';
import parse from 'autosuggest-highlight/parse';
// 


// places api
const GOOGLE_MAPS_API_KEY = 'AIzaSyC3aviU6KHXAjoSnxcw6qbOhjnFctbxPkE';

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };
//

function CreateClient() {
  const navigate = useNavigate();
  const api = useApi()
  const formikRef = useRef()
  const arrayHelpersRef = useRef()
  const arrayHelpersRefEmail = useRef()
  const { id } = useParams();
  const location = useLocation();
  const rootStore = useStores()
  const { loginStore } = rootStore;

  const getBackgoudColor = (backgroundColor) => {
    try {
      return JSON.parse(loginStore.branding_data?.[backgroundColor])?.hex
    } catch (error) {
      return ''
    }
  }
 

  const [Client, setClient] = useState(null);
  const [customFields, setCustomFields] = useState([]);
  const [customFieldsInitialValues, setCustomFieldsInitialValues] = useState([]);
  const [CustomFieldModalOpen, setCustomFieldModalOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);

  // places  api
  const [Address, setAddress] = useState(null);
  const [City, setCity] = useState(null);
  const [Country, setCountry] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const loaded = useRef(false);

  const handlePlaceSelect = (place) => {
    const { structured_formatting, terms } = place;
    const city = structured_formatting.main_text;
    const country = structured_formatting.secondary_text;

    setCity(city)
    setCountry(country)
  };
  //

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
        document.querySelector('head'),
        'google-maps',
      );
    }

    loaded.current = true;
  }

  const fetch = React.useMemo(
    () =>
      debounce((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 400),
    [],
  );

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
      
    }
    if (!autocompleteService.current) return undefined;
    
    if (inputValue === '') {
      setOptions(Address ? [Address] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (Address) newOptions = [Address];

        if (results) newOptions = [...newOptions, ...results];
      
        setOptions(newOptions);
      }
    });

    return () => active = false;

  }, [Address, inputValue, fetch]);
  //

  const getClientDetail = (id) => {
    setLoading(true)
    api.getClientEditDetail(id).then((result) => {
      if (result.kind === "ok") {
        setClient(result.data)
        setAddress(result?.data?.address_1)
        setCity(result?.data?.city)
        setCountry(result?.data?.country)
      } else {
        showMessage()
      }
    }).finally(() => setLoading(false))
  }

  const getCustomFields = () => {
    setLoading(true)
    api.getCustomFields({ type: 'CLIENT', object_id: id, }).then((result) => {
      if (result.kind === "ok") {

        const { data } = result
        const { results } = data
        if (results.length > 0) {
          const fields = results[0]['fields']
          setCustomFields(fields)
          setCustomFieldsInitialValues(getFieldInitialValue(fields))
        }
      }
    }).finally(() => setLoading(false))
  }

  const saveClient = (values) => {
    setLoading(true)
    api.createClient({...values, city: City, country: Country, address_1: Address?.description || ''}).then((result) => {
      if (result.kind === "ok") {
        runInAction(() => {
          showMessage('Client created', 'success')
          refresh ? window.location.reload() : navigate(ROUTES.CLIENTS)
        })
      } else {
        if (result.kind === "bad-data") {
          showMessage('validation errors found')
          formikRef.current.setErrors(result.errors);
        } else {
          showMessage()
        }
      }
    }).finally(() => setLoading(false))
  }

  const updateClient = (values) => {
    setLoading(true)
    api.updateClient({...values, city: City, country: Country, address_1: Address?.description || ''}).then((result) => {
      if (result.kind === "ok") {
        showMessage('Client updated', 'success')
        refresh ? window.location.reload() : navigate(ROUTES.CLIENTS)
      } else {
        if (result.kind === "bad-data") {
          showMessage('validation errors found')
          formikRef.current.setErrors(result.errors);
        } else {
          showMessage()
        }
      }
    }).finally(() => setLoading(false))
  }

  const onCloseCustomModal = () => {
    setCustomFieldModalOpen(false)
    getCustomFields()
  }

  const handleAddCustomField = () => setCustomFieldModalOpen(true);

  useEffect(() => {
    getCustomFields()
    if (id) getClientDetail(id)
    else setClient(null)
  }, [location])

  const renderCustomField = (index, field, errors, setFieldValue) => {
    const optionsForField = generateOptionsFromString(field['field_options'])
    const value = field['default_value'].value
    return (
      <React.Fragment key={index}>
        <FormikInput
          useBrandingColor={'secondary'}
          name={field['field_name']}
          label={field['field_label']}
          type={field['field_type_input']}
          setFieldValue={setFieldValue}
          options={optionsForField}
          labelFieldName={'label'}
          errors={errors}
          initialValue={value}
          item
          xs={12}
          md={6}
        />
      </React.Fragment>
    )
  }

  const renderAddPhoneNumber = (index, setFieldValue, errors, arrayHelpers, values) => {

    let error = {}

    const valuesPhoneNumberArray = values['phone_numbers']
    const hasDuplicates = checkDuplicatesInArray(valuesPhoneNumberArray, 'phone_number')

    if (hasDuplicates === true && values['phone_numbers'][index]['phone_number'] !== '') {
      error[`phone_numbers[${index}].phone_number`] = 'Duplicated phone number'
    } else {
      error = {}
    }

    if (errors['phone_numbers'] !== undefined && errors['phone_numbers'][index] !== undefined) {
      const rawError = errors['phone_numbers'][index]
      error[`phone_numbers[${index}].phone_number`] = rawError['phone_number']
    }

    return (
      <Grid item xs={12} key={index}>
        <FormikInput
          useBrandingColor={'secondary'}
          name={`phone_numbers[${index}].phone_number`}
          label={'Phone number'}
          type={'phone_input'}
          placeholder={'input phone number'}
          setFieldValue={setFieldValue}
          errors={error}
        />
        {values.phone_numbers.length > 1 && <MDButton
          variant="contained"
          className="mini-button error"
          size="small"
          style={{ float: 'right' }}
          onClick={() => arrayHelpers.remove(index)}
        >Delete</MDButton>}
      </Grid>
    )
  }

  const renderAddEmail = (index, setFieldValue, errors, arrayHelpers, values) => {

    let error = {}

    const valuesEmailArray = values['emails']
    const hasDuplicates = checkDuplicatesInArray(valuesEmailArray, 'email')

    if (hasDuplicates === true && values['emails'][index]['email'] !== '') {
      error[`emails[${index}].email`] = 'Duplicated email'
    } else {
      error = {}
    }

    if (errors['emails'] !== undefined && errors['emails'][index] !== undefined) {
      const rawError = errors['emails'][index]
      error[`emails[${index}].email`] = rawError['email']
    }

    return (
      <Grid item xs={12} key={index}>
        <FormikInput
          useBrandingColor={'secondary'}
          name={`emails[${index}].email`}
          label={'Email'}
          type={'email'}
          placeholder={'input email'}
          setFieldValue={setFieldValue}
          errors={error}
          helperInput={
            index === 0 && <MDTypography variant={'text'} color={'error'} sx={{ fontSize: '12px' }}>Email for user login</MDTypography>
          }
        />
        {values.emails.length > 1 && <MDButton
          variant="contained"
          className="mini-button error"
          size="small"
          style={{ float: 'right' }}
          onClick={() => arrayHelpers.remove(index)}
        >Delete</MDButton>}
      </Grid>
    )
  }

  return (
    <AdminLayout title={id === undefined ? "New Client" : "Edit Client"} loading={loading}>
      <Grid container alignItems="top" paddingTop={0} marginTop={0}>
        <Grid item ml={5} xs={12} lg={9} md={9} className="leftContainer">
          <MDTypography variant="h2" useBrandingColor={'tertiary'} fontWeight="medium" mb={1}>
            {id === undefined ? 'Create New Client' : 'Edit Client'}
          </MDTypography>
        </Grid>
      </Grid>

      <Formik
        innerRef={formikRef}
        initialValues={Object.assign({}, clientInitialValues(Client), customFieldsInitialValues)}
        validationSchema={clientValidationSchema}
        validateOnBlur={false}
        validateOnChange={false}
        enableReinitialize
        onSubmit={values => {
          const phoneNumbers = values.phone_numbers
          const emails = values.emails

          const phoneNumbersHasDuplicates = checkDuplicatesInArray(phoneNumbers, 'phone_number')
          const emailsHasDuplicates = checkDuplicatesInArray(emails, 'email')
          if (!phoneNumbersHasDuplicates && !emailsHasDuplicates) {
            const refresh = false
            const valuesToSend = { ...values, refresh }
            id === undefined ? saveClient(valuesToSend) : updateClient(valuesToSend)
          }
        }}
      >
        {({ errors, setFieldValue, values, handleSubmit }) => {
          return (
            <Form>
              <Grid container spacing={{ xs: 2, md: 3, lg: 3 }} pb={3} className="task_container" sx={{ alignItems: 'flex-start' }}>
                <FormikInput
                  useBrandingColor={'secondary'}
                  name={'first_name'}
                  label={'First Name'}
                  placeholder={'input first name'}
                  errors={errors}
                  item
                  xs={12}
                  md={6}
                />
                <FormikInput
                  useBrandingColor={'secondary'}
                  name={'last_name'}
                  label={'Last Name'}
                  placeholder={'input last name'}
                  errors={errors}
                  item
                  xs={12}
                  md={6}
                />
                <FormikInput
                  useBrandingColor={'secondary'}
                  name={'company_name'}
                  label={'Company Name'}
                  placeholder={'input company name'}
                  errors={errors}
                  item
                  xs={12}
                  md={6}
                />
                <Grid item xs={12} md={6} />
                <FormikInput
                  useBrandingColor={'secondary'}
                  name={'company_name_primary'}
                  label={'Use company name as the primary name'}
                  type={'boolean'}
                  setFieldValue={setFieldValue}
                  errors={errors}
                  item
                  xs={12}
                  md={6}
                />
                <Grid item xs={12} md={6} />
                <Grid item xs={12} md={6}>
                  <FieldArray
                    name="phone_numbers"
                    render={arrayHelpers => {
                      arrayHelpersRef.current = arrayHelpers;
                      return (
                        <Grid container>
                          {values.phone_numbers.map((phone_number, index) => renderAddPhoneNumber(index, setFieldValue, errors, arrayHelpers, values))}
                          <MDButton
                            color="green"
                            useBrandingColor={'primary'}
                            className="mini-button"
                            onClick={() => arrayHelpersRef?.current?.push({ phone_number: "" })}>Add phone +
                          </MDButton>
                        </Grid>
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FieldArray
                    name="emails"
                    render={arrayHelpers => {
                      arrayHelpersRefEmail.current = arrayHelpers;
                      return (
                        <Grid container>
                          {values.emails.map((email, index) => renderAddEmail(index, setFieldValue, errors, arrayHelpers, values))}
                          <MDButton
                            color="green"
                            useBrandingColor={'primary'}
                            className="mini-button"
                            onClick={() => arrayHelpersRefEmail?.current?.push({ email: "" })}>Add email +
                          </MDButton>
                        </Grid>
                      )
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <MDAutocomplete
                    options={options}
                    id="google-map-demo"
                    getOptionLabel={(option) => typeof option === 'string' ? option : option.description}
                    filterOptions={(x) => x}
                    autoComplete
                    includeInputInList
                    useBrandingColor={'secondary'}
                    fullWidth
                    filterSelectedOptions
                    value={Address}
                    style={{
                      backgroundColor: getBackgoudColor('secondary'),
                      borderStyle: 'none'
                    }}
                    noOptionsText="No locations"
                    onChange={(event, newValue) => {
                      setOptions(newValue ? [newValue, ...options] : options);
                      handlePlaceSelect(newValue)
                      setAddress(newValue);
                    }}
                    onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
                    renderInput={(params) => <TextField {...params} label="Add a location" fullWidth />}
                    renderOption={(props, option) => {
                      const matches = option.structured_formatting.main_text_matched_substrings || [];

                      const parts = parse(
                        option.structured_formatting.main_text,
                        matches.map((match) => [match.offset, match.offset + match.length]),
                      );

                      return (
                        <li {...props}>
                          <Grid container alignItems="center">
                            <Grid item sx={{ display: 'flex', width: 44 }}>
                              <LocationOnIcon sx={{ color: 'text.secondary' }} />
                            </Grid>
                            <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                              {parts.map((part, index) => (
                                <MDBox
                                  key={index}
                                  component="span"
                                  sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
                                >
                                  {part.text}
                                </MDBox>
                              ))}
                              <MDTypography variant="body2" color="text.secondary">
                                {option.structured_formatting.secondary_text}
                              </MDTypography>
                            </Grid>
                          </Grid>
                        </li>
                      );
                    }}
                  />

                </Grid>

                <Grid mt={3} item xs={12}>
                  <MDTypography variant="h6" fontWeight="bold">Automated notifications</MDTypography>
                </Grid>
                <FormikInput
                  useBrandingColor={'secondary'}
                  name={'quote_follow_up'}
                  label={'Quote follow-up'}
                  type={'boolean'}
                  setFieldValue={setFieldValue}
                  errors={errors}
                  item
                  xs={12}
                  md={6}
                  helperInput={
                    <>
                      <MDTypography style={{ fontSize: 16 }}>Follow up on an outstanding quote.</MDTypography>
                      <MDTypography variant="h6" fontWeight="bold" color="green">Settings</MDTypography>
                    </>
                  }
                />
                <FormikInput
                  useBrandingColor={'secondary'}
                  name={'job_follow_up'}
                  label={'Job follow-up'}
                  type={'boolean'}
                  setFieldValue={setFieldValue}
                  errors={errors}
                  item
                  xs={12}
                  md={6}
                  helperInput={
                    <>
                      <MDTypography style={{ fontSize: 16 }}>Follow up when you close a job. </MDTypography>
                      <MDTypography variant="h6" fontWeight="bold" color="green">Settings</MDTypography>
                    </>
                  }
                />
                <FormikInput
                  useBrandingColor={'secondary'}
                  name={'appointment_reminders'}
                  label={'Appointment reminders'}
                  type={'boolean'}
                  setFieldValue={setFieldValue}
                  errors={errors}
                  item
                  xs={12}
                  md={6}
                  helperInput={
                    <>
                      <MDTypography style={{ fontSize: 16 }}>Remind your client of an upcoming assessment or
                        visit.</MDTypography>
                      <MDTypography variant="h6" fontWeight="bold" color="green">Settings</MDTypography>
                    </>
                  }
                />
                <FormikInput
                  useBrandingColor={'secondary'}
                  name={'invoice_follow_up'}
                  label={'Invoice follow-up'}
                  type={'boolean'}
                  setFieldValue={setFieldValue}
                  errors={errors}
                  item
                  xs={12}
                  md={6}
                  helperInput={
                    <>
                      <MDTypography style={{ fontSize: 16 }}>Follow up on an overdue invoice.</MDTypography>
                      <MDTypography variant="h6" fontWeight="bold" color="green">Settings</MDTypography>
                    </>
                  }
                />
                <Grid item xs={12} lg={12} md={12}>
                  <MDTypography variant="h6" fontWeight="bold">Additional client details</MDTypography>
                </Grid>
                <FormikInput
                  useBrandingColor={'secondary'}
                  name={'referred_by'}
                  label={'Referred by'}
                  placeholder={'input name'}
                  setFieldValue={setFieldValue}
                  errors={errors}
                  item
                  xs={12}
                  md={6}
                />
                <FormikInput
                  useBrandingColor={'secondary'}
                  name={'how_did_you_hear_about_us'}
                  label={'How did you hear about us?'}
                  placeholder={'input source'}
                  setFieldValue={setFieldValue}
                  errors={errors}
                  item
                  xs={12}
                  md={6}
                />
                {customFields.map((field, index) => {
                  return renderCustomField(index, field, errors, setFieldValue)
                })}

                <CustomFieldModal
                  modalOpen={CustomFieldModalOpen}
                  handleClose={onCloseCustomModal}
                />
                <Grid item xs={12} lg={12} md={12}>
                  <MDButton type={'button'} onClick={handleAddCustomField} useBrandingColor={'primary'} color="green" styel={{ float: 'right' }}>Add
                    Custom Field</MDButton>
                </Grid>
                <Grid item display={{ xs: 'block', lg: 'flex' }} xs={12} lg={12} md={12} mb={3}
                  justifyContent={'space-evenly'}>
                  <Grid item xs={12} lg={3} md={5} mb={{ xs: 2 }}>
                    <MDButton
                      useBrandingColor={'primary'}
                      color="green"
                      type="button"
                      variant="outlined"
                      fullWidth
                      onClick={() => navigate(ROUTES.CLIENTS)}
                    >
                      Cancel
                    </MDButton>
                  </Grid>
                  <Grid item xs={12} lg={3} md={5} mb={{ xs: 2 }}>
                    <MDButton
                      color="green"
                      useBrandingColor={'primary'}
                      type="button"
                      onClick={() => {
                        handleSubmit()
                        setFieldValue('refresh', true)
                        setRefresh(true)
                      }}
                      fullWidth
                    >
                      {id === undefined ? 'Save and Create Another' : 'Save and Keep Editing'}
                    </MDButton>
                  </Grid>
                  <Grid item xs={12} lg={3} md={5} mb={{ xs: 2 }}>
                    <MDButton
                      color="green"
                      useBrandingColor={'primary'}
                      type="button"
                      onClick={() => {
                        handleSubmit()
                        setFieldValue('refresh', false)
                        setRefresh(false)
                      }}
                      fullWidth
                    >
                      {id === undefined ? 'Create' : 'Save'}
                    </MDButton>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          )
        }}
      </Formik>
    </AdminLayout>
  )
}

export default observer(CreateClient)
