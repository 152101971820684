import { useEffect, useState } from "react";
import { Grid } from '@mui/material';
import ClientPanelLayout from "components/ClientPanelLayout";
import MDTypography from "components/MDTypography";
import StripeAddCard from 'components/StripeAddCard'
import StripeListCards from 'components/StripeListCards'
import { useApi, showMessage } from "services/helpers";

// stripe
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import "../styles.css"

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC);

export default function Wallet(props) {
  const api = useApi()

  //Stripe
  const [clientsecret, setClientSecret] = useState('')
  useEffect(() => {
    api.stripePaymentIntent().then((result) => {
      if (result.kind === "ok") setClientSecret(result.response?.clientSecret)
      else showMessage('There was an error getting the information from stripe')
    })
  }, [])
  const options = { clientSecret: clientsecret };

  return (

    <ClientPanelLayout title={"Wallet"} >
      <Grid container spacing={3}>
        <Grid item xs={3} lg={12} md={12} ml={2}>
          <MDTypography variant="h3" fontWeight="medium" mb={1} style={{ float: 'left' }}>
            Payment method
          </MDTypography>
        </Grid>
      </Grid>



      {/* Stripe - should only be called when the client secret is set */}
      <Grid item xs={12} lg={12} md={12}>
        {clientsecret &&
          <Elements stripe={stripePromise} options={options}>
            <Grid container spacing={3} padding={3} className="task_container">

              <StripeListCards>
                <MDTypography variant="h6" fontWeight="medium" mb={1}>
                  Your cards
                </MDTypography>
              </StripeListCards>

            </Grid>

            <Grid container spacing={3} padding={3} className="task_container">
              
              <StripeAddCard>
                <MDTypography variant="h6" fontWeight="medium" mb={1}>
                  Add card
                </MDTypography>
              </StripeAddCard>
            </Grid>

          </Elements>
        }

      </Grid>

    </ClientPanelLayout>
  )
}
