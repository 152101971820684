import { useEffect, useState, useRef } from "react";
import { Grid } from '@mui/material';
import AdminLayout from "components/AdminLayout";
import Icon from "@mui/material/Icon";
import { useNavigate, useParams } from "react-router-dom";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDSwitch from "components/MDSwitch";
import Checkbox from "@mui/material/Checkbox";
import { Form, Formik } from "formik";
import { clientInitialValues, clientValidationSchema, } from "./user_form";
import FormikInput from "components/FormikInput";
import { useStores } from "models";
import {showMessage, useApi} from "services/helpers";
import { ROUTES } from "constants";
import deleteIcon from 'assets/images/icons/delete_background.svg'
import default_img from 'assets/images/icons/default_img.svg'
import {useApiMessages} from "../../../services/api/api-messages";

const permissionFields = [
  [
    {
      name: 'Schedule',
      key: 'Schedule',
      permissions: [
        {
          key: 'View their own schedule',
          label: 'View their own schedule',
        },
        {
          key: `View & complete their own schedule`,
          label: `View & complete their own schedule`,
        },
        {
          key: 'Edit their own schedule',
          label: 'Edit their own schedule',
        },
        {
          key: `Edit everyone's schedule`,
          label: `Edit everyone's schedule`,
        },
        {
          key: `Edit & Delete everyone's schedule`,
          label: `Edit & Delete everyone's schedule`,
        },
      ]
    },
    {
      name: 'Time tracking & Timesheets',
      key: 'Time tracking & Timesheets',
      permissions: [
        {
          key: 'View & record their own',
          label: 'View & record their own',
        },
        {
          key: 'View, record, edit their own',
          label: 'View, record, edit their own',
        },
        {
          key: 'View, edit, delete all',
          label: 'View, edit, delete all',
        },
      ]
    },
    {
      name: 'Notes',
      key: 'Notes',
      permissions: [
        {
          key: 'View all notes',
          label: 'View all notes',
        },
        {
          key: 'View all notes',
          label: 'View all notes',
        },
        {
          key: 'View & edit all',
          label: 'View & edit all',
        },
        {
          key: 'View, edit & delete all',
          label: 'View, edit & delete all'
        },
      ]
    }
  ],
  [
    {
      name: 'Show pricing',
      key: 'Show pricing',
      permissions: []
    },
    {
      name: 'Clients & Properties',
      key: 'Clients & Properties',
      permissions: [
        {
          key: 'Include access toall client custom fields',
          label: 'Include access toall client custom fields',
        },
        {
          key: 'View client name & address only',
          label: 'View client name & address only',
        },
        {
          key: 'View full client & property info',
          label: 'View full client & property info',
        },
        {
          key: 'View & edit client & property info',
          label: 'View & edit client & property info',
        },
        {
          key: 'View, edit & delete client & property info',
          label: 'View, edit & delete client & property info',
        },
      ]
    },
    {
      name: 'Requests',
      key: 'Requests',
      permissions: [
        {
          key: 'View only',
          label: 'View only',
        },
        {
          key: 'View, create & edit',
          label: 'View, create & edit',
        },
        {
          key: 'View, create, edit & delete',
          label: 'View, create, edit & delete'
        },
      ]
    }
  ],
  [
    {
      name: 'Jobs',
      key: 'Jobs',
      permissions: [
        {
          key: 'View only',
          label: 'View only',
        },
        {
          key: 'View, create & edit',
          label: 'View, create & edit',
        },
        {
          key: 'View, create, edit & delete',
          label: 'View, create, edit & delete',
        },
      ]
    },
    {
      name: 'Quotes',
      key: 'Quotes',
      permissions: [
        {
          key: 'View only',
          label: 'View only',
        },
        {
          key: 'View, create & edit',
          label: 'View, create & edit',
        },
        {
          key: 'View, create, edit & delete',
          label: 'View, create, edit & delete'
        },
      ]
    },
    {
      name: 'Invoices',
      key: 'Invoices',
      permissions: [
        {
          key: 'View only',
          label: 'View only',
        },
        {
          key: 'View, create & edit',
          label: 'View, create & edit',
        },
        {
          key: 'View, create, edit & delete',
          label: 'View, create, edit & delete'
        },
      ]
    }
  ]
]

export default function CreateUser() {
  const navigate = useNavigate();
  const rootStore = useStores()
  const formikRef = useRef()
  const { id } = useParams();
  const { loginStore } = rootStore;
  const api = useApi()
  const messages = useApiMessages('employee', 'employees')

  const [file, setFile] = useState(null);

  const [loading, setLoading] = useState(false);

  const [User, setUser] = useState(null);
  const [customFieldsInitialValues, setCustomFieldsInitialValues] = useState([]);

  const [update, setUpdate] = useState(false);

  const getUserDetail = (id) => {
    setLoading(true)
    loginStore.environment.api.getClientEditDetail(id).then((result) => {
      if (result.kind === "ok") {
        setFile(result.data.photo)
        setUser(result.data)
      } else {
        showMessage()
      }
    }).finally(() => setLoading(false))
  }

  // get lists
  useEffect(() => {
    // get countries
    if (id) getUserDetail(id)
  }, []);

  const createTeam = (values, formik) => {
    setLoading(true)
    let data = { ...values }
    // dont send if is not a new file
    if (file?.name) data.photo = file
    if (id) {
      api.updateTeam(data).handle({
        successMessage: messages.update.success,
        errorMessage: messages.update.error,
        onSuccess: () => { navigate(ROUTES.SETTINGS_TEAM) },
        onError: (result) => { formik.setErrors(result?.errors) },
        onFinally: () => { setLoading(false) }
      })
    } else {
      api.createTeam(data).handle({
        successMessage: messages.create.success,
        errorMessage: messages.create.error,
        onSuccess: () => { navigate(ROUTES.SETTINGS_TEAM) },
        onError: (result) => { formik.setErrors(result?.errors) },
        onFinally: () => { setLoading(false) }
      })
    }
  }

  const renderPermissionsInput = (errors, setFieldValue, values) => {
    let fields

    fields = permissionFields.map((group, i) => (
      <>
        {group.map((mod, ii) => (
          <Grid item xs={6} lg={4} md={4} justifyContent={'center'} key={`mod_${ii}`}>
            <Grid item xs={6} lg={12} md={12} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>

              <MDTypography mb={{ xs: 2, md: 0 }} sx={{ mr: 5 }} variant="h6" fontWeight="bold">
                {mod.name}
              </MDTypography>
              <MDSwitch type="ios" />

            </Grid>
            {mod.permissions.map((perm,) => (
              <Grid item xs={6} lg={11} md={11} ml={1} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>

                <MDTypography variant="h6" fontWeight="regular">
                  {perm.label}
                </MDTypography>
                <Checkbox fontSize={'small'} />

              </Grid>
            ))}
          </Grid>
        ))}

        <Grid item xs={12} lg={12} md={12} key={`group_${i}`}>
          <hr className="MuiDivider-root MuiDivider-fullWidth" style={{ background: '#C6C9CE', margin: '12px 0' }} />
        </Grid>
      </>
    ))

    return fields
  }

  const handleDrop = (e) => {
    e.preventDefault();
    const newFile = [...e.dataTransfer.files];
    setFile(...newFile)
  }
  const handleInputFile = (event) => {
    if (event.target.files?.length) {
      const newFile = [...event.target.files];
      setFile(...newFile)
    }
  }

  const renderImageInput = (errors, setFieldValue, values) => {

    return (
      <Grid item xs={12} lg={6} md={12} sx={{ height: '280px' }}>
        <MDBox
          onDragOver={(e) => e.preventDefault()}
          onDrop={(e) => handleDrop(e)}
          sx={{ height: '200px', position: 'relative' }}
        >
          <input type='file' accept='image/*' id='images' multiple style={{ display: 'none' }}
            onChange={(event) => handleInputFile(event)}
            onClick={(event) => event.target.value = null}
          />
          {file && <MDBox component={'img'} src={deleteIcon} alt={'delete-icon'} sx={{ position: 'absolute', right: 15, top: 15, zIndex: 99 }} onClick={() => setFile(null)} />}
          <label htmlFor={'images'}>
            <MDBox sx={{ width: '100%', height: 270, borderRadius: '8px', objectFit: 'cover', backgroundColor: '#c7c7c7', position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1 }}>
              <MDBox
                component={'img'} alt={'default_img'}
                src={
                  !file
                    ? default_img
                    : file?.name
                      ?  URL.createObjectURL(file)
                      : file
                }
                sx={file ? { width: '100%', height: '100%', objectFit: 'cover' } : {}}
              />
            </MDBox>
          </label>
        </MDBox>
      </Grid>
    )
  }

  return (
    <AdminLayout title={"New Task"}>
      <Grid container alignItems="top" paddingTop={0} marginTop={0}>
        <Grid ml={5} item xs={12} lg={9} md={9} >
          <MDTypography variant="h2" fontWeight="medium" mb={1}>
            {id ? 'Update User' : 'Create New User'}
          </MDTypography>
        </Grid>
      </Grid>

      <Formik
        innerRef={formikRef}
        initialValues={Object.assign({}, clientInitialValues(User), customFieldsInitialValues)}
        validationSchema={clientValidationSchema}
        validateOnBlur={false}
        validateOnChange={false}
        enableReinitialize
        onSubmit={createTeam}
      >
        {({ errors, setFieldValue, values, handleSubmit }) => {
          return (
            <Form>
              <Grid container spacing={{ xs: 2, md: 3, lg: 3 }} pb={3} className="task_container" sx={{ alignItems: 'flex-start' }}>
                {renderImageInput(errors, setFieldValue, values)}
                <Grid item xs={12} lg={6} md={12}>
                  <FormikInput
                    name={'first_name'}
                    useBrandingColor={'secondary'}
                    label={'First Name'}
                    placeholder={'input first name of user'}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    item xs={12} lg={12} md={12}
                    mb={2} mt={2}
                  />
                  <FormikInput
                    name={'last_name'}
                    useBrandingColor={'secondary'}
                    label={'Last Name'}
                    placeholder={'input last name of user'}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    item xs={12} lg={12} md={12}
                    mb={2}
                  />
                  <FormikInput
                    name={'email'}
                    useBrandingColor={'secondary'}
                    label={'Email Address'}
                    placeholder={'input email address'}
                    type={'email'}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    item xs={12} lg={12} md={12}
                    mb={2}
                  />
                </Grid>
                <FormikInput
                  name={`phone_number`}
                  useBrandingColor={'secondary'}
                  label={'Phone number'}
                  type={'phone_input'}
                  placeholder={'input phone number'}
                  setFieldValue={setFieldValue}
                  errors={errors}
                  item xs={12} lg={6} md={6}
                />


                <FormikInput
                  name={'address'}
                  useBrandingColor={'secondary'}
                  label={'Address'}
                  placeholder={'input address'}
                  setFieldValue={setFieldValue}
                  errors={errors}
                  item xs={12} lg={6} md={6}
                />

                <FormikInput
                  name={`extension_number`}
                  useBrandingColor={'secondary'}
                  label={'Extension Number'}
                  type={'number'}
                  placeholder={'input extension number'}
                  setFieldValue={setFieldValue}
                  errors={errors}
                  item xs={12} lg={6} md={6}
                />


                  <FormikInput
                    name={'compensation'}
                    useBrandingColor={'secondary'}
                    label={'User Compensation'}
                    placeholder={'input address'}
                    setFieldValue={setFieldValue}
                    type={'numeric'}
                    errors={errors}
                    item xs={12} lg={6} md={6}
                  />


                  <Grid item xs={12} lg={6} md={6} mt={-3} display={'flex'} alignItems={'center'}>
                      <MDTypography sx={{ mr: 5 }} variant="h6" fontWeight="bold">
                        Default Calling Group
                      </MDTypography>
                      <FormikInput
                        sx={{ mt: 3 }}
                        name={'default_calling_group'}
                        useBrandingColor={'secondary'}
                        type={'boolean'}
                        setFieldValue={setFieldValue}
                        errors={errors}
                      />
                  </Grid>



                <Grid item xs={12} lg={6} md={6} display={'flex'} alignItems={'center'}>
                  <MDTypography sx={{ mr: 5 }} variant="h6" fontWeight="bold">
                    Make Administrator
                  </MDTypography>
                  <FormikInput
                    sx={{ mt: 3 }}
                    name={'is_staff'}
                    useBrandingColor={'secondary'}
                    type={'boolean'}
                    setFieldValue={setFieldValue}
                    errors={errors}
                  />
                </Grid>


                <Grid item xs={12} lg={12} md={12}>
                  <hr className="MuiDivider-root MuiDivider-fullWidth" style={{ background: '#C6C9CE', margin: '12px 0' }} />
                </Grid>

                {renderPermissionsInput(errors, setFieldValue, values)}

                <Grid item xs={12} lg={12} md={12} display="flex" justifyContent="space-evenly" alignItems="center">
                  <MDButton
                    color="green"
                    variant="outlined"
                    style={{ width: 300 }}
                  >
                    Cancel
                  </MDButton>

                  <MDButton
                    color="green"
                    type='submit'
                    style={{ width: 300 }}
                  >
                    Save User
                  </MDButton>
                </Grid>

              </Grid>
            </Form>
          )
        }}
      </Formik>

    </AdminLayout>
  )
}
