import { useEffect, useState } from "react";
import moment from 'moment'
import { Grid } from '@mui/material';
import AdminLayout from "../../components/AdminLayout";
import { useNavigate } from "react-router-dom";
import MDTypography from "../../components/MDTypography";
import { useStores } from "../../models";
import { Headerx } from "./components/Headerx";
import SimpleTable from "components/SimpleTable";
import "./styles.css"
import { ROUTES } from 'constants'
//icons
import DeleteIcon from 'assets/images/icons/delete.png';
import Edit from "assets/images/icons/edit.png";
import { showMessage, money_fmt } from "../../services/helpers";
import * as React from "react";
import MDButton from "../../components/MDButton";
import { Link } from "react-router-dom";

const dataTableModel = {
    columns: [
        { Header: "Number", accessor: "number" },
        { Header: "Name (A-Z)", accessor: "title" },
        { Header: "Client", accessor: "client" },
        { Header: "Quote", accessor: "quote" },
        { Header: "Subtotal", accessor: "subtotal" },
        { Header: "Discount", accessor: "discount" },
        { Header: "Total", accessor: "total" },
        { Header: "End Date", accessor: "end_date" },
        { Header: "Created", accessor: "created_at" },
        // {Header: "", accessor: "action"},
    ],
    rows: [],
    spacing: 10,
}

export default function Jobs() {
    const rootStore = useStores();
    const { loginStore } = rootStore;
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [Data, setData] = useState({ ...dataTableModel });
    const [JobStatus, setJobStatus] = useState({
        ENDING: 0,
        LATE: 0,
        REQUIRES_INVOICE: 0,
        UNSCHEDULED: 0,
        TODAY: 0,
        UPCOMING: 0,
    });

    const nameLinkBox = (name, link) => (
        <Link to={link} className='base-item link-item' >
            {name}
        </Link>
    )

    const getJobs = (searchData, extraData) => {
        setLoading(true)
        loginStore.environment.api.getJobs(searchData, extraData)
            .then((result) => {
                if (result.kind === "ok") {
                    const responseData = result?.data?.results;
                    let data = { ...dataTableModel };
                    data.rows = responseData.length > 0
                        ? responseData.map(r => {
                            return {
                                number: nameLinkBox(`JN${r.id}`, ROUTES.EDIT_JOB(r.id)),
                                title: r.title,
                                client: r?.quote?.client_name
                                ? <Link to={ROUTES.CLIENTS_PROFILE(r.quote.client_id)} className='base-item link-item'>{r?.quote?.client_name}</Link>
                                : '',
                                quote: r?.quote?.title,
                                subtotal: money_fmt(r?.quote?.subtotal),
                                discount: money_fmt(r?.quote?.discount),
                                total: money_fmt(r?.quote?.total),
                                created_at: r?.created_at ? moment(r.created_at).format('ll') : '-',
                                end_date: r?.end_date ? moment(r.end_date).format('ll') : '-',
                                // action: <MDButton color="green" iconOnly onClick={() => navigate(ROUTES.EDIT_JOB(r.id))}>
                                //     <img width={'80%'} src={Edit}/>
                                // </MDButton>
                            }
                        }) : [];
                    setData(data)

                } else {
                    if (result.kind === "bad-data") {
                        showMessage(result?.errors, 'error', true)
                    } else {
                        showMessage('An error occurred, please try again later')
                    }
                }
            }).finally(() => setLoading(false))
    }

    const getJobStatus = () => {
        loginStore.environment.api.getJobStatus().then((result) => {
            if (result.kind === "ok") {
                setJobStatus(result.data)
            }
        })
    }

    useEffect(() => {
        getJobs('');
        getJobStatus();
    }, [])

    return (
        <AdminLayout title={"Jobs"} searchFunc={getJobs} loading={loading}>
            <Grid container alignItems="center" pb={3} pt={{ xs: '50px', sm: 0 }}>
                <Grid item xs={12} lg={12} md={12} mb={3}>
                    <Headerx status={JobStatus} />
                </Grid>
                <Grid item xs={7} md={3} lg={10} mt='auto' pb={{ xs: '20px', md: 0 }}>
                    <MDTypography useBrandingColor={'tertiary'} variant="h2" fontWeight="medium" width='12rem' ml={{ xs: 0, lg: 2 }}>
                        All Jobs
                    </MDTypography>
                </Grid>
                <Grid item xs={6} md={3} lg={2} mt={{ xs: 'auto', md: 0, lg: 'auto' }}>
                    <MDButton
                        useBrandingColor={'primary'}
                        color="green"
                        className={['btn-import-client', false && 'active'].join(" ")}
                        onClick={() => navigate(ROUTES.NEW_JOB)}
                    >{'Add New +'}</MDButton>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12} ml={3}>
                    <SimpleTable data={Data} />
                </Grid>
            </Grid>
        </AdminLayout>
    )
}
