import Grid from "@mui/material/Grid";
import MDBox from "../../../components/MDBox";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import MDTypography from "../../../components/MDTypography";
import moment from "moment";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Button, ButtonGroup } from "@mui/material";
import { FilterButton } from "./FilterButton";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import MDButton from "../../../components/MDButton";

const GreenButton = styled(Button)(({ theme }) => ({
  color: 'var(--white)',
  backgroundColor: "var(--green)",
  '&:hover': {
    backgroundColor: "var(--dark-green)",
    boxShadow: 'none',
    color: 'var(--white)'
  },
  '&:focus:not(:hover)': {
    color: 'var(--white)',
    backgroundColor: "var(--green)",
  },
}));

const CoralButton = styled(Button)(({ theme }) => ({
  color: 'var(--grey)',
  border: "none",
  width: "100px",
  boxShadow: 'none',
  borderRight: "none !important",
  backgroundColor: "var(--very-light-coral)",
  borderColor: "transparent",
  borderRadius: "16px !important",
  '&:hover': {
    // backgroundColor:  "var(--light-coral)",
    boxShadow: 'none',
    color: 'var(--black-light)',
    border: "none",
  },
  '&:focus:not(:hover)': {
    backgroundColor: "var(--very-light-coral)",
    color: 'var(--grey)',
  },
}));

const ActiveButton = styled(Button)(({ theme }) => ({
  color: 'var(--light-black)',
  borderColor: "transparent",
  borderRight: "none !important",
  width: "120px",
  border: "none",
  backgroundColor: "var(--white)",
  zIndex: 999,
  boxShadow: "0px 10px 30px 4px rgba(0,0,0,0.16)",
  borderRadius: "16px !important",
  '&:hover': {
    backgroundColor: "var(--white)",
    color: 'var(--light-black)',
    border: "none",
  },
  '&:focus:not(:hover)': {
    backgroundColor: "var(--white)",
    color: 'var(--light-black)',
  },

}));

function addWeeks(date, weeks) {
  date.setDate(date.getDate() + 7 * weeks);
  return date;
}
export const CalendarToolbar = (props) => {
  console.log(' props ===>>> ', props)
  const { date, onView, onNavigate, view } = props
  const navigate = useNavigate()

  const goToWeekView = () => onView('week');
  
  const goToMonthView = () => onView('month');

  const goToDayView = () => onView('day')
  
  const goToTimelineView = () => onView('agenda')
  
  const DateButton = ({ view, title, onClick }) => {
    return view === title.toLowerCase() ? (
      <ActiveButton
        onClick={onClick}
        style={{ textTransform: "capitalize" }}
      >
        {title}
      </ActiveButton>
    ) : (
      <CoralButton
        onClick={onClick}
        style={{ textTransform: "capitalize" }}
      >
        {title}
      </CoralButton>
    )
  }

  const goToBack = () => {
    let mDate = date;
    let newDate;
    if (view == "week") {
      newDate = addWeeks(mDate, -1);
    } else if (view == "day") {
      newDate = new Date(
        mDate.getFullYear(),
        mDate.getMonth(),
        mDate.getDate() - 1,
        1);
    } else {
      newDate = new Date(
        mDate.getFullYear(),
        mDate.getMonth() - 1,
        1);
    }
    console.log('prev ===>>> ', newDate);
    onNavigate('prev', newDate);
    // this.getCalendarEvents(newDate);

  }

  const goToNext = () => {
    let mDate = date;
    let newDate;
    if (view == "week") {
      newDate = addWeeks(mDate, 1);
    } else if (view == "day") {
      newDate = new Date(
        mDate.getFullYear(),
        mDate.getMonth(),
        mDate.getDate() + 1,
        1);
    } else {
      newDate = new Date(
        mDate.getFullYear(),
        mDate.getMonth() + 1,
        1);
    }
    console.log('next ===>>> ', newDate);
    onNavigate('next', newDate);
    // this.getCalendarEvents(newDate);

  }

  const today = moment(date);
  const start_week = today.startOf('week').format('D');
  const end_week = today.endOf('week').format('D');
  const week_display = moment(date).format('MMMM') + " " + start_week + "-" + end_week + ", " + moment(date).format('YYYY');


  return (
    <Grid container spacing={2} style={{ borderColor: "var(--light-grey)", position: 'absolute', width: '95%' }} pb={3} borderBottom={2}>

      <Grid item xs={12} lg={4} md={4}  >
        <MDBox sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
          <ChevronLeftIcon fontSize={"large"} onClick={goToBack} color={"green"} />
          <MDTypography color={"green"} fontWeight={"bold"} sx={{ fontSize: 24 }}>
            {view == "week" ? week_display : moment(date).format('MMMM YYYY')}
          </MDTypography>
          <ChevronRightIcon onClick={goToNext} fontSize={"large"} color={"green"} />
        </MDBox>
      </Grid>

      <Grid item xs={12} lg={5} md={5}  >
        <ButtonGroup
          style={{
            borderShadow: "none !important",
            borderRadius: "16px !important",
            backgroundColor: "var(--very-light-coral)",
            border: "none"
          }}  >
          <DateButton title={"Day"} onClick={goToDayView} view={view} />
          <DateButton title={"Week"} onClick={goToWeekView} view={view} />
          <DateButton title={"Month"} onClick={goToMonthView} view={view} />
          <DateButton title={"Team"} onClick={goToTimelineView} view={view} />
        </ButtonGroup>
      </Grid>
      <Grid item xs={2} lg={2} md={2} justifyContent={"center"} display={"flex"} flexDirection={"row"} >
        {/* <FilterButton /> */}
      </Grid>
      <Grid item xs={2} lg={1} md={1}  >
        <MDButton useBrandingColor={'primary'} color={'green'} onClick={goToWeekView} view={view}>Today</MDButton>
      </Grid>
    </Grid>)
}
