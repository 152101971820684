import React, { useState, useRef } from 'react';
import {Link} from 'react-router-dom';
/* MUI components */
import {AppBar, Icon, IconButton, Toolbar, Typography, Box, Menu, Popper} from '@mui/material';

/* MD components */
import MDBox from "components/MDBox";
import MDBadge from "components/MDBadge";

/* utils */
import { useMaterialUIController } from 'context';
import { useStores } from 'models';

/* styles */
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarDesktopMenu,
  navbarIconButton
} from "../styles"
import { styles } from './styles';
import MDInput from 'components/MDInput';
import MDButton from "../../../MDButton";

export const DashboardNavbarMobile = ({handleMiniSidenav, isMini, iconHomeColor, absolute, light, iconsStyle}) => {
  const [controller, dispatch] = useMaterialUIController();
  const { transparentNavbar, darkMode } = controller;
  const { loginStore } = useStores();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openOptions, setOpenOptions] = useState(loginStore.navbarOpen);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenOptions((previousOpen) => !previousOpen);
  };
  const closeMenu = () => setAnchorEl(null);
  const handleOpenPhone = (event) => {
    event.preventDefault()
    loginStore.setSidePhoneOpen(!loginStore.sidePhoneOpen);
    setOpenOptions((previousOpen) => !previousOpen);
  }
  return (
    <>
    <AppBar
      sx={theme => ({
        ...navbar(theme, { transparentNavbar, absolute, light, darkMode }),
        background: "transparent",
        boxShadow: "none",
        paddingInline: '18px',
        filter: loginStore.sidePhoneOpen ? 'blur(2px)' : 'none',
      })}
    >
      <Toolbar
        sx={theme => ({ ...navbarContainer(theme), justifyContent: "center" })}
        disableGutters={true}
      >
        <MDBox
          color="inherit"
          style={{ justifyContent: "space-between" }}
          mb={{ xs: 1, md: 0 }}
          sx={theme => navbarRow(theme, { isMini })}
        >
          <IconButton
            sx={navbarDesktopMenu}
            onClick={handleMiniSidenav}
            size="small"
            disableRipple
          >
            <Icon fontSize="medium" sx={iconHomeColor}>
              menu
            </Icon>
          </IconButton>
          {loginStore.company_name && (
            <Typography style={styles.company_name} variant="h6">
              {loginStore.company_name}
            </Typography>
          )}
          <MDButton
            useBrandingColor={'primary'}
            color={'green'}
            sx={styles.box_menu_handler}
            onClick={e => handleClick(e)}
            id='open-options'
          >
            <div style={styles.dot}></div>
            <div style={styles.dot}></div>
            <div style={styles.dot}></div>
          </MDButton>

          <Popper
            anchorEl={anchorEl}
            open={openOptions}
            onClose={closeMenu}
            sx={styles.menu}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left"
            }}
          >
            <Box sx={styles.popper}>

              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                aria-controls="notification-menu"
                aria-haspopup="true"
                variant="contained"
                component={Link}
                to="/notifications"
                style={{
                  backgroundColor: "#60A77C",
                  width: 40,
                  height: 40,
                  borderRadius: 8
                }}
              >
                <MDBadge badgeContent={9} color="error" size="xs" circular>
                  <Icon sx={iconsStyle}>notifications</Icon>
                </MDBadge>
              </IconButton>
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                aria-controls="notification-menu"
                aria-haspopup="true"
                variant="contained"
                onClick={handleOpenPhone}
                style={{
                  backgroundColor: "#60A77C",
                  width: 40,
                  height: 40,
                  borderRadius: 8,
                  marginRight: 15
                }}
              >
                <Icon sx={iconsStyle}>phone</Icon>
              </IconButton>
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                aria-controls="notification-menu"
                aria-haspopup="true"
                variant="contained"
                style={{
                  backgroundColor: "#60A77C",
                  width: 40,
                  height: 40,
                  borderRadius: 8,
                  marginRight: 15
                }}
              >
                <Icon sx={iconsStyle}>mail</Icon>
              </IconButton>
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                aria-controls="notification-menu"
                aria-haspopup="true"
                variant="contained"
                style={{
                  backgroundColor: "#60A77C",
                  width: 40,
                  height: 40,
                  borderRadius: 8,
                  marginRight: 15
                }}
              >
                <Icon sx={iconsStyle}>person</Icon>
              </IconButton>
              <IconButton
                size="small"
                disableRipple
                color="red"
                sx={navbarIconButton}
                component={Link}
                to="/settings"
                style={{
                  backgroundColor: "#60A77C",
                  width: 40,
                  height: 40,
                  borderRadius: 8,
                  marginRight: 15
                }}
              >
                <Icon sx={iconsStyle}>settings</Icon>
              </IconButton>
            </Box>
          </Popper>
        </MDBox>
      </Toolbar>
    </AppBar>
    {(openOptions)&& (
      <Box style={{ width: 280, alignSelf: 'start', marginTop: '100px'}}>
        <MDInput
          label="search..."
          style={{width: '100%', height: '44px'}}
        />
      </Box>
    )}
     </>
  )
}
