import { Instance, SnapshotOut, types } from "mobx-state-tree"
import { withEnvironment } from "../extensions/with-environment";
import { withRootStore } from "../extensions/with-root-store";
/**
 * Model description here for TypeScript hints.
 */


export const LoginStoreModel = types
    .model("LoginStore")
    .extend(withRootStore)
    .extend(withEnvironment)
    .props({
        // user
        id: types.maybeNull(types.number),
        first_name: types.maybeNull(types.string),
        last_name: types.maybeNull(types.string),
        email: types.maybeNull(types.string),
        username: types.maybeNull(types.string),
        admin: types.maybeNull(types.boolean),
        address_1: types.maybeNull(types.string),
        address_2: types.maybeNull(types.string),
        appointment_reminders: types.maybeNull(types.boolean),
        city: types.maybeNull(types.number),
        // token
        access_token: types.maybeNull(types.string),
        refresh_token: types.maybeNull(types.string),
        // company
        company_id: types.maybeNull(types.number),
        company_name: types.maybeNull(types.string),
        company_name_primary: types.maybeNull(types.boolean),
        company_phone_number: types.maybeNull(types.string),
        company_address_1: types.maybeNull(types.string),
        company_address_2: types.maybeNull(types.string),
        company_website: types.maybeNull(types.string),
        company_email: types.maybeNull(types.string),
        company_city: types.maybeNull(types.string),
        company_state: types.maybeNull(types.string),
        company_country: types.maybeNull(types.string),
        company_zip_code: types.maybeNull(types.string),
        //
        country: types.maybeNull(types.number),
        how_did_you_hear_about_us: types.maybeNull(types.string),
        industry: types.maybeNull(types.string),
        invoice_follow_up: types.maybeNull(types.boolean),
        job_follow_up: types.maybeNull(types.boolean),
        name: types.maybeNull(types.string),
        phone_number: types.maybeNull(types.string),
        others_phone_number: types.maybeNull(types.string),
        others_email: types.maybeNull(types.string),
        quote_follow_up: types.maybeNull(types.boolean),
        referred_by: types.maybeNull(types.string),
        role: types.maybeNull(types.string),
        state: types.maybeNull(types.number),
        zip_code: types.maybeNull(types.string),
        company_data: types.maybeNull(types.frozen()),
        branding_data: types.maybeNull(types.frozen()),
        // ux
        side_phone_open: types.maybeNull(types.boolean),
        navbar_open: types.maybeNull(types.boolean),
        // custom fields
        custom_fields_tasks: types.maybeNull(types.array(types.frozen())),
        custom_fields_users: types.maybeNull(types.array(types.frozen())),
        custom_fields_clients: types.maybeNull(types.array(types.frozen())),
        custom_fields_requests: types.maybeNull(types.array(types.frozen())),
        custom_fields_events: types.maybeNull(types.array(types.frozen())),
        custom_fields_jobs: types.maybeNull(types.array(types.frozen())),
        custom_fields_categorys: types.maybeNull(types.array(types.frozen())),
        custom_fields_products: types.maybeNull(types.array(types.frozen())),
        custom_fields_invoices: types.maybeNull(types.array(types.frozen())),
        custom_fields_quotes: types.maybeNull(types.array(types.frozen())),
        custom_fields_inventories: types.maybeNull(types.array(types.frozen())),
    })
    .views(self => ({
        get isLoggedIn() {
            return self.access_token !== null && self.access_token !== undefined
        },
        get isUserAdmin() {
            return self.role === 'ADMIN'
        },
        get isUserClient() {
            return self.role === 'CLIENT'
        },
        get fullName() {
            return self.first_name + ' ' + self.last_name
        },
        get getAllData() {
            return { ...self }
        },
        get getCompany() {
            return {...self.company_data}
        },
        get getCompanyData() {
            return self.company_name || {}
        },
        get sidePhoneOpen() {
            return self.side_phone_open || false
        },
        get navbarOpen() {
            return self.navbar_open || false
        },
        get getCustomFieldsTask() {
            return self.custom_fields_tasks ? [...self.custom_fields_tasks] : []
        },
        get getCustomFieldsUser() {
            return self.custom_fields_users ? [...self.custom_fields_users] : []
        },
        get getCustomFieldsClient() {
            return self.custom_fields_clients ? [...self.custom_fields_clients] : []
        },
        get getCustomFieldsRequest() {
            return self.custom_fields_requests ? [...self.custom_fields_requests] : []
        },
        get getCustomFieldsEvent() {
            return self.custom_fields_events ? [...self.custom_fields_events] : []
        },
        get getCustomFieldsJob() {
            return self.custom_fields_jobs ? [...self.custom_fields_jobs] : []
        },
        get getCustomFieldsCategory() {
            return self.custom_fields_categorys ? [...self.custom_fields_categorys] : []
        },
        get getCustomFieldsProduct() {
            return self.custom_fields_products ? [...self.custom_fields_products] : []
        },
        get getCustomFieldsInvoice() {
            return self.custom_fields_invoices ? [...self.custom_fields_invoices] : []
        },
        get getCustomFieldsQuote() {
            return self.custom_fields_quotes ? [...self.custom_fields_quotes] : []
        },
        get getCustomFieldsInventory() {
            return self.custom_fields_inventories ? [...self.custom_fields_inventories] : []
        },
        get getBrandingColor() {
            try {
                const primary = JSON.parse(self.branding_data.primary)
                const secondary = JSON.parse(self.branding_data.secondary)
                const tertiary = JSON.parse(self.branding_data.tertiary)
                const quaternary = JSON.parse(self.branding_data.quaternary)
                
                return {
                    primary: `rgba(${primary.rgb.r},${primary.rgb.g},${primary.rgb.b},${primary.rgb.a})`,
                    secondary: `rgba(${secondary.rgb.r},${secondary.rgb.g},${secondary.rgb.b},${secondary.rgb.a})`,
                    tertiary: `rgba(${tertiary.rgb.r},${tertiary.rgb.g},${tertiary.rgb.b},${tertiary.rgb.a})`,
                    quaternary: `rgba(${quaternary.rgb.r},${quaternary.rgb.g},${quaternary.rgb.b},${quaternary.rgb.a})`,
                }                
            } catch {
                return {}
            }
        }
    }))
    .actions(self => ({
        setApiToken(token: string | null) {
            const api = self.environment.api.apisauce
            self.access_token = token
            if (token) {
                api?.setHeader('Authorization', 'Bearer ' + token)
            } else {
                api?.deleteHeader('Authorization')
            }
        },
        setRefreshToken(refresh_token: string | null) {
            self.refresh_token = refresh_token
        },
        setUser(data: any) {
            self.id = data.id
            self.admin = data.admin
            self.first_name = data.first_name
            self.last_name = data.last_name
            self.email = data.email
            self.city = data.company?.city
            // company data
            self.company_id = data.company?.id
            self.company_name = data.company?.company_name
            self.company_name_primary = data.company_name_primary
            self.company_phone_number = data.company_phone_number

            self.country = data.company?.country
            self.industry = data.company?.industry
            self.role = data.role
            self.refresh_token = data.refresh_token
            self.address_1 = data.address_1
            self.address_2 = data.address_2
            self.appointment_reminders = data.appointment_reminders
            self.how_did_you_hear_about_us = data.how_did_you_hear_about_us
            self.invoice_follow_up = data.invoice_follow_up
            self.job_follow_up = data.job_follow_up
            self.name = data.name
            self.phone_number = data.phone_number
            self.others_phone_number = data.others_phone_number
            self.others_email = data.others_email
            self.quote_follow_up = data.quote_follow_up
            self.referred_by = data.referred_by

            self.state = data.state
            self.zip_code = data.zip_code
        },
        setCompany(data: any | {}) {
            self.company_data = data
        },
        setBranding(data: any | {}) {
            self.branding_data = data
        },
        setSidePhoneOpen(open: boolean) {
            self.side_phone_open = open
        },
        setNavbarOpen(open: boolean) {
            self.navbar_open = open
        },
        setCustomField(field: any, form: any) {
            let aux: any, temp: any
            switch (form) {
                case 'Task':
                    aux = self.getCustomFieldsClient
                    temp = [...aux, field]
                    self.custom_fields_tasks = temp || []
                    break;
                case 'User':
                    aux = self.getCustomFieldsClient
                    temp = [...aux, field]
                    self.custom_fields_users = temp || []
                    break;
                case 'Client':
                    aux = self.getCustomFieldsClient
                    temp = [...aux, field]
                    self.custom_fields_clients = temp || []
                    break;
                case 'Request':
                    aux = self.getCustomFieldsClient
                    temp = [...aux, field]
                    self.custom_fields_requests = temp || []
                    break;
                case 'Event':
                    aux = self.getCustomFieldsClient
                    temp = [...aux, field]
                    self.custom_fields_events = temp || []
                    break;
                case 'Job':
                    aux = self.getCustomFieldsClient
                    temp = [...aux, field]
                    self.custom_fields_jobs = temp || []
                    break;
                case 'Category':
                    aux = self.getCustomFieldsClient
                    temp = [...aux, field]
                    self.custom_fields_categorys = temp || []
                    break;
                case 'Product':
                    aux = self.getCustomFieldsClient
                    temp = [...aux, field]
                    self.custom_fields_products = temp || []
                    break;
                case 'Invoice':
                    aux = self.getCustomFieldsClient
                    temp = [...aux, field]
                    self.custom_fields_invoices = temp || []
                    break;
                case 'Quote':
                    aux = self.getCustomFieldsClient
                    temp = [...aux, field]
                    self.custom_fields_quotes = temp || []
                    break;
                case 'Inventory':
                    aux = self.getCustomFieldsClient
                    temp = [...aux, field]
                    self.custom_fields_inventories = temp || []
                    break;
            }
        },
        reset() {
            const api = self.environment.api.apisauce
            api.deleteHeader("Authorization")
            Object.keys(self).map(key => {
                self[key] = null
            })
        }
    }))

/**
 * Un-comment the following to omit model attributes from your snapshots (and from async storage).
 * Useful for sensitive data like passwords, or transitive state like whether a modal is open.

 * Note that you'll need to import `omit` from ramda, which is already included in the project!
 *  .postProcessSnapshot(omit(["password", "socialSecurityNumber", "creditCardNumber"]))
 */

type LoginStoreType = Instance<typeof LoginStoreModel>

export interface LoginStore extends LoginStoreType {
}

type LoginStoreSnapshotType = SnapshotOut<typeof LoginStoreModel>

export interface LoginStoreSnapshot extends LoginStoreSnapshotType {
}
