import React, {Children, useCallback, useEffect, useMemo, useState} from 'react'
import {Link as Routelink} from 'react-router-dom';
import AdminLayout from "../../components/AdminLayout";
import ThimeCard from "../../components/ThimeCard";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import Grid from '@mui/material/Grid';
import {ROUTES} from "../../constants";

const Separator = () => <div style={{height: 22}}/>;

export default function ReportPage() {  
  const reportLinks = [
        {title: "Schedule", description: "", link: ROUTES.REPORTS_SCHEDULE},
        {title: "Daily Transaction ", description: "", link: ROUTES.REPORTS_DAILY_TRANSACTION},
        {title: "Sales Report", description: "", link: ROUTES.REPORTS_SALES},
        {title: "Open Work Order/Job/Invoice", description: "", link: ROUTES.REPORTS_OPEN_ORDER},
        {title: "Selected Sales", description: "", link: ROUTES.REPORTS_SELECTED_SALES},
        {title: "Sales Rep", description: "", link: ROUTES.REPORTS_SALES_REP},
        {title: "Special order Report", description: "", link: ROUTES.REPORTS_SPECIAL_ORDER},
        {title: "Sales Tax Report", description: "", link: ROUTES.REPORTS_SALES_TAX},
    ];
  //
  return (
    <AdminLayout title={"Reports"}>
      <Grid container alignItems="top" paddingTop={0} marginTop={0} pt={{xs: 7.5, sm: 0}}>
         <Grid item xs={12} lg={4} md={4} display={"flex"}
              pl={3} pr={3}
              flexDirection={"column"}
              justifyContent={"flex-start"}
        >
          <MDBox pl={1} pr={1} mb={3}>
            <ThimeCard color={'#FFDBA4'} title={'Reports'}>              
              {reportLinks.map((reportLink, ridx)=> <>
                <MDBox component={Routelink} to={reportLink.link}>
                  <MDTypography variant="h6" fontWeight={"medium"} style={{color: "var(--light-black)"}}>
                    {reportLink.title}
                  </MDTypography>
                  <MDTypography variant="button" fontWeight={"bold"} style={{color: "var(--grey)", paddingBottom: 10}}>
                     {reportLink.description}
                  </MDTypography>
              </MDBox>
              <Separator/>
              </>)}

            </ThimeCard>
          </MDBox>
        </Grid>
      </Grid>
    </AdminLayout>)
}