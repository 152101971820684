import React, { useState } from 'react';
import MDButton from "components/MDButton";
import { useNavigate } from "react-router-dom";


export const AddButton = (props) => {
  const { tab, styles, onClick} = props;
  const navigate = useNavigate()
  const [active, setActive] = useState(false);

  return (
    <div style={{ float: 'right', marginLeft: 27 }}>
      <MDButton
        useBrandingColor={'primary'}
        color="green"
        className={['btn-import', active && 'active'].join(" ")}
        onClick={onClick}
      >
        Create New Message +
      </MDButton>
    </div>
  );
}
