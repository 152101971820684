import moment from "moment";
import {Link} from "react-router-dom";


export const dataTableModel = {
  columns: [
    { Header: "Name (A-Z)", accessor: "name", width: "30%"},
    { Header: "Address", accessor: "address_1", width: "30%" },
    { Header: "Email", accessor: "email" },
    { Header: "Phone", accessor: "phone_number" },
    { Header: "Date Joined", accessor: "date_joined", width: "30%"},
    { Header: "Last Active Date", accessor: "last_login", width: "30%"},
  ],
  rows: [],
  spacing: 10,
}

const nameLinkBox = (item) => {
  return (
    <Link to={`profile/${item.id}`} className='base-item link-item'>{item.name}</Link>
  )
}


export const renderTableRow = (item) => {
  item.last_login = item.last_login ? moment(item.last_login).format('MMMM Do YYYY, hh:mm A') : 'Never'
  item.date_joined = moment(item.date_joined).format('MMMM Do YYYY, hh:mm A')
  item.name = nameLinkBox(item)
  return item
}
