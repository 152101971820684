import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import './style.css';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

export default function MDCheckbox(props) {
  return (
    <div>
      <Checkbox 
        {...label} 
        sx={{
            color: "success",
            '&.Mui-checked': {
              color: "success",
            },
          }}
        className={props.alignment ? 'class-right' : ""}
        checked={props.checked}
        onChange={props.onChange}
        value={props.value}
        name={props.name}
      />
    </div>
  );
}