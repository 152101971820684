import { Grid } from '@mui/material';
import SimpleTable from "components/SimpleTable";
import { useStores } from "models";
import moment from 'moment';
import { useEffect, useState } from "react";
import { showMessage } from "services/helpers";
import AdminLayout from "../../../components/AdminLayout";
import MDInput from "../../../components/MDInput";
import MDTypography from "../../../components/MDTypography";
import { Header } from "../components/Header";
import "../styles.css";

 
export const invoiceDataTableModel = {
  columns: [
    { Header: "Number", accessor: "id" },
    { Header: "Invoice for", accessor: "invoice_for" },
    { Header: "Title", accessor: "title" },
    { Header: "Status", accessor: "status" }, 
    { Header: "Subtotal", accessor: "subtotal" },
    { Header: "Discount", accessor: "discount" },
    { Header: "Total", accessor: "total" },      
  ],
  rows: []
}

const headers = [
    {title: 'Total taxes', quantity: '0', content: '', id: 1},   
  ];

export default function OpenWork(props) {
    const rootStore = useStores();
    const {loginStore} = rootStore;
    const [Invoices, setInvoices] = useState({...invoiceDataTableModel});
    const [Headers, setHeaders] = useState(headers);
    const [loading, setLoading] = useState(false);
    const [StartDate, setStartDate] = useState(`${new Date().getFullYear()}-01-01`);
    const [EndDate, setEndDate] = useState(`${new Date().getFullYear()}-12-31`);
    //
    const getSalesTaxReport = (startDate, endDate) => {
        setLoading(true);
        loginStore.environment.api.getSalesTaxReport(startDate, endDate)
        .then((result) => {
            if (result.kind === "ok") {
                const {invoices_paid: invoices, total_taxes} = result.data;
                const parsedInvoices = invoices.map((invoice, iidix) => 
                ({...invoice, 
                    invoice_for: invoice.invoice_for?.full_name
                }))
                setInvoices({...Invoices, rows: parsedInvoices});
                setHeaders([
                    {title: 'Total taxes', quantity: total_taxes, content: '', id: 1},                    
                ]);
            } else {
                if (result.kind === "bad-data") {
                    showMessage(result?.errors, 'error', true);
                } else {
                    showMessage('An error occurred, please try again later');
                }
            }
        })
        .finally(() => setLoading(false))
    }
    
    useEffect(()=> {
        if (!!StartDate && !!EndDate) {
            getSalesTaxReport(StartDate, EndDate);
        }
    } ,[StartDate, EndDate]);

    return (

    <AdminLayout title={"Sales Tax"} loading={loading}>
        <Grid item xs={12} lg={12} md={12} mb={3}>
            <Header data={Headers} styles={{ width: 164}}/>
        </Grid>
        {/*  */}
         <Grid container ml={2} mb={2}>
            <Grid item xs={6} lg={3} py={{xs: 4, lg: 0}} pr={{xs: 2, lg: 0}} mr={{lg: 2, xs: 0}}>
                <MDTypography variant="h6" fontWeight="bold">
                    Start Date
                </MDTypography>
                <MDInput
                    fullWidth
                    useBrandingColor={'secondary'}
                    input={{placeholder: "Select date"}}
                    type='date'
                    value={StartDate}
                    onChange={evt => setStartDate(evt.target.value)}
                />
            </Grid>
            <Grid item xs={6} lg={3} py={{xs: 4, lg: 0}}>
                <MDTypography variant="h6" fontWeight="bold">
                    End Date
                </MDTypography>
                <MDInput
                    fullWidth
                    useBrandingColor={'secondary'}
                    input={{placeholder: "Select date"}}
                    type="date"
                    value={EndDate}
                    onChange={evt => setEndDate(evt.target.value)}
                />
            </Grid>
        </Grid>
        {/* INVOICES */}
        <Grid container spacing={3} mt={2}>
            <Grid item xs={7} md={3} lg={10} mt='auto' pb={{ xs: '20px', md: 0 }}>
                <MDTypography useBrandingColor={'tertiary'} variant="h2" fontWeight="medium" width='12rem' ml={{ xs: 0, lg: 2 }}>
                    Invoices
                </MDTypography>
            </Grid>   
            <Grid item xs={12} lg={12} md={12} ml={2}>
                <SimpleTable data={Invoices} emptyLabelText={'No invoices found'}/>
            </Grid>
        </Grid>
    </AdminLayout>
    )
}
