import React, {useState} from 'react';
import {Grid} from '@mui/material';
import './headers.css';

export const Header = (props) => {
  return (
    <Grid container gap={'10px'} pl={2} justifyContent={{xs: 'center', md: 'space-between'}}>
      <Grid item className="boxess" xs={5} md={3} lg={1.5}>
        <h6>Draft</h6>
        <p>{props?.status?.DRAFT || 0}</p>
      </Grid>
      <Grid item className="boxess" xs={5} md={3} lg={1.5}>
        <h6>Awaiting response</h6>
        <p>{props?.status?.AWAITING_RESPONSE || 0}</p>
      </Grid>
      <Grid item className="boxess" xs={5} md={3} lg={1.5}>
        <h6>Changes requested</h6>
        <p>{props?.status?.CHANGES_REQUESTED || 0}</p>
      </Grid>
      <Grid item className="boxess" xs={5} md={3} lg={1.5}>
        <h6>Approved</h6>
        <p>{props?.status?.APPROVED || 0}</p>
      </Grid>
      <Grid item className="boxess" xs={5} md={3} lg={1.5}>
        <h6>Converted</h6>
        <p>{props?.status?.CONVERTED || 0}</p>
      </Grid>
      <Grid item className="boxess" xs={5} md={3} lg={1.5}>
        <h6>Archived</h6>
        <p>{props?.status?.ARCHIVED || 0}</p>
      </Grid>
    </Grid>
  );
}