import {useEffect, useRef, useState} from "react";
import 'react-calendar-timeline/lib/Timeline.css'
import { Grid } from '@mui/material';
import AdminLayout from "components/AdminLayout";
import {useNavigate, useParams} from "react-router-dom";
import styles from "../Scheduler.module.css";
import "./react-calendar-timeline.css"
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
//icons
import { useStores } from "models";
import { showMessage, dateRange } from "services/helpers";
import FormikInput from "../../../components/FormikInput";
import {Form, Formik} from "formik";
import {eventValidationSchemaTask, getEventInitialValues} from "./form";


export default function Event(props) {
  const formikRef = useRef()
  const navigate = useNavigate();
  const {id} = useParams();
  const rootStore = useStores();
  const { loginStore } = rootStore;
  const [events, setEvents] = useState();
  const Repeats = [
    {value:'NEVER', label:'Never'},
    {value:'DAILY', label:'Daily'},
    {value:'WEEK', label:'Week'},
    {value:'MONTH', label:'Month'},
  ];

  const getEventById = (id) => {
    loginStore.environment.api.getEventById(id)
      .then((result) => {
        const { data } = result
        setEvents(data)
      })
      .catch(() => showMessage())
  }
  const handleCreate = (values) => {
    values.repeats = values.repeats?.value;
    loginStore.environment.api.createEvent(values).then((result) => {
      if (result.kind === "ok") {
        showMessage('Event created!', 'success');
        setTimeout(() => {
          navigate('/scheduler')
        }, 300);
      } else {
        if (result.kind === "bad-data") {
          showMessage(result?.errors, 'error', true)
        } else {
          showMessage('An error occurred, please try again later')
        }
      }
    });
  }

  useEffect(() => {
    if (id) getEventById(id)
  }, [])
  
  return (

    <AdminLayout title={"New Event"}>
      <Grid container alignItems="top" paddingTop={0} marginTop={0}>
        <Grid item xs={12} lg={9} md={9}  className={styles.leftContainer}>
          <MDTypography variant="h2" fontWeight="medium" mb={1}>
            {id ? 'Update Event' : 'Create New Event'}
          </MDTypography>
        </Grid>
      </Grid>
        <Formik
          innerRef={formikRef}
          initialValues={getEventInitialValues(events)}
          validationSchema={eventValidationSchemaTask}
          validateOnBlur={false}
          validateOnChange={false}
          enableReinitialize
          onSubmit={values => {
            handleCreate(values)
          }}
        >
          {({ errors, setFieldValue, values }) => {
            console.log('values: ', values);
            // console.log('errors: ', errors);
            return (
              <Form>
                <Grid container spacing={3} pb={3}  className="task_container">
                  <FormikInput
                    name={'title'}
                    label={'Title'}
                    errors={errors}
                    fullWidth
                    placeholder='input title'
                    item xs={6} lg={6} md={6}
                  />
                  <FormikInput
                    name={'repeats'}
                    label={'Repeats'}
                    setFieldValue={setFieldValue}
                    labelFieldName={'label'}
                    type={'select'}
                    placeholder={'Repeats'}
                    options={Repeats}
                    errors={errors}
                    item xs={6} lg={6} md={6}
                  />
                  <FormikInput
                    name={'instructions'}
                    label={'Instructions'}
                    errors={errors}
                    type={'textarea'}
                    rows={5}
                    fullWidth
                    placeholder='input title'
                    item xs={6} lg={6} md={6}
                  />
                  <Grid item xs={12} lg={12} md={12}>
                    <hr className="MuiDivider-root MuiDivider-fullWidth" style={{background: '#C6C9CE', margin: '32px 0'}} />
                  </Grid>
                  <FormikInput
                    name={'start_date'}
                    label={'Start Date'}
                    errors={errors}
                    type={'datetime-local'}
                    placeholder='input title'
                    item xs={3} lg={6} md={3}
                  />
                  <FormikInput
                    name={'end_date'}
                    label={'End Date'}
                    errors={errors}
                    type={'datetime-local'}
                    placeholder='input title'
                    item xs={3} lg={6} md={3}
                  />
                  <Grid item xs={12} lg={12} md={12} display="flex" justifyContent="center" alignItems="center">
                    <MDButton
                      useBrandingColor={'primary'}
                      color="green"
                      type="submit"
                      className={'btn-save-task'}
                    >
                      {id ? 'Edit Event' : 'Create Event'}
                    </MDButton>
                  </Grid>
                </Grid>
              </Form>
            )}
          }
        </Formik>
    </AdminLayout>
  )
}
