import { useEffect, useState } from "react";
import moment from 'moment'
import { Grid, InputAdornment, IconButton } from '@mui/material';
import AdminLayout from "../../../components/AdminLayout";
import { useNavigate, Link as RouteLink } from "react-router-dom";
import MDButton from "../../../components/MDButton";
import MDTypography from "../../../components/MDTypography";
import {Tables} from "../components/Tables";
import "../styles.css"
import { ROUTES } from "constants";

const header = [
    {title: 'Name (A-Z)', align: '', id: 1},
    {title: 'Address', align: '', id: 2}
];
const rows = [
    {name: 'Lorem Ipsum', address: 'Loren Ipsum'},
    {name: 'Lorem Ipsum', address: 'Loren Ipsum'},
    {name: 'Lorem Ipsum', address: 'Loren Ipsum'},
    {name: 'Lorem Ipsum', address: 'Loren Ipsum'},
    {name: 'Lorem Ipsum', address: 'Loren Ipsum'},
    {name: 'Lorem Ipsum', address: 'Loren Ipsum'},
    {name: 'Lorem Ipsum', address: 'Loren Ipsum'},
    {name: 'Lorem Ipsum', address: 'Loren Ipsum'},
  ];


export default function ClientHub(props) {
    const navigate = useNavigate();

  return (
    <AdminLayout title={"Manage Team"}>
        <Grid container alignItems="top" paddingTop={0} marginTop={0} spacing={3}>
            <Grid item xs={12} lg={12} md={12} mb={2}>
                <MDTypography variant="h2" fontWeight="medium" mb={1} ml={3} style={{ float: 'left' }}>
                    Clients Hub
                </MDTypography>
            </Grid>
        </Grid>
        <Grid container spacing={3}>
            <Grid onClick={() => navigate(ROUTES.REPORTS_CLIENT_HUB_DETAIL)} item xs={12} lg={12} md={12} ml={2}>
                <Tables body={rows} header={header} />
            </Grid>
        </Grid>
    </AdminLayout>
  )
}
